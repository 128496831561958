import React, { Component } from 'react';
import AgentLeftBar from '../../agent/AgentLeftBarComponent';
import AgentHeaderNavigation from '../../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../../agent/AgentCommonHeaderComponent';
import history from "../../../shared/history";
import Link from 'react-router-dom/es/Link';
import ViewTopUp from './ViewTopUpComponent';
import NewTopUp from './NewTopUpComponent';
import TopUpSuccess from './TopUpSuccessComponent';

class AgentTopUpDetails extends Component {
    constructor() {
        super();
        this.state = {
          hideMsg: false,
          leftNavActive: '/top-up',
          url: history.location.pathname,
          showConfirmationPopup : false,
          confimationAction : null,
          showLoaderOnPopup : false,
          isError : false
        }
    }
    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }

    handleConfirmationPopup = (event,action)=>{
        this.setState({
            showConfirmationPopup : event,
            confimationAction : action
        })		
    } 

    submitPaymentInit = (values) => {
        this.setState({
            showLoaderOnPopup : true
        })
        let loginCred = {
            "token" : "bearer "+localStorage.getItem('refresh_token')
        }
        let self = this;
        const successCb = (response) => {
            self.setState({
                showLoaderOnPopup : true
            })
            const successPopupCb = (payload) => {
                window.location.href = payload.easeubuzz_pay_url;

            }
            const errorPopupCb = () => {
                self.setState({
                    showConfirmationPopup : false,
                    isError : true
                });
                setTimeout(function(){ 
                    self.setState({
                        isError : false
                    }); }, 5000);
            }
            values.token = 'bearer '+response.token;
            values.refresh_token = 'bearer '+response.refresh_token;
            self.props.topUpPaymentInitAction(values,successPopupCb,errorPopupCb);
        }
        const errorCb = () => {
            this.props.logoutAction();
        }
        this.props.generateTokenAction(loginCred,successCb,errorCb);
    }

    render() {
        const { accountResponse,productResponse,pagesResponse,searchRequestForm,perPageLimit,getRecordsPerPage,submitCreditForm,addCreditRequest,currentPage} = this.props;
        let activeLeftNavigation  = this.state.url;
        return (
            <React.Fragment>
                 {/*nav-section Start*/}
                 <AgentHeaderNavigation productResponseList={productResponse} {...this.props}/>
                 {/*nav-section end*/}
                    <section className="mid-wrapper">
                        <AgentCommonHeader {...this.props}/>
                        <div className="my-account-info">
                            <div className="container">
                                <div className="my-account-sec">
                                    <div className="my-account-outer">
                                    <AgentLeftBar handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} logoutAction={this.props.logoutAction}/>
                                    {this.props.type === 'top-up' ?
                                    <div className="profile-detail-info">
                                        <div className="edit-profile-bar">
                                            <div className="pull-right">
                                                { /* eslint-disable-next-line */}
                                                <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                            </div>
                                        </div>
                                        <div className="tabs-container-detail">
                                            <div className="mark-up-info">
                                                <div className="tabs-top">
                                                    <div className="tabs-title">
                                                    <h2><i className="icon icon-currency2"></i><span>Top UP</span></h2>
                                                    </div>
                                                </div>
                                                <div className="category-list updates-list">
                                                    <ul>
                                                    <li>
                                                        <Link to="view-topup">
                                                            <div className="category-card">
                                                                <figure><i className="icon icon-currency2"></i></figure>
                                                                <div className="category-card-bottom">
                                                                    <h3>View All Top Ups</h3>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </li>  
                                                    <li>
                                                        <Link to ="topup-request">
                                                            <div className="category-card">
                                                                <figure><i className="icon icon-currency2"></i></figure>
                                                                <div className="category-card-bottom">
                                                                    <h3>New Top Up Request</h3>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </li>  
                                                </ul>
                                            </div>                            
                                            </div>
                                        </div>
                                    </div> : ''}
                                    {this.props.type === 'view-topup' ? <ViewTopUp {...this.props} getRecordsPerPage={getRecordsPerPage} perPageLimit={perPageLimit} accountResponse={accountResponse} pagesResponse ={pagesResponse} searchRequestForm={searchRequestForm} currentPage={currentPage}/> : ''}
                                    {this.props.type === 'topup-request' ? <NewTopUp {...this.props} {...this.state} addCreditRequest={addCreditRequest} submitPaymentInit={this.submitPaymentInit} accountResponse={accountResponse} pagesResponse ={pagesResponse} handleConfirmationPopup={this.handleConfirmationPopup} /> : ''}
                                    {this.props.type === 'topup-request-processing' ? <TopUpSuccess {...this.props} addCreditRequest={addCreditRequest} submitCreditForm={submitCreditForm} accountResponse={accountResponse} pagesResponse ={pagesResponse} /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default (AgentTopUpDetails)