import React from 'react';
import { getNewDateFormted, currencyFormat, dateFormat } from '../../../../shared/commonHelper';
import VendorFlightFareSummaryComponent from './VendorFlightFareSummaryComponent'
import VendorFlightTravellerComponent from './VendorFlightTravellerComponent'
import VendorFlightReviewBookingComponent from './VendorFlightReviewBookingComponent'
import * as flightHelper from '../../../../shared/flightHelper';

class VendorFlightBookTicketComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			autocomplete: {
				count: null,
				type: null,
				error: null,
				search: null,
				searchResults: null,
				showPopup: false,
			},
			searchStart: null,
			count: 0,
			showConfirmationPopup: false,
			showBalancePopup: true
		}
	}

	//************************************************************Confimation Popup*********************************************************/
	handleConfirmationPopup = (event) => {
		this.setState({
			showConfirmationPopup: event
		})
	}

	handleConfimationAction = () => {
		this.setState({
			showConfirmationPopup: false
		})
		this.props.handleHold('ticket');
		
	}
	//************************************************************AutoFill passenger from customer List Start***********************************************/	

	//Function to show and hide popup 
	handlePopup = (type, count) => {
		this.setState({
			autocomplete: Object.assign({},
				this.state.autocomplete,
				{
					count: count,
					type: type,
					showPopup: true
				}
			)
		})
	}

	//Function to search customer from customer list
	handleSearch = () => {
		let searchResults = [];
		this.setState({
			searchStart: true
		});

		if (!this.state.autocomplete.search || this.state.autocomplete.search.length < 1) {
			this.setState({
				autocomplete: Object.assign({},
					this.state.autocomplete,
					{
						error: true,
						showPopup: true
					}
				)
			})
		}
		else {
			if (this.props.customerList.response) {
				for (let i = 0; i < this.props.customerList.response.passenger.length; i++) {
					let item = this.props.customerList.response.passenger[i];
					let firstName = item.first_name;
					let lastName = item.last_name;
					if (firstName.includes(this.state.autocomplete.search) || lastName.includes(this.state.autocomplete.search)
						|| firstName.toUpperCase().includes(this.state.autocomplete.search) || lastName.toUpperCase().includes(this.state.autocomplete.search)
						|| firstName.toLowerCase().includes(this.state.autocomplete.search) || lastName.toLowerCase().includes(this.state.autocomplete.search)
					) {
						searchResults.push(item);
					}
				}
			}
			this.setState({
				autocomplete: Object.assign({},
					this.state.autocomplete,
					{
						error: false,
						searchResults: searchResults,
						showPopup: true
					}
				)
			})
		}
	}

	//Function to autofill passenger detail from customer list
	autoCompleteCustomer = (value) => {
		let isDomestic = this.props.searchQuery && this.props.searchQuery.is_domestic;
		let defaultAddress = this.props.accountResponse.myAccountResponse.response ? this.props.accountResponse.myAccountResponse.response.office_address : '';
		let defaultEmail = this.props.accountResponse.myAccountResponse.response ? this.props.accountResponse.myAccountResponse.response.email_address : '';
		if (this.state.autocomplete.count && this.state.autocomplete.type) {
			let count = this.state.autocomplete.count;
			let type = this.state.autocomplete.type;
			this.props.setFieldValue("first_name_" + type + "_" + count, value.first_name);
			this.props.setFieldValue("last_name_" + type + "_" + count, value.last_name);
			this.props.setFieldValue("mobile_no", value.mobile_no);
			this.props.setFieldValue("gender_" + type + "_" + count, value.gender);
			this.props.setFieldValue("dob_" + type + "_" + count, value.date_of_birth ? getNewDateFormted(value.date_of_birth) : null);
			this.props.setFieldValue("frequentFlyer_" + type + "_" + count, value.frequent_flyer_no ? value.frequent_flyer_no : '');
			this.props.setFieldValue("email", value.email_id ? value.email_id : defaultEmail);
			this.props.setFieldValue("addressLine1", value.address ? value.address : defaultAddress);
			this.props.setFieldValue("country", value.country_id ? value.country_id : '');
			this.props.setFieldValue("city", value.city_id ? value.city_id : '');
			this.props.setFieldValue("country_name", value.country_id ? value.country : '');
			this.props.setFieldValue("country_code", value.country_code ? value.country_code : '');
			this.props.setFieldValue("city_name", value.city_id ? value.city : '');
			this.props.setFieldValue("passenger_title_" + type + "_" + count, value.title_id);
			this.props.setFieldValue("title_name_" + type + "_" + count, value.passenger_title);
			if (isDomestic === 'false') {
				this.props.setFieldValue("passport_" + type + "_" + count, value.passport_no ? value.passport_no : '');
				this.props.setFieldValue("passportExpiry_" + type + "_" + count, value.passport_exp ? getNewDateFormted(value.passport_exp) : null);
				this.props.setFieldValue("nationality_" + type + "_" + count, value.nationality_code);
			}
			this.setState({
				autocomplete: Object.assign({},
					this.state.autocomplete,
					{
						showPopup: false
					}
				)
			})
		}
	}

	//************************************************************AutoFill passenger from customer List End***********************************************/
	enterPressed = (event) => {
		var code = event.keyCode || event.which;
		if (code === 13) {
			this.handleSearch();
		}
	}

	// ----------Balance popup-------------
	handleBalancePopup = () => {
		this.setState({ showBalancePopup: !this.state.showBalancePopup });
	}

	render() {
		let fareQuote = null;
		if(this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete'){
			let index = this.props.location.state.index;
			fareQuote = this.props.location.state.data[index];
		}else{
			fareQuote = this.props.location.state.data;
		}
		let accountResponse = this.props.accountResponse.myAccountResponse.response
		let totalAccountBalance = 0;
		if (accountResponse) {
			if (parseInt(accountResponse.cash_balance) > 0) {
				totalAccountBalance = parseInt(accountResponse.cash_balance) + parseInt(accountResponse.credit_limit);
			} else {
				totalAccountBalance = parseInt(accountResponse.credit_limit);
			}
		}
		let offeredFare = fareQuote.fare.offered_fare;
		let showInsuffient = false
		if (offeredFare && accountResponse && (totalAccountBalance < offeredFare)) {
			showInsuffient = true;
		}
		let segment1 = '';
		let segment2 = '';
		let totalPublishedFare = 0;
		let bothActive = false;
		let firstActive = false;
		if (this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete') {
			segment1 = this.props.location.state.data[0].segments[0];
			segment2 = this.props.location.state.data[1].segments[0];
			totalPublishedFare = currencyFormat(Number(this.props.location.state.data[0].fare.published_fare) + Number(this.props.location.state.data[1].fare.published_fare));
			if (this.props.location.state.index === 1) {
				bothActive = true;
				firstActive = true;
			}
		}

		return (
			<section className="mid-wrapper">
				<div className="container">
					<div className="review-booking-wrap">
					{
							(this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete') &&
							<div className="review-step">
								<div className="review-step-title">
									<div className="cols pull-left"> <span>Booking In Progress</span> </div>
									<div className="cols pull-right"> </div>
								</div>
								<div className="review-step-deatil review-booking">
									<div className="step-deatil-outer">
										<div className="booking-details">
											<div className={`cols ${firstActive && 'disabled-section'}`}>
												<div className="booking-head"> <span>Outbound Booking</span> </div>
												<div className="booking-list">
													<ul>
														<li><span>{this.props.searchQuery.segments[0].origin} - {this.props.searchQuery.segments[0].destination}</span>,
											<span>{segment1[0].airline.airline_code} -
											{segment1[0].airline.flight_number + ' '}
																{segment1[0].airline.fare_class}</span></li>
														<li><span>{dateFormat(segment1[0].stop_point_departure_time, 'DD MMM YYYY')}</span>,
											<span>{flightHelper.getDepartureTime(segment1[0].stop_point_departure_time)} -
											{flightHelper.getArivalTime(segment1)}</span></li>
													</ul>
												</div>
											</div>
											<div className={`cols ${!bothActive && 'disabled-section'}`}>
												<div className="booking-head"> <span>Inbound Booking</span> </div>
												<div className="booking-list">
													<ul>
														<li> <span>{this.props.searchQuery.segments[1].origin} - {this.props.searchQuery.segments[1].destination}</span>,
											<span>{segment2[0].airline.airline_code} -
											{segment2[0].airline.flight_number} {segment2[0].airline.fare_class}</span></li>
														<li><span>{dateFormat(segment2[0].stop_point_departure_time, 'DD MMM YYYY')}</span>,
											<span>{flightHelper.getDepartureTime(segment2[0].stop_point_departure_time)} -
											{flightHelper.getArivalTime(segment2)}</span> </li>
													</ul>
												</div>
											</div>
											<div className="total-adults-price">
												<div className="pull-left">Total Publish </div>
												<div className="pull-right">
													<i className="icon icon-rupee" />
													<span>{totalPublishedFare}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						<div className="left-side">
							<div className="fare-trigger">
								{ /* eslint-disable-next-line */}
								<a href="javascript:void(0)" className="btn btn-sky-blue fare-trigger-btn"><i className="icon icon-currency-file"></i>Fare Summery</a>
							</div>
							<div className="fare-summery">
								<React.Fragment>
									<VendorFlightFareSummaryComponent fareQuote={fareQuote} searchQuery={this.props.searchQuery}/>
								</React.Fragment>
							</div>
						</div>
						<div className="right-side">
							<div className="review-steps-wrap">
								<span className="insufficient_balance text-center" style={{ display: showInsuffient && !this.props.addPassengerSuccess ? 'block' : 'none' }}>Insufficient balance in your account.Please request for cash or credit</span>
								<div className="review-step-nav">
									<ul>
										<li className={!this.props.addPassengerSuccess ? 'current' : 'active'}>
											<div className="step-col">
												<div className="step-icon"><i className="icon icon-traveller" /><i className="icon icon-checked" /></div>
												<h2><span>TRAVELLER</span>
													{ /* eslint-disable-next-line */}
													<a href="javascript:void(0);" onClick={(event) => this.props.switchPage(false)} className="icon icon-pencil" > </a>
												</h2>
											</div>
										</li>
										<li className={this.props.addPassengerSuccess ? 'current' : ''}>
											<div className="step-col">
												<div className="step-icon"><i className="icon icon-book" /><i className="icon icon-checked" /></div>
												<h2><span>REVIEW BOOKING</span></h2>
											</div>
										</li>
									</ul>
								</div>
								{
									!this.props.addPassengerSuccess
									&&
									<VendorFlightTravellerComponent {...this.props}
										handlePopup={this.handlePopup}
									/>
								}
								{
									this.props.addPassengerSuccess
									&&
									<VendorFlightReviewBookingComponent
										{...this.props}
										fare={this.state}
										handleConfirmationPopup={this.handleConfirmationPopup}
									/>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="popup-crad popup-customer-list" style={{ display: this.state.autocomplete.showPopup ? 'block' : 'none' }}>
					<div className="popup-outer">
						<div className="popup-content">
							<div className="popup-head">
								<h2>Customer List</h2>
								<div className="popup-close"><span onClick={() => {
									this.setState({ autocomplete: Object.assign({}, this.state.autocomplete, { showPopup: false }), searchStart: null });

								}
								}
									className="icon icon-cross" /></div>
							</div>
							<div className="customer-list-block">
								<div className="customer-select">
									<div className="form-group-customer">
										<div className="customer-select-box">
											<input type="search" defaultValue={this.state.autocomplete.search} onKeyPress={(event) => this.enterPressed(event)} onChange={(event) => this.setState({ autocomplete: Object.assign({}, this.state.autocomplete, { search: event.target.value, showPopup: true }) })} />
											<button className="btn-sky-blue" onClick={this.handleSearch}>Search</button>
										</div>
									</div>
									{
										this.state.autocomplete.error &&
										<span className="red-error">Please enter atleast 1 Characters</span>
									}
								</div>
							</div>
							<div className="popup-scorll">
								<ul className="list-select">
									{
										this.state.searchStart && this.state.autocomplete.search
											?
											this.state.autocomplete.searchResults && this.state.autocomplete.searchResults.map((item, index) => {
												return (
													<React.Fragment key={index}>
														{ /* eslint-disable-next-line */}
														<li><a href="javascript:void(0);" onClick={() => this.autoCompleteCustomer(item)} key={index} >{item.first_name + " " + item.last_name + " (" + item.mobile_no + ")"}</a></li>
													</React.Fragment>
												)
											})
											:
											this.props.customerList.response && this.props.customerList.response.passenger.map((item, index) => {
												return (
													<React.Fragment key={index}>
														{ /* eslint-disable-next-line */}
														<li><a href="javascript:void(0);" onClick={() => this.autoCompleteCustomer(item)} key={index} >{item.first_name + " " + item.last_name + " (" + item.mobile_no + ")"}</a></li>
													</React.Fragment>
												)
											})
									}
								</ul>
							</div>
						</div>
					</div>
					<div className="popup-overlay" />
				</div>

				{/* Popup for booking confirmation */}
				<div className={`popup-crad confirmation-popup ${this.state.showConfirmationPopup ? 'show' : ''}`}>
					<div className="popup-outer">
						<div className="popup-content">
							<div className="popup-head">
								<h2>Booking Confirmation</h2>
								<div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false, null)} /></div>
							</div>
							<div className="popup-body popup-scorll">
								<div className="inner-confirmation">
									<p>Are you sure you want to continue?</p>
									<div className="popup-footer">
										<button className="btn btn-orange" onClick={() => this.handleConfimationAction()}>Yes</button>
										<button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false, null)}>No</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="popup-overlay" />
				</div>

				{accountResponse && accountResponse.user_type_id !== 1 && showInsuffient && <InsufficentBalancePopupComponent showInsuffient={showInsuffient} {...this.props} {...this.state} handleBalancePopup={this.handleBalancePopup} />}

			</section>
		)
	}
}

/*--------- Insufficent balance popup component ------------*/
class InsufficentBalancePopupComponent extends React.Component {
	render() {
		let { showBalancePopup, handleBalancePopup, showInsuffient } = this.props;
		return (
			<div className={`popup-crad confirmation-popup ${showBalancePopup && showInsuffient ? 'show' : ''}`}>
				<div className="popup-outer">
					<div className="popup-content">
						<div className="popup-head">
							<h2>Insufficent Balance</h2>
							<div className="popup-close"><span className="icon icon-cross" onClick={() => handleBalancePopup()} /></div>
						</div>
						<div className="popup-body popup-scorll">
							<div className="inner-confirmation">
								<p>Insufficient balance in your account.Please request for cash or credit!</p>
								<div className="popup-footer">
									<button className="btn btn-orange" onClick={() => handleBalancePopup()}>Yes</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="popup-overlay"></div>
			</div>
		);
	}
}

export default VendorFlightBookTicketComponent