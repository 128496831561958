import React, { Component } from 'react';
import AgentLeftBar from '../../agent/AgentLeftBarComponent';
import AgentHeaderNavigation from '../../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../../agent/AgentCommonHeaderComponent';
import ViewCredits from '../../agent/credits/ViewCreditsComponent';
import NewCredit from '../../agent/credits/NewCreditComponent';
import history from "../../../shared/history";
import Link from 'react-router-dom/es/Link';

class Credits extends Component {
    constructor() {
        super();
        this.state = {
          hideMsg: false,
          leftNavActive: '/credits',
          url: history.location.pathname
        }
    }
    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }
    render() {
        const { accountResponse,productResponse,pagesResponse,searchRequestForm,perPageLimit,getRecordsPerPage,submitCreditForm,addCreditRequest,currentPage} = this.props;
        let viewCreditResponse = accountResponse.viewCreditRequestResponse.response;
        let activeLeftNavigation  = this.state.url;
        return (
            <React.Fragment>
                 {/*nav-section Start*/}
                 <AgentHeaderNavigation productResponseList={productResponse} {...this.props}/>
                 {/*nav-section end*/}
                    <section className="mid-wrapper">
                        <AgentCommonHeader {...this.props}/>
                        <div className="my-account-info">
                            <div className="container">
                                <div className="my-account-sec">
                                    <div className="my-account-outer">
                                    <AgentLeftBar handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} logoutAction={this.props.logoutAction}/>
                                    {this.props.type === 'credits' ?
                                    <div className="profile-detail-info">
                                        <div className="edit-profile-bar">
                                            <div className="pull-right">
                                                { /* eslint-disable-next-line */}
                                                <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                            </div>
                                        </div>
                                        <div className="tabs-container-detail">
                                            <div className="mark-up-info">
                                                <div className="tabs-top">
                                                    <div className="tabs-title">
                                                        <h2><i className="icon icon-currency"></i><span>CREDIT LIMIT REQUEST</span></h2>
                                                    </div>
                                                </div>
                                                <div className="category-list updates-list">
                                                    <ul>
                                                    <li>
                                                            <Link to="view-credits">
                                                                <div className="category-card">
                                                                    <figure><i className="icon icon-view-credit"></i></figure>
                                                                    <div className="category-card-bottom">
                                                                        <h3>View Credit Limit Request</h3>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>  
                                                        <li>
                                                            <Link to ="credit-request">
                                                                <div className="category-card">
                                                                    <figure><i className="icon icon-credit-add"></i></figure>
                                                                    <div className="category-card-bottom">
                                                                        <h3>New Credit Limit Request</h3>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>  
                                                    </ul>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>: ''}
                                    {this.props.type === 'view-credits' ? <ViewCredits getRecordsPerPage={getRecordsPerPage} perPageLimit={perPageLimit} accountResponse={accountResponse} pagesResponse ={pagesResponse} viewCreditResponseList={viewCreditResponse} searchRequestForm={searchRequestForm} currentPage={currentPage}/> : ''}
                                    {this.props.type === 'credit-request' ? <NewCredit {...this.props} addCreditRequest={addCreditRequest} submitCreditForm={submitCreditForm} accountResponse={accountResponse} pagesResponse ={pagesResponse} /> : ''}                           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
        )
    }
}

export default (Credits)