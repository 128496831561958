/* external dependencies */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from "yup";

class LoginOtpComponent extends React.Component {
    
    enterPressed = (formikProps, event) => { //event is not passed from methd calling as its received by default when it was binded to our context
        var code = event.keyCode || event.which;
        if (code === 13) {
            formikProps.handleSubmit();
        }
    }

    otpValidator = yup.object().shape({
        otp: yup.string()
            .required('OTP is required')
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Must be exactly 6 digits')
            .max(6, 'Must be exactly 6 digits')
    });

    handleCancelClick = () => {
        this.props.handleComponentClass('');
    }

    hideAlertBox = (setErrors, setTouched) => {
        setErrors({})
        setTouched({}, false);
        const alertDivs = document.getElementsByClassName('alert');
        for (let i = 0; i < alertDivs.length; i++) {
            alertDivs[i].setAttribute('class', 'hide-alert');
        }
    }

    hideAlertBoxSuccess = () => {
        const alertDivs = document.getElementsByClassName('alert');
        for (let i = 0; i < alertDivs.length; i++) {
            alertDivs[i].setAttribute('class', 'hide-alert');
        }
    }

    hideAlertBoxError = () => {
        const alertDivs = document.getElementsByClassName('alert');
        for (let i = 0; i < alertDivs.length; i++) {
            alertDivs[i].setAttribute('class', 'hide-alert');
        }
    }
   
    renderForm = (formikProps) => {
        const self = this;
        const { handleSubmit, isSubmitting, isValid, errors, touched, setErrors, setTouched } = formikProps;
        const { errorMsg, successMsg, resend, otpSubmit, disableResendButton,countdown } = this.props;

        return (
            <div className="inner-login">
                <h2>Please Enter the OTP Code</h2>
                {errors.otp && touched.otp && setTimeout(() => setErrors({}), 5000) &&
                    <div className="alert alert-danger"><strong><i className="fa fa-exclamation-triangle"></i>{errors.otp}</strong>
                        <i className="fa fa-times" aria-hidden="true" onClick={() => self.hideAlertBox(setErrors, setTouched)}></i></div>
                }
                {errorMsg && <div className="alert alert-danger"><strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong>
                    <i className="fa fa-times" aria-hidden="true" onClick={() => self.hideAlertBoxError()}></i></div>
                }
                {successMsg &&  <div className="alert alert-success"> <strong> <i className="fa fa-check-circle"></i> {successMsg}</strong>
                    <i className="fa fa-times" aria-hidden="true" onClick={() => self.hideAlertBoxSuccess()}></i></div>}

                <div className="login_form" style={{paddingTop: '7px'}}>
                    <p>A One-Time Password (OTP) has been sent to your registered email address. Please enter the code below to verify your account:</p>
                    <div className="form-group">
                        <Field
                            type="text"
                            name="otp"
                            className="form-control" required
                            onKeyPress={this.enterPressed.bind(this, formikProps)} //this will not be received as an argument, it shows only the context to which our method is binded
                        />
                        <i className="fa fa-key"></i>
                        <label className="control-label">OTP</label>
                    </div>
                </div>
                <div className="login-btn">
                    <p className='otp-resend-otp'>
                        <span>{disableResendButton  && `Resend OTP in (${countdown}s)`}</span>
                    </p>
                    <button className="btn btn-dark loading-btn" disabled={disableResendButton} onClick={() => this.props.handleResendLoginSubmit(this.props.history.location)}>{resend === true ? 'Resending...' : 'resend'}</button>
                    <button
                        type="submit"
                        className="btn btn-green loading-btn"
                        disabled={isSubmitting || !isValid}
                        onClick={handleSubmit}>{otpSubmit === true ? '...' : 'Login'}
                    </button>
                </div>
            </div>
        );
    }

   

    render() {
        // let { productResponse, resetPassword, location } = this.props;
        const flipCardClass = 'show-reset-pass';
        return (
            <div className="login-send-otp">
                <div className="login-outer">
                    <Formik
                        initialValues={{ otp: '' }}
                        onSubmit={(values, actions) => this.props.handleVerifyOtp(values, actions, flipCardClass)}
                        render={this.renderForm}
                        validationSchema={this.otpValidator}
                    />
                </div>
            </div>
        );
    }
}

export default LoginOtpComponent;