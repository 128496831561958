import { apiFlightSearchResults, apiSendItinerariesEmail, apiGetFlightFareRuleDetails, apiGetFlightPriceDetails, apiKafilaFlightSearchResults, apiGetKafilaFlightFareRuleDetails, apiTripJackFlightSearchResults,apiGetTripJackFareRuleDetails, apiAirIQFlightSearchResults, apiGetAirIQFlightFareRuleDetails, apiGoflyFlightSearchResults, apiGetGoflyFlightFareRuleDetails, apieflyFlightSearchResults,apiGeteflyFlightFareRuleDetails,apiSatkarFlightSearchResults, apiGetSatkarFlightFareRuleDetails, apiRiyaFlightSearchResults, apiGetRiyaFlightFareRuleDetails, apiGetFareBoutiqueFlightFareRuleDetails, apiFareBoutiqueFlightSearchResults} from '../../api/flight';

export const FLIGHT_SEARCH_START = 'FLIGHT_SEARCH_START';
export const flightSearchStart = () => {
    return {
        type: FLIGHT_SEARCH_START
    };
};

export const FLIGHT_SEARCH_OK = 'FLIGHT_SEARCH_OK';
export const flightSearchOk = (output, searchFilters) => {
    return {
        type: FLIGHT_SEARCH_OK,
        output: output,
        searchFilters: searchFilters
    };
};

export const getFlightSearchResults = (payload, callback) => {
    return (dispatch, getState) => {
        dispatch(flightSearchStart());
        return apiFlightSearchResults(payload)
            .then((response) => {
                if (response.status_code === 200) {
                    dispatch(flightSearchOk(response.content, payload));
                    response && callback && callback(response.content.results);
                } else {
                    dispatch(flightSearchOk(response, payload));
                    response && callback && callback(response);
                }

            });
    }
};

export const ADVANCE_SEARCH_UPDATED = 'ADVANCE_SEARCH_UPDATED';
export const filterFlightResults = (filters) => {
    return {
        type: ADVANCE_SEARCH_UPDATED,
        advanceFilters: filters
    };
};


//Send email of multiple flights to user
export const ITINERARIES_EMAIL_START = 'ITINERARIES_EMAIL_START';
export const itinerariesEmailStart = () => {
    return {
        type: ITINERARIES_EMAIL_START
    };
};

export const ITINERARIES_EMAIL_OK = 'ITINERARIES_EMAIL_OK';
export const itinerariesEmailSuccess = (output) => {
    return {
        type: ITINERARIES_EMAIL_OK,
        output: output
    };
};

export const ITINERARIES_EMAIL_ERR = 'ITINERARIES_EMAIL_ERR';
export const itinerariesEmailError = (output) => {
    return {
        type: ITINERARIES_EMAIL_ERR,
        output: output
    };
};

export const sendItinerariesEmail = (payload,successCb,errorCb) => {
    return (dispatch, getState) => {
        dispatch(itinerariesEmailStart());
        return apiSendItinerariesEmail(payload)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(itinerariesEmailSuccess(response));
                    successCb && successCb(response);
                } else{
                    dispatch(itinerariesEmailError(response));
                    errorCb && errorCb(response);
                }
            });
    }
};

//Get flight fare rules details
export const FLIGHT_FARE_RULE_DETAILS_START = 'FLIGHT_FARE_RULE_DETAILS_START';
export const flightFareRuleDetailsStart = () => {
    return {
        type: FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const FLIGHT_FARE_RULE_DETAILS_OK = 'FLIGHT_FARE_RULE_DETAILS_OK';
export const flightFareRuleDetailsSuccess = (output) => {
    return {
        type: FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const FLIGHT_FARE_RULE_DETAILS_ERR = 'FLIGHT_FARE_RULE_DETAILS_ERR';
export const flightFareRuleDetailsError = (output) => {
    return {
        type: FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};

export const ROUND_FLIGHT_FARE_RULE_DETAILS_START = 'ROUND_FLIGHT_FARE_RULE_DETAILS_START';
export const roundFlightFareRuleDetailsStart = () => {
    return {
        type: ROUND_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const ROUND_FLIGHT_FARE_RULE_DETAILS_OK = 'ROUND_FLIGHT_FARE_RULE_DETAILS_OK';
export const roundFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const ROUND_FLIGHT_FARE_RULE_DETAILS_ERR = 'ROUND_FLIGHT_FARE_RULE_DETAILS_ERR';
export const roundFlightFareRuleDetailsError = (output) => {
    return {
        type: ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};
export const SET_FLIGHT_FARE_RULE_DETAILS = 'SET_FLIGHT_FARE_RULE_DETAILS';
export const setFlightFareRuleDetails = (response) => {
        return {
            type: SET_FLIGHT_FARE_RULE_DETAILS,
            response: response
        };
};
export const getFlightFareRuleDetails = (payload,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(roundFlightFareRuleDetailsStart());
        }else{
            dispatch(flightFareRuleDetailsStart());
        }
        return apiGetFlightFareRuleDetails(payload)
            .then((response) => {
                if(response){
                    if(index === 1){
                        dispatch(roundFlightFareRuleDetailsSuccess(response));
                    }else{
                        dispatch(flightFareRuleDetailsSuccess(response));
                    }
                } else{
                    if(index === 1){
                        dispatch(roundFlightFareRuleDetailsError(response));
                    }else{
                        dispatch(flightFareRuleDetailsError(response));
                    }
                    
                }
            });
    }
};


//Get flight price details for advance search
export const FLIGHT_PRICE_DETAILS_START = 'FLIGHT_PRICE_DETAILS_START';
export const flightPriceDetailsStart = () => {
    return {
        type: FLIGHT_PRICE_DETAILS_START
    };
};

export const FLIGHT_PRICE_DETAILS_OK = 'FLIGHT_PRICE_DETAILS_OK';
export const flightPriceDetailsSuccess = (output) => {
    return {
        type: FLIGHT_PRICE_DETAILS_OK,
        output: output
    };
};

export const FLIGHT_PRICE_DETAILS_ERR = 'FLIGHT_PRICE_DETAILS_ERR';
export const flightPriceDetailsError = (output) => {
    return {
        type: FLIGHT_PRICE_DETAILS_ERR,
        output: output
    };
};

export const getFlightPriceDetails = (payload) => {
    return (dispatch, getState) => {
        dispatch(flightPriceDetailsStart());
        return apiGetFlightPriceDetails(payload)
            .then((response) => {
                if(response.status === 'ok'){
                    dispatch(flightPriceDetailsSuccess(response));
                } else{
                    dispatch(flightPriceDetailsError(response));
                }
            });
    }
};

//reinitialize flight price details for advance search
export const REINITIALIZE_FLIGHT_PRICE_DETAILS_START = 'REINITIALIZE_FLIGHT_PRICE_DETAILS_START';
export const reInitializeFlightPriceDetailsStart = () => {
    return {
        type: REINITIALIZE_FLIGHT_PRICE_DETAILS_START
    };
};

export const reInitializeFlightPriceDetails = () => {
    return (dispatch, getState) => {
        dispatch(reInitializeFlightPriceDetailsStart());
    }
};

export const FLIGHT_SEARCH_KAFILA_START = 'FLIGHT_SEARCH_KAFILA_START';
export const flightSearchKafilaStart = () => {
    return {
        type: FLIGHT_SEARCH_KAFILA_START
    };
};


export const FLIGHT_SEARCH_KAFILA_OK = 'FLIGHT_SEARCH_KAFILA_OK';
export const flightSearchKafilaOk = (output, searchFilters) => {
    return {
        type: FLIGHT_SEARCH_KAFILA_OK,
        output: output,
        searchFilters: searchFilters
    };
};

export const getKafilaFlightSearchResults = (payload, callback) => {
    return (dispatch, getState) => {
        dispatch(flightSearchKafilaStart());
        return apiKafilaFlightSearchResults(payload)
            .then((response) => {
                dispatch(flightSearchKafilaOk(response, payload));
                response && callback && callback(response.results);
            });
    }
};

//Get Kafila flight fare rules details
export const KAFILA_FLIGHT_FARE_RULE_DETAILS_START = 'KAFILA_FLIGHT_FARE_RULE_DETAILS_START';
export const kafilaFlightFareRuleDetailsStart = () => {
    return {
        type: KAFILA_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const KAFILA_FLIGHT_FARE_RULE_DETAILS_OK = 'KAFILA_FLIGHT_FARE_RULE_DETAILS_OK';
export const kafilaFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: KAFILA_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const KAFILA_FLIGHT_FARE_RULE_DETAILS_ERR = 'KAFILA_FLIGHT_FARE_RULE_DETAILS_ERR';
export const kafilaFlightFareRuleDetailsError = (output) => {
    return {
        type: KAFILA_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};

export const KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_START = 'KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_START';
export const kafilaRoundFlightFareRuleDetailsStart = () => {
    return {
        type: KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_OK = 'KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_OK';
export const kafilaRoundFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR = 'KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR';
export const kafilaRoundFlightFareRuleDetailsError = (output) => {
    return {
        type: KAFILA_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};
export const SET_KAFILA_FLIGHT_FARE_RULE_DETAILS = 'SET_KAFILA_FLIGHT_FARE_RULE_DETAILS';
export const setKafilaFlightFareRuleDetails = (response) => {
        return {
            type: SET_KAFILA_FLIGHT_FARE_RULE_DETAILS,
            response: response
        };
};

export const getKafilaFlightFareRuleDetails = (payload,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(kafilaRoundFlightFareRuleDetailsStart());
        }else{
            dispatch(kafilaFlightFareRuleDetailsStart());
        }
        return apiGetKafilaFlightFareRuleDetails(payload)
            .then((response) => {
                if(response){
                    if(index === 1){
                        dispatch(kafilaRoundFlightFareRuleDetailsSuccess(response));
                    }else{
                        dispatch(kafilaFlightFareRuleDetailsSuccess(response));
                    }
                } else{
                    if(index === 1){
                        dispatch(kafilaRoundFlightFareRuleDetailsError(response));
                    }else{
                        dispatch(kafilaFlightFareRuleDetailsError(response));
                    }
                    
                }
            });
    }
};



export const FLIGHT_SEARCH_TRIPJACK_START = 'FLIGHT_SEARCH_TRIPJACK_START';
export const flightSearchTripJackStart = () => {
    return {
        type: FLIGHT_SEARCH_TRIPJACK_START
    };
};


export const FLIGHT_SEARCH_TRIPJACK_OK = 'FLIGHT_SEARCH_TRIPJACK_OK';
export const flightSearchTripJackOk = (output, searchFilters) => {
    return {
        type: FLIGHT_SEARCH_TRIPJACK_OK,
        output: output,
        searchFilters: searchFilters
    };
};
export const getTripJackFlightSearchResults = (payload) => {
    return (dispatch, getState) => {
        dispatch(flightSearchTripJackStart());
        return apiTripJackFlightSearchResults(payload)
            .then((response) => {
                dispatch(flightSearchTripJackOk(response, payload));
            });
    }
};

//Get flight fare rules details
export const TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_START = 'TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_START';
export const tripJackFlightFareRuleDetailsStart = () => {
    return {
        type: TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_OK = 'TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_OK';
export const tripJackFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_ERR = 'TRIP_JACK_FLIGHT_FARE_RULE_DETAILS_ERR';
export const tripJackFlightFareRuleDetailsError = (output) => {
    return {
        type: FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};

export const TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_START = 'TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_START';
export const tripJackRoundFlightFareRuleDetailsStart = () => {
    return {
        type: TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_OK = 'TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_OK';
export const tripJackRoundFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR = 'TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR';
export const tripJackRoundFlightFareRuleDetailsError = (output) => {
    return {
        type: TRIP_JACK_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};
export const SET_TRIP_JACK_FLIGHT_FARE_RULE_DETAILS = 'SET_TRIP_JACK_FLIGHT_FARE_RULE_DETAILS';
export const setTripJackFlightFareRuleDetails = (response) => {
        return {
            type: SET_TRIP_JACK_FLIGHT_FARE_RULE_DETAILS,
            response: response
        };
};
export const getTripJackFlightFareRuleDetails = (payload,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(tripJackRoundFlightFareRuleDetailsStart());
        }else{
            dispatch(tripJackFlightFareRuleDetailsStart());
        }
        return apiGetTripJackFareRuleDetails(payload)
            .then((response) => {
                if(response){
                    if(index === 1){
                        dispatch(tripJackRoundFlightFareRuleDetailsSuccess(response));
                    }else{
                        dispatch(tripJackFlightFareRuleDetailsSuccess(response));

                    }
                } else{
                    if(index === 1){
                        dispatch(tripJackRoundFlightFareRuleDetailsError(response));
                    }else{
                        dispatch(tripJackFlightFareRuleDetailsError(response));
                    }
                    
                }
            });
    }
};

export const SORT_RESULTS = 'SORT_RESULTS';
export const sortFlightResults = (filters) => {
    return {
        type: SORT_RESULTS,
        resultSorting: filters
    };
};


export const FLIGHT_SEARCH_AIRIQ_START = 'FLIGHT_SEARCH_AIRIQ_START';
export const flightSearchAirIQStart = () => {
    return {
        type: FLIGHT_SEARCH_AIRIQ_START
    };
};


export const FLIGHT_SEARCH_AIRIQ_OK = 'FLIGHT_SEARCH_AIRIQ_OK';
export const flightSearchAirIQOk = (output, searchFilters) => {
    return {
        type: FLIGHT_SEARCH_AIRIQ_OK,
        output: output,
        searchFilters: searchFilters
    };
};

export const getAirIQFlightSearchResults=(payload)=>{
    return (dispatch)=>{
        dispatch(flightSearchAirIQStart());
        return apiAirIQFlightSearchResults(payload)
        .then((response)=>{
            dispatch(flightSearchAirIQOk(response, payload));
        })
    }

}


//Get Air IQ flight fare rules details
export const AIRIQ_FLIGHT_FARE_RULE_DETAILS_START = 'AIRIQ_FLIGHT_FARE_RULE_DETAILS_START';
export const airIQFlightFareRuleDetailsStart = () => {
    return {
        type: AIRIQ_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const AIRIQ_FLIGHT_FARE_RULE_DETAILS_OK = 'AIRIQ_FLIGHT_FARE_RULE_DETAILS_OK';
export const airIQFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: AIRIQ_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const AIRIQ_FLIGHT_FARE_RULE_DETAILS_ERR = 'AIRIQ_FLIGHT_FARE_RULE_DETAILS_ERR';
export const airIQFlightFareRuleDetailsError = (output) => {
    return {
        type: AIRIQ_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};

export const AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_START = 'AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_START';
export const airIQRoundFlightFareRuleDetailsStart = () => {
    return {
        type: AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_OK = 'AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_OK';
export const airIQRoundFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR = 'AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR';
export const airIQRoundFlightFareRuleDetailsError = (output) => {
    return {
        type: AIRIQ_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};
export const SET_AIRIQ_FLIGHT_FARE_RULE_DETAILS = 'SET_AIRIQ_FLIGHT_FARE_RULE_DETAILS';
export const setAirIQFlightFareRuleDetails = (response) => {
        return {
            type: SET_AIRIQ_FLIGHT_FARE_RULE_DETAILS,
            response: response
        };
};
export const getAirIQFlightFareRuleDetails = (payload,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(airIQRoundFlightFareRuleDetailsStart());
        }else{
            dispatch(airIQFlightFareRuleDetailsStart());
        }
        return apiGetAirIQFlightFareRuleDetails(payload)
            .then((response) => {
                if(response){
                    if(index === 1){
                        dispatch(airIQRoundFlightFareRuleDetailsSuccess(response));
                    }else{
                        dispatch(airIQFlightFareRuleDetailsSuccess(response));

                    }
                } else{
                    if(index === 1){
                        dispatch(airIQRoundFlightFareRuleDetailsError(response));
                    }else{
                        dispatch(airIQFlightFareRuleDetailsError(response));
                    }
                    
                }
            });
    }
};

// Gofly 
export const FLIGHT_SEARCH_GOFLY_START = 'FLIGHT_SEARCH_GOFLY_START';
export const flightSearchGoflyStart = () => {
    return {
        type: FLIGHT_SEARCH_GOFLY_START
    };
};


export const FLIGHT_SEARCH_GOFLY_OK = 'FLIGHT_SEARCH_GOFLY_OK';
export const flightSearchGoflyOk = (output, searchFilters) => {
    return {
        type: FLIGHT_SEARCH_GOFLY_OK,
        output: output,
        searchFilters: searchFilters
    };
};

export const getGoflyFlightSearchResults=(payload)=>{
    return (dispatch)=>{
        dispatch(flightSearchGoflyStart());
        return apiGoflyFlightSearchResults(payload)
        .then((response)=>{
            dispatch(flightSearchGoflyOk(response, payload));
        })
    }

}

//Get Gofly flight fare rules details
export const GOFLY_FLIGHT_FARE_RULE_DETAILS_START = 'GOFLY_FLIGHT_FARE_RULE_DETAILS_START';
export const goflyFlightFareRuleDetailsStart = () => {
    return {
        type: GOFLY_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const GOFLY_FLIGHT_FARE_RULE_DETAILS_OK = 'GOFLY_FLIGHT_FARE_RULE_DETAILS_OK';
export const goflyFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: GOFLY_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const GOFLY_FLIGHT_FARE_RULE_DETAILS_ERR = 'GOFLY_FLIGHT_FARE_RULE_DETAILS_ERR';
export const goflyFlightFareRuleDetailsError = (output) => {
    return {
        type: GOFLY_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};

export const GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_START = 'GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_START';
export const goflyRoundFlightFareRuleDetailsStart = () => {
    return {
        type: GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_OK = 'GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_OK';
export const goflyRoundFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR = 'GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR';
export const goflyRoundFlightFareRuleDetailsError = (output) => {
    return {
        type: GOFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};
export const SET_GOFLY_FLIGHT_FARE_RULE_DETAILS = 'SET_GOFLY_FLIGHT_FARE_RULE_DETAILS';
export const setGoflyFlightFareRuleDetails = (response) => {
        return {
            type: SET_GOFLY_FLIGHT_FARE_RULE_DETAILS,
            response: response
        };
};
export const getGoflyFlightFareRuleDetails = (payload,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(goflyRoundFlightFareRuleDetailsStart());
        }else{
            dispatch(goflyFlightFareRuleDetailsStart());
        }
        return apiGetGoflyFlightFareRuleDetails(payload)
            .then((response) => {
                if(response){
                    if(index === 1){
                        dispatch(goflyRoundFlightFareRuleDetailsSuccess(response));
                    }else{
                        dispatch(goflyFlightFareRuleDetailsSuccess(response));

                    }
                } else{
                    if(index === 1){
                        dispatch(goflyRoundFlightFareRuleDetailsError(response));
                    }else{
                        dispatch(goflyFlightFareRuleDetailsError(response));
                    }
                    
                }
            });
    }
};

// GET Efly Flight Search Details
export const FLIGHT_SEARCH_EFLY_START = 'FLIGHT_SEARCH_EFLY_START';
export const flightSearcheflyStart = () => {
    return {
        type: FLIGHT_SEARCH_EFLY_START
    };
};


export const FLIGHT_SEARCH_EFLY_OK = 'FLIGHT_SEARCH_EFLY_OK';
export const flightSearcheflyOk = (output, searchFilters) => {
    return {
        type: FLIGHT_SEARCH_EFLY_OK,
        output: output,
        searchFilters: searchFilters
    };
};

export const geteflyFlightSearchResults=(payload)=>{
    return (dispatch)=>{
        dispatch(flightSearcheflyStart());
        return apieflyFlightSearchResults(payload)
        .then((response)=>{
            dispatch(flightSearcheflyOk(response, payload));
        })
    }

}

//Get efly flight fare rules details
export const EFLY_FLIGHT_FARE_RULE_DETAILS_START = 'EFLY_FLIGHT_FARE_RULE_DETAILS_START';
export const eflyFlightFareRuleDetailsStart = () => {
    return {
        type: EFLY_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const EFLY_FLIGHT_FARE_RULE_DETAILS_OK = 'EFLY_FLIGHT_FARE_RULE_DETAILS_OK';
export const eflyFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: EFLY_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const EFLY_FLIGHT_FARE_RULE_DETAILS_ERR = 'EFLY_FLIGHT_FARE_RULE_DETAILS_ERR';
export const eflyFlightFareRuleDetailsError = (output) => {
    return {
        type: EFLY_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};

export const EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_START = 'EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_START';
export const eflyRoundFlightFareRuleDetailsStart = () => {
    return {
        type: EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_OK = 'EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_OK';
export const eflyRoundFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR = 'EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR';
export const eflyRoundFlightFareRuleDetailsError = (output) => {
    return {
        type: EFLY_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};
export const SET_EFLY_FLIGHT_FARE_RULE_DETAILS = 'SET_EFLY_FLIGHT_FARE_RULE_DETAILS';
export const seteflyFlightFareRuleDetails = (response) => {
        return {
            type: SET_EFLY_FLIGHT_FARE_RULE_DETAILS,
            response: response
        };
};
export const geteflyFlightFareRuleDetails = (payload,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(eflyRoundFlightFareRuleDetailsStart());
        }else{
            dispatch(eflyFlightFareRuleDetailsStart());
        }
        return apiGeteflyFlightFareRuleDetails(payload)
            .then((response) => {
                if(response){
                    if(index === 1){
                        dispatch(eflyRoundFlightFareRuleDetailsSuccess(response));
                    }else{
                        dispatch(eflyFlightFareRuleDetailsSuccess(response));

                    }
                } else{
                    if(index === 1){
                        dispatch(eflyRoundFlightFareRuleDetailsError(response));
                    }else{
                        dispatch(eflyFlightFareRuleDetailsError(response));
                    }
                    
                }
            });
    }
};

// satkar search api start================================================================

export const FLIGHT_SEARCH_SATKAR_START = 'FLIGHT_SEARCH_SATKAR_START';
export const flightSearchSatkarStart = () => {
    return {
        type: FLIGHT_SEARCH_SATKAR_START
    };
};


export const FLIGHT_SEARCH_SATKAR_OK = 'FLIGHT_SEARCH_SATKAR_OK';
export const flightSearchSatkarOk = (output, searchFilters) => {
    return {
        type: FLIGHT_SEARCH_SATKAR_OK,
        output: output,
        searchFilters: searchFilters
    };
};
export const FLIGHT_SEARCH_SATKAR_ERR = 'FLIGHT_SEARCH_SATKAR_ERR';
export const flightSearchSatkarErr = (output, searchFilters) => {
    return {
        type: FLIGHT_SEARCH_SATKAR_ERR,
        output: output,
        searchFilters: searchFilters
    };
};
export const getSatkarFlightSearchResults = (payload) => {
    return (dispatch, getState) => {
        dispatch(flightSearchSatkarStart());
        return apiSatkarFlightSearchResults(payload)
            .then((response) => {
                dispatch(flightSearchSatkarOk(response, payload));
            });
    }
};

//Get Satkar flight fare rules details
export const SATKAR_FLIGHT_FARE_RULE_DETAILS_START = 'SATKAR_FLIGHT_FARE_RULE_DETAILS_START';
export const satkarFlightFareRuleDetailsStart = () => {
    return {
        type: SATKAR_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const SATKAR_FLIGHT_FARE_RULE_DETAILS_OK = 'SATKAR_FLIGHT_FARE_RULE_DETAILS_OK';
export const satkarFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: SATKAR_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const SATKAR_FLIGHT_FARE_RULE_DETAILS_ERR = 'SATKAR_FLIGHT_FARE_RULE_DETAILS_ERR';
export const satkarFlightFareRuleDetailsError = (output) => {
    return {
        type: SATKAR_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};

export const SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_START = 'SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_START';
export const satkarRoundFlightFareRuleDetailsStart = () => {
    return {
        type: SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_OK = 'SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_OK';
export const satkarRoundFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR = 'SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR';
export const satkarRoundFlightFareRuleDetailsError = (output) => {
    return {
        type: SATKAR_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};
export const SET_SATKAR_FLIGHT_FARE_RULE_DETAILS = 'SET_SATKAR_FLIGHT_FARE_RULE_DETAILS';
export const setSatkarFlightFareRuleDetails = (response) => {
        return {
            type: SET_SATKAR_FLIGHT_FARE_RULE_DETAILS,
            response: response
        };
};
export const getSatkarFlightFareRuleDetails = (payload,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(satkarRoundFlightFareRuleDetailsStart());
        }else{
            dispatch(satkarFlightFareRuleDetailsStart());
        }
        return apiGetSatkarFlightFareRuleDetails(payload)
            .then((response) => {
                if(response){
                    if(index === 1){
                        dispatch(satkarRoundFlightFareRuleDetailsSuccess(response));
                    }else{
                        dispatch(satkarFlightFareRuleDetailsSuccess(response));

                    }
                } else{
                    if(index === 1){
                        dispatch(satkarRoundFlightFareRuleDetailsSuccess(response));
                    }else{
                        dispatch(satkarFlightFareRuleDetailsSuccess(response));
                    }
                    
                }
            });
    }
};


//===========================riya search api start==================================
export const FLIGHT_SEARCH_RIYA_START = 'FLIGHT_SEARCH_RIYA_START';
export const flightSearchRiyaStart = () => {
    return {
        type: FLIGHT_SEARCH_RIYA_START
    };
};


export const FLIGHT_SEARCH_RIYA_OK = 'FLIGHT_SEARCH_RIYA_OK';
export const flightSearchRiyaOk = (output, searchFilters) => {
    return {
        type: FLIGHT_SEARCH_RIYA_OK,
        output: output,
        searchFilters: searchFilters
    };
};

export const getRiyaFlightSearchResults = (payload) => {
    return (dispatch, getState) => {
        dispatch(flightSearchRiyaStart());
        return apiRiyaFlightSearchResults(payload)
            .then((response) => {
                dispatch(flightSearchRiyaOk(response, payload));
            });
    }
};

//===================Get Riya flight fare rules details=============================================
export const RIYA_FLIGHT_FARE_RULE_DETAILS_START = 'RIYA_FLIGHT_FARE_RULE_DETAILS_START';
export const riyaFlightFareRuleDetailsStart = () => {
    return {
        type: RIYA_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const RIYA_FLIGHT_FARE_RULE_DETAILS_OK = 'RIYA_FLIGHT_FARE_RULE_DETAILS_OK';
export const riyaFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: RIYA_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const RIYA_FLIGHT_FARE_RULE_DETAILS_ERR = 'RIYA_FLIGHT_FARE_RULE_DETAILS_ERR';
export const riyaFlightFareRuleDetailsError = (output) => {
    return {
        type: RIYA_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};

export const RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_START = 'RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_START';
export const riyaRoundFlightFareRuleDetailsStart = () => {
    return {
        type: RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_OK = 'RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_OK';
export const riyaRoundFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR = 'RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR';
export const riyaRoundFlightFareRuleDetailsError = (output) => {
    return {
        type: RIYA_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};
export const SET_RIYA_FLIGHT_FARE_RULE_DETAILS = 'SET_RIYA_FLIGHT_FARE_RULE_DETAILS';
export const setRiyaFlightFareRuleDetails = (response) => {
        return {
            type: SET_RIYA_FLIGHT_FARE_RULE_DETAILS,
            response: response
        };
};
export const getRiyaFlightFareRuleDetails = (payload,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(riyaRoundFlightFareRuleDetailsStart());
        }else{
            dispatch(riyaFlightFareRuleDetailsStart());
        }
        return apiGetRiyaFlightFareRuleDetails(payload)
            .then((response) => {
                if(response){
                    if(index === 1){
                        dispatch(riyaRoundFlightFareRuleDetailsSuccess(response));
                    }else{
                        dispatch(riyaFlightFareRuleDetailsSuccess(response));

                    }
                } else{
                    if(index === 1){
                        dispatch(riyaRoundFlightFareRuleDetailsError(response));
                    }else{
                        dispatch(riyaFlightFareRuleDetailsError(response));
                    }
                    
                }
            });
    }
};

//Fare Boutique
 
export const FLIGHT_SEARCH_FAREBOUTIQUE_START = 'FLIGHT_SEARCH_FAREBOUTIQUE_START';
export const flightSearchFareBoutiqueStart = () => {
    return {
        type: FLIGHT_SEARCH_FAREBOUTIQUE_START
    };
};


export const FLIGHT_SEARCH_FAREBOUTIQUE_OK = 'FLIGHT_SEARCH_FAREBOUTIQUE_OK';
export const flightSearchFareBoutiqueOk = (output, searchFilters) => {
    return {
        type: FLIGHT_SEARCH_FAREBOUTIQUE_OK,
        output: output,
        searchFilters: searchFilters
    };
};

export const getFareBoutiqueFlightSearchResults=(payload)=>{
  
    return (dispatch)=>{
        
        dispatch(flightSearchFareBoutiqueStart());
        return apiFareBoutiqueFlightSearchResults(payload)
        
        .then((response)=>{
           
            dispatch(flightSearchFareBoutiqueOk(response, payload));
        })
       
    }

}


//Get Fare Boutique fare rules details
export const FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS_START = 'FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS_START';
export const fareBoutiqueFlightFareRuleDetailsStart = () => {
    return {
        type: FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS_OK = 'FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS_OK';
export const fareBoutiqueFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS_ERR = 'FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS_ERR';
export const fareBoutiqueFlightFareRuleDetailsError = (output) => {
    return {
        type: FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};

export const FAREBOUTIQUE_ROUND_FLIGHT_FARE_RULE_DETAILS_START = 'FAREBOUTIQUE_ROUND_FLIGHT_FARE_RULE_DETAILS_START';
export const fareBoutiqueRoundFlightFareRuleDetailsStart = () => {
    return {
        type: FAREBOUTIQUE_ROUND_FLIGHT_FARE_RULE_DETAILS_START
    };
};

export const FAREBOUTIQUE_ROUND_FLIGHT_FARE_RULE_DETAILS_OK = 'FAREBOUTIQUE_ROUND_FLIGHT_FARE_RULE_DETAILS_OK';
export const fareBoutiqueRoundFlightFareRuleDetailsSuccess = (output) => {
    return {
        type: FAREBOUTIQUE_ROUND_FLIGHT_FARE_RULE_DETAILS_OK,
        output: output
    };
};

export const FAREBOUTIQUE_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR = 'FAREBOUTIQUE_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR';
export const fareBoutiqueRoundFlightFareRuleDetailsError = (output) => {
    return {
        type: FAREBOUTIQUE_ROUND_FLIGHT_FARE_RULE_DETAILS_ERR,
        output: output
    };
};
export const SET_FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS = 'SET_FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS';
export const setFareBoutiqueFlightFareRuleDetails = (response) => {
        return {
            type: SET_FAREBOUTIQUE_FLIGHT_FARE_RULE_DETAILS,
            response: response
        };
};
export const getFareBoutiqueFlightFareRuleDetails = (payload,index) => {
    return (dispatch, getState) => {
        if(index === 1){
            dispatch(fareBoutiqueRoundFlightFareRuleDetailsStart());
        }else{
            dispatch(fareBoutiqueFlightFareRuleDetailsStart());
        }
        return apiGetFareBoutiqueFlightFareRuleDetails(payload)
            .then((response) => {
                if(response){
                    if(index === 1){
                        dispatch(fareBoutiqueRoundFlightFareRuleDetailsSuccess(response));
                    }else{
                        dispatch(fareBoutiqueFlightFareRuleDetailsSuccess(response));

                    }
                } else{
                    if(index === 1){
                        dispatch(fareBoutiqueRoundFlightFareRuleDetailsError(response));
                    }else{
                        dispatch(fareBoutiqueFlightFareRuleDetailsError(response));
                    }
                    
                }
            });
    }
};