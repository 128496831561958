import React, { Component } from 'react';
import AgentLeftBar from '../../agent/AgentLeftBarComponent';
import AgentHeaderNavigation from '../../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../../agent/AgentCommonHeaderComponent';
import history from "../../../shared/history";
import Link from 'react-router-dom/es/Link';
import AddVendorBookingComponent from './AddVendorBookingComponent';
import { Formik } from 'formik';
import * as yup from "yup";
import ViewVendorComponent from './ViewVendorComponent';
import ViewVendorBookingDetailComponent from './ViewVendorBookingDetailComponent';
import { dateDifference, getNextDate } from '../../../shared/commonHelper';

class VendorComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideMsg: false,
            leftNavActive: '/vendor',
            url: history.location.pathname,
            editSuccess : false,
            editerror   :false,
            showConfirmationPopup : false,
            deleteVendorId : null,
            deleteSuccess : null,
            addResponse : null,
            activeForm: 'initial'
        }
    }

    handleActiveForm = (formType) => {
        this.setState({activeForm: formType})
    }

    handleConfimationAction = () =>{
		this.setState({
			showConfirmationPopup : false
        });

        const successCb = () =>{
            this.setState({
                deleteSuccess : true
            },() => {
                this.props.viewVendorBookings();
                setTimeout(
                    function() {
                        this.setState({deleteSuccess: false});
                    }
                    .bind(this),
                    3000
                )
            })
        }
        this.props.deleteVendorBookings(this.state.deleteVendorId,successCb);
    }

    handleConfirmationPopup = (event,vendor_id)=>{
        this.setState({
            showConfirmationPopup : event,
            deleteVendorId        : vendor_id
        })		
    }

    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }

    addVendorBookings = (values) => {
        let blackOutArray = [];
        if(values["BlackFromDate0"] && values["BlackToDate0"]){
            for (let i = 0; i < values.blackOutCount; i++) {
                blackOutArray.push(
                    {
                        "blackout_from" : values["BlackFromDate" + i],
                        "blackout_to"   : values["BlackToDate" + i]
                    }
                )
            }
        }

        let availableSeatArray = [];
        for (let seatAvailable = 0; seatAvailable < values.seatAllocationCount; seatAvailable++) {
            availableSeatArray.push(
                {
                    "travel_date_from": values["startDate" + seatAvailable],
			        "travel_date_to": values["endDate" + seatAvailable],
			        "seats_available": values["seatsCount" + seatAvailable],
                    "adult_tax": values["adultTax" + seatAvailable],
                    "child_base_fare": values["childBasic" + seatAvailable],
                    "adult_base_fare": values["adultBasic" + seatAvailable],
                    "child_tax": values["childTax" + seatAvailable],
                    "infant_base_fare": values["infantBasic" + seatAvailable],
                    "infant_tax": values["infantTax" + seatAvailable]
                }
            )
        }

        let segments = [];
        for (let i = 0; i < values.segmentCount; i++) {
            segments.push(
                {
                    "vendor_stoppage_id": values["stoppage_id" + i],
                    "airline_code": values["airlines" + i],
                    "departure": values["departHour" + i]+':'+values["departMin" + i]+':00',
                    "arrival": values["arrivalHour" + i]+':'+values["arrivalMin" + i]+':00',
                    "trip_type": "onward",
                    "b_class" : values["booking_class" + i],
                    "aircraft" : values["craft" + i],
                    "origin": values["segmentOrigin" + i],
                    "destination": values["segmentDest" + i],
                    "flight_no": values["flight_no" + i],
                    "baggage": values["baggage" + i],
                    "is_arrival_next_day": values["isArrivalNextDay" + i],
                    "origin_terminal": values["origin_terminal" + i],
                    "destination_terminal": values["destination_terminal" + i]
                }
            );
        }
        if(parseInt(values.journey_type) === 2){
            for (let i = 0; i < values.returnSegmentCount; i++) {
                segments.push(
                    {
                        "vendor_stoppage_id": values["stoppage_idreturn" + i],
                        "airline_code": values["airlinesreturn" + i],
                        "departure": values["departHourreturn" + i]+':'+values["departMinreturn" + i]+':00',
                        "arrival": values["arrivalHourreturn" + i]+':'+values["arrivalMinreturn" + i]+':00',
                        "trip_type": "return",
                        "b_class" : values["booking_classreturn" + i],
                        "aircraft" : values["craftreturn" + i],
                        "origin": values["segmentOriginreturn" + i],
                        "destination": values["segmentDestreturn" + i],
                        "flight_no": values["flight_noreturn" + i],
                        "baggage": values["baggagereturn" + i],
                        "is_arrival_next_day": values["isArrivalNextDayreturn" + i],
                        "origin_terminal": values["origin_terminalreturn" + i],
                        "destination_terminal": values["destination_terminalreturn" + i]
                    }
                );
            }
        }
        if(this.props.location.state){
            let deletedSegemts = [];
            for(let outer = 0 ; outer < this.props.location.state.data.segments.length; outer++){
                let outerData = this.props.location.state.data.segments[outer];
                let stoppage_flag = false;
                for(let inner = 0; inner < segments.length;inner++){
                    if(segments[inner].vendor_stoppage_id === outerData.vendor_stoppage_id){    
                        stoppage_flag = true;
                    }
                }
                if(!stoppage_flag){
                    deletedSegemts.push({
                        "vendor_stoppage_id" : outerData.vendor_stoppage_id,
                        "status" : 'D'
                    });
                }
            }
            if(deletedSegemts.length > 0){
                segments = segments.concat(deletedSegemts);
            }
        }

        let submitData = {
            "is_domestic": values.is_domestic,
            "journey_type": values.journey_type,
            "pnr": values.pnr,
            "origin": values.origin,
            "destination": values.destination,
            "is_refundable": values.is_refundable,
            "cabin_class": values.cabin_class,
            "fare_rule_details":values.fareRules,
            "booking_date_from": values.validFromDate,
            "booking_date_to": values.validToDate,
            "travel_date_from": values.travelFromDate,
            "travel_date_to": values.travelToDate,
            "blackouts": blackOutArray,
            "segments": segments,
            "booking_seats" : availableSeatArray
        }
        
        const actionCb = (response) => {
            window.scrollTo(0,0);
            this.setState({
                addResponse : response
            },() => {
                setTimeout(
                    function() {
                        this.setState({addResponse: false, activeForm : 'initial'});

                    }
                    .bind(this),
                    3000
                )
            })
        }
        const successCb = () =>{
            window.scrollTo(0,0);
            this.setState({
                editSuccess : true
            },() => {
                setTimeout(
                    function() {
                        this.setState({editSuccess: false});
                    }
                    .bind(this),
                    3000
                )
            })
        }
        const errorCb = (response) =>{
            window.scrollTo(0,0);
            this.setState({
                editerror : response.message
            },() =>{
                setTimeout(
                    function() {
                        this.setState({editerror: false});
                    }
                    .bind(this),
                    3000
                )
            })
        }
        if(this.props.type === 'editVendor'){
            this.props.editVendorBookings(this.props.location.state.data.vendor_id,submitData,successCb,errorCb);
        }else{
            this.props.addVendorBookings(submitData,actionCb);
        }
    }

    intitialValues = (values) => {
        let onwardSegment = [];
        let returnSegment = [];
        let travelDateFrom = '';
        let travelDateTo = '';
        if(values){
            for(let i = 0;i<values.segments.length;i++){
                if(values.segments[i].trip_type === 'onward'){
                    onwardSegment.push(values.segments[i]);
                }else{
                    returnSegment.push(values.segments[i]);
                }
            }
        
            travelDateFrom = values.travel_date_from;
            if(!travelDateFrom && values.seat_info && values.seat_info.length > 0 && values.seat_info[0].travel_date_from){
                travelDateFrom = values.seat_info[0].travel_date_from;
            }
            travelDateTo = values.travel_date_to;
            let lastIndex = 0;
            if(values.seat_info && values.seat_info.length > 0)
            {
                lastIndex = (values.seat_info.length) - 1;
            }
            if(!travelDateTo && values.seat_info && values.seat_info.length > 0 && values.seat_info[lastIndex].travel_date_to){
                travelDateTo = values.seat_info[lastIndex].travel_date_to;
            }
        }
        let initialValues = {
            is_domestic         :values?values.is_domestic:"",
            journey_type        :values?values.journey_type:"",
            pnr                 :values?values.pnr:"",
            origin              :values?values.origin:"",
            destination         :values?values.destination:"",
            cabin_class         :values?values.cabin_class:"",
            validFromDate       :values?values.booking_date_from:"",
            validToDate         :values?values.booking_date_to:"", 
            travelFromDate      :values?travelDateFrom:"", 
            travelToDate        :values?travelDateTo:"", 
            is_refundable       :values?values.is_refundable:0,
            segmentCount        :values?onwardSegment.length:1,
            returnSegmentCount  :values?returnSegment.length:1,
            fareRules           :values?values.fare_rule_details:"",
            blackOutCount       :(values && values.blackouts) ? values.blackouts.length : 1,
            seatsCount          :(values && values.seatsCount) ? values.seatsCount : 0,
            startDate0          :(values && values.startDate0) ? values.startDate0 : '',
            endDate0            :(values && values.endDate0) ? values.endDate0 : '',
            seatsCount0         :(values && values.seatsCount0) ? values.seatsCount0 : '',
            adultBasic          :(values && values.adult_base_fare) ? values.adult_base_fare : 0,
            adultTax            :(values && values.adult_tax) ? values.adult_tax : 0,
            childBasic          :(values && values.child_base_fare) ? values.child_base_fare : 0,
            childTax            :(values && values.child_tax) ? values.child_tax : 0,
            infantBasic         :(values && values.infant_base_fare) ? values.infant_base_fare : 0,
            infantTax           :(values && values.infant_tax) ? values.infant_tax : 0,
            seatAllocationCount : 1,
            enableAllDates      : false
        }; 


        if(!values) // If add new vendor booking
        {
                initialValues.airlines0             ="";
                initialValues.flight_no0            ="";
                initialValues.segmentOrigin0        ="";
                initialValues.segmentDest0          ="";
                initialValues.departHour0           ="";
                initialValues.departMin0            ="";
                initialValues.arrivalHour0          ="";
                initialValues.arrivalMin0           ="";
                initialValues.airlinesreturn0       ="";
                initialValues.flight_noreturn0      ="";
                initialValues.segmentOriginreturn0  ="";
                initialValues.segmentDestreturn0    ="";
                initialValues.departMinreturn0      ="";
                initialValues.departHourreturn0     ="";
                initialValues.arrivalHourreturn0    ="";
                initialValues.arrivalMinreturn0     ="";
                initialValues.baggage0              ="";
                initialValues.baggagereturn0        ="";
                initialValues.isArrivalNextDay0     ="N";
                initialValues.startDate0            ="";
                initialValues.endDate0              ="";
                initialValues.seatsCount0           ="";
                initialValues.adultBasic0           ="";
                initialValues.adultTax0             ="";
                initialValues.childBasic0           ="";
                initialValues.childTax0             ="";
                initialValues.infantBasic0          ="";
                initialValues.infantTax0            ="";
                initialValues.seatAllocationCount   = 1;
                initialValues.origin_terminal0      = "";
                initialValues.destination_terminal0 = "";
        }else{// If edit any vendor booking
            if(values.black_out && values.black_out.length > 0) {
                for(let i = 0; i<values.black_out.length; i++) {
                    initialValues['BlackFromDate'+i]  = values.black_out[i][0];
                    initialValues['BlackToDate'+i]  = values.black_out[i][1];
                }
            }
            //For onward segments
            if(onwardSegment.length > 0){
                for(let onward = 0; onward < onwardSegment.length ; onward++){
                    let departDate = onwardSegment[onward].departure.split(':');
                    let arrivalDate = onwardSegment[onward].arrival.split(':');
                    initialValues['stoppage_id'+onward]  = onwardSegment[onward].vendor_stoppage_id;
                    initialValues['airlines'+onward]  = onwardSegment[onward].airline_code;
                    initialValues['flight_no'+onward] = onwardSegment[onward].flight_no;
                    initialValues['segmentOrigin'+onward] = onwardSegment[onward].origin;
                    initialValues['segmentDest'+onward]   = onwardSegment[onward].destination;
                    initialValues['departHour'+onward]    = departDate[0];
                    initialValues['departMin'+onward]     = departDate[1];
                    initialValues['arrivalHour'+onward]   = arrivalDate[0];
                    initialValues['arrivalMin'+onward]    = arrivalDate[1];
                    initialValues['booking_class'+onward] = onwardSegment[onward].b_class ? onwardSegment[onward].b_class : '';
                    initialValues['craft'+onward]         = onwardSegment[onward].aircraft ? onwardSegment[onward].aircraft : '';
                    initialValues['baggage'+onward]       = onwardSegment[onward].baggage ? onwardSegment[onward].baggage : '';
                    initialValues['origin_terminal'+onward] = onwardSegment[onward].origin_terminal ? onwardSegment[onward].origin_terminal : '';
                    initialValues['destination_terminal'+onward] = onwardSegment[onward].destination_terminal ? onwardSegment[onward].destination_terminal : '';
                }
            }
            //For return segments
            if(returnSegment.length > 0){
                for(let retunS = 0; retunS < returnSegment.length ; retunS++){
                    let departDate = returnSegment[retunS].departure.split(':');
                    let arrivalDate = returnSegment[retunS].arrival.split(':');
                    initialValues['stoppage_idreturn'+retunS]   = returnSegment[retunS].vendor_stoppage_id;
                    initialValues['airlinesreturn'+retunS]      = returnSegment[retunS].airline_code;
                    initialValues['flight_noreturn'+retunS]     = returnSegment[retunS].flight_no;
                    initialValues['segmentOriginreturn'+retunS] = returnSegment[retunS].origin;
                    initialValues['segmentDestreturn'+retunS]   = returnSegment[retunS].destination;
                    initialValues['departHourreturn'+retunS]    = departDate[0];
                    initialValues['departMinreturn'+retunS]     = departDate[1];
                    initialValues['arrivalHourreturn'+retunS]   = arrivalDate[0];
                    initialValues['arrivalMinreturn'+retunS]    = arrivalDate[1];
                    initialValues['booking_classreturn'+retunS] = returnSegment[retunS].b_class ? returnSegment[retunS].b_class : '';
                    initialValues['craftreturn'+retunS]         = returnSegment[retunS].aircraft ? returnSegment[retunS].aircraft : '';
                    initialValues['baggagereturn'+retunS]       = returnSegment[retunS].baggage ? returnSegment[retunS].baggage : '';
                    initialValues['isArrivalNextDayreturn'+retunS] = returnSegment[retunS].isArrivalNextDay ? returnSegment[retunS].isArrivalNextDay : '';
                    initialValues['stoppage_id'+retunS]         = returnSegment[retunS].vendor_stoppage_id;
                    initialValues['origin_terminalreturn'+retunS]     = returnSegment[retunS].origin_terminal ? returnSegment[retunS].origin_terminal : '';
                    initialValues['destination_terminalreturn'+retunS] = returnSegment[retunS].destination_terminal ? returnSegment[retunS].destination_terminal : '';
                }
            }
            //for Black out period 
            if(values.blackouts.length > 0){
                for(let blackout = 0; blackout < values.blackouts.length ; blackout++){
                    initialValues['BlackFromDate'+blackout]   = values.blackouts[blackout].blackout_from;
                    initialValues['BlackToDate'+blackout]      = values.blackouts[blackout].blackout_to;
                }
            }
            
            if(values.seat_info.length > 0)
            {
                for(let allSeats = 0; allSeats < values.seat_info.length; allSeats++){
                    initialValues['startDate'+allSeats]   = values.seat_info[allSeats].travel_date_from;
                    initialValues['endDate'+allSeats]     = values.seat_info[allSeats].travel_date_to;
                    initialValues['seatsCount'+allSeats]  = values.seat_info[allSeats].seat_available;
                    initialValues['adultBasic'+allSeats]  = values.seat_info[allSeats].adult_base_fare;
                    initialValues['adultTax'+allSeats]    = values.seat_info[allSeats].adult_tax;
                    initialValues['childBasic'+allSeats]  = values.seat_info[allSeats].child_base_fare;
                    initialValues['childTax'+allSeats]    = values.seat_info[allSeats].child_tax;
                    initialValues['infantBasic'+allSeats] = values.seat_info[allSeats].infant_base_fare;
                    initialValues['infantTax'+allSeats]   = values.seat_info[allSeats].infant_tax;
                }
            }
        }
        return initialValues;
    }

    saveDateWiseSeat = (values,actions,formState) => {
        let differenceInDate = dateDifference(values.travelFromDate,values.travelToDate);
        let newDate = values.travelFromDate;
        actions.setFieldValue('seatAllocationCount',(parseInt(differenceInDate) + 1));
        for(let i = 0; i <= differenceInDate; i++)
        {
            if(newDate.includes("T"))
            {
                var datewithoutT = newDate.split('T');
                newDate = datewithoutT[0];
            }
            let seats = values["seatsCount"+i] ? values["seatsCount"+i] : values.seatsCount;
            let adultFare = values["adultBasic"+i] ? values["adultBasic"+i] : values.adultBasic;
            let adultTax = values["adultTax"+i] ? values["adultTax"+i] : values.adultTax;
            let childBasic = values["childBasic"+i] ? values["childBasic"+i] : values.childBasic;
            let childTax = values["childTax"+i] ? values["childTax"+i] : values.childTax;
            let infantBasic = values["infantBasic"+i] ? values["infantBasic"+i] : values.infantBasic;
            let infantTax = values["infantTax"+i] ? values["infantTax"+i] : values.infantTax;

            actions.setFieldValue('startDate'+i,newDate+'T:00:00:00');
            actions.setFieldValue('endDate'+i,newDate+'T:23:59:59');
            actions.setFieldValue('seatsCount'+i,seats);
            actions.setFieldValue('adultBasic'+i,adultFare);
            actions.setFieldValue('adultTax'+i,adultTax);
            actions.setFieldValue('childBasic'+i,childBasic);
            actions.setFieldValue('childTax'+i,childTax);
            actions.setFieldValue('infantBasic'+i,infantBasic);
            actions.setFieldValue('infantTax'+i,infantTax);
            newDate = getNextDate(newDate);
        }
        this.handleActiveForm(formState);
    }

    render() {
        const { accountResponse, productResponse } = this.props;
        let activeLeftNavigation = this.state.url;
        //Intital values
        let initialValues = '';
        if(this.props.location.pathname === '/edit-vendor-booking'){
            initialValues = this.intitialValues(this.props.location.state.data);
        }else{
            initialValues = this.intitialValues(null);
        }
        //Validation schema
        const validationSchema = yup.object()
            .shape({
                is_domestic     :yup.string().required(),
                journey_type    :yup.string().required(),
                pnr             :yup.string().required(),
                origin          :yup.string().required(),
                destination     :yup.string().required(),
                //adultBasic      :yup.number().required(),
                //adultTax        :yup.number().required(),
                //childBasic      :yup.number().required(),
                //childTax        :yup.number().required(),
                //infantBasic     :yup.number().required(),
                //infantTax       :yup.number().required(),
                airlines0       :yup.string().required(),
                flight_no0      :yup.string().required(),
                segmentOrigin0  :yup.string().required(),
                segmentDest0    :yup.string().required(),
                departHour0     :yup.string().required(),
                departMin0      :yup.string().required(),
                arrivalMin0     :yup.string().required(),
                arrivalHour0    :yup.string().required(),
                validFromDate   :yup.string().required(),
                validToDate     :yup.string().required(),
                travelFromDate  :yup.string().required(),
                travelToDate    :yup.string().required(),
                booking_class0  :yup.string().required(),
                fareRules       :yup.string().required(),
                //if round trip is selected then both round trip first segment will be mandatory
                airlinesreturn0 :yup.string()
                                .when("journey_type", {
                                    is: '2',
                                    then: yup.string().required(),
                                }),
                flight_noreturn0:yup.string()
                                .when("journey_type", {
                                    is: '2',
                                    then: yup.string().required(),
                                }),
                segmentOriginreturn0:yup.string()
                                .when("journey_type", {
                                    is: '2',
                                    then: yup.string().required(),
                                }),
                segmentDestreturn0:yup.string()
                                .when("journey_type", {
                                    is: '2',
                                    then: yup.string().required(),
                                }),
                departMinreturn0:yup.string()
                                .when("journey_type", {
                                    is: '2',
                                    then: yup.string().required(),
                                }),
                departHourreturn0:yup.string()
                                .when("journey_type", {
                                    is: '2',
                                    then: yup.string().required(),
                                }),
                arrivalHourreturn0:yup.string()
                                .when("journey_type", {
                                    is: '2',
                                    then: yup.string().required(),
                                }),
                arrivalMinreturn0:yup.string()
                                .when("journey_type", {
                                    is: '2',
                                    then: yup.string().required(),
                                })
            });

        
        const seatAllocationValidation = yup.object()
        .shape({
            seatsCount      :yup.string().required(),
            adultBasic      :yup.number().required(),
            adultTax        :yup.number().required(),
            childBasic      :yup.number().required(),
            childTax        :yup.number().required(),
            infantBasic     :yup.number().required(),
            infantTax       :yup.number().required(),
        })
        
        return (
            <React.Fragment>
                {/*nav-section Start*/}
                <AgentHeaderNavigation productResponseList={productResponse} {...this.props} />
                {/*nav-section end*/}
                <section className="mid-wrapper">
                    <AgentCommonHeader {...this.props} />
                    <div className="my-account-info">
                        <div className="container">
                            <div className="my-account-sec">
                                <div className="my-account-outer">
                                    <AgentLeftBar handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} logoutAction={this.props.logoutAction} />
                                    {this.props.type === 'vendor' &&
                                        <div className="profile-detail-info">
                                            <div className="edit-profile-bar">
                                                <div className="pull-right">
                                                    { /* eslint-disable-next-line */}
                                                    <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                                </div>
                                            </div>
                                            <div className="tabs-container-detail">
                                                <div className="mark-up-info">
                                                    <div className="tabs-top">
                                                        <div className="tabs-title">
                                                            <h2><i className="icon icon-money"></i><span>Vendor</span></h2>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="category-list updates-list">
                                                        <ul>
                                                            <li>
                                                                <Link to="view-vendor-bookings">
                                                                    <div className="category-card">
                                                                        <figure><i className="icon icon-view-deposit"></i></figure>
                                                                        <div className="category-card-bottom">
                                                                            <h3>View Vendor Bookings</h3>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="add-vendor-booking">
                                                                    <div className="category-card">
                                                                        <figure><i className="icon icon-deposit-request"></i></figure>
                                                                        <div className="category-card-bottom">
                                                                            <h3>New Vendor Booking</h3>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.props.type === 'viewVendor' && <ViewVendorComponent  {...this.props} handleConfirmationPopup={this.handleConfirmationPopup} deleteSuccess={this.state.deleteSuccess}/>}
                                    {this.props.type === 'viewDetail' && <ViewVendorBookingDetailComponent  {...this.props} />}
                                    {(this.props.type === 'addVendor' || this.props.type === 'editVendor') &&
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={(values,actions) => {
                                                if(this.state.activeForm === 'initial')
                                                {
                                                    this.handleActiveForm('seatsAllocation');
                                                }else if(this.state.activeForm === 'seatsAllocation')
                                                {
                                                    this.saveDateWiseSeat(values,actions,'seatsAllocationListing');
                                                }
                                                else
                                                {
                                                    this.addVendorBookings(values);
                                                }
                                            }}
                                            validationSchema={(this.state.activeForm === 'initial') ? validationSchema : seatAllocationValidation}
                                            render={formikProps => <AddVendorBookingComponent  {...formikProps} {...this.props} {...this.state} handleActiveForm={this.handleActiveForm} />}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`popup-crad confirmation-popup ${this.state.showConfirmationPopup ? 'show' : ''}`}>
                        <div className="popup-outer">
                            <div className="popup-content">
                                <div className="popup-head">
                                    <h2>Booking Confirmation</h2>
                                    <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false, null)} /></div>
                                </div>
                                <div className="popup-body popup-scorll">
                                    <div className="inner-confirmation">
                                        <p>Are you sure you want to continue?</p>
                                        <div className="popup-footer">
                                            <button className="btn btn-orange" onClick={() => this.handleConfimationAction()}>Yes</button>
                                            <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false, null)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup-overlay" />
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default VendorComponent