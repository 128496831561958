/* external dependencies */
import React from 'react';
import FlightSearchHeaderComponent from '../flights/FlightSearchHeaderComponent';
import HotelSearchHeaderComponent from '../hotel/HotelSearchHeaderComponent';
import AgentHeaderNavigationComponent from '../agent/AgentHeaderNavigationComponent';
import AgentCommonHeaderComponent from '../agent/AgentCommonHeaderComponent';
import AgentLeftBarComponent from '../agent/AgentLeftBarComponent';
import history from "../../shared/history";

class BookingError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leftNavActive: '/booking-calendar',
            url: history.location.pathname,
        }
    }

    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }
    componentDidMount(){
        if(this.props.location && this.props.location.state && this.props.location.state.pageUrl === "my-bookings"){
            this.props.history.listen((location,action)=>{
                if(action==='POP'){
                    this.props.history.push({
                        pathname: "/my-bookings"
                    });
                }
            })
        }
    }

     errorHandlerFunc=()=>{
        try {
            return this.props.location.state.response && this.props.location.state.response.length ? JSON.parse(this.props.location && this.props.location.state && this.props.location.state.response) : (this.props.location.state && this.props.location.state.response)
        } catch (error) {
            return this.props.location.state.response
        }
    }

    render() {
        let errorMessageResponse=this.errorHandlerFunc()
        let activeLeftNavigation = this.state.url;
        if(this.props.location.state.page === 'agent')
        {
        
            return (
                <React.Fragment>
                {/*nav-section Start*/}
                <AgentHeaderNavigationComponent productResponseList={this.props.location.state.productResponse} agentProductAccess={this.props.location.state.agentProductAccess} {...this.props}/>
                {/*nav-section end*/}
                <section className="mid-wrapper">
                    <AgentCommonHeaderComponent {...this.props.location.state} />
                    <div className="my-account-info">
                        <div className="container">
                            <div className="my-account-sec">
                                <div className="my-account-outer">
                                    <AgentLeftBarComponent handleClickValue={this.handleClickValue.bind(this)} accountResponse={this.props.location.state.accountResponse} activeLeftNavigation={activeLeftNavigation} />
                                    <div className="profile-detail-info">
                                        <div className="edit-profile-bar">
                                            <div className="pull-right">
                                                { /* eslint-disable-next-line */}
                                                <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                            </div>
                                        </div>
                                        <div className="error-wrap">
                                            <div className="error-wrap-outer">
                                                <div className="review-step-title">
                                                    <div className="cols pull-left"><i className="icon icon-notification" /><span>Error Found!</span> </div>
                                                </div>
                                                <div className="block">
                                                    <div className="no-error-box">
                                                        <figure><img src="assets/images/error-img.svg" alt="" /></figure>
                                                        <p>{
                                                            typeof errorMessageResponse === "object" ? <p>{this.props.location&&this.props.location.state&& Object.keys(errorMessageResponse) && Object.keys(errorMessageResponse).length>0 &&errorMessageResponse.message}</p> : 
                                                            <p>{errorMessageResponse && errorMessageResponse}</p>  
                                                        }</p>
                                                        <p>Go to
                                                        { /* eslint-disable-next-line */}
                                                        <a className="text-anchor" href="javascript:void(0);" onClick={() => {
                                                            this.props.history.push({
                                                                pathname: "/flight-search"
                                                            });
                                                        }}> Search Page</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
            );
        }
        else if(this.props.location.state.page === 'hotel-book')
        {
            return(
                <React.Fragment>
                    <HotelSearchHeaderComponent {...this.props.location.state} pageType="booking" />
                    <section className="mid-wrapper">
                        <div className="container">
                            <div className="review-booking-wrap">
                                <div className="error-wrap">
                                    <div className="error-wrap-outer">
                                        <div className="review-step-title">
                                            <div className="cols pull-left"><i className="icon icon-notification" /><span>Error Found!</span> </div>
                                        </div>
                                        <div className="block">
                                            <div className="no-error-box">
                                                <figure><img src="assets/images/error-img.svg" alt="" /></figure>
                                                <p>{
                                                    typeof errorMessageResponse === "object" ? <p>{this.props.location&&this.props.location.state&& Object.keys(errorMessageResponse) && Object.keys(errorMessageResponse).length>0 &&errorMessageResponse.message}</p> : 
                                                    <p>{errorMessageResponse && errorMessageResponse}</p>  
                                                }</p>
                                                <p>Go to
                                                    { /* eslint-disable-next-line */}
                                                    <a className="text-anchor" href="javascript:void(0);" onClick={() => {
                                                        this.props.history.push({
                                                            pathname: "/hotel-search"
                                                        });
                                                    }}> Search Page</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }

        else if (typeof errorMessageResponse === "object" && errorMessageResponse &&errorMessageResponse&&
            errorMessageResponse.message && errorMessageResponse.message.includes('You do not have enough balance to make this booking'))
             {

            return (
                <>
                    <FlightSearchHeaderComponent {...this.props.location.state} pageType="booking" />


                    <section className="mid-wrapper">
                        <div className="container">
                            <div className="review-booking-wrap">
                                <div className="error-wrap">
                                    <div className="error-wrap-outer">
                                        <div className="review-step-title">
                                            <div className="cols pull-left"><i className="icon icon-notification" /><span>Error Found!</span> </div>
                                        </div>
                                        <div className="block">
                                            <div className="no-error-box">
                                                <figure><img src="assets/images/error-img.svg" alt="" /></figure>

                                                <p>{errorMessageResponse &&errorMessageResponse.message &&errorMessageResponse.message}</p>
                                                {
                                                    errorMessageResponse ?
                                                        <p>Go to
                                                            { /* eslint-disable-next-line */}
                                                            <a className="text-anchor" href="javascript:void(0);" onClick={() => {
                                                                this.props.history.push({
                                                                    pathname: "/my-bookings"
                                                                });
                                                            }}> My Booking</a></p> :
                                                        <p>Go to
                                                            { /* eslint-disable-next-line */}
                                                            <a className="text-anchor" href="javascript:void(0);" onClick={() => {
                                                                this.props.history.push({
                                                                    pathname: "/flight-search"
                                                                });
                                                            }}> Search Page</a></p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </>
            )
        }
        else 
        {
            return(
                <React.Fragment>
                    <FlightSearchHeaderComponent {...this.props.location.state} pageType="booking" />
                    <section className="mid-wrapper">
                        <div className="container">
                            <div className="review-booking-wrap">
                                <div className="error-wrap">
                                    <div className="error-wrap-outer">
                                        <div className="review-step-title">
                                            <div className="cols pull-left"><i className="icon icon-notification" /><span>Error Found!</span> </div>
                                        </div>
                                        <div className="block">
                                            <div className="no-error-box">
                                                <figure><img src="assets/images/error-img.svg" alt="" /></figure>
                                                {
                                                    typeof errorMessageResponse === "object" ? <p>{this.props.location&&this.props.location.state&& Object.keys(errorMessageResponse) && Object.keys(errorMessageResponse).length>0 &&errorMessageResponse.message}</p> : 
                                                    <p>{errorMessageResponse && errorMessageResponse}</p>  
                                                }
                                                
                                                {
                                                        this.props.location&&this.props.location.state&& typeof errorMessageResponse === "object" && errorMessageResponse &&
                                                        Object.keys(errorMessageResponse) && Object.keys(errorMessageResponse).length>0 &&errorMessageResponse && errorMessageResponse.message && errorMessageResponse.message.includes('The price has been changed for this booking. Your booking is under process. Kindly do not book it again, For assistance call our helpdesk') ?
                                                        <p>Go to
                                                            { /* eslint-disable-next-line */}
                                                            <a className="text-anchor" href="javascript:void(0);" onClick={() => {
                                                                this.props.history.push({
                                                                    pathname: "/my-bookings"
                                                                });
                                                            }}> My Booking</a></p> :
                                                            <p>Go to
                                                            { /* eslint-disable-next-line */}
                                                            <a className="text-anchor" href="javascript:void(0);" onClick={() => {
                                                                this.props.history.push({
                                                                    pathname: "/flight-search"
                                                                });
                                                            }}> Search Page</a></p>
                                                        }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }


        
    }
}

export default BookingError;