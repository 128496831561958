/* external dependencies */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from "yup";

class RailwayLoginComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            isRememberMe: false,
            inputType: 'password',
            eyeIcon: 'icon icon-eye',
            username: '',
            password: ''
        };
    }

    /* create our form validator */
    loginValidator = yup.object()
        .shape({
            username: yup.string()
                .required('Username/Password is required.'),
            password: yup.string()
                .required('Username/Password is required.')
        });

    //This function will be called on click of eye icon in password fields (if input type is text than set it to password and vice versa)
    showHidePassword() {
        this.setState({
            inputType: this.state.inputType === 'text' ? 'password' : 'text',
            eyeIcon: this.state.inputType === 'text' ? 'icon icon-eye' : 'icon icon-eye-blocked'
        })
    }
    enterPressed = (formikProps, event) => { //event is not passed from method calling as its received by default when it was binded to our context
        var code = event.keyCode || event.which;
        if (code === 13) {
            formikProps.handleSubmit();
        }
    }
    onChange(e, formikProps) {
        this.setState({ [e.target.name]: e.target.value })
        formikProps.setFieldValue([e.target.name], e.target.value);
    }
    renderForm = (formikProps) => {
        const { handleSubmit, errors, touched, values } = formikProps;
        const { errorMsg, successMsg } = this.props;
        return (
            <div className="inner-login">
                <h2>Secure Login</h2>
                {((errors.username && touched.username) || (errors.password && touched.password)) && <div className="alert alert-danger"> <strong><i className="fa fa-exclamation-triangle"></i>{errors.username ? errors.username : errors.password}</strong></div>}
                {errorMsg && <div className="alert alert-danger"> <strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong> <i className="fa fa-times" aria-hidden="true"></i></div>}
                {successMsg && <div className="alert alert-success"> <strong> <i className="fa fa-check-circle"></i>  You have logged in Successfully.</strong>  </div>}
                <div className="login_form">
                    <div className="form-group">
                        <Field
                            type="text"
                            name="username"
                            className="form-control" required
                            value={values.username}
                            onKeyPress={this.enterPressed.bind(this, formikProps)} //this will not be received as an argument, it shows only the context to which our method is binded
                            onBlur={(value) => this.onChange(value, formikProps)}
                        />
                        <i className="material-icons">person</i>
                        <label className="control-label"> Username</label>
                    </div>
                    <div className="form-group">
                        <Field
                            type={this.state.inputType}
                            name="password"
                            className="form-control" required
                            value={values.password}
                            onKeyPress={this.enterPressed.bind(this, formikProps)} //this will not be received as an argument, it shows only the context to which our method is binded
                            onBlur={(value) => this.onChange(value, formikProps)}
                        />
                        <i className="material-icons">lock</i>
                        <label className="control-label"> Password</label>
                        <span className="password-show-hide" onClick={() => this.showHidePassword()} >
                            { /* eslint-disable-next-line */}
                            <a href="javascript:void(0)"><i className={this.state.eyeIcon}></i></a>
                        </span>
                    </div>
                </div>
                <div className="form-login-button">
                    <div className="remember-me">
                    </div>
                    <div className="login-btn">
                        <button
                            type="submit"
                            className={`btn btn-green ${values.username && values.password ? "" : "disabled"}`}
                            onClick={handleSubmit}>Login</button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let { productResponse } = this.props;
        return (
            <section className="mid-wrapper">
                <div className="container">
                    <div className="login-container">
                        <div className="login-area">
                            <div className="our-services">
                                <ul>
                                    {
                                        productResponse.response && productResponse.response.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <i className="">
                                                        <img src={item.product_image} alt="" /></i>
                                                    <h3>{item.product_name}</h3>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="login-block login-wcard">
                                <div className="flip-card ">
                                    <div className="flip-card-inner">
                                        {/*Login Form Start*/}
                                        <div className="login-card">
                                            <div className="login-outer">
                                                <Formik
                                                    initialValues={{
                                                        username: '',
                                                        password: ''
                                                    }}
                                                    onSubmit={(values, actions) => this.props.handleLoginSubmit(values, actions)}
                                                    validationSchema={this.loginValidator}
                                                    render={this.renderForm}
                                                />
                                            </div>
                                        </div>
                                        {/*Login Form End*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default RailwayLoginComponent;