import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';

class Calender extends Component {
  constructor(props) {
    super(props);
  }

  calenderEvent = (event) => {
    const start_date = moment(event.startStr).format('YYYY-MM-DD');
    const end_date = moment(event.endStr).format('YYYY-MM-DD');
    this.props.getCalendarDetail({ start_date,end_date });
  };

  handleEventClick = (items) => {
    let calEvent = items.event.extendedProps;
    if (calEvent.productId === 1) {
      const bookingDetails = {
        data: calEvent.data,
        pageUrl: 'booking-calendar',
      };
      sessionStorage.setItem('booking-details', JSON.stringify(bookingDetails));
      window.open('/booking', '_blank');
    } else if (calEvent.productId === 3) {
      const bookingDetails = {
        booking_id: calEvent.data.booking_id,
        pageUrl: 'booking-calendar',
      };
      localStorage.setItem('hotel-booking-details', JSON.stringify(bookingDetails));
      window.open('/hotel-booking', '_blank');
    }
  };

  renderEventContent = (eventInfo) => (
    <>
      {eventInfo.event.extendedProps.productId === 1 ? (
        <>
          {' '}
          <img src={eventInfo.event.extendedProps.imageurl} width='16' height='16' />
          {eventInfo.event.extendedProps.sectors} <samp>PNR:{eventInfo.event.extendedProps.paxInfo}</samp>
        </>
      ) : (
        <>
          {' '}
          <img src={eventInfo.event.extendedProps.imageurl} width='16' height='16' />
          {eventInfo.event.extendedProps.sectors} <samp>Rooms:{eventInfo.event.extendedProps.paxInfo}</samp>
        </>
      )}
      <i>{eventInfo.event.title}</i>
    </>
  );

  componentDidMount() {
    const prevButton = document.querySelector('.fc-prev-button');
    const prevButtonYear = document.querySelector('.fc-prevYear-button');
    const spanPrev = document.querySelector('.fc-icon-chevron-left');
    const spanNext = document.querySelector('.fc-icon-chevron-right');
    const spanPrevYear = document.querySelector('.fc-icon-chevrons-left');
    const spanNextYear = document.querySelector('.fc-icon-chevrons-right');

    if (spanPrev) {
      spanPrev.classList.remove('fc-icon', 'fc-icon-chevron-left');
    }
    if (spanNext) {
      spanNext.classList.remove('fc-icon', 'fc-icon-chevron-right');
    }
    if (spanPrevYear) {
      spanPrevYear.classList.remove('fc-icon', 'fc-icon-chevrons-left');
    }
    if (spanNextYear) {
      spanNextYear.classList.remove('fc-icon', 'fc-icon-chevrons-right');
    }
    if (prevButton) {
      prevButton.innerHTML = 'Prev';
    }
    if (prevButtonYear) {
      prevButtonYear.innerHTML = 'Prev Year';
    }

    const nextButton = document.querySelector('.fc-next-button');
    const nextButtonYear = document.querySelector('.fc-nextYear-button');

    if (nextButton) {
      nextButton.innerHTML = 'Next';
    }
    if (nextButtonYear) {
      nextButtonYear.innerHTML = 'Next Year';
    }
  }

  render() {
    return (
      <>
        <div className='demo-app lets'>
          <div className='demo-app-main'>
            <FullCalendar
              plugins={[dayGridPlugin]}
              headerToolbar={{
                left: 'prev,next,prevYear,nextYear today',
                center: 'title',
                right: 'dayGridMonth,dayGridWeek,dayGridDay',
              }}
              initialView='dayGridMonth'
              eventLimit={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              events={this.props.events}
              eventContent={this.renderEventContent}
              eventClick={this.handleEventClick}
              datesSet={(e) => this.calenderEvent(e)}
            />
            <div className="loader content-loader" style={{ display: this.props.calendarResponse.loading ? "block" : "none" }}><div className="loader-content"><div className="loader-ring blue-ring"> <span></span> </div></div></div>
          </div>
        </div>
      </>
    );
  }
}

export default Calender;

