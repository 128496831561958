import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import 'react-dates/initialize';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates'
import { Formik } from "formik";
import Pagination from '../../shared/Pagination';
import { Alert } from 'reactstrap';
// import ReceiptVoucherComponent from '../../../components/agent/deposits/ReceiptVoucherComponent';


class ViewDeposits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment(),
            focused: false,
            createdAt: moment(),
            calendarFocused: false,
        }
    }
    onDateChange = (createdAt) => {
        this.setState(() => ({ createdAt }));
    }
    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused }))
    }
    changeDateTimeFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }
    changeDateFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }

    printReceiptVoucher = (event, item) => {
        // this code will open a new tab with print option and writing the content from specified div innetHTML
        let data = {
            item: item,
            depositRequestResponse: null,
            parent: null
        }
        localStorage.setItem("print-receipt-voucher", JSON.stringify(data));
        window.open('/print-receipt-voucher', '_blank');
        // let myWindow=window.open('','',''); 
        // myWindow.document.write(document.getElementById(divID).innerHTML);

        // myWindow.document.close();
        // myWindow.focus();
        // myWindow.print();
        // myWindow.close();
        // return true;
    }

    renderForm = (formikProps) => {
        const { focused, date, createdAt } = this.state;
        const { setFieldValue, handleSubmit } = formikProps;
        let diffTime = Math.abs(new Date(createdAt) - new Date(date));
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return (
            <div className="search-report-form view-deposit">
                {
                    diffDays > 31 ? <Alert color="danger">You can search deposite of maximum 31 days.</Alert> : ''
                }
                <div className="form-row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="form-group">
                            <label className="control-label">START DATE:</label>
                            <div className="input-group input-addon single-calendar">
                                {
                                    <SingleDatePicker
                                        date={this.state.createdAt}
                                        placeholder={"START DATE"}
                                        onDateChange={createdAt => {
                                            this.setState({ createdAt });
                                            setFieldValue("start_date", this.changeDateFormat(createdAt));
                                        }}
                                        focused={this.state.calendarFocused}
                                        onFocusChange={this.onFocusChange}
                                        id="start_date"
                                        displayFormat="DD-MM-YYYY"
                                        readOnly={true}
                                        showDefaultInputIcon={true}
                                        inputIconPosition={"after"}
                                        isOutsideRange={() => false}
                                        hideKeyboardShortcutsPanel={true}
                                        reopenPickerOnClearDate={true}
                                        withPortal={true}
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                        <div className="form-group">
                            <label className="control-label">END DATE:</label>
                            <div className="input-group input-addon single-calendar" >
                                <SingleDatePicker
                                    date={date}
                                    placeholder={"END DATE"}
                                    onDateChange={date => {
                                        this.setState({ date });
                                        setFieldValue("end_date", this.changeDateFormat(date));
                                    }}
                                    focused={focused}
                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                    id="end_date"
                                    displayFormat="DD-MM-YYYY"
                                    readOnly={true}
                                    showDefaultInputIcon={true}
                                    inputIconPosition={"after"}
                                    isOutsideRange={() => false}
                                    hideKeyboardShortcutsPanel={true}
                                    reopenPickerOnClearDate={true}
                                    withPortal={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="btn-block text-right">
                        {diffDays && diffDays > 31 ?
                            <button type="button" className="btn btn-sky-blue" onClick={handleSubmit} disabled>Search</button>
                            :
                            <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>Search</button>
                        }
                    </div>

                </div>
            </div>
        )
    }

    initialValues = {
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
    }

    render() {
        const { date, createdAt } = this.state;
        const { depositResponseList, pagesResponse, accountResponse, perPageLimit, currentPage } = this.props;
        let diffTime = Math.abs(new Date(createdAt) - new Date(date));
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return (
            <div className="profile-detail-info">
                <div className="edit-profile-bar">
                    <div className="pull-right">
                        { /* eslint-disable-next-line */}
                        <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn">
                            <i className="icon icon-menu"></i>Profile Menus</a>
                    </div>
                </div>
                <div className="tabs-container-detail">
                    <div className="statement-info">
                        <div className="tabs-top">
                            <div className="tabs-title">
                                <div className="pull-right"> <Link to="deposit-request" className="sm-btn blue-sm-btn">New Deposit Request</Link> </div>
                                <h2><i className="icon icon-money"></i><span>My Deposit Requests</span></h2>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: pagesResponse.response && pagesResponse.response.pages['view_deposit-request'].page_description }} />
                        </div>

                        <div className="statement-outer">
                            <form>
                                <Formik
                                    onSubmit={(values) => this.props.searchRequestForm(values)}
                                    render={this.renderForm}
                                    initialValues={this.initialValues}
                                />
                            </form>
                            <div className="balance-sheet-info">
                                <div className="balance-table">
                                    <div className="table-outer">
                                        <div className="table-container">
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Amount</th>
                                                        <th>Deposit Type</th>
                                                        <th>Deposit On</th>
                                                        <th>Beneficiary Bank</th>
                                                        <th>Processed Status</th>
                                                        <th>Processed Date and Time</th>
                                                        <th>Processed Remarks</th>
                                                        <th>Remarks</th>
                                                        <th>Receipt Voucher</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {(accountResponse.depositRequestResponse.loading === false) ?
                                                        (depositResponseList && depositResponseList.length > 0 && diffDays <= 31 ? depositResponseList.map((item, key) => {
                                                            var processedStatus;
                                                            var statusClass = '';
                                                            if (item.processed_status && item.processed_status === 'D') {
                                                                processedStatus = 'Decline';
                                                            } else if (item.processed_status && item.processed_status === 'P') {
                                                                processedStatus = 'Pending';
                                                            }
                                                            else if (item.processed_status && item.processed_status === 'A') {
                                                                processedStatus = 'Approved';
                                                                statusClass = 'green-text';
                                                            }
                                                            var recordCount = perPageLimit * (currentPage - 1);
                                                            return (
                                                                <tr key={key}>
                                                                    <td>{key + 1 + recordCount}</td>
                                                                    <td>{item.amount.toLocaleString()}</td>
                                                                    <td>{item.deposit_type}</td>
                                                                    <td>{item.date_deposit}</td>
                                                                    <td>{item.beneficiary_bank_name !== null ? item.beneficiary_bank_name : '-'}</td>
                                                                    <td className={statusClass}>{processedStatus}</td>
                                                                    <td>{item.processed_date !== null ? item.processed_date : '-'}</td>
                                                                    <td>
                                                                        {
                                                                            item.processed_remarks !== null
                                                                                ?
                                                                                <React.Fragment>
                                                                                    { /* eslint-disable-next-line */}
                                                                                    <a className="tooltip-info" href="#" data-tooltip={item.processed_remarks}>
                                                                                        <i className="icon icon-info"></i></a>
                                                                                </React.Fragment>
                                                                                :
                                                                                '-'
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.remarks
                                                                                ?
                                                                                <React.Fragment>
                                                                                    { /* eslint-disable-next-line */}
                                                                                    <a className="tooltip-info" href="javascript:void(0);" data-tooltip={item.remarks}><i className="icon icon-info"></i></a>
                                                                                </React.Fragment>
                                                                                :
                                                                                "-"
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {/* <div id={'print-voucher-'+key} style={{display:'none'}}>
                                                                        <ReceiptVoucherComponent receiptDetails={item} />
                                                                    </div> */}
                                                                        {
                                                                            item.processed_status && item.processed_status === 'A'
                                                                                ?
                                                                                <React.Fragment>
                                                                                    { /* eslint-disable-next-line */}
                                                                                    <span className="tooltip-info green-text" href="javascript:void(0);" onClick={(event) => this.printReceiptVoucher(event, item)} data-tooltip={'Print Receipt Voucher'}>
                                                                                        <i className="fa fa-print" aria-hidden="true"></i>
                                                                                    </span>
                                                                                </React.Fragment>
                                                                                :
                                                                                "-"
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }) : <tr><td colSpan="10"><span className="no-records">There is no deposit request found.</span></td></tr>
                                                        ) : <tr><td colSpan="10"><div className="loader relative-loader" style={{ "display": "block" }}><div className="loader-content"><div className="loader-ring blue-ring"> <span></span> </div></div></div></td></tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {
                                    diffDays <= 31 ?
                                        <div className="table-footer">
                                            <Pagination
                                                totalRecord={accountResponse.depositRequestResponse.total_count}
                                                pageLimit={perPageLimit}
                                                activePage={currentPage}
                                                getRecordsPerPage={(value) => this.props.getRecordsPerPage(value)} />
                                        </div>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (ViewDeposits)