import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import 'react-dates/initialize';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates'
import { Formik } from "formik";
import Pagination from '../../shared/Pagination';
import { currencyFormat } from '../../../shared/commonHelper';
import { Alert } from 'reactstrap';

class ViewCredits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment(),
            focused: false,
            createdAt: moment(),
            calendarFocused: false,
            dataValue: null
        }
    }
    onDateChange = (createdAt) => {
        this.setState(() => ({ createdAt }));
    }
    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused }))
    }
    changeDateTimeFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }
    changeDateFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }

    renderForm = (formikProps) => {
        const { focused, date, createdAt } = this.state;
        const { handleSubmit, setFieldValue } = formikProps;
        let diffTime = Math.abs(new Date(createdAt) - new Date(date));
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return (
            <div className="search-report-form">
                {
                    diffDays > 31 ? <Alert color="danger">You can search credits of maximum 31 days.</Alert> : ''
                }
                <div className="form-row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="form-group">
                            <label className="control-label">START DATE:</label>
                            <div className="input-group input-addon single-calendar">
                                {
                                    <SingleDatePicker
                                        date={this.state.createdAt}
                                        placeholder={"START DATE"}
                                        onDateChange={createdAt => {
                                            this.setState({ createdAt });
                                            setFieldValue("start_date", this.changeDateFormat(createdAt));
                                        }}
                                        focused={this.state.calendarFocused}
                                        onFocusChange={this.onFocusChange}
                                        id="start_date"
                                        displayFormat="DD-MM-YYYY"
                                        readOnly={true}
                                        showDefaultInputIcon={true}
                                        isOutsideRange={() => false}
                                        inputIconPosition={"after"}
                                        hideKeyboardShortcutsPanel={true}
                                        reopenPickerOnClearDate={true}
                                        withPortal={true}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                        <div className="form-group">
                            <label className="control-label">END DATE:</label>
                            <div className="input-group input-addon single-calendar" >
                                <SingleDatePicker
                                    date={date}
                                    placeholder={"END DATE"}
                                    onDateChange={date => {
                                        this.setState({ date });
                                        setFieldValue("end_date", this.changeDateFormat(date));
                                    }}
                                    focused={focused}
                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                    id="end_date"
                                    displayFormat="DD-MM-YYYY"
                                    readOnly={true}
                                    showDefaultInputIcon={true}
                                    isOutsideRange={() => false}
                                    inputIconPosition={"after"}
                                    hideKeyboardShortcutsPanel={true}
                                    reopenPickerOnClearDate={true}
                                    withPortal={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="btn-block text-right">
                        {diffDays && diffDays > 31 ?
                            <button type="button" className="btn btn-sky-blue" onClick={handleSubmit} disabled>Search</button>
                            :
                            <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>Search</button>
                        }
                    </div>
                </div>
            </div>
        )
    }

    initialValues = {
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
    }

    render() {
        const { date, createdAt } = this.state;
        const { accountResponse, pagesResponse, perPageLimit, currentPage } = this.props;
        let diffTime = Math.abs(new Date(createdAt) - new Date(date));
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return (
            <div className="profile-detail-info">
                <div className="edit-profile-bar">
                    <div className="pull-right">
                        { /* eslint-disable-next-line */}
                        <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn">
                            <i className="icon icon-menu"></i>Profile Menus</a>
                    </div>
                </div>
                <div className="tabs-container-detail">
                    <div className="statement-info">
                        <div className="tabs-top">
                            <div className="tabs-title">
                                <div className="pull-right"> <Link to="credit-request" className="sm-btn blue-sm-btn">New Credit Request</Link> </div>
                                <h2><i className="icon icon-currency"></i><span>MY CREDIT LIMIT REQUESTS</span></h2>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: pagesResponse.response && pagesResponse.response.pages['view_credit-request'].page_description }} />
                        </div>

                        <div className="statement-outer">
                            <Formik
                                onSubmit={(values) => this.props.searchRequestForm(values)}
                                render={this.renderForm}
                                initialValues={this.initialValues}
                            />
                            <div className="balance-sheet-info">
                                <div className="balance-table">
                                    <div className="table-outer">
                                        <div className="table-container">
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Amount</th>
                                                        <th>Request On</th>
                                                        <th>Status</th>
                                                        <th>Processed Date</th>
                                                        <th>Processed Remarks</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(accountResponse.viewCreditRequestResponse.loading === false) ?
                                                        (accountResponse.viewCreditRequestResponse.response && accountResponse.viewCreditRequestResponse.response.length > 0 && diffDays <= 31 ? accountResponse.viewCreditRequestResponse.response.map((item, key) => {

                                                            var recordCount = perPageLimit * (currentPage - 1);
                                                            return (
                                                                <tr key={key}>
                                                                    <td>{key + 1 + recordCount}</td>
                                                                    <td>{currencyFormat(item.amount)}</td>
                                                                    <td>{item.request_to_name}</td>
                                                                    <td>{(item.processed_status === "P") ? "Pending" : ((item.processed_status === "D") ? "Decline" : "Approved")}</td>
                                                                    <td>{item.processed_date !== null ? item.processed_date : "-"}</td>
                                                                    <td>{item.processed_remarks !== null ?
                                                                        <React.Fragment>
                                                                            { /* eslint-disable-next-line */}
                                                                            <a className="tooltip-info" href="#" data-tooltip={item.processed_remarks}>
                                                                                <i className="icon icon-info"></i></a> </React.Fragment> : '-'}</td>
                                                                    <td>{item.remarks ?
                                                                        <React.Fragment>
                                                                            { /* eslint-disable-next-line */}
                                                                            <a className="tooltip-info" href="javascript:void(0);" data-tooltip={item.remarks}><i className="icon icon-info"></i></a> </React.Fragment> : ""}</td>
                                                                </tr>
                                                            );
                                                        })
                                                            : <tr><td colSpan="10"><span className="no-records">THERE IS NO CREDIT REQUEST FOUND.</span></td></tr>
                                                        ) :
                                                        <tr><td colSpan="10"><div className="loader relative-loader" style={{ "display": "block" }}><div className="loader-content"><div className="loader-ring blue-ring"><span></span> </div></div></div></td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {
                                    diffDays <= 31 ?
                                        <div className="table-footer">
                                            <Pagination
                                                totalRecord={accountResponse.viewCreditRequestResponse.total_count}
                                                pageLimit={perPageLimit}
                                                activePage={currentPage}
                                                getRecordsPerPage={(value) => this.props.getRecordsPerPage(value, this.props.values)} />
                                        </div>
                                    : ''    
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default (ViewCredits)