
import {
    IS_BOOKING_PENDING,
    IS_FILTER_CHECKED,
    IS_FILTER_CHECKED_INBOUND,
    SCROLL_POSITION,
    BANNER_FETCH_START,
    BANNER_FETCH_OK,
    BANNER_FETCH_ERR,
    NOTICE_FETCH_START,
    NOTICE_FETCH_OK,
    NOTICE_FETCH_ERR,
    PRODUCTS_FETCH_START,
    PRODUCTS_FETCH_OK,
    PRODUCTS_FETCH_ERR,
	CITIES_FETCH_START,
    CITIES_FETCH_OK,
    CITIES_FETCH_ERR,
	POST_SUBSCRIBE,
    SUBSCRIBE_OK,
    SUBSCRIBE_ERR,
	GENERAL_SETTINGS_START,
    GENERAL_SETTINGS_OK,
    GENERAL_SETTINGS_ERR,
	PAGES_FETCH_OK,
    PAGES_FETCH_START,
    PAGES_FETCH_ERR,
    JWT_FETCH_OK,
    JWT_FETCH_ERR,
    CHAT_FETCH_OK,
    CHAT_FETCH_ERR,
    POST_MESSAGE_FETCH_START,
    POST_MESSAGE_FETCH_OK,
    POST_MESSAGE_FETCH_ERR,
    GET_NOTIFICATIONS_START,
    GET_NOTIFICATIONS_OK,
    GET_NOTIFICATIONS_ERR,
    UPDATE_NOTIFICATIONS_START,
    UPDATE_NOTIFICATIONS_OK,
    UPDATE_NOTIFICATIONS_ERR,
    GET_PRODUCT_ACCESS_START,
    GET_PRODUCT_ACCESS_OK,
    GET_PRODUCT_ACCESS_ERR,
    CONTACT_US_START,
    CONTACT_US_OK,
    CONTACT_US_ERR,
    HOTEL_CITIES_FETCH_START,
    HOTEL_CITIES_FETCH_OK,
    HOTEL_CITIES_FETCH_ERR,
    ADD_PASSENGER_START,
    ADD_PASSENGER_OK,
    ADD_PASSENGER_ERR,
    GET_CUSTOMERS_START,
    GET_CUSTOMERS_OK,
    GET_CUSTOMERS_ERR,
    ADD_COMPANY_GST_DETAILS_START,
    ADD_COMPANY_GST_DETAILS_OK,
    ADD_COMPANY_GST_DETAILS_ERR,
	GET_IP_START,
    GET_IP_OK,
    GET_IP_ERR,
    GET_NATIONALITY_START,
    GET_NATIONALITY_OK,
    GET_NATIONALITY_ERROR
} from '../../actions/shared/sharedAction';

export const initialSessionState = {
    bannerResponse: {
        error :null,
        loading: false,
        response:null
    },
    noticeResponse: {
        error :null,
        loading: false,
        response:null
    },
     productResponse: {
         error :null,
         loading: false,
         response:null
     },
	citiesResponse: {
        error :null,
        loading: false,
        response:null
    },
	 subscribeResponse: {
        error: null,
        loading: false,
        response: null
    },
	settingResponse: {
        error :null,
        loading: false,
        response:null
    },
	pagesResponse: {
        error :null,
        loading: false,
        response:null
    },
    jwtResponse: {
        error :null,
        loading: false,
        response:null
    },
    chatResponse :{
        error :null,
        loading: false,
        response:null
    },
    messageResponse:{
        error :null,
        loading: false,
        response:null
    },
    notificationResponse: {
        error :null,
        loading: false,
        response:null
    },
    updateNotificationResponse: {
        error :null,
        loading: false,
        response:null
    },
    agentProductAccess: {
        error :null,
        loading: false,
        response:null
    },
    contactUsResponse: {
        error :null,
        loading: false,
        response:null
    },
    hotelCitiesResponse: {
        error :null,
        loading: false,
        response:null
    },
    addPassenger: {
        error :null,
        loading: false,
        response:null
    },
    customerList: {
        error :null,
        loading: false,
        response:null
    },
    addCompanyGSTDetails: {
        error :null,
        loading: false,
        response:null
    },
	getIpResponse: {
        error: null,
        loading: false,
        response: null
    },
    isBookingPending:false,
    isFilterChecked:5,
    isFilterCheckedInbound:5,
    scroll_position:{
        parentContainer:"",
        childContainer:""
    },
    getNationalityDetails: {
        error: null,
        loading: false,
        response: null
    },
};

export function shared (state = initialSessionState, action) {
    switch(action.type) {
        //================================ For Banners ===================================
        case BANNER_FETCH_START :
            return {
                ...state, 
                bannerResponse : {
                    error   : '',
                    loading : true
                }
            }
            case IS_BOOKING_PENDING :
                return {
                    ...state,
                    isBookingPending:action.response
                }
                case IS_FILTER_CHECKED :
                return {
                    ...state,
                    isFilterChecked:action.response
                }
                case IS_FILTER_CHECKED_INBOUND :
                return {
                    ...state,
                    isFilterCheckedInbound:action.response
                }
                case SCROLL_POSITION :
                return {
                    ...state,
                    scroll_position:action.response
                }
        case BANNER_FETCH_OK :
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state, 
                bannerResponse : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                    ...action.decoded
                }
            };

        case BANNER_FETCH_ERR :
            /* reset session to initial state with the error */
            return {
                ...state, 
                bannerResponse : {
                    error   : action.error,
                    loading : false
                }
            }
        //================================ For Notices ===================================
        case NOTICE_FETCH_START :
            return {
                ...state, 
                noticeResponse : {
                    error   : '',
                    loading : true
                }
            }
        case NOTICE_FETCH_OK :
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state, 
                noticeResponse : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                    ...action.decoded
                }
            };
        case NOTICE_FETCH_ERR :
            /* reset session to initial state with the error */
            return {
                ...state, 
                noticeResponse : {
                    error   : action.error,
                    loading : false
                }
            }
         //================================ For Cities ===================================    
        case CITIES_FETCH_START :
            return {
                ...state, 
                citiesResponse : {
                    error   : '',
                    loading : true
                }
            }
        case CITIES_FETCH_OK :
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state, 
                citiesResponse : {
                    error       : '',
                    loading     : false,
                    response    : action.citiesResponse,
                    ...action.decoded
                }
            };

        case CITIES_FETCH_ERR :
            /* reset session to initial state with the error */
            return {
                ...state, 
                citiesResponse : {
                    error   : action.error,
                    loading : false
                }
            }
		/* for user subscribe */
        case POST_SUBSCRIBE :
            return {
                ...state,
                subscribeResponse : {
                    loading : true
                }
            };
        case SUBSCRIBE_OK :
            return {
                ...state,
                subscribeResponse : {
                    loading: false,
                    error   : false,
                    response: action.message
                }
            };
        case SUBSCRIBE_ERR :
            return {
                ...state,
                subscribeResponse : {
                    loading: false,
                    error: action.message,
                    response : false
                }
            };
//============================================== For Products ================================
         case PRODUCTS_FETCH_START :
             return {
                 ...state, 
                 productResponse : {
                     error   : '',
                     loading : true
                 }
             }
         case PRODUCTS_FETCH_OK :
             return {
                 ...state, 
                 productResponse : {
                     error       : '',
                     loading     : false,
                     response    : action.response,
                     ...action.decoded
                 }
             };

         case PRODUCTS_FETCH_ERR :
             return {
                 ...state, 
                 productResponse : {
                     error   : action.error,
                     loading : false
                 }
             }


		//================================ For General Settings ===================================  
		case GENERAL_SETTINGS_START :
			return {
				...state, 
				settingResponse : {
					error   : '',
					loading : true
				}
			}
		case GENERAL_SETTINGS_OK :
			/* set session as new object, to make sure no old session info lingers ... */
			return {
				...state, 
				settingResponse : {
					error       : '',
					loading     : false,
					response    : action.response,
					...action.decoded
				}
			};

		case GENERAL_SETTINGS_ERR :
			/* reset session to initial state with the error */
			return {
				...state, 
				citiesResponse : {
					error   : action.error,
					loading : false
				}
        };
		//================================ For Page INfo for login ===================================  
		case PAGES_FETCH_START :
			return {
				...state, 
				pagesResponse : {
					error   : '',
					loading : true
				}
			}
		case PAGES_FETCH_OK :
			/* set session as new object, to make sure no old session info lingers ... */
			return {
				...state, 
				pagesResponse : {
					error       : '',
					loading     : false,
					response    : action.response,
					...action.decoded
				}
			};

		case PAGES_FETCH_ERR :
			/* reset session to initial state with the error */
			return {
				...state, 
				pagesResponse : {
					error   : action.error,
					loading : false
				}
        };
        case JWT_FETCH_OK :
            return {
                ...state,
                jwtResponse : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                    ...action.decoded
                }
        }
        case JWT_FETCH_ERR :
            return {
                ...state,
                jwtResponse : {
                    error       : action.error,
                    loading     : false,
                    ...action.decoded
                }
        }
        /* case CHAT_FETCH_START :
            return {
                ...state, 
                chatResponse : {
                    error   : '',
                    loading : true
                }
        } */
        case CHAT_FETCH_OK :
            return {
                ...state,
                chatResponse : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                    ...action.decoded
                }
        }
        case CHAT_FETCH_ERR :
            return {
                ...state,
                chatResponse : {
                    error       : action.error,
                    loading     : false,
                    ...action.decoded
                }
        }
        case POST_MESSAGE_FETCH_START :
            return {
                ...state, 
                messageResponse : {
                    error   : '',
                    loading : true
                }
        }
        case POST_MESSAGE_FETCH_OK :
            return {
                ...state,
                messageResponse : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                    ...action.decoded
                }
        }
        case POST_MESSAGE_FETCH_ERR :
            return {
                ...state,
                messageResponse : {
                    error       : action.error,
                    loading     : false,
                    ...action.decoded
                }
        }
        /* for user notifictions */
        case GET_NOTIFICATIONS_START :
        return {
            ...state,
            notificationResponse : {
                loading : true,
                error   : null,
                response: null
            }
        };
        case GET_NOTIFICATIONS_OK :
            return {
            ...state,
            notificationResponse : {
                loading: false,
                error   : false,
                response: action.response
            }
            };
        case GET_NOTIFICATIONS_ERR :
            return {
            ...state,
            notificationResponse : {
                loading: false,
                error: action.message,
                response : false
            }
            };
        /* for update user notifictions */
        case UPDATE_NOTIFICATIONS_START :
            return {
                ...state,
                updateNotificationResponse : {
                    loading : true,
                    error   : null,
                    response: null
                }
            };
        case UPDATE_NOTIFICATIONS_OK :
            return {
            ...state,
            updateNotificationResponse : {
                loading: false,
                error   : false,
                response: action.response
            }
            };
        case UPDATE_NOTIFICATIONS_ERR :
            return {
            ...state,
            updateNotificationResponse : {
                loading: false,
                error: action.message,
                response : false
            }
        };
        /* Get user product access*/
        case GET_PRODUCT_ACCESS_START :
            return {
                ...state,
                agentProductAccess : {
                    loading : true,
                    error   : null,
                    response: null
                }
            };
        case GET_PRODUCT_ACCESS_OK :
            return {
                ...state,
                agentProductAccess : {
                    loading: false,
                    error   : false,
                    response: action.response
                }
            };
        case GET_PRODUCT_ACCESS_ERR :
            return {
                ...state,
                agentProductAccess : {
                    loading: false,
                    error: action.message,
                    response : false
                }
        };
        /* for contact us */
        case CONTACT_US_START :
            return {
                ...state,
                contactUsResponse : {
                    loading : true
                }
            };
        case CONTACT_US_OK :
            return {
                ...state,
                contactUsResponse : {
                    loading : false,
                    error   : false,
                    response: action.message
                }
            };
        case CONTACT_US_ERR :
            return {
                ...state,
                contactUsResponse : {
                    loading  : false,
                    error    : action.message,
                    response : false
                }
            };
            //================================ For Hotel Cities ===================================    
            case HOTEL_CITIES_FETCH_START :
            return {
                ...state, 
                hotelCitiesResponse : {
                    error   : '',
                    loading : true
                }
            }
            case HOTEL_CITIES_FETCH_OK :
                /* set session as new object, to make sure no old session info lingers ... */
                return {
                    ...state, 
                    hotelCitiesResponse : {
                        error       : '',
                        loading     : false,
                        response    : action.citiesResponse,
                        ...action.decoded
                    }
                };
            case HOTEL_CITIES_FETCH_ERR :
                /* reset session to initial state with the error */
                return {
                    ...state, 
                    hotelCitiesResponse : {
                        error   : action.error,
                        loading : false
                    }
                }
                //Add pasengers at flight booking time
            case ADD_PASSENGER_START :
                return {
                    ...state, 
                    addPassenger : {
                        error   : '',
                        loading : true
                    }
                }
            case ADD_PASSENGER_OK :
                return {
                    ...state, 
                    addPassenger : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    }
                };

            case ADD_PASSENGER_ERR :
                return {
                    ...state, 
                    addPassenger : {
                        error   : action.error,
                        loading : false
                    }
                }
            //Get customer list for autofill
            case GET_CUSTOMERS_START :
                return {
                    ...state, 
                    customerList : {
                        error   : '',
                        loading : true
                    }
                }
            case GET_CUSTOMERS_OK :
                return {
                    ...state, 
                    customerList : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    }
                };

            case GET_CUSTOMERS_ERR :
                return {
                    ...state, 
                    customerList : {
                        error   : action.error,
                        loading : false
                    }
                }
            //Add company gst details at flight booking time
            case ADD_COMPANY_GST_DETAILS_START :
                return {
                    ...state, 
                    addCompanyGSTDetails : {
                        error   : '',
                        loading : true
                    }
                }
            case ADD_COMPANY_GST_DETAILS_OK :
                return {
                    ...state, 
                    addCompanyGSTDetails : {
                        error       : '',
                        loading     : false,
                        response    : action.response,
                    }
                };

            case ADD_COMPANY_GST_DETAILS_ERR :
                return {
                    ...state, 
                    addCompanyGSTDetails : {
                        error   : action.error,
                        loading : false
                    }
                }
			 //Get IP
        case GET_IP_START:
            return {
                ...state,
                getIpResponse: {
                    error: '',
                    loading: true
                }
            }
        case GET_IP_OK:
            return {
                ...state,
                getIpResponse: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

        case GET_IP_ERR:
            return {
                ...state,
                getIpResponse: {
                    error: action.error,
                    loading: false
                }
            }
            // get nationality api
            case GET_NATIONALITY_START:
            return {
                ...state,
                getNationalityDetails: {
                    error: '',
                    loading: true
                }
            }
        case GET_NATIONALITY_OK:
            return {
                ...state,
                getNationalityDetails: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

        case GET_NATIONALITY_ERROR:
            return {
                ...state,
                getNationalityDetails: {
                    error: action.error,
                    loading: false
                }
            }
        default :
            return state;
    }
}


