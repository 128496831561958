import React, { Component } from 'react';
import { Formik } from 'formik';
import * as yup from "yup";

class TrainFailedBookingComponent extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showFailBookingPopup: false,
            showConfirmationPopup: false,
            cancelMessage: null,
        }

    }

    handleConfirmationPopup = (event) => {
        this.setState({
            showConfirmationPopup: event
        })
    }

    failBooking = (values) => {
        this.setState({
            showConfirmationPopup: false
        })
        const formValues = {
            "booking_id": this.props.failPopupValue.booking_id,
            "remark": values.remark
        };
    
        const successCb = () => {
            this.props.getTrainBookings(null, this.props.limitOffSet);
            this.setState({ cancelMessage: 'success' });
        }
        const errorCb = () => {
            this.props.getTrainBookings(null, this.props.limitOffSet);
            this.setState({ cancelMessage: 'error' });
        }

        this.props.cancelTrainBooking(formValues, successCb, errorCb);
    }

    cancelValidator = yup.object()
        .shape({
            remark: yup.string()
                .required('Remark is required.'),
        });
   
    initialValuesTrain = {
        remark: '',
        };    

    renderForm = (formikProps) => {
        const { handleSubmit, handleBlur, touched, errors, setFieldValue, values } = formikProps;
        const { showConfirmationPopup } = this.state;
        return (
            <React.Fragment>
                 <div className={`popup-crad change-request-popup show`}>
                    <div className="popup-outer">
                        {
                            this.props.failPopupValue &&
                            <div className="popup-content">
                                <div className="popup-head">
                                    <h2>Fail Booking</h2>
                                    <div className="popup-close" onClick={() => this.props.showFailBooking()}><span className="icon icon-cross" /></div>
                                </div>
                                <div className="popup-scorll">
                                    <div className="alert alert-success" style={{ display: this.state.cancelMessage === 'success' ? 'block' : 'none' }}>
                                        <strong><i className="fa fa-check-circle" />{this.props.cancelTrainBookingDetail.response
                                            && this.props.cancelTrainBookingDetail.response.message}</strong>
                                    </div>
                                    <div className="alert alert-danger" style={{ display: this.state.cancelMessage === 'error' ? 'block' : 'none' }}>
                                        <strong><i className="fa fa-exclamation-triangle" />Error in sending cancellation request</strong>
                                    </div>
                                    <div className="change-requrst-block">
                                        <div className="block remark-sector">
                                            <h5>Please enter remarks<span className="red-error">*</span></h5>
                                            <textarea 
                                                className={errors.remark && touched.remark ? "error-shadow" : ''} 
                                                name='remark' 
                                                value={values.remark}  
                                                onChange={(event) => { setFieldValue('remark', event.target.value); }}
                                                onBlur={handleBlur} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="popup-footer">
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className={`btn btn-orange loading-btn`}
                                        onClick={handleSubmit}><span>Send Request</span>
                                        {
                                            this.props.cancelBookingDetail.loading
                                                ?
                                                <div className="flight-animated"><label><span><small></small><small></small><small></small></span>
                                                    <img src="/assets/images/flight-continue.svg" alt="" /></label></div>
                                                : ''
                                        }
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="popup-overlay" />
                </div>
                {/* Popup for credit confirmation */}
                <div className={`popup-crad confirmation-popup ${showConfirmationPopup ? 'show' : ''}`}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>Cancellation Confirmation</h2>
                                <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false)} /></div>
                            </div>
                            <div className="popup-body popup-scorll">
                                <div className="inner-confirmation">
                                    <p>Are you sure you want to continue?</p>
                                    <div className="popup-footer">
                                        <button className="btn btn-orange" onClick={() => this.failBooking(values)}>Yes</button>
                                        <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false)}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay" />
                </div>

            </React.Fragment>
        )
    }

    render() {
        return (
            <Formik
                initialValues={this.initialValuesTrain}
                onSubmit={() => this.handleConfirmationPopup(true)}
                validationSchema={this.cancelValidator}
                render={this.renderForm}
            />
        )

    }
}

export default TrainFailedBookingComponent
