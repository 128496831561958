import React from 'react';

class SessionExpire extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tripCount: (props &&props.startCount),
            count: (props &&props.startCounts)
        }
    }

    componentWillReceiveProps(nextProps, secondProps) {
        const { startCount,startCounts } = this.props
        if (nextProps.startCount !== startCount) {
            if (startCount > 0) {
                this.setState({
                    tripCount: nextProps.startCount
                })
            }
        }
        // if (this.props.fareQuote.loading === true) {
        //     if (secondProps.startCounts !== startCounts) {
        //         this.setState({
        //             count: startCounts
        //         })
        //     }
        // }
    }

    componentDidMount() {
         const { tripJackBooking } = this.props
        // this.setState({
        //    count: startCounts
        // })
            this.doTripjackIntervalChange()
            this.doIntervalChange()
    }

    doTripjackIntervalChange = () => {
        this.myInterval = setInterval(() => {
            if (this.state.tripCount > 0) {
                this.setState(prevState => ({
                    tripCount: prevState.tripCount - 1
                }))
            }
        }, 1000)
    }

    doIntervalChange = () => {
        this.myIntervals = setInterval(() => {
            if (this.state.count > 0) {
                this.setState(prevState => ({
                    count: prevState.count - 1
                }))
            }
        }, 1000)

    }

    pad(num) {
        return ("0" + num).slice(-2);
    }

    tripJackTimeFormats(tripCount) {
        var minutes = Math.floor(tripCount / 60);
        var secs = tripCount % 60;
        minutes = minutes % 60;
        return `${this.pad(minutes)}:${this.pad(secs)}`;
    }

    timeFormat(count) {
        var minutes = Math.floor(count / 60);
        var secs = count % 60;
        minutes = minutes % 60;
        return `${this.pad(minutes)}:${this.pad(secs)}`;
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
        clearInterval(this.myIntervals)
    }

    render() {
        let ticketApiCheck = false;
        let loading = this.props.fareQuote && this.props.fareQuote.loading;
        let index = this.props.location.state.index;
        if (this.props.ticketBookingData && (this.props.ticketBookingData.loading === true || this.props.pnrRetrieveData.loading === true)) {
            ticketApiCheck = true
        }
        const { count, tripCount } = this.state
        return (
            <React.Fragment>
                <div className="session-card">
                    <div className="card-info">
                        <div className="session-card-inner">
                            {this.props.history.location.pathname === "/book-ticket" &&
                                <h3>Book your ticket before your session ends in <small><img src="assets/images/flight-continue-black.svg" alt="" /></small></h3>
                            }
                            {this.props.history.location.pathname === "/hotel-book" &&
                                <h3>Book your hotel before your session ends in</h3>
                            }

                            {
                                this.props.location.state.searchType === 'one-way' && count > 0 && this.props.location.state.data.api_source === "tbo" &&//  && loading === false
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'one-way' && count > 0 && this.props.location.state.data.api_source === "kafila" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'one-way' && count > 0 && this.props.location.state.data.api_source === "omairiq" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'one-way' && count > 0 && this.props.location.state.data.api_source === "gofly" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'one-way'&& count > 0 &&this.props.location.state.data.api_source ==="efly"&&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }  
                            {
                                this.props.location.state.searchType === 'one-way' && count > 0 && loading === true && this.props.location.state.data.api_source === "tripjack" &&
                                <div className="pull-right"><span className="session-timer">...</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip' && count > 0 && this.props.location.state.data[index].api_source === "tbo" && // && loading === false&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip' && count > 0 && this.props.location.state.data[index].api_source === "kafila" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip' && count > 0 && this.props.location.state.data[index].api_source === "omairiq" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip' && count > 0 && this.props.location.state.data[index].api_source === "gofly" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip' && count > 0 &&this.props.location.state.data[index].api_source ==="efly"&&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                             {
                                this.props.location.state.searchType === 'one-way' && count > 0 && this.props.location.state.data.api_source === "riya_travel" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                             {
                                this.props.location.state.searchType === 'one-way' && count > 0 && this.props.location.state.data.api_source === "fbq" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                             {
                                this.props.location.state.searchType === 'round-trip' && count > 0 && this.props.location.state.data[index].api_source === "fbq" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip-complete' && count > 0 && this.props.location.state.data[index].api_source === "kafila" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip-complete' && count > 0 && this.props.location.state.data[index].api_source === "tbo" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip-complete' && count > 0 && this.props.location.state.data[index].api_source === "omairiq" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip-complete' && count > 0 && this.props.location.state.data[index].api_source === "gofly" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip-complete'&& count > 0 && this.props.location.state.data[index].api_source ==="efly" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {/* {
                                this.props.location.state.searchType === 'round-trip'

                                && count > 0 && loading === false &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            } */}
                            {
                                this.props.location.state.searchType === 'round-trip' && this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st > 0 && loading === false &&
                                <div className="pull-right"><span className="session-timer">{this.tripJackTimeFormats(tripCount)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'special-return' && this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st > 0 && loading === false &&
                                <div className="pull-right"><span className="session-timer">{this.tripJackTimeFormats(tripCount)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'special-return' && loading === true && this.props.location.state.data.api_source === "tripjack" &&
                                <div className="pull-right"><span className="session-timer">...</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'special-return' && count > 0 && this.props.location.state.data.api_source === "tbo" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip-complete' && this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st > 0 && loading === false &&
                                <div className="pull-right"><span className="session-timer">{this.tripJackTimeFormats(tripCount)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip-complete' && loading === true && this.props.location.state.data[index].api_source === "tripjack" &&
                                <div className="pull-right"><span className="session-timer">...</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'round-trip' && loading === true && this.props.location.state.data[index].api_source === "tripjack" &&
                                <div className="pull-right"><span className="session-timer">...</span></div>
                            }
                             {
                                this.props.location.state.searchType === 'round-trip' && count > 0 && this.props.location.state.data[index].api_source === "riya_travel" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                             {
                                this.props.location.state.searchType === 'round-trip-complete' && count > 0 && this.props.location.state.data[index].api_source === "riya_travel" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'special-return' && count > 0 && this.props.location.state.data.api_source === "riya_travel" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                             {
                                this.props.location.state.searchType === 'round-trip-complete' && count > 0 && this.props.location.state.data[index].api_source === "fbq" &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(count)}</span></div>
                            }
                            {
                                this.props.location.state.searchType === 'one-way' && this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st > 0 && loading === false &&
                                <div className="pull-right"><span className="session-timer">{this.tripJackTimeFormats(tripCount)}</span></div>
                            }
                            {
                                this.props.location.state && this.props.location.state.hoteldata && (this.props.location.state.hoteldata.hotel_category === 'HOTEL' || this.props.location.state.hoteldata.hotel_category === '') &&
                                <div className="pull-right"><span className="session-timer">{this.timeFormat(tripCount)}</span></div>
                            }
                        </div>
                    </div>
                </div>

                {count <= 0 || tripCount <= 0 && ticketApiCheck === false ?

                    <div className="popup-crad confirm-popup show">
                        <div className="popup-outer">
                            <div className="popup-content">
                                <div className="popup-head">
                                    <h2>Confirm to Process</h2>
                                </div>
                                <div className="popup-scorll">
                                    <div className="confirm-popup-block block">
                                        <div className="block">
                                            <div className="session-exp">
                                                <h4>Your Session has been expired.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block">
                                    <div className="confirm-box-button">
                                        {
                                            this.props.history.location.pathname === "/book-ticket" &&
                                            <button className="btn btn-blue" onClick={() =>
                                                this.props.history.push({
                                                    pathname: "/flight-search",
                                                    state: {
                                                        searchAPINo: true
                                                    }
                                                })
                                            }>Back to flight search</button>
                                        }
                                        {
                                            this.props.history.location.pathname === "/hotel-book" &&
                                            <button className="btn btn-blue" onClick={() =>
                                                this.props.history.push({
                                                    pathname: "/hotel-search",
                                                    state: {
                                                        searchAPINo: true
                                                    }
                                                })
                                            }>Back to hotel search</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup-overlay"></div>
                    </div>
                    : ''
                }
            </React.Fragment>
        );
    }
}

export default SessionExpire;