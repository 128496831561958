import React, { Component } from 'react';
import FlightLoader from '../../components/flights/FlightSearchDetailsLoaderComponent';
import FlightLeftAdvanceSearch from '../../components/flights/FlightSearchDetailsLeftComponent';
import FlightRightOneWayDetailsList from './FlightSearchDetailsRightOneWayComponent';
import FlightRightRoundTripDetailsList from './FlightSearchDetailsRightRoundTripComponent';
import FlightRightMultiCityDetailsList from './FlightSearchDetailsRightMultiCityComponent';
import FlightRightGDSRoundTripDetailsList from './FlightSearchDetailsRightGDSRoundTripComponent';
import FlightRightAdvanceOneWayDetailsList from './FlightAdvanceSearchDetailsRightOneWayComponent';
import FlightRightAdvanceRoundTripDetailsList from './FlightAdvanceSearchDetailsRightRoundTripComponent';
import FlightSearchHeaderComponent from './FlightSearchHeaderComponent';
import { currencyFormat, dateFormat } from '../../shared/commonHelper';
import * as flightHelper from '../../shared/flightHelper';

class FlightSearchDetailsComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			bottomDepartureFlightDetails:[],
			bottomReturnFlightDetails:[],
			specialReturn: props.searchQuery.roundTripType,
			selectedSeatClass: [],
			isShowFareDetailpopup: false,
			bottomOfferedFare: false,
			outBoundAirlineCode:null,
			inBoundAirlineCode:null,
			outBoundKafilaAirlineCode:null,
			inBoundKafilaAirlineCode:null,
			outBoundApiSource:null,
			inBoundApiSource:null,
			sortFilter : {
				sortType: null,
				sortBy : null,
				inboundSortType:null,
				inboundSortBy : null,
			}
		};
	}

	trackContainerScrolling =() =>{
        let section = document.getElementsByClassName("page-container")[0];
        let footer = document.getElementsByTagName("footer")[0];
        let pageScorllRemaining = (section.scrollHeight + footer.scrollHeight - window.pageYOffset - window.innerHeight);
        this.props.scroll_position({...this.props.track_scroll_position,parentContainer:pageScorllRemaining})
      }

	  componentDidUpdate(prevProps) {
		// Check if searchQuery has changed and update the specialReturn state
		if (prevProps.searchQuery.roundTripType !== this.props.searchQuery.roundTripType) {
			this.setState({
				specialReturn: this.props.searchQuery.roundTripType
			});
		}
	}
	
	  componentDidMount() {
        document.addEventListener('scroll', this.trackContainerScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackContainerScrolling);
    }

	// Set details for outbound flights
	getBottomDepartureFlightsDetails = (data) => {
		this.setState({bottomDepartureFlightDetails  : data});
		this.setState({outBoundAirlineCode  : data.length !== 0?data[0].airline_code:null});
		this.setState({outBoundKafilaAirlineCode  : data.length !== 0?data[0].segments[0][0].airline.airline_code:null});
		this.setState({outBoundApiSource  : data.length !== 0?data[0].api_source:null});
	}

	// Set details for inbound flights
	getBottomReturnFlightsDetails = (data) => {
		this.setState({bottomReturnFlightDetails  : data});
		this.setState({inBoundAirlineCode  : data.length !== 0?data[0].airline_code:null});
		this.setState({inBoundKafilaAirlineCode  : data.length !== 0?data[0].segments[0][0].airline.airline_code:null});
		this.setState({inBoundApiSource  : data.length !== 0?data[0].api_source:null});
	}

	// Set special return and according flight search
	flightSearchSpecialReturn = (event) => {
		let specialReturnType = event.currentTarget.dataset.specialreturn;
		
        this.setState({specialReturn: specialReturnType});
        if(specialReturnType === 'LCC')
        {
			this.props.searchQuery.source = ["SG","6E","G8"];
			this.props.searchQuery.journey_type = "5";
			this.props.searchQuery.roundTripType = specialReturnType;
		}
        else if(specialReturnType === 'GDS')
        {
			this.props.searchQuery.source = ["GDS"];
			this.props.searchQuery.journey_type = "5";
			this.props.searchQuery.roundTripType = specialReturnType;
		}
        else
        {
			delete this.props.searchQuery.source;
			this.props.searchQuery.journey_type = "2";
			this.props.searchQuery.roundTripType = specialReturnType;
		}
		const is_kafila_user 	= localStorage.getItem('is_kafila_user');
		setTimeout(() => {
			if (is_kafila_user === 'Y' && ['1', '2', '5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' || this.props.searchQuery.pft === 'STUDENT')) {
				if (this.props.searchQuery.is_domestic === 'false' && (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" || this.props.searchQuery.journey_url === 'lcc-special-return')) {
				} else {
					if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
						this.props.getKafilaFlightSearchResults(this.props.searchQuery);
					}
				}
			}
		}, 1000);
		if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
			if (this.props.searchQuery.pft === 'REGULAR') {
				setTimeout(() => {
					if (['1', '2'].includes(this.props.searchQuery.journey_type)) {
						this.props.getTripJackFlightSearchResults(this.props.searchQuery);
					}
				}, 2000);
			}
			else if (['1', '2'].includes(this.props.searchQuery.journey_type)) {
				this.props.getTripJackFlightSearchResults(this.props.searchQuery);
			}
		}
		if (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'STUDENT' || this.props.searchQuery.pft === 'SENIOR_CITIZEN') {
			this.props.getFlightSearchResults(this.props.searchQuery);
		}
		setTimeout(() => {
			if ((['1', '2', '5'].includes(this.props.searchQuery.journey_type) && this.props.searchQuery.pft === 'REGULAR')) {
				if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
				} else {
					if (this.props.searchQuery.is_domestic === 'false') {
						if (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" || this.props.searchQuery.journey_url === 'lcc-special-return') {
							if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
								this.props.getGoflyFlightSearchResults(this.props.searchQuery);
							}
						} else {
							this.props.getGoflyFlightSearchResults(this.props.searchQuery);
						}
					} else {
						if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
							this.props.getGoflyFlightSearchResults(this.props.searchQuery);
						}
					}
				}
			}
		}, 3000);

		setTimeout(() => {
			if (['1', '2', '5'].includes(this.props.searchQuery.journey_type) && this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journeyUrl !== 'advance-search') {
				if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
				} else {
					if (this.props.searchQuery.is_domestic === 'false' && (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" || this.props.searchQuery.journey_url === 'lcc-special-return')) {
					} else {
						if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
							this.props.geteflyFlightSearchResults(this.props.searchQuery);
						}
					}
				}
			}
		}, 3000);

		setTimeout(() => {
			if (this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search') {
				if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {

				}
				else {
					if (this.props.searchQuery.is_domestic === 'false' && (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" || this.props.searchQuery.journey_url === 'lcc-special-return')) {
					} else {
						if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
							this.props.getAirIQFlightSearchResults(this.props.searchQuery);
						}
					}
				}
			}
		}, 3000);

		setTimeout(() => {
			if ((['1', '2', '5'].includes(this.props.searchQuery.journey_type) && this.props.searchQuery.pft === 'REGULAR')) {
				if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
				} else {
					if (this.props.searchQuery.is_domestic === 'false') {
						if (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" || this.props.searchQuery.journey_url === 'lcc-special-return') {
							if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
								this.props.getSatkarFlightSearchResults(this.props.searchQuery);
							}
						} else {
							this.props.getSatkarFlightSearchResults(this.props.searchQuery);
						}
					} else {
						if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
							this.props.getSatkarFlightSearchResults(this.props.searchQuery);
						}
					}
				}
			}
		}, 3200);
		setTimeout(() => {
			if (['1', '2', '5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' || this.props.searchQuery.pft === 'STUDENT')) {
				if (this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search') {
					this.props.getRiyaFlightSearchResults(this.props.searchQuery);
				}
			}
		}, 1000);


		setTimeout(() => {
			if (['1', '2', '5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR')) {
				if (this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search') {
					if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
						this.props.getFareBoutiqueFlightSearchResults(this.props.searchQuery);
					}

				}
			}
		}, 1000);
		
	}

	//get selected total seat class for flight price
	getAdvanceRoundTripSelectedSeatClass = (selectedSeatInfo) => {
		let selectedSeatClass = this.state.selectedSeatClass;

        if(selectedSeatClass.length > 0)
        {
			selectedSeatClass.length > 0 && selectedSeatClass.map((seats, index) => {
				//Functionality for Outbound 
				if(seats.indexOf('OB') > -1 && selectedSeatInfo.indexOf('OB') > -1)
				{
					if(seats.split('-')[0] === selectedSeatInfo.split('-')[0])
					{
						if(seats.split('-')[1] === selectedSeatInfo.split('-')[1])
						{
							selectedSeatClass.splice(index, 1);
						}
					}
					else
					{
						let seatTempArray = [];
						selectedSeatClass.filter(function(value, index){
							if(value.indexOf('IB') > -1)
							{
								seatTempArray.push(value);
							}
							selectedSeatClass = seatTempArray;
							return true;
						});
					}
				}

				//Functionality for Inbound 
				if(seats.indexOf('IB') > -1 && selectedSeatInfo.indexOf('IB') > -1)
				{
					if(seats.split('-')[0] === selectedSeatInfo.split('-')[0])
					{
						if(seats.split('-')[1] === selectedSeatInfo.split('-')[1])
						{
							selectedSeatClass.splice(index, 1);
						}
					}
					else
					{
						let seatTempArray = [];
						selectedSeatClass.filter(function(value, index){
							if(value.indexOf('OB') > -1)
							{
								seatTempArray.push(value);
							}
							selectedSeatClass = seatTempArray;
							return true;
						});
					}
				}
				return true;
			})
			selectedSeatClass.push(selectedSeatInfo);
			this.setState({
				selectedSeatClass : selectedSeatClass
			});
		}
        else
        {
			selectedSeatClass = [];
			selectedSeatClass.push(selectedSeatInfo);
			this.setState({
                selectedSeatClass : selectedSeatClass
			});
		}
		this.props.reInitializeFlightPriceDetails();
	}

	//Show fare details
    showFareDetailsPopup = (event,resultIndex='',traceID='') => {
        if(resultIndex !== ''){
            this.props.flightFareDetails(traceID,resultIndex);
		}
        this.setState({isShowFareDetailpopup: this.state.isShowFareDetailpopup === true ? false : true })
	}

	//Show bottom offered fare for advance search
	showBottomOfferedFare = () => {
		this.setState({bottomOfferedFare: !this.state.bottomOfferedFare});
	}

	//Show bottom offered fare for advance search
	emptySelectedSeatClass = (boundType) => {
		let selectedSeatClass = this.state.selectedSeatClass;
		let seatTempArray = [];
		selectedSeatClass.filter(function(value, index){
			if(boundType.indexOf('OB') > -1)
			{
				if(value.indexOf('IB') > -1)
				{
					seatTempArray.push(value);
				}
			}
			if(boundType.indexOf('IB') > -1)
			{
				if(value.indexOf('OB') > -1)
				{
					seatTempArray.push(value);
				}
			}
			selectedSeatClass = seatTempArray;
			return true;
		});
		this.setState({
			selectedSeatClass : selectedSeatClass
		});
	}

	sortResults = (type) => {
		let sortTypeColumn = 'sortType';
		let sortByColumn = 'sortBy';
		if(type.includes("inbound"))
		{
			sortTypeColumn = 'inboundSortType';
			sortByColumn = 'inboundSortBy';
		}
		let sortType = null;
		if(!this.state.sortFilter[sortTypeColumn])
		    sortType = 'asc';
		else if(this.state.sortFilter[sortTypeColumn] === 'asc')
		    sortType = 'desc';
		else if(this.state.sortFilter[sortTypeColumn] === 'desc')
		    sortType = 'asc';

		
		this.setState({
		    sortFilter : {
			[sortTypeColumn] : sortType,
			[sortByColumn] : type
		    }
		});

		let filters = this.props.resultSorting;
		filters[sortTypeColumn] = sortType;
		filters[sortByColumn] = type;
		this.props.sortFlightResults(filters);
	  }

	render() {
		let content;
		if(this.props.loading === true)
		{
			content = <FlightLoader />
		}
		else
		{
			if(this.props.searchQuery.journey_type === '1'){
				// One way search
				content = (
					<React.Fragment>
						<section className="mid-wrapper inner-mid-section">
							<div className="container">
								<div className="search-result-section">
									<FlightLeftAdvanceSearch {...this.props}/> 
									<FlightRightOneWayDetailsList {...this.props} sortResults={this.sortResults} sortFilter={this.state.sortFilter}/>	
								</div>
							</div>
						</section>
					</React.Fragment>
				)
			}
			else if(this.props.searchQuery.journey_type === '2' || this.props.searchQuery.journey_type === '5') // Round trip search
			{
				if(this.state.specialReturn === "GDS" || (this.props.searchQuery.is_domestic === 'false' && this.state.specialReturn === "LCC") || (this.props.searchQuery.is_domestic === 'false') || (this.props.searchQuery.originCountryName !== 'India' && this.props.searchQuery.destinationCountryName !== 'India'))
				{
					content = (
						<React.Fragment>
							<section className="mid-wrapper inner-mid-section">
								<div className="container">
									<div className="search-result-section">
										<div className="round-search-tab">
											<ul className="tab-list">
												<li className={(this.state.specialReturn === "normalReturn") ? "active" : ""}><span className="tab-anchor" data-specialreturn="normalReturn" onClick={this.flightSearchSpecialReturn}>Normal Return</span></li>
												<li className={(this.state.specialReturn === "LCC") ? "active" : ""}><span className="tab-anchor" data-specialreturn="LCC" onClick={this.flightSearchSpecialReturn}>LCC Special Return</span></li>
												<li className={(this.state.specialReturn === "GDS") ? "active" : ""}><span className="tab-anchor" data-specialreturn="GDS" onClick={this.flightSearchSpecialReturn}>GDS Special Return</span></li>
											</ul>
										</div>
										<FlightLeftAdvanceSearch {...this.props}/> 
										<FlightRightGDSRoundTripDetailsList {...this.props}/>	
									</div>
								</div>
							</section>
						</React.Fragment>
					)
				}
				else
				{
					content = (
						<React.Fragment>
							<section className="mid-wrapper round-trip-mid">
								<div className="container">
									<div className="search-result-section round-trip-wrap">
										<div className="round-search-tab">
											<ul className="tab-list">
												<li className={(this.state.specialReturn === "normalReturn") ? "active" : ""}><span className="tab-anchor" data-specialreturn="normalReturn" onClick={this.flightSearchSpecialReturn}>Normal Return</span></li>
												<li className={(this.state.specialReturn === "LCC") ? "active" : ""}><span className="tab-anchor" data-specialreturn="LCC" onClick={this.flightSearchSpecialReturn}>LCC Special Return</span></li>
												<li className={(this.state.specialReturn === "GDS") ? "active" : ""}><span className="tab-anchor" data-specialreturn="GDS" onClick={this.flightSearchSpecialReturn}>GDS Special Return</span></li>
											</ul>
										</div>
										<FlightLeftAdvanceSearch {...this.props}/> 
										<FlightRightRoundTripDetailsList {...this.props}
											getBottomDepartureFlightsDetails={this.getBottomDepartureFlightsDetails}
											getBottomReturnFlightsDetails={this.getBottomReturnFlightsDetails}
											showBottomOfferedFare={this.showBottomOfferedFare}
											outBoundAirlineCode={this.state.outBoundAirlineCode}
											inBoundAirlineCode={this.state.inBoundAirlineCode}
											sortResults={this.sortResults} 
											sortFilter={this.state.sortFilter}
										/>
									</div>
								</div>
							</section>
							{this.state.bottomDepartureFlightDetails.length !== 0 && this.state.bottomReturnFlightDetails.length !== 0 && <BottomRoundTripFlightComponent {...this.props} {...this.state}/>}
						</React.Fragment>
					)
				}
			}
			else if(this.props.searchQuery.journey_type === '3') // For multicity search
			{
				content = (
					<React.Fragment>
						<section className="mid-wrapper inner-mid-section">
							<div className="container">
								<div className="search-result-section">
									<FlightLeftAdvanceSearch {...this.props}/> 
									<FlightRightMultiCityDetailsList {...this.props} />	
								</div>
							</div>
						</section>
					</React.Fragment>
				)
			}
			else if(this.props.searchQuery.journey_type === '4') // For advance search
			{
				if(this.props.searchQuery.advanceSearchType === '1'){
					// advance search one way
					content = (
						<React.Fragment>
							<section className="mid-wrapper inner-mid-section">
								<div className="container">
									<div className="search-result-section">
										<FlightLeftAdvanceSearch {...this.props}/> 
										<FlightRightAdvanceOneWayDetailsList {...this.props} />	
									</div>
								</div>
							</section>
						</React.Fragment>
					)
				}
				else
				{
					// advance search round trip
					content = (
						<React.Fragment>
							<section className="mid-wrapper round-trip-mid">
								<div className="container">
									<div className="search-result-section round-trip-wrap">
										<FlightLeftAdvanceSearch {...this.props}/> 
										<FlightRightAdvanceRoundTripDetailsList {...this.props}
											getBottomDepartureFlightsDetails={this.getBottomDepartureFlightsDetails}
											getBottomReturnFlightsDetails={this.getBottomReturnFlightsDetails}
											getAdvanceRoundTripSelectedSeatClass={this.getAdvanceRoundTripSelectedSeatClass}
											showFareDetailsPopup={this.showFareDetailsPopup}
											emptySelectedSeatClass={this.emptySelectedSeatClass}
											{...this.state}
										/>
									</div>
								</div>
							</section>
							{this.state.bottomDepartureFlightDetails.length !== 0 && this.state.bottomReturnFlightDetails.length !== 0 && <BottomAdvanceRoundTripFlightComponent {...this.props} {...this.state} showFareDetailsPopup={this.showFareDetailsPopup}/>}
						</React.Fragment>
					)
				}
			}
		}
		return (
			<React.Fragment>
				<FlightSearchHeaderComponent {...this.props} />
				{/**************************************** Bottom left and right section - start ***************************************/}
				{content}
				{/**************************************** Bottom left and right section - end *****************************************/}
			</React.Fragment>
		)
	}
}

// Bottom for normal round trip
class BottomRoundTripFlightComponent extends React.Component {
	constructor(){
		super();
		this.state = {
			toggleOnMobile : false,
			isDestinationSame: false
		}
	}
	startBooking = (bottomDepartureFlightDetails,bottomReturnFlightDetails) =>{

		if(this.props.searchQuery.roundTripType === 'LCC')
        {
            if((this.props.outBoundAirlineCode !== this.props.inBoundAirlineCode) || (this.props.outBoundKafilaAirlineCode !== this.props.inBoundKafilaAirlineCode) || (this.props.outBoundApiSource !== this.props.inBoundApiSource))
            {
				alert('Please selelct same airlines.')
				return false;
			}
		}

		let searchType = '';
		if(this.props.searchQuery.journey_type === '5'){
			searchType = 'lcc-special-return';
		}else{
			searchType = 'round-trip';
		}
		let data = [];
		data.push(bottomDepartureFlightDetails[0]);
		data.push(bottomReturnFlightDetails[0]);
		const searchQueryDestionationOnward = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[0].destinationAirportCode
		const searchQueryDestionationReturn = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[1].originAirportCode
		const dataDestinationOnward = bottomDepartureFlightDetails[0] &&
			bottomDepartureFlightDetails[0].segments &&
			bottomDepartureFlightDetails[0].segments[0] &&
			bottomDepartureFlightDetails[0].segments[0][bottomDepartureFlightDetails[0].segments[0].length - 1] &&
			bottomDepartureFlightDetails[0].segments[0][bottomDepartureFlightDetails[0].segments[0].length - 1].destination &&
			bottomDepartureFlightDetails[0].segments[0][bottomDepartureFlightDetails[0].segments[0].length - 1].destination.airport &&
			bottomDepartureFlightDetails[0].segments[0][bottomDepartureFlightDetails[0].segments[0].length - 1].destination.airport.airport_code
		const dataDestinationReturn = bottomReturnFlightDetails[0] &&
			bottomReturnFlightDetails[0].segments &&
			bottomReturnFlightDetails[0].segments[0] &&
			bottomReturnFlightDetails[0].segments[0][0] &&
			bottomReturnFlightDetails[0].segments[0][0].origin &&
			bottomReturnFlightDetails[0].segments[0][0].origin.airport &&
			bottomReturnFlightDetails[0].segments[0][0].origin.airport.airport_code

		if (searchType === 'round-trip') {
			// let bookingParam = this.props.searchResult.param ? this.props.searchResult.param[0] : '';
			// let bookingParam_rt = this.props.searchResult.param_rt ? this.props.searchResult.param_rt[0] : '';
			let bookingParam = ""
			let bookingPramReturn = ""
			if (this.props.searchResult && this.props.searchResult.Param) {
				bookingParam = this.props.searchResult && this.props.searchResult.Param ? this.props.searchResult.Param : '';
			} else if (this.props.searchResult && this.props.searchResult.param) {
				bookingParam = this.props.searchResult && this.props.searchResult.param ? this.props.searchResult.param : ''
			}
			if (this.props.searchResult && this.props.searchResult.param_return) {
				bookingPramReturn = this.props.searchResult && this.props.searchResult.param_return ? this.props.searchResult.param_return : '';
			}
			if ((searchQueryDestionationOnward !== dataDestinationOnward) || (searchQueryDestionationReturn !== dataDestinationReturn)) {
				this.setState({ isDestinationSame: !this.state.isDestinationSame });
			} else {
				this.props.history.push({
					pathname: "/book-ticket",
					state: {
						searchType: searchType,
						trace_id: this.props.searchResult && this.props.searchResult.trace_id,
						data: data,
						index: 0,
						param: bookingParam,
						param_return: bookingPramReturn
						// param_rt: bookingParam_rt
					}
				});
			}

		}
		else {
			if ((searchQueryDestionationOnward !== dataDestinationOnward) || (searchQueryDestionationReturn !== dataDestinationReturn)) {
				this.setState({ isDestinationSame: !this.state.isDestinationSame });
			} else {
				this.props.history.push({
					pathname: "/book-ticket",
					state: {
						searchType: searchType,
						trace_id: this.props.searchResult && this.props.searchResult.trace_id,
						data: data,
						index: 0,
						param: this.props.searchResult && this.props.searchResult.param,
						param_return: this.props.searchResult && this.props.searchResult.param_return,
					}
				});
			}
		}
	}

	slideOnMobileView = () => {
		if(window.innerWidth < 767){
			this.setState({
				toggleOnMobile : this.state.toggleOnMobile ? false : true
			})
		}
	}

	handleConfirmationPopup = (action) => {
		this.setState({
			isDestinationSame: action,
		})
	}

	handleConfimationAction = (bottomDepartureFlightDetails, bottomReturnFlightDetails) => {
		this.setState({
			isDestinationSame: false
		})
		if (this.props.searchQuery.roundTripType === 'LCC') {
			if ((this.props.outBoundAirlineCode !== this.props.inBoundAirlineCode) || (this.props.outBoundKafilaAirlineCode !== this.props.inBoundKafilaAirlineCode) || (this.props.outBoundApiSource !== this.props.inBoundApiSource)) {
				alert('Please selelct same airlines.')
				return false;
			}
		}

		let searchType = '';
		if (this.props.searchQuery.journey_type === '5') {
			searchType = 'lcc-special-return';
		} else {
			searchType = 'round-trip';
		}
		let data = [];
		data.push(bottomDepartureFlightDetails[0]);
		data.push(bottomReturnFlightDetails[0]);

		if (searchType === 'round-trip') {
			// let bookingParam = this.props.searchResult.param ? this.props.searchResult.param[0] : '';
			// let bookingParam_rt = this.props.searchResult.param_rt ? this.props.searchResult.param_rt[0] : '';
			let bookingParam = ""
			let bookingPramReturn = ""
			if (this.props.searchResult && this.props.searchResult.Param) {
				bookingParam = this.props.searchResult && this.props.searchResult.Param ? this.props.searchResult.Param : '';
			} else if (this.props.searchResult && this.props.searchResult.param) {
				bookingParam = this.props.searchResult && this.props.searchResult.param ? this.props.searchResult.param : ''
			}
			if (this.props.searchResult && this.props.searchResult.param_return) {
				bookingPramReturn = this.props.searchResult && this.props.searchResult.param_return ? this.props.searchResult.param_return : '';
			}

			this.props.history.push({
				pathname: "/book-ticket",
				state: {
					searchType: searchType,
					trace_id: this.props.searchResult && this.props.searchResult.trace_id,
					data: data,
					index: 0,
					param: bookingParam,
					param_return: bookingPramReturn
					// param_rt: bookingParam_rt
				}
			});
		}
		else {
			this.props.history.push({
				pathname: "/book-ticket",
				state: {
					searchType: searchType,
					trace_id: this.props.searchResult && this.props.searchResult.trace_id,
					data: data,
					index: 0,
					param: this.props.searchResult && this.props.searchResult.param,
					param_return: this.props.searchResult && this.props.searchResult.param_return,
				}
			});
		}
	}

	render() {
		const {searchQuery, bottomDepartureFlightDetails, bottomReturnFlightDetails, bottomOfferedFare} = this.props;
		const searchQueryDestionationOnward = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[0].destinationAirportCode
		const searchQueryDestionationReturn = this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[1].originAirportCode
		const dataDestinationOnward = bottomDepartureFlightDetails[0] &&
			bottomDepartureFlightDetails[0].segments &&
			bottomDepartureFlightDetails[0].segments[0] &&
			bottomDepartureFlightDetails[0].segments[0][bottomDepartureFlightDetails[0].segments[0].length - 1] &&
			bottomDepartureFlightDetails[0].segments[0][bottomDepartureFlightDetails[0].segments[0].length - 1].destination &&
			bottomDepartureFlightDetails[0].segments[0][bottomDepartureFlightDetails[0].segments[0].length - 1].destination.airport &&
			bottomDepartureFlightDetails[0].segments[0][bottomDepartureFlightDetails[0].segments[0].length - 1].destination.airport.airport_code
		const dataDestinationReturn = bottomReturnFlightDetails[0] &&
			bottomReturnFlightDetails[0].segments &&
			bottomReturnFlightDetails[0].segments[0] &&
			bottomReturnFlightDetails[0].segments[0][0] &&
			bottomReturnFlightDetails[0].segments[0][0].origin &&
			bottomReturnFlightDetails[0].segments[0][0].origin.airport &&
			bottomReturnFlightDetails[0].segments[0][0].origin.airport.airport_code

		return (
			<React.Fragment>
				<div className="selection-box round-selection-box">
					<div className="selection-outer add-trip">
						<div className="top-trigger" onClick={() => this.slideOnMobileView()}><i className="icon icon-caret-bottom"></i></div>
						<div className="container">
							<div className="flight-booking">
								<div className="cols" style={{ display: this.state.toggleOnMobile ? 'none' : 'block' }}>
									<div className="booking-info">
										<div className="booking-title">
											<h3>{searchQuery.segments[0].originCityName + ' To ' + searchQuery.segments[0].destinationCityName}</h3>
											<span>{dateFormat(searchQuery.segments[0].preferred_departure_time, 'DD MMM YYYY, FDAY')}</span>
										</div>
										<div className="booking-airline">
											<div className="airline-col">
												<figure>{bottomDepartureFlightDetails.length > 0 ? <img src={bottomDepartureFlightDetails[0].segments[0][0].airline.flight_logo} alt="" /> : ''}</figure>
												<div className="flight-name">
													<span>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getCombinedAirlineName(bottomDepartureFlightDetails[0].segments[0]) : '-'}</span>
													<p>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getCombinedAirlineCode(bottomDepartureFlightDetails[0].segments[0]) : '-'}</p>
												</div>
											</div>
											<div className="time-date-col">
												<div className="col1">
													<div className="time-date">
														<strong>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getDepartureTime(bottomDepartureFlightDetails[0].segments[0][0].origin.dep_time) : '-'}</strong>
														<span>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getDepartureDate(bottomDepartureFlightDetails[0].segments[0][0].origin.dep_time, 'DD MMM') : '-'}</span>
													</div>
												</div>
												<div className="col1 col-direction">
													<div className="flight-direction"><div className="flight-bar"><i className="icon icon-flight3"></i></div></div>
												</div>
												<div className="col1">
													<div className="time-date">
														<strong>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getArivalTime(bottomDepartureFlightDetails[0].segments[0]) : '-'}</strong>
														<span>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getArivalDate(bottomDepartureFlightDetails[0].segments[0]) : '-'}</span>
													</div>
												</div>
											</div>
											<div className="amount-col">
												<strong><i className="fa fa-inr"></i>{bottomDepartureFlightDetails.length > 0 ? currencyFormat(bottomDepartureFlightDetails[0].fare.published_fare) : '-'}</strong>
												<small className="offered-fare" style={{ display: (bottomOfferedFare === true) ? 'block' : 'none' }}><i className="fa fa-inr" aria-hidden="true"></i>{bottomDepartureFlightDetails.length > 0 ? currencyFormat(bottomDepartureFlightDetails[0].fare.offered_fare) : '-'}</small>
											</div>
										</div>
									</div>
								</div>
								<div className="cols" style={{ display: this.state.toggleOnMobile ? 'none' : 'block' }}>
									<div className="booking-info">
										<div className="booking-title">
											<h3>{searchQuery.segments[1].originCityName + ' To ' + searchQuery.segments[1].destinationCityName}</h3>
											<span>{dateFormat(searchQuery.segments[1].preferred_departure_time, 'DD MMM YYYY, FDAY')}</span></div>
										<div className="booking-airline">
											<div className="airline-col">
												<figure>{bottomReturnFlightDetails.length > 0 ? <img src={bottomReturnFlightDetails[0].segments[0][0].airline.flight_logo} alt="" /> : ''}</figure>
												<div className="flight-name">
													<span>{bottomReturnFlightDetails.length > 0 ? flightHelper.getCombinedAirlineName(bottomReturnFlightDetails[0].segments[0]) : '-'}</span>
													<p>{bottomReturnFlightDetails.length > 0 ? flightHelper.getCombinedAirlineCode(bottomReturnFlightDetails[0].segments[0]) : '-'}</p>
												</div>
											</div>
											<div className="time-date-col">
												<div className="col1">
													<div className="time-date">
														<strong>{bottomReturnFlightDetails.length > 0 ? flightHelper.getDepartureTime(bottomReturnFlightDetails[0].segments[0][0].origin.dep_time) : '-'}</strong>
														<span>{bottomReturnFlightDetails.length > 0 ? flightHelper.getDepartureDate(bottomReturnFlightDetails[0].segments[0][0].origin.dep_time, 'DD MMM') : '-'}</span>
													</div>
												</div>
												<div className="col1 col-direction">
													<div className="flight-direction"><div className="flight-bar"><i className="icon icon-flight3"></i></div></div>
												</div>
												<div className="col1">
													<div className="time-date">
														<strong>{bottomReturnFlightDetails.length > 0 ? flightHelper.getArivalTime(bottomReturnFlightDetails[0].segments[0]) : '-'}</strong>
														<span>{bottomReturnFlightDetails.length > 0 ? flightHelper.getArivalDate(bottomReturnFlightDetails[0].segments[0]) : '-'}</span>
													</div>
												</div>
											</div>
											<div className="amount-col">
												<strong><i className="fa fa-inr"></i>{bottomReturnFlightDetails.length > 0 ? currencyFormat(bottomReturnFlightDetails[0].fare.published_fare) : '-'}</strong>
												<small className="offered-fare" style={{ display: (bottomOfferedFare === true) ? 'block' : 'none' }}><i className="fa fa-inr" aria-hidden="true"></i>{bottomReturnFlightDetails.length > 0 ? currencyFormat(bottomReturnFlightDetails[0].fare.offered_fare) : '-'}</small>
											</div>
										</div>
									</div>
								</div>
								<div className="cols total-fare-amount">
									<div className="total-fare">
										<span>Total Fare:</span>
										<div className="block">
											<strong><i className="fa fa-inr"></i>
												{bottomReturnFlightDetails.length > 0 && bottomDepartureFlightDetails.length > 0 ? currencyFormat(bottomDepartureFlightDetails[0].fare.published_fare + bottomReturnFlightDetails[0].fare.published_fare) : '-'}
											</strong>
											<small className="offered-fare" style={{ display: (bottomOfferedFare === true) ? 'block' : 'none' }}><i className="fa fa-inr" aria-hidden="true"></i>
												{bottomReturnFlightDetails.length > 0 && bottomDepartureFlightDetails.length > 0 ? currencyFormat(bottomDepartureFlightDetails[0].fare.offered_fare + bottomReturnFlightDetails[0].fare.offered_fare) : '-'}
											</small>
											<div className="boox-btn">
												{ /* eslint-disable-next-line */}
												<a href="javascript:void(0);" className="btn btn-primary"
													onClick={() => this.startBooking(bottomDepartureFlightDetails, bottomReturnFlightDetails)}
												>Book Now</a></div>
										</div>
									</div>
								</div>
								<div className={`popup-crad confirmation-popup ${this.state.isDestinationSame ? 'show' : ''}`}>
									<div className="popup-outer">
										<div className="popup-content" style={{ maxWidth: '373px' }}>
											<div className="popup-head">
												<h2>Different Airport</h2>
												<div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false)} /></div>
											</div>
											<div className="popup-body popup-scorll">
												{
													searchQueryDestionationOnward !== dataDestinationOnward &&
													<div className="inner-confirmation">
														<p>
															{`You have searched for Onward (`}
															<strong>
																{`${this.props.searchQuery.segments[0].originAirportCode} - ${this.props.searchQuery.segments[0].destinationAirportCode}`}
															</strong>
															{`)`}
														</p>
														<p>
															{`however, this flight option from Onward (`}
															<strong>
																{/* bottomDepartureFlightDetails[0].segments[0][0].origin */}
																{bottomDepartureFlightDetails[0].segments[0][0].origin.airport.airport_code} -
																{bottomDepartureFlightDetails[0].segments[0][bottomDepartureFlightDetails[0].segments[0].length - 1].destination.airport.airport_code}
															</strong>
															{`)`}
														</p>
													</div>
												}
												{
													searchQueryDestionationReturn !== dataDestinationReturn &&
													<div className="inner-confirmation">
														<p>
															{`You have searched for Return (`}
															<strong>
																{`${this.props.searchQuery.segments[1].originAirportCode} - ${this.props.searchQuery.segments[1].destinationAirportCode}`}
															</strong>
															{`)`}
														</p>
														<p>
															{`however, this flight option from Return (`}
															<strong>
																{bottomReturnFlightDetails[0].segments[0][0].origin.airport.airport_code} -
																{bottomReturnFlightDetails[0].segments[0][bottomReturnFlightDetails[0].segments[0].length - 1].destination.airport.airport_code}
															</strong>
															{`)`}
														</p>
													</div>
												}


												<div className="inner-confirmation">
													<p>Are you sure you want to continue?</p>
													<div className="popup-footer">
														<button className="btn btn-orange" onClick={() => this.handleConfimationAction(bottomDepartureFlightDetails, bottomReturnFlightDetails)}>Yes</button>
														<button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false)}>No</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="popup-overlay" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>)
	}
}

//Bottom for advance search round trip
class BottomAdvanceRoundTripFlightComponent extends React.Component {
	constructor(){
		super();
		this.state = {
			toggleOnMobile : false
		}
	}

	slideOnMobileView = () => {
		if(window.innerWidth < 767){
			this.setState({
				toggleOnMobile : this.state.toggleOnMobile ? false : true
			})
		}
	}

	startBooking = (bottomDepartureFlightDetails,bottomReturnFlightDetails) =>{
		let searchType = '';
		let priceRBD = null;
		if(this.props.searchQuery.journey_type === '5'){
			searchType = 'lcc-special-return';
		}else if(this.props.searchQuery.journey_type === '4'){
			searchType = 'advance-search-round';
			priceRBD = this.props.flightPriceDetail.response;
		}else{
			searchType = 'round-trip';
		}
		let data = [];
		data.push(bottomDepartureFlightDetails[0]);
		data.push(bottomReturnFlightDetails[0]);
		this.props.history.push({
            pathname:"/book-ticket",
            state:{
                searchType : searchType,
                trace_id : this.props.searchResult.trace_id,
				data: data,
				index : 0,
				priceRBD : priceRBD,
			}
		});
	}

	//get flight price details
	getFlightPrice = (bottomDepartureFlightDetails, bottomReturnFlightDetails, selectedSeatInfo) => {
		let outBoundflightSegments = [];
		let inBoundflightSegments = [];
		let flightClassSeat = '';
		bottomDepartureFlightDetails[0].segments[0].map(function (segment, index) {
			selectedSeatInfo.map(function (seatClass, classIndex) {
				if(seatClass.split('-')[0] === bottomDepartureFlightDetails[0].result_index)
				{
					if(seatClass.split('-')[1] === index.toString())
					{
						flightClassSeat = seatClass.split('-')[2];
						let singleSegment = {
							"trip_indicator":segment.trip_indicator,
							"segment_indicator":segment.segment_indicator,
							"airline":{
								"airline_code": segment.airline.airline_code,
								"airline_name": segment.airline.airline_name,
								"flight_number": segment.airline.flight_number,
								"fare_class": flightClassSeat,
								"operating_carrier": segment.airline.operating_carrier,
							}
						}
						outBoundflightSegments.push(singleSegment);
					}
				}
				return true;
			})
			return true;
		})

		bottomReturnFlightDetails[0].segments[0].map(function (segment, index) {
			selectedSeatInfo.map(function (seatClass, classIndex) {
				if(seatClass.split('-')[0] === bottomReturnFlightDetails[0].result_index)
				{
					if(seatClass.split('-')[1] === index.toString())
					{
						flightClassSeat = seatClass.split('-')[2];
						let singleSegment = {
							"trip_indicator":segment.trip_indicator,
							"segment_indicator":segment.segment_indicator,
							"airline":{
								"airline_code": segment.airline.airline_code,
								"airline_name": segment.airline.airline_name,
								"flight_number": segment.airline.flight_number,
								"fare_class": flightClassSeat,
								"operating_carrier": segment.airline.operating_carrier,
							}
						}
						inBoundflightSegments.push(singleSegment);
					}
				}
				return true;
			})
			return true;
		})

		let flightData = {
			"adult_count": this.props.searchQuery.adult_count.toString(),
			"child_count": this.props.searchQuery.child_count.toString(),
			"infant_count": this.props.searchQuery.infant_count.toString(),
			"trace_id": this.props.searchResult.trace_id,
			"segments": this.props.searchQuery.segments,
								"is_domestic" : this.props.searchQuery.is_domestic,
                                "air_search_result":[
                                    {
                                        "result_index":bottomDepartureFlightDetails[0].result_index,
                                        "source":bottomDepartureFlightDetails[0].source,
                                        "is_lcc":bottomDepartureFlightDetails[0].is_lcc,
                                        "is_refundable":bottomDepartureFlightDetails[0].is_refundable,
                                        "airline_remark":bottomDepartureFlightDetails[0].airline_remark,
										"segments":[outBoundflightSegments],
										"fare" : bottomDepartureFlightDetails[0].fare
				},
				{
                                        "result_index":bottomReturnFlightDetails[0].result_index,
                                        "source":bottomReturnFlightDetails[0].source,
                                        "is_lcc":bottomReturnFlightDetails[0].is_lcc,
                                        "is_refundable":bottomReturnFlightDetails[0].is_refundable,
                                        "airline_remark":bottomReturnFlightDetails[0].airline_remark,
										"segments":[inBoundflightSegments],
										"fare" : bottomReturnFlightDetails[0].fare
				}
			]
		}
		this.props.flightPriceDetails(flightData);
		return true;
	}

	render() {
		const {searchQuery, bottomDepartureFlightDetails, bottomReturnFlightDetails, selectedSeatClass} = this.props;
        let totalTraveller = this.props.searchQuery.adult_count+this.props.searchQuery.infant_count+this.props.searchQuery.child_count;
		let notAvailableSeatOutbound = [];
		let notAvailableSeatInbound = [];
		let availableSeatOutbound = [];
		let availableSeatInbound = [];
		let disabledClass = 'disabled';
		let totalSegments = bottomDepartureFlightDetails[0].segments[0].length+bottomReturnFlightDetails[0].segments[0].length;
		selectedSeatClass.map((selectedSeat, index) => {
			if(selectedSeat.split('-')[3] < totalTraveller)
			{
				if(selectedSeat.indexOf('OB') > -1)
				{
					notAvailableSeatOutbound.push(selectedSeat);
				}
				else if(selectedSeat.indexOf('IB') > -1)
				{
					notAvailableSeatInbound.push(selectedSeat);
				}
			}
			else
			{
				if(selectedSeat.indexOf('OB') > -1)
				{
					availableSeatOutbound.push(selectedSeat);
				}
				else if(selectedSeat.indexOf('IB') > -1)
				{
					availableSeatInbound.push(selectedSeat);
				}
			}
			return true;
		})

		if(totalSegments === (availableSeatOutbound.length + availableSeatInbound.length))
		{
			disabledClass = '';
		}

		return (
			<React.Fragment>
				<div className="selection-box advance-search-bottom">
					<div className="selection-outer">
						<div className="top-trigger" onClick={() => this.slideOnMobileView()}><i className="icon icon-caret-bottom"></i></div>
						<div className="container">
							<div className="flight-booking">
								<div className="cols" style={{ display: this.state.toggleOnMobile ? 'none' : 'block' }}>
									<div className={notAvailableSeatOutbound.length > 0 ? 'booking-info red-border' : 'booking-info'}>
										<div className="booking-title">
											<h3>{searchQuery.segments[0].originCityName + ' To ' + searchQuery.segments[0].destinationCityName}</h3>
											<span>{dateFormat(searchQuery.segments[0].preferred_departure_time, 'DD MMM YYYY, FDAY')}</span>
										</div>
										<div className="booking-airline">
											<div className="airline-col">
												<figure>{bottomDepartureFlightDetails.length > 0 ? <img src={bottomDepartureFlightDetails[0].segments[0][0].airline.flight_logo} alt="" /> : ''}</figure>
												<div className="flight-name">
													<span>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getCombinedAirlineName(bottomDepartureFlightDetails[0].segments[0]) : '-'}</span>
													<p>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getCombinedAirlineCode(bottomDepartureFlightDetails[0].segments[0]) : '-'}</p>
												</div>
											</div>
											<div className="time-date-col">
												<div className="col1">
													<div className="time-date">
														<strong>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getDepartureTime(bottomDepartureFlightDetails[0].segments[0][0].origin.dep_time) : '-'}</strong>
														<span>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getDepartureDate(bottomDepartureFlightDetails[0].segments[0][0].origin.dep_time, 'DD MMM') : '-'}</span>
													</div>
												</div>
												<div className="col1 col-direction">
													<div className="flight-direction"><div className="flight-bar"><i className="icon icon-flight3"></i></div></div>
												</div>
												<div className="col1">
													<div className="time-date">
														<strong>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getArivalTime(bottomDepartureFlightDetails[0].segments[0]) : '-'}</strong>
														<span>{bottomDepartureFlightDetails.length > 0 ? flightHelper.getArivalDate(bottomDepartureFlightDetails[0].segments[0]) : '-'}</span>
													</div>
												</div>
											</div>
											<div className="amount-col">
											</div>
										</div>
									</div>
								</div>
								<div className="cols" style={{ display: this.state.toggleOnMobile ? 'none' : 'block' }}>
									<div className={notAvailableSeatInbound.length > 0 ? 'booking-info red-border' : 'booking-info'}>
										<div className="booking-title">
											<h3>{searchQuery.segments[1].originCityName + ' To ' + searchQuery.segments[1].destinationCityName}</h3>
											<span>{dateFormat(searchQuery.segments[1].preferred_departure_time, 'DD MMM YYYY, FDAY')}</span></div>
										<div className="booking-airline">
											<div className="airline-col">
												<figure>{bottomReturnFlightDetails.length > 0 ? <img src={bottomReturnFlightDetails[0].segments[0][0].airline.flight_logo} alt="" /> : ''}</figure>
												<div className="flight-name">
													<span>{bottomReturnFlightDetails.length > 0 ? flightHelper.getCombinedAirlineName(bottomReturnFlightDetails[0].segments[0]) : '-'}</span>
													<p>{bottomReturnFlightDetails.length > 0 ? flightHelper.getCombinedAirlineCode(bottomReturnFlightDetails[0].segments[0]) : '-'}</p>
												</div>
											</div>
											<div className="time-date-col">
												<div className="col1">
													<div className="time-date">
														<strong>{bottomReturnFlightDetails.length > 0 ? flightHelper.getDepartureTime(bottomReturnFlightDetails[0].segments[0][0].origin.dep_time) : '-'}</strong>
														<span>{bottomReturnFlightDetails.length > 0 ? flightHelper.getDepartureDate(bottomReturnFlightDetails[0].segments[0][0].origin.dep_time, 'DD MMM') : '-'}</span>
													</div>
												</div>
												<div className="col1 col-direction">
													<div className="flight-direction"><div className="flight-bar"><i className="icon icon-flight3"></i></div></div>
												</div>
												<div className="col1">
													<div className="time-date">
														<strong>{bottomReturnFlightDetails.length > 0 ? flightHelper.getArivalTime(bottomReturnFlightDetails[0].segments[0]) : '-'}</strong>
														<span>{bottomReturnFlightDetails.length > 0 ? flightHelper.getArivalDate(bottomReturnFlightDetails[0].segments[0]) : '-'}</span>
													</div>
												</div>
											</div>
											<div className="amount-col">
											</div>
										</div>
									</div>
								</div>
								<div className="cols total-fare-amount advance-search-bottom">
								{  this.props.flightPriceDetail.response && this.props.flightPriceDetail.loading === false?
										<div className="total-fare">
											<div className="booking-title">
												<span>Total Fare:</span>
												<div className="title-label">
													<ul className="list-icon">
														{/* Fare Rule Icon  --------------------------------------------------------------------- Start here */}
														<li title="Fare Rules">
															{ /* eslint-disable-next-line */}
														<a href="javascript:void(0)" onClick={(event) => this.props.showFareDetailsPopup(event,this.props.flightPriceDetail.response.results[0][0].result_index,this.props.flightPriceDetail.response.trace_id)}>
																<i className="fa icon-edit-right" aria-hidden="true"></i>
															</a>
														</li>
														{/* Is Refundable Icon  --------------------------------------------------------------------- Start here */}
														{
															this.props.flightPriceDetail.response.results[0][0].is_refundable
																?
																<li title="Refundable">
																	{ /* eslint-disable-next-line */}
																	<a href="javascript:void(0)">
																		<i className="fa icon icon-reg" aria-hidden="true"></i>
																	</a>
																</li>
																:
																""
														}
													</ul>
												</div>
											</div>

											<div className="block">
												<div className="fare-left">
													<strong><i className="fa fa-inr"></i>
														{currencyFormat(this.props.flightPriceDetail.response.results[0][0].fare.published_fare)}
													</strong>
													<div className="tooltip-amount">{ /* eslint-disable-next-line */}
														<a className="tooltip-link" href="javascript:void(0);">Fare Breakup</a>
														<div className="amount-detail">
															<div className="amount-detail">
																<ul>
																	<li>Base Fare <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(this.props.flightPriceDetail.response.results[0][0].fare.base_fare)}</span></li>
																	{
																		this.props.flightPriceDetail.response.results[0][0].fare.other_charges !== 0
																	?<li>Other Charges <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(this.props.flightPriceDetail.response.results[0][0].fare.other_charges)}</span></li>
																	:''
																	}

																	{
																		this.props.flightPriceDetail.response.results[0][0].fare.tax !== 0
																	?<li>Tax <span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(this.props.flightPriceDetail.response.results[0][0].fare.tax)}</span></li>
																	:''
																	}

																	{
																		this.props.flightPriceDetail.response.results[0][0].fare.yq_tax !== 0
																	?<li>YQ Tax (Included)<span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(this.props.flightPriceDetail.response.results[0][0].fare.yq_tax)}</span></li>
																	:''
																	}

																	{
																		this.props.flightPriceDetail.response.results[0][0].fare.other_tax !== 0
																	?<li>Other Tax<span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(this.props.flightPriceDetail.response.results[0][0].fare.other_tax)}</span></li>
																	:''
																	}

																	{
																		this.props.flightPriceDetail.response.results[0][0].fare.service_charge !== 0
																	?<li>Service Charge<span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(this.props.flightPriceDetail.response.results[0][0].fare.service_charge)}</span></li>
																	:''
																	}

																	{/* {
																	this.props.flightPriceDetail.response.results[0][0].fare.Discount+this.props.flightPriceDetail.response.results[0][0].fare.agent_discount !== 0 
																	?<li>Discount<span><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(this.props.flightPriceDetail.response.results[0][0].fare.Discount+this.props.flightPriceDetail.response.results[0][0].fare.agent_discount)}</span></li>
																	:''
																} */}

																	<li className="last-amount-total"> <span><small>Total:</small><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(this.props.flightPriceDetail.response.results[0][0].fare.published_fare)}</span></li>
																</ul>
															</div>
														</div>
													</div>
												</div>
												<div className="boox-btn">
													<span className="btn btn-primary"
												onClick={() => this.startBooking(bottomDepartureFlightDetails,bottomReturnFlightDetails)}
													>Book Now</span>
												</div>
											</div>

										</div>
										:
										<React.Fragment>
											{
											((notAvailableSeatOutbound.length > 0 || notAvailableSeatInbound.length > 0) || (availableSeatOutbound.length+availableSeatInbound.length !== totalSegments)) &&
												<div className="seat-error-msg">
													<span className="red-text">Please select available seats class from flight detail</span>
												</div>
											}
											{
												this.props.flightPriceDetail.error &&
												<div className="seat-error-msg">
													<span className="red-text">{this.props.flightPriceDetail.error}</span>
												</div>
											}
											<div className="flight-price loading-btn">
												<span
											className={'btn btn-yellow anchor '+disabledClass}
													onClick={(event) => this.getFlightPrice(bottomDepartureFlightDetails, bottomReturnFlightDetails, selectedSeatClass)}
												>
													{
												this.props.flightPriceDetail.loading === true?
															<React.Fragment>
																Price This Flight
													<div className="flight-animated">
														<label><span><small></small><small></small><small></small></span><img src="/assets/images/flight-continue.svg" alt=""/></label>
																</div>
															</React.Fragment>
															:
															'Price This Flight'
													}
												</span>
											</div>
										</React.Fragment>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>)
	}
}

export default FlightSearchDetailsComponent