import LOGIN_API from '../../api/shared/index';
import { getProductAccess} from './sharedAction';

export const SESSION_LOGIN_START = 'SESSION_LOGIN_START';
const startLogin = () => {
    return {
        type: SESSION_LOGIN_START
    };
};

export const SESSION_LOGIN_OK = 'SESSION_LOGIN_OK';
const loginSuccess = (token) => {
    return {
        type: SESSION_LOGIN_OK,
        token
    }
};

export const SESSION_LOGIN_ERR = 'SESSION_LOGIN_ERR';
const loginError = (status, message, errors) => {
    return {
        type: SESSION_LOGIN_ERR,
        status,
        message,
        errors
    }
};

export const SESSION_LOGOUT = 'SESSION_LOGOUT';
const logoutSession = () => {
    return {
        type: SESSION_LOGOUT
    }
};

export function loginAction (credentials, successCb, errorCb) {
    return (dispatch, getState) => {

        dispatch(startLogin());

        return LOGIN_API.login(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(loginSuccess(payload.token));
                    /* save our new jwt token for future requests */
                    LOGIN_API.setToken(payload.token);
   
                    localStorage.setItem('token', payload.token);
                    localStorage.setItem('refresh_token', payload.refresh_token);
                    localStorage.setItem('login', true);
                    localStorage.setItem('showWarning', 'show');
                    localStorage.setItem('is_kafila_user', payload.is_kafila_user);

                    if(localStorage.getItem('token').length > 0 && localStorage.getItem('login') === 'true') {
                        dispatch(getProductAccess('', successCb,payload));
                    }
                } else {
                    dispatch(loginError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message);
                }
            });
    }
}

//For logout
export function logoutAction () {
    return (dispatch) => {
        return LOGIN_API.logout()
            .then(() => {
                localStorage.setItem('login',false);
                localStorage.setItem('verify-otp',false);
                localStorage.setItem('assigned-product','');
                window.location.href = '/login';
                dispatch(logoutSession());
            })
            .catch((error) => {

            })
            .then(() => {
                localStorage.setItem('token', '');
                localStorage.setItem('refresh_token', '');
                localStorage.setItem('is_kafila_user', '');
            });
    }
}


export const GENERATE_TOKEN_START = 'GENERATE_TOKEN_START';
const startGenerateToken = () => {
    return {
        type: GENERATE_TOKEN_START
    };
};

export const GENERATE_TOKEN_OK = 'GENERATE_TOKEN_OK';
const generateTokenSuccess = (token) => {
    return {
        type: GENERATE_TOKEN_OK,
        token
    }
};

export const GENERATE_TOKEN_ERR = 'GENERATE_TOKEN_ERR';
const generateTokenError = (status, message, errors) => {
    return {
        type: GENERATE_TOKEN_ERR,
        status,
        message,
        errors
    }
};

export function generateTokenAction (credentials, successCb, errorCb) {
    return (dispatch, getState) => {

        dispatch(startGenerateToken());

        return LOGIN_API.generateToken(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(generateTokenSuccess(payload.token));
                    /* save our new jwt token for future requests */
                    LOGIN_API.setToken(payload.token);
   
                    localStorage.setItem('token', payload.token);
                    localStorage.setItem('refresh_token', payload.refresh_token);
                    successCb && successCb(payload);
                } else {
                    dispatch(generateTokenError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message);
                }
            });
    }
}

export const VERIFY_LOGIN_START = 'VERIFY_LOGIN_START';
const startVerifyLogin = () => {
    return {
        type: VERIFY_LOGIN_START
    };
};

export const VERIFY_LOGIN_OK = 'VERIFY_LOGIN_OK';
const loginVerifySuccess = (token) => {
    return {
        type: VERIFY_LOGIN_OK,
        token
    }
};

export const VERIFY_LOGIN_ERR = 'VERIFY_LOGIN_ERR';
const loginVerifyError = (status, message, errors) => {
    return {
        type: VERIFY_LOGIN_ERR,
        status,
        message,
        errors
    }
};

export function loginVerifyAction (credentials, successCb, errorCb) {
    return (dispatch, getState) => {

        dispatch(startVerifyLogin());

        return LOGIN_API.verifyOtp(credentials)
            .then((payload) => {
                if (payload.status === 'ok') {
                    /* fire off our success message */
                    dispatch(loginVerifySuccess(payload.token));
                    /* save our new jwt token for future requests */
                    LOGIN_API.setToken(payload.token);
   
                    localStorage.setItem('token', payload.token);
                    localStorage.setItem('refresh_token', payload.refresh_token);
                    localStorage.setItem('verify-otp', true);
                    localStorage.setItem('showWarning', 'show');
                    localStorage.setItem('is_kafila_user', payload.is_kafila_user);

                    if(localStorage.getItem('token').length > 0 && localStorage.getItem('verify-otp') === 'true') {
                        dispatch(getProductAccess('', successCb,payload));
                    }
                } else {
                    dispatch(loginVerifyError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message);
                }
            });
    }
}