import React, { Component } from 'react';
import history from "../../../shared/history";
import { SingleDatePicker } from 'react-dates'
import moment from 'moment';
import { dateFormat, getCurrentDate } from '../../../shared/commonHelper';
import Select from "react-virtualized-select";
import { Field } from 'formik';
import Pagination from '../../shared/Pagination';
import FlightCancellationComponent from './FlightCancellationComponent';
import { Alert } from 'reactstrap';

class FlightRequestCancellation extends Component {
    constructor() {
        super();
        this.state = {
            hideMsg: false,
            leftNavActive: '/my-bookings',
            url: history.location.pathname,
            date: moment(),
            focused: false,
            calendarFocused: false,
            createdAt: moment(),
            selectedAirlines: 'Any',
            originCityArray: [],
            destinationCityArray: [],
            showCreditNotePopup: null,
            showCancelPopup: false,
            cancelPopupValue: null,
            isCollapse:false,
            collapseIndex:null
        }
   }
   
    onDateChange = (createdAt) => {
        this.setState(() => ({ createdAt }));
    }
    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused }))
    }
    changeDateTimeFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }
    changeDateFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }
    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }


    /*************************** Set Origin City Name ***************************/
    setOriginCityName = (selectedOriginCity, setFieldValue) => {
        setFieldValue('originCityFullName', selectedOriginCity.label);
        setFieldValue('originCityName', selectedOriginCity.cityName);
        setFieldValue('origin', selectedOriginCity.value);
        this.setState({ selectedOriginCity });
    }

    setDestinationCityName = (selectedDestinationCity, setFieldValue) => {
        setFieldValue('destinationCityFullName', selectedDestinationCity.label);
        setFieldValue('destinationCityName', selectedDestinationCity.cityName);
        setFieldValue('destination', selectedDestinationCity.value);
        this.setState({ selectedDestinationCity });
    }

    enterPressed = (handleSubmit, event) => { //event is not passed from methd calling as its received by default when it was binded to our context
        var code = event.keyCode || event.which;
        if (code === 13) {
            handleSubmit();
            
        }
       
    }

    printCreditNote = (data, type) => {
        let printCreditNote = {};
        if (type === 'partial') {
            printCreditNote = data;
        } else {
            printCreditNote = {
                "booking_id": parseInt(data.booking_id),
                "credit_note_no": data && data.credit_note,
                "cancel_id": data && data.cancel_id
            }
        }
        localStorage.setItem("print-credit-note-data", JSON.stringify(printCreditNote));
        window.open('/print-credit-note', "_blank");
    }
  


    componentDidMount() {
        // this.resetFormValues(this.props.setFieldValue);
        this.props.getBookingStatus(this.props.location.state.clickedProductCode);
        this.getOriginCitiesByFilter('default');
        this.getDestinationCitiesByFilter('default');
             
    }

    resetFormValues = (setFieldValue) => {
        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        setFieldValue("booking_status", 'all');
        setFieldValue("pnr_no", '');
        setFieldValue("invoice_no", '');
        setFieldValue("booking_transaction_ref", '');
        setFieldValue("passenger_name", '');
        setFieldValue("ticket_no", '');
        setFieldValue("originCityName", '');
        setFieldValue("destinationCityName", '');
        setFieldValue("airlines", 'all');
        this.setState({
            createdAt: moment(),
            date: moment(),
            selectedDestinationCity: null,
            selectedOriginCity: null,
        })
        var limitOffset = "?limit=4&offset=0&booking_status=all&start_date=" + current_date + '&end_date=' + current_date;
        this.props.getMyBookings(limitOffset);
    }


    //*************************************** Get origin city *********************************************/
    getOriginCitiesByFilter(filterString) {
        if (filterString && filterString.length > 1) {
            const successCb = () => {
                let cityArray = [];
                if (this.props.citiesResponse.response) {
                    cityArray = this.props.citiesResponse.response.map(function (cityData, index) {
                        return ({
                            value: cityData.city_code,//set value
                            cityName: cityData.city_name,
                            label: cityData.city_full_name,
                        });
                    })
                }
                this.setState({ originCityArray: cityArray });
            }
            this.props.getCitiesAction(filterString, successCb);
        }
    }
    //************************************** Get destination city ******************************************/
    getDestinationCitiesByFilter(filterString) {
        if (filterString && filterString.length > 1) {
            const successCb = () => {
                let cityArray = [];
                if (this.props.citiesResponse.response) {
                    cityArray = this.props.citiesResponse.response.map(function (cityData, index) {
                        return ({
                            value: cityData.city_code,//set value
                            cityName: cityData.city_name,
                            label: cityData.city_full_name,
                        });
                    })
                }
                this.setState({ destinationCityArray: cityArray });
            }
            this.props.getCitiesAction(filterString, successCb);
        }
    }


    showCancelTicket = (item, popupValue) => {
        this.setState({
            showCancelPopup: popupValue,
            cancelPopupValue: item
        })
    }
    sameFlightAccordian = (index) => {
        if (this.state.isCollapse && this.state.collapseIndex === index) {
            this.setState({ isCollapse: false, collapseIndex: null });
        } else {
            this.setState({ isCollapse: true, collapseIndex: index });
        }
    }

   

    render() {
        const { getRequestCancellationDetails, allAirlinesData, setFieldValue, handleBlur, handleSubmit, handleChange, bookingStatusDetails, perPageLimit, currentPage } = this.props;
        const { selectedOriginCity, selectedDestinationCity, focused, date, originCityArray, destinationCityArray, createdAt } = this.state;
        let diffTime = Math.abs(new Date(createdAt) - new Date(date));
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const current_date = moment().format('YYYY-MM-DD');
        return (
           
            <React.Fragment>
                <div className="profile-detail-info">
                    
                    <div className="edit-profile-bar">
                        <div className="pull-right">
                            { /* eslint-disable-next-line */}
                            <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                        </div>
                    </div>
                    <div className="tabs-container-detail search-transation">
                        <div className="statement-info">
                            <div className="tabs-top">
                                <div className="tabs-title">
                                    <h2><i className="fa fa-exchange"></i><span>REQUEST CANCELLATION</span></h2>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: this.props.location.state.pageDescription }} />
                            </div>
                            <div className="statement-outer statement-transaction">
                                <div className="form-row">
                                    <div className="col-left">
                                        <div className="transtion-table-outer">
                                            <div className="table-transaction">
                                                <ul className="transaction-list">
                                                    {(getRequestCancellationDetails.loading === false) ?
                                                        getRequestCancellationDetails && getRequestCancellationDetails.response  && getRequestCancellationDetails.response.bookings.length > 0 ? getRequestCancellationDetails.response.bookings.map((item, index) => {
                                                            let ticketStatus = item.cancellation_status &&  item.cancellation_status.replaceAll('_', ' ') && item.cancellation_status.replaceAll('_', ' ').toUpperCase();
                                                            let confirmBooking = {
                                                                "transaction_no": item.transaction_no,
                                                                "pnr": item.pnr,
                                                                "booking_status": item.booking_status,
                                                                "booking_type": item.booking_type,
                                                                'booking_id': item.booking_id,
                                                                'kafila_booking_id':item.kafila_booking_id,
                                                                "booking_from": item.booking_from,
                                                                "request_from":"request-cancellation"
                                                            }
                                                            let confirmTripBooking = {	
                                                                "transaction_no": item.tripjack_booking_id,	
                                                                "pnr": item.pnr,	
                                                                "booking_status": item.booking_status,	
                                                                "booking_type": item.booking_type,	
                                                                'booking_id': item.booking_id,	
                                                                'kafila_booking_id':item.kafila_booking_id,
                                                                "booking_from": item.booking_from,
                                                                "request_from":"request-cancellation"
                                                            }
                                                            return (
                                                                <React.Fragment key={index}>
                                                                <li className={this.state.collapseIndex === index ? 'active' : ""}>
                                                                    <div className="col-transaction">
                                                                        <div className="col-table col-thead">
                                                                            <div className="cols col-name"><span>{item.passengers[0].first_name + ' ' + item.passengers[0].last_name}</span></div>
                                                                            <div className="cols col-hold">
                                                                                <span className="hold-tk">{ticketStatus}</span>
                                                                                {/* <span className="hold-info"  style={{dispaly:'none'}}>Ticket might have been issued
                                                                    <samp>Please call <a href="#">Helpdesk 01244998999</a></samp>                                      
                                                                    </span> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-table col-tbody">
                                                                            <div className="cols col-name">
                                                                                {
                                                                                    item.passengers.map((data, newKey) => {
                                                                                        return (
                                                                                            <span key={newKey}>{(data.pax_title ? data.pax_title : '') + ' ' + data.first_name + ' ' + data.last_name}</span>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <div className="cols">
                                                                                <div className="ticket-info">
                                                                                    {/* flight_no node required */}
                                                                                    <span>{item.segments[0][0].airline_code} | {item.segments[0][0].flight_no} | {dateFormat(item.segments[0][0].departure_time, 'DD MMM YYYY')}</span>
                                                                                    {
                                                                                        item.segments.map((segment, segmentKey) => {
                                                                                            return <span className="text-bold" key={segmentKey}>
                                                                                                {segment.map((newSegment, newSegmentKey) => {
                                                                                                    let destination = newSegmentKey !== 0 ? segment[newSegmentKey - 1].destination_code : '';
                                                                                                    if (destination === newSegment.origin_code)
                                                                                                        return (
                                                                                                            <React.Fragment key={newSegmentKey}>
                                                                                                                -{newSegment.destination_code}
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    else
                                                                                                        return (
                                                                                                            <React.Fragment key={newSegmentKey}>
                                                                                                                {newSegment.origin_code}-{newSegment.destination_code}
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                })}</span>
                                                                                        })
                                                                                    }
                                                                                    {

                                                                                    }
                                                                                </div>
                                                                                <div className="col-bottom">
                                                                                    <div className="book-datail"> <span>Booked On:</span> <small>{dateFormat(item.booking_date, 'FDAY, DD MMM YYYY')}</small></div>
                                                                                    {/* <div className="book-datail"> <span>Request On:</span> <small>{dateFormat(item.request_date, 'FDAY, DD MMM YYYY')}</small></div> */}
                                                                                    {/* <div className="book-datail"><span>Last Ticketing Date:</span> <small>{dateFormat(item.segments[0][0].departure_time, 'FDAY, DD MMM YYYY')}</small></div> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="cols col-pnr">
                                                                                <div className="inner-pnr">
                                                                                    {
                                                                                        (item.pnr !== "null" && item.pnr !== "" ) ?
                                                                                            (<div className="col-info">
                                                                                                <span className="text-bold">PNR:</span><small>{item.pnr}</small>
                                                                                            </div>) : ""
                                                                                    }
                                                                                    <div className="col-info"><span className="text-bold">Reference No:</span> <small>{item.reference_number}</small></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                        {item.status && item.status.length && <div className="steps-block">

                                                                            <div className="prize-progress">
                                                                                <div className="prize-progress-inner">

                                                                                    <div className="progress">
                                                                                        <div className="progress-bar" style={{ '--progressBar': item.status && item.status.length === 1 ? '0%' : item.status && item.status.length > 1 && item.status[item.status.length - 1] && (item.status[item.status.length - 1].cancellation_status === 'Rejected' || item.status[item.status.length - 1].cancellation_status === 'Refunded') ? "100%" : '68%' }}></div>
                                                                                    </div>
                                                                                    <div className={`progress-label-block steps-${(item.status && item.status.length === 1) ? '3' :
                                                                                        (item.status && item.status.length > 1 && item.status[item.status.length - 1] &&
                                                                                            (item.status[item.status.length - 1].cancellation_status === 'Rejected' ||
                                                                                                (item.status && item.status.length && item.status[item.status.length - 1].cancellation_status === 'Refunded'))) ?
                                                                                            item.status && item.status.length : item.status && item.status.length + 1}`}>

                                                                                        {
                                                                                            item.status && item.status.length &&
                                                                                            item.status.map((cancel_status, id) => {
                                                                                                return <div className="progress-label completed" key={id}>
                                                                                                    <div className="prog-label">
                                                                                                        <span className="label-line"></span>
                                                                                                        <div className="steps-label">
                                                                                                            <span className="steps-text">
                                                                                                                <div className="steps-text-inner"> <div class="step-heading">{cancel_status.cancellation_status}<div className='label-tooltip'><i className="fa fa-exclamation-circle"></i><div className="info-tooltip"><div className="tooltip-outer"><p>{cancel_status.remark}</p></div></div></div></div><small>{moment(cancel_status.date_added).format('HH:mm, DD - MMM')}</small></div>
                                                                                                            </span></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            })
                                                                                        }
                                                                                        {item.status && item.status.length === 1 && <div className="progress-label">
                                                                                            <div className="prog-label">
                                                                                                <span className="label-line"></span>
                                                                                                <div className="steps-label">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>}
                                                                                        {item.status && item.status.length === 1 && <div className="progress-label">
                                                                                            <div className="prog-label">
                                                                                                <span className="label-line"></span>
                                                                                                <div className="steps-label">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>}

                                                                                        {(item.status && item.status.length > 1 && item.status[item.status.length - 1] && (item.status[item.status.length - 1].cancellation_status !== 'Rejected' && item.status[item.status.length - 1].cancellation_status !== 'Refunded')) &&
                                                                                            <div className="progress-label">
                                                                                                <div className="prog-label">
                                                                                                    <span className="label-line"></span>
                                                                                                    <div className="steps-label">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                       
                                                                    {
                                                                            <div className='remark-flex remark-block'>
                                                                        <div className="remark-left remarks-col"><b>Remark : </b>{item.status && item.status.length  ? item.status[item.status.length-1].remark : '-'}</div>
                                                                        <div className="remark-right remarks-col"><b>Requested By : </b>{item.requested_by}</div>
                                                                        </div>
                                                                    }
                                                                    <div className="foot-block ms-flex-end">
                                                                        {/* <span className="question-tag"><i className="icon icon-help-round-button"></i></span>  */}
                                                                        {
                                                                            ((item.cancellation_status === 'cancel_confirmed' || item.cancellation_status === 'partial_cancel_confirmed') && item.credit_note ) &&
                                                                            <React.Fragment>
                                                                                <button className="btn btn-yellow" onClick={() => this.printCreditNote(item)}><i className="fa fa-print" aria-hidden="true"></i> Credit Note</button>
                                                                            </React.Fragment>
                                                                        }

                                                                        {
                                                                            item.booking_from ===  "tripjack" &&
                                                                            <button className="btn btn-green" onClick={() => {
                                                                                this.props.history.push({
                                                                                    pathname: "/booking",
                                                                                    state: {
                                                                                        data: confirmTripBooking,
                                                                                        pageUrl: 'request-bookings',
    
                                                                                    }
                                                                                });
                                                                            }}>Open</button>
                                                                        }
                                                                        {
                                                                            item.booking_from !==  "tripjack" &&
                                                                            <button className="btn btn-green" onClick={() => {
                                                                                this.props.history.push({
                                                                                    pathname: "/booking",
                                                                                    state: {
                                                                                        data: confirmBooking,
                                                                                        pageUrl: 'request-bookings',
    
                                                                                    }
                                                                                });
                                                                            }}>Open</button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        <div className="same-flight-widget">
                                                                        {/* eslint-disable-next-line */}
                                                                        <a href="javascript:void(0);" onClick={()=>this.sameFlightAccordian(index)}  className={this.state.collapseIndex !== null && this.state.collapseIndex === index ? "same-flight-btn active" : "same-flight-btn"}><span> More Details</span><i className="icon icon icon-arrow-down"></i></a>
                                                                    </div>
                                                                    }
                                                                    
                                                                {
                                                                            <div className="block-accordion">
                                                                                {
                                                                                    item.segments && item.segments.length && item.segments.map((value,outerMostIndex) => {
                                                                                        if(item.booking_from === 'kafila' || item.booking_from === 'tripjack' || item.booking_from === 'tbo'){
                                                                                        let origin_code = value && value[0].origin_code;
                                                                                        let destination_code = value && value[value.length - 1].destination_code;
                                                                                        //===============================PARTIAL CANCELLATION START====KAFILA/TRIPJACK==============================
                                                                                        if (item.partial_cancellation_details && item.partial_cancellation_details.length && (item.booking_from === 'kafila' || item.booking_from === 'tripjack')) {
                                                                                            if (origin_code === destination_code) {
                                                                                                if(value && value.length === 2){
                                                                                                    let origin_codeR = value && value[value.length - 1].destination_code;
                                                                                                    let destination_codeR = value && value[0].origin_code;
                                                                                                    return value.length && value.map((itemInner,outerIndex) => {
                                                                                                      return  item.partial_cancellation_details && item.partial_cancellation_details.map((part,innerIndex)=>{
                                                                                                            if (origin_code === destination_code && origin_codeR === destination_codeR && part.origin === itemInner.origin_code) {
                                                                                                                let destination_codeInner = itemInner.destination_code
                                                                                                                let origin_codeInner = itemInner.origin_code
                                                                                                                        return <div className='top-flight-box' key={innerIndex}>
                                                                                                                            <div className='flight-box'>
                                                                                                                                <div className='flight-box-inner'>
                                                                                                                                    <div className="flb-flight-name">
                                                                                                                                        <div className='flb-name'>{itemInner.airline_name}</div>
                                                                                                                                        <div className='flb-code'>{`${itemInner.airline_code}-${itemInner.flight_no}`}</div>
                                                                                                                                    </div>
                                                                                                                                    <div className='flight-box-right'>
                                                                                                                                    <div className='flight-box-right-inner'>
                                                                                                                                        <div className="flight-segment-ac flight-segment-left">
                                                                                                                                            <div className='segment-from'>{origin_codeInner}</div>
                                                                                                                                            <div className='segment-from-time'>{moment(itemInner.departure_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                                        </div>
                                                                                                                                        <div className='flight-segment-way'>
                                                                                                                                        <small className="dot"></small>
                                                                                                                                        <small className="dot"></small>
                                                                                                                                            <span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span>
                                                                                                                                        </div>
                                                                                                                                        <div className="flight-segment-ac flight-segment-right">
                                                                                                                                            <div className='segment-to'>{destination_codeInner}</div>
                                                                                                                                            <div className='segment-to-time'>{moment(itemInner.arrival_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className='segment-box'>
                                                                                                                                <div className='segment-row'> 
                                                                                                                                {
                                                                                                                                             part.passengers.map((passenger,ind)=>{
                                                                                                                                                return (
                                                                                                                                                    <React.Fragment key={ind}>
                                                                                                                                                    <div className="segment-col">
                                                                                                                                                        <div className="segment-col-inner">
                                                                                                                                                            <div className='segment-name'>
                                                                                                                                                                {passenger.first_name} {passenger.last_name}
                                                                                                                                                            </div>
                                                                                                                                                            <div className='segment-status' style={{
                                                                                                                                                              color :  (item.cancellation_status === 'cancel_pending' || item.cancellation_status === 'partial_cancel_pending') ? 'yellow' :
                                                                                                                                                              (item.cancellation_status === 'cancel_confirmed' || item.cancellation_status === 'partila_cancel_confirmed') ? 'green' :
                                                                                                                                                              (item.cancellation_status === 'cancel_rejected' || item.cancellation_status === 'rejected') ? "red" :
                                                                                                                                                              item.cancellation_status === 'cancel_acknowledged' ? 'tomato' : ""
                                                                                                                                                                }}>
                                                                                                                                                                {item.cancellation_status && item.cancellation_status.replaceAll('_'," ")}
                                                                                                                                                            </div>             
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    </React.Fragment>
                                                                                                                                                );
                                                                                                                                            })
                                                                                                                                       
                                                                                                                                }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                            }
                                                                                                        })
                                                                                                    })
                                                                                                }
                                                                                                if(value && value.length > 2){
                                                                                                    let origin_codeR = value && value[value.length - 1].destination_code;
                                                                                                    let destination_codeR = value && value[0].origin_code;
                                                                                                    let origin_destination = item.destination_code;
                                                                                                    var index = value.findIndex(segment => segment.origin_code === origin_destination);
                                                                                                    let ArrivalTime = value &&  value[index-1] && value[index-1].departure_time;
                                                                                                    let last_arrival_time = value &&  value[index-1] && value[index-1].arrival_time;
                                                                                                    return value.length && value.map((itemInner,index) => {
                                                                                                      return  item.partial_cancellation_details && item.partial_cancellation_details.map((part,innerIndex)=>{
                                                                                                        let destinationTime="";
                                                                                                        if(origin_code===part.destination){
                                                                                                            destinationTime=value[value.length-1].arrival_time
                                                                                                            
                                                                                                        }else{
                                                                                                            destinationTime=last_arrival_time
                                                                                                            ArrivalTime=value && value[0].departure_time
                                                                                                        }
                                                                                                            if (origin_code === destination_code && origin_codeR === destination_codeR && part.origin === itemInner.origin_code) {
                                                                                                                        return <div className='top-flight-box' key={innerIndex}>
                                                                                                                            <div className='flight-box'>
                                                                                                                                <div className='flight-box-inner'>
                                                                                                                                    <div className="flb-flight-name">
                                                                                                                                        <div className='flb-name'>{itemInner.airline_name}</div>
                                                                                                                                        <div className='flb-code'>{`${itemInner.airline_code}-${itemInner.flight_no}`}</div>
                                                                                                                                    </div>
                                                                                                                                    <div className='flight-box-right'>
                                                                                                                                    <div className='flight-box-right-inner'>
                                                                                                                                        <div className="flight-segment-ac flight-segment-left">
                                                                                                                                            <div className='segment-from'>{part.origin}</div>
                                                                                                                                            <div className='segment-from-time'>{moment(part.travel_date_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                                        </div>
                                                                                                                                        <div className='flight-segment-way'>
                                                                                                                                        <small className="dot"></small>
                                                                                                                                        <small className="dot"></small>
                                                                                                                                            <span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span>
                                                                                                                                        </div>
                                                                                                                                        <div className="flight-segment-ac flight-segment-right">
                                                                                                                                            <div className='segment-to'>{part.destination}</div>
                                                                                                                                            <div className='segment-to-time'>{moment(destinationTime).format('HH:mm, DD - MMM')}</div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className='segment-box'>
                                                                                                                                <div className='segment-row'> 
                                                                                                                                {
                                                                                                                                         part.passengers.map((passenger,ind)=>{
                                                                                                                                            return (
                                                                                                                                                <React.Fragment key={ind}>
                                                                                                                                                <div className="segment-col">
                                                                                                                                                    <div className="segment-col-inner">
                                                                                                                                                        <div className='segment-name'>
                                                                                                                                                            {passenger.first_name} {passenger.last_name}
                                                                                                                                                        </div>
                                                                                                                                                        <div className='segment-status' style={{
                                                                                                                                                          color :  (item.cancellation_status === 'cancel_pending' || item.cancellation_status === 'partial_cancel_pending') ? 'yellow' :
                                                                                                                                                          (item.cancellation_status === 'cancel_confirmed' || item.cancellation_status === 'partila_cancel_confirmed') ? 'green' :
                                                                                                                                                          (item.cancellation_status === 'cancel_rejected' || item.cancellation_status === 'rejected') ? "red" :
                                                                                                                                                          item.cancellation_status === 'cancel_acknowledged' ? 'tomato' : ""
                                                                                                                                                            }}>
                                                                                                                                                            {item.cancellation_status && item.cancellation_status.replaceAll('_'," ")}
                                                                                                                                                        </div>             
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                </React.Fragment>
                                                                                                                                            );
                                                                                                                                        })
                                                                                                                                }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                            }
                                                                                                        })
                                                                                                    })
                                                                                                }
                                                                                                    
                                                                                            }
                                                                                            else {
                                                                                                return <div className='top-flight-box' key={1}>
                                                                                                <div className='flight-box'>
                                                                                                    <div className='flight-box-inner'>
                                                                                                        <div className="flb-flight-name">
                                                                                                            <div className='flb-name'>{value && value[0].airline_name}</div>
                                                                                                            <div className='flb-code'>{`${value && value[0].airline_code}-${value && value[0].flight_no}`}</div>
                                                                                                        </div>
                                                                                                        <div className='flight-box-right'>
                                                                                                        <div className='flight-box-right-inner'>
                                                                                                            <div className="flight-segment-ac flight-segment-left">
                                                                                                                <div className='segment-from'>{origin_code}</div>
                                                                                                                <div className='segment-from-time'>{moment(value && value[0].departure_time).format('HH:mm, DD - MMM')}</div>
                                                                                                            </div>
                                                                                                            <div className='flight-segment-way'>
                                                                                                            <small className="dot"></small>
                                                                                                            <small className="dot"></small>
                                                                                                                <span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span>
                                                                                                            </div>
                                                                                                            <div className="flight-segment-ac flight-segment-right">
                                                                                                                <div className='segment-to'>{destination_code}</div>
                                                                                                                <div className='segment-to-time'>{moment(value && value[value.length - 1].arrival_time).format('HH:mm, DD - MMM')}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='segment-box'>
                                                                                                    <div className='segment-row'> 
                                                                                                    {
                                                                                                        item.partial_cancellation_details && item.partial_cancellation_details.length && item.partial_cancellation_details.map((partialValue)=>{
                                                                                                            return partialValue.passengers.map((passenger,ind)=>{
                                                                                                                return (
                                                                                                                    <React.Fragment key={ind}>
                                                                                                                    <div className="segment-col">
                                                                                                                        <div className="segment-col-inner">
                                                                                                                            <div className='segment-name'>
                                                                                                                                {passenger.first_name} {passenger.last_name}
                                                                                                                            </div>
                                                                                                                                <div className='segment-status' style={{
                                                                                                                                    color: (item.cancellation_status === 'cancel_pending' || item.cancellation_status === 'partial_cancel_pending') ? 'yellow' :
                                                                                                                                        (item.cancellation_status === 'cancel_confirmed' || item.cancellation_status === 'partila_cancel_confirmed') ? 'green' :
                                                                                                                                        (item.cancellation_status === 'cancel_rejected' || item.cancellation_status === 'rejected') ? "red" :
                                                                                                                                                item.cancellation_status === 'cancel_acknowledged' ? 'tomato' : ""
                                                                                                                                }}>
                                                                                                                                    {item.cancellation_status && item.cancellation_status.replaceAll('_', " ")}
                                                                                                                            </div>             
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    </React.Fragment>
                                                                                                                );
                                                                                                            })
                                                                                                        })
                                                                                                    }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            }
                                                                                        } 
                                                                                        // =============================== PARTIAL CANCELLATION START TBO ======================================
                                                                                        else if (item.partial_cancellation_details && item.partial_cancellation_details.length && item.booking_from === 'tbo') {
                                                                                                return  item.partial_cancellation_details && item.partial_cancellation_details.map((part,key)=>{
                                                                                                return <div className='top-flight-box' key={key}>
                                                                                                <div className='flight-box'>
                                                                                                    <div className='flight-box-inner'>
                                                                                                        <div className="flb-flight-name">
                                                                                                                {
                                                                                                                    value.map((itemInner,index) => {
                                                                                                                        if (itemInner.origin_code === part.origin) {
                                                                                                                            return <React.Fragment key={index}>
                                                                                                                                <div className='flb-name'>{itemInner.airline_name}</div>
                                                                                                                                <div className='flb-code'>{`${itemInner.airline_code}-${itemInner.flight_no}`}</div>
                                                                                                                            </React.Fragment>
                                                                                                                        }
                                                                                                                    })
                                                                                                                }
                                                                                                           
                                                                                                        </div>
                                                                                                            
                                                                                                            <div className='flight-box-right'>
                                                                                                                <div className='flight-box-right-inner'>
                                                                                                                    <div className="flight-segment-ac flight-segment-left">
                                                                                                                        <div className='segment-from'>{part.origin}</div>
                                                                                                                        {
                                                                                                                value.map((itemInner,ind) => {
                                                                                                                    if (itemInner.origin_code === part.origin) {
                                                                                                                        return <React.Fragment key={ind}>
                                                                                                                            <div className='segment-from-time'>{moment(itemInner.departure_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                        </React.Fragment>
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                                       
                                                                                                                    </div>
                                                                                                                    <div className='flight-segment-way'>
                                                                                                                        <small className="dot"></small>
                                                                                                                        <small className="dot"></small>
                                                                                                                        <span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span>
                                                                                                                    </div>
                                                                                                                    <div className="flight-segment-ac flight-segment-right">
                                                                                                                        <div className='segment-to'>{part.destination}</div>
                                                                                                                        {
                                                                                                                            value.map((itemInner,ind) => {
                                                                                                                                if (itemInner.destination_code === part.destination && part.origin === itemInner.origin_code ) {
                                                                                                                                    return <React.Fragment key={ind}>
                                                                                                                                        <div className='segment-to-time'>{moment(itemInner.arrival_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                                    </React.Fragment>
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='segment-box'>
                                                                                                    <div className='segment-row'> 
                                                                                                    {
                                                                                                             part.passengers.map((passenger,ind)=>{
                                                                                                                return (
                                                                                                                    <React.Fragment key={ind}>
                                                                                                                    <div className="segment-col">
                                                                                                                        <div className="segment-col-inner">
                                                                                                                            <div className='segment-name'>
                                                                                                                                {passenger.first_name} {passenger.last_name}
                                                                                                                            </div>
                                                                                                                                <div className='segment-status' style={{
                                                                                                                                    color: (item.cancellation_status === 'cancel_pending' || item.cancellation_status === 'partial_cancel_pending') ? 'yellow' :
                                                                                                                                        (item.cancellation_status === 'cancel_confirmed' || item.cancellation_status === 'partila_cancel_confirmed') ? 'green' :
                                                                                                                                        (item.cancellation_status === 'cancel_rejected' || item.cancellation_status === 'rejected') ? "red" :
                                                                                                                                                item.cancellation_status === 'cancel_acknowledged' ? 'tomato' : ""
                                                                                                                                }}>
                                                                                                                                    {item.cancellation_status && item.cancellation_status.replaceAll('_', " ")}
                                                                                                                            </div>             
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    </React.Fragment>
                                                                                                                );
                                                                                                            })
                                                                                                    }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                                })
                                                                                            // }
                                                                                        //===============================PARTIAL CANCELLATION ENDS==================================
                                                                                        }
                                                                                        // =============================== PARTIAL CANCELLATION END TBO ======================================

                                                                                    //==========================================NORMAL CANCELLATION START ===================================================
                                                                                         else {
                                                                                            if (origin_code === destination_code) {
                                                                                                if (value.length === 2) {
                                                                                                    let origin_codeR = value && value[value.length - 1].destination_code;
                                                                                                    let destination_codeR = value && value[0].origin_code;
                                                                                                    return value.length && value.map((itemInner,ind) => {
                                                                                                        if (origin_code === destination_code && origin_codeR === destination_codeR) {
                                                                                                            let origin_code = itemInner.origin_code
                                                                                                            return <div className='top-flight-box' key={ind}>
                                                                                                            <div className='flight-box'>
                                                                                                                <div className='flight-box-inner'>
                                                                                                                    <div className="flb-flight-name">
                                                                                                                        <div className='flb-name'>{itemInner.airline_name}</div>
                                                                                                                        <div className='flb-code'>{`${itemInner.airline_code}-${itemInner.flight_no}`}</div>
                                                                                                                    </div>
                                                                                                                    <div className='flight-box-right'>
                                                                                                                    <div className='flight-box-right-inner'>
                                                                                                                        <div className="flight-segment-ac flight-segment-left">
                                                                                                                            <div className='segment-from'>{origin_code}</div>
                                                                                                                            <div className='segment-from-time'>{moment(itemInner.departure_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                        </div>
                                                                                                                        <div className='flight-segment-way'>
                                                                                                                        <small className="dot"></small>
                                                                                                                        <small className="dot"></small>
                                                                                                                            <span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span>
                                                                                                                        </div>
                                                                                                                        <div className="flight-segment-ac flight-segment-right">
                                                                                                                            <div className='segment-to'>{origin_codeR}</div>
                                                                                                                            <div className='segment-to-time'>{moment(itemInner.arrival_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='segment-box'>
                                                                                                                <div className='segment-row'> 
                                                                                                                {
                                                                                                                    item.passengers && item.passengers.map((passenger,key)=>{
                                                                                                                            return (
                                                                                                                                <React.Fragment key={key}>
                                                                                                                                <div className="segment-col">
                                                                                                                                    <div className="segment-col-inner">
                                                                                                                                        <div className='segment-name'>
                                                                                                                                            {passenger.first_name} {passenger.last_name}
                                                                                                                                        </div>
                                                                                                                                            <div className='segment-status' style={{
                                                                                                                                                color: (item.cancellation_status === 'cancel_pending' || item.cancellation_status === 'partial_cancel_pending') ? 'yellow' :
                                                                                                                                                    (item.cancellation_status === 'cancel_confirmed' || item.cancellation_status === 'partila_cancel_confirmed') ? 'green' :
                                                                                                                                                    (item.cancellation_status === 'cancel_rejected' || item.cancellation_status === 'rejected') ? "red" :
                                                                                                                                                            item.cancellation_status === 'cancel_acknowledged' ? 'tomato' : ""
                                                                                                                                            }}>
                                                                                                                                                {item.cancellation_status && item.cancellation_status.replaceAll('_', " ")}
                                                                                                                                        </div>             
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                </React.Fragment>
                                                                                                                            );
                                                                                                                    })
                                                                                                                }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        }
                                                                                                    })



                                                                                                }
                                                                                                if(value.length > 2){
                                                                                                    let origin = value && value[0].origin_code;
                                                                                                    let departureTime = value && value[0].departure_time;
                                                                                                    let origin_destination = item.destination_code;
                                                                                                    var index = value.findIndex(segment => segment.origin_code === origin_destination);
                                                                                                    let segFirst = value.filter((seg) => {
                                                                                                        if (seg.destination_code === item.destination_code) {
                                                                                                            return seg
                                                                                                        }
                                                                                                        return null;
                                                                                                    })
                                                                                                    let segSecond = value.filter((seg) => {
                                                                                                        if (seg.origin_code === item.destination_code) {
                                                                                                            return seg
                                                                                                        }
                                                                                                    })
                                                                                                        return (segFirst.length && segSecond.length ? <React.Fragment>
                                                                                                             <div className='top-flight-box'>
                                                                                                            <div className='flight-box'>
                                                                                                                {
                                                                                                                   segFirst && segFirst.length && segFirst.map((segValue,indexx)=>{
                                                                                                                    return <div className='flight-box-inner' key={indexx}>
                                                                                                                    <div className="flb-flight-name">
                                                                                                                        <div className='flb-name'>{value && value[0].airline_name}</div>
                                                                                                                        <div className='flb-code'>{`${value && value[0].airline_code}-${value && value[0].flight_no}`}</div>
                                                                                                                    </div>
                                                                                                                    <div className='flight-box-right'>
                                                                                                                    <div className='flight-box-right-inner'>
                                                                                                                        <div className="flight-segment-ac flight-segment-left">
                                                                                                                            <div className='segment-from'>{origin}</div>
                                                                                                                            <div className='segment-from-time'>{moment(departureTime).format('HH:mm, DD - MMM')}</div>
                                                                                                                        </div>
                                                                                                                        <div className='flight-segment-way'>
                                                                                                                        <small className="dot"></small>
                                                                                                                        <small className="dot"></small>
                                                                                                                            <span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span>
                                                                                                                        </div>
                                                                                                                        <div className="flight-segment-ac flight-segment-right">
                                                                                                                            <div className='segment-to'>{segValue.destination_code}</div>
                                                                                                                            <div className='segment-to-time'>{moment(segValue.arrival_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                   }) 
                                                                                                                }
                                                                                                                
                                                                                                            </div>
                                                                                                            <div className='segment-box'>
                                                                                                                <div className='segment-row'> 
                                                                                                                {
                                                                                                                    item.passengers && item.passengers.map((passenger)=>{
                                                                                                                            return (
                                                                                                                                <React.Fragment key={passenger.pax_id}>
                                                                                                                                <div className="segment-col">
                                                                                                                                    <div className="segment-col-inner">
                                                                                                                                        <div className='segment-name'>
                                                                                                                                            {passenger.first_name} {passenger.last_name}
                                                                                                                                        </div>
                                                                                                                                            <div className='segment-status' style={{
                                                                                                                                                color: (item.cancellation_status === 'cancel_pending' || item.cancellation_status === 'partial_cancel_pending') ? 'yellow' :
                                                                                                                                                    (item.cancellation_status === 'cancel_confirmed' || item.cancellation_status === 'partila_cancel_confirmed') ? 'green' :
                                                                                                                                                    (item.cancellation_status === 'cancel_rejected' || item.cancellation_status === 'rejected') ? "red" :
                                                                                                                                                            item.cancellation_status === 'cancel_acknowledged' ? 'tomato' : ""
                                                                                                                                            }}>
                                                                                                                                            {item.cancellation_status && item.cancellation_status.replaceAll('_'," ")}
                                                                                                                                        </div>             
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                </React.Fragment>
                                                                                                                            );
                                                                                                                    })
                                                                                                                }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='top-flight-box'>
                                                                                                            <div className='flight-box'>
                                                                                                                {
                                                                                                                    segSecond.map((segValue,index)=>{
                                                                                                                        return <div className='flight-box-inner' key={index}>
                                                                                                                        <div className="flb-flight-name">
                                                                                                                            <div className='flb-name'>{segValue.airline_name}</div>
                                                                                                                            <div className='flb-code'>{`${segValue.airline_code}-${segValue.flight_no}`}</div>
                                                                                                                        </div>
                                                                                                                        <div className='flight-box-right'>
                                                                                                                        <div className='flight-box-right-inner'>
                                                                                                                            <div className="flight-segment-ac flight-segment-left">
                                                                                                                                <div className='segment-from'>{segValue.origin_code}</div>
                                                                                                                                <div className='segment-from-time'>{moment(segValue.departure_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                            </div>
                                                                                                                            <div className='flight-segment-way'>
                                                                                                                            <small className="dot"></small>
                                                                                                                            <small className="dot"></small>
                                                                                                                                <span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span>
                                                                                                                            </div>
                                                                                                                            <div className="flight-segment-ac flight-segment-right">
                                                                                                                                <div className='segment-to'>{value && value[value.length-1] && value[value.length-1].destination_code}</div>
                                                                                                                                <div className='segment-to-time'>{moment(value && value[value.length-1] && value[value.length-1].arrival_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    })
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className='segment-box'>
                                                                                                                <div className='segment-row'> 
                                                                                                                {
                                                                                                                    item.passengers && item.passengers.map((passenger)=>{
                                                                                                                            return (
                                                                                                                                <React.Fragment key={passenger.pax_id}>
                                                                                                                                <div className="segment-col">
                                                                                                                                    <div className="segment-col-inner">
                                                                                                                                        <div className='segment-name'>
                                                                                                                                            {passenger.first_name} {passenger.last_name}
                                                                                                                                        </div>
                                                                                                                                            <div className='segment-status' style={{
                                                                                                                                                color: (item.cancellation_status === 'cancel_pending' || item.cancellation_status === 'partial_cancel_pending') ? 'yellow' :
                                                                                                                                                    (item.cancellation_status === 'cancel_confirmed' || item.cancellation_status === 'partila_cancel_confirmed') ? 'green' :
                                                                                                                                                    (item.cancellation_status === 'cancel_rejected' || item.cancellation_status === 'rejected') ? "red" :
                                                                                                                                                            item.cancellation_status === 'cancel_acknowledged' ? 'tomato' : ""
                                                                                                                                            }}>
                                                                                                                                            {item.cancellation_status && item.cancellation_status.replaceAll('_'," ")}
                                                                                                                                        </div>             
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                </React.Fragment>
                                                                                                                            );
                                                                                                                    })
                                                                                                                }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        </React.Fragment>:
                                                                                                        <React.Fragment>
                                                                                                        {
                                                                                                            value && value.length && value.map((itemInner,ind)=>{
                                                                                                               return <div className='top-flight-box' key={ind}>
                                                                                                                <div className='flight-box'>
                                                                                                                    <div className='flight-box-inner'>
                                                                                                                        <div className="flb-flight-name">
                                                                                                                            <div className='flb-name'>{itemInner.airline_name}</div>
                                                                                                                            <div className='flb-code'>{`${itemInner.airline_code}-${itemInner.flight_no}`}</div>
                                                                                                                        </div>
                                                                                                                        <div className='flight-box-right'>
                                                                                                                            <div className='flight-box-right-inner'>
                                                                                                                                <div className="flight-segment-ac flight-segment-left">
                                                                                                                                    <div className='segment-from'>{itemInner.origin_code}</div>
                                                                                                                                    <div className='segment-from-time'>{moment(itemInner.departure_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                                </div>
                                                                                                                                <div className='flight-segment-way'>
                                                                                                                                    <small className="dot"></small>
                                                                                                                                    <small className="dot"></small>
                                                                                                                                    <span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span>
                                                                                                                                </div>
                                                                                                                                <div className="flight-segment-ac flight-segment-right">
                                                                                                                                    <div className='segment-to'>{itemInner.destination_code}</div>
                                                                                                                                    <div className='segment-to-time'>{moment(itemInner.arrival_time).format('HH:mm, DD - MMM')}</div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='segment-box'>
                                                                                                                    <div className='segment-row'>
                                                                                                                        {
                                                                                                                            item.passengers && item.passengers.map((passenger, ind) => {
                                                                                                                                return (
                                                                                                                                    <React.Fragment key={ind}>
                                                                                                                                        <div className="segment-col">
                                                                                                                                            <div className="segment-col-inner">
                                                                                                                                                <div className='segment-name'>
                                                                                                                                                    {passenger.first_name} {passenger.last_name}
                                                                                                                                                </div>
                                                                                                                                                <div className='segment-status' style={{
                                                                                                                                                    color: (item.cancellation_status === 'cancel_pending' || item.cancellation_status === 'partial_cancel_pending') ? 'yellow' :
                                                                                                                                                        (item.cancellation_status === 'cancel_confirmed' || item.cancellation_status === 'partila_cancel_confirmed') ? 'green' :
                                                                                                                                                            (item.cancellation_status === 'cancel_rejected' || item.cancellation_status === 'rejected') ? "red" :
                                                                                                                                                                item.cancellation_status === 'cancel_acknowledged' ? 'tomato' : ""
                                                                                                                                                }}>
                                                                                                                                                    {item.cancellation_status && item.cancellation_status.replaceAll('_', " ")}
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </React.Fragment>
                                                                                                                                );
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            
                                                                                                            })
                                                                                                        }
                                                                                                        </React.Fragment>
                                                                                                            
                                                                                                        )
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                    return <div className='top-flight-box'>
                                                                                                <div className='flight-box'>
                                                                                                    <div className='flight-box-inner'>
                                                                                                        <div className="flb-flight-name">
                                                                                                            <div className='flb-name'>{value && value[0].airline_name}</div>
                                                                                                            <div className='flb-code'>{`${value && value[0].airline_code}-${value && value[0].flight_no}`}</div>
                                                                                                        </div>
                                                                                                        <div className='flight-box-right'>
                                                                                                        <div className='flight-box-right-inner'>
                                                                                                            <div className="flight-segment-ac flight-segment-left">
                                                                                                                <div className='segment-from'>{origin_code}</div>
                                                                                                                <div className='segment-from-time'>{moment(value && value[0].departure_time).format('HH:mm, DD - MMM')}</div>
                                                                                                            </div>
                                                                                                            <div className='flight-segment-way'>
                                                                                                            <small className="dot"></small>
                                                                                                            <small className="dot"></small>
                                                                                                                <span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span>
                                                                                                            </div>
                                                                                                            <div className="flight-segment-ac flight-segment-right">
                                                                                                                <div className='segment-to'>{destination_code}</div>
                                                                                                                <div className='segment-to-time'>{moment(value && value[value.length - 1].arrival_time).format('HH:mm, DD - MMM')}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='segment-box'>
                                                                                                    <div className='segment-row'> 
                                                                                                    {
                                                                                                        item.passengers && item.passengers.map((passenger,ind)=>{
                                                                                                                return (
                                                                                                                    <React.Fragment key={ind}>
                                                                                                                    <div className="segment-col">
                                                                                                                        <div className="segment-col-inner">
                                                                                                                            <div className='segment-name'>
                                                                                                                                {passenger.first_name} {passenger.last_name}
                                                                                                                            </div>
                                                                                                                                <div className='segment-status' style={{
                                                                                                                                    color: (item.cancellation_status === 'cancel_pending' || item.cancellation_status === 'partial_cancel_pending') ? 'yellow' :
                                                                                                                                        (item.cancellation_status === 'cancel_confirmed' || item.cancellation_status === 'partila_cancel_confirmed') ? 'green' :
                                                                                                                                        (item.cancellation_status === 'cancel_rejected' || item.cancellation_status === 'rejected') ? "red" :
                                                                                                                                                item.cancellation_status === 'cancel_acknowledged' ? 'tomato' : ""
                                                                                                                                }}>
                                                                                                                                {item.cancellation_status && item.cancellation_status.replaceAll('_'," ")}
                                                                                                                            </div>             
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    </React.Fragment>
                                                                                                                );
                                                                                                        })
                                                                                                    }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                                }

                                                                                        }
                                                                                    }
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        }
                                                                
                                                                </li>
                                                                
                                                                
                                                                </React.Fragment>
                                                            )

                                                        }) : <div className="no-result-wrap"><div className="result-wrap-outer"><div className="block"><div className="no-result-box"><h2>No results found</h2><p>We can’t find any item matching your search.</p></div></div></div></div>
                                                        : <div className="loader relative-loader" style={{ "display": "block" }}><div className="loader-content"><div className="loader-ring blue-ring"> <span></span> </div></div></div>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-right">
                                        <div className="search-transaction-form form-sec-block">
                                            <h3 className="title-search">Filter</h3>
                                            <div className="search-form">
                                                <form>
                                                    {
                                                       diffDays > 31 ? <Alert color="danger">You can search booking of maximum 31 days.</Alert> : ''
                                                    }
                                                    <div className="form-row">
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">BOOKING STATUS:</label>
                                                                <div className="custom-select-box">
                                                                    <select className="form-control custom-filed" name="booking_status" value={this.props.values.booking_status} onBlur={handleBlur} onChange={handleChange} onKeyPress={this.enterPressed.bind(this, handleSubmit)}>
                                                                        <option value="all">All</option>
                                                                        {
                                                                            bookingStatusDetails.response && bookingStatusDetails.response.booking_status.map((item, key) => {
                                                                                if(item.status_id === 6 || item.status_id === 7 || item.status_id === 6 || item.status_id === 11 || item.status_id === 26){
                                                                                    return (
                                                                                        <option key={key} value={item.status}>{item.status_name}</option>
                                                                                    );
                                                                                }
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">

                                                                <label className="control-label">Start Date :</label>
                                                                <div className="input-group input-addon single-calendar">
                                                                    {
                                                                        <SingleDatePicker
                                                                            date={createdAt}
                                                                            onDateChange={createdAt => {
                                                                                this.setState({ createdAt });
                                                                                setFieldValue("start_date", this.changeDateTimeFormat(createdAt));
                                                                            }}
                                                                            focused={this.state.calendarFocused}
                                                                            onFocusChange={this.onFocusChange}
                                                                            id="start_date"
                                                                            displayFormat="DD-MM-YYYY"
                                                                            readOnly={false}
                                                                            showDefaultInputIcon={true}
                                                                            inputIconPosition={"after"}
                                                                            isOutsideRange={() => false}
                                                                            hideKeyboardShortcutsPanel={true}
                                                                            isDayHighlighted={day => day.isSame(current_date, 'day')}
                                                                            reopenPickerOnClearDate={true}
                                                                            withPortal={true}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">End Date :</label>
                                                                <div className="input-group input-addon single-calendar">
                                                                    {
                                                                        <SingleDatePicker
                                                                            date={date}
                                                                            onDateChange={date => {
                                                                                this.setState({ date });
                                                                                setFieldValue("end_date", this.changeDateFormat(date));
                                                                            }}
                                                                            focused={focused}
                                                                            onFocusChange={({ focused }) => this.setState({ focused })}
                                                                            id="end_date"
                                                                            displayFormat="DD-MM-YYYY"
                                                                            readOnly={true}
                                                                            showDefaultInputIcon={true}
                                                                            inputIconPosition={"after"}
                                                                            isOutsideRange={() => false}
                                                                            hideKeyboardShortcutsPanel={true}
                                                                            isDayHighlighted={day => day.isSame(current_date, 'day')}
                                                                            reopenPickerOnClearDate={true}
                                                                            withPortal={true}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">PNR NO:</label>
                                                                <Field className="form-control custom-filed" type="text" name="pnr_no" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                    createdAt: null,
                                                                    date: null
                                                                })}} />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Ticket NO:</label>
                                                                <Field className="form-control custom-filed" type="text" name="ticket_no" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                    createdAt: null,
                                                                    date: null
                                                                })}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Reference NO:</label>
                                                                <Field className="form-control custom-filed" type="text" name="booking_transaction_ref" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                    createdAt: null,
                                                                    date: null
                                                                })}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">PASSENGER NAME:</label>
                                                                <Field className="form-control custom-filed" type="text" name="passenger_name" onKeyPress={this.enterPressed.bind(this, handleSubmit)} />

                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Invoice NO:</label>
                                                                <Field className="form-control custom-filed" type="text" name="invoice_no" onKeyPress={this.enterPressed.bind(this, handleSubmit)} onKeyUp={() => {this.setState({
                                                                    createdAt: null,
                                                                    date: null
                                                                })}} />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Origin:</label>
                                                                <div className="custom-select-box">
                                                                    <Select
                                                                        component={Select}
                                                                        value={selectedOriginCity}
                                                                        options={originCityArray}
                                                                        name="origin"
                                                                        className="custom-select-box"
                                                                        onBlur={this.originHandleBlur}
                                                                        onChange={(event) => { this.setOriginCityName(event, setFieldValue) }}
                                                                        onInputChange={(event) => { this.getOriginCitiesByFilter(event) }}
                                                                        placeholder="Select Origin City"
                                                                        openMenuOnFocus="true"
                                                                        // tabSelectsValue="false"
                                                                        defaultMenuIsOpen={true}
                                                                    >
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Destination:</label>
                                                                <div className="custom-select-box">
                                                                    <Select
                                                                        component={Select}
                                                                        value={selectedDestinationCity}
                                                                        options={destinationCityArray}
                                                                        name="destination"
                                                                        className="custom-select-box"
                                                                        onBlur={this.destinationHandleBlur}
                                                                        onChange={(event) => { this.setDestinationCityName(event, setFieldValue) }}
                                                                        onInputChange={(event) => { this.getDestinationCitiesByFilter(event) }}
                                                                        placeholder="Select Destination City"
                                                                        openMenuOnFocus="true"
                                                                        tabIndex="0"
                                                                    // tabSelectsValue="false"
                                                                    >
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Airline:</label>
                                                                <div className="custom-select-box">
                                                                    <select className="form-control custom-filed" name="airlines" value={this.props.values.airlines} onBlur={handleBlur} onChange={handleChange} onKeyPress={this.enterPressed.bind(this, handleSubmit)}>
                                                                        <option value="all">All</option>
                                                                        {
                                                                            allAirlinesData.response && allAirlinesData.response.map((item, key) => {
                                                                                return (
                                                                                    <option key={key} value={item.airline_name}>{item.airline_name}</option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btn-block text-center">
                                                        {diffDays && diffDays >31 ?
                                                                <button type="button" className="btn btn-sky-blue" onClick={handleSubmit} disabled>Search</button>
                                                            :
                                                                <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>Search</button>
                                                        }
                                                        <button type="button" className="btn btn-grey" onClick={() => this.resetFormValues(setFieldValue)}>Cancel</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-footer">
                        <Pagination
                            totalRecord={getRequestCancellationDetails.response && getRequestCancellationDetails.response.total_count}
                            pageLimit={perPageLimit}
                            activePage={currentPage}
                            getRecordsPerPage={(value) =>{ this.props.getRecordsPerPage(value, this.props.values);this.setState({isCollapse:false,collapseIndex:null})}} />
                    </div>
                    {
                        this.creditNotePopup()
                    }
                    {
                        this.state.showCancelPopup && this.state.cancelPopupValue && !this.state.cancelPopupValue.kafila_booking_id && this.state.cancelPopupValue.booking_from !== 'efly' && this.state.cancelPopupValue.booking_from !== 'gofly' && this.state.cancelPopupValue.booking_from !== 'airiq' && this.state.cancelPopupValue.booking_type === 'online' &&
                        <FlightCancellationComponent {...this.props} {...this.state} showCancelTicket={this.showCancelTicket} />
                    }
                    {
                        this.state.showCancelPopup && this.state.cancelPopupValue && this.state.cancelPopupValue.kafila_booking_id && this.state.cancelPopupValue.booking_from !== 'efly' && this.state.cancelPopupValue.booking_from !== 'gofly' && this.state.cancelPopupValue.booking_from !== 'airiq'&& this.state.cancelPopupValue.booking_from !== 'tripjack' && this.state.cancelPopupValue.booking_from !== 'tbo' && this.state.cancelPopupValue.booking_type === 'online' &&
                        <FlightCancellationComponent {...this.props} {...this.state} showCancelTicket={this.showCancelTicket} />
                    }
                    {
                       (this.state.showCancelPopup && this.state.cancelPopupValue && (this.state.cancelPopupValue.booking_type === 'offline' || this.state.cancelPopupValue.booking_from === 'efly' || this.state.cancelPopupValue.booking_from === 'gofly' || this.state.cancelPopupValue.booking_from === 'airiq' || (this.state.cancelPopupValue.kafila_booking_id && this.state.cancelPopupValue.kafila_booking_id !== "" && this.state.cancelPopupValue.booking_type === 'online' && this.state.cancelPopupValue.booking_from !== 'kafila'))) &&
                       
                        <div className={`popup-crad confirmation-popup show`}>
                            <div className="popup-outer">
                                <div className="popup-content">
                                    <div className="popup-head">
                                        <h2>Cancel request</h2>
                                        <div className="popup-close"><span className="icon icon-cross" onClick={() => this.setState({
                                            showCancelPopup: false
                                        })} /></div>
                                    </div>
                                    <div className="popup-body popup-scorll">
                                        <div className="inner-confirmation">
                                            <p>Please contact to helpdesk for cancellation</p>
                                            <div className="popup-footer">
                                                <button className="btn btn-orange" onClick={() => this.setState({
                                                    showCancelPopup: false
                                                })}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="popup-overlay" />
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }

    /* credit note popup for partial cancellation */
    creditNotePopup = () => {
        return (
            <div className={`popup-crad confirmation-popup ${this.state.showCreditNotePopup ? 'show' : ''}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>Click to view Credit Note</h2>
                            <div className="popup-close"></div>
                        </div>
                        <div className="popup-body popup-scorll">
                            <ul className="list-select">
                                {
                                    this.props.partailCancellationInfo.response && this.props.partailCancellationInfo.response.details &&
                                    this.props.partailCancellationInfo.response.details.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                { /* eslint-disable-next-line */}
                                                <li><a href="javascript:void(0);" onClick={() => this.printCreditNote({
                                                    'booking_id': item.booking_id,
                                                    'cancel_id': item.cancel_id
                                                }, 'partial')} >{(Number(index) + 1) + '. ' + item.pax_names.join(', ') + '  -  ' + item.segments.join(', ')}</a></li>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </ul>
                            <div className="popup-footer">
                                <button className="btn btn-orange" onClick={() => this.handleCreditNotePopup(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay" />
            </div>
        )
    }
}

export default (FlightRequestCancellation)