import React from 'react';
class BackToTopComponent extends React.Component {
  constructor(props) {
    super(props);
   
}
  handleToTop = () => {
    // this.props.isFilterChecked(15)
    // this.props.isFilterCheckedInbound(15)
    const container = document.querySelector('.page-containers-start');
    const containerInbound = document.querySelector('.page-containers-start-outbound');
    container && container.scrollTo({top:0,behavior:"smooth"})
    window.scrollTo({top:0,behavior:"smooth"})
    containerInbound && containerInbound.scrollTo({top:0,behavior:"smooth"})
    this.props.scroll_position({...this.props.track_scroll_position,BTT:true})
  }

    render() {
      const container = document.querySelector('.page-containers-start');
      const containerInbound = document.querySelector('.page-containers-start-outbound');
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return (
        <React.Fragment>
          {
            (window.location && window.location.pathname === '/flight-search-details' || window.location && window.location.pathname === '/hotel-search-details' || window.location.pathname === '/hotel-room-detail') && 
            <div className='scroll-top show_top'
            style={{
              display: (scrollTop > 300 && this.props.track_scroll_position.parentContainer !== "")
                || (container && container.scrollTop > 200 && window.innerWidth > 766) || this.props.track_scroll_position && this.props.track_scroll_position.roomContainer > 50 || (containerInbound && containerInbound.scrollTop > 200 && window.innerWidth > 766) ? "block" : 'none'
            }}>
            <a href="javascript:void(0)"  onClick={()=>this.handleToTop()} className='plane-icon-style'>
              <img src={(window.location && window.location.pathname === '/hotel-search-details' || window.location.pathname === '/hotel-room-detail') ? "/assets/images/top1.png" : '/assets/images/plane-icon.svg'}/>
            </a>
              </div>
          }
        </React.Fragment>
      )

    }
  }
  export default BackToTopComponent;