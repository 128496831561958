import React from 'react';
import HotelFareSummaryComponent from './HotelFareSummaryComponent';
import HotelGuestComponent from './HotelGuestComponent';
import HotelReviewBookingComponent from './HotelReviewBookingComponent';
import { currencyFormat, getNewDateFormted } from '../../../shared/commonHelper';
import SessionExpire from '../../shared/SessionExpire';

class HotelBookComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			autocomplete: {
				count: null,
				type: null,
				error: null,
				roomNumber: null,
				search: null,
				searchResults: null,
				showPopup: false,
			},
			searchStart : null,	
			showBalancePopup: true,
			priceChangedOk : null,	
		}
	}

	//****************** AutoFill passenger from customer List Start *************************/	
	//Function to show and hide popup 
	handlePopup = (type, roomNumber, count) => {
		this.setState({
			autocomplete: Object.assign({},
				this.state.autocomplete,
				{
					count: count,
					type: type,
					roomNumber: roomNumber,
					showPopup: true
				}
			)
		})
	}

	//Function to search customer from customer list
	handleSearch = () => {
		let searchResults = [];
		this.setState({
			searchStart : true
		});

		if (!this.state.autocomplete.search || this.state.autocomplete.search.length < 1) {
			this.setState({
				autocomplete: Object.assign({},
					this.state.autocomplete,
					{
						error: true,
						showPopup: true
					}
				)
			})
		}
		else {
			if (this.props.customerList.response) {
				for (let i = 0; i < this.props.customerList.response.passenger.length; i++) {
					let item = this.props.customerList.response.passenger[i];
					let firstName = item.first_name;
					let lastName = item.last_name;
					if (firstName.includes(this.state.autocomplete.search) || lastName.includes(this.state.autocomplete.search) 
						|| firstName.toUpperCase().includes(this.state.autocomplete.search) || lastName.toUpperCase().includes(this.state.autocomplete.search)
						|| firstName.toLowerCase().includes(this.state.autocomplete.search) || lastName.toLowerCase().includes(this.state.autocomplete.search)
						) {
						searchResults.push(item);
					}
				}
			}
			this.setState({
				autocomplete: Object.assign({},
					this.state.autocomplete,
					{
						error: false,
						searchResults: searchResults,
						showPopup: true
					}
				)
			})
		}
	}

	//Function to autofill passenger detail from customer list
	autoCompleteCustomer = (value) => {

		if (this.state.autocomplete.count && this.state.autocomplete.type) {
			let count = this.state.autocomplete.count;
			let type = this.state.autocomplete.type;
			let roomNumber = this.state.autocomplete.roomNumber;
			this.props.setFieldValue("first_name_room"+ roomNumber +'_'+ type + count, value.first_name);
			this.props.setFieldValue("last_name_room"+ roomNumber +'_' + type +count, value.last_name);
			this.props.setFieldValue("guest_title_room"+ roomNumber +'_' + type +count, value.passenger_title);

			if (this.props.searchQuery.is_domestic === 'false') {
				this.props.setFieldValue("passport_no_room"+ roomNumber +'_'+ type + count, value.passport_no ? value.passport_no : '');
				this.props.setFieldValue("passport_issue_date_room"+ roomNumber +'_'+ type + count, value.passport_exp ? getNewDateFormted(value.passport_exp):null);
			}

			this.setState({
				autocomplete: Object.assign({},
					this.state.autocomplete,
					{
						showPopup: false
					}
				)
			})
		}
	}
	//******************* AutoFill passenger from customer List End **********************/

	/**************************** Balance popup *******************************/
    handleBalancePopup = ()=> {
		this.setState({showBalancePopup : !this.state.showBalancePopup});
	} 
	
	render() {
		let	roomData = this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.hotel_room_details;
		let	roomDataTripjack = this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.hotel_rooms_details;
		let	fareDetails = this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.total_price;
		let sum = 0;
		let sumUpdated = 0;
		roomDataTripjack && roomDataTripjack.map((item1, key1) => {
			for (let i = 0; i < (item1 && item1.room_combination && item1.room_combination.length); i++) {
				sum += (item1 && item1.room_combination && item1.room_combination[i] && item1.room_combination[i].price_modified && item1.room_combination[i].price_modified.published_price)
				sumUpdated+= (item1 && item1.room_combination && item1.room_combination[i] && item1.room_combination[i].price_modified && item1.room_combination[i].price_modified.offered_price)
			}
		})
	
		let accountResponse = this.props.accountResponse.myAccountResponse.response
        let totalAccountBalance = 0;
		let tripjackOfferPrice = sum;
		let tripjackModifiedPrice = sumUpdated;
        if(accountResponse){
            if(parseInt(accountResponse.cash_balance) > 0){
                totalAccountBalance = parseInt(accountResponse.cash_balance) + parseInt(accountResponse.credit_limit);
            }else{
                totalAccountBalance = parseInt(accountResponse.credit_limit);
            }
		}

		let offeredFare = fareDetails && fareDetails.offered_price;
        let showInsuffient = false
        if(this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type === 'tripjack'){
			if(tripjackOfferPrice && accountResponse && (totalAccountBalance < tripjackOfferPrice)){
				showInsuffient = true;
			}
		}else{
			if(offeredFare && accountResponse && (totalAccountBalance < offeredFare)){
				showInsuffient = true;
			}
		}

		let showChangedPrice =  (this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type === 'tripjack' && this.state.priceChangedOk === null) && this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.is_price_changed;
		let searchSum = 0
		let searchSumSupdated = 0
		let roomDataTripjackSearch = this.props.location && this.props.location.state && this.props.location.state.roomData
		roomDataTripjackSearch && roomDataTripjackSearch.map((item1, key1) => {
				searchSum += (item1 && item1.price_modified  && item1.price_modified && item1.price_modified.published_price)
				searchSumSupdated+= (item1 && item1.price_modified  && item1.price_modified && item1.price_modified.offered_price)
			return null;
		})
		let tripjackOfferPriceOld = searchSum;
		let tripjackModifiedPriceOld = searchSumSupdated
		
		return (
			<section className="mid-wrapper hotel-book-container">
				<div className="container">
					<div className="review-booking-wrap">
						<React.Fragment>
							<SessionExpire startCount='900' {...this.props}/>
						</React.Fragment>
						<div className="left-side">
							<div className="fare-trigger">
								{ /* eslint-disable-next-line */}	
								<a href="javascript:void(0)" className="btn btn-sky-blue fare-trigger-btn"><i className="icon icon-currency-file"></i>Fare Summery</a>
							</div>
							<div className="fare-summery">
								<React.Fragment>
									<HotelFareSummaryComponent loading={this.props.blockHotelDetails.loading} 
									currencyCode={this.props.location && this.props.location.state && this.props.location.state.hoteldata && this.props.location.state.hoteldata.price && this.props.location.state.hoteldata.price.currency_code} 
									roomData={roomData} 
									roomDataTripjack={roomDataTripjack}
									fareDetails={fareDetails}
									supplier_type={this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type}
									noOfRooms={this.props.searchQuery.no_of_rooms} />
								</React.Fragment>
								{/* <div className="apply-discount">
									<div className="discount-outer"> <i className="scissors-icon"><img src="assets/images/scissors.svg" alt=""/></i>
										<h3>Apply Discount</h3>
										<p>If you have Have a discount/promo code ? please enter for discount</p>
										<div className="input-group add-input-group">
										<input className="form-control custom-filed" type="text" placeholder="Enter Coupon Code" />
										<button className="input-btn">Apply</button>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					<div className="right-side">
					<div className="review-steps-wrap">
						<span className="insufficient_balance text-center" style={{display:showInsuffient?'block':'none'}}>Insufficient balance in your account.Please request for cash or credit.</span>
						<div className="review-step-nav">
							<ul>
								<li className={!this.props.isActiveGuestBooking ? 'current' : 'active'}>
									<div className="step-col">
										<div className="step-icon"><i className="icon icon-traveller" /><i className="icon icon-checked" /></div>
										<h2><span>GUEST DETAILS</span>
											{ /* eslint-disable-next-line */}
											<a href="javascript:void(0);" onClick={(event) => (this.props.switchPage(false),this.props.setFieldValue("terms_conditions", false),this.props.setFieldValue("under_cancel", false))} className="icon icon-pencil" > </a>
										</h2>
									</div>
								</li>
								<li className={this.props.isActiveGuestBooking ? 'current' : ''}>
									<div className="step-col">
										<div className="step-icon"><i className="icon icon-book" /><i className="icon icon-checked" /></div>
										<h2><span>REVIEW BOOKING</span></h2>
									</div>
								</li>
							</ul>
						</div>

						{ !this.props.isActiveGuestBooking &&
							<HotelGuestComponent 
								{...this.props}
								handlePopup={this.handlePopup}
							/>
						}

						{
							this.props.isActiveGuestBooking &&
							<HotelReviewBookingComponent 
								{...this.props}
								fareDetails={fareDetails}
								tripjackOfferPrice={tripjackOfferPrice}
							/>
						}
					</div>
				</div>
				</div>
			</div>

			{/* start customer list popup */}
			<div className="popup-crad popup-customer-list" style={{ display: this.state.autocomplete.showPopup ? 'block' : 'none' }}>
				<div className="popup-outer">
					<div className="popup-content">
						<div className="popup-head">
							<h2>Customer List</h2>
							<div className="popup-close"><span onClick={() => {
								this.setState({ autocomplete: Object.assign({}, this.state.autocomplete, { showPopup: false }) , searchStart : null });
									}
								}
								className="icon icon-cross" />
							</div>
						</div>
						<div className="customer-list-block">
							<div className="customer-select">
								<div className="form-group-customer">
									<div className="customer-select-box">
										<input type="search" defaultValue={this.state.autocomplete.search} onKeyPress={(event) => this.enterPressed(event)} onChange={(event) => this.setState({ autocomplete: Object.assign({}, this.state.autocomplete, { search: event.target.value, showPopup: true }) })} />
										<button className="btn-sky-blue" onClick={this.handleSearch}>Search</button>
									</div>
								</div>
								{
									this.state.autocomplete.error &&
									<span className="red-error">Please enter atleast 1 Characters</span>
								}
							</div>
						</div>
						<div className="popup-scorll">
							<ul className="list-select">
								{
									this.state.searchStart && this.state.autocomplete.search
									?
									this.state.autocomplete.searchResults && this.state.autocomplete.searchResults.map((item, index) => {
										return (
											<React.Fragment key={index}>
												{ /* eslint-disable-next-line */}
													<li><a href="javascript:void(0);" onClick={() => this.autoCompleteCustomer(item)} key={index} >{item.first_name + " " + item.last_name + " (" + item.mobile_no + ")"}</a></li>
											</React.Fragment>
										)
									})
									:
									this.props.customerList.response  && this.props.customerList.response.passenger.map((item, index) => {
										return (
											<React.Fragment key={index}>
												{ /* eslint-disable-next-line */}
													<li><a href="javascript:void(0);" onClick={() => this.autoCompleteCustomer(item)} key={index} >{item.first_name + " " + item.last_name + " (" + item.mobile_no + ")"}</a></li>
											</React.Fragment>
										)
									})
								}
							</ul>
						</div>
					</div>
				</div>
			<div className="popup-overlay" />
		</div>
		{/* End customer list popup */}

		{ accountResponse && accountResponse.user_type_id !== 1 && showInsuffient && <InsufficentBalancePopupComponent showInsuffient={showInsuffient} {...this.props} {...this.state} handleBalancePopup={this.handleBalancePopup}/>}	
		{
			this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type === 'tripjack' && 
			<div className={`popup-crad total-fare-card ${showChangedPrice && 'show'}`}>
				<div className="popup-outer">
					<div className="popup-content">
						<div className="popup-head">
							<h2>The total fare has been changed.</h2>
						</div>
						<div className="popup-scorll">
							<div className="fare-change-block">
								<div className="fare-block-inner">
									<div className="fare-col fare-thead">
										<div className="cols" />
										<div className="cols"><span>Published Fare</span></div>
										<div className="cols"><span>Offered Fare</span></div>
									</div>
									<div className="fare-col fare-tbody">
										<ul>
											<li>
												<div className="cols"><span className="red-text">New Price</span></div>
												<div className="cols"><span className="red-text"><i className="fa fa-inr" aria-hidden="true" />
													{currencyFormat(tripjackOfferPrice)}</span></div>
												<div className="cols"><span className="red-text"><i className="fa fa-inr" aria-hidden="true" />
													{currencyFormat(tripjackModifiedPrice)}</span></div>
											</li>
											<li>
												<div className="cols"><span>Old Price</span></div>
												<div className="cols"><span><i className="fa fa-inr" aria-hidden="true" />
													{currencyFormat(tripjackOfferPriceOld)}</span>
												</div>
												<div className="cols"><span><i className="fa fa-inr" aria-hidden="true" />
													{currencyFormat(tripjackModifiedPriceOld)}</span>
												</div>
											</li>
										</ul>
										<div className="fare-bottom-block">
											<span className="red-text">(*TDS INCLUDE)</span>
											<span>Do you want to proceed further?</span>
										</div>
										<div className="popup-footer">
											<button className="btn btn-orange" onClick={() => {
													this.setState({
														priceChangedOk: 'yes'
													})
												}}>Yes</button>
											<button className="btn btn-grey" onClick={() => {
													this.props.history.push({
														pathname: "/hotel-room-detail"
													});
												}}>No</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="popup-overlay" />
			</div>
		}
		</section>
		)
	}
}

/*--------- Insufficent balance popup component ------------*/
class InsufficentBalancePopupComponent extends React.Component { 
    render(){
        let { showBalancePopup, handleBalancePopup,showInsuffient } = this.props;
        return (
            <div className={`popup-crad confirmation-popup ${showBalancePopup && showInsuffient ? 'show' : ''}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>Insufficent Balance</h2>
                            <div className="popup-close"><span className="icon icon-cross" onClick={()=> handleBalancePopup() }/></div>
                        </div>							
                        <div className="popup-body popup-scorll">
                            <div className="inner-confirmation">
                                <p>Insufficient balance in your account.Please request for cash or credit!</p>
                                <div className="popup-footer">
                                    <button className="btn btn-orange" onClick={()=> handleBalancePopup() }>Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay"></div>
            </div>
        );
    }
}

export default HotelBookComponent

