import React, { Component } from 'react';
import AgentLeftBar from '../../agent/AgentLeftBarComponent';
import AgentHeaderNavigation from '../../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../../agent/AgentCommonHeaderComponent';
import history from "../../../shared/history";
import Link from 'react-router-dom/es/Link';
import FlightTypes from './FlightTypesComponent';
import DomesticMarkupTool from "./DomesticMarkupToolComponent";
import InternationalMarkupTool from "./InternationalMarkupToolComponent";
import HotelTypes from './HotelTypesComponent';

class AgentMarkUpTool extends Component {
    constructor() {
        super();
        this.state = {
          hideMsg: false,
          leftNavActive: '/mark-up-tool',
          url: history.location.pathname
        }
    }
    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }
    render() {
        const { accountResponse,productResponse} = this.props;
        let activeLeftNavigation  = this.state.url;
        return (
            <React.Fragment>
                 {/*nav-section Start*/}
                 <AgentHeaderNavigation productResponseList={productResponse} {...this.props}/>
                 {/*nav-section end*/}
                    <section className="mid-wrapper">
                        <AgentCommonHeader {...this.props}/>
                        <div className="my-account-info">
                            <div className="container">
                                <div className="my-account-sec">
                                    <div className="my-account-outer">
                                        <AgentLeftBar handleClickValue={this.handleClickValue.bind(this)} 
                                        accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} logoutAction={this.props.logoutAction}/>
                                        <div className="profile-detail-info">
                                            <div className="edit-profile-bar">
                                                <div className="pull-right">
                                                    { /* eslint-disable-next-line */}
                                                    <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                                </div>
                                            </div>
                                            {this.props.type ==="/mark-up-tool"?
                                            <div className="tabs-container-detail">
                                                <div className="mark-up-info">
                                                    <div className="tabs-top">
                                                        <div className="tabs-title">
                                                            <h2><span>Select Category</span></h2>
                                                        </div>
                                                    </div>
                                                    <div className="category-list updates-list">
                                                        <ul>
                                                            <li>
                                                                <Link to="flight-type-list">
                                                                    <div className="category-card">
                                                                        <figure><i className="icon icon-flight-circle"></i></figure>
                                                                        <div className="category-card-bottom">
                                                                            <h3>Flight</h3>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>  
                                                            <li>
                                                                <Link to="hotel-type-list">
                                                                    <div className="category-card disabled">
                                                                        <figure><i className="fa fa-bed"></i></figure>
                                                                        <div className="category-card-bottom">
                                                                            <h3>Hotel</h3>
                                                                        </div>
                                                                    </div>
                                                               </Link> 
                                                            </li>  
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            :""}
                                            {this.props.type === '/flight-type-list' ? <FlightTypes  {...this.props} /> : ''}
                                            {this.props.type === "/domestic-markup-tool"?<DomesticMarkupTool {...this.props}/>:""}
                                            {this.props.type === "/international-markup-tool"?<InternationalMarkupTool {...this.props}/>:""}
                                            {this.props.type === '/hotel-type-list' ? <HotelTypes  {...this.props} /> : ''}
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
        )
    }
}

export default (AgentMarkUpTool)