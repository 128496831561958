import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup"
import HotelSearchHeaderComponent from "../../components/hotel/HotelSearchHeaderComponent";
import HotelBookComponent from "../../components/hotel/booking/HotelBookComponent";
import { getPaxTitle, blockHotelRoom, hotelBooking, getBookingHotelDetails, generateVoucher, getTripjackHotelReview, hotelTripjackBooking, generateConfirmBook, getTripjackBookingHotelDetails  } from "../../actions/hotel/hotelBookAction";
import { addPassenger, getCustomerList,isBookingPending } from '../../actions/shared/sharedAction';
import BookingConfirmComponent from "../../components/hotel/booking/BookingConfirmComponent";
import { myAccountAction } from "../../actions/agentAction";
import moment from 'moment';
import { getNewDateFormted} from "../../shared/commonHelper";
import { getTripjackCancelPolicy } from "../../actions/hotel/hotelSearchDetailsAction";

class HotelBookContainer extends React.Component {
    constructor() {
        super()
        this.state = {
            isActiveGuestBooking: false,
            isActiveBookingReview: false,
            isShowPopup:false,
            HotelBookingData:"",
            showConfirmationPopupNRI:false,
            handlesubPopup:false
        }
    }

    handleConfirmationPopupNRI = (event) => {
        this.setState({
            showConfirmationPopupNRI: event
        })
    }

    scrollToFirstError = (value) => {
        let str = Object.values(value) && Object.values(value).length && Object.values(value)[0]
        let res = str && str.split(" ") && str.split(" ").join("") && str.split(" ").join("").replace(/[^A-Za-z\s]/g, '');
        const firstErrorElement = Object.values(value) && Object.values(value).length && document.querySelector(`.${res}`)
        if (firstErrorElement) {
          firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };


    //Function to get details of guests accoding to rooms
    getGuestDetails = (index, formData) => {
        let roomIndex = Number(index) + 1;
        let adultCount = this.props.searchQuery.room_guests[index].no_of_adults;//Number of adult in a room 
        let childCount = this.props.searchQuery.room_guests[index].no_of_child;//Number of child in a room 
        //Loop to get adult data for room
        let guestData = [];
        for (let i = 1; i <= adultCount; i++) {
            let adultData = {}
            adultData = {
                "title": formData["guest_title_room" + roomIndex + "_adult" + i],
                "first_name": formData["first_name_room" + roomIndex + "_adult" + i].toUpperCase(),
                "middle_name": null,
                "last_name": formData["last_name_room" + roomIndex + "_adult" + i].toUpperCase(),
                "phoneno": formData["contact_no_room" + roomIndex + "_adult" + i],
                "email": null,
                "pax_type": 1,
                "lead_passenger": i === 1 ? true : false,
                "passport_no": formData["passport_no_room" + roomIndex + "_adult" + i] ? formData["passport_no_room" + roomIndex + "_adult" + i] : null,
                "passport_issue_date":formData["passport_no_room" + roomIndex + "_adult" + i] && formData["passport_issue_date_room" + roomIndex + "_adult" + i] ? moment(formData["passport_issue_date_room" + roomIndex + "_adult" + i]).format('YYYY-MM-DD') : null,
                "passport_exp_date": formData["passport_no_room" + roomIndex + "_adult" + i] && formData["passport_exp_date_room" + roomIndex + "_adult" + i] ? moment(formData["passport_exp_date_room" + roomIndex + "_adult" + i]).format('YYYY-MM-DD') : null,
                "pan": formData['isCopyPANToAll']  ? 
                        formData["pan_no_room" + i + "_adult" + i] ? formData["pan_no_room" + i + "_adult" + i].toUpperCase(): null :
                        formData["pan_no_room" + roomIndex + "_adult" + i] ? formData["pan_no_room" + roomIndex + "_adult" + i].toUpperCase() : null,
            }
            guestData.push(adultData);
        }
        //Loop to get child data for room
        for (let i = 1; i <= childCount; i++) {
            let childData = {}
            childData = {
                "title": formData["guest_title_room" + roomIndex + "_child" + i],
                "first_name": formData["first_name_room" + roomIndex + "_child" + i].toUpperCase(),
                "middle_name": null,
                "last_name": formData["last_name_room" + roomIndex + "_child" + i].toUpperCase(),
                "phoneno": null,
                "email": null,
                "pax_type": 2,
                "age": this.props.searchQuery.room_guests[index].child_age[i - 1],
                "lead_passenger": false,
                "passport_no": null,
                "passport_issue_date": null,
                "passport_exp_date": null
            }
            guestData.push(childData);
        }
        return guestData;
    }
    //this submit from hotel guest details 
    handleSubmit = (formData) => {
        if(this.props.searchQuery.guest_nationality !== 'IN' && !this.state.handlesubPopup && !this.state.isActiveGuestBooking){
            this.handleConfirmationPopupNRI(true)
        }else{
                this.setState({
                    showConfirmationPopupNRI: false,
                    handlesubPopup:false
                })
                if (formData.terms_conditions && formData.under_cancel) {
                    let hotelData = this.props.location.state.hoteldata;
                    let blockHotelData = this.props.blockHotelDetails.response;
                    let supplierType = this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type;
        
                    if (supplierType !== 'tripjack') {
                        //Get rooms data for hotel book API
                        let hotelRoomDetails = [];
                        if (blockHotelData) {
                            for (let i = 0; i < blockHotelData.hotel_room_details.length; i++) {
                                let roomDetails = blockHotelData.hotel_room_details[i];
                                let details = {
                                    "room_index": roomDetails.room_index,
                                    "room_type_code": roomDetails.room_type_code,
                                    "room_type_name": roomDetails.room_type_name,
                                    "rate_plan_code": roomDetails.rate_plan_code,
                                    "smoking_preference": 0,
                                    "supplements": null,
                                    "rate_plan_name": null,
                                    "bed_types": roomDetails.bed_types,
                                    "bed_type_code": null,
                                    "bed_type_description": null,
                                    "price": this.getRoomPriceJson(roomDetails),
                                    "hotel_passenger": this.getGuestDetails(i, formData),
                                    "adult_count": this.props.searchQuery.room_guests[i].no_of_adults,//Number of adult in a room 
                                    "child_count": this.props.searchQuery.room_guests[i].no_of_child,//Number of child in a room 
                                    "cancellation_policy": roomDetails.cancellation_policies,
                                    "room_id": roomDetails.room_id,
                                    "room_promotion": roomDetails.room_promotion,
                                    "price_modified": roomDetails.price_modified
                                }
                                hotelRoomDetails.push(details);
                            }
                        }
        
                        let bookHotelData = {
                            "trace_id": blockHotelData && blockHotelData.trace_id,
                            "result_index": hotelData.result_index,
                            "hotel_code": hotelData.hotel_code,
                            "hotel_name": hotelData.hotel_name,
                            "guest_nationality": this.props.searchQuery.guest_nationality,
                            "no_of_rooms": this.props.searchQuery.no_of_rooms,
                            "is_voucher_booking": formData.submitType === 'hold' ? false : true,
                            "hotel_rooms_details": hotelRoomDetails,
                            "hotel_rating": hotelData.star_rating,
                            "city_id": this.props.searchQuery.city_id,
                            "booking_category_id": this.props.searchQuery.is_domestic === 'true' ? 1 : 2,
                            "address": hotelData.hotel_address,
                            "client_reference_no": "0",
                            'check_in_date': this.props.searchQuery.check_in_date,
                            "total_price": blockHotelData.total_price,
                            "is_package_fare": blockHotelData.is_package_fare,
                            "is_package_details_mandatory": blockHotelData.is_package_details_mandatory,
                            "is_corporate": formData['is_corporate']
                        }
        
                        if (formData.flight_no || formData.transport_no) {
                            let transportNo = formData.transport_type === "flight" ? formData.flight_no : formData.transport_no;
                            let arrivalDate = moment(formData.check_in_date).format('YYYY-MM-DD') + ' ' + formData.arrival_hour + ':' + formData.arrival_minute + ':00';
                            bookHotelData.arrival_transport = {
                                "arrival_transport_type": formData.transport_type === "flight" ? 0 : 1,
                                "transport_info_id": transportNo,
                                "time": arrivalDate
                            }
                        }
        
                        const successCb = (response) => {
                            this.props.history.push({
                                pathname: "/hotel-booking-confirm",
                                state: {
                                    bookingType: 'confirm',
                                    response: response
                                }
                            });
                            this.props.myAccountAction();
                        }
                        const errorCb = (response) => {
                            this.bookingError(response);
                        }
                        this.props.hotelBooking(bookHotelData, successCb, errorCb);
                    }
                    if (supplierType === 'tripjack') {
                        //Get rooms data for hotel book API
                        let cancellationDateTripjack = this.props.cancelPolicy.response && this.props.cancelPolicy.response.cancellation_policies && this.props.cancelPolicy.response.cancellation_policies.length > 0 &&
                        this.props.cancelPolicy.response.cancellation_policies.filter((date) => {
                                return date.charge === 0;
                            });
                        if (cancellationDateTripjack && cancellationDateTripjack.length > 0) {
                            if (cancellationDateTripjack && cancellationDateTripjack.length > 1) {
                                cancellationDateTripjack = cancellationDateTripjack.reduce((max, current) => {
                                    return new Date(current.to_date) > new Date(max.to_date) ? current : max;
                                });
                            } else {
                                cancellationDateTripjack = cancellationDateTripjack[0]
                            }
                        }
                        let hotelRoomDetails = [];
                        let total_price = [];
                        if (blockHotelData) {
                            for (let i = 0; i < blockHotelData.hotel_rooms_details.length; i++) {
                                for (let j = 0; j < blockHotelData.hotel_rooms_details[i].room_combination.length; j++) {
                                    let roomDetails = blockHotelData.hotel_rooms_details[i].room_combination[j];
                                    total_price.push(roomDetails)
                                    let details = {
                                        "child_count": this.props.searchQuery.room_guests[j].no_of_child,//Number of child in a room ,
                                        "adult_count": this.props.searchQuery.room_guests[j].no_of_adults,
                                        "require_all_pax_details": roomDetails.require_all_pax_details,
                                        "room_id": roomDetails.room_id,
                                        "room_status": roomDetails.room_status,
                                        "room_index": roomDetails.room_index,
                                        "room_type_code": roomDetails.room_type_code,
                                        "room_type_name": roomDetails.room_type_name,
                                        "rate_plan_code": roomDetails.rate_plan_code,
                                        "rate_plan": roomDetails.rate_plan,
                                        "info_source": roomDetails.info_source,
                                        "sequence_no": roomDetails.sequence_no,
                                        "day_rates": roomDetails.day_rates,
                                        "supplier_price": roomDetails.supplier_price,
                                        "price": roomDetails.price,
                                        "price_modified": roomDetails.price_modified,
                                        "room_promotion": roomDetails.room_promotion,
                                        "amenities": roomDetails.amenities,
                                        "amenity": roomDetails.amenity,
                                        "smoking_preference": roomDetails.smoking_preference,
                                        "bed_types": roomDetails.bed_types,
                                        "hotel_supplements": roomDetails.hotel_supplements,
                                        "last_cancellation_date": cancellationDateTripjack && cancellationDateTripjack.to_date,//roomDetails.last_cancellation_date,
                                        "cancellation_policies": this.props.cancelPolicy.response && this.props.cancelPolicy.response.cancellation_policies, //roomDetails.cancellation_policies,
                                        "last_voucher_date": roomDetails.last_voucher_date,
                                        "cancellation_policy": this.props.cancelPolicy.response && this.props.cancelPolicy.response.cancellation_policy,//roomDetails.cancellation_policy,
                                        "inclusion": roomDetails.inclusion,
                                        "is_passport_mandatory": roomDetails.is_passport_mandatory,
                                        "is_pan_mandatory": roomDetails.is_pan_mandatory,
                                        "hotel_passenger": this.getGuestDetails(j, formData),
                                    }
                                    hotelRoomDetails.push(details);
                                }
                            }
                        }
        
                        const successCb = (response) => {
                            this.props.history.push({
                                pathname: "/hotel-booking-confirm",
                                state: {
                                    bookingType: 'confirm',
                                    response: this.props.hotelBookDetails.response,
                                    formData: formData,
                                    cancelPolicy: this.props.cancelPolicy,
                                    blockHotelDetails: this.props.blockHotelDetails,
                                    adultCount: this.props.searchQuery.room_guests
                                }
                            });
                            this.props.myAccountAction();
                        }
                        const successCb1 = (response) => {
                            if(this.props.hotelBookedDetails.response && this.props.hotelBookedDetails.response.inProgress){
                                this.props.isBookingPending(true)
                                    setTimeout(() => {
                                    this.props.isBookingPending(false)
                                    this.props.getTripjackBookingHotelDetails(this.state.HotelBookingData, successCb, errorCb);
                                    }, 10000);
                            }else{
                                this.props.history.push({
                                    pathname: "/hotel-booking-confirm",
                                    state: {
                                        bookingType: 'confirm',
                                        response: this.props.hotelBookDetails.response,
                                        formData: formData,
                                        cancelPolicy: this.props.cancelPolicy,
                                        blockHotelDetails: this.props.blockHotelDetails,
                                        adultCount: this.props.searchQuery.room_guests
                                    }
                                });
                                this.props.myAccountAction();
                            }
                        }
                        const errorCb = (response) => {
                            this.bookingError(response);
                        }
                        const successBooking = (response) => {
                            let bookHotel = {
                                "trace_id": null,
                                "result_index": response.tripjack_bookingId,
                                "hotel_code": response.hotel_code,
                                "hotel_name": response.hotel_name,
                                "tripjack_bookingId": response.tripjack_bookingId,
                                "guest_nationality": this.props.searchQuery.guest_nationality,
                                "no_of_rooms": response.no_of_rooms,
                                "is_voucher_booking": formData.submitType === 'book' ? true : false,
                                "hotel_rooms_details": response.hotel_room_details,
                                "hotel_rating": response.star_rating,
                                "city_id": response.city_id,
                                "booking_category_id": this.props.searchQuery.is_domestic === 'true' ? 1 : 2,
                                "address": response.address_line1 + response.address_line2,
                                "client_reference_no": "0",
                                "check_in_date": this.props.searchQuery.check_in_date,
                                "total_price": response.total_price,
                                "booking_id": response.booking_id
                            }
                            this.setState({HotelBookingData:bookHotel})
                            
                            this.props.getTripjackBookingHotelDetails(bookHotel, successCb1, errorCb);
                        }
        
                        if (formData.submitType === 'hold') {
                            let cancellationDateTripjack = this.props.cancelPolicy.response && this.props.cancelPolicy.response.cancellation_policies && this.props.cancelPolicy.response.cancellation_policies.length > 0 &&
                                this.props.cancelPolicy.response.cancellation_policies.filter((date) => {
                                    return date.charge === 0;
                                });
                            if (cancellationDateTripjack && cancellationDateTripjack.length > 0) {
                                if (cancellationDateTripjack && cancellationDateTripjack.length > 1) {
                                    cancellationDateTripjack = cancellationDateTripjack.reduce((max, current) => {
                                        return new Date(current.to_date) > new Date(max.to_date) ? current : max;
                                    });
                                } else {
                                    cancellationDateTripjack = cancellationDateTripjack[0]
                                }
                            }
                            const addressParts = [];
        
                            if (hotelData.hotel_address.adr) {
                              addressParts.push(hotelData.hotel_address.adr);
                            }
                            
                            if (hotelData.hotel_address.city && hotelData.hotel_address.city.name) {
                              addressParts.push(hotelData.hotel_address.city.name);
                            }
                            
                            
                            if (hotelData.hotel_address.state && hotelData.hotel_address.state.name) {
                              addressParts.push(hotelData.hotel_address.state.name);
                            }
        
                            if (hotelData.hotel_address.postalCode) {
                                addressParts.push(hotelData.hotel_address.postalCode);
                              }
                            
                            if (hotelData.hotel_address.country && hotelData.hotel_address.country.name) {
                              addressParts.push(hotelData.hotel_address.country.name);
                            }
                            
                            const address = addressParts.join(', ');
                            let bookHotelData = {
                                "trace_id": blockHotelData && blockHotelData.trace_id,
                                "result_index": hotelData.result_index,
                                "hotel_code": hotelData.hotel_code,
                                "hotel_name": hotelData.hotel_name,
                                "guest_nationality": this.props.searchQuery.guest_nationality,
                                "no_of_rooms": this.props.searchQuery.no_of_rooms,
                                "is_voucher_booking": formData.submitType === 'hold' ? false : true,
                                "hotel_rooms_details": hotelRoomDetails,
                                "hotel_rating": hotelData.star_rating,
                                "city_id": this.props.searchQuery.city_id,
                                "booking_category_id": this.props.searchQuery.is_domestic === 'true' ? 1 : 2,
                                "address": address,
                                "client_reference_no": "0",
                                'check_in_date': this.props.searchQuery.check_in_date,
                                "tripjack_bookingId": blockHotelData.bookingId,
                                "booking_type": formData.submitType,
                                "total_price": {
                                    "room_price": total_price && total_price[0].price_modified && total_price[0].price_modified.room_price,
                                    "offered_price": total_price && total_price[0].price_modified && total_price[0].price_modified.offered_price,
                                    "offered_price_old": total_price && total_price[0].price_modified && total_price[0].price_modified.offered_price_old,
                                    "published_price": total_price && total_price[0].price_modified && total_price[0].price_modified.published_price,
                                    "published_price_old": total_price && total_price[0].price_modified && total_price[0].price_modified.published_price_old,
                                    "admin_unique_id": total_price && total_price[0].price_modified && total_price[0].price_modified.admin_unique_id,
                                    "agent_unique_id": total_price && total_price[0].price_modified && total_price[0].price_modified.agent_unique_id,
                                    "tax": total_price && total_price[0].price_modified && total_price[0].price_modified.tax,
                                    "other_tax": total_price && total_price[0].price_modified && total_price[0].price_modified.other_tax,
                                    "service_charge": total_price && total_price[0].price_modified && total_price[0].price_modified.service_charge,
                                    "discount": total_price && total_price[0].price_modified && total_price[0].price_modified.discount,
                                    "agent_discount": total_price && total_price[0].price_modified && total_price[0].price_modified.agent_discount,
                                    "tds": total_price && total_price[0].price_modified && total_price[0].price_modified.tds,
                                    "cgst": total_price && total_price[0].price_modified && total_price[0].price_modified.cgst,
                                    "igst": total_price && total_price[0].price_modified && total_price[0].price_modified.igst,
                                    "sgst": total_price && total_price[0].price_modified && total_price[0].price_modified.sgst,
                                    "other_charges": total_price && total_price[0].price && total_price[0].price.other_charges,
                                    "extra_guest_charge": total_price && total_price[0].price && total_price[0].price.extra_guest_charge,
                                    "agent_commission": total_price && total_price[0].price && total_price[0].price.agent_commission,
                                    "consider_agent_discount": total_price && total_price[0].price_modified && total_price[0].price_modified.consider_agent_discount
                                },
                                "last_cancellation_date": cancellationDateTripjack && cancellationDateTripjack.to_date,
                                "cancellation_policy": this.props.cancelPolicy.response && this.props.cancelPolicy.response.cancellation_policy,
                                "check_out_date":this.props.searchQuery && this.props.searchQuery.check_out_date ? this.props.searchQuery.check_out_date : ""
                            }
        
                            if (formData.flight_no || formData.transport_no) {
                                let transportNo = formData.transport_type === "flight" ? formData.flight_no : formData.transport_no;
                                let arrivalDate = moment(formData.check_in_date).format('YYYY-MM-DD') + ' ' + formData.arrival_hour + ':' + formData.arrival_minute + ':00';
                                bookHotelData.arrival_transport = {
                                    "arrival_transport_type": formData.transport_type === "flight" ? 0 : 1,
                                    "transport_info_id": transportNo,
                                    "time": arrivalDate
                                }
                            }
                            this.props.hotelTripjackBooking(bookHotelData, successBooking, errorCb);
                        }
                        if (formData.submitType === 'book') {
                            let cancellationDateTripjack = this.props.cancelPolicy.response && this.props.cancelPolicy.response.cancellation_policies && this.props.cancelPolicy.response.cancellation_policies.length > 0 &&
                                this.props.cancelPolicy.response.cancellation_policies.filter((date) => {
                                    return date.charge === 0;
                                });
                            if (cancellationDateTripjack && cancellationDateTripjack.length > 0) {
                                if (cancellationDateTripjack && cancellationDateTripjack.length > 1) {
                                    cancellationDateTripjack = cancellationDateTripjack.reduce((max, current) => {
                                        return new Date(current.to_date) > new Date(max.to_date) ? current : max;
                                    });
                                } else {
                                    cancellationDateTripjack = cancellationDateTripjack[0]
                                }
                            }
                            const addressParts = [];
        
                            if (hotelData.hotel_address.adr) {
                              addressParts.push(hotelData.hotel_address.adr);
                            }
                            
                            if (hotelData.hotel_address.city && hotelData.hotel_address.city.name) {
                              addressParts.push(hotelData.hotel_address.city.name);
                            }
                            
                           
                            if (hotelData.hotel_address.state && hotelData.hotel_address.state.name) {
                              addressParts.push(hotelData.hotel_address.state.name);
                            }
        
                            if (hotelData.hotel_address.postalCode) {
                                addressParts.push(hotelData.hotel_address.postalCode);
                              }
                            
                            if (hotelData.hotel_address.country && hotelData.hotel_address.country.name) {
                              addressParts.push(hotelData.hotel_address.country.name);
                            }
                            
                            const address = addressParts.join(', ');
                            
                            let bookHotelData = {
                                "trace_id": blockHotelData && blockHotelData.trace_id,
                                "result_index": hotelData.result_index,
                                "hotel_code": hotelData.hotel_code,
                                "hotel_name": hotelData.hotel_name,
                                "guest_nationality": this.props.searchQuery.guest_nationality,
                                "no_of_rooms": this.props.searchQuery.no_of_rooms,
                                "is_voucher_booking": formData.submitType === 'hold' ? false : true,
                                "hotel_rooms_details": hotelRoomDetails,
                                "hotel_rating": hotelData.star_rating,
                                "city_id": this.props.searchQuery.city_id,
                                "booking_category_id": this.props.searchQuery.is_domestic === 'true' ? 1 : 2,
                                "address": address,
                                "client_reference_no": "0",
                                'check_in_date': this.props.searchQuery.check_in_date,
                                "tripjack_bookingId": blockHotelData.bookingId,
                                "total_price": {
                                    "room_price": total_price && total_price[0].price_modified && total_price[0].price_modified.room_price,
                                    "offered_price": total_price && total_price[0].price_modified && total_price[0].price_modified.offered_price,
                                    "offered_price_old": total_price && total_price[0].price_modified && total_price[0].price_modified.offered_price_old,
                                    "published_price": total_price && total_price[0].price_modified && total_price[0].price_modified.published_price,
                                    "published_price_old": total_price && total_price[0].price_modified && total_price[0].price_modified.published_price_old,
                                    "admin_unique_id": total_price && total_price[0].price_modified && total_price[0].price_modified.admin_unique_id,
                                    "agent_unique_id": total_price && total_price[0].price_modified && total_price[0].price_modified.agent_unique_id,
                                    "tax": total_price && total_price[0].price_modified && total_price[0].price_modified.tax,
                                    "other_tax": total_price && total_price[0].price_modified && total_price[0].price_modified.other_tax,
                                    "service_charge": total_price && total_price[0].price_modified && total_price[0].price_modified.service_charge,
                                    "discount": total_price && total_price[0].price_modified && total_price[0].price_modified.discount,
                                    "agent_discount": total_price && total_price[0].price_modified && total_price[0].price_modified.agent_discount,
                                    "tds": total_price && total_price[0].price_modified && total_price[0].price_modified.tds,
                                    "cgst": total_price && total_price[0].price_modified && total_price[0].price_modified.cgst,
                                    "igst": total_price && total_price[0].price_modified && total_price[0].price_modified.igst,
                                    "sgst": total_price && total_price[0].price_modified && total_price[0].price_modified.sgst,
                                    "other_charges": total_price && total_price[0].price && total_price[0].price.other_charges,
                                    "extra_guest_charge": total_price && total_price[0].price && total_price[0].price.extra_guest_charge,
                                    "agent_commission": total_price && total_price[0].price && total_price[0].price.agent_commission,
                                    "consider_agent_discount": total_price && total_price[0].price_modified && total_price[0].price_modified.consider_agent_discount
                                },
                                "last_cancellation_date": cancellationDateTripjack && cancellationDateTripjack.to_date,
                                "cancellation_policy": this.props.cancelPolicy.response && this.props.cancelPolicy.response.cancellation_policy,
                                "check_out_date":this.props.searchQuery && this.props.searchQuery.check_out_date ? this.props.searchQuery.check_out_date : ""
                            }
        
                            if (formData.flight_no || formData.transport_no) {
                                let transportNo = formData.transport_type === "flight" ? formData.flight_no : formData.transport_no;
                                let arrivalDate = moment(formData.check_in_date).format('YYYY-MM-DD') + ' ' + formData.arrival_hour + ':' + formData.arrival_minute + ':00';
                                bookHotelData.arrival_transport = {
                                    "arrival_transport_type": formData.transport_type === "flight" ? 0 : 1,
                                    "transport_info_id": transportNo,
                                    "time": arrivalDate
                                }
                            }
                            this.props.hotelTripjackBooking(bookHotelData, successBooking, errorCb);
                        }
                    }
                }
                else {
                    this.setState({ isActiveGuestBooking: true });
                }
            
        }
        
    }

    handlePopupSubmit=(submit,value)=>{
        this.setState({handlesubPopup:value})
        if(value){
            submit()
        }
    }

    //Function to get price of rooms in JSON
    getRoomPriceJson(roomDetails) {
        let price = {
            "currency_code": roomDetails.price.currency_code,
            "room_price": roomDetails.price.room_price,
            "tax": roomDetails.price.tax,
            "extra_guest_charge": roomDetails.price.extra_guest_charge,
            "child_charge": roomDetails.price.child_charge,
            "other_charges": roomDetails.price.other_charges,
            "discount": roomDetails.price.discount,
            "published_price": roomDetails.price.published_price,
            "published_price_rounded_off": roomDetails.price.published_price_rounded_off,
            "offered_price": roomDetails.price.offered_price,
            "offered_price_rounded_off": roomDetails.price.offered_price_rounded_off,
            "agent_commission": roomDetails.price.agent_commission,
            "agent_mark_up": roomDetails.price.agent_mark_up,
            "service_tax": roomDetails.price.service_tax,
            "tds": roomDetails.price.tds,
            "service_charge": roomDetails.price.service_charge,
            "total_gst_amount": roomDetails.price.total_gst_amount,
            "gst": roomDetails.price.gst
        }
        return price;
    }

    //Function to switch page between guest and review booking
    switchPage = (value) => {
        this.setState({
            isActiveGuestBooking: value,
        })
    }

    // booking error page
    bookingError = (response) => {
        this.props.history.push({
            pathname: "/booking-error",
            state: {
                page: 'hotel-book',
                response: response.message,
                searchQuery: this.props.searchQuery,
                productResponse: this.props.productResponse,
                agentProductAccess: this.props.agentProductAccess
            }
        });
    }

    componentDidMount() {
        if (!this.props.type) {
            this.props.getCustomerList();// get customer list
            let hotelData = this.props.location.state.hoteldata;
            let roomData = this.props.location.state.roomData;
            let hotelRoomDetails = [];
            let hotelRoomDetailsTripjack = '';
            //Get hotel room details values for block room detail API response
            for (let i = 0; i < roomData.length; i++) {
                let roomDetails = roomData[i];
                let details = {
                    "room_index": roomDetails.room_index,
                    "room_type_code": roomDetails.room_type_code,
                    "room_type_name": roomDetails.room_type_name,
                    "rate_plan_code": roomDetails.rate_plan_code,
                    "smoking_preference": 0,
                    "supplements": null,
                    "price": this.getRoomPriceJson(roomDetails)
                }
                hotelRoomDetails.push(details);

                let detail =  roomDetails.room_id;
                hotelRoomDetailsTripjack = detail;
            }

            this.props.getPaxTitle();
            let blockRoomData = {
                "trace_id": this.props.location.state.trace_id,
                "result_index": hotelData && hotelData.result_index,
                "hotel_code": hotelData && hotelData.hotel_code,
                "hotel_name": hotelData && hotelData.hotel_name,
                "guest_nationality": this.props.searchQuery.guest_nationality,
                "no_of_rooms": this.props.searchQuery.no_of_rooms,
                "is_voucher_booking": true,
                "hotel_rooms_details": hotelRoomDetails,
                "star_rating": hotelData && hotelData.star_rating,
                "city_id": this.props.searchQuery.city_id,
                "booking_category_id": this.props.searchQuery.is_domestic === 'true' ? 1 : 2,
            }
            let blockRoomDataReview = {
                "hotel_code": hotelData && hotelData.hotel_code,
                "option_id": hotelRoomDetailsTripjack,
                "star_rating": hotelData && hotelData.star_rating,
                "city_id": this.props.searchQuery.city_id,
                "booking_category_id": this.props.searchQuery.is_domestic === 'true' ? 1 : 2,
            }
            let blockRoomDataCancelPolicy = {
                "hotel_code": hotelData && hotelData.hotel_code,
                "option_id": hotelRoomDetailsTripjack,
            }

            const successCb = (response) => {
                //    this.props.getBookingDetails();
            }
            const errorCb = (response) => {
                this.bookingError(response);
            }
            if(hotelData && hotelData.supplier_type === 'tripjack'){
                this.props.getTripjackHotelReview(blockRoomDataReview, successCb, errorCb);
                this.props.getTripjackCancelPolicy(blockRoomDataCancelPolicy)
            }else{
                this.props.blockHotelRoom(blockRoomData, successCb, errorCb);
            }
        }
    }

    //Generate Voucher After hold boooking
    generateVoucherAfterHold = (bookingId, tboBookingId) => {
        let data = {
            "tbo_booking_id": tboBookingId,
            "booking_id": bookingId
        }
        const errorCb = (response) => {
            this.bookingError(response);
        }
        const bookingDetailSuccessCb = (response) => {
            this.props.history.push({
                pathname: "/hotel-booking-confirm",
                state: {
                    bookingType: 'confirm',
                    response: response.booking_details,
                    booking_value: 'Vouchered',
                    cancelPolicy: this.props.cancelPolicy,
                    blockHotelDetails: this.props.blockHotelDetails,
                    adultCount: this.props.searchQuery.room_guests
                }
            });
            this.props.myAccountAction();
        }
        const successCb = (response) => {
            let data = {
                "tbo_booking_id": response.tbo_booking_id,
                "booking_id": response.booking_id,
            }
            this.props.getBookingDetails(data, bookingDetailSuccessCb, errorCb);
            this.props.myAccountAction();
        }
        this.props.generateVoucher(data, successCb, errorCb);
    }

    generateTripjackVoucherAfterHold = (response, searchQuery) => {
        let cancellationDateTripjack = this.props.cancelPolicy.response && this.props.cancelPolicy.response.cancellation_policies &&
         this.props.cancelPolicy.response.cancellation_policies.length > 0 &&
                this.props.cancelPolicy.response.cancellation_policies.filter((date) => {
                        return date.charge === 0;
                    });
                if (cancellationDateTripjack && cancellationDateTripjack.length > 0) {
                    if (cancellationDateTripjack && cancellationDateTripjack.length > 1) {
                        cancellationDateTripjack = cancellationDateTripjack.reduce((max, current) => {
                            return new Date(current.to_date) > new Date(max.to_date) ? current : max;
                        });
                    } else {
                        cancellationDateTripjack = cancellationDateTripjack[0]
                    }
                }
        let bookHotelData = {
            "trace_id": null,
            "result_index": response.tripjack_bookingId,
            "hotel_code": response.hotel_code,
            "hotel_name": response.hotel_name,
            "tripjack_bookingId": response.tripjack_bookingId,
            "guest_nationality": searchQuery.guest_nationality,
            "no_of_rooms": response.no_of_rooms,
            "is_voucher_booking": false,
            "hotel_rooms_details": response.hotel_room_details,
            "hotel_rating": response.star_rating,
            "city_id": response.city_id,
            "booking_category_id": searchQuery.is_domestic === 'true' ? 1 : 2,
            "address": response.address_line1 + response.address_line2,
            "client_reference_no": "0",
            "check_in_date": searchQuery.check_in_date,
            "total_price": response.total_price,
            "booking_id": response.booking_id,
            "last_cancellation_date":cancellationDateTripjack && cancellationDateTripjack.to_date,
            "cancellation_policy":this.props.cancelPolicy.response && this.props.cancelPolicy.response.cancellation_policy
        }

        const errorCb = (response) => {
            this.bookingError(response);
        }
        const bookingDetailSuccessCb = (response) => {
            this.props.isBookingPending(false)
            this.props.history.push({
                pathname: "/hotel-booking-confirm",
                state: {
                    bookingType: 'confirm',
                    response: this.props.hotelBookDetails.response,
                    booking_value: 'Vouchered',
                    cancelPolicy: this.props.cancelPolicy,
                    blockHotelDetails: this.props.blockHotelDetails,
                    adultCount: this.props.searchQuery.room_guests
                }
            });
            this.props.myAccountAction();
        }
        const bookingDetailSuccessCb1 = (response) => {
            if(this.props.hotelBookedDetails.response && this.props.hotelBookedDetails.response.inProgress){
                this.props.isBookingPending(true);
                setTimeout(() => {
                    this.props.getTripjackBookingHotelDetails(bookHotelData, bookingDetailSuccessCb, errorCb);
                }, 10000);
            }else{
                this.props.history.push({
                    pathname: "/hotel-booking-confirm",
                    state: {
                        bookingType: 'confirm',
                        response: this.props.hotelBookDetails.response,
                        booking_value: 'Vouchered',
                        cancelPolicy: this.props.cancelPolicy,
                        blockHotelDetails: this.props.blockHotelDetails,
                        adultCount: this.props.searchQuery.room_guests
                    }
                });
                this.props.myAccountAction();
            }
        }
        const successCb = () => {
            this.props.getTripjackBookingHotelDetails(bookHotelData, bookingDetailSuccessCb1, errorCb);
            //this.props.myAccountAction();
        }
        this.props.generateConfirmBook(bookHotelData, successCb, errorCb);
    }

    render() {
        let initialValues = {};
        var validationSchema = {};
        let checkInDate = this.props.searchQuery.check_in_date.split('/')[2] + '-' + this.props.searchQuery.check_in_date.split('/')[1] + '-' + this.props.searchQuery.check_in_date.split('/')[0];
        let checkOutDate = this.props.searchQuery.check_out_date.split('/')[2] + '-' + this.props.searchQuery.check_out_date.split('/')[1] + '-' + this.props.searchQuery.check_out_date.split('/')[0];

        let self = this;
        for (let i = 1; i <= this.props.searchQuery.room_guests.length; i++) {
            const isPanMandatory = this.props.type ? false : this.props.location.state.roomData[i-1] && this.props.location.state.roomData[i-1].is_pan_mandatory;
            const isPassportMandatory = this.props.type ? false : (this.props.location.state.roomData[i-1] && this.props.location.state.roomData[i-1].is_passport_mandatory === true ? true: false);

            for (let j = 1; j <= this.props.searchQuery.room_guests[i - 1].no_of_adults; j++) {
                initialValues["first_name_room" + i + '_adult' + j] = "";
                initialValues["last_name_room" + i + '_adult' + j] = "";
                initialValues["guest_title_room" + i + '_adult' + j] = "Mr";
                initialValues["pan_no_room" + i + '_adult' + j] = "";
                initialValues["contact_no_room" + i + '_adult' + j] = "";

                if ((this.props.searchQuery.is_domestic === 'false') || isPassportMandatory) {
                    initialValues["passport_no_room" + i + '_adult' + j] = "";
                    initialValues["passport_issue_date_room" + i + '_adult' + j] = ""
                    initialValues["passport_exp_date_room" + i + '_adult' + j] = ""
                    initialValues["passporDaytIssue" + i + "_adult" + j] = null;
                    initialValues["passporMonthtIssue" + i + "_adult" + j] = null;
                    initialValues["passporYeartIssue" + i + "_adult" + j] = null;

                    initialValues["passportDayExpiry" + i + "_adult" + j] = null;
                    initialValues["passportMonthExpiry" + i + "_adult" + j] = null;
                    initialValues["passportYearExpiry" + i + "_adult" + j] = null;
                }

                validationSchema = Object.assign(validationSchema,
                    {
                        ["first_name_room" + i + '_adult' + j]: Yup.string().trim().min(2, 'First Name should have min 2 character!').max(50, 'First Name can not be greater than 50 character!')
                            .test("first_name_room" + i + '_adult' + j, 'Invalid First Name.',
                                function (value) {
                                    if (value && value.indexOf(' ') > 0) {
                                        return true;
                                    } else {
                                        if (self.props.paxTitles.response) {
                                            for (let i = 0; i < self.props.paxTitles.response.pax_titles.length; i++) {
                                                if (value === self.props.paxTitles.response.pax_titles[i].passenger_title)
                                                    return false;
                                            }
                                        }
                                        return true;
                                    }
                                }
                            )
                            .test(
                                (["first_name_room" + i + '_adult' + j],["last_name_room" + i + '_adult' + j]),
                                "Name should not be same.",
                                function (value,valu1) {
                                  if (value === "" || !value) {
                                    return this.createError({message:"First Name is Required."})
                                  } else {
                                    let flag = false;
                                    let firstName_1 = "";
                                    let lastName_1 = "";
                                    let fullName_1 = "";
                                    let firstName_2 = "";
                                    let lastName_2 = "";
                                    let fullName_2 = "";
                              
                                    for (let m = 0; m < j; m++) {
                                      firstName_1 = this.resolve(
                                        Yup.ref("first_name_room" + i + '_adult' + (m+1))
                                      );
                                      lastName_1 = this.resolve(
                                        Yup.ref("last_name_room" + i + '_adult' + (m+1))
                                      );
                                      fullName_1 = (firstName_1 + " " + lastName_1).toLowerCase();
                              
                                      for (let p = 0; p < j; p++) {
                                        if (p !== m) {
                                          firstName_2 = this.resolve(
                                            Yup.ref("first_name_room" + i + '_adult' + (p+1))
                                          );
                                          lastName_2 = this.resolve(
                                            Yup.ref("last_name_room" + i + '_adult' + (p+1))
                                          );
                                          fullName_2 = (
                                            firstName_2 +
                                            " " +
                                            lastName_2
                                          ).toLowerCase();
                              
                                          if (fullName_1 === fullName_2) {
                                            flag = true;
                                            this.createError({message:"Name should not be same."})
                                            break;
                                          }
                                        }
                                      }
                                      if (flag) {
                                        break;
                                      }
                                    }
                              
                                    return !flag;
                                  }
                                }
                              ).required('First Name is required.')
                            .test(
                                ["first_name_room" + i + '_adult' + j],
                                "Name should not be same.",
                                function (value) {
                                    if (value === "" || !value) {
                                        return this.createError({ message: "Invalid First Name." });
                                    } else {
                                        let flag = false;
                                        let firstName_1 = "";
                                        let lastName_1 = "";
                                        let fullName_1 = "";
                                        let firstName_2 = "";
                                        let lastName_2 = "";
                                        let fullName_2 = "";
                            
                                        firstName_1 = this.resolve(Yup.ref("first_name_room" + i + '_adult' + j));
                                        lastName_1 = this.resolve(Yup.ref("last_name_room" + i + '_adult' + j));
                                        fullName_1 = (firstName_1 + " " + lastName_1).toLowerCase();
                            
                                        for (let k = 1; k <= i - 1; k++) {
                                            for (let l = 1; l <= j+1; l++) {
                                                firstName_2 = this.resolve(Yup.ref("first_name_room" + k + '_adult' + l));
                                                lastName_2 = this.resolve(Yup.ref("last_name_room" + k + '_adult' + l));
                                                fullName_2 = (firstName_2 + " " + lastName_2).toLowerCase();
                                                if (fullName_1 && fullName_2 && fullName_1 === fullName_2) {
                                                    flag = true;
                                                    return false;
                                                }
                                            }
                                        }
                                        
                            
                                        return true;
                                    }
                                }
                            )
                            .matches(/^[a-zA-Z\s]+$/, "First Name is invalid.")
                            .required('First Name is required.'),
                        ["last_name_room" + i + '_adult' + j]: Yup.string().trim().min(2, 'Last Name should have min 2 character!').max(50, 'Last Name can not be greater than 50 character!')
                            .test("last_name_room" + i + '_adult' + j, 'Invalid Last Name.',
                                function (value) {
                                    if (value && value.indexOf(' ') > 0) {
                                        return false
                                    }
                                     else {
                                        if (self.props.paxTitles.response) {
                                            for (let i = 0; i < self.props.paxTitles.response.pax_titles.length; i++) {
                                                if (value === self.props.paxTitles.response.pax_titles[i].passenger_title)
                                                    return  false;
                                            }
                                        }
                                        return true;
                                    }
                                }
                            )
                            .matches(/^[a-zA-Z\s]+$/, "Last Name is invalid.")
                            .required('Last Name is required.'),
                        ["guest_title_room" + i + '_adult' + j]: Yup.string()
                            .required('Title is required'),
                        ["contact_no_room" + i + '_adult' + j]: Yup.string()
                        .typeError("Please enter valid phone number")
                        .matches(/^[0-9]+$/, "Must be only digits")
                        .min(10, "Must be exactly 10 digits")
                        .max(10, "Must be exactly 10 digits")
                            .test(["contact_no_room" + i + '_adult' + j], 'Please enter valid mobile number.',
                                function (value) {
                                    if (value === '' || value === undefined) {
                                        return true;
                                    }
                                    else {
                                        var numberPattern = /^[+]?[(]?[0-9]{3}[)]?[-\\.]?[0-9]{3}[-\\.]?[0-9]{4,6}$/;
                                        return numberPattern.test(value);
                                    }

                                }),
                        is_corporate: Yup.bool().oneOf([true, false]).notRequired(),
                        isCopyPANToAll: Yup.bool().oneOf([true, false]).notRequired(),
                    });

                    if(isPassportMandatory) {
                        if(this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type ==='tripjack' &&isPassportMandatory === true){
                            validationSchema = Object.assign(validationSchema, {
                                ["passport_no_room" + i + '_adult' + j] : Yup.string().required("Passport Number is required"),

                                ["passport_issue_date_room" + i + '_adult' + j] : Yup.string().required("Passport Issue Date is required")
                                
                                  .test(
                                    ["passport_issue_date_room" + i + "_adult" + j],
                                    "Passport Issue date must not be greater than Check In Date",
                                    function(value) {
                                        let bookingDate=new Date(checkInDate);
                                        let issueDate=new Date(value)
                                      if (bookingDate < issueDate) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  .test(
                                    ["passport_issue_date_room" + i + "_adult" + j],
                                    "Passport Expiry Date must not be equal to Check In Date",
                                    function(value) {
                                      if (checkInDate === value) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  .test(
                                    ["passport_issue_date_room" + i + "_adult" + j],
                                    "Enter valid date",
                                    function(value) {
                                        let expiryDate=this.resolve(
                                            Yup.ref("passport_exp_date_room" + i + '_adult' + j)
                                          );
                                      if (value === expiryDate) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  .test(
                                    ["passport_issue_date_room" + i + "_adult" + j],
                                    "Passport Issue Date must not be equal to Passport Expiry Date",
                                    function(value) {
                                      if (value === "Invalid Date") {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  
                                .nullable(),

                                ["passport_exp_date_room" + i + '_adult' + j] : Yup.string().required("Passport Expiration Date required")
                                .test(
                                    ["passport_exp_date_room" + i + "_adult" + j],
                                    "Passport Expiry must not be equal to Check Out Date",
                                    function(value) {
                                      if (value === checkOutDate) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  .test(
                                    ["passport_exp_date_room" + i + "_adult" + j],
                                    "Passport Expiry Date must be greater than Check Out Date",
                                    function(value) {
                                        let bookOutDate=new Date(checkOutDate);
                                        let expiryDate=new Date(value)
                                      if (bookOutDate > expiryDate) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  .test(
                                    ["passport_exp_date_room" + i + "_adult" + j],
                                    "Passport Expiry Date must not be equal to Passport Issue Date",
                                    function(value) {
                                        let issueDate=this.resolve(
                                            Yup.ref("passport_issue_date_room" + i + '_adult' + j)
                                          );
                                      if (issueDate === value) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  
                                .nullable(),
                                                            
                            })
                        }
                        if(this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type !=='tripjack'){
                            validationSchema = Object.assign(validationSchema, {
                                ["passport_no_room" + i + '_adult' + j] : Yup.string().required("Passport Number is required"),

                                ["passport_issue_date_room" + i + '_adult' + j] : Yup.string().required("Passport Issue Date is required")
                                
                                  .test(
                                    ["passport_issue_date_room" + i + "_adult" + j],
                                    "Passport Issue date must not be greater than Check In Date",
                                    function(value) {
                                        let bookingDate=new Date(checkInDate);
                                        let issueDate=new Date(value)
                                      if (bookingDate < issueDate) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  .test(
                                    ["passport_issue_date_room" + i + "_adult" + j],
                                    "Passport Expiry Date must not be equal to Check In Date",
                                    function(value) {
                                      if (checkInDate === value) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  .test(
                                    ["passport_issue_date_room" + i + "_adult" + j],
                                    "Enter valid date",
                                    function(value) {
                                        let expiryDate=this.resolve(
                                            Yup.ref("passport_exp_date_room" + i + '_adult' + j)
                                          );
                                      if (value === expiryDate) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  .test(
                                    ["passport_issue_date_room" + i + "_adult" + j],
                                    "Passport Issue Date must not be equal to Passport Expiry Date",
                                    function(value) {
                                      if (value === "Invalid Date") {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  
                                .nullable(),

                                ["passport_exp_date_room" + i + '_adult' + j] : Yup.string().required("Passport Expiration Date required")
                                .test(
                                    ["passport_exp_date_room" + i + "_adult" + j],
                                    "Passport Expiry must not be equal to Check Out Date",
                                    function(value) {
                                      if (value === checkOutDate) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  .test(
                                    ["passport_exp_date_room" + i + "_adult" + j],
                                    "Passport Expiry Date must be greater than Check Out Date",
                                    function(value) {
                                        let bookOutDate=new Date(checkOutDate);
                                        let expiryDate=new Date(value)
                                      if (bookOutDate > expiryDate) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  .test(
                                    ["passport_exp_date_room" + i + "_adult" + j],
                                    "Passport Expiry Date must not be equal to Passport Issue Date",
                                    function(value) {
                                        let issueDate=this.resolve(
                                            Yup.ref("passport_issue_date_room" + i + '_adult' + j)
                                          );
                                      if (issueDate === value) {
                                        return false;
                                      }else{
                                        return true
                                      }
                                    }
                                  )
                                  
                                .nullable(),
                                                            
                            })
                        }
                        
                    }else if(!isPassportMandatory){
                        if(this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type ==='tripjack' &&isPassportMandatory === true){
                            validationSchema = Object.assign(validationSchema, {
                                ["passport_no_room" + i + '_adult' + j] : Yup.string(),

                                ["passport_issue_date_room" + i + '_adult' + j] : Yup.string()
                                .test({
                                    
                                    message:"Passport Issue date must not be greater than Check In Date",
                                    name:"passport_issue_date_room",
                                    exclusive: true,
                                    test:(value)=>{
                                        let passport_value= Yup.ref("passport_no_room" + i + '_adult' + j)
                                        let bookingDate = new Date(checkInDate);
                                        let issueDate = new Date(value)
                                        if(bookingDate < issueDate){
                                            return false
                                        }else{
                                            return true
                                        }
                                    },
                                }).test({
                                    message:"Passport Issue Date must not be equal to Check In Date",
                                    name:"passport_issue_date_room2",
                                    exclusive: true,
                                    test:(value)=>{
                                        if(checkInDate === value){
                                            return false
                                        }else{
                                            return true
                                        }
                                    },
                                })
                            
                                  
                                .nullable(),

                                ["passport_exp_date_room" + i + '_adult' + j] : Yup.string()
                                .test({
                                    message:"Passport Issue date must not be greater than Check In Date",
                                    name:"passport_issue_date_room",
                                    exclusive: true,
                                    test:(value)=>{
                                        let bookOutDate = new Date(checkOutDate);
                                        let expiryDate = new Date(value)
                                        if (bookOutDate > expiryDate) {
                                            return false;
                                        } else {
                                            return true
                                        }
                                    },
                                }).test({
                                    message:"Passport Expiry Date must be greater than Check Out Date",
                                    name:"passport_issue_date_room2",
                                    exclusive: true,
                                    test:(value)=>{
                                        if(value === checkOutDate){
                                            return false
                                        }else{
                                            return true
                                        }
                                    },
                                })
                                //   .test(
                                //     ["passport_exp_date_room" + i + "_adult" + j],
                                //     "Passport Expiry Date must not be equal to Passport Issue Date",
                                //     function(value) {
                                //         let issueDate=this.resolve(
                                //             Yup.ref("passport_issue_date_room" + i + '_adult' + j)
                                //           );
                                //       if (issueDate === value) {
                                //         return false;
                                //       }else{
                                //         return true
                                //       }
                                //     }
                                //   )
                                  
                                .nullable(),
                                                            
                            })
                        }
                        if(this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type !=='tripjack'){
                            validationSchema = Object.assign(validationSchema, {
                                ["passport_no_room" + i + '_adult' + j] : Yup.string(),

                                ["passport_issue_date_room" + i + '_adult' + j] : Yup.string()
                                .test({
                                    
                                    message:"Passport Issue date must not be greater than Check In Date",
                                    name:"passport_issue_date_room",
                                    exclusive: true,
                                    test:(value)=>{
                                        let passport_value= Yup.ref("passport_no_room" + i + '_adult' + j)
                                        let bookingDate = new Date(checkInDate);
                                        let issueDate = new Date(value)
                                        if(bookingDate < issueDate){
                                            return false
                                        }else{
                                            return true
                                        }
                                    },
                                }).test({
                                    message:"Passport Issue Date must not be equal to Check In Date",
                                    name:"passport_issue_date_room2",
                                    exclusive: true,
                                    test:(value)=>{
                                        if(checkInDate === value){
                                            return false
                                        }else{
                                            return true
                                        }
                                    },
                                })
                            
                                  
                                .nullable(),

                                ["passport_exp_date_room" + i + '_adult' + j] : Yup.string()
                                .test({
                                    message:"Passport Issue date must not be greater than Check In Date",
                                    name:"passport_issue_date_room",
                                    exclusive: true,
                                    test:(value)=>{
                                        let bookOutDate = new Date(checkOutDate);
                                        let expiryDate = new Date(value)
                                        if (bookOutDate > expiryDate) {
                                            return false;
                                        } else {
                                            return true
                                        }
                                    },
                                }).test({
                                    message:"Passport Expiry Date must be greater than Check Out Date",
                                    name:"passport_issue_date_room2",
                                    exclusive: true,
                                    test:(value)=>{
                                        if(value === checkOutDate){
                                            return false
                                        }else{
                                            return true
                                        }
                                    },
                                })
                                //   .test(
                                //     ["passport_exp_date_room" + i + "_adult" + j],
                                //     "Passport Expiry Date must not be equal to Passport Issue Date",
                                //     function(value) {
                                //         let issueDate=this.resolve(
                                //             Yup.ref("passport_issue_date_room" + i + '_adult' + j)
                                //           );
                                //       if (issueDate === value) {
                                //         return false;
                                //       }else{
                                //         return true
                                //       }
                                //     }
                                //   )
                                  
                                .nullable(),
                                                            
                            })
                        }
                    }

                    if(isPanMandatory) {
                        if(this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type ==='tripjack' &&this.props.searchQuery.is_domestic === 'true'){
                            validationSchema = Object.assign(validationSchema, {
                                ["pan_no_room" + i + '_adult' + j]: Yup.string().matches(/^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/, "Please enter valid PAN card number").required("PAN No is Mandatory")
                            })
                        }
                        if(this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type ==='tripjack' &&this.props.searchQuery.is_domestic === 'false'){
                            validationSchema = Object.assign(validationSchema, {
                                ["pan_no_room" + i + '_adult' + j]: Yup.string().matches(/^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/, "Please enter valid PAN card number").required("PAN No is Mandatory")
                            })
                        }
                        if(this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type !=='tripjack'){
                            validationSchema = Object.assign(validationSchema, {
                                ["pan_no_room" + i + '_adult' + j]: Yup.string().matches(/^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/, "Please enter valid PAN card number").required("PAN No is Mandatory")
                            })
                        }
                    }
                    else {
                        validationSchema = Object.assign(validationSchema, {
                            ["pan_no_room" + i + '_adult' + j]: Yup.string().matches(/^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/, "Please enter valid PAN card number")
                        })
                    }
                }

            for (let k = 1; k <= this.props.searchQuery.room_guests[i - 1].no_of_child; k++) {
                initialValues["first_name_room" + i + '_child' + k] = "";
                initialValues["last_name_room" + i + '_child' + k] = "";
                initialValues["guest_title_room" + i + '_child' + k] = "Mstr";

                validationSchema = Object.assign(validationSchema,
                    {
                        ["first_name_room" + i + '_child' + k]: Yup.string()
                            .test("first_name_room" + i + '_child' + k, 'Whitespace is not allowed in First Name.',
                                function (value) {
                                    if (value && value.indexOf(' ') > 0) {
                                        return true
                                    } else {
                                        return true;
                                    }
                                }
                            )
                            .min(2, 'First Name should have min 2 character!').max(50, 'First Name can not be greater than 50 character!').required('First Name is required.')
                            .test(
                                ["first_name_room" + i + '_adult' + k],
                                "Name should not be same.",
                                function (value) {
                                  if (value === "" || !value) {
                                    return this.createError({message:"Invalid First Name."})
                                  } else {
                                    let flag = false;
                                    let firstName_1 = "";
                                    let lastName_1 = "";
                                    let fullName_1 = "";
                                    let firstName_2 = "";
                                    let lastName_2 = "";
                                    let fullName_2 = "";
                              
                                    for (let m = 0; m < k; m++) {
                                      firstName_1 = this.resolve(
                                        Yup.ref("first_name_room" + i + '_child' + (m+1))
                                      );
                                      lastName_1 = this.resolve(
                                        Yup.ref("last_name_room" + i + '_child' + (m+1))
                                      );
                                      fullName_1 = (firstName_1 + " " + lastName_1).toLowerCase();
                              
                                      for (let p = 0; p < k; p++) {
                                        if (p !== m) {
                                          firstName_2 = this.resolve(
                                            Yup.ref("first_name_room" + i + '_child' + (p+1))
                                          );
                                          lastName_2 = this.resolve(
                                            Yup.ref("last_name_room" + i + '_child' + (p+1))
                                          );
                                          fullName_2 = (
                                            firstName_2 +
                                            " " +
                                            lastName_2
                                          ).toLowerCase();
                              
                                          if (fullName_1 === fullName_2) {
                                            flag = true;
                                            this.createError({message:"Name should not be same."})
                                            break;
                                          }
                                        }
                                      }
                                      if (flag) {
                                        break;
                                      }
                                    }
                              
                                    return !flag;
                                  }
                                }
                              ).required('First Name is required.')
                            .test(
                                ["first_name_room" + i + '_child' + k],
                                "Name should not be same.",
                                function (value) {
                                    if (value === "" || !value) {
                                        return this.createError({ message: "Invalid First Name." });
                                    } else {
                                        let flag = false;
                                        let firstName_1 = "";
                                        let lastName_1 = "";
                                        let fullName_1 = "";
                                        let firstName_2 = "";
                                        let lastName_2 = "";
                                        let fullName_2 = "";
                            
                                        firstName_1 = this.resolve(Yup.ref("first_name_room" + i + '_child' + k));
                                        lastName_1 = this.resolve(Yup.ref("last_name_room" + i + '_child' + k));
                                        fullName_1 = (firstName_1 + " " + lastName_1).toLowerCase();
                            
                                        for (let t = 1; t <= i - 1; t++) {
                                            for (let l = 1; l <= k+1; l++) {
                                                firstName_2 = this.resolve(Yup.ref("first_name_room" + t + '_child' + l));
                                                lastName_2 = this.resolve(Yup.ref("last_name_room" + t + '_child' + l));
                                                fullName_2 = (firstName_2 + " " + lastName_2).toLowerCase();
                                                if (fullName_1 && fullName_2 && fullName_1 === fullName_2) {
                                                    flag = true;
                                                    return false;
                                                }
                                            }
                                        }
                                        
                            
                                        return true;
                                    }
                                }
                            ).required('First Name is required.'),
                        ["last_name_room" + i + '_child' + k]: Yup.string()
                            .test("last_name_room" + i + '_child' + k, 'Whitespace is not allowed in Last Name.',
                                function (value) {
                                    if (value && value.indexOf(' ') > 0) {
                                        return false
                                    } else {
                                        return true;
                                    }
                                }
                            )
                            .min(2, 'Last Name should have min 2 character!').max(50, 'Last Name can not be greater than 50 character!').required('Last Name is required.'),
                        ["guest_title_room" + i + '_child' + k]: Yup.string()
                            .required('Title is required')
                    })

                if(isPassportMandatory) {
                    if (this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type === 'tripjack' && this.props.searchQuery.is_domestic === 'false') {
                        validationSchema = Object.assign(validationSchema, {
                            ["passport_no_room" + i + '_child' + k]: Yup.string(),
                            ["passport_issue_date_room" + i + '_child' + k]: Yup.string().nullable(),
                            ["passport_exp_date_room" + i + '_child' + k]: Yup.string().nullable(),

                        })
                    }
                    if (this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type !== 'tripjack') {
                        validationSchema = Object.assign(validationSchema, {
                            ["passport_no_room" + i + '_child' + k]: Yup.string().required("Passport Number is required"),
                            ["passport_issue_date_room" + i + '_child' + k]: Yup.string().required("Passport Issue Date is required").nullable(),
                            ["passport_exp_date_room" + i + '_child' + k]: Yup.string().required("Passport Expiration Date required").nullable(),

                        })
                    }
                }
            }

            if (this.state.isActiveGuestBooking) {
                validationSchema = Object.assign(validationSchema,
                    {
                        terms_conditions: Yup.bool().oneOf([true], "required"),
                        under_cancel: Yup.bool().oneOf([true], "required")
                    })
            }

            validationSchema = Object.assign(validationSchema,
                {
                    arrival_hour: Yup.string().test('arrival_hour', 'Arrival Hour Required.',
                        function (value) {
                            let transportType = this.resolve(Yup.ref("transport_type"));
                            let flightNo = this.resolve(Yup.ref("flight_no"));
                            let transportNo = this.resolve(Yup.ref("transport_no"));
                            if (transportType === 'flight') {
                                if (flightNo) {
                                    return value ? true : false;
                                }
                                else {
                                    return true;
                                }
                            }
                            else if (transportType === 'surface') {
                                if (transportNo) {
                                    return value ? true : false;
                                }
                                else {
                                    return true;
                                }
                            }
                        }),
                    arrival_minute: Yup.string().test('arrival_minute', 'Required.',
                        function (value) {
                            let transportType = this.resolve(Yup.ref("transport_type"));
                            let flightNo = this.resolve(Yup.ref("flight_no"));
                            let transportNo = this.resolve(Yup.ref("transport_no"));
                            if (transportType === 'flight') {
                                if (flightNo) {
                                    return value ? true : false;
                                }
                                else {
                                    return true;
                                }
                            }
                            else if (transportType === 'surface') {
                                if (transportNo) {
                                    return value ? true : false;
                                }
                                else {
                                    return true;
                                }
                            }
                        })
                })
            //If is_package_details_mandatory is true then arrival details are mandatory
            if (this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.is_package_details_mandatory) {
                validationSchema = Object.assign(validationSchema,
                    {
                        flight_no: Yup.string().test('flight_no', 'Required.',
                            function (value) {
                                let transportNo = this.resolve(Yup.ref("transport_no"));
                                if (transportNo) {
                                    return true;
                                }
                                else {
                                    return value ? true : false;
                                }
                            }),
                        transport_no: Yup.string().test('transport_no', 'Required.',
                            function (value) {
                                let flightNo = this.resolve(Yup.ref("flight_no"));
                                if (flightNo) {
                                    return true;
                                }
                                else {
                                    return value ? true : false;
                                }
                            }),
                        arrival_hour: Yup.string().required('Arrival hour is mandatory'),
                        arrival_minute: Yup.string().required('Arrival minute is mandatory'),
                        check_in_date: Yup.string().required('Check in date is mandatory')
                    })
            }
            initialValues["transport_type"] = "flight";
            initialValues["flight_no"] = "";
            initialValues["transport_no"] = "";
            initialValues["check_in_date"] = getNewDateFormted(checkInDate);
            initialValues["arrival_hour"] = "";
            initialValues["arrival_minute"] = "";
            initialValues["terms_conditions"] = false;
            initialValues["under_cancel"] = false;
            initialValues["is_corporate"] = false;
            initialValues["isCopyPANToAll"] = false;
            initialValues["isPanMandatoryFormik"] = isPanMandatory;

            var validationSchemas = Yup.object().shape(
                validationSchema
            );
        }

        return (
            <React.Fragment>
                <HotelSearchHeaderComponent {...this.props} />
                {
                    <Formik
                        initialValues={initialValues} //Set initial values of fields to blank
                        validationSchema={validationSchemas}
                        onSubmit={(values) => this.handleSubmit(values)}
                        render={formikProps =>
                            this.props.type
                                ?
                                <BookingConfirmComponent {...this.props} generateVoucherAfterHold={this.generateVoucherAfterHold} generateTripjackVoucherAfterHold={this.generateTripjackVoucherAfterHold}/>
                                :
                                <HotelBookComponent
                                    {...this.props}
                                    {...this.state}
                                    {...formikProps}
                                    isActiveGuestBooking={this.state.isActiveGuestBooking}
                                    isActiveBookingReview={this.state.isActiveBookingReview}
                                    switchPage={this.switchPage}
                                    handleConfirmationPopupNRI={this.handleConfirmationPopupNRI}
                                    handlePopupSubmit={this.handlePopupSubmit}
                                    scrollToFirstError={this.scrollToFirstError}
                                />
                        }

                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        searchQuery: state.hotelSearchReducer.searchQuery,
        paxTitles: state.hotelBookReducer.paxTitles,
        blockHotelDetails: state.hotelBookReducer.blockHotelDetails,
        addPassengerData: state.shared.addPassenger,
        isBookingPendingData:state.shared.isBookingPending,
        customerList: state.shared.customerList,
        agentProductAccess: state.shared.agentProductAccess,
        productResponse: state.shared.productResponse,
        hotelBookDetails: state.hotelBookReducer.hotelBookDetails,
        hotelBookedDetails: state.hotelBookReducer.hotelBookedDetails,
        accountResponse: state.myAccount,//my account details
        voucherDetails: state.hotelBookReducer.voucherDetails,
        hotelReview: state.hotelBookReducer.hotelReview,
        cancelPolicy: state.hotelSearchDetailsReducer.cancelPolicy,
        isShowPopup:state.isShowPopup
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPaxTitle: () => dispatch(getPaxTitle()),//get pax titles
        blockHotelRoom: (data, successCb, errorCb) => dispatch(blockHotelRoom(data, successCb, errorCb)),
        addPassenger: (data, successcb, errorcb) => dispatch(addPassenger(data, successcb, errorcb)), //add passenger in customer list 
        getCustomerList: () => dispatch(getCustomerList()),//get customer list
        hotelBooking: (data, successCb, errorCb) => dispatch(hotelBooking(data, successCb, errorCb)), //Book hotel
        getBookingDetails: (data, successCb, errorCb) => dispatch(getBookingHotelDetails(data, successCb, errorCb)),
        myAccountAction: () => dispatch(myAccountAction()),
        generateVoucher: (data, successCb, errorCb) => dispatch(generateVoucher(data, successCb, errorCb)),//Generate Voucher to confirm booking
        getTripjackHotelReview: (data, successCb, errorCb) => dispatch(getTripjackHotelReview(data, successCb, errorCb)), // tripjack hotel review
        getTripjackCancelPolicy: (data) => dispatch(getTripjackCancelPolicy(data)), //tripjack cancel policy
        hotelTripjackBooking: (data, successCb, errorCb) => dispatch(hotelTripjackBooking(data, successCb, errorCb)), //Book Tripjack hotel
        generateConfirmBook: (data, successCb, errorCb) => dispatch(generateConfirmBook(data, successCb, errorCb)),//Generate tripjack Voucher to confirm booking
        getTripjackBookingHotelDetails: (data, successCb, errorCb) => dispatch(getTripjackBookingHotelDetails(data, successCb, errorCb)),
        isBookingPending: (response) => dispatch(isBookingPending(response)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelBookContainer);
