/* external dependencies */
import React from 'react';
import AgentLeftBar from '../agent/AgentLeftBarComponent';
import AgentHeaderNavigation from '../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../agent/AgentCommonHeaderComponent';
import history from "../../shared/history";
import {DayPicker, MonthPicker, YearPicker} from "react-dropdown-date";
import { getCurrentDate } from '../../shared/commonHelper';

class AgentInfoUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'ownership-details',
            leftNavActive: '/agent-info',
            url: history.location.pathname,
            gstCertificateClass : false,
            selectedFile : null,
            thumb: undefined,
            gstFlage:false,
            addressFlage:false,
            panFlage:false,
        }
    }

    handleGst(event){
        this.setState({
            gstCertificateClass : event.checked ? true : false
        });
    };

    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }
    handleAgentTabClick(searchType, event) {
        this.setState({ activeTab: searchType });
    }

    render() {
        const { logoutAction,values, errors, touched, handleBlur,handleChange,handleSubmit, accountResponse, productResponse,setFieldTouched,setFieldValue } = this.props;
        let activeLeftNavigation  = this.state.url;
        let currentDate = getCurrentDate();
        const endDate = currentDate.getFullYear();
        var errorMsg,successMsg;
        
        if(this.props.updateAgentMsg === 'success')
        {
            errorMsg = '';
            successMsg = 'Agent details has been successfully updated.';
        }
        else if(this.props.updateAgentMsg === 'error')
        {
            errorMsg = 'Agent details updation failed.';
            successMsg = '';
        }
        return (
            <React.Fragment>
                {/*nav-section Start*/}
                <AgentHeaderNavigation productResponseList={productResponse} {...this.props}/>
                {/*nav-section end*/}
                {/*Mid Wrapper Start*/}
                <section className="mid-wrapper">
                    <AgentCommonHeader {...this.props}/>
                    <div className="my-account-info">
                        <div className="container">
                            <div className="my-account-sec">
                                <div className="my-account-outer">
                                    <AgentLeftBar logoutAction={logoutAction} handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} />
                                    {activeLeftNavigation === '/agent-info' ?
                                        <div className="profile-detail-info">
                                            <div className="edit-profile-bar">
                                                <div className="pull-right">
                                                    { /* eslint-disable-next-line */}
                                                    <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                                                </div>
                                            </div>
                                            <div className="profile-tabs-info">
                                                <div className="account-information content-tabs">
                                                    <div className="information-tabs">
                                                        <ul className="tabs-nav">
                                                            <li className={(this.state.activeTab === "ownership-details") ? "active" : ""}>{ /* eslint-disable-next-line */}<span className="anchor" title="#account_information" href="" onClick={this.handleAgentTabClick.bind(this, 'ownership-details')}><i className="icon icon-user-fill" /><span>Ownership Details</span></span></li>
                                                            <li className={(this.state.activeTab === "company-information") ? "active" : ""}>{ /* eslint-disable-next-line */}<span className="anchor" title="#account_information" href="" onClick={this.handleAgentTabClick.bind(this, 'company-information')}><i className="icon icon-currency-noti" /><span>Company Information</span></span></li>
                                                            <li className={(this.state.activeTab === "communication-details") ? "active" : ""}>{ /* eslint-disable-next-line */}<span className="anchor" title="#account_information" href="" onClick={this.handleAgentTabClick.bind(this, 'communication-details')}><i className="icon icon-contact-file" /><span>Communication Details</span></span></li>
                                                        </ul>
                                                    </div>
                                                    {successMsg && <div className="alert alert-success" style={{ display: (this.props.hideMsg === true)?'none':'' }}> 
                                                    <strong> <i className="fa fa-check-circle"></i> {successMsg} </strong> <i className="fa fa-times" aria-hidden="true" onClick={this.props.showReplyForm}></i> </div>}
                                                    {errorMsg && <div className="alert alert-danger" style={{ display: (this.props.hideMsg === true)?'none':'' }}> 
                                                    <strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong> <i className="fa fa-times" aria-hidden="true" onClick={this.props.showReplyForm}></i></div>}
                                                    <div className="tab-container">
                                                        <div className="tab-content" id="account_information" style={{ display: (this.state.activeTab === 'ownership-details')?'block':'none' }}>
                                                            <div className="tabs-detail-card">
                                                                <div className="agent-details">
                                                                    <div className="agent-form">
                                                                    <div className="form-row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">First Name<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                <input name="first_name" className="form-control" type="text" value={values.first_name} placeholder="(ex. Ramesh)" onBlur={handleBlur} onChange={handleChange} />
                                                                                </div>
                                                                                {errors.first_name && touched.first_name && <span className="error-msg">{errors.first_name}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">Last Name<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                <input name="last_name" className="form-control" type="text" value={values.last_name} placeholder="(ex. Gupta)"  onBlur={handleBlur} onChange={handleChange} />
                                                                                </div>
                                                                                {errors.last_name && touched.last_name && <span className="error-msg">{errors.last_name}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">Gender<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                <div className="radio-check inline-check">
                                                                                    <input type="radio" id="1" name="gender" onBlur={handleBlur} onChange={(event) => { setFieldValue("gender", "male");
                                    }} checked={values.gender === 'male' ? true : ''}/>
                                                                                    <label htmlFor="1"><i className="fa fa-male" aria-hidden="true"></i>Male</label>
                                                                                </div>
                                                                                <div className="radio-check inline-check">
                                                                                    <input type="radio" id="2" name="gender" onBlur={handleBlur} onChange={(event) => { setFieldValue("gender", "female");
                                    }} checked={values.gender === 'female' ? true : ''}/>
                                                                                    <label htmlFor="2"><i className="fa fa-female" aria-hidden="true"></i>Female</label>
                                                                                </div>
                                                                                {errors.gender && touched.gender && <span className="error-msg">{errors.gender}</span>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">Date of Birth<span className="red-error">*</span></label>
                                                                                <div className="control-group col-section">
                                                                                <div className="form-row">
                                                                                    <div className="col-md-4">
                                                                                        <div className="select-custom">
                                                                                        <DayPicker defaultValue={values.dobDay?values.dobDay:'Day'} year={values.dobyear} month={values.dobmonth} endYearGiven  name='dobDay' onChange={(event) => {setFieldValue("dobDay", event); }} classes={'form-control'}/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <div className="select-custom">
                                                                                        <MonthPicker defaultValue={values.dobmonth?values.dobmonth:'Month'} endYearGiven year={values.dobyear} onChange={(event) => {setFieldValue("dobmonth", parseInt(event)+1); }} name={'dobmonth'} onBlur={handleBlur} classes={'form-control'} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <div className="select-custom">
                                                                                        <YearPicker defaultValue={values.dobyear?values.dobyear:'Year'} start={1905} end={endDate} reverse onChange={(event) => { setFieldValue("dobyear", event); }} name={'dobyear'} onBlur={handleBlur} classes={'form-control'}/>
                                                                                        </div>
                                                                                        {(errors.dobDay || errors.dobDay || errors.dobyear) && (touched.dobDay || touched.dobDay || touched.dobyear) && <span className="error-msg">Please enter date of birth</span>}
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div className="form-btn-block">
                                                                            <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>Update</button>
                                                                            <button type="button" className="btn btn-grey" onClick={this.props.handleReset}>Reset</button>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-content" id="personal_information" style={{ display: (this.state.activeTab === 'company-information')?'block':'none' }}>
                                                            <div className="tabs-detail-card">
                                                                <div className="agent-details">
                                                                    <div className="agent-form">
                                                                    <div className="form-row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-block disabled-section">
                                                                                <label className="control-label">Company Name<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                <input className="form-control" type="text" value={values.company_name} placeholder="(ex. abc pvt ltd)" name="company_name" onBlur={handleBlur} onChange={handleChange}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block disabled-section">
                                                                                <label className="control-label">Type of Ownership<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                    <div className="select-custom">
                                                                                    <select className="form-control" name="ownershipType" onChange={handleChange} onBlur={handleBlur} value={values.ownershipType} >
                                                                                        <option>Select Type of Ownership</option>
                                                                                        {
                                                                                            this.props.ownerShip.response && this.props.ownerShip.response.ownerships && 
                                                                                            this.props.ownerShip.response.ownerships.map((item,key) => {
                                                                                               
                                                                                                return (
                                                                                                    <option key={key} value={item.type_id} >{item.ownership_type}</option>
                                                                                                );
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    </select>
                                                                                    </div>
                                                                                </div>
                                                                                {errors.ownershipType && touched.ownershipType && <span className="error-msg">{errors.ownershipType}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block disabled-section">
                                                                                <div className="control-group">
                                                                                <div className="checklabel"><span>GST</span><small className="checkbox">
                                                                                    <input type="checkbox" name="check_gst" onChange={(e) => { this.handleGst(e.target)}}/>
                                                                                    <label></label>
                                                                                    </small>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                        <div className="form-block file-upload-block">
                                                                            <div className={" "+(this.state.gstCertificateClass === false ? 'disabled-section':'')}>
                                                                                <label className="control-label">GST Certificate</label>
                                                                                <div className="input-group">
                                                                                <div className="file-upload-filed">
                                                                                    <div className="file-btn">Upload File
                                                                                    <input type="file" id="upload" name="gst_certificate" onChange={(event) => {
                                                                                        if(event.currentTarget.files[0] && event.currentTarget.files[0])
                                                                                        {
                                                                                            setFieldTouched('gst_certificate_file');
                                                                                            setFieldValue("gst_certificate_file", event.currentTarget.files[0]);
                                                                                            this.props.uploadFile(this,'gst_certificate',event.currentTarget.files[0]);
                                                                                            this.setState({gstFlage:true});
                                                                                        }
                                                                                        else{
                                                                                            this.setState({gstFlage:false});
                                                                                            setFieldValue("gst_certificate_file", "");
                                                                                            setFieldValue("gst_certificate", "");
                                                                                        }
                                                                                    }}/>
                                                                                    </div>
                                                                                    <div className="file_name"></div>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            { /* eslint-disable-next-line */}
                                                                            <a href={values.gst_certificate} className="btn-file-view" target="_blank"><i className={""+(values.gst_certificate ? 'fa fa-eye' : '')} aria-hidden="true"></i></a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-btn-block">
                                                                            <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>Update</button>
                                                                            <button type="button" className="btn btn-grey" onClick={this.props.handleReset}>Reset</button>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-content" id="contact_information" style={{ display: (this.state.activeTab === 'communication-details')?'block':'none' }}>
                                                            <div className="tabs-detail-card">
                                                                <div className="agent-details">
                                                                    <div className="agent-form">
                                                                    <div className="form-row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">Office Address<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                    <textarea className="form-control" name="office_address" onBlur={handleBlur} onChange={handleChange} value={values.office_address}/>
                                                                                </div>
                                                                                {errors.office_address && touched.office_address && <span className="error-msg">{errors.office_address}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">State<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                <div className="select-custom">
                                                                                <select className="form-control" name="state" onChange={handleChange} onBlur={handleBlur} value={values.state}>
                                                                                    <option value="">Select State</option>
                                                                                    {
                                                                                        this.props.allCities && this.props.allCities.state.map((item,key) => {
                                                                                                return (
                                                                                                    <option key={key} value={item.id}>{item.state}</option>
                                                                                                )
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                                </div>
                                                                                </div>
                                                                                {errors.state && touched.state && <span className="error-msg">{errors.state}</span>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">City/District<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                <div className="select-custom">
                                                                                <select className="form-control" name="city" onChange={handleChange} onBlur={handleBlur} value={values.city}>
                                                                                    <option>Select City/District</option>
                                                                                    {
                                                                                        this.props.allCities && values.state && this.props.allCities.city[values.state].map((item,key) => {
                                                                                                return (
                                                                                                    <option key={key} value={item.id}>{item.city}</option>
                                                                                                )
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                                </div>
                                                                                </div>
                                                                                {errors.city && touched.city && <span className="error-msg">{errors.city}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">Pincode</label>
                                                                                <div className="control-group">
                                                                                    <input className="form-control" type="text" placeholder="(ex. 304001)" name="pincode" onBlur={handleBlur} onChange={handleChange} value={values.pincode}/>
                                                                                </div>
                                                                                {errors.pincode && touched.pincode && <span className="error-msg">{errors.pincode}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">Phone Number</label>
                                                                                <div className="control-group">
                                                                                    <input className="form-control" type="text" placeholder="(ex. 01412222222)" name="phone_number" onBlur={handleBlur} onChange={handleChange} alue={values.phone_number}/>
                                                                                </div>
                                                                                {errors.phone_number && touched.phone_number && <span className="error-msg">{errors.phone_number}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">Mobile Number<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                    <input className="form-control" type="text" placeholder="(ex. 9999999999)" name="mobile_number" onBlur={handleBlur} onChange={handleChange} value={values.mobile_number}/>
                                                                                </div>
                                                                                {errors.mobile_number && touched.mobile_number && <span className="error-msg">{errors.mobile_number}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">Alternative Mobile Number</label>
                                                                                <div className="control-group">
                                                                                    <input className="form-control" type="text" placeholder="(ex. 9999999999)" name="alternative_mobile_number" onBlur={handleBlur} onChange={handleChange} value={values.alternative_mobile_number}/>
                                                                                </div>
                                                                                {errors.alternative_mobile_number && touched.alternative_mobile_number && <span className="error-msg">{errors.alternative_mobile_number}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">Email Address<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                    <input className="form-control" type="text" placeholder="(ex. abc@gmail.com)" name="email_address" onBlur={handleBlur} onChange={handleChange} value={values.email_address}/>
                                                                                </div>
                                                                                {errors.email_address && touched.email_address && <span className="error-msg">{errors.email_address}</span>}
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div className="col-md-6">
                                                                            <div className="form-block disabled-section">
                                                                                <label className="control-label">PAN Number<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                    <input className="form-control" type="text" placeholder="(ex. 123456)" name="pan_number" onBlur={handleBlur} onChange={handleChange}  value={values.pan_number} />
                                                                                </div>
                                                                                {errors.pan_number && touched.pan_number && <span className="error-msg">{errors.pan_number}</span>}
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div className="col-md-6">
                                                                            <div className="form-block file-upload-block">
                                                                            <div className="disabled-section">
                                                                                <label className="control-label">PAN<span className="red-error">*</span></label>
                                                                                <div className="input-group">
                                                                                <div className="file-upload-filed">
                                                                                    <div className="file-btn">Upload File
                                                                                    <input type="file" id="uploadPan" name="pan_card" 
                                                                                    // value={values.pan_card} 
                                                                                    onChange={(event) => {
                                                                                        if(event.currentTarget.files[0] && event.currentTarget.files[0])
                                                                                        {
                                                                                            setFieldTouched('pan_file');
                                                                                            setFieldValue("pan_file", event.currentTarget.files[0]);
                                                                                            this.props.uploadFile(this,'pan_card',event.currentTarget.files[0]);
                                                                                            this.setState({panFlage:true});
                                                                                        }
                                                                                        else{
                                                                                            this.setState({panFlage:false});
                                                                                            setFieldValue("pan_file", "");
                                                                                            setFieldValue("pan_card", "");
                                                                                        }
                                                                                    }}/>
                                                                                    </div>
                                                                                    <div className="panFileName file_name"></div>
                                                                                </div>
                                                                                </div>
                                                                                {errors.pan_file && touched.pan_file && <span className="error-msg">{errors.pan_file}</span>}
                                                                            </div>
                                                                            { /* eslint-disable-next-line */}
                                                                            <a href={values.pan_card} className="btn-file-view" target="_blank"><i className="fa fa-eye" aria-hidden="true"></i></a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block disabled-section">
                                                                                <label className="control-label">Address Proof Type<span className="red-error">*</span></label>
                                                                                <div className="control-group">
                                                                                <div className="select-custom">
                                                                                <select className="form-control" name="address_proof_type" onChange={handleChange} onBlur={handleBlur} value={values.address_proof_type}>
                                                                                    <option>Select Address Proof Type</option>
                                                                                    {
                                                                                        this.props.addressProof.response && this.props.addressProof.response.address_proofs &&
                                                                                        this.props.addressProof.response.address_proofs.map((item,key) => {
                                                                                                return (
                                                                                                    <option key={key} value={item.type_id}>{item.address_proof_type}</option>
                                                                                                )
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                                </div>
                                                                                </div>
                                                                                {errors.address_proof_type && touched.address_proof_type && <span className="error-msg">{errors.address_proof_type}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block file-upload-block">
                                                                            <div className="disabled-section">
                                                                                <label className="control-label">Address Proof<span className="red-error">*</span></label>
                                                                                <div className="input-group">
                                                                                <div className="file-upload-filed">
                                                                                    <div className="file-btn">Upload File
                                                                                    <input type="file" id="uploadAddressProof" name="address_proof" 
                                                                                    // value={values.address_proof} 
                                                                                    onChange={(event) => {
                                                                                        if(event.currentTarget.files[0] && event.currentTarget.files[0])
                                                                                        {
                                                                                            setFieldTouched('address_proof_file');
                                                                                            setFieldValue("address_proof_file", event.currentTarget.files[0]);
                                                                                            this.props.uploadFile(this,'address_proof',event.currentTarget.files[0]);
                                                                                            this.setState({addressFlage:true});
                                                                                        }
                                                                                        else{
                                                                                            this.setState({addressFlage:false});
                                                                                            setFieldValue("address_proof_file", "");
                                                                                            setFieldValue("address_proof", "");
                                                                                        }
                                                                                    }}/>
                                                                                    </div>
                                                                                    <div className="addressProofFile file_name"></div>
                                                                                </div>
                                                                                </div>
                                                                                {errors.address_proof_file && touched.address_proof_file && <span className="error-msg">{errors.address_proof_file}</span>}
                                                                            </div>
                                                                            { /* eslint-disable-next-line */}
                                                                            <a href={values.address_proof} className="btn-file-view" target="_blank"><i className="fa fa-eye" aria-hidden="true"></i></a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-block">
                                                                                <label className="control-label">Website</label>
                                                                                <div className="control-group">
                                                                                <input className="form-control" type="text" placeholder="(ex. https://www.jaipurflight.com)" name="website" onBlur={handleBlur} onChange={handleChange} value={values.website}/>
                                                                                </div>
                                                                                {errors.website && touched.website && <span className="error-msg">{errors.website}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-btn-block">
                                                                            <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>Update</button>
                                                                            <button type="button" className="btn btn-grey" onClick={this.props.handleReset}>Reset</button>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default AgentInfoUpdate;