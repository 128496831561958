import React, { Component } from 'react';
import jwt from 'jsonwebtoken';
import Footer from '../../components/shared/FooterComponent';
import Login from '../../containers/shared/LoginContainer';
import { Router, Switch, Route } from 'react-router-dom';
import FlightSearch from '../../containers/flights/FlightSearchContainer';
import Register from '../../containers/shared/RegisterContainer';
import FlightSearchDetails from '../../containers/flights/FlightSearchDetailsContainer';
import ThankYou from '../../components/shared/register/thankYouComponent';
import history from "../../shared/history";
import MyAccount from '../../containers/agent/MyAccountContainer';
import { connect } from 'react-redux'
import { settingAction, pagesAction, productsAction, getUserInfoJwt, postMessageRequest, getProductAccess } from '../../actions/shared/sharedAction';
import { myAccountAction } from '../../actions/agentAction';
import { logoutAction } from '../../actions/shared/sessionAction';
import HeaderWithoutSession from '../../components/shared/HeaderWithoutSession';
import HeaderWithSession from '../../components/shared/HeaderWithSession';
import AgentPasswordChange from '../../containers/agent/AgentChangePasswordContainer';
import AgentBankDetail from '../../containers/agent/AgentBankDetailContainer';
import AccountStatement from '../../containers/agent/AgentAccountStatementContainer';
import AgentMyBookings from '../../containers/agent/AgentMyBookingsContainer';
import AgentTopUp from '../../containers/agent/AgentTopUpContainer';
import AgentMarkUpTools from '../../containers/agent/AgentMarkUpToolContainer';
import AgentDeposit from '../../containers/agent/AgentDepositsContainer';
import AgentCredits from '../../containers/agent/AgentCreditsContainer';
import FlightBookTicketContainer from '../flights/FlightBookTicketContainer';
import { getParameterFromUrl } from '../../shared/commonHelper';
import AgentInfo from '../../containers/agent/AgentInfoUpdateContainer';
import AgentCalendar from '../../containers/agent/AgentCalendarContainer';
import BookingError from '../../components/shared/BookingError';
import HotelSearch from '../hotel/HotelSearchContainer';
import HotelSearchDetailsContainer from '../hotel/HotelSearchDetailsContainer';
import ChatMessageContainer from './ChatMessageContainer';
import AboutUsContainer from './AboutUsContainer';
import ContactUsContainer from './ContactUsContainer';
import TermsAndConditionsContainer from './TermsAndConditionsContainer';
import CreateReportContainer from '../agent/CreateReportContainer';
import HotelBookContainer from '../hotel/HotelBookContainer';
import PrintHotelInvoiceContainer from '../hotel/PrintHotelInvoiceContainer';
import HotelVoucherContainer from '../agent/HotelVoucherContainer';
import RailwayLoginContainer from '../railway/RailwayLoginContainer';
import RailwayLandingContainer from '../railway/RailwayLandingContainer';
import RailwayVerifyContainer from '../railway/RailwayVerifyContainer';
import VendorContainer from '../agent/VendorContainer';
import FlightBookMainContainer from '../flights/FlightBookMainContainer';
import VendorFlightBookTicketContainer from '../flights/VendorFlightBookTicketContainer';
import RailwayDetailVerification from '../railway/RailwayDetailVerificationContainer';
import PrintRailwayInvoiceContainer from '../railway/PrintRailwayInvoiceContainer';
import PrintRailwayCreditNoteContainer from '../railway/PrintRailwayCreditNoteContainer';
import OwlCarousel from 'react-owl-carousel2';
import packageJson from '../../../package.json';
import HotelRoomContainer from '../hotel/HotelRoomContainer';
import BackToTopContainer from './BackToTopContainer';
import AgentRequestCancellationContainer from '../agent/AgentRequestCancellationContainer';
class RootContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            connected: false,
            url: history.location.pathname,
            redirectionUrl : null,
            overFlowClass : 'visible',
            modalWarning: '',
        };
    }

    componentDidMount() {
        let token = getParameterFromUrl('token');
        let redirection = getParameterFromUrl('redirection');
        let refresh_token = getParameterFromUrl('refreshToken');
        let isKafilaUser = getParameterFromUrl('is_kafila_user');

        //this code is to redirect user to assigned product searching page when coming from CMS
        if (token !== null) {
            localStorage.setItem('token', token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('login', true);
            localStorage.setItem('verify-otp', true);
            localStorage.setItem('is_kafila_user', isKafilaUser);

            if (redirection !== null) {
                this.setState({
                    redirectionUrl: redirection
                });
                let data = {
                    'transaction_no': getParameterFromUrl('transaction_no'),
                    'pnr': getParameterFromUrl('pnr')
                }
                history.push({
                    pathname: '/' + redirection,
                    state: {
                        data: data
                    }
                });
                window.location.href = '/' + redirection;
            }
        }

        history.listen((location, action) => {
            this.setState({
                url: location.pathname,
            });
        });
        this.props.getUserInfoJwt(localStorage.getItem('token'));
        this.props.settingAction();
        const pagesSuccess = (pages) =>{
            
            const targetPages = pages.filter((page) => {
                return (page.notice_page_show === 1 && page.is_show === 1)
            });
            
            if(targetPages.length > 0 && (this.state.url === '/flight-search' || this.state.url === '/hotel-search' || this.state.url === '/jfrb/login') && localStorage.getItem('showWarning') === 'show')
            {
                this.setState({modalWarning: 'show'}, function()
                {
                    localStorage.setItem('showWarning', '');
                });
            }
        }
        this.props.pagesAction(pagesSuccess);
        this.props.productsAction();
        if (localStorage.getItem('login') === 'true' || localStorage.getItem('verify-otp') === 'true') {
                this.props.myAccountAction();
                this.props.getProductAccess();
        }

         // Get the current version from the environment variable
         const currentVersion = packageJson.version;

         // Get the previous version from local storage (or set it to an empty string if it doesn't exist)
         const previousVersion = localStorage.getItem('version') || '';
         // Check if the current version is different from the previous version
         if (currentVersion !== previousVersion) {
             // If the versions are different, store the current version in local storage
             localStorage.setItem('version', currentVersion);
             // Reload the page to apply any changes that may have been made
             window.location.reload(true);
         }

    }

    changeOverFlowClass = (ítem, callback) => {
        this.setState({
            overFlowClass: ítem
        }, () => {
            callback && callback();
        });
    }

    setWarningModalClass = (modalClass) =>{
        this.setState({modalWarning: modalClass});
    }

    render() {
        let outerClass;
        if (((this.state.url === "/login" || this.state.url === "/" || this.state.url === "") && !this.state.redirectionUrl) || this.state.url === '/jfrb/login') {
            outerClass = "main-section login-wrap";
        } else if (this.state.url === "/flight-search") {
            outerClass = "main-section search-form-wrap";
        } else if (this.state.url === "/hotel-search") {
            outerClass = "main-section search-form-wrap ht-form-wrap";
        } else if (this.state.url.includes("/register") || this.state.url === "/thank-you") {
            outerClass = "main-section register-wrap";
        } else if (this.state.url === "/verify-otp") {
            outerClass = "main-section login-wrap";
        }
        let sessionLogin = this.state.url === "/verify-otp" ? localStorage.getItem('verify-otp') : localStorage.getItem('login');
        
        return (
            <Router history={history}>
                <React.Fragment>
                    <WarningModalComponent pagesResponse={this.props.pagesResponse} showModal={this.state.modalWarning} setWarningModalClass={this.setWarningModalClass} />
                    <section className={outerClass} id="mainPageContent" style={{ width: "100%", float: "left", height: "100vh", overflow: this.state.overFlowClass }}>
                        <div className="page-container">
                            {
                                (sessionLogin === 'true' && this.state.url !== '/print-ticket' && this.state.url !== '/print-train-invoice' && this.state.url !== '/print-invoice' && this.state.url !== '/print-credit-note' && this.state.url !== '/print-hotel-invoice' && this.state.url !== '/hotel-voucher' && this.state.url !== '/print-hotel-credit-note' && this.state.url !== '/print-receipt-voucher' && this.state.url !== '/print-train-credit-note') &&
                                <HeaderWithSession {...this.props} url={this.state.url} />
                            }
                            {
                                (sessionLogin === 'false' || !sessionLogin) && this.state.url !== '/railway' &&
                                <HeaderWithoutSession {...this.props} url={this.state.url} />
                            }
                            <Switch>
                                <SimpleRoute isAllowed={this.state.url} exact path='/' component={Login} />
                                <SimpleRoute isAllowed={this.state.url} exact path='/login' component={Login} />
                                <SimpleRoute isAllowed={this.state.url} exact path='/verify-otp' render={props => <Login type="/verify-otp"  {...props}  />} />
                                <SimpleRoute isAllowed={this.state.url} exact path='/thank-you' component={ThankYou} />
                                <SimpleRoute isAllowed={this.state.url} exact path='/register' component={Register} />
                                <SimpleRoute isAllowed={this.state.url} exact path='/register/:distributorID' component={Register} />
                                <SimpleRoute isAllowed={this.state.url} exact path='/contact-us' render={props => <ContactUsContainer type="/contact-us" {...this.props} />} />
                                <SimpleRoute isAllowed={this.state.url} exact path='/about-us' render={props => <AboutUsContainer type="/about-us" {...this.props} />} />
                                <SimpleRoute isAllowed={this.state.url} exact path='/terms-and-conditions' render={props => <TermsAndConditionsContainer type="/terms-and-conditions" {...this.props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/cancellation-terms-and-conditions' render={props => <TermsAndConditionsContainer type="/cancellation-terms-and-conditions" {...this.props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/topup-terms-and-conditions' render={props => <TermsAndConditionsContainer type="/topup-terms-and-conditions" {...this.props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path="/flight-search" render={props => <FlightSearch {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path="/flight-search-details" render={props => <FlightSearchDetails {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path="/my-account" component={MyAccount} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/change-password' component={AgentPasswordChange} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/mark-up-tool' render={props => <AgentMarkUpTools type="/mark-up-tool" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/flight-type-list' render={props => <AgentMarkUpTools type="/flight-type-list" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/hotel-type-list' render={props => <AgentMarkUpTools type="/hotel-type-list" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/domestic-markup-tool' render={props => <AgentMarkUpTools type="/domestic-markup-tool" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/international-markup-tool' render={props => <AgentMarkUpTools type="/international-markup-tool" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/deposits' render={props => <AgentDeposit type="/deposits" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/view-deposit' render={props => <AgentDeposit type="/view-deposit" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/deposit-request' render={props => <AgentDeposit type="/deposit-request" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/bank-detail' component={AgentBankDetail} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/credits' render={props => <AgentCredits type="credits" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/view-credits' render={props => <AgentCredits type="view-credits" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/credit-request' render={props => <AgentCredits type="credit-request" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/account-statement' component={AccountStatement} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/book-ticket' component={FlightBookMainContainer} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/agent-info' component={AgentInfo} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/hold-confirm' render={props => <FlightBookTicketContainer type="hold-confirm" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/booking-confirm' render={props => <FlightBookTicketContainer type="booking-confirm" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/booking-calendar' render={props => <AgentCalendar type="calendar" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/booking' render={props => <AgentCalendar type="booking" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/hotel-booking' render={props => <AgentCalendar type="hotel-booking" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/print-ticket' render={props => <AgentCalendar type="print-ticket" {...props} changeOverFlowClass={this.changeOverFlowClass} />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/print-invoice' render={props => <AgentCalendar type="print-invoice" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/print-credit-note' render={props => <AgentCalendar type="print-credit-note" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/booking-error' render={props => <BookingError {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/my-bookings' render={props => <AgentMyBookings type="my-bookings" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/view-flight-booking' render={props => <AgentMyBookings type="view-flight-booking" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/request-bookings' render={props => <AgentRequestCancellationContainer type="request-bookings" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/request-flight-booking' render={props => <AgentRequestCancellationContainer type="request-flight-booking" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/create-report' component={CreateReportContainer} /> 
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/hotel-room-detail' render={props => <HotelRoomContainer type="hotel-room-detail" {...props} />} />
                               
                                {/* Topup routes */}
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/top-up' render={props => <AgentTopUp type="top-up" {...props} />}/>
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/view-topup' render={props => <AgentTopUp type="view-topup" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/topup-request' render={props => <AgentTopUp type="topup-request" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/topup-request/processing' render={props => <AgentTopUp type="topup-request-processing" {...props} />} />
                                {/* Vendor routes */}
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/vendor' render={props => <VendorContainer type="vendor" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/add-vendor-booking' render={props => <VendorContainer type="addVendor" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/view-booking-detail' render={props => <VendorContainer type="viewDetail" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/edit-vendor-booking' render={props => <VendorContainer type="editVendor" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/view-vendor-bookings' render={props => <VendorContainer type="viewVendor" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/pending-booking' render={props => <VendorFlightBookTicketContainer type="pendingBooking" {...props} />} />
                                {/************************************************ Hotel routes ******************************************/}
                                <ProtectedRoute isAllowed={sessionLogin} path='/print-receipt-voucher' render={props => <AgentCalendar type="print-receipt-voucher" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path="/hotel-search" component={HotelSearch} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path="/hotel-search-details" render={props => <HotelSearchDetailsContainer {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path="/hotel-book" render={props => <HotelBookContainer {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/hotel-booking-confirm' render={props => <HotelBookContainer {...props} type="booking-confirm" />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/print-hotel-invoice' render={props => <PrintHotelInvoiceContainer {...props} type="invoice" />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/view-hotel-booking' render={props => <AgentMyBookings type="view-hotel-booking" {...props} />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/hotel-voucher' render={props => <HotelVoucherContainer {...props} changeOverFlowClass={this.changeOverFlowClass} />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/print-hotel-credit-note' render={props => <AgentCalendar type="print-hotel-credit-note" {...props} />} />
                                {/************************************************ Railway routes ******************************************/}
                                <Route path='/jfrb/login' component={RailwayLoginContainer} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/jfrb/detail-verification' component={RailwayDetailVerification} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/railway/verify' component={RailwayVerifyContainer} />
                                <Route isAllowed={sessionLogin} path='/railway' component={RailwayLandingContainer} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/print-train-invoice' render={props => <PrintRailwayInvoiceContainer {...props} type="invoice" />} />
                                <ProtectedRoute isAllowed={sessionLogin} path='/print-train-credit-note' render={props => <PrintRailwayCreditNoteContainer {...props} type="invoice" />} />
                                <ProtectedRoute isAllowed={sessionLogin} exact path='/view-train-booking' render={props => <AgentMyBookings type="view-train-booking" {...props} />} />
                            </Switch>
                        </div>
                        {
                            (this.state.url !== '/print-train-invoice' && this.state.url !== '/print-ticket' && this.state.url !== '/print-invoice' && this.state.url !== '/print-credit-note' && this.state.url !== '/print-hotel-invoice' && this.state.url !== '/hotel-voucher' && this.state.url !== '/print-hotel-credit-note' && this.state.url !== '/print-receipt-voucher' && this.state.url !== '/print-train-credit-note') &&
                            <Footer  {...this.props} />
                        }
                        {(sessionLogin === 'true' && this.props.jwtResponse.response && this.props.jwtResponse.response.user_type === "Agent") ?
                            <React.Fragment><BackToTopContainer {...this.props} pathname={window.location.pathname}/></React.Fragment> : ""}
                            
                    </section>
                    <div id="pageBody">
                    </div>
                </React.Fragment>
            </Router>
        )
    }
}

const WarningModalComponent = (props) => {

    const {pagesResponse, showModal, setWarningModalClass}  = props;
    const {response}                                        = pagesResponse;
    
    const options = {
        nav:false,
        items :1,
        itemsDesktop : [1199,1],
        itemsDesktopSmall : [980,1],
        itemsTablet: [768,1],
        itemsTabletSmall: false,
        autoHeight: true,
        itemsMobile : [479,1],
        navigation: true,
        autoplay: true,
        rewind: true,
        lazyLoad: true,
        navigationText:false,
        navText : ["<i class='icon icon-arrow-left'></i>", "<i class='icon icon-arrow-right'></i>"],
    };
    
    const events = {
        onInitialized: function(event) {
            if(document.getElementsByClassName('owl-height')[0])
            {
                document.getElementsByClassName('owl-height')[0].style.height = 'auto';
            }
        },
    };

    if(showModal === 'show')
    {
        let carouselData    = [];
        const allPages      = Object.keys(response.pages);
        for(let page=0; page < allPages.length; page++)
        {
            if(response.pages[allPages[page]].notice_page_show === 1 && response.pages[allPages[page]].is_show === 1)
            {
                carouselData.push(response.pages[allPages[page]]);
            }
        }
        
        return (
            <div className={"popup-crad important-message-popup " + showModal}>   
                <div className="popup-outer">
                     <div className="popup-content">
                        <div className="popup-head">
                            <h2>Important Message</h2>
                            <div className="popup-close" onClick={() => setWarningModalClass('')}><span className="icon icon-cross" /></div>
                        </div>
                        <OwlCarousel options={options} className="owl-carousel-warning" events={events}>
                            {carouselData && carouselData.map((item,index) => 
                                {
                                    return (
                                        <div className="popup-scorll" key={index}>
                                            <div className="important-message-block block">
                                            <div className="block px-2 py-4">
                                                {
                                                    item.page_description && <div dangerouslySetInnerHTML={{__html: item.page_description}}></div>
                                                }
                                                <p className="my-2 text-center"><img className="owl-lazy" data-src={item.page_image} src={item.page_image}  alt="" /></p>
                                            </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
                <div className="popup-overlay"></div>
            </div>
        )
    }
    else
    {
        return true;
    }
}

const ProtectedRoute = ({ isAllowed, ...props }) => {
    if (localStorage.getItem('verify-otp') === 'true') {
        let token = localStorage.getItem('token');
        let decoded = jwt.decode(token);
        let tokenExpiry = decoded.exp;
        let currentTimestamp = Math.floor(Date.now() / 1000);
        if (currentTimestamp > tokenExpiry) {
            localStorage.removeItem('token');
            localStorage.removeItem('verify-otp');
            return window.location.href = "/verify-otp"
        }
        else {
            return <Route {...props} />
        }
    } else if (localStorage.getItem('login') === 'true') {
        let token = localStorage.getItem('token');
        let decoded = jwt.decode(token);
        let tokenExpiry = decoded.exp;
        let currentTimestamp = Math.floor(Date.now() / 1000);
        if (currentTimestamp > tokenExpiry) {
            localStorage.removeItem('token');
            localStorage.removeItem('login');
            return window.location.href = "/login"
        }
        else {
            return <Route {...props} />
        }
    } else {
        return window.location.href = "/verify-otp"
    }
}

const SimpleRoute = ({ isAllowed, ...props }) => {
    //this code is to redirect a user to his assgined product search page if he/she tries to load register etc. pages after login
    if (isAllowed !== '/' && isAllowed !== '/login' && isAllowed !== '/about-us' && isAllowed !== '/contact-us' && isAllowed !== '/terms-and-conditions'  && isAllowed !== '/verify-otp') {
        if (localStorage.getItem('verify-otp') === 'true') {
            return window.location.href = localStorage.getItem('assigned-product'); //this localStorage item is set after login success in loginContainer
        } else if (localStorage.getItem('login') === 'true') {
            return window.location.href = localStorage.getItem('assigned-product'); //this localStorage item is set after login success in loginContainer
        } else {
            return <Route {...props} />
        }
    } else {
        return <Route {...props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        settingResponse: state.shared.settingResponse,
        pagesResponse: state.shared.pagesResponse,
        productResponse: state.shared.productResponse,
        accountResponse: state.myAccount,//my account details
        jwtResponse: state.shared.jwtResponse,//my account details
        agentProductAccess: state.shared.agentProductAccess
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        settingAction: (credentials) => dispatch(settingAction(credentials)),
        pagesAction: (credentials) => dispatch(pagesAction(credentials)),
        productsAction: (credentials) => dispatch(productsAction(credentials)),
        logoutAction: () => dispatch(logoutAction()),
        myAccountAction: (credentials) => dispatch(myAccountAction(credentials)),
        getUserInfoJwt: (credentials) => dispatch(getUserInfoJwt(credentials)),
        postMessageRequest: (credentials, successCb, errorCb) => dispatch(postMessageRequest(credentials, successCb, errorCb)),
        getProductAccess: (data, successCb) => dispatch(getProductAccess('', successCb))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer)
