import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import 'react-dates/initialize';
import { Formik } from "formik";
import * as Yup from "yup";

class NewTopUp extends Component {
    constructor() {
        super();
        this.state = {
            hideMsg  : false,
            formFlag : true,
        }
    }

    ChangeValue = (value,setFieldValue,amountValue) => {
        var FieldVal = amountValue !== '' ? parseInt(amountValue) + parseInt(value) : value;
        setFieldValue('amount',FieldVal);
    }  

    redirectToTermsAndCondition() {
        window.open("/topup-terms-and-conditions", "_blank");
    }

      renderForm = (formikProps) =>{
        const { errors, handleBlur, handleChange,handleSubmit, values, handleReset} = formikProps;
        const { showConfirmationPopup} = this.props;
        return(
            <React.Fragment>
            <form autoComplete="off" id="credit_request" tabIndex="0">
                <div className="form-group">
                    <div className="input-group">
                        <input className="form-control custom-filed" type="text" name="amount" value={values.amount} placeholder="Enter Amount" onBlur={handleBlur} onChange={handleChange} tabIndex="1"/>
                        {errors.amount &&<span className="error-msg">{errors.amount}</span>}
                    </div>
                </div>
                {/* <div className="col-block col-add-block">
                    <div className="col-plus-minus">
                        <div className="form-inner">
                            <div className="group-input-topup" onClick={() => this.ChangeValue(500,setFieldValue,values.amount)}>
                                <span className="bold-active"><i className="fa fa-plus" aria-hidden="true"></i> </span>
                                <small>500</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-plus-minus">
                        <div className="form-inner">
                            <div className="group-input-topup" onClick={() => this.ChangeValue(1000,setFieldValue,values.amount)}>
                                <span className="bold-active" ><i className="fa fa-plus" aria-hidden="true"></i> </span>
                                <small>1000</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-plus-minus">
                        <div className="form-inner">
                            <div className="group-input-topup" onClick={() => this.ChangeValue(5000,setFieldValue,values.amount)}>
                                <span className="bold-active"><i className="fa fa-plus" aria-hidden="true"></i> </span>
                                <small>5000</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-plus-minus">
                        <div className="form-inner">
                            <div className="group-input-topup" onClick={() => this.ChangeValue(10000,setFieldValue,values.amount)}>
                                <span className="bold-active"><i className="fa fa-plus" aria-hidden="true"></i> </span>
                                <small>10,000</small>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="form-group">
                    <div className="input-group">
                        <textarea className="form-control custom-filed" name="remark" value={values.remark} placeholder="Enter Remark" onBlur={handleBlur} onChange={handleChange} tabIndex="2"></textarea>
                        {errors.remark &&<span className="error-msg">{errors.remark}</span>}
                    </div>
                </div>
                <div className="form-group">
                    <div className="input-group">
                        <div className="show-check">
                            <small className="checkbox">
                                <input type="checkbox" name="termAndCondition" onBlur={handleBlur} onChange={handleChange}  />
                                <label></label>
                            </small>
                            { /* eslint-disable-next-line */}
                            <span>I have read and agree to the <a href="javascript:void(0);" onClick={this.redirectToTermsAndCondition}>Terms &amp; Conditions</a></span>
                        </div>
                        {errors.termAndCondition &&<span className="error-msg">{errors.termAndCondition}</span>}
                    </div>
                </div>
                <div className="btn-block text-center">
                    <div className="col1"><button type="button" className="btn btn-sky-blue" onClick={handleSubmit} tabIndex="3">{/*accountResponse.addCreditRequestResponse.loading === false?"Submit Request":"Submitting.."*/}Continue </button></div>
                    <div className="col1"><button type="reset" onClick={handleReset} className="btn btn-dark" tabIndex="4">Reset</button></div>
                </div>
            </form>
            {/* Popup for credit confirmation */}
            <div className={`popup-crad confirmation-popup ${showConfirmationPopup ? 'show' : ''}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>Top Up Confirmation</h2>
                            <div className="popup-close"><span className="icon icon-cross" onClick={()=>this.props.handleConfirmationPopup(false)}/></div>
                        </div>							
                        <div className="popup-body popup-scorll">
                            {
                                !this.props.showLoaderOnPopup &&
                                <div className="inner-confirmation">
                                    <p>Are you sure you want to continue?</p>
                                    <div className="popup-footer">
                                        <button className="btn btn-orange" onClick={()=>this.props.submitPaymentInit(values)}>Yes</button>
                                        <button className="btn btn-grey" onClick={()=>this.props.handleConfirmationPopup(false)}>No</button>
                                    </div>
                                </div>
                            }
                            {
                                this.props.showLoaderOnPopup &&
                                <div className="loader ssr-loader relative-loader" style={{display:'block'}}>
                                    <div className="loader-content">
                                            <div className="loader-ring blue-ring"> 
                                                <span> </span>
                                            </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="popup-overlay"/>
            </div>
            </React.Fragment>
            )
    }
    render() {
       var successMsg;
        const showReplyForm = () => {
            this.setState({hideMsg: true});
        };
        const initialFormValue = {
            amount:"",
            remark:"",
            termAndCondition : false
        };
        const validationSchema =  Yup.object().shape({
            amount: Yup.number()
                .required("Amount is required")
                .typeError("Please enter valid amount"),
            termAndCondition: Yup.bool()
                .required("Must agree Terms and Conditions")
                .oneOf([true], "Must agree Terms and Conditions"),
            remark: Yup.string()
                .required("Remark is required")   
        });
        return (
            <div className="profile-detail-info">
                <div className="edit-profile-bar">
                    <div className="pull-right">
                    { /* eslint-disable-next-line */}
                        <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn">
                        <i className="icon icon-menu"></i>Profile Menus</a>
                    </div>
                </div>
                <div className="tabs-container-detail">
                    <div className="statement-info">
                        <div className="tabs-top">
                            <div className="tabs-title">
                                <div className="pull-right"> <Link to ="view-topup" className="sm-btn blue-sm-btn">View All Top Ups</Link> </div>
                                <h2><i className="icon icon-currency2"></i><span>New Top UP Request</span></h2>
                            </div> 
                        </div>
                        <div className="change-pass-info top-up-tab">
                            <div className="change-pass-form">
                                {successMsg && <div className="alert alert-success" style={{ display: (this.state.hideMsg === true)?'none':'' }}> <strong> <i className="fa fa-check-circle"></i> {successMsg} </strong> <i className="fa fa-times" aria-hidden="true" onClick={showReplyForm}></i> </div>}
                                {this.props.isError && <div className="alert alert-danger" style={{ display: (this.state.hideMsg === true)?'none':'' }}> <strong><i className="fa fa-exclamation-triangle"></i>There is some error in deposit</strong> <i className="fa fa-times" aria-hidden="true" onClick={showReplyForm}></i></div>}
                                <Formik
                                    initialValues = {initialFormValue}
                                    onSubmit={() => this.props.handleConfirmationPopup(true,null)}
                                    validationSchema = {validationSchema}
                                    render={this.renderForm}
                                /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (NewTopUp)