import React, { Component } from 'react';
import { dateFormat, getCurrentDate, currencyFormat } from '../../../../shared/commonHelper';
import moment from 'moment';
import VendorFlightFareSummaryComponent from './VendorFlightFareSummaryComponent';
import * as flightHelper from '../../../../shared/flightHelper';

class VendorBookingConfirmComponent extends Component {
    constructor(){
        super();
        this.state = {
            showConfirmationPopup : 'show'
        }
    }
    
    componentDidMount() {
        if (this.props.location.state.bookingType === 'confirm') {
            this.props.myAccountAction();
        }
    }

    hidePopupModal = () =>{
        this.setState({ showConfirmationPopup : 'hide' })
    }


    outboundBooking = () => {
        this.props.initialState();
        this.props.location.state.searchType = 'round-trip-complete';
        let formikValues = this.props.outboundPaxInfo.details;
        this.props.saveFlightOutboundPaxInfo(formikValues, this.props.outboundPaxInfo.booking_id, this.props.outboundPaxInfo.outboundPub);
        this.props.location.state.index = 1;
        this.props.location.state.data = this.props.location.state.searchData;
        this.props.history.push({
            pathname: "/book-ticket",
            state: {
                searchType: 'round-trip-complete',
                trace_id: this.props.location.state.trace_id,
                data: this.props.location.state.searchData,
                index: 1,
                isOutBoundVendor: true,
                param : this.props.location.state.param,
                param_rt : this.props.location.state.param_rt
            }
        });
    }

    render() {
        let data = this.props.location.state.data;
        let leadPax = null;
        if (data) {
            for (let i = 0; i < data.tbo_response.flight_itinerary.passenger.length; i++) {
                if (data.tbo_response.flight_itinerary.passenger[i].is_lead_pax === true) {
                    leadPax = data.tbo_response.flight_itinerary.passenger[i];
                }
            }
        }
        let currentDate = moment(moment(getCurrentDate())).format('YYYY-MM-DD') + 'T00:00:00';
        let fareQuote = data.tbo_response.flight_itinerary;
        return (
            <React.Fragment>
                <StatusModalPopup  showModal={this.state.showConfirmationPopup}  hidePopupModal ={this.hidePopupModal}/>
                <section className="mid-wrapper confirm-booking">
                    <div className="container">
                        <div className="review-booking-wrap">
                            {
                                (this.props.location.state.searchType === 'round-trip') &&
                                <div className="review-step">
                                    <div className="review-step-title">
                                        <div className="cols pull-left"> <span>Booking In Progress</span> </div>
                                        <div className="cols pull-right"> </div>
                                    </div>
                                    <div className="review-step-deatil review-booking">
                                        <div className="step-deatil-outer">
                                            <div className="booking-details">
                                                <div className={`cols disabled-section`}>
                                                    <div className="booking-head"> <span>Outbound Booking In Progress</span> </div>
                                                    <div className="booking-list">
                                                        <ul>
                                                            <li><span>{this.props.searchQuery.segments[0].origin} - {this.props.searchQuery.segments[0].destination}</span>,
											<span>{this.props.location.state.searchData[0].airline_code} -
											{this.props.location.state.searchData[0].segments[0][0].airline.flight_number + ' '}
                                                                    {this.props.location.state.searchData[0].segments[0][0].airline.fare_class}</span></li>
                                                            <li><span>{dateFormat(this.props.location.state.searchData[0].segments[0][0].stop_point_departure_time, 'DD MMM YYYY')}</span>,
											<span>{flightHelper.getDepartureTime(this.props.location.state.searchData[0].segments[0][0].stop_point_departure_time)} -
											{flightHelper.getArivalTime(this.props.location.state.searchData[0].segments[0])}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className={`cols`}>
                                                    <div className="booking-head"> <span>Inbound Booking</span> </div>
                                                    <div className="booking-list">
                                                        <ul>
                                                            <li>
                                                                <samp className="outbound-booking">
                                                                    { /* eslint-disable-next-line */}
                                                                    <a href="javascript:void(0);" onClick={() => this.outboundBooking()}>Click here for inbound booking</a>
                                                                </samp>
                                                                <span>{this.props.searchQuery.segments[1].origin} - {this.props.searchQuery.segments[1].destination}</span></li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="total-adults-price">
                                                    <div className="pull-left">Total Publish </div>
                                                    <div className="pull-right">
                                                        <i className="icon icon-rupee" />
                                                        <span>{currencyFormat(Number(this.props.location.state.searchData[0].fare.published_fare) + Number(this.props.location.state.searchData[1].fare.published_fare))}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="left-side">
                                <VendorFlightFareSummaryComponent fareQuote={fareQuote} booking={true} />
                            </div>
                            <div className="right-side">
                                <div className="review-steps-wrap">
                                    <div className="tabs-content-wrap">
                                        {
                                            <div className="step-content">
                                                <h3 className="step-title"><span>Booking Confirmation</span> </h3>
                                                <div className="review-filght-step">
                                                    <div
                                                        className="review-step hold-booking-confirm">
                                                        <div className='review-step-deatil'>
                                                            <div className="step-deatil-outer">
                                                                <div className="flight-details-block">
                                                                    <div className="flight-col">
                                                                        <div className="flight-head">
                                                                            <div className="cols">
                                                                                <span>JaipurFlight.com </span></div>
                                                                            <div className="cols">
                                                                                {
                                                                                    data && data.tbo_response.flight_itinerary.pnr &&
                                                                                    <span className="pnr-no">PNR : {data && data.tbo_response.flight_itinerary.pnr}</span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="flight-list">
                                                                            <div className="cols">
                                                                                <ul>
                                                                                    <li>{leadPax && leadPax.address_line_1}
                                                                                        {leadPax && leadPax.address_line_2 !== '' ? ', ' + (leadPax.address_line_2 ? leadPax.address_line_2 : '') : ''}</li>
                                                                                    <li>{leadPax && leadPax.city}</li>
                                                                                    <li>Phone: {leadPax && leadPax.contact_no}</li>
                                                                                </ul>
                                                                            </div>
                                                                            <div className="cols">
                                                                                <ul>
                                                                                    <li><span className="text-bold">Pending</span>
                                                                                    </li>
                                                                                    <li>Booked on: <span className="text-bold">{data && dateFormat(currentDate, 'DD MMM YYYY')}</span></li>
                                                                                    <li>Travel Date: <span className="text-bold">{data && dateFormat(data.tbo_response.flight_itinerary.segments[0].stop_point_departure_time, 'DD MMM YYYY')}</span> </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-step">
                                                        <div className="review-step-title">
                                                            <div className="cols pull-left"> <span>FLIGHT INFORMATION</span> </div>
                                                            <div className="cols pull-right"></div>
                                                        </div>
                                                        <div className="review-step-deatil">
                                                            <div className="step-deatil-outer">
                                                                <div className="flight-information">
                                                                    <div className="inner-fl-info">
                                                                        <table className="fl-book-info">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Flight No</th>
                                                                                    <th>Origin</th>
                                                                                    <th>Destination</th>
                                                                                    <th>Dep Date Time</th>
                                                                                    <th>Arr Date Time</th>
                                                                                    <th>Class</th>
                                                                                    <th>Status</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    data && data.tbo_response.flight_itinerary.segments.map((item, index) => {
                                                                                        let originTerminal = '';
                                                                                        let destinationTerminal = '';
                                                                                        if (item.origin_airport_terminal !== '' && item.origin_airport_terminal) {
                                                                                            originTerminal = '-T-' + item.origin_airport_terminal;
                                                                                        }
                                                                                        if (item.destination_airport_terminal !== '' && item.destination_airport_terminal) {
                                                                                            destinationTerminal = '-T-' + item.destination_airport_terminal;
                                                                                        }
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{item.airline_code + '-' + item.airline.flight_number}</td>
                                                                                                <td>{item.origin_code} ({item.origin_airport_name + '' + originTerminal})</td>
                                                                                                <td>{item.destination_code} ({item.destination_airport_name + '' + destinationTerminal})</td>
                                                                                                <td>{dateFormat(item.origin.dep_time, 'DD MM YYYY HH:MM')}</td>
                                                                                                <td>{dateFormat(item.destination.arr_time, 'DD MM YYYY HH:MM')}</td>
                                                                                                <td>{item.fare_class ? item.fare_class : '-'}</td>
                                                                                                <td>Pending</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="review-step">
                                                        <div className="review-step-title">
                                                            <div className="cols pull-left"> <span>Passenger Details</span> </div>
                                                            <div className="cols pull-right">  </div>
                                                        </div>
                                                        <div className="review-step-deatil">
                                                            <div className="step-deatil-outer">
                                                                <div className="passenger-information">
                                                                    <div className="passenger-fl-info">
                                                                        {
                                                                            data && data.tbo_response.flight_itinerary.passenger.map((item, index) => {
                                                                                let paxType = '';
                                                                                if (item.pax_type === 1)
                                                                                    paxType = 'Adult';
                                                                                else if (item.pax_type === 2)
                                                                                    paxType = 'Child';
                                                                                else
                                                                                    paxType = 'Infant';
                                                                                return (
                                                                                    <div className="cols" key={index}>
                                                                                        <div className="adult-title"><span>Passenger {index + 1}</span> <small>({paxType})</small></div>
                                                                                        <ul className="psg-list">
                                                                                            <li>
                                                                                                <span>Name:</span>
                                                                                                <small>{item.title + ' ' + item.first_name + ' ' + item.last_name}</small>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Gender:</span>
                                                                                                <small>{item.gender === 2 ? 'Female' : 'Male'}</small>
                                                                                            </li>
                                                                                            <li style={{ display: (item.is_lead_pax === true) ? 'block' : 'none' }}>
                                                                                                <span>Address:</span>
                                                                                                <small>{item.address_line_1}</small>
                                                                                            </li>
                                                                                            <li style={{ display: (item.is_lead_pax === true) ? 'block' : 'none' }}>
                                                                                                <span>Mobile No:</span>
                                                                                                <small>{item.contact_no}</small>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>DOB:</span>
                                                                                                <small>{dateFormat(item.date_of_birth, 'DD MMM YYYY')}</small>
                                                                                            </li>
                                                                                            <li style={{ display: (item.is_lead_pax === true) ? 'block' : 'none' }}>
                                                                                                <span>Email:</span>
                                                                                                <small>{item.email}</small>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        data && data.tbo_response.flight_itinerary.farerules &&
                                                        <div className="review-step">
                                                            <div className="review-step-title">
                                                                <div className="cols pull-left"> <span>Fare Rules</span> </div>
                                                                <div className="cols pull-right"> </div>
                                                            </div>
                                                            <div className="review-step-deatil">
                                                                <div className="step-deatil-outer">
                                                                    <div className="fare-rule-info">
                                                                        <div className="inner-fare-block">
                                                                            {
                                                                                data.tbo_response.flight_itinerary.farerules.map((item, index) => {
                                                                                    let holdFareType = '';
                                                                                    if (this.props.location.state.bookingType === 'hold')
                                                                                        holdFareType = <React.Fragment>{item.airline}:{item.origin}-{item.destination}<br /></React.Fragment>;
                                                                                    else
                                                                                        holdFareType = '';

                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            <p>{holdFareType}</p>
                                                                                            <div dangerouslySetInnerHTML={{ __html: item.farerule_detail }} />
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="btn-block text-right"></div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }

}

const StatusModalPopup = (props) => {
    const { showModal, hidePopupModal } = props;
        return (
            <div className={`popup-crad confirm-popup ${showModal}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>Confirm to Process</h2>
                        </div>
                        <div className="popup-scorll">
                            <div className="confirm-popup-block block">
                                <div className="block">
                                    <div className="session-exp">
                                        <h5>Your booking is under process. Kindly do not book it again, For assistance call our helpdesk.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block">
                            <div className="confirm-box-button">
                                <button className="btn btn-blue" onClick={() =>hidePopupModal()}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay"></div>
            </div>
        )
    
}

export default VendorBookingConfirmComponent;