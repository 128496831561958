import React, { Component } from "react";

class WalletBoxMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cashBalance: 0,
            creditBalance: 0,
            load:false
          };
        this.handleRecharge = this.handleRecharge.bind(this);
        
    }

    handleRecharge() {
        window.open('/my-account', '_blank');
    }

    cashBalance = () => {
        return Number(
            (this.props.accountResponse && this.props.accountResponse.myAccountResponse && this.props.accountResponse.myAccountResponse.loading) ? 0 : this.props.accountResponse.myAccountResponse &&
             this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.cash_balance ? this.props.accountResponse.myAccountResponse.response.cash_balance : 0
        );
    };
    creditBalance = () => {
        return Number(
            (this.props.accountResponse && this.props.accountResponse.myAccountResponse && this.props.accountResponse.myAccountResponse.loading) ? 0 : this.props.accountResponse.myAccountResponse &&
             this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.credit_limit ? this.props.accountResponse.myAccountResponse.response.credit_limit : 0
        );
    };
    fetchBalance = async () => {
        if(!this.state.load){
            this.setState({load:true})
            await this.props.myAccountAction();
            let apiBalance = this.props.accountResponse.myAccountResponse &&
                this.props.accountResponse.myAccountResponse.response &&
                this.props.accountResponse.myAccountResponse.response.cash_balance;
            let creditBalance = this.props.accountResponse.myAccountResponse &&
                this.props.accountResponse.myAccountResponse.response &&
                this.props.accountResponse.myAccountResponse.response.credit_limit;
            if (this.props.accountResponse.myAccountResponse.loading === false) {
                setTimeout(() => {
                    this.setState({ load: false });
                }, 1000);
            }
        if (creditBalance !== this.state.creditBalance) {
            this.setState({ creditBalance: creditBalance });
        }
        if (apiBalance !== this.state.cashBalance) {
            this.setState({ cashBalance: apiBalance });
            return apiBalance;
        } else {
            return this.state.cashBalance;
        }
        }
    }
    componentDidUpdate= (props,state)=>{
        let apiBalance =  props.accountResponse.myAccountResponse &&
            props.accountResponse.myAccountResponse.response &&
            props.accountResponse.myAccountResponse.response.cash_balance;
            if (apiBalance !== state.cashBalance) {
                this.setState({ cashBalance: apiBalance });
                return apiBalance;
            } else {
                return state.cashBalance;
            }
    }
    

    render() {
        let is_credit_allowed = this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.is_credit_allowed &&
         this.props.accountResponse.myAccountResponse.response.is_credit_allowed.toLowerCase() === 'y';
        return (
            <div className="payment-block mobile-item">
                <div className="payment-inner">
                <div className={ is_credit_allowed ?  "payment-card-outer" : "payment-card-outer payment-card-outer_credit"} >
                        <div className="payment-card">
                            <div className="payment-cell cash-payment">
                                <div className="payment-cell-inner">
                                    <span className='payment-text'>Cash Balance</span>
                                    <span className={(this.state.cashBalance < 0 || this.cashBalance() < 0) ? 'danger_color payment-amount':'payment-amount'}>₹<strong className={(this.state.cashBalance < 0 || this.cashBalance() < 0) ? 'danger_color':''}>{this.state.cashBalance ?  this.state.cashBalance : this.cashBalance()}</strong></span>
                                </div>
                            </div>
                            {
                                is_credit_allowed &&
                            <div className="payment-cell credit-payment">
                                <div className="payment-cell-inner">
                                    <span className='payment-text'>Credit Limit</span>
                                    <span className='payment-amount'>₹<strong>{this.state.creditBalance ? this.state.creditBalance : this.creditBalance()}</strong></span>
                                </div>
                            </div>
                            }
                            <span className={this.state.load ? "refresh-icons  spin-loading": "refresh-icons"} onClick={() => this.fetchBalance()}><i className="fa fa-refresh" aria-hidden="true"></i></span>
                        </div>
                    </div>
                    <div className="recharge-btns" onClick={this.handleRecharge}><div className="recharge-btns-text"> <span>Recharge</span></div></div>
                </div>
            </div>
        );
    }
}

export default WalletBoxMobile;
