import React from 'react';
import { Formik } from 'formik';
import {dateFormat} from '../../shared/commonHelper';
import * as Yup from "yup";


class ChatMessageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toId:null,
            loading: true,
            showPopup:true,
            messageCount:null,
            newMesage:null
        }
    }
    intervalID = 0;
    
    messageChatResponse = () => {
        var messageData = JSON.parse(localStorage.getItem('messageCount')); 
        this.setState({messageCount:messageData});
      
        if(this.props.jwtResponse && this.props.jwtResponse.response && this.props.jwtResponse.response.user_unique_code)
        {
            const postData = {
                "from_id":this.props.jwtResponse.response.user_unique_code,
                "to_id":this.props.jwtResponse.response.parent_unique_code
            }
            const successCb = () => {
                var allMessage = this.props.chatResponse.response && this.props.chatResponse.response.length;
                localStorage.setItem('messageCount',JSON.stringify(allMessage));
                if(allMessage < messageData){
                    this.setState({newMesage : allMessage})
                }
                else{
                this.setState({newMesage : allMessage-  this.state.messageCount })
                }
            }   
            const errorCb = () => {
            }
                /*this.props.getAgentChatResponse(postData,successCb,errorCb)
                this.intervalID = setInterval(() =>
                { 
                    this.props.getAgentChatResponse(postData,successCb,errorCb)
                },5000);*/
        }
    }
    componentDidMount() {
        this.messageChatResponse();  
    }

    messageSentRequest=(valus)=>{
        if(this.props.jwtResponse && this.props.jwtResponse.response && this.props.jwtResponse.response.user_unique_code)
        {
            const postMessageData = {
                "from_id":this.props.jwtResponse.response.user_unique_code,
                "to_id":this.props.jwtResponse.response.parent_unique_code,
                "message":valus.message,

            }
            const successCb = () => {
                this.messageChatResponse();
                document.getElementById("myForm").reset();
                this.scrollBottom();
                valus.message = "";
            }   
            /* on error, update the error in the state */
            const errorCb = () => {
                
            }
            this.props.postMessageRequest(postMessageData,successCb,errorCb);
        }
    }
    scrollBottom(){
        var objDiv = document.getElementById("chat-section-start");
        setTimeout(function(){  objDiv.scrollTop = objDiv.scrollHeight; }, 200);
    }
    handlePopup = (type) => {
        this.scrollBottom();
        type = type?false:true;
        if(type === true){
            clearInterval(this.intervalID);
            this.messageChatResponse();
        }else{
            this.messageChatResponse();
        }
        this.setState({showPopup: type});
    }
    
    renderForm = (formikProps) =>{
        let toId = this.props.jwtResponse.response && this.props.jwtResponse.response.parent_unique_code?this.props.jwtResponse.response.parent_unique_code:"";
        const {handleChange,handleSubmit, errors} = formikProps;
        return(
             <div className="message-box">
                <div className="message-icon"> <i className="fa fa-comments" aria-hidden="true" onClick={() => this.handlePopup(this.state.showPopup)}></i>
                                             
                    <div className="msg-count" style={{ display: (this.state.newMesage === 0)?'none':'block' }}><span>{this.state.newMesage}</span></div>
                </div>
               
                <div className="message-chat-box" style={{display: (this.state.showPopup)?'none':'block' }}>
                {this.props.chatResponse.loading === true?
                    <div className="loader relative-loader" style={{"display":"block"}}><div className="loader-content"><div className="loader-ring blue-ring"> <span></span> </div></div></div>
                : <div className="chat-mid-block" id="chat-section-start">
                        { this.props.chatResponse.response && this.props.chatResponse.response.map((value,key)=>{
                            
                            return(
                                <div key={key} className= {(toId === value.to_id)?"chat-block chat-me":"chat-block"}>
                                    <div className="col-chat">
                                        <figure>
                                        {(toId !== value.to_id)?
                                            <img src={this.props.jwtResponse.response && this.props.jwtResponse.response.parent_company_logo?this.props.jwtResponse.response.parent_company_logo:""} alt="user" />
                                        :   <img src={this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.company_logo?this.props.accountResponse.myAccountResponse.response.company_logo:""} alt="user" />
                                        }
                                        </figure>
                                        <div className="chat-box-detail">
                                            <div className="chat-info"><p>{value.message}</p></div>
                                            <div className="chat-name-time"> <span className="ch-time">{dateFormat(value.date_added,"MMM DD, HH:MM AMPM")}</span></div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })}  
                    </div>}
                   
                    <form id="myForm">
                        <div className="chat-input">
                            <button className="msg-btn" onClick={handleSubmit}> <i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                            <div className="input-msg">
                                <input className={errors.message ?"msg-control error-shadow":"msg-control"} id="message-input" type="text" placeholder="Message" name="message" onChange={handleChange}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }


    render() {
        

        let validationSchemaBasicDetails = '';
        validationSchemaBasicDetails = Yup.object().shape({
            message:
                Yup.string()
                    .required('Message is required.')
                });
         return (
            <Formik
                validationSchema={validationSchemaBasicDetails}
                onSubmit={(values) => {this.messageSentRequest(values)}}
                render={this.renderForm}
             />
         )
    }
}
export default ChatMessageComponent;