/* external dependencies */
import React from 'react'
import { connect } from 'react-redux'
import { getAgentChatResponse } from '../../actions/shared/sharedAction';
import ChatMessageComponent from '../../components/shared/ChatMessageComponent';

class ChatMessageContainer extends React.Component {
    render() {
        return (
            <ChatMessageComponent {...this.props}/>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        chatResponse    : state.shared.chatResponse,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAgentChatResponse:   (credentials,successCb,errorCb) => dispatch(getAgentChatResponse(credentials,successCb,errorCb)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ChatMessageContainer)