
import {
    MY_ACCOUNT_START,
    MY_ACCOUNT_OK,
    MY_ACCOUNT_ERR,
    AGENT_CHANGE_PASSWORD_START,
    AGENT_CHANGE_PASSWORD_OK,
    AGENT_CHANGE_PASSWORD_ERR,
	BANK_DETAIL_START,
    BANK_DETAIL_OK,
    BANK_DETAIL_ERR,
    DEPOSIT_REQUEST_START,
    DEPOSIT_REQUEST_OK,
    DEPOSIT_REQUEST_ERR,
    VIEW_CREDIT_REQUEST_START,
    VIEW_CREDIT_REQUEST_OK,
    VIEW_CREDIT_REQUEST_ERR,
    DEPOSIT_TYPE_START,
    DEPOSIT_TYPE_OK,
    DEPOSIT_TYPE_ERR,
    SUBMIT_DEPOSIT_START,
    SUBMIT_DEPOSIT_OK,
    SUBMIT_DEPOSIT_ERR,
	ADD_CREDIT_REQUEST_START,
    ADD_CREDIT_REQUEST_OK,
    ADD_CREDIT_REQUEST_ERROR,
    UPDATE_AGENT_INFO_START,
    UPDATE_AGENT_INFO_OK,
    UPDATE_AGENT_INFO_ERR,
    GET_MARKUP_TOOL_START,
    GET_MARKUP_TOOL_OK,
    GET_MARKUP_TOOL_ERROR,
    DOMESTIC_MARKUP_TOOL_START,
    DOMESTIC_MARKUP_TOOL_SUCCESS,
    DOMESTIC_MARKUP_TOOL_ERROR,
    INTERNATIONAL_MARKUP_TOOL_START,
    INTERNATIONAL_MARKUP_TOOL_SUCCESS,
    INTERNATIONAL_MARKUP_TOOL_ERROR,
    CALENDAR_DETAILS_START,
    CALENDAR_DETAILS_OK,
    CALENDAR_DETAILS_ERROR,
    BOOKING_DETAILS_START,
    BOOKING_DETAILS_OK,
    BOOKING_DETAILS_ERROR,
    USER_TYPE_START,
    USER_TYPE_OK,
    USER_TYPE_ERROR,
    TRANSACTION_TYPE_START,
    TRANSACTION_TYPE_OK,
    TRANSACTION_TYPE_ERROR,
    BOOKING_STATUS_START,
    BOOKING_STATUS_OK,
    BOOKING_STATUS_ERROR,
    ACCOUNT_STATEMENT_START,
    ACCOUNT_STATEMENT_OK,
    ACCOUNT_STATEMENT_ERROR,
    MY_BOOKINGS_START,
    MY_BOOKINGS_OK,
    MY_BOOKINGS_ERROR,
    CANCEL_BOOKING_START,
    CANCEL_BOOKING_OK,
    CANCEL_BOOKING_ERROR,
    RELEASE_PNR_START,
    RELEASE_PNR_OK,
    RELEASE_PNR_ERROR,
    UPDATE_TICKET_START,
    UPDATE_TICKET_OK,
    UPDATE_TICKET_ERR,
    EMAIL_TICKET_START,
    EMAIL_TICKET_OK,
    EMAIL_TICKET_ERR,
    CANCEL_REFUND_START,
    CANCEL_REFUND_OK,
    CANCEL_REFUND_ERR,
    BOOKING_REPORT_START,
    BOOKING_REPORT_OK,
    BOOKING_REPORT_ERR,
    EMAIL_INVOICE_START,
    EMAIL_INVOICE_OK,
    EMAIL_INVOICE_ERR,
    BOOKING_INFO_START,
    BOOKING_INFO_OK,
    BOOKING_INFO_ERR,
    EMAIL_OFFLINE_TICKET_START,
    EMAIL_OFFLINE_TICKET_OK,
    EMAIL_OFFLINE_TICKET_ERR,
    EMAIL_OFFLINE_INVOICE_START,
    EMAIL_OFFLINE_INVOICE_OK,
    EMAIL_OFFLINE_INVOICE_ERR,
    GET_HOTEL_MARKUP_TOOL_START,
    GET_HOTEL_MARKUP_TOOL_OK,
    GET_HOTEL_MARKUP_TOOL_ERR,
    UPDATE_HOTEL_MARKUP_START,
    UPDATE_HOTEL_MARKUP_SUCCESS,
    UPDATE_HOTEL_MARKUP_ERR,
    HOTEL_INVOICE_START,
    HOTEL_INVOICE_OK,
    HOTEL_INVOICE_ERR,
	MY_HOTEL_BOOKINGS_START,
    MY_HOTEL_BOOKINGS_OK,
    MY_HOTEL_BOOKINGS_ERROR,
    CANCEL_HOTEL_BOOKING_START,
    CANCEL_HOTEL_BOOKING_OK,
    CANCEL_HOTEL_BOOKING_ERROR,
    EMAIL_HOTEL_INVOICE_START,
    EMAIL_HOTEL_INVOICE_OK,
    EMAIL_HOTEL_INVOICE_ERR,
    CANCEL_HOTEL_REFUND_START,
    CANCEL_HOTEL_REFUND_OK,
    CANCEL_HOTEL_REFUND_ERR,
    EMAIL_FLIGHT_CREDIT_NOTE_START,
    EMAIL_FLIGHT_CREDIT_NOTE_OK,
    EMAIL_FLIGHT_CREDIT_NOTE_ERR,
    EMAIL_HOTEL_CREDIT_NOTE_START,
    EMAIL_HOTEL_CREDIT_NOTE_OK,
    EMAIL_HOTEL_CREDIT_NOTE_ERR,
    PARTIAL_CANCELLATIONS_START,
    PARTIAL_CANCELLATIONS_OK,
    PARTIAL_CANCELLATIONS_ERR,
    TOP_UP_PAYMENT_INIT_START,
    TOP_UP_PAYMENT_INIT_OK,
    TOP_UP_PAYMENT_INIT_ERR,
    TOP_UP_PAYMENT_RESPONSE_START,
    TOP_UP_PAYMENT_RESPONSE_OK,
    TOP_UP_PAYMENT_RESPONSE_ERR,
    ALL_TOP_UP_FETCH_START,
    ALL_TOP_UP_FETCH_OK,
    ALL_TOP_UP_FETCH_ERR,
    CANCEL_TRAIN_BOOKING_START,
    CANCEL_TRAIN_BOOKING_OK,
    CANCEL_TRAIN_BOOKING_ERROR,
    RELEASE_TRIPJACK_START,
    RELEASE_TRIPJACK_OK,
    RELEASE_TRIPJACK_ERROR,
    CANCEL_TRIPJACK_BOOKING_START,
    CANCEL_TRIPJACK_BOOKING_OK,
    CANCEL_TRIPJACK_BOOKING_ERROR,
    CANCEL_TRIPJACK_HOTEL_BOOKING_START,
    CANCEL_TRIPJACK_HOTEL_BOOKING_OK,
    CANCEL_TRIPJACK_HOTEL_BOOKING_ERROR,
    CANCEL_KAFILA_BOOKING_START,
    CANCEL_KAFILA_BOOKING_OK,
    CANCEL_KAFILA_BOOKING_ERROR,
    RELEASE_TRIPJACK_HOTEL_START,
    RELEASE_HOTEL_TRIPJACK_OK,
    RELEASE_HOTEL_TRIPJACK_ERROR,
    REQUEST_CANCELLATION_START,
    REQUEST_CANCELLATION_OK,
    REQUEST_CANCELLATION_ERROR,
    CANCEL_RIYA_BOOKING_START,
    CANCEL_RIYA_BOOKING_OK,
    CANCEL_RIYA_BOOKING_ERROR
} from '../actions/agentAction';

export const initialSessionState = {
    myAccountResponse: {
        error: null,
        loading: false,
        response: null
    },
    myAgentChangePasswordResponse: {
        error: null,
        loading: false,
        response: null
    },
	agentBankDetail: {
        error: null,
        loading: false,
        response: null
    },
    depositRequestResponse:{
       error: null,
        loading: false,
        response: null 
    },
    viewCreditRequestResponse:{
        error: null,
         loading: false,
         response: null 
    },
    depositTypeResponse:{
        error: null,
         loading: false,
         response: null 
    },
    newDepositResponse:{
        error: null,
         loading: false,
         response: null 
    },
    addCreditRequestResponse:{
        error: null,
        loading: false,
        response: null 
    },
    updateAgentResponse:{
        error: null,
        loading: false,
        response: null 
    },
    getMarkupToolResponse:{
        error: null,
        loading: false,
        response: null
    },
    updateDomesticMarkupToolResponse:{
        error: null,
        loading: false,
        response: null
    },
    updateInternationalMarkupToolResponse:{
        error: null,
        loading: false,
        response: null
    },
    calendarResponse: {
        error: null,
        loading: false,
        response: null
    },
    bookingDetails: {
        error: null,
        loading: false,
        response: null
    },
    userTypeDetails: {
        error: null,
        loading: false,
        response: null
    },
    transactionTypeDetails: {
        error: null,
        loading: false,
        response: null
    },
    bookingStatusDetails: {
        error: null,
        loading: false,
        response: null
    },
    accountStatementDetails: {
        error: null,
        loading: false,
        response: null
    },
    myAccountDetails: {
        error: null,
        loading: false,
        response: null
    },
    cancelBookingDetail: {
        error: null,
        loading: false,
        response: null
    },
    releasePnrDetail: {
        error: null,
        loading: false,
        response: null
    },
    updateTicketDetails: {
        error :null,
        loading: false,
        response:null
    },
    emailTicketDetails: {
        error :null,
        loading: false,
        response:null
    },
    emailOfflineTicketDetails: {
        error :null,
        loading: false,
        response:null
    },
    cancelRefundDetails: {
        error :null,
        loading: false,
        response:null
    },
    bookingReportData: {
        error :null,
        loading: false,
        response:null
    },
    emailInvoiceDetails: {
        error :null,
        loading: false,
        response:null
    },
    emailOfflineInvoiceDetails: {
        error :null,
        loading: false,
        response:null
    },
    bookingInfoResponse:{
        error: null,
         loading: false,
         response: null 
     },
     getHotelMarkupResponse:{
        error: null,
        loading: false,
        response: null
    },
    submitHotelMarkupResponse:{
        error: null,
        loading: false,
        response: null
    },
    hotelInvoiceInfoResponse:{
        error: null,
         loading: false,
         response: null 
     },
	myHotelBookingDetails: {
        error: null,
        loading: false,
        response: null
    },
    cancelHotelBookingDetail: {
        error: null,
        loading: false,
        response: null
    },
    emailHotelInvoiceDetails: {
        error :null,
        loading: false,
        response:null
    },
    cancelHotelBookingDetails: {
        error :null,
        loading: false,
        response:null
    },
    emailFlightCreditNoteInfo: {
        error :null,
        loading: false,
        response:null
    },
    emailHotelCreditNoteInfo: {
        error :null,
        loading: false,
        response:null
    },
    partailCancellationInfo: {
        error :null,
        loading: false,
        response:null
    },
    topUpPaymentInitInfo: {
        error :null,
        loading: false,
        response:null
    },
    topUpPaymentResponseInfo: {
        error :null,
        loading: false,
        response:null
    },
    allTopUpInfo: {
        error :null,
        loading: false,
        response:null
    },
    cancelTrainBookingDetail: {
        error :null,
        loading: false,
        response:null
    },
    releaseTripJackDetail: {
        error: null,
        loading: false,
        response: null
    },
    cancelTripJackBookingDetail: {
        error: null,
        loading: false,
        response: null
    },
    kafila_cancel_pnr:{
        loading:false,
        error:null,
        response:null
    },
    releaseHotelTripJackDetail:{
        error:null,
        loading:false,
        response:null
    },
    request_cancellation: {
        error: null,
        loading: false,
        response: null
    },
    cancelRiyaBookingDetail: {
        error: null,
        loading: false,
        response: null
    },
};

export function myAccount(state = initialSessionState, action) {
    switch (action.type) {
        //================================ For My Account ===================================
        case MY_ACCOUNT_START:
            return {
                ...state,
                myAccountResponse: {
                    error: '',
                    loading: true
                }
            }
        case MY_ACCOUNT_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                myAccountResponse: {
                    error: '',
                    loading: false,
                    response: action.response,
                    ...action.decoded
                }
            };

        case MY_ACCOUNT_ERR:
            /* reset session to initial state with the error */
            return {
                ...state,
                myAccountResponse: {
                    error: action.error,
                    loading: false
                }
            }
//================================ For My Account ===================================
            case AGENT_CHANGE_PASSWORD_START:
            return {
                ...state,
                myAgentChangePasswordResponse: {
                    error: '',
                    loading: true
                }
            }
            case AGENT_CHANGE_PASSWORD_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                myAgentChangePasswordResponse: {
                    error: '',
                    loading: false,
                    success: true
                }
            };

            case AGENT_CHANGE_PASSWORD_ERR:
            /* reset session to initial state with the error */
            return {
                ...state,
                myAgentChangePasswordResponse: {
                    error: action,
                    loading: false,
                    success: false
                }
            }
			//================================ For Agent Bank Details ===================================
            case BANK_DETAIL_START:
            return {
                ...state,
                agentBankDetail: {
                    error: '',
                    loading: true
                }
            }
            case BANK_DETAIL_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                agentBankDetail: {
                    error: '',
                    loading: false,
                    response: action
                }
            };

            case BANK_DETAIL_ERR:
            /* reset session to initial state with the error */
            return {
                ...state,
                agentBankDetail: {
                    error: action,
                    loading: false,
                    response: false
                }
            }

        //================================ For Deposit Request ===================================
        case DEPOSIT_REQUEST_START:
            return {
                ...state,
                depositRequestResponse: {
                    error: '',
                    loading: true
                }
            }
        case DEPOSIT_REQUEST_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                depositRequestResponse: {
                    error: '',
                    loading: false,
                    response: action.response,
                    total_count: action.total_count,
                    ...action.decoded
                }
            };

        case DEPOSIT_REQUEST_ERR:
            /* reset session to initial state with the error */
            return {
                ...state,
                depositRequestResponse: {
                    error: action.error,
                    loading: false
                }
            }

            //================================ For Credit Request ===================================
            case VIEW_CREDIT_REQUEST_START:
            return {
                ...state,
                viewCreditRequestResponse: {
                    error: '',
                    loading: true
                }
            }
            case VIEW_CREDIT_REQUEST_OK:
             /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                viewCreditRequestResponse: {
                    error: '',
                    loading: false,
                    response: action.response,
                    total_count: action.total_count,
                    ...action.decoded
                }
            };

            case VIEW_CREDIT_REQUEST_ERR:
            /* reset session to initial state with the error */
            return {
                ...state,
                viewCreditRequestResponse: {
                    error: action.error,
                    loading: false
                }
            }

 //================================ For Deposit Type =================================== 
        case DEPOSIT_TYPE_START:
        return {
            ...state,
            depositTypeResponse: {
                error: '',
                loading: true
            }
        }
        case DEPOSIT_TYPE_OK:
        /* set session as new object, to make sure no old session info lingers ... */
        return {
            ...state,
            depositTypeResponse: {
                error: '',
                loading: false,
                response: action.response,
                ...action.decoded
            }
        };

        case DEPOSIT_TYPE_ERR:
        /* reset session to initial state with the error */
        return {
            ...state,
            depositTypeResponse: {
                error: action.error,
                loading: false
            }
        }

        //================================ For My Account ===================================
            case SUBMIT_DEPOSIT_START:
            return {
                ...state,
                newDepositResponse: {
                    error: '',
                    loading: true
                }
            }
            case SUBMIT_DEPOSIT_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                newDepositResponse: {
                    error: '',
                    loading: false,
                    success: true
                }
            };

            case SUBMIT_DEPOSIT_ERR:
            /* reset session to initial state with the error */
            return {
                ...state,
                newDepositResponse: {
                    error: action,
                    loading: false,
                    success: false
                }
            }
			// ====================================== For Add Credit Request =========================
            case ADD_CREDIT_REQUEST_START:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                addCreditRequestResponse:{
                    error:"",
                    loading:true
                }
            };

            case ADD_CREDIT_REQUEST_OK:
            return {
                ...state,
                addCreditRequestResponse:{
                    error:"",
                    loading: false,
                    response: action.response,
                    ...action.decoded
                }
            };
            
            case ADD_CREDIT_REQUEST_ERROR:
            return {
                ...state,
                addCreditRequestResponse:{
                    error:action,
                    loading: false
                }
            };

             //================================ Update Agent Info ===================================
             case UPDATE_AGENT_INFO_START:
             return {
                 ...state,
                 updateAgentResponse: {
                     error: '',
                     loading: true
                 }
             }
             case UPDATE_AGENT_INFO_OK:
             /* set session as new object, to make sure no old session info lingers ... */
             return {
                 ...state,
                 updateAgentResponse: {
                     error: '',
                     loading: false,
                     success: true
                 }
             };
 
             case UPDATE_AGENT_INFO_ERR:
             /* reset session to initial state with the error */
             return {
                 ...state,
                 updateAgentResponse: {
                     error: action,
                     loading: false,
                     success: false
                 }
             };
//============================ Update Markup Tools Start ========================= //    
            case GET_MARKUP_TOOL_START:
                return {
                    ...state,
                    getMarkupToolResponse: {
                        error: '',
                        loading: true
                    }
                }
                case GET_MARKUP_TOOL_OK:
                /* set session as new object, to make sure no old session info lingers ... */
                return {
                    ...state,
                    getMarkupToolResponse: {
                        error: '',
                        loading: false,
                        response: action.response,
                        ...action.decoded
                    }
                };

                case GET_MARKUP_TOOL_ERROR:
                /* reset session to initial state with the error */
                return {
                    ...state,
                    getMarkupToolResponse: {
                        error: action.error,
                        loading: false
                    }
                };
           
                case DOMESTIC_MARKUP_TOOL_START:
                return {
                    ...state,
                    updateDomesticMarkupToolResponse: {
                        error: '',
                        loading: true
                    }
                }
                case DOMESTIC_MARKUP_TOOL_SUCCESS:
                /* set session as new object, to make sure no old session info lingers ... */
                return {
                    ...state,
                    updateDomesticMarkupToolResponse: {
                        error: '',
                        loading: false,
                        response: action.response,
                        ...action.decoded
                    }
                };

                case DOMESTIC_MARKUP_TOOL_ERROR:
                /* reset session to initial state with the error */
                return {
                    ...state,
                    updateDomesticMarkupToolResponse: {
                        error: action.error,
                        message: action.message,
                        loading: false
                    }
                };
                case INTERNATIONAL_MARKUP_TOOL_START:
                return {
                    ...state,
                    updateInternationalMarkupToolResponse: {
                        error: '',
                        loading: true
                    }
                }
                case INTERNATIONAL_MARKUP_TOOL_SUCCESS:
                /* set session as new object, to make sure no old session info lingers ... */
                return {
                    ...state,
                    updateInternationalMarkupToolResponse: {
                        error: '',
                        loading: false,
                        response: action.response,
                        ...action.decoded
                    }
                };

                case INTERNATIONAL_MARKUP_TOOL_ERROR:
                /* reset session to initial state with the error */
                return {
                    ...state,
                    updateInternationalMarkupToolResponse: {
                        error: action.error,
                        message: action.message,
                        loading: false
                    }
                };
//============================ Update Markup Tools Start ========================= //

//=========================== Calendar Details START ============================ //
            case CALENDAR_DETAILS_START:
            return {
                ...state,
                calendarResponse: {
                    error: '',
                    loading: true
                }
            }
            case CALENDAR_DETAILS_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                calendarResponse: {
                    error: '',
                    loading: false,
                    response: action.response
                }
            };

            case CALENDAR_DETAILS_ERROR:
            /* reset session to initial state with the error */
            return {
                ...state,
                calendarResponse: {
                    error: action,
                    loading: false,
                    response: false
                }
            }


//=========================== Calendar Details END ============================== //

//=========================== Booking Details START ============================ //
            case BOOKING_DETAILS_START:
            return {
                ...state,
                bookingDetails: {
                    error: '',
                    loading: true
                }
            }
            case BOOKING_DETAILS_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                bookingDetails: {
                    error: '',
                    loading: false,
                    response: action.response
                }
            };

            case BOOKING_DETAILS_ERROR:
            /* reset session to initial state with the error */
            return {
                ...state,
                bookingDetails: {
                    error: action,
                    loading: false,
                    response: false
                }
            }


//=========================== Booking Details END ============================== //

//=========================== REQUEST CANCELLATION START ============================ //
case REQUEST_CANCELLATION_START:
    return {
        ...state,
        request_cancellation: {
            error: '',
            loading: true
        }
    }
    case REQUEST_CANCELLATION_OK:
    return {
        ...state,
        request_cancellation: {
            error: '',
            loading: false,
            response: action.response
        }
    };

    case REQUEST_CANCELLATION_ERROR:
    return {
        ...state,
        request_cancellation: {
            error: action,
            loading: false,
            response: false
        }
    }


//=========================== REQUEST CANCELLATION END ============================== //

//=========================== User Type START ============================ //
        case USER_TYPE_START:
            return {
                ...state,
                userTypeDetails: {
                    error: '',
                    loading: true
                }
            }
        case USER_TYPE_OK:
            return {
                ...state,
                userTypeDetails: {
                    error: '',
                    loading: false,
                    response: action.response
                }
            };

        case USER_TYPE_ERROR:
            return {
                ...state,
                userTypeDetails: {
                    error: action,
                    loading: false,
                    response: false
                }
            }
//=========================== User Type END ============================== //
//=========================== Transaction type START ============================ //
            case TRANSACTION_TYPE_START:
                return {
                    ...state,
                    transactionTypeDetails: {
                        error: '',
                        loading: true
                    }
                }
            case TRANSACTION_TYPE_OK:
                return {
                    ...state,
                    transactionTypeDetails: {
                        error: '',
                        loading: false,
                        response: action.response
                    }
                };

            case TRANSACTION_TYPE_ERROR:
                return {
                    ...state,
                    transactionTypeDetails: {
                        error: action,
                        loading: false,
                        response: false
                    }
                }
//=========================== Transaction type END ============================== //
//=========================== GET booking status START ============================ //
            case BOOKING_STATUS_START:
                return {
                    ...state,
                    bookingStatusDetails: {
                        error: '',
                        loading: true
                    }
                }
            case BOOKING_STATUS_OK:
                return {
                    ...state,
                    bookingStatusDetails: {
                        error: '',
                        loading: false,
                        response: action.response
                    }
                };

            case BOOKING_STATUS_ERROR:
                return {
                    ...state,
                    bookingStatusDetails: {
                        error: action,
                        loading: false,
                        response: false
                    }
                }
//=========================== GET booking status END ============================== //

    //=========================== GET Account Statement START ============================ //
            case ACCOUNT_STATEMENT_START:
            return {
                ...state,
                accountStatementDetails: {
                    error: '',
                    loading: true
                }
            }
            case ACCOUNT_STATEMENT_OK:
            return {
                ...state,
                accountStatementDetails: {
                    error: '',
                    loading: false,
                    response: action.response
                }
            };

        case ACCOUNT_STATEMENT_ERROR:
            return {
                ...state,
                accountStatementDetails: {
                    error: action,
                    loading: false,
                    response: false
                }
            }
    //=========================== GET Account Statement END ============================== //
    //=========================== GET My Account START ============================ //
            case MY_BOOKINGS_START:
            return {
                ...state,
                myAccountDetails: {
                    error: '',
                    loading: true
                }
            }
            case MY_BOOKINGS_OK:
            return {
                ...state,
                myAccountDetails: {
                    error: '',
                    loading: false,
                    response: action.response
                }
            };

        case MY_BOOKINGS_ERROR:
            return {
                ...state,
                myAccountDetails: {
                    error: action,
                    loading: false,
                    response: false
                }
            }
    //=========================== GET My Account END ============================== //
    //=========================== Cancel booking START ============================ //
        case CANCEL_BOOKING_START:
            return {
                ...state,
                cancelBookingDetail: {
                    error: '',
                    loading: true
                }
            }
            case CANCEL_BOOKING_OK:
            return {
                ...state,
                cancelBookingDetail: {
                    error: '',
                    loading: false,
                    response: action.response
                }
            };

        case CANCEL_BOOKING_ERROR:
            return {
                ...state,
                cancelBookingDetail: {
                    error: action,
                    loading: false,
                    response: false
                }
            }
//=========================== Cancel booking END ============================== //
    //=========================== Release pnr START ============================ //
        case RELEASE_PNR_START:
            return {
                ...state,
                releasePnrDetail: {
                    error: '',
                    loading: true
                }
            }
            case RELEASE_PNR_OK:
            return {
                ...state,
                releasePnrDetail: {
                    error: '',
                    loading: false,
                    response: action.response
                }
            };

        case RELEASE_PNR_ERROR:
            return {
                ...state,
                releasePnrDetail: {
                    error: action,
                    loading: false,
                    response: false
                }
            }
//=========================== Release pnr END ============================== //
             //Update ticket
             case UPDATE_TICKET_START :
             return {
                 ...state, 
                 updateTicketDetails : {
                     error   : '',
                     loading : true,
                     response : null
                 }
             }
         case UPDATE_TICKET_OK :
             return {
                 ...state, 
                 updateTicketDetails : {
                     error       : '',
                     loading     : false,
                     response    : action.response,
                 }
             };
 
         case UPDATE_TICKET_ERR :
             return {
                 ...state, 
                 updateTicketDetails : {
                    error   : true,
                    loading : false,
                    response : action.response
                 }
             }    

    //=========================== Release pnr END ============================== //
        case EMAIL_TICKET_START :
             return {
                 ...state, 
                 emailTicketDetails : {
                     error   : null,
                     loading : true,
                     response : null
                 }
             }
         case EMAIL_TICKET_OK :
             return {
                 ...state, 
                 emailTicketDetails : {
                     error       : null,
                     loading     : false,
                     response    : action.response,
                 }
             };
 
         case EMAIL_TICKET_ERR :
             return {
                 ...state, 
                 emailTicketDetails : {
                    error   : true,
                    loading : false,
                    response : action.response
                 }
             }  
        //=========================== Release pnr END ============================== //
         case EMAIL_OFFLINE_TICKET_START :
            return {
                ...state, 
                emailOfflineTicketDetails : {
                    error   : null,
                    loading : true,
                    response : null
                }
            }
        case EMAIL_OFFLINE_TICKET_OK :
            return {
                ...state, 
                emailOfflineTicketDetails : {
                    error       : null,
                    loading     : false,
                    response    : action.response,
                }
            };

        case EMAIL_OFFLINE_TICKET_ERR :
            return {
                ...state, 
                emailOfflineTicketDetails : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            }          
    //============================== Cancel Refund Details ===========================
    case CANCEL_REFUND_START :
             return {
                 ...state, 
                 cancelRefundDetails : {
                     error   : null,
                     loading : true,
                     response : null
                 }
             }
         case CANCEL_REFUND_OK :
             return {
                 ...state, 
                 cancelRefundDetails : {
                     error       : null,
                     loading     : false,
                     response    : action.response,
                 }
             };
 
         case CANCEL_REFUND_ERR :
             return {
                 ...state, 
                 cancelRefundDetails : {
                    error   : true,
                    loading : false,
                    response : action.response
                 }
             }    
              //============================== Booking report Details ===========================
            case BOOKING_REPORT_START :
                return {
                    ...state, 
                    bookingReportData : {
                        error   : null,
                        loading : true,
                        response : null
                    }
                }
            case BOOKING_REPORT_OK :
                return {
                    ...state, 
                    bookingReportData : {
                        error       : null,
                        loading     : false,
                        response    : action.response,
                    }
                };

            case BOOKING_REPORT_ERR :
                return {
                    ...state, 
                    bookingReportData : {
                        error   : true,
                        loading : false,
                        response : action.response
                    }
                }
                
                
        //=========================== Release pnr END ============================== //
        case EMAIL_INVOICE_START :
             return {
                 ...state, 
                 emailInvoiceDetails : {
                     error   : null,
                     loading : true,
                     response : null
                 }
             }
         case EMAIL_INVOICE_OK :
             return {
                 ...state, 
                 emailInvoiceDetails : {
                     error       : null,
                     loading     : false,
                     response    : action.response,
                 }
             };
 
         case EMAIL_INVOICE_ERR :
             return {
                 ...state, 
                 emailInvoiceDetails : {
                    error   : true,
                    loading : false,
                    response : action.response
                 }
             } 
            //=========================== Release pnr END ============================== //
        case EMAIL_OFFLINE_INVOICE_START :
            return {
                ...state, 
                emailOfflineInvoiceDetails : {
                    error   : null,
                    loading : true,
                    response : null
                }
            }
        case EMAIL_OFFLINE_INVOICE_OK :
            return {
                ...state, 
                emailOfflineInvoiceDetails : {
                    error       : null,
                    loading     : false,
                    response    : action.response,
                }
            };

        case EMAIL_OFFLINE_INVOICE_ERR :
            return {
                ...state, 
                emailOfflineInvoiceDetails : {
                error   : true,
                loading : false,
                response : action.response
                }
            }     
    //================================ For Booking Info ===================================
        case BOOKING_INFO_START:
        return {
            ...state,
            bookingInfoResponse: {
                error: '',
                loading: true,
                response:false
            }
        }
    case BOOKING_INFO_OK:
        /* set session as new object, to make sure no old session info lingers ... */
        return {
            ...state,
            bookingInfoResponse: {
                error: '',
                loading: false,
                response: action.response,
            }
        };

    case BOOKING_INFO_ERR:
        /* reset session to initial state with the error */
        return {
            ...state,
            bookingInfoResponse: {
                error: action.error,
                loading: false,
                response:false
            }
        }

//====================================get hotel markup tool data===============================================
            case GET_HOTEL_MARKUP_TOOL_START:
                return {
                    ...state,
                    getHotelMarkupResponse: {
                        error: '',
                        loading: true
                    }
                }
            case GET_HOTEL_MARKUP_TOOL_OK:
                /* set session as new object, to make sure no old session info lingers ... */
                return {
                    ...state,
                    getHotelMarkupResponse: {
                        error: '',
                        loading: false,
                        response: action.response,
                        ...action.decoded
                    }
                };
            case GET_HOTEL_MARKUP_TOOL_ERR:
                /* reset session to initial state with the error */
                return {
                    ...state,
                    getHotelMarkupResponse: {
                        error: action.error,
                        loading: false
                    }
                };

//====================================SUBMIT hotel markup tool data===============================================
            case UPDATE_HOTEL_MARKUP_START:
            return {
                ...state,
                submitHotelMarkupResponse: {
                    error: '',
                    loading: true
                }
            }
        case UPDATE_HOTEL_MARKUP_SUCCESS:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                submitHotelMarkupResponse: {
                    error: '',
                    loading: false,
                    response: action.response,
                    ...action.decoded
                }
            };
        case UPDATE_HOTEL_MARKUP_ERR:
            /* reset session to initial state with the error */
            return {
                ...state,
                submitHotelMarkupResponse: {
                    error: action.error,
                    loading: false
                }
            };

//================================ Hotel Invoice Information ===================================
            case HOTEL_INVOICE_START:
            return {
                ...state,
                hotelInvoiceInfoResponse: {
                    error: '',
                    loading: true
                }
            }
            case HOTEL_INVOICE_OK:
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                hotelInvoiceInfoResponse: {
                    error: '',
                    loading: false,
                    response: action.response,
                }
            };

            case HOTEL_INVOICE_ERR:
            /* reset session to initial state with the error */
            return {
                ...state,
                hotelInvoiceInfoResponse: {
                    error: action.error,
                    loading: false
                }
            }
		//=========================== GET My hotel booking START ============================ //
			case MY_HOTEL_BOOKINGS_START:
				return {
					...state,
					myHotelBookingDetails: {
						error: '',
						loading: true
					}
				}
			case MY_HOTEL_BOOKINGS_OK:
				return {
					...state,
					myHotelBookingDetails: {
						error: '',
						loading: false,
						response: action.response
					}
				};

			case MY_HOTEL_BOOKINGS_ERROR:
				return {
					...state,
					myHotelBookingDetails: {
						error: action.message,
						loading: false,
						response: false
					}
				}
        //=========================== GET My hotel booking END ============================== //
        //=========================== Cancel Hotel booking START ============================ //
        case CANCEL_HOTEL_BOOKING_START:
            return {
                ...state,
                cancelHotelBookingDetail: {
                    error: '',
                    loading: true
                }
            }
            case CANCEL_HOTEL_BOOKING_OK:
            return {
                ...state,
                cancelHotelBookingDetail: {
                    error: '',
                    loading: false,
                    response: action.response
                }
            };

        case CANCEL_HOTEL_BOOKING_ERROR:
            return {
                ...state,
                cancelHotelBookingDetail: {
                    error: action,
                    loading: false,
                    response: false
                }
            }
//=========================== Cancel Hotel booking END ============================== //
//============================Release hotel Tripjack start =========================//
            case RELEASE_TRIPJACK_HOTEL_START:
            return {
                ...state,
                releaseHotelTripJackDetail: {
                    error: '',
                    loading: true
                }
            }
            case RELEASE_HOTEL_TRIPJACK_OK:
            return {
                ...state,
                releaseHotelTripJackDetail: {
                    error: '',
                    loading: false,
                    response: action.response
                }
            };

        case RELEASE_HOTEL_TRIPJACK_ERROR:
            return {
                ...state,
                releaseHotelTripJackDetail: {
                    error: action,
                    loading: false,
                    response: null
                }
            }
//============================Release hotel Tripjack end=========================//
//=========================== EMAIL HOTEL INVOICE ============================== //
        case EMAIL_HOTEL_INVOICE_START :
            return {
                ...state, 
                emailHotelInvoiceDetails : {
                    error   : null,
                    loading : true,
                    response : null
                }
            }
        case EMAIL_HOTEL_INVOICE_OK :
            return {
                ...state, 
                emailHotelInvoiceDetails : {
                    error       : null,
                    loading     : false,
                    response    : action.response,
                }
            };

        case EMAIL_HOTEL_INVOICE_ERR :
            return {
                ...state, 
                emailHotelInvoiceDetails : {
                error   : true,
                loading : false,
                response : action.response
                }
            } 
//=========================== EMAIL HOTEL INVOICE END============================== //

//============================== Cancel Hotel Refund Details ===========================
        case CANCEL_HOTEL_REFUND_START :
            return {
                ...state, 
                cancelHotelBookingDetails : {
                    error   : null,
                    loading : true,
                    response : null
                }
            }
        case CANCEL_HOTEL_REFUND_OK :
            return {
                ...state, 
                cancelHotelBookingDetails : {
                    error       : null,
                    loading     : false,
                    response    : action.response,
                }
            };

        case CANCEL_HOTEL_REFUND_ERR :
            return {
                ...state, 
                cancelHotelBookingDetails : {
                error   : true,
                loading : false,
                response : action.response
                }
            } 
    //=========================== EMAIL FLIGHT CREDIT NOTE ============================== //
        case EMAIL_FLIGHT_CREDIT_NOTE_START :
            return {
                ...state, 
                emailFlightCreditNoteInfo : {
                    error   : null,
                    loading : true,
                    response : null
                }
            }
        case EMAIL_FLIGHT_CREDIT_NOTE_OK :
            return {
                ...state, 
                emailFlightCreditNoteInfo : {
                    error       : null,
                    loading     : false,
                    response    : action.response,
                }
            };

        case EMAIL_FLIGHT_CREDIT_NOTE_ERR :
            return {
                ...state, 
                emailFlightCreditNoteInfo : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            } 
//=========================== EMAIL FLIGHT CREDIT NOTE END============================== //
//=========================== EMAIL Hotel CREDIT NOTE ============================== //
        case EMAIL_HOTEL_CREDIT_NOTE_START :
            return {
                ...state, 
                emailHotelCreditNoteInfo : {
                    error   : null,
                    loading : true,
                    response : null
                }
            }
        case EMAIL_HOTEL_CREDIT_NOTE_OK :
            return {
                ...state, 
                emailHotelCreditNoteInfo : {
                    error       : null,
                    loading     : false,
                    response    : action.response,
                }
            };

        case EMAIL_HOTEL_CREDIT_NOTE_ERR :
            return {
                ...state, 
                emailHotelCreditNoteInfo : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            } 
//=========================== EMAIL FLIGHT CREDIT NOTE END============================== //
//=========================== Partial cancellation detail Start ============================== //
        case PARTIAL_CANCELLATIONS_START :
            return {
                ...state, 
                partailCancellationInfo : {
                    error   : null,
                    loading : true,
                    response : null
                }
            }
        case PARTIAL_CANCELLATIONS_OK :
            return {
                ...state, 
                partailCancellationInfo : {
                    error       : null,
                    loading     : false,
                    response    : action.response,
                }
            };

        case PARTIAL_CANCELLATIONS_ERR :
            return {
                ...state, 
                partailCancellationInfo : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            } 
//=========================== Partial cancellation detail END============================== //
//=========================== Topup payment init Start ============================== //
        case TOP_UP_PAYMENT_INIT_START :
            return {
                ...state, 
                topUpPaymentInitInfo : {
                    error   : null,
                    loading : true,
                    response : null
                }
            }
        case TOP_UP_PAYMENT_INIT_OK :
            return {
                ...state, 
                topUpPaymentInitInfo : {
                    error       : null,
                    loading     : false,
                    response    : action.response,
                }
            };

        case TOP_UP_PAYMENT_INIT_ERR :
            return {
                ...state, 
                topUpPaymentInitInfo : {
                    error   : true,
                    loading : false,
                    response : action.response
                }
            } 
//=========================== Topup payment init Start END============================== //
//=========================== Topup payment Response Start ============================== //
            case TOP_UP_PAYMENT_RESPONSE_START :
                return {
                    ...state, 
                    topUpPaymentResponseInfo : {
                        error   : null,
                        loading : true,
                        response : null
                    }
                }
            case TOP_UP_PAYMENT_RESPONSE_OK :
                return {
                    ...state, 
                    topUpPaymentResponseInfo : {
                        error       : null,
                        loading     : false,
                        response    : action.response,
                    }
                };

            case TOP_UP_PAYMENT_RESPONSE_ERR :
                return {
                    ...state, 
                    topUpPaymentResponseInfo : {
                        error   : true,
                        loading : false,
                        response : action.response
                    }
                } 
//=========================== Topup payment Response Start END============================== //
//=========================== All Topup Start ============================== //
                case ALL_TOP_UP_FETCH_START :
                    return {
                        ...state, 
                        allTopUpInfo : {
                            error   : null,
                            loading : true,
                            response : null
                        }
                    }
                case ALL_TOP_UP_FETCH_OK :
                    return {
                        ...state, 
                        allTopUpInfo : {
                            error       : null,
                            loading     : false,
                            response    : action.response,
                        }
                    };

                case ALL_TOP_UP_FETCH_ERR :
                    return {
                        ...state, 
                        allTopUpInfo : {
                            error   : true,
                            loading : false,
                            response : action.response
                        }
                    } 
//=========================== All Topup payment END============================== //

//=========================== Cancel Train booking START ============================ //
        case CANCEL_TRAIN_BOOKING_START:
            return {
                ...state,
                cancelTrainBookingDetail: {
                    error: '',
                    loading: true
                }
            }
        case CANCEL_TRAIN_BOOKING_OK:
            return {
                ...state,
                cancelTrainBookingDetail: {
                    error: '',
                    loading: false,
                    response: action.response
                }
            };

        case CANCEL_TRAIN_BOOKING_ERROR:
            return {
                ...state,
                cancelTrainBookingDetail: {
                    error: action,
                    loading: false,
                    response: false
                }
            }
//=========================== Cancel Train booking END ============================== //

//=========================== Release TripJack START ============================ //
case RELEASE_TRIPJACK_START:
    return {
        ...state,
        releaseTripJackDetail: {
            error: '',
            loading: true
        }
    }
case RELEASE_TRIPJACK_OK:
    return {
        ...state,
        releaseTripJackDetail: {
            error: '',
            loading: false,
            response: action.response
        }
    };

case RELEASE_TRIPJACK_ERROR:
    return {
        ...state,
        releaseTripJackDetail: {
            error: action,
            loading: false,
            response: false
        }
    }
//=========================== Release TripJack END ============================== //
//=========================== Cancel TripJack booking START ============================ //
case CANCEL_TRIPJACK_BOOKING_START:
    return {
        ...state,
        cancelTripJackBookingDetail: {
            error: '',
            loading: true
        }
    }
    case CANCEL_TRIPJACK_BOOKING_OK:
    return {
        ...state,
        cancelTripJackBookingDetail: {
            error: '',
            loading: false,
            response: action.response
        }
    };

case CANCEL_TRIPJACK_BOOKING_ERROR:
    return {
        ...state,
        cancelTripJackBookingDetail: {
            error: action,
            loading: false,
            response: false
        }
    }
//=========================== Cancel TripJack booking END ============================== //

    //=========================== Cancel kafila booking START ============================ //
case CANCEL_KAFILA_BOOKING_START:
    return {
        ...state,
        kafila_cancel_pnr: {
            error: '',
            loading: true
        }
    }
    case CANCEL_KAFILA_BOOKING_OK:
    return {
        ...state,
        kafila_cancel_pnr: {
            error: '',
            loading: false,
            response: action.response
        }
    };

case CANCEL_KAFILA_BOOKING_ERROR:
    return {
        ...state,
        kafila_cancel_pnr: {
            error: action,
            loading: false,
            response: action
        }
    }


//=========================== Cancel Tripjack Hotel booking START ============================ //
case CANCEL_TRIPJACK_HOTEL_BOOKING_START:
    return {
        ...state,
        cancelHotelBookingDetail: {
            error: '',
            loading: true
        }
    }
    case CANCEL_TRIPJACK_HOTEL_BOOKING_OK:
    return {
        ...state,
        cancelHotelBookingDetail: {
            error: '',
            loading: false,
            response: action.response
        }
    };

case CANCEL_TRIPJACK_HOTEL_BOOKING_ERROR:
    return {
        ...state,
        cancelHotelBookingDetail: {
            error: action,
            loading: false,
            response: false
        }
    }

     //=========================== Cancel RIya booking START ============================ //
case CANCEL_RIYA_BOOKING_START:
    return {
        ...state,
        cancelRiyaBookingDetail: {
            error: '',
            loading: true
        }
    }
    case CANCEL_RIYA_BOOKING_OK:
    return {
        ...state,
        cancelRiyaBookingDetail: {
            error: '',
            loading: false,
            response: action.response
        }
    };

case CANCEL_RIYA_BOOKING_ERROR:
    return {
        ...state,
        cancelRiyaBookingDetail: {
            error: action,
            loading: false,
            response: false
        }
    }
//=========================== Cancel Riya booking END ============================== //
//=========================== Cancel Tripjack Hotel booking END ============================== //

        default:
            return state;
    }
}
