import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import 'react-dates/initialize';
import { Formik } from "formik";
import * as Yup from "yup";

class NewCredit extends Component {
    constructor() {
        super();
        this.state = {
        hideMsg  : false,
        formFlag : true,
        }
    }

      renderForm = (formikProps) =>{
        const { errors, handleBlur, handleChange,handleSubmit, values, handleReset} = formikProps;
        const { showConfirmationPopup} = this.props; //accountResponse,
        return(
            <React.Fragment>
            <form autoComplete="off" id="credit_request" tabIndex="0">
                <div className="form-group">
                    <div className="input-group">
                        <input className="form-control custom-filed" type="text" name="amount" value={values.amount} placeholder="Enter Amount" onBlur={handleBlur} onChange={handleChange} tabIndex="1"/>
                        {errors.amount &&<span className="error-msg">{errors.amount}</span>}
                    </div>
                </div>
                <div className="form-group">
                    <div className="input-group">
                        <textarea className="form-control custom-filed" name="remarks" value={values.remarks} placeholder="Enter Remark" onBlur={handleBlur} onChange={handleChange} tabIndex="2"></textarea>
                        {errors.remarks &&<span className="error-msg">{errors.remarks}</span>}
                    </div>
                </div>
                <div className="btn-block text-center">
                    <div className="col1"><button type="button" className="btn btn-sky-blue" onClick={handleSubmit} tabIndex="3">{/*accountResponse.addCreditRequestResponse.loading === false?"Submit Request":"Submitting.."*/}Continue </button></div>
                    <div className="col1"><button type="reset" onClick={handleReset} className="btn btn-dark" tabIndex="4">Reset</button></div>
                </div>
            </form>
            {/* Popup for credit confirmation */}
            <div className={`popup-crad confirmation-popup ${showConfirmationPopup  ? 'show' : ''}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>Credit Confirmation</h2>
                            <div className="popup-close"><span className="icon icon-cross" onClick={()=>this.props.handleConfirmationPopup(false)}/></div>
                        </div>							
                        <div className="popup-body popup-scorll">
                        {
                            !this.props.showLoaderOnPopup &&
                            <div className="inner-confirmation">
                                <p>Are you sure you want to continue?</p>
                                <div className="popup-footer">
                                    <button className="btn btn-orange" onClick={()=>this.props.submitCreditForm(values)}>Yes</button>
                                    <button className="btn btn-grey" onClick={()=>this.props.handleConfirmationPopup(false)}>No</button>
                                </div>
                            </div>
                        }
                        {
                            this.props.showLoaderOnPopup &&
                            <div className="loader ssr-loader relative-loader" style={{display:'block'}}>
                                <div className="loader-content">
                                        <div className="loader-ring blue-ring"> 
                                            <span> </span>
                                        </div>
                                </div>
                            </div>
                        }    
                        </div>
                    </div>
                </div>
                <div className="popup-overlay"/>
            </div>
            </React.Fragment>
            )
    }
    render() {
       const {pagesResponse} = this.props;
       
       var errorMsg,successMsg;
        const showReplyForm = () => {
            this.setState({hideMsg: true});
        };
        const initialFormValue = {
            amount:"",
            remarks:"",
        };
        const validationSchema =  Yup.object().shape({
            amount: Yup.number()
                .required("Amount is required")
                .typeError("Please enter valid amount"),
            remarks: Yup.string()
                .required("Remark is required"),
        });

       if(this.props.addCreditRequest === 'success')
       {
            errorMsg = '';
            successMsg = 'Credit Request has been send successfully.';
            if(!this.state.formFlag){
                document.getElementById("credit_request").reset();
                this.setState({formFlag: false});
            }
       }
       else if(this.props.addCreditRequest === 'error')
       {
           errorMsg = 'Credit Request insertion failed.';
           successMsg = '';
       }
        return (
            <div className="profile-detail-info">
                <div className="edit-profile-bar">
                    <div className="pull-right">
                    { /* eslint-disable-next-line */}
                        <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn">
                        <i className="icon icon-menu"></i>Profile Menus</a>
                    </div>
                </div>
                <div className="tabs-container-detail">
                    <div className="statement-info">
                        <div className="tabs-top">
                            <div className="tabs-title">
                                <h2><i className="icon icon-currency"></i><span>New CREDIT LIMIT REQUESTS</span></h2>
                                <div className="pull-right"> <Link to ="view-credits" className="sm-btn blue-sm-btn">View Credit Request</Link> </div>
                            </div> 
                            <div dangerouslySetInnerHTML={{ __html: pagesResponse.response && pagesResponse.response.pages['new_credit-request'].page_description }} />
                        </div>
                       
                        <div className="change-pass-info">
                            <div className="change-pass-form">
                                {successMsg && <div className="alert alert-success" style={{ display: (this.state.hideMsg === true)?'none':'' }}> <strong> <i className="fa fa-check-circle"></i> {successMsg} </strong> <i className="fa fa-times" aria-hidden="true" onClick={showReplyForm}></i> </div>}
                                {errorMsg && <div className="alert alert-danger" style={{ display: (this.state.hideMsg === true)?'none':'' }}> <strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong> <i className="fa fa-times" aria-hidden="true" onClick={showReplyForm}></i></div>}
                                <p className="required">All Fields are Required<sup>*</sup></p>
                                <Formik
                                    initialValues = {initialFormValue}
                                    onSubmit={() => this.props.handleConfirmationPopup(true,null)}
                                    validationSchema = {validationSchema}
                                    render={this.renderForm}
                                /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (NewCredit)