import React, { Component } from 'react';

class AgentHeaderNavigation extends Component {
    render() {
        const { productResponseList } = this.props;
        let productAccess = [];
        if (this.props.agentProductAccess.response) {
            for (let i = 0; i < this.props.agentProductAccess.response.user_access_info.length; i++) {
                productAccess.push(this.props.agentProductAccess.response.user_access_info[i].product_code);
            }
        }
        return (
            <div className="nav-section">
                <div className="container">
                    <ul className="nav-block">
                        {
                            productResponseList && productResponseList.response && productResponseList.response.map((item, key) => {
                                let product_nav;
                                if (item.product_url === 'flight-booking') {
                                    product_nav = "assets/images/flight-nav.svg";
                                } else if (item.product_url === 'train-booking') {
                                    product_nav = "assets/images/railway-nav.svg";
                                } else if (item.product_url === 'hotel-booking') {
                                    product_nav = "assets/images/hotel-nav.svg";
                                } else if (item.product_url === 'tour-packages') {
                                    product_nav = "assets/images/tour-nav.svg";
                                }

                                let redirect = null;
                                if (item.product_url === 'flight-booking') {
                                    redirect = '/flight-search';
                                }
                                else if (item.product_url === 'hotel-booking') {
                                    redirect = '/hotel-search';
                                }
                                else if (item.product_url === 'train-booking') {
                                    redirect = 'https://www.agent.irctc.co.in/nget/train-search';
                                }
                                return (
                                    <React.Fragment key={key}>
                                        {
                                            productAccess.includes(item.product_code)
                                                ?
                                                <li className={item.product_url === 'flight-booking' ? "" : ''}>
                                                    {/* eslint-disable-next-line */}
                                                    {(redirect ? <a href={redirect} ><i><img src={product_nav} alt="" /></i>{item.product_name}</a>
                                                        /* eslint-disable-next-line */
                                                        : <a href="javascript:void(0);"><i><img src={product_nav} alt="" /></i>{item.product_name}</a>)}
                                                </li>
                                                :
                                                <li className={`disable-nav ${item.product_url === 'flight-booking' ? "" : ''}`}>
                                                    {/* eslint-disable-next-line */
                                                        <a href="javascript:void(0);"><i><img src={product_nav} alt="" /></i>{item.product_name}</a>}
                                                </li>
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

export default (AgentHeaderNavigation)