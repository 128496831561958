
/* external dependencies */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from "yup";
import Link from "react-router-dom/es/Link";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            isRememberMe: false,
            inputType: 'password',
            eyeIcon: 'icon icon-eye',
            username: '',
            password: ''
        };
    }

    handleRememberMe = (event, inputs) => {
        this.setState({isRememberMe: event.target.checked});
        
        if(event.target.checked && inputs.username && inputs.password) {
            localStorage.setItem('username', inputs.username);
            localStorage.setItem('password', inputs.password);
        }

        if(!event.target.checked) {
            localStorage.setItem('username', '');
            localStorage.setItem('password', '');
        }
    }

    //will be called on click of eye icon in password fields (if input type is text than set it to password and vice versa)
    showHidePassword(){
        this.setState({
            inputType: this.state.inputType === 'text' ? 'password' : 'text',
            eyeIcon: this.state.inputType === 'text' ? 'icon icon-eye' : 'icon icon-eye-blocked'
        })  
    }

    /* create our form validator */
    loginValidator = yup.object()
    .shape({
        username: yup.string()
            .matches(/^[0-9]+$/, 'Mobile Number should only contain numbers')
            .required('Mobile Number/Password is required'),
        password: yup.string().required('Mobile Number/Password is required'),
    });


    onChange(e, formikProps) {
        this.setState({ [e.target.name]: e.target.value });
        formikProps.setFieldValue(e.target.name, e.target.value);
    }

    enterPressed = (formikProps, event) => { //event is not passed from methd calling as its received by default when it was binded to our context
        var code = event.keyCode || event.which;
        if(code === 13) {             
            formikProps.handleSubmit();
        }
    }

    handleClick = () => {
        this.props.handleComponentClass('show-send-otp');
    }
    handleKeyDown = (e) => {
        const allowedKeys = ['Backspace', 'Delete', 'Tab', 'Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight','Shift'];
        if (
            !(allowedKeys.includes(e.key) || (e.key >= '0' && e.key <= '9') || ((e.key === 'a' || e.key === 'z' || e.key === 'c' || e.key === 'x' || e.key === 'y') && e.ctrlKey))
        ) {
            e.preventDefault();
        }
    }

    renderForm = (formikProps) => {
        const {handleSubmit, errors, touched, values, isSubmitting} = formikProps;
        const {errorMsg, successMsg, session} = this.props;
        
        return (
            <div className="inner-login">
                <h2>Secure Login</h2>
                {((errors.username && touched.username) || (errors.password && touched.password)) && <div className="alert alert-danger"> <strong><i className="fa fa-exclamation-triangle"></i>{errors.username?errors.username:errors.password}</strong></div>}
                {errorMsg && <div className="alert alert-danger"> <strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong> <i className="fa fa-times" aria-hidden="true"></i></div>}
                {successMsg && <div className="alert alert-success"> <strong> <i className="fa fa-check-circle"></i>  You have logged in Successfully. Please wait while we are redirecting </strong>  </div>}
                <div className="login_form">
                    <div className="form-group">
                        <Field
                            type="text"
                            name="username"
                            className="form-control"
                            required
                            value={values.username}
                            onKeyDown={(e)=>this.handleKeyDown(e)}
                            onChange={(value) => this.onChange(value, formikProps)}
                        />
                        <i className="material-icons">person</i>
                        <label className="control-label"> Enter Mobile Number</label>
                    </div>
                    <div className="form-group">
                        <Field
                            type={this.state.inputType}
                            name="password"
                            className="form-control" required
                            value={values.password}
                            onKeyPress={this.enterPressed.bind(this, formikProps)} //this will not be received as an argument, it shows only the context to which our method is binded
                            onChange={(value) => this.onChange(value, formikProps)}
                        />
                        <i className="material-icons">lock</i>
                        <label className="control-label"> Password</label>
                        <span className="password-show-hide" onClick={() => this.showHidePassword()} >
                            { /* eslint-disable-next-line */}
                            <a href="javascript:void(0)"><i className={this.state.eyeIcon}></i></a>
                        </span>
                    </div>
                </div>
                <div className="form-login-button"> 
                {/* this.props.handleComponentClass.bind('show-send-otp') */}
                    { /* eslint-disable-next-line */}
                    <a className="forgot-password" href="javascript:void(0)" onClick={this.handleClick}>Forgot/Reset Password</a>
                    <div className="remember-me">
                        <small className="checkbox">
                            <input
                                type="checkbox"
                                name="rememberme"
                                defaultChecked={localStorage.getItem('username') && localStorage.getItem('password')}
                                onBlur={(event) => this.handleRememberMe(event, values) }
                            />
                            <label></label>
                        </small>
                        <span>Remember Me</span>
                    </div>
                    {
                        <div className="login-btn">
                            <button type="submit" className="btn btn-green loader-btn" disabled={isSubmitting} onClick={handleSubmit}>
                                <span style={{ display: `${session.loading === true ? 'none' : ''}` }}>Login</span>
                                <div className="loader white-ring" style={{ display: `${session.loading === true ? 'block' : ''}` }}>
                                    <div className="loader-content">
                                        <div className="loader-ring blue-ring"> <span></span> </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    render() {
        const {pagesResponse} = this.props;
        return (
            <div className="login-card">
                <div className="login-outer">
                    <Formik
                        initialValues={{username: (localStorage.getItem('username')) ? localStorage.getItem('username') :'', 
                                            password: (localStorage.getItem('password')) ? localStorage.getItem('password') : ''}}
                        onSubmit={(values, actions) => this.props.handleLoginSubmit(values, actions)}
                        validationSchema={this.loginValidator}
                        render={this.renderForm}
                    />    
                    <div className="login-footer">
                        <p> {pagesResponse.response && pagesResponse.response.pages['login'].page_description} <Link to="/register">SIGNUP NOW</Link></p>
                    </div>   
                </div>
            </div>
        );
    }
}

export default LoginForm