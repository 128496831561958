import React from "react";
import { Field } from "formik";
import {
  currencyFormat,
  dateFormat,
  getNewDateFormted,
  newDateFunction,
} from "../../../shared/commonHelper";
import Select from "react-virtualized-select";
import moment from "moment";
import Datetime from "react-datetime";
import * as flightHelper from "../../../shared/flightHelper";
import "react-datetime/css/react-datetime.css";

class FlightTravellerComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      showSSr: [],
      selectedYear: "",
      selectedMonth: "",
      selectedDay: "",

      selectedIssueYear: "",
      selectedIssueMonth: "",
      selectedIssueDay: "",

      selectedDobYear: "",
      selectedDobMonth: "",
      selectedDobDay: "",
      showPopup: false,
    };
  }
  autofill = (title, type, count) => {
    if (title === "Mr" || title === "Mstr") {
      this.props.setFieldValue("gender_" + type + "_" + count, "male");
    } else if (title && title !== "") {
      this.props.setFieldValue("gender_" + type + "_" + count, "female");
    }
  };

  getComapnyName = (event, setFieldValue) => {
    setFieldValue("gst_number", event.company_gst_no);
    setFieldValue("gst_company_name", event.value);
    setFieldValue("gst_company_number", event.company_contact_number);
    setFieldValue("gst_company_address", event.company_address);
    setFieldValue("gst_company_email", event.company_email);
  };

  openSsr = (event, action) => {
    let ssrShow = this.state.showSSr;
    if (action === "add") {
      ssrShow.push(event);
    } else if (action === "remove") {
      var indexValue = ssrShow.indexOf(event);
      ssrShow.splice(indexValue, 1);
    }
    this.setState({
      showSSr: ssrShow,
    });
  };

  passengerForm = (count, type, icon) => {
    const {
      handleChange,
      values,
      ssrDetails,
      errors,
      touched,
      handleBlur,
      setFieldValue,
      fareQuote,
      country_details,
      ssrKafilaDetails,
      ssrKafilaDetailsRound,
    } = this.props;
    let isDomestic =
      this.props.searchQuery && this.props.searchQuery.is_domestic;
    let pft = this.props.searchQuery && this.props.searchQuery.pft;
    let airline_code =
      this.props.fareQuote.response &&
      this.props.fareQuote.response.results.airline_code;
    let isPassportMandatory =
      this.props.fareQuote.response &&
      this.props.fareQuote.response.results &&
      this.props.fareQuote.response.results.is_passport_mandatory;
    let airline_remark =
      this.props.fareQuote.response &&
      this.props.fareQuote.response.results.airline_remark;
    let pcs_pid =
      this.props.fareQuote.response &&
      this.props.fareQuote.response.conditions &&
      this.props.fareQuote.response.conditions.pcs &&
      this.props.fareQuote.response.conditions.pcs.pid; /// passport issue date required or not check in fare quote for trip jack
    let dobRequired = false;
    let tripJackBooking = false;
    let studentFareRequired = false;
    let tboBooking = false;
    let satkarBooking = false;
    let KafilaBooking = false;
    let riyaBooking = false;
    let fareBoutiqueBooking = false;
    if (
      isDomestic === "false" &&
      this.props.location.state.data.api_source === "omairiq"
    ) {
      dobRequired = true;
    } else if (isDomestic === "false" && type !== "adult") {
      dobRequired = true;
    }
    if (
      isDomestic === "false" &&
      this.props.location.state.data.api_source === "gofly"
    ) {
      dobRequired = true;
    } else if (isDomestic === "false" && type !== "adult") {
      dobRequired = true;
    }
    if (
      isDomestic === "false" &&
      this.props.location.state.data.api_source === "satkar"
    ) {
      dobRequired = true;
    } else if (isDomestic === "false" && type !== "adult") {
      dobRequired = true;
    }
    if (
      isDomestic === "false" &&
      this.props.location.state.data.api_source === "fbq"
    ) {
      dobRequired = true;
    } else if (isDomestic === "false" && type !== "adult") {
      dobRequired = true;
    }
    if (isDomestic === "false" && this.props.location.state.data.api_source === "riya_travel") {
      dobRequired = true;
    } else if (isDomestic === "false" && type !== "adult") {
      dobRequired = true;
    }
    if (
      (airline_code === "AK" ||
        airline_code === "I5" ||
        airline_code === "FD" ||
        airline_code === "D7" ||
        airline_code === "QZ" ||
        airline_code === "QZ2" ||
        airline_code === "IX" ||
        airline_code === "G9" ||
        airline_code === "YY") &&
      type === "adult"
    ) {
      dobRequired = true;
    } else if (type !== "adult") {
      dobRequired = true;
    } else if (
      type === "adult" &&
      (airline_code === "SG" ||
        airline_code === "G8" ||
        airline_code === "6E" ||
        airline_code === "EK") &&
      isDomestic === "false"
    ) {
      dobRequired = true;
    } else if (airline_code === "OG" || airline_code === "S5") {
      dobRequired = true;
    } else if (
      (airline_code === "Air Asia" || airline_code === "Akasa Air") &&
      this.props.location.state.data.api_source === "tbo" &&
      type === "adult"
    ) {
      dobRequired = true;
    } else if (
      type === "adult" &&
      this.props.location.state.data.api_source === "tbo" &&
      isDomestic === "false"
    ) {
      dobRequired = true;
    } else if (
      (this.props.location.state.data.api_source === "tbo" ||
        (this.props.location.state.index !== undefined &&
          this.props.location.state.data[this.props.location.state.index]
            .api_source === "tbo")) &&
      pft === "STUDENT" &&
      (airline_code === "6E" || airline_code === "SG")
    ) {
      studentFareRequired = true;
    } else if (
      (this.props.location.state.data.api_source === "kafila" ||
        (this.props.location.state.index !== undefined &&
          this.props.location.state.data[this.props.location.state.index]
            .api_source === "kafila")) &&
      pft === "STUDENT"
    ) {
      studentFareRequired = true;
    }

    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return" || this.props.location.state.searchType === 'multi-city') &&
      this.props.location.state.data.api_source === "tripjack"
    ) {
      dobRequired = true;
      tripJackBooking = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "tripjack"
    ) {
      dobRequired = true;
      tripJackBooking = true;
    }

    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "multi-city" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "tbo"
    ) {
      tboBooking = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "tbo"
    ) {
      tboBooking = true;
    }

    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "kafila" &&
      isDomestic === "false"
    ) {
      dobRequired = true;
      KafilaBooking = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "kafila" &&
      isDomestic === "false"
    ) {
      dobRequired = true;
      KafilaBooking = true;
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "kafila" &&
      isDomestic === "true"
    ) {
      KafilaBooking = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "kafila" &&
      isDomestic === "true"
    ) {
      KafilaBooking = true;
    }

    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "multi-city" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "satkar"
    ) {
      satkarBooking = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "satkar"
    ) {
      satkarBooking = true;
    }

    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "multi-city" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "fbq"
    ) {
      fareBoutiqueBooking = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "fbq"
    ) {
      fareBoutiqueBooking = true;
    }

    if ((this.props.location.state.searchType === "one-way" || this.props.location.state.searchType === "multi-city" || this.props.location.state.searchType === "special-return") && this.props.location.state.data.api_source === "riya_travel" ) {
      riyaBooking = true;
    } else if ( (this.props.location.state.searchType === "round-trip" || this.props.location.state.searchType === "round-trip-complete") && this.props.location.state.data[this.props.location.state.index].api_source === "riya_travel") {
      riyaBooking = true;
    }

    let showPassportIssue = false;
    if (
      (airline_remark &&
        airline_remark !== null &&
        airline_remark.includes("NDC")) ||
      (tripJackBooking && pcs_pid)
    ) {
      showPassportIssue = true;
    }

    let showPassportIssueAndCountry = false;
    if (
      isDomestic === "false" &&
      tboBooking === true &&
      isPassportMandatory === true
    ) {
      showPassportIssueAndCountry = true;
    }
    if (
      isDomestic === "false" &&
      fareBoutiqueBooking === true 
    ) {
      showPassportIssueAndCountry = true;
    }
    if (
      isDomestic === "false" &&
      satkarBooking === true 
    ) {
      showPassportIssueAndCountry = true;
    }

    let showPassportIssueAndCountryForNDC = false;
    if (isDomestic === "false" && tboBooking === true && isPassportMandatory === true && (airline_remark && airline_remark !== null && airline_remark.includes("NDC"))) {
      showPassportIssueAndCountryForNDC = true;
    }

    
    if (isDomestic === "false" && tboBooking === true && isPassportMandatory === true && (airline_code === "AK" || airline_code === "I5" || airline_code === "FD" || airline_code === "D7" || airline_code === "QZ" || airline_code === "QZ2" || airline_code === "IX" || airline_code === "G9" || airline_code === "YY")) {
      showPassportIssueAndCountryForNDC = true;
      showPassportIssueAndCountry = true;
    }

    if (isDomestic === "false" && riyaBooking === true && isPassportMandatory === true ) {
      showPassportIssueAndCountry = true;
      showPassportIssueAndCountryForNDC = true
    }

    let passengers = [];
    let date = getNewDateFormted(
      this.props.searchQuery.segments[0].preferred_departure_time
    );
    let dobmax = null;
    let dobmin = null;
    let valid = null;
    if (type === "adult" && pft !== "SENIOR_CITIZEN") {
      dobmax = newDateFunction(moment(date).subtract(12, "years"));
      valid = function (currentDate) {
        return currentDate.isBefore(dobmax);
      };
      if (
        this.props.fareQuote.response &&
        this.props.fareQuote.response.conditions &&
        this.props.fareQuote.response.conditions.pcs &&
        this.props.fareQuote.response.conditions.dob
      ) {
        if (this.props.fareQuote.response.conditions.pcs.dobe === true) {
          dobRequired = true;
        }
        if (this.props.fareQuote.response.conditions.pcs.dobe === false) {
          dobRequired = false;
        }
      } else if (
        this.props.fareQuote.response &&
        this.props.fareQuote.response.conditions &&
        this.props.fareQuote.response.conditions.dob &&
        this.props.fareQuote.response.conditions.pcs === undefined
      ) {
        if (this.props.fareQuote.response.conditions.dob.adobr === true) {
          dobRequired = true;
        }
        if (this.props.fareQuote.response.conditions.dob.adobr === false) {
          dobRequired = false;
        }
      }
    }
    if (type === "adult" && pft === "SENIOR_CITIZEN") {
      dobmax = newDateFunction(moment(date).subtract(60, "years"));
      valid = function (currentDate) {
        return currentDate.isBefore(dobmax);
      };
      dobRequired = true;
      // if (
      //   this.props.fareQuote.response &&
      //   this.props.fareQuote.response.conditions &&
      //   this.props.fareQuote.response.conditions.pcs &&
      //   this.props.fareQuote.response.conditions.dob
      // ) {
      //   if (this.props.fareQuote.response.conditions.pcs.dobe === true) {
      //     dobRequired = true;
      //   }
      //   if (this.props.fareQuote.response.conditions.pcs.dobe === false) {
      //     dobRequired = false;
      //   }
      // } else if (
      //   this.props.fareQuote.response &&
      //   this.props.fareQuote.response.conditions &&
      //   this.props.fareQuote.response.conditions.dob &&
      //   this.props.fareQuote.response.conditions.pcs === undefined
      // ) {
      //   if (this.props.fareQuote.response.conditions.dob.adobr === true) {
      //     dobRequired = true;
      //   }
      //   if (this.props.fareQuote.response.conditions.dob.adobr === false) {
      //     dobRequired = false;
      //   }
      // }
    } else if (type === "child") {
      dobmax = newDateFunction(moment(date).subtract(2, "years"));
      dobmin = newDateFunction(moment(date).subtract(12, "years"));
      valid = function (current) {
        return current.isAfter(dobmin) && current.isBefore(dobmax);
      };
      if (
        this.props.fareQuote.response &&
        this.props.fareQuote.response.conditions &&
        this.props.fareQuote.response.conditions.pcs
      ) {
        if (this.props.fareQuote.response.conditions.pcs.dobe === true) {
          dobRequired = true;
        }
        if (this.props.fareQuote.response.conditions.pcs.dobe === false) {
          dobRequired = false;
        }
      } else if (
        this.props.fareQuote.response &&
        this.props.fareQuote.response.conditions &&
        this.props.fareQuote.response.conditions.dob &&
        this.props.fareQuote.response.conditions.pcs === undefined
      ) {
        if (this.props.fareQuote.response.conditions.dob.cdobr === true) {
          dobRequired = true;
        }
        if (this.props.fareQuote.response.conditions.dob.cdobr === false) {
          dobRequired = false;
        }
      }
    } else if (type === "infant") {
      dobmin = newDateFunction(moment(date).subtract(2, "years"));
      dobmax = newDateFunction(moment(date).subtract(0, "years"));
      valid = function (current) {
        return current.isAfter(dobmin) && current.isBefore(dobmax);
      };
      if (
        this.props.fareQuote.response &&
        this.props.fareQuote.response.conditions &&
        this.props.fareQuote.response.conditions.pcs
      ) {
        if (this.props.fareQuote.response.conditions.pcs.dobe === true) {
          dobRequired = true;
        }
        if (this.props.fareQuote.response.conditions.pcs.dobe === false) {
          dobRequired = false;
        }
      } else if (
        this.props.fareQuote.response &&
        this.props.fareQuote.response.conditions &&
        this.props.fareQuote.response.conditions.dob &&
        this.props.fareQuote.response.conditions.pcs === undefined
      ) {
        if (this.props.fareQuote.response.conditions.dob.idobr === true) {
          dobRequired = true;
        }
        if (this.props.fareQuote.response.conditions.dob.idobr === false) {
          dobRequired = false;
        }
      }
    }

    let validPassportDate = function (current) {
      return current.isAfter();
    };
    let validPassportIssueDate = function (current) {
      return current.isBefore();
    };

    const years = [...Array(100).keys()].map(
      (i) => i + new Date().getFullYear()
    );
    const Issueyears = [...Array(100).keys()].map(
      (i) => new Date().getFullYear() - i
    );
    const Dobyears = [...Array(100).keys()]
      .map((i) => new Date().getFullYear() - i)
      .filter((year) => year <= 2011);
    const DobChildyears = [...Array(100).keys()]
      .map((i) => new Date().getFullYear() - i)
      .filter((year) => year >= 2011 && year <= new Date().getFullYear() - 2);
    const DobInfantyears = [...Array(3).keys()].map(
      (i) => new Date().getFullYear() - 2 + i
    );

    const months = [
      { value: "01", label: "January" },
      { value: "02", label: "February" },
      { value: "03", label: "March" },
      { value: "04", label: "April" },
      { value: "05", label: "May" },
      { value: "06", label: "June" },
      { value: "07", label: "July" },
      { value: "08", label: "August" },
      { value: "09", label: "September" },
      { value: "10", label: "October" },
      { value: "11", label: "November" },
      { value: "12", label: "December" },
    ];
    const days = [...Array(31).keys()].map((i) => i + 1);

    for (let i = 1; i <= count; i++) {
      passengers.push(
        <div key={type + "" + i} className="review-step">
          <div className="review-step-title">
            <div className="cols pull-left">
              <i className={`icon ${icon}`} />
              <span>Passanger </span>
              <small>({type + " " + i})</small>{" "}
            </div>
            <div className="cols pull-right">
              {/* eslint-disable-next-line */}
              <a
                className="link-col"
                href="javascript:void(0)"
                onClick={() => this.props.handlePopup(type, i)}
              >
                Select Passenger from Customer List
              </a>
            </div>
          </div>
          <div className="review-step-deatil">
            <div className="step-deatil-outer">
              <div className="adult-form">
                <div className="block form-card">
                  <div className="adult-form-outer">
                    <div className="form-row">
                      <div className="col-sm-12 col-lg-3">
                        <div className="form-group">
                          <label className="control-label">
                            Salutation<sup>*</sup>
                          </label>
                          <div className="custom-select-box">
                            <select
                              className="form-control custom-filed"
                              value={
                                values["passenger_title_" + type + "_" + i]
                              }
                              name={"passenger_title_" + type + "_" + i}
                              onChange={(event) => {
                                //AutoFill gender
                                handleChange(event);
                                var index =
                                  event.nativeEvent.target.selectedIndex;
                                this.autofill(
                                  event.nativeEvent.target[index].text,
                                  type,
                                  i
                                );
                                setFieldValue(
                                  "title_name_" + type + "_" + i,
                                  event.nativeEvent.target[index].text
                                );
                              }}
                            >
                              {
                                /* eslint-disable-next-line */
                                this.props.paxTitles.response &&
                                  this.props.paxTitles.response.pax_titles.map(
                                    (item, key) => {
                                      if (
                                        item.pax_type.toLowerCase() === type
                                      ) {
                                        return (
                                          <option
                                            key={key}
                                            value={item.title_id}
                                          >
                                            {item.passenger_title}
                                          </option>
                                        );
                                      }
                                    }
                                  )
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className={`col-sm-12 col-lg-3 ${errors["first_name_" + type + "_" + i] && errors["first_name_" + type + "_" + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                        <div className="form-group">
                          <label className="control-label">
                            First Name <sup>*</sup>
                          </label>
                          <Field
                            className={
                              errors["first_name_" + type + "_" + i] &&
                              touched["first_name_" + type + "_" + i]
                                ? "form-control custom-filed error-shadow"
                                : "form-control custom-filed"
                            }
                            style={{ textTransform: "uppercase" }}
                            type="text"
                            name={"first_name_" + type + "_" + i}
                            onBlur={handleBlur}
                            value={values["first_name_" + type + "_" + i]}
                            maxLength="32"
                          />
                          {errors["first_name_" + type + "_" + i] &&
                            touched["first_name_" + type + "_" + i] && (
                              <span className="error-msg">
                                {errors["first_name_" + type + "_" + i]}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className={`col-sm-12 col-lg-3 ${errors["last_name_" + type + "_" + i] && errors["last_name_" + type + "_" + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                        <div className="form-group">
                          <label className="control-label">
                            Last Name <sup>*</sup>
                          </label>
                          <Field
                            className={
                              errors["last_name_" + type + "_" + i] &&
                              touched["last_name_" + type + "_" + i]
                                ? "form-control custom-filed error-shadow"
                                : "form-control custom-filed"
                            }
                            style={{ textTransform: "uppercase" }}
                            type="text"
                            name={"last_name_" + type + "_" + i}
                            value={values["last_name_" + type + "_" + i]}
                            maxLength="32"
                          />
                          {errors["last_name_" + type + "_" + i] &&
                            touched["last_name_" + type + "_" + i] && (
                              <span className="error-msg">
                                {errors["last_name_" + type + "_" + i]}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className={`col-sm-12 col-lg-3 ${errors["dob_" + type + "_" + i] && errors["dob_" + type + "_" + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                        <div className="form-group">
                          <label className="control-label">
                            Date of Birth{" "}
                            {dobRequired === true ? <sup>*</sup> : ""}
                          </label>
                          <div className="date-picker">
                            <label className="input-group input-addon">
                              {/* <Datetime 
                                                            ref={"dobPicker"+ type + '_' + i}
                                                            value={values['dob_' + type + '_' + i]}
                                                            onChange={(date) => {setTimeout(
                                                                () => this.refs["dobPicker"+ type + '_' + i].closeCalendar(),
                                                                100
                                                            ); setFieldValue('dob_' + type + '_' + i,date)}}
                                                            className={errors['dob_' + type + '_' + i] && touched['dob_' + type + '_' + i] && dobRequired ===true?'form-control custom-filed date error-shadow':'form-control custom-filed date'}
                                                            name={'dob_' + type + '_' + i}
                                                            autoComplete="off"
                                                            timeFormat={false} 
                                                            dateFormat="DD-MM-YYYY"
                                                            isValidDate={ (valid) } 
                                                            closeOnSelect={true}
                                                            inputProps={{readOnly:true}}
                                                        /> 
                                                        <span className="input-group-addon"><i className="fa fa-calendar" /></span>  */}
                              <div className="custom-select-box select-col">
                                <select
                                  className={
                                    errors["dob_" + type + "_" + i] &&
                                    touched["dob_" + type + "_" + i] &&
                                    dobRequired === true
                                      ? "form-control custom-filed date error-shadow"
                                      : "form-control custom-filed date"
                                  }
                                  onChange={(event) => {
                                    this.setState({
                                      selectedDobDay: event.target.value,
                                    });
                                    setFieldValue(
                                      "dobDay_" + type + "_" + i,
                                      event.target.value
                                    );
                                    if (
                                      values["dobYear_" + type + "_" + i] !==
                                        null &&
                                      values["dobMonth_" + type + "_" + i] !==
                                        null
                                    ) {
                                      setFieldValue(
                                        "dob_" + type + "_" + i,
                                        `${
                                          values["dobYear_" + type + "_" + i]
                                        }-${
                                          values["dobMonth_" + type + "_" + i]
                                        }-${parseInt(
                                          event.nativeEvent.target.value,
                                          10
                                        )
                                          .toString()
                                          .padStart(2, "0")}`
                                      );
                                    }
                                  }}
                                  name={"dobDay_" + type + "_" + i}
                                  value={
                                    //this.state.selectedDobDay !== '' ? this.state.selectedDobDay :
                                    values["dobDay_" + type + "_" + i]
                                      ? values["dobDay_" + type + "_" + i]
                                      : values["dob_" + type + "_" + i]
                                      ? new Date(
                                          values["dob_" + type + "_" + i]
                                        ).getDate()
                                      : ""
                                  }
                                >
                                  <option value="" disabled>
                                    Day
                                  </option>
                                  {days.map((day) => (
                                    <option key={day} value={day}>
                                      {day}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="custom-select-box select-col">
                                <select
                                  disabled={
                                    values["dobDay_" + type + "_" + i] === null
                                  }
                                  className={
                                    errors["dob_" + type + "_" + i] &&
                                    touched["dob_" + type + "_" + i] &&
                                    dobRequired === true
                                      ? "form-control custom-filed date error-shadow"
                                      : "form-control custom-filed date"
                                  }
                                  onChange={(event) => {
                                    this.setState({
                                      selectedDobMonth: event.target.value,
                                    });
                                    setFieldValue(
                                      "dobMonth_" + type + "_" + i,
                                      event.target.value
                                    );
                                    if (
                                      values["dobYear_" + type + "_" + i] !==
                                        null &&
                                      values["dobDay_" + type + "_" + i] !==
                                        null
                                    ) {
                                      setFieldValue(
                                        "dob_" + type + "_" + i,
                                        `${
                                          values["dobYear_" + type + "_" + i]
                                        }-${
                                          event.nativeEvent.target.value
                                        }-${parseInt(
                                          values["dobDay_" + type + "_" + i],
                                          10
                                        )
                                          .toString()
                                          .padStart(2, "0")}`
                                      );
                                    }
                                  }}
                                  name={"dobMonth_" + type + "_" + i}
                                  value={
                                    //this.state.selectedDobMonth !== '' ? this.state.selectedDobMonth :
                                    values["dobMonth_" + type + "_" + i]
                                      ? values["dobMonth_" + type + "_" + i]
                                      : values["dob_" + type + "_" + i]
                                      ? months[
                                          new Date(
                                            values["dob_" + type + "_" + i]
                                          ).getMonth() +
                                            1 -
                                            1
                                        ] &&
                                        months[
                                          new Date(
                                            values["dob_" + type + "_" + i]
                                          ).getMonth() +
                                            1 -
                                            1
                                        ].value
                                      : ""
                                  }
                                >
                                  <option value="" disabled>
                                    Month
                                  </option>
                                  {months.map((month, index) => (
                                    <option key={index} value={month.value}>
                                      {month.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="custom-select-box select-col">
                                {type === "adult" && (
                                  <select
                                    disabled={
                                      values["dobMonth_" + type + "_" + i] ===
                                      null
                                    }
                                    className={
                                      errors["dob_" + type + "_" + i] &&
                                      touched["dob_" + type + "_" + i] &&
                                      dobRequired === true
                                        ? "form-control custom-filed date error-shadow"
                                        : "form-control custom-filed date"
                                    }
                                    onChange={(event) => {
                                      this.setState({
                                        selectedDobYear: event.target.value,
                                      });
                                      setFieldValue(
                                        "dobYear_" + type + "_" + i,
                                        event.target.value
                                      );
                                      if (
                                        values["dobMonth_" + type + "_" + i] !==
                                          null &&
                                        values["dobDay_" + type + "_" + i] !==
                                          null
                                      ) {
                                        setFieldValue(
                                          "dob_" + type + "_" + i,
                                          `${event.nativeEvent.target.value}-${
                                            values["dobMonth_" + type + "_" + i]
                                          }-${parseInt(
                                            values["dobDay_" + type + "_" + i],
                                            10
                                          )
                                            .toString()
                                            .padStart(2, "0")}`
                                        );
                                      }
                                    }}
                                    name={"dobYear_" + type + "_" + i}
                                    value={
                                      //this.state.selectedDobYear !== '' ? this.state.selectedDobYear :
                                      values["dobYear_" + type + "_" + i]
                                        ? values["dobYear_" + type + "_" + i]
                                        : values["dob_" + type + "_" + i]
                                        ? new Date(
                                            values["dob_" + type + "_" + i]
                                          ).getFullYear()
                                        : ""
                                    }
                                  >
                                    <option value="" disabled>
                                      Year
                                    </option>
                                    {Dobyears.map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                )}
                                {type === "child" && (
                                  <select
                                    disabled={
                                      values["dobMonth_" + type + "_" + i] ===
                                      null
                                    }
                                    className={
                                      errors["dob_" + type + "_" + i] &&
                                      touched["dob_" + type + "_" + i] &&
                                      dobRequired === true
                                        ? "form-control custom-filed error-shadow"
                                        : "form-control custom-filed"
                                    }
                                    onChange={(event) => {
                                      handleChange(event);
                                      this.setState({
                                        selectedDobYear: event.target.value,
                                      });
                                      setFieldValue(
                                        "dobYear_" + type + "_" + i,
                                        event.target.value
                                      );
                                      if (
                                        values["dobMonth_" + type + "_" + i] !==
                                          null &&
                                        values["dobDay_" + type + "_" + i] !==
                                          null
                                      ) {
                                        setFieldValue(
                                          "dob_" + type + "_" + i,
                                          `${event.nativeEvent.target.value}-${
                                            values["dobMonth_" + type + "_" + i]
                                          }-${parseInt(
                                            values["dobDay_" + type + "_" + i],
                                            10
                                          )
                                            .toString()
                                            .padStart(2, "0")}`
                                        );
                                      }
                                    }}
                                    name={"dobYear_" + type + "_" + i}
                                    value={
                                      //this.state.selectedDobYear !== '' ? this.state.selectedDobYear :
                                      values["dobYear_" + type + "_" + i]
                                        ? values["dobYear_" + type + "_" + i]
                                        : values["dob_" + type + "_" + i]
                                        ? new Date(
                                            values["dob_" + type + "_" + i]
                                          ).getFullYear()
                                        : ""
                                    }
                                  >
                                    <option value="" disabled>
                                      Year
                                    </option>
                                    {DobChildyears.map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                )}
                                {type === "infant" && (
                                  <select
                                    disabled={
                                      values["dobMonth_" + type + "_" + i] ===
                                      null
                                    }
                                    className={
                                      errors["dob_" + type + "_" + i] &&
                                      touched["dob_" + type + "_" + i] &&
                                      dobRequired === true
                                        ? "form-control custom-filed error-shadow"
                                        : "form-control custom-filed"
                                    }
                                    onChange={(event) => {
                                      handleChange(event);
                                      this.setState({
                                        selectedDobYear: event.target.value,
                                      });
                                      setFieldValue(
                                        "dobYear_" + type + "_" + i,
                                        event.target.value
                                      );
                                      if (
                                        values["dobMonth_" + type + "_" + i] !==
                                          null &&
                                        values["dobDay_" + type + "_" + i] !==
                                          null
                                      ) {
                                        setFieldValue(
                                          "dob_" + type + "_" + i,
                                          `${event.nativeEvent.target.value}-${
                                            values["dobMonth_" + type + "_" + i]
                                          }-${parseInt(
                                            values["dobDay_" + type + "_" + i],
                                            10
                                          )
                                            .toString()
                                            .padStart(2, "0")}`
                                        );
                                      }
                                    }}
                                    name={"dobYear_" + type + "_" + i}
                                    value={
                                      //this.state.selectedDobYear !== '' ? this.state.selectedDobYear :
                                      values["dobYear_" + type + "_" + i]
                                        ? values["dobYear_" + type + "_" + i]
                                        : ""
                                    }
                                  >
                                    <option value="" disabled>
                                      Year
                                    </option>
                                    {DobInfantyears.map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              </div>
                            </label>
                          </div>
                          {errors["dob_" + type + "_" + i] &&
                            touched["dob_" + type + "_" + i] &&
                            dobRequired === true && (
                              <span className="error-msg">
                                {errors["dob_" + type + "_" + i]}
                              </span>
                            )}
                        </div>
                      </div>
                      {(tboBooking === true || KafilaBooking === true) &&
                        pft === "SENIOR_CITIZEN" && (
                          <div className={`col-sm-12 col-lg-3 ${errors["senior_citizen_id_" + type + "_" + i] && errors["senior_citizen_id_" + type + "_" + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                            <div className="form-group">
                              <label className="control-label">
                                Enter Any Id No <sup>*</sup>
                              </label>
                              <Field
                                className={
                                  errors[
                                    "senior_citizen_id_" + type + "_" + i
                                  ] &&
                                  touched["senior_citizen_id_" + type + "_" + i]
                                    ? "form-control custom-filed error-shadow"
                                    : "form-control custom-filed"
                                }
                                style={{ textTransform: "uppercase" }}
                                type="text"
                                name={"senior_citizen_id_" + type + "_" + i}
                                onBlur={handleBlur}
                                value={
                                  values["senior_citizen_id_" + type + "_" + i]
                                }
                                maxLength="32"
                              />
                              {errors["senior_citizen_id_" + type + "_" + i] &&
                                touched[
                                  "senior_citizen_id_" + type + "_" + i
                                ] && (
                                  <span className="error-msg">
                                    {
                                      errors[
                                        "senior_citizen_id_" + type + "_" + i
                                      ]
                                    }
                                  </span>
                                )}
                            </div>
                          </div>
                        )}
                      <div className={`col-sm-12 col-lg-3`}>
                        <div className="form-group">
                          <label className="control-label">
                            Gender<sup>*</sup>
                          </label>
                          <div className="custom-select-box">
                            <select
                              className="form-control custom-filed"
                              value={values["gender_" + type + "_" + i]}
                              name={"gender_" + type + "_" + i}
                              onChange={handleChange}
                              disabled
                            >
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {isDomestic === "false" && (
                        <div className={`col-sm-12 col-lg-3`}>
                          <div className="form-group">
                            <label className="control-label">Nationality</label>
                            <div className="custom-select-box">
                              <select
                                value={
                                  values["nationality_" + type + "_" + i] !== ""
                                    ? values["nationality_" + type + "_" + i]
                                    : "Indian"
                                }
                                name={"nationality_" + type + "_" + i}
                                onChange={handleChange}
                                className="form-control custom-filed"
                              >
                                {country_details.response &&
                                  country_details.response.country_details &&
                                  country_details.response.country_details.map(
                                    (item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          value={item.nationality}
                                        >
                                          {item.nationality}
                                        </option>
                                      );
                                    }
                                  )}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className={`col-sm-12 col-lg-3`}>
                        <div className="form-group">
                          <label className="control-label">
                            Frequent Flyer No
                          </label>
                          <Field
                            className="form-control custom-filed"
                            type="text"
                            name={"frequentFlyer_" + type + "_" + i}
                          />
                        </div>
                      </div>
                      <div className={`col-sm-12 col-lg-3 ${errors["ff_airline_" + type + "_" + i] && errors["ff_airline_" + type + "_" + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                        <div className="form-group">
                          <label className="control-label">
                            Frequent Flyer Airline Code
                          </label>
                          <Field
                            className="form-control custom-filed"
                            type="text"
                            name={"ff_airline_" + type + "_" + i}
                          />
                          {errors["ff_airline_" + type + "_" + i] &&
                            touched["ff_airline_" + type + "_" + i] && (
                              <span className="error-msg">
                                {errors["ff_airline_" + type + "_" + i]}
                              </span>
                            )}
                        </div>
                      </div>
                      {(tboBooking === true || KafilaBooking === true) &&
                        pft === "STUDENT" &&
                        studentFareRequired === true && (
                          <div className={`col-sm-12 col-lg-3 ${errors["document_type_" + type + "_" + i] && errors["document_type_" + type + "_" + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                            <div className="form-group">
                              <label className="control-label">
                                Student ID{" "}
                                {studentFareRequired === true ? (
                                  <sup>*</sup>
                                ) : (
                                  ""
                                )}
                              </label>
                              <Field
                                className={
                                  errors["document_type_" + type + "_" + i] &&
                                  touched["document_type_" + type + "_" + i]
                                    ? "form-control custom-filed error-shadow"
                                    : "form-control custom-filed"
                                }
                                type="text"
                                name={"document_type_" + type + "_" + i}
                                value={
                                  values["document_type_" + type + "_" + i]
                                }
                              />
                              {errors["document_type_" + type + "_" + i] &&
                                touched["document_type_" + type + "_" + i] && (
                                  <span className="error-msg">
                                    {errors["document_type_" + type + "_" + i]}
                                  </span>
                                )}
                            </div>
                          </div>
                        )}
                      {isDomestic === "false" && (
                        <React.Fragment>
                          <div className={`col-sm-12 col-lg-3 ${errors["passport_" + type + "_" + i] && errors["passport_" + type + "_" + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                            <div className="form-group">
                             
                              <label className="control-label">
                                Passport No
                                {this.props.passportValidation[type] && (
                                  <sup>*</sup>
                                )}
                              </label>
                              <Field
                                className={
                                  errors["passport_" + type + "_" + i] &&
                                  touched["passport_" + type + "_" + i]
                                    ? "form-control custom-filed error-shadow"
                                    : "form-control custom-filed"
                                }
                                type="text"
                                name={"passport_" + type + "_" + i}
                              />
                              {errors["passport_" + type + "_" + i] &&
                                touched["passport_" + type + "_" + i] && (
                                  <span className="error-msg">
                                    {errors["passport_" + type + "_" + i]}
                                  </span>
                                )}
                            </div>
                          </div>
                          {
                            showPassportIssueAndCountryForNDC === true &&
                            <div className={`col-sm-12 col-lg-3`}>
                              <div className="form-group">
                                <label className="control-label">
                                  Passport Country Code
                                  {/* {
                                                        this.props.passportValidation[type] && <sup>*</sup>
                                                    } */}
                                </label>
                                <div className="custom-select-box">
                                  <select
                                    className={
                                      errors[
                                        "passport_issue_country_code_" +
                                        type +
                                        "_" +
                                        i
                                      ] &&
                                        touched["passport_" + type + "_" + i]
                                        ? "form-control custom-filed error-shadow"
                                        : "form-control custom-filed"
                                    }
                                    value={
                                      values[
                                        "passport_issue_country_code_" +
                                        type +
                                        "_" +
                                        i
                                      ] !== ""
                                        ? values[
                                        "passport_issue_country_code_" +
                                        type +
                                        "_" +
                                        i
                                        ]
                                        : "IN"
                                    }
                                    name={
                                      "passport_issue_country_code_" +
                                      type +
                                      "_" +
                                      i
                                    }
                                    onChange={(event) => {
                                      handleChange(event);
                                      var index =
                                        event.nativeEvent.target
                                          .selectedIndex;
                                      setFieldValue(
                                        "passport_issue_country_code_" +
                                        type +
                                        "_" +
                                        i,
                                        event.nativeEvent.target[index].value
                                      );
                                    }}
                                  >
                                    {/* <option value="">Country Code</option> */}
                                    {country_details.response &&
                                      country_details.response
                                        .country_details &&
                                      country_details.response.country_details.map(
                                        (item, key) => {
                                          return (
                                            <option
                                              key={key}
                                              value={item.short_name}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          }
                          {(showPassportIssue === true ||
                            showPassportIssueAndCountry === true) && (
                            <React.Fragment>
                              <div className={`col-sm-12 col-lg-3 ${errors["passportIssue_" + type + "_" + i] && errors["passportIssue_" + type + "_" + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                                <div className="form-group">
                                  <label className="control-label">
                                    Passport Issue
                                    {this.props.passportValidation[type] && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <div className="date-picker">
                                    <label className="input-group input-addon">
                                      {/* <Datetime 
                                                            ref={"passPicker"+ type + '_' + i}
                                                            value={values['passportIssue_' + type + '_' + i]}
                                                            onChange={(date) => {setTimeout(
                                                                () => this.refs["passPicker"+ type + '_' + i].closeCalendar(),
                                                                100
                                                            ); setFieldValue('passportIssue_' + type + '_' + i,date)}}
                                                            className={errors['passportIssue_' + type + '_' + i] && touched['passportIssue_' + type + '_' + i]?'form-control custom-filed date error-shadow':'form-control custom-filed date'}
                                                            name={'passportIssue_' + type + '_' + i}
                                                            autoComplete="off"
                                                            timeFormat={false} 
                                                            dateFormat="DD-MM-YYYY"
                                                            isValidDate={(validPassportIssueDate) }
                                                            closeOnSelect={true}
                                                            inputProps={{readOnly:true}} 
                                                        /> 
                                                        <span className="input-group-addon"><i className="fa fa-calendar" /></span> */}
                                      <div className="custom-select-box select-col">
                                        <select
                                          className={
                                            errors[
                                              "passportIssue_" + type + "_" + i
                                            ] &&
                                            touched[
                                              "passportIssue_" + type + "_" + i
                                            ]
                                              ? "form-control custom-filed error-shadow"
                                              : "form-control custom-filed"
                                          }
                                          onChange={(event) => {
                                            handleChange(event);
                                            this.setState({
                                              selectedIssueDay:
                                                event.target.value,
                                            });
                                            setFieldValue(
                                              "passporDaytIssue_" +
                                                type +
                                                "_" +
                                                i,
                                              event.target.value
                                            );
                                            if (
                                              values[
                                                "passporYeartIssue_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ] !== null &&
                                              values[
                                                "passporMonthtIssue_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ] !== null
                                            ) {
                                              setFieldValue(
                                                "passportIssue_" +
                                                  type +
                                                  "_" +
                                                  i,
                                                `${
                                                  values[
                                                    "passporYeartIssue_" +
                                                      type +
                                                      "_" +
                                                      i
                                                  ]
                                                }-${
                                                  values[
                                                    "passporMonthtIssue_" +
                                                      type +
                                                      "_" +
                                                      i
                                                  ]
                                                }-${parseInt(
                                                  event.nativeEvent.target
                                                    .value,
                                                  10
                                                )
                                                  .toString()
                                                  .padStart(2, "0")}`
                                              );
                                            }
                                          }}
                                          name={
                                            "passporDaytIssue_" + type + "_" + i
                                          }
                                          value={
                                            //this.state.selectedIssueDay !== '' ? this.state.selectedIssueDay :
                                            values[
                                              "passporDaytIssue_" +
                                                type +
                                                "_" +
                                                i
                                            ]
                                              ? values[
                                                  "passporDaytIssue_" +
                                                    type +
                                                    "_" +
                                                    i
                                                ]
                                              : ""
                                          }
                                        >
                                          <option value="" disabled>
                                            Day
                                          </option>
                                          {days.map((day) => (
                                            <option key={day} value={day}>
                                              {day}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="custom-select-box select-col">
                                        <select
                                          disabled={
                                            values[
                                              "passporDaytIssue_" +
                                                type +
                                                "_" +
                                                i
                                            ] === null
                                          }
                                          className={
                                            errors[
                                              "passportIssue_" + type + "_" + i
                                            ] &&
                                            touched[
                                              "passportIssue_" + type + "_" + i
                                            ]
                                              ? "form-control custom-filed error-shadow"
                                              : "form-control custom-filed"
                                          }
                                          onChange={(event) => {
                                            handleChange(event);
                                            this.setState({
                                              selectedIssueMonth:
                                                event.target.value,
                                            });
                                            setFieldValue(
                                              "passporMonthtIssue_" +
                                                type +
                                                "_" +
                                                i,
                                              event.target.value
                                            );
                                            if (
                                              values[
                                                "passporYeartIssue_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ] !== null &&
                                              values[
                                                "passporDaytIssue_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ] !== null
                                            ) {
                                              setFieldValue(
                                                "passportIssue_" +
                                                  type +
                                                  "_" +
                                                  i,
                                                `${
                                                  values[
                                                    "passporYeartIssue_" +
                                                      type +
                                                      "_" +
                                                      i
                                                  ]
                                                }-${
                                                  event.nativeEvent.target.value
                                                }-${parseInt(
                                                  values[
                                                    "passporDaytIssue_" +
                                                      type +
                                                      "_" +
                                                      i
                                                  ],
                                                  10
                                                )
                                                  .toString()
                                                  .padStart(2, "0")}`
                                              );
                                            }
                                          }}
                                          name={
                                            "passporMonthtIssue_" +
                                            type +
                                            "_" +
                                            i
                                          }
                                          value={
                                            //this.state.selectedIssueMonth !== '' ? this.state.selectedIssueMonth :
                                            values[
                                              "passporMonthtIssue_" +
                                                type +
                                                "_" +
                                                i
                                            ]
                                              ? values[
                                                  "passporMonthtIssue_" +
                                                    type +
                                                    "_" +
                                                    i
                                                ]
                                              : ""
                                          }
                                        >
                                          <option value="" disabled>
                                            Month
                                          </option>
                                          {months.map((month, index) => (
                                            <option
                                              key={index}
                                              value={month.value}
                                            >
                                              {month.label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="custom-select-box select-col">
                                        <select
                                          disabled={
                                            values[
                                              "passporMonthtIssue_" +
                                                type +
                                                "_" +
                                                i
                                            ] === null
                                          }
                                          className={
                                            errors[
                                              "passportIssue_" + type + "_" + i
                                            ] &&
                                            touched[
                                              "passportIssue_" + type + "_" + i
                                            ]
                                              ? "form-control custom-filed error-shadow"
                                              : "form-control custom-filed"
                                          }
                                          onChange={(event) => {
                                            handleChange(event);
                                            this.setState({
                                              selectedIssueYear:
                                                event.target.value,
                                            });
                                            setFieldValue(
                                              "passporYeartIssue_" +
                                                type +
                                                "_" +
                                                i,
                                              event.target.value
                                            );
                                            if (
                                              values[
                                                "passporMonthtIssue_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ] !== null &&
                                              values[
                                                "passporDaytIssue_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ] !== null
                                            ) {
                                              setFieldValue(
                                                "passportIssue_" +
                                                  type +
                                                  "_" +
                                                  i,
                                                `${
                                                  event.nativeEvent.target.value
                                                }-${
                                                  values[
                                                    "passporMonthtIssue_" +
                                                      type +
                                                      "_" +
                                                      i
                                                  ]
                                                }-${parseInt(
                                                  values[
                                                    "passporDaytIssue_" +
                                                      type +
                                                      "_" +
                                                      i
                                                  ],
                                                  10
                                                )
                                                  .toString()
                                                  .padStart(2, "0")}`
                                              );
                                            }
                                          }}
                                          name={
                                            "passporYeartIssue_" +
                                            type +
                                            "_" +
                                            i
                                          }
                                          value={
                                            //this.state.selectedIssueYear !== '' ? this.state.selectedIssueYear :
                                            values[
                                              "passporYeartIssue_" +
                                                type +
                                                "_" +
                                                i
                                            ]
                                              ? values[
                                                  "passporYeartIssue_" +
                                                    type +
                                                    "_" +
                                                    i
                                                ]
                                              : ""
                                          }
                                        >
                                          <option value="" disabled>
                                            Year
                                          </option>
                                          {Issueyears.map((year) => (
                                            <option key={year} value={year}>
                                              {year}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </label>
                                  </div>
                                  {errors["passportIssue_" + type + "_" + i] &&
                                    touched[
                                      "passportIssue_" + type + "_" + i
                                    ] && (
                                      <span className="error-msg">
                                        {
                                          errors[
                                            "passportIssue_" + type + "_" + i
                                          ]
                                        }
                                      </span>
                                    )}
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                          <div className={`col-sm-12 col-lg-3 ${errors["passportExpiry_" + type + "_" + i] && errors["passportExpiry_" + type + "_" + i].split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                            <div className="form-group">
                              <label className="control-label">
                                Passport Expiry
                                {this.props.passportValidation[type] && (
                                  <sup>*</sup>
                                )}
                              </label>

                              <div className="date-picker">
                                <label className="input-group input-addon">
                                  {/* <Datetime 
                                                            ref={"passExpPicker"+ type + '_' + i}
                                                            value={values['passportExpiry_' + type + '_' + i]}
                                                            onChange={(date) => {setTimeout(
                                                                () => this.refs["passExpPicker"+ type + '_' + i].closeCalendar(),
                                                                100
                                                            ); setFieldValue('passportExpiry_' + type + '_' + i,date)}}
                                                            className={errors['passportExpiry_' + type + '_' + i] && touched['passportExpiry_' + type + '_' + i]?'form-control custom-filed date error-shadow':'form-control custom-filed date'}
                                                            name={'passportExpiry_' + type + '_' + i}
                                                            autoComplete="off"
                                                            timeFormat={false} 
                                                            dateFormat="DD-MM-YYYY"
                                                            isValidDate={ (validPassportDate) }
                                                            closeOnSelect={true}
                                                            inputProps={{readOnly:true}} 
                                                        /> 
                                                        <span className="input-group-addon"><i className="fa fa-calendar" /></span> */}
                                  <div className="custom-select-box select-col">
                                    <select
                                      className={
                                        errors[
                                          "passportExpiry_" + type + "_" + i
                                        ] &&
                                        touched[
                                          "passportExpiry_" + type + "_" + i
                                        ]
                                          ? "form-control custom-filed error-shadow"
                                          : "form-control custom-filed"
                                      }
                                      onChange={(event) => {
                                        handleChange(event);
                                        this.setState({
                                          selectedDay: event.target.value,
                                        });
                                        setFieldValue(
                                          "passportDayExpiry_" + type + "_" + i,
                                          event.target.value
                                        );
                                        if (
                                          values[
                                            "passportYearExpiry_" +
                                              type +
                                              "_" +
                                              i
                                          ] !== null &&
                                          values[
                                            "passportMonthExpiry_" +
                                              type +
                                              "_" +
                                              i
                                          ] !== null
                                        ) {
                                          setFieldValue(
                                            "passportExpiry_" + type + "_" + i,
                                            `${
                                              values[
                                                "passportYearExpiry_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ]
                                            }-${
                                              values[
                                                "passportMonthExpiry_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ]
                                            }-${parseInt(
                                              event.nativeEvent.target.value,
                                              10
                                            )
                                              .toString()
                                              .padStart(2, "0")}`
                                          );
                                        }
                                      }}
                                      name={
                                        "passportDayExpiry_" + type + "_" + i
                                      }
                                      value={
                                        //this.state.selectedDay !== '' ? this.state.selectedDay :
                                        values[
                                          "passportDayExpiry_" + type + "_" + i
                                        ]
                                          ? values[
                                              "passportDayExpiry_" +
                                                type +
                                                "_" +
                                                i
                                            ]
                                          : ""
                                      }
                                    >
                                      <option value="" disabled>
                                        Day
                                      </option>
                                      {days.map((day) => (
                                        <option key={day} value={day}>
                                          {day}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="custom-select-box select-col">
                                    <select
                                      disabled={
                                        values[
                                          "passportDayExpiry_" + type + "_" + i
                                        ] === null
                                      }
                                      className={
                                        errors[
                                          "passportExpiry_" + type + "_" + i
                                        ] &&
                                        touched[
                                          "passportExpiry_" + type + "_" + i
                                        ]
                                          ? "form-control custom-filed error-shadow"
                                          : "form-control custom-filed"
                                      }
                                      onChange={(event) => {
                                        handleChange(event);
                                        this.setState({
                                          selectedMonth: event.target.value,
                                        });
                                        setFieldValue(
                                          "passportMonthExpiry_" +
                                            type +
                                            "_" +
                                            i,
                                          event.target.value
                                        );
                                        if (
                                          values[
                                            "passportYearExpiry_" +
                                              type +
                                              "_" +
                                              i
                                          ] !== null &&
                                          values[
                                            "passportDayExpiry_" +
                                              type +
                                              "_" +
                                              i
                                          ] !== null
                                        ) {
                                          setFieldValue(
                                            "passportExpiry_" + type + "_" + i,
                                            `${
                                              values[
                                                "passportYearExpiry_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ]
                                            }-${
                                              event.nativeEvent.target.value
                                            }-${parseInt(
                                              values[
                                                "passportDayExpiry_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ],
                                              10
                                            )
                                              .toString()
                                              .padStart(2, "0")}`
                                          );
                                        }
                                      }}
                                      name={
                                        "passportMonthExpiry_" + type + "_" + i
                                      }
                                      value={
                                        //this.state.selectedMonth !== '' ? this.state.selectedMonth :
                                        values[
                                          "passportMonthExpiry_" +
                                            type +
                                            "_" +
                                            i
                                        ]
                                          ? values[
                                              "passportMonthExpiry_" +
                                                type +
                                                "_" +
                                                i
                                            ]
                                          : ""
                                      }
                                    >
                                      <option value="" disabled>
                                        Month
                                      </option>
                                      {months.map((month, index) => (
                                        <option key={index} value={month.value}>
                                          {month.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="custom-select-box select-col">
                                    <select
                                      disabled={
                                        values[
                                          "passportMonthExpiry_" +
                                            type +
                                            "_" +
                                            i
                                        ] === null
                                      }
                                      className={
                                        errors[
                                          "passportExpiry_" + type + "_" + i
                                        ] &&
                                        touched[
                                          "passportExpiry_" + type + "_" + i
                                        ]
                                          ? "form-control custom-filed error-shadow"
                                          : "form-control custom-filed"
                                      }
                                      onChange={(event) => {
                                        handleChange(event);
                                        this.setState({
                                          selectedYear: event.target.value,
                                        });
                                        setFieldValue(
                                          "passportYearExpiry_" +
                                            type +
                                            "_" +
                                            i,
                                          event.target.value
                                        );
                                        if (
                                          values[
                                            "passportMonthExpiry_" +
                                              type +
                                              "_" +
                                              i
                                          ] !== null &&
                                          values[
                                            "passportDayExpiry_" +
                                              type +
                                              "_" +
                                              i
                                          ] !== null
                                        ) {
                                          setFieldValue(
                                            "passportExpiry_" + type + "_" + i,
                                            `${
                                              event.nativeEvent.target.value
                                            }-${
                                              values[
                                                "passportMonthExpiry_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ]
                                            }-${parseInt(
                                              values[
                                                "passportDayExpiry_" +
                                                  type +
                                                  "_" +
                                                  i
                                              ],
                                              10
                                            )
                                              .toString()
                                              .padStart(2, "0")}`
                                          );
                                        }
                                      }}
                                      name={
                                        "passportYearExpiry_" + type + "_" + i
                                      }
                                      value={
                                        //this.state.selectedYear !== '' ? this.state.selectedYear :
                                        values[
                                          "passportYearExpiry_" + type + "_" + i
                                        ]
                                          ? values[
                                              "passportYearExpiry_" +
                                                type +
                                                "_" +
                                                i
                                            ]
                                          : ""
                                      }
                                    >
                                      <option value="" disabled>
                                        Year
                                      </option>
                                      {years.map((year) => (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </label>
                              </div>
                              {errors["passportExpiry_" + type + "_" + i] &&
                                touched["passportExpiry_" + type + "_" + i] && (
                                  <span className="error-msg">
                                    {errors["passportExpiry_" + type + "_" + i]}
                                  </span>
                                )}
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  {/* ssr round trip kafila */}
                  {this.props.location.state.index === 1 &&
                  KafilaBooking &&
                  this.props.ssrKafilaDetailsRound.response &&
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.searchType !== "one-way" &&
                  !tripJackBooking ? (
                    <div
                      className={
                        this.state.showSSr.length === 0
                          ? "ssr-detail show-ssr"
                          : "ssr-detail"
                      }
                    >
                      <div className="adult-title">
                        <span>SSR Details</span>
                        {(this.state.showSSr.length === 0 ||
                          !this.state.showSSr.includes(type + "_" + i)) && (
                          /* eslint-disable-next-line */
                          <a
                            className="show-hide-right"
                            href="javascript:void(0);"
                            onClick={() => this.openSsr(type + "_" + i, "add")}
                          >
                            <span>
                              <i className="icon icon-arrow-down"></i> Add Baggage & Meal
                            </span>
                          </a>
                        )}
                        {this.state.showSSr.includes(type + "_" + i) && (
                          /* eslint-disable-next-line */
                          <a
                            className="show-hide-right"
                            href="javascript:void(0);"
                            onClick={() =>
                              this.openSsr(type + "_" + i, "remove")
                            }
                          >
                            <span>
                              <i className="icon icon-arrow-up"></i>Hide Baggage & Meal
                            </span>
                          </a>
                        )}
                      </div>

                      <div
                        className="adult-form-outer"
                        style={{
                          display: this.state.showSSr.includes(type + "_" + i)
                            ? "block"
                            : "none",
                        }}
                      >
                        <div className="form-row">
                          {(fareQuote.loading || ssrKafilaDetails.loading) && (
                            <div
                              className="loader ssr-loader relative-loader blue-ring"
                              style={{ display: "block" }}
                            >
                              <div className="loader-content">
                                <div className="loader-ring blue-ring">
                                  {" "}
                                  <span></span>{" "}
                                </div>
                              </div>
                            </div>
                          )}
                          {!ssrKafilaDetailsRound.loading &&
                            ssrKafilaDetailsRound.error && (
                              <React.Fragment>
                                <div>No SSR found</div>
                              </React.Fragment>
                            )}
                          {/*----------done-----------*/}
                          {!ssrKafilaDetailsRound.loading &&
                            ssrKafilaDetailsRound.response &&
                            ssrKafilaDetailsRound.response.result &&
                            ssrKafilaDetailsRound.response.result
                              .meal_dynamic &&
                            ssrKafilaDetailsRound.response.result.meal_dynamic
                              .length > 0 &&
                            ssrKafilaDetailsRound.response.result.meal_dynamic.map(
                              (itemOuter, indexOuter) => {
                                return Object.keys(itemOuter).map(
                                  (objectKeys, objectIndex) => {
                                    return (
                                      <React.Fragment key={objectIndex}>
                                        <div className="col-sm-12 col-lg-3">
                                          <div className="form-group">
                                            <label className="control-label">
                                              {objectKeys.replace("_", "-")}
                                            </label>
                                            <label className="control-label">
                                              MEAL PREFERENCES
                                            </label>
                                            <div className="custom-select-box">
                                              <select
                                                className="form-control custom-filed"
                                                onChange={(event) => {
                                                  let index =
                                                    event.target.selectedIndex;
                                                  let el =
                                                    event.target.childNodes[
                                                      index
                                                    ];
                                                  setFieldValue(
                                                    "meal_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i,
                                                    el.getAttribute("id")
                                                  );
                                                  setFieldValue(
                                                    "meal_val_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i,
                                                    event.target.value
                                                  );
                                                  this.props.getAdditionalCharges(
                                                    "meal",
                                                    type +
                                                      "_" +
                                                      i +
                                                      indexOuter +
                                                      objectIndex,
                                                    el.getAttribute("id"),
                                                    event.target.value
                                                  );
                                                }}
                                                value={
                                                  values[
                                                    "meal_val_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i
                                                  ]
                                                }
                                              >
                                                <option value="0" id="0">
                                                  No Preferences
                                                </option>
                                                {itemOuter[objectKeys].map(
                                                  (item, key) => {
                                                    return (
                                                      <option
                                                        id={item.price}
                                                        key={key}
                                                        value={item.code}
                                                      >
                                                        {(item.airline_description !==
                                                        ""
                                                          ? item.airline_description
                                                          : item.code) +
                                                          " - Rs." +
                                                          currencyFormat(
                                                            item.price
                                                          )}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                            </div>
                                            <span className="select-items">
                                              Meal Type:{" "}
                                              {
                                                values[
                                                  "meal_val_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                            <span className="select-items">
                                              Meal Charges: Rs.{" "}
                                              {
                                                values[
                                                  "meal_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    );
                                  }
                                );
                              }
                            )}
                          {/*----------done-----------*/}
                          {/*----------done-----------*/}
                          {!ssrKafilaDetailsRound.loading &&
                            ssrKafilaDetailsRound.response &&
                            ssrKafilaDetailsRound.response.result &&
                            ssrKafilaDetailsRound.response.result.meal &&
                            ssrKafilaDetailsRound.response.result.meal.length >
                              0 && (
                              <React.Fragment>
                                <div className="col-sm-12 col-lg-3">
                                  <div className="form-group">
                                    <label className="control-label">
                                      ABCDEF
                                    </label>
                                    <label className="control-label">
                                      MEAL PREFERENCES
                                    </label>
                                    <div className="custom-select-box">
                                      <select
                                        className="form-control custom-filed"
                                        onChange={(event) => {
                                          setFieldValue(
                                            "gds_meal_" + type + "_" + i,
                                            event.target.value
                                          );
                                          //  this.props.getAdditionalCharges('meal',type + '_' + i,event.target.value);
                                        }}
                                      >
                                        <option value="0">
                                          No Preferences
                                        </option>
                                        {ssrKafilaDetailsRound.response.result.meal.map(
                                          (item, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={item.code}
                                              >
                                                {item.code +
                                                  " - " +
                                                  item.description}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                    <span className="select-items">
                                      Meal Type:{" "}
                                      {values["gds_meal_" + type + "_" + i]}
                                    </span>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                          {/*----------done-----------*/}
                          {/*----------done-----seat preference------*/}
                          {!ssrKafilaDetailsRound.loading &&
                            type !== "infant" &&
                            this.props.fareQuote.response &&
                            !this.props.fareQuote.response.results.is_lcc &&
                            ssrKafilaDetailsRound.response &&
                            ssrKafilaDetailsRound.response.result &&
                            ssrKafilaDetailsRound.response.result
                              .seat_preference &&
                            ssrKafilaDetailsRound.response.result
                              .seat_preference.length > 0 && (
                              <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                  <label
                                    className="control-label"
                                    style={{ visibility: "hidden" }}
                                  >
                                    ABCDEF
                                  </label>
                                  <label className="control-label">
                                    SEAT PREFERENCES
                                  </label>
                                  <div className="custom-select-box">
                                    <select
                                      className="form-control custom-filed"
                                      onChange={(event) => {
                                        setFieldValue(
                                          "gds_seat_" + type + "_" + i,
                                          event.target.value
                                        );
                                      }}
                                    >
                                      <option value="0">No Preferences</option>
                                      {ssrKafilaDetailsRound.response.result
                                        .seat_preference &&
                                        ssrKafilaDetailsRound.response.result
                                          .seat_preference.length > 0 &&
                                        ssrKafilaDetailsRound.response.result.seat_preference.map(
                                          (item, index) => {
                                            if (item && item.length) {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.code}
                                                >
                                                  {item.description}
                                                </option>
                                              );
                                            } else {
                                              return false;
                                            }
                                          }
                                        )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}
                          {/*----------done--baggage---------*/}

                          {!ssrKafilaDetailsRound.loading &&
                            type !== "infant" &&
                            ssrKafilaDetailsRound.response &&
                            ssrKafilaDetailsRound.response.baggage &&
                            ssrKafilaDetailsRound.response.baggage.length > 0 &&
                            ssrKafilaDetailsRound.response.baggage.map(
                              (itemOuter, indexOuter) => {
                                return Object.keys(itemOuter).map(
                                  (objectKeys, objectIndex) => {
                                    return (
                                      <React.Fragment key={objectIndex}>
                                        <div className="col-sm-12 col-lg-3">
                                          <div className="form-group">
                                            <label className="control-label">
                                              {objectKeys.replace("_", "-")}
                                            </label>
                                            <label className="control-label">
                                              Baggage
                                            </label>
                                            <div className="custom-select-box">
                                              <select
                                                className="form-control custom-filed"
                                                name={
                                                  "baggage_" +
                                                  type +
                                                  indexOuter +
                                                  objectIndex +
                                                  "_" +
                                                  i
                                                }
                                                disabled={indexOuter && indexOuter !== 0}
                                                onChange={(event) => {
                                                  let index =
                                                    event.target.selectedIndex;
                                                  let el =
                                                    event.target.childNodes[
                                                      index
                                                    ];
                                                  let data = ssrKafilaDetailsRound.response && ssrKafilaDetailsRound.response.baggage && ssrKafilaDetailsRound.response.baggage;
                                                  for (let j = 0; j < data.length; j++) {
                                                    if (j !== 0) {
                                                      setFieldValue("baggage_" + type + j + objectIndex + "_" + i, 0);
                                                      setFieldValue("baggage_kg_" + type + j + objectIndex + "_" + i, el.getAttribute("id"));
                                                      this.props.getAdditionalCharges("baggage", type + j + objectIndex + "_" + i, 0, el.getAttribute("id"));
                                                    } else {
                                                      setFieldValue("baggage_" + type + j + objectIndex + "_" + i, event.target.value);
                                                      setFieldValue("baggage_kg_" + type + j + objectIndex + "_" + i, el.getAttribute("id"));
                                                      this.props.getAdditionalCharges("baggage", type + j + objectIndex + "_" + i, event.target.value, el.getAttribute("id"));
                                                    }
                                                  }
                                                }}
                                                value={
                                                  values[
                                                    "baggage_" +
                                                      type +
                                                      objectIndex +
                                                      0 +
                                                      "_" +
                                                      i
                                                  ]
                                                }
                                              >
                                                <option value="0.0" disabled={indexOuter !== 0 && values["baggage_kg_" + type + indexOuter + 0 + "_" + i]}>
                                                  {this.props.location.state
                                                    .data.api_source ===
                                                  "kafila"
                                                    ? "No Extra Baggage"
                                                    : "No Preferences"}
                                                </option>
                                                {itemOuter[objectKeys]
                                                  .sort((a, b) => {
                                                    let x = a.price;
                                                    let y = b.price;
                                                    if (x > y) {
                                                      return 1;
                                                    } else {
                                                      return -1;
                                                    }
                                                  })
                                                  .map((item, key) => {
                                                    if (item.weight)
                                                      return (
                                                        <option
                                                          id={item.weight}
                                                          value={item.price}
                                                          key={key}
                                                          disabled={indexOuter !== 0}
                                                        >
                                                          {item.weight +
                                                            " - Rs." +
                                                            currencyFormat(
                                                              item.price
                                                            ) +
                                                            " " +
                                                            (item.text
                                                              ? ""
                                                              : "")}
                                                        </option>
                                                      );
                                                    else return false;
                                                  })}
                                              </select>
                                            </div>
                                            <span className="select-items">
                                              Baggage Weight :{" "}
                                              {
                                                values[
                                                  "baggage_kg_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }{" "}
                                              Kg
                                            </span>
                                            <span className="select-items">
                                              Baggage Charges : Rs.{" "}
                                              {
                                                values[
                                                  "baggage_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    );
                                  }
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* ssr one way kafila */}

                  {KafilaBooking &&
                  this.props.location.state.index !== 1 &&
                  this.props.ssrKafilaDetails &&
                  this.props.ssrKafilaDetails.response &&
                  !tripJackBooking ? (
                    <div
                      className={
                        this.state.showSSr.length === 0
                          ? "ssr-detail show-ssr"
                          : "ssr-detail"
                      }
                    >
                      <div className="adult-title">
                        <span>SSR Details</span>
                        {(this.state.showSSr.length === 0 ||
                          !this.state.showSSr.includes(type + "_" + i)) && (
                          /* eslint-disable-next-line */
                          <a
                            className="show-hide-right"
                            href="javascript:void(0);"
                            onClick={() => this.openSsr(type + "_" + i, "add")}
                          >
                            <span>
                              <i className="icon icon-arrow-down"></i> Add Baggage & Meal
                            </span>
                          </a>
                        )}
                        {this.state.showSSr.includes(type + "_" + i) && (
                          /* eslint-disable-next-line */
                          <a
                            className="show-hide-right"
                            href="javascript:void(0);"
                            onClick={() =>
                              this.openSsr(type + "_" + i, "remove")
                            }
                          >
                            <span>
                              <i className="icon icon-arrow-up"></i>Hide Baggage & Meal
                            </span>
                          </a>
                        )}
                      </div>

                      <div
                        className="adult-form-outer"
                        style={{
                          display: this.state.showSSr.includes(type + "_" + i)
                            ? "block"
                            : "none",
                        }}
                      >
                        <div className="form-row">
                          {(fareQuote.loading || ssrKafilaDetails.loading) && (
                            <div
                              className="loader ssr-loader relative-loader blue-ring"
                              style={{ display: "block" }}
                            >
                              <div className="loader-content">
                                <div className="loader-ring blue-ring">
                                  {" "}
                                  <span></span>{" "}
                                </div>
                              </div>
                            </div>
                          )}
                          {!ssrKafilaDetails.loading &&
                            ssrKafilaDetails.error && (
                              <React.Fragment>
                                <div>No SSR found</div>
                              </React.Fragment>
                            )}
                          {/*----------done-----------*/}
                          {!ssrKafilaDetails.loading &&
                            ssrKafilaDetails.response &&
                            ssrKafilaDetails.response.result &&
                            ssrKafilaDetails.response.result.meal_dynamic &&
                            ssrKafilaDetails.response.result.meal_dynamic
                              .length > 0 &&
                            ssrKafilaDetails.response.result.meal_dynamic.map(
                              (itemOuter, indexOuter) => {
                                return Object.keys(itemOuter).map(
                                  (objectKeys, objectIndex) => {
                                    return (
                                      <React.Fragment key={objectIndex}>
                                        <div className="col-sm-12 col-lg-3">
                                          <div className="form-group">
                                            <label className="control-label">
                                              {objectKeys.replace("_", "-")}
                                            </label>
                                            <label className="control-label">
                                              MEAL PREFERENCES
                                            </label>
                                            <div className="custom-select-box">
                                              <select
                                                className="form-control custom-filed"
                                                onChange={(event) => {
                                                  let index =
                                                    event.target.selectedIndex;
                                                  let el =
                                                    event.target.childNodes[
                                                      index
                                                    ];
                                                  setFieldValue(
                                                    "meal_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i,
                                                    el.getAttribute("id")
                                                  );
                                                  setFieldValue(
                                                    "meal_val_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i,
                                                    event.target.value
                                                  );
                                                  this.props.getAdditionalCharges(
                                                    "meal",
                                                    type +
                                                      "_" +
                                                      i +
                                                      indexOuter +
                                                      objectIndex,
                                                    el.getAttribute("id"),
                                                    event.target.value
                                                  );
                                                }}
                                                value={
                                                  values[
                                                    "meal_val_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i
                                                  ]
                                                }
                                              >
                                                <option value="0" id="0">
                                                  No Preferences
                                                </option>
                                                {itemOuter[objectKeys].map(
                                                  (item, key) => {
                                                    return (
                                                      <option
                                                        id={item.price}
                                                        key={key}
                                                        value={item.code}
                                                      >
                                                        {(item.airline_description !==
                                                        ""
                                                          ? item.airline_description
                                                          : item.code) +
                                                          " - Rs." +
                                                          currencyFormat(
                                                            item.price
                                                          )}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                            </div>
                                            <span className="select-items">
                                              Meal Type:{" "}
                                              {
                                                values[
                                                  "meal_val_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                            <span className="select-items">
                                              Meal Charges: Rs.{" "}
                                              {
                                                values[
                                                  "meal_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    );
                                  }
                                );
                              }
                            )}
                          {/*----------done-----------*/}
                          {/*----------done-----------*/}
                          {!ssrKafilaDetails.loading &&
                            ssrKafilaDetails.response &&
                            ssrKafilaDetails.response.result &&
                            ssrKafilaDetails.response.result.meal &&
                            ssrKafilaDetails.response.result.meal.length >
                              0 && (
                              <React.Fragment>
                                <div className="col-sm-12 col-lg-3">
                                  <div className="form-group">
                                    <label
                                      className="control-label"
                                      style={{ visibility: "hidden" }}
                                    >
                                      ABCDEF
                                    </label>
                                    <label className="control-label">
                                      MEAL PREFERENCES
                                    </label>
                                    <div className="custom-select-box">
                                      <select
                                        className="form-control custom-filed"
                                        onChange={(event) => {
                                          setFieldValue(
                                            "gds_meal_" + type + "_" + i,
                                            event.target.value
                                          );
                                          //  this.props.getAdditionalCharges('meal',type + '_' + i,event.target.value);
                                        }}
                                      >
                                        <option value="0">
                                          No Preferences
                                        </option>
                                        {ssrKafilaDetails.response.result.meal.map(
                                          (item, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={item.code}
                                              >
                                                {item.code +
                                                  " - " +
                                                  item.description}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                    <span className="select-items">
                                      Meal Type:{" "}
                                      {values["gds_meal_" + type + "_" + i]}
                                    </span>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                          {/*----------done-----------*/}
                          {/*----------done------seat preference-----*/}
                          {!ssrKafilaDetails.loading &&
                            type !== "infant" &&
                            this.props.fareQuote.response &&
                            !this.props.fareQuote.response.results.is_lcc &&
                            ssrKafilaDetails.response &&
                            ssrKafilaDetails.response.result &&
                            ssrKafilaDetails.response.result.seat_preference &&
                            ssrKafilaDetails.response.result.seat_preference
                              .length > 0 && (
                              <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                  <label
                                    className="control-label"
                                    style={{ visibility: "hidden" }}
                                  >
                                    ABCDEF
                                  </label>
                                  <label className="control-label">
                                    SEAT PREFERENCES
                                  </label>
                                  <div className="custom-select-box">
                                    <select
                                      className="form-control custom-filed"
                                      onChange={(event) => {
                                        setFieldValue(
                                          "gds_seat_" + type + "_" + i,
                                          event.target.value
                                        );
                                      }}
                                    >
                                      <option value="0">No Preferences</option>
                                      {ssrKafilaDetails.response.result.seat_preference.map(
                                        (item, index) => {
                                          if (item && item.length) {
                                            return (
                                              <option
                                                key={index}
                                                value={item.code}
                                              >
                                                {item.description}
                                              </option>
                                            );
                                          } else {
                                            return false;
                                          }
                                        }
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}
                          {/*----------done-- baggage---------*/}

                          {!ssrKafilaDetails.loading && type !== "infant" && ssrKafilaDetails.response && ssrKafilaDetails.response.baggage && ssrKafilaDetails.response.baggage.length > 0 &&
                            ssrKafilaDetails.response.baggage.map((itemOuter, indexOuter) => {
                              return Object.keys(itemOuter).map((objectKeys, objectIndex) => {
                                return (
                                  <React.Fragment key={objectIndex}>
                                    <div className="col-sm-12 col-lg-3">
                                      <div className="form-group">
                                        <label className="control-label">
                                          {objectKeys.replace("_", "-")}
                                        </label>
                                        <label className="control-label">
                                          Baggage
                                        </label>
                                        <div className="custom-select-box">
                                          <select
                                            className="form-control custom-filed"
                                            name={"baggage_" + type + indexOuter + objectIndex + "_" + i}
                                            disabled={indexOuter && indexOuter !== 0}
                                            onChange={(event) => {
                                              let index = event.target.selectedIndex;
                                              let el = event.target.childNodes[index];
                                              let data = ssrKafilaDetails.response && ssrKafilaDetails.response.baggage && ssrKafilaDetails.response.baggage;
                                              for (let j = 0; j < data.length; j++) {
                                                if(j !==0){
                                                  setFieldValue("baggage_" + type + j + objectIndex + "_" + i, 0);
                                                  setFieldValue("baggage_kg_" + type + j + objectIndex + "_" + i, el.getAttribute("id"));
                                                  this.props.getAdditionalCharges("baggage", type + j + objectIndex + "_" + i, 0, el.getAttribute("id"));
                                                }else{
                                                  setFieldValue("baggage_" + type + j + objectIndex + "_" + i, event.target.value);
                                                  setFieldValue("baggage_kg_" + type + j + objectIndex + "_" + i, el.getAttribute("id"));
                                                  this.props.getAdditionalCharges("baggage", type + j + objectIndex + "_" + i, event.target.value, el.getAttribute("id"));
                                                }

                                              }
                                            }}
                                            value={values["baggage_" + type + objectIndex + 0 + "_" + i]}   >
                                            <option value="0.0" disabled={   indexOuter !== 0 &&   values["baggage_kg_" + type + indexOuter + 0 + "_" + i] }>
                                              {this.props.location.state.data.api_source === "kafila" ? "No Extra Baggage" : "No Preferences"}
                                            </option>
                                            {itemOuter[objectKeys].sort((a, b) => {
                                                let x = a.price;
                                                let y = b.price;
                                                if (x > y) {return 1; } 
                                                else { return -1; }
                                              }).map((item, key) => {
                                                if (item.weight)
                                                  return (
                                                    <option id={item.weight} value={item.price} key={key} disabled={indexOuter !== 0} >
                                                      {item.weight + " - Rs." + currencyFormat(item.price) + " " + (item.text ? "" : "")}
                                                    </option>
                                                  );
                                                else return false;
                                              })}
                                          </select>
                                        </div>
                                        <span className="select-items">
                                          Baggage Weight :{" "}
                                          {values["baggage_kg_" + type + indexOuter + objectIndex + "_" + i]}{" "}
                                          Kg
                                        </span>
                                        <span className="select-items">
                                          Baggage Charges : Rs.{" "}
                                          {
                                            values["baggage_" + type + indexOuter + objectIndex + "_" + i]
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              }
                              );
                            }
                            )}
                          {/*tripJackBooking && !ssrDetails.loading && type !== 'infant' && ssrDetails.response &&
                                                ssrDetails.response.length > 0 && ssrDetails.response.map((itemOuter,indexOuter) => {
                                                    return Object.keys(itemOuter).map((objectKeys,objectIndex) => {
                                                        return itemOuter[objectKeys].ssr_info && itemOuter[objectKeys].ssr_info.MEAL && <React.Fragment key={objectIndex}>
                                                    <div className="col-sm-12 col-lg-3">
                                                        <div className="form-group">
                                                        <label className="control-label">
                                                            {
                                                            itemOuter[objectKeys].origin.airport.airport_code}-{itemOuter[objectKeys].destination.airport.airport_code
                                                            }
                                                        </label>
                                                            <label className="control-label">MEAL PREFERENCES</label>
                                                            <div className="custom-select-box">
                                                                <select className="form-control custom-filed" onChange={(event) => {
                                                                            let index = event.target.selectedIndex;
                                                                            let el = event.target.childNodes[index];
                                                                            setFieldValue('meal_' + type +indexOuter+objectIndex+ '_' + i,el.getAttribute('id'));
                                                                            setFieldValue('meal_val_' + type +indexOuter+objectIndex+ '_' + i,event.target.value);
                                                                            this.props.getAdditionalCharges('meal',type +indexOuter+objectIndex+ '_' + i,el.getAttribute('id'),event.target.value);
                                                                        }} value={values['meal_val_' + type +indexOuter+objectIndex+ '_' + i]}>
                                                                 <option value="0"  id="0">No Preferences</option>
                                                                    {
                                                                        itemOuter[objectKeys].ssr_info && itemOuter[objectKeys].ssr_info.MEAL && itemOuter[objectKeys].ssr_info.MEAL.map((item, key) => {
                                                                            return (
                                                                                <option id={item.amount ? item.amount : 0} key={key} value={item.code}>{(item.desc !== '' ?item.desc:item.code) + ' - Rs.' + currencyFormat(item.amount)}</option>

                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            <span className="select-items">Meal Type: {values['meal_val_' + type +indexOuter+objectIndex+ '_' + i]}</span>
                                                            <span className="select-items">Meal Charges: Rs. {values['meal_' + type +indexOuter+objectIndex+ '_' + i]}</span>
                                                                
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                                })
                                                            })
                                                        */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {(this.props.ssrDetails.response &&
                    !tripJackBooking &&
                    !KafilaBooking && !riyaBooking) ||
                  (tripJackBooking && type !== "infant") ? (
                    <div
                      className={
                        this.state.showSSr.length === 0
                          ? "ssr-detail show-ssr"
                          : "ssr-detail"
                      }
                    >
                      <div className="adult-title">
                        <span>SSR Details</span>
                        {(this.state.showSSr.length === 0 ||
                          !this.state.showSSr.includes(type + "_" + i)) && (
                          /* eslint-disable-next-line */
                          <a
                            className="show-hide-right"
                            href="javascript:void(0);"
                            onClick={() => this.openSsr(type + "_" + i, "add")}
                          >
                            <span>
                              <i className="icon icon-arrow-down"></i> Add Baggage & Meal
                            </span>
                          </a>
                        )}
                        {this.state.showSSr.includes(type + "_" + i) && (
                          /* eslint-disable-next-line */
                          <a
                            className="show-hide-right"
                            href="javascript:void(0);"
                            onClick={() =>
                              this.openSsr(type + "_" + i, "remove")
                            }
                          >
                            <span>
                              <i className="icon icon-arrow-up"></i>Hide Baggage & Meal
                            </span>
                          </a>
                        )}
                      </div>
                      <div
                        className="adult-form-outer"
                        style={{
                          display: this.state.showSSr.includes(type + "_" + i)
                            ? "block"
                            : "none",
                        }}
                      >
                        <div className="form-row">
                          {(fareQuote.loading || ssrDetails.loading) && (
                            <div
                              className="loader ssr-loader relative-loader blue-ring"
                              style={{ display: "block" }}
                            >
                              <div className="loader-content">
                                <div className="loader-ring blue-ring">
                                  {" "}
                                  <span></span>{" "}
                                </div>
                              </div>
                            </div>
                          )}
                          {!ssrDetails.loading && ssrDetails.error && (
                            <React.Fragment>
                              <div>No SSR found</div>
                            </React.Fragment>
                          )}
                          {!ssrDetails.loading &&
                            ssrDetails.response &&
                            ssrDetails.response.meal_dynamic &&
                            ssrDetails.response.meal_dynamic.length > 0 &&
                            ssrDetails.response.meal_dynamic.map(
                              (itemOuter, indexOuter) => {
                                return Object.keys(itemOuter).map(
                                  (objectKeys, objectIndex) => {
                                  let requiredFieldValidators = this.props.fareQuote.response &&  this.props.fareQuote.response.results && this.props.fareQuote.response.results && this.props.fareQuote.response.results.requiredFieldValidators
                                    return (
                                      <React.Fragment key={objectIndex}>
                                        <div className="col-sm-12 col-lg-3">
                                          <div className="form-group">
                                            <label className="control-label">
                                              {objectKeys.replace("_", "-")}
                                            </label>
                                            <label className="control-label">
                                              MEAL PREFERENCES
                                            </label>
                                            <div className="custom-select-box">
                                              <select
                                                className="form-control custom-filed"
                                                onChange={(event) => {
                                                  let index =
                                                    event.target.selectedIndex;
                                                  let el =
                                                    event.target.childNodes[
                                                      index
                                                    ];
                                                  setFieldValue(
                                                    "meal_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i,
                                                    el.getAttribute("id")
                                                  );
                                                  setFieldValue(
                                                    "meal_val_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i,
                                                    event.target.value
                                                  );
                                                  this.props.getAdditionalCharges(
                                                    "meal",
                                                    type +
                                                      "_" +
                                                      i +
                                                      indexOuter +
                                                      objectIndex,
                                                    el.getAttribute("id"),
                                                    event.target.value
                                                  );
                                                }}
                                                value={
                                                  values[
                                                    "meal_val_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i
                                                  ]
                                                }
                                              >
                                                <option value="0" id="0">
                                                  No Preferences
                                                </option>
                                                {itemOuter[objectKeys].map(
                                                  (item, key) => {
                                                    return (
                                                      <option
                                                        id={item.price}
                                                        key={key}
                                                        value={item.code}
                                                        disabled={requiredFieldValidators.IsMealRequired && item.code === "NoMeal"}>
                                                        {(item.airline_description !==
                                                        ""
                                                          ? item.airline_description
                                                          : item.code) +
                                                          " - Rs." +
                                                          currencyFormat(
                                                            item.price
                                                          )}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                            </div>
                                            <span className="select-items">
                                              Meal Type:{" "}
                                              {
                                                values[
                                                  "meal_val_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                            <span className="select-items">
                                              Meal Charges: Rs.{" "}
                                              {
                                                values[
                                                  "meal_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    );
                                  }
                                );
                              }
                            )}
                          {!ssrDetails.loading &&
                            ssrDetails.response &&
                            ssrDetails.response.meal &&
                            ssrDetails.response.meal.length > 0 && (
                              <React.Fragment>
                                <div className="col-sm-12 col-lg-3">
                                  <div className="form-group">
                                    <label className="control-label">
                                      MEAL PREFERENCES
                                    </label>
                                    <div className="custom-select-box">
                                      <select
                                        className="form-control custom-filed"
                                        onChange={(event) => {
                                          setFieldValue(
                                            "gds_meal_" + type + "_" + i,
                                            event.target.value
                                          );
                                          //  this.props.getAdditionalCharges('meal',type + '_' + i,event.target.value);
                                        }}
                                      >
                                        <option value="0">
                                          No Preferences
                                        </option>
                                        {ssrDetails.response.meal.map(
                                          (item, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={item.code}
                                              >
                                                {item.code +
                                                  " - " +
                                                  item.description}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                    <span className="select-items">
                                      Meal Type:{" "}
                                      {values["gds_meal_" + type + "_" + i]}
                                    </span>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                          {!ssrDetails.loading &&
                            type !== "infant" &&
                            this.props.fareQuote.response &&
                            !this.props.fareQuote.response.results.is_lcc &&
                            ssrDetails.response &&
                            ssrDetails.response.seat_preference &&
                            ssrDetails.response.seat_preference.length > 0 && (
                              <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                  <label className="control-label">
                                    SEAT PREFERENCES
                                  </label>
                                  <div className="custom-select-box">
                                    <select
                                      className="form-control custom-filed"
                                      onChange={(event) => {
                                        setFieldValue(
                                          "gds_seat_" + type + "_" + i,
                                          event.target.value
                                        );
                                      }}
                                    >
                                      <option value="0">No Preferences</option>
                                      {ssrDetails.response.seat_preference.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={item.code}
                                            >
                                              {item.description}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}

                          {!ssrDetails.loading &&
                            type !== "infant" &&
                            ssrDetails.response &&
                            ssrDetails.response.baggage &&
                            ssrDetails.response.baggage.length > 0 &&
                            ssrDetails.response.baggage.map(
                              (itemOuter, indexOuter) => {
                                return Object.keys(itemOuter).map(
                                  (objectKeys, objectIndex) => {
                                    return (
                                      <React.Fragment key={objectIndex}>
                                        <div className="col-sm-12 col-lg-3">
                                          <div className="form-group">
                                            <label className="control-label">
                                              {objectKeys.replace("_", "-")}
                                            </label>
                                            <label className="control-label">
                                              Baggage
                                            </label>
                                            <div className="custom-select-box">
                                              <select
                                                className="form-control custom-filed"
                                                name={
                                                  "baggage_" +
                                                  type +
                                                  indexOuter +
                                                  objectIndex +
                                                  "_" +
                                                  i
                                                }
                                                disabled ={objectIndex && objectIndex !== 0}
                                                onChange={(event) => {
                                                  let index =
                                                    event.target.selectedIndex;
                                                  let el =
                                                    event.target.childNodes[
                                                      index
                                                    ];
                                                let data=ssrDetails.response&&ssrDetails.response.baggage&&ssrDetails.response.baggage[indexOuter]
                                                let objKeys=Object.keys(data)

                                                if (el.value === "0.0") {
                                                  for (let j = 0; j < objKeys.length; j++) {
                                                    setFieldValue(
                                                      "baggage_" + type + indexOuter + j + "_" + i,
                                                      "0.0"
                                                    );
                                                    setFieldValue(
                                                      "baggage_kg_" + type + indexOuter + j + "_" + i,
                                                      null
                                                    );
                                                    this.props.getAdditionalCharges(
                                                      "baggage",
                                                      type + indexOuter + j + "_" + i,
                                                      0.0,
                                                      null
                                                    );
                                                  }
                                                  return;
                                                }

                                                  for (let j = 0; j < objKeys.length; j++) {
                                                    data[objKeys].map(
                                                      (item, key) => {
                                                        if (item.weight > 0 && (Number(item.weight) === Number(el.getAttribute("id")))) {
                                                          setFieldValue(
                                                            "baggage_" +
                                                            type +
                                                            indexOuter +
                                                            j +
                                                            "_" +
                                                            i,
                                                            item.price ? item.price : 0 + 'B' + key
                                                          );
                                                          setFieldValue(
                                                            "baggage_kg_" +
                                                            type +
                                                            indexOuter +
                                                            j +
                                                            "_" +
                                                            i,
                                                            item.weight
                                                          );
                                                          this.props.getAdditionalCharges(
                                                            "baggage",
                                                            type +
                                                            indexOuter +
                                                            j +
                                                            "_" +
                                                            i,
                                                            item.price ? item.price : 0,
                                                            item.weight
                                                          );
                                                        }else if(el.getAttribute("id") === null){
                                                          setFieldValue(
                                                            "baggage_" +
                                                            type +
                                                            indexOuter +
                                                            j +
                                                            "_" +
                                                            i,
                                                            0.0
                                                          );
                                                          setFieldValue(
                                                            "baggage_kg_" +
                                                            type +
                                                            indexOuter +
                                                            j +
                                                            "_" +
                                                            i,
                                                            null
                                                          );
                                                          this.props.getAdditionalCharges(
                                                            "baggage",
                                                            type +
                                                            indexOuter +
                                                            j +
                                                            "_" +
                                                            i,
                                                            null,
                                                            null
                                                          );
                                                        }
                                                      })
                                                  }
                                                }}
                                                value={
                                                  values[
                                                    "baggage_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i
                                                  ]
                                                }
                                              >
                                                <option value="0.0"
                                                disabled={objectIndex !== 0  && values[
                                                  "baggage_kg_" +
                                                    type +
                                                    indexOuter +
                                                    0 +
                                                    "_" +
                                                    i
                                                ]}
                                                >
                                                  {this.props.location.state
                                                    .data.api_source === "tbo"
                                                    ? "No Extra Baggage"
                                                    : "No Preferences"}
                                                </option>
                                                {itemOuter[objectKeys].map(
                                                  (item, key) => {
                                                    if (item.weight > 0)
                                                      return (
                                                        <option
                                                          id={item.weight}
                                                          value={item.price && item.price !== 0 ? item.price : 0+'B'+key}
                                                          key={key}
                                                          disabled={objectIndex !== 0}
                                                        >
                                                          {item.weight +
                                                            " kg - Rs." +
                                                            currencyFormat(
                                                              item.price
                                                            ) +
                                                            " " +
                                                            (item.text
                                                              ? item.text
                                                              : "")}
                                                        </option>
                                                      );
                                                    else return false;
                                                  }
                                                )}
                                              </select>
                                            </div>
                                            <span className="select-items">
                                              Baggage Weight :{" "}
                                              {
                                                values[
                                                  "baggage_kg_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }{" "}
                                              Kg
                                            </span>
                                            <span className="select-items">
                                              Baggage Charges : Rs.{" "}
                                              {
                                                values[
                                                  "baggage_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ] && typeof values[
                                                  "baggage_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ] === 'string' ? 0 : values[
                                                  "baggage_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    );
                                  }
                                );
                              }
                            )}
                          {tripJackBooking &&
                            !ssrDetails.loading &&
                            type !== "infant" &&
                            ssrDetails.response &&
                            ssrDetails.response.length > 0 &&
                            ssrDetails.response.map((itemOuter, indexOuter) => {
                              return Object.keys(itemOuter).map(
                                (objectKeys, objectIndex) => {
                                  return (
                                    itemOuter[objectKeys].ssr_info &&
                                    itemOuter[objectKeys].ssr_info.MEAL && (
                                      <React.Fragment key={objectIndex}>
                                        <div className="col-sm-12 col-lg-3">
                                          <div className="form-group">
                                            <label className="control-label">
                                              {
                                                itemOuter[objectKeys].origin
                                                  .airport.airport_code
                                              }
                                              -
                                              {
                                                itemOuter[objectKeys]
                                                  .destination.airport
                                                  .airport_code
                                              }
                                            </label>
                                            <label className="control-label">
                                              MEAL PREFERENCES
                                            </label>
                                            <div className="custom-select-box">
                                              <select
                                                className="form-control custom-filed"
                                                onChange={(event) => {
                                                  let index =
                                                    event.target.selectedIndex;
                                                  let el =
                                                    event.target.childNodes[
                                                      index
                                                    ];
                                                  setFieldValue(
                                                    "meal_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i,
                                                    el.getAttribute("id")
                                                  );
                                                  setFieldValue(
                                                    "meal_val_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i,
                                                    event.target.value
                                                  );
                                                  this.props.getAdditionalCharges(
                                                    "meal",
                                                    type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i,
                                                    el.getAttribute("id"),
                                                    event.target.value
                                                  );
                                                }}
                                                value={
                                                  values[
                                                    "meal_val_" +
                                                      type +
                                                      indexOuter +
                                                      objectIndex +
                                                      "_" +
                                                      i
                                                  ]
                                                }
                                              >
                                                <option value="0" id="0">
                                                  No Preferences
                                                </option>
                                                {itemOuter[objectKeys]
                                                  .ssr_info &&
                                                  itemOuter[objectKeys].ssr_info
                                                    .MEAL &&
                                                  itemOuter[
                                                    objectKeys
                                                  ].ssr_info.MEAL.map(
                                                    (item, key) => {
                                                      return (
                                                        <option
                                                          id={
                                                            item.amount
                                                              ? item.amount
                                                              : 0
                                                          }
                                                          key={key}
                                                          value={item.code}
                                                        >
                                                          {(item.desc !== ""
                                                            ? item.desc
                                                            : item.code) +
                                                            " - Rs." +
                                                            currencyFormat(
                                                              item.amount
                                                            )}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                              </select>
                                            </div>
                                            <span className="select-items">
                                              Meal Type:{" "}
                                              {
                                                values[
                                                  "meal_val_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                            <span className="select-items">
                                              Meal Charges: Rs.{" "}
                                              {
                                                values[
                                                  "meal_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    )
                                  );
                                }
                              );
                            })}
                          {tripJackBooking &&
                            type !== "infant" &&
                            !ssrDetails.loading &&
                            ssrDetails.response &&
                            ssrDetails.response.length > 0 &&
                            ssrDetails.response.map((itemOuter, indexOuter) => {
                              return Object.keys(itemOuter).map(
                                (objectKeys, objectIndex) => {
                                  return (
                                    itemOuter[objectKeys].ssr_info &&
                                    itemOuter[objectKeys].ssr_info.BAGGAGE && (
                                      <React.Fragment key={objectIndex}>
                                        <div className="col-sm-12 col-lg-3">
                                          <div className="form-group">
                                            <label className="control-label">
                                              {
                                                itemOuter[objectKeys].origin
                                                  .airport.airport_code
                                              }
                                              -
                                              {
                                                itemOuter[objectKeys]
                                                  .destination.airport
                                                  .airport_code
                                              }
                                            </label>
                                            <label className="control-label">
                                              Baggage
                                            </label>
                                            <div className="custom-select-box">
                                              <select
                                                className="form-control custom-filed"
                                                name={
                                                  "baggage_" +
                                                  type +
                                                  indexOuter +
                                                  objectIndex +
                                                  "_" +
                                                  i
                                                }
                                                disabled ={objectIndex && objectIndex !== 0}
                                                onChange={(event) => {
                                                  let index =
                                                    event.target.selectedIndex;
                                                  let el =
                                                    event.target.childNodes[
                                                      index
                                                    ];
                                                  let data=ssrDetails.response&&ssrDetails.response[indexOuter]&&ssrDetails.response[indexOuter]
                                                 
                                                  // Handle "No Preferences" case
                                                  if (el.value === "0.0") {
                                                    for (let j = 0; j < data.length; j++) {
                                                      setFieldValue(
                                                        "baggage_" + type + indexOuter + j + "_" + i,
                                                        "0.0"
                                                      );
                                                      setFieldValue(
                                                        "baggage_kg_" + type + indexOuter + j + "_" + i,
                                                        null
                                                      );
                                                      this.props.getAdditionalCharges(
                                                        "baggage",
                                                        type + indexOuter + j + "_" + i,
                                                        0.0,
                                                        null
                                                      );
                                                    }
                                                    return;
                                                  }

                                                  for (let j = 0; j < data.length; j++) {
                                                    itemOuter[j]
                                                    .ssr_info &&
                                                    itemOuter[j].ssr_info
                                                      .BAGGAGE &&
                                                    itemOuter[j].ssr_info.BAGGAGE.map(
                                                      (item, key) => {
                                                          if( item.desc ===  el.getAttribute("id")){
                                                            setFieldValue(
                                                              "baggage_" +
                                                                type +
                                                                indexOuter +
                                                                j +
                                                                "_" +
                                                                i,
                                                              item.amount ? item.amount : 0+'B'+key
                                                            );
                                                            setFieldValue(
                                                              "baggage_kg_" +
                                                                type +
                                                                indexOuter +
                                                                j +
                                                                "_" +
                                                                i,
                                                              item.desc
                                                            );
                                                            this.props.getAdditionalCharges(
                                                              "baggage",
                                                              type +
                                                                indexOuter +
                                                                j +
                                                                "_" +
                                                                i,
                                                                item.amount ? item.amount : 0,
                                                              item.desc
                                                            );
                                                            }else if(el.getAttribute("id") === null){
                                                              setFieldValue(
                                                                "baggage_" +
                                                                  type +
                                                                  indexOuter +
                                                                  j +
                                                                  "_" +
                                                                  i,
                                                                0.0
                                                              );
                                                              setFieldValue(
                                                                "baggage_kg_" +
                                                                  type +
                                                                  indexOuter +
                                                                  j +
                                                                  "_" +
                                                                  i,
                                                                null
                                                              );
                                                              this.props.getAdditionalCharges(
                                                                "baggage",
                                                                type +
                                                                  indexOuter +
                                                                  j +
                                                                  "_" +
                                                                  i,
                                                                  null,
                                                                null
                                                              );
                                                            }
                                                      })
                                                  }
                                                }}
                                                value={
                                                  values[
                                                    "baggage_" +
                                                      type +
                                                      indexOuter +
                                                      objectKeys +
                                                      "_" +
                                                      i
                                                  ]
                                                }
                                              >
                                                <option value="0.0"
                                                disabled={objectIndex !== 0 && values[
                                                  "baggage_kg_" +
                                                    type +
                                                    indexOuter +
                                                    0 +
                                                    "_" +
                                                    i
                                                ]}
                                                >
                                                  No Preferences
                                                </option>
                                                {itemOuter[objectKeys]
                                                  .ssr_info &&
                                                  itemOuter[objectKeys].ssr_info
                                                    .BAGGAGE &&
                                                  itemOuter[
                                                    objectKeys
                                                  ].ssr_info.BAGGAGE.map(
                                                    (item, key) => {
                                                      return (
                                                        <option
                                                          id={item.desc}
                                                          value={
                                                            item.amount && item.amount != 0 
                                                              ? item.amount 
                                                              : 0+"B"+key
                                                          }
                                                          key={key}
                                                        disabled={objectIndex !== 0}
                                                        >
                                                          {item.desc +
                                                            " - Rs." +
                                                            currencyFormat(
                                                              item.amount
                                                            )}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                              </select>
                                            </div>
                                            <span className="select-items">
                                              Baggage Weight :{" "}
                                              {
                                                values[
                                                  "baggage_kg_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                            <span className="select-items">
                                              Baggage Charges : Rs.{" "}
                                              {
                                                values[
                                                  "baggage_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ] && typeof values[
                                                  "baggage_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ] === 'string' ? 0 : values[
                                                  "baggage_" +
                                                    type +
                                                    indexOuter +
                                                    objectIndex +
                                                    "_" +
                                                    i
                                                ]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    )
                                  );
                                }
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                   {/* Riya Traveller Supplier for singe passenger checked */}
                   {
                     type !== "infant" && this.props.ssrDetails.response && riyaBooking ?
                      <div className={this.state.showSSr.length === 0 ? "ssr-detail show-ssr" : "ssr-detail"}   >
                        <div className="adult-title">
                          <span>SSR Details</span>
                          {(this.state.showSSr.length === 0 || !this.state.showSSr.includes(type + "_" + i)) && (
                            /* eslint-disable-next-line */
                            <a className="show-hide-right" href="/" onClick={(e) => { e.preventDefault(); this.openSsr(type + "_" + i, "add") }}   >
                              <span>
                                <i className="icon icon-arrow-down"></i> Add Baggage & Meal
                              </span>
                            </a>
                          )}
                          {this.state.showSSr.includes(type + "_" + i) && (
                            /* eslint-disable-next-line */
                            <a className="show-hide-right" href="/" onClick={(e) => { e.preventDefault(); this.openSsr(type + "_" + i, "remove") }} >
                              <span>
                                <i className="icon icon-arrow-up"></i>Hide Baggage & Meal
                              </span>
                            </a>
                          )}
                        </div>
                        <div className="adult-form-outer" style={{ display: this.state.showSSr.includes(type + "_" + i) ? "block" : "none", }} >
                          <div className="form-row">
                            {(fareQuote.loading || ssrDetails.loading) && (
                              <div className="loader ssr-loader relative-loader blue-ring" style={{ display: "block" }}  >
                                <div className="loader-content">
                                  <div className="loader-ring blue-ring">
                                    {" "}
                                    <span></span>{" "}
                                  </div>
                                </div>
                              </div>
                            )}
                            {!fareQuote.loading && ssrDetails.error && (
                              <React.Fragment>
                                <div>No SSR found</div>
                              </React.Fragment>
                            )}
                            
                            {
                              !ssrDetails.loading && ssrDetails.response && ssrDetails.response.ssr_info && ssrDetails.response.ssr_info.meal_dynamic &&
                              ssrDetails.response.ssr_info.meal_dynamic.length > 0 &&
                              [...new Map(ssrDetails.response.ssr_info.meal_dynamic.map(item => [`${item.origin}-${item.destination}`, item])).values()].map((itemOuter, indexOuter) => {
                                
                              
                                const mealOptions = ssrDetails.response.ssr_info.meal_dynamic.filter(item => item.origin === itemOuter.origin && item.destination === itemOuter.destination);
                        
                                return (
                                  <React.Fragment key={indexOuter}>
                                    <div className="col-sm-12 col-lg-3">
                                      <div className="form-group">
                                        <label className="control-label">
                                          {itemOuter.origin + '-' + itemOuter.destination}
                                        </label>
                                        <label className="control-label">
                                          MEAL PREFERENCES
                                        </label>
                                        <div className="custom-select-box">
                                          <select className="form-control custom-filed"
                                            onChange={(event) => {
                                              let index = event.target.selectedIndex;
                                              let el = event.target.childNodes[index];
                                              setFieldValue("meal_" + type + 0 + indexOuter + "_" + i, el.getAttribute("id"));
                                              setFieldValue("meal_val_" + type + 0 + indexOuter + "_" + i, event.target.value);
                                              this.props.getAdditionalCharges("meal", type + "_" + i + 0 + indexOuter, el.getAttribute("id"), event.target.value
                                              );
                                            }}
                                            value={values["meal_val_" + type + 0 + indexOuter + "_" + i]}>
                                            <option value="0" id="0">
                                              No Preferences
                                            </option>
                                            {mealOptions.map((meal, index) => (
                                              <option key={index} value={meal.code} id={meal.price}>
                                                {/* {meal.description} - {meal.price} {meal.currency} */}
                                                {(meal.description !== "" ? meal.description : meal.code) + " - Rs." + currencyFormat(meal.price)}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <span className="select-items">
                                          <strong>Meal Type: </strong>{" "}
                                          {
                                            values["meal_val_" + type + 0 + indexOuter + "_" + i]
                                          }
                                        </span>
                                        <span className="select-items">
                                        <strong>Meal Charges: </strong>Rs.{" "}
                                          {
                                            values["meal_" + type + 0 + indexOuter + "_" + i]
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })
                            }
                           
                           
                      {
                        !ssrDetails.loading &&
                        ssrDetails.response &&
                        ssrDetails.response.results &&
                        ssrDetails.response.results.segments &&
                        ssrDetails.response.results.segments.length > 0 &&
                        ssrDetails.response.results.segments.map((itemOuter, indexOuter) => {
                          const origin = itemOuter[0].origin.airport.airport_code;
                          const destination = itemOuter[itemOuter.length - 1].destination.airport.airport_code;
                          return itemOuter.map((segment, objectIndex) => {
                            const baggageOptions = ssrDetails.response.ssr_info.baggage;
                            if (!baggageOptions || baggageOptions.length === 0) {
                              return null;
                            }
                            
                            // Filter baggage options based on origin and destination
                            const filteredBaggageOptions = baggageOptions.filter(baggage => {
                              return baggage.origin === origin && baggage.destination === destination;
                            });

                            const isFirstSegment = objectIndex === 0;
                            const firstDropdownValue = values["baggage_kg_" + type + indexOuter + objectIndex + i];
                            const secondDropdownDisabled = objectIndex % 2 === 1 && firstDropdownValue;

                            return (
                              <div className="col-sm-12 col-lg-3" key={objectIndex}>
                                <div className="form-group">
                                  <label className="control-label">
                                    {segment.origin.airport.airport_code + '-' + segment.destination.airport.airport_code}
                                  </label>
                                  <label className="control-label">
                                    Baggage
                                  </label>
                                  <div className="custom-select-box">
                                    <select
                                      className="form-control custom-filed"
                                      name={"baggage_" + type + indexOuter + objectIndex + i}
                                      disabled={objectIndex && objectIndex !== 0}
                                      onChange={(event) => {
                                        const index = event.target.selectedIndex;
                                        const el = event.target.childNodes[index];
                                        const baggageWeight = event.target.value;

                                        if (objectIndex % 2 === 0) {
                                          setFieldValue("baggage_" + type + indexOuter + (objectIndex + 1) + i, 0);
                                          setFieldValue("baggage_kg_" + type + indexOuter + (objectIndex + 1) + i, baggageWeight);
                                          this.props.getAdditionalCharges("baggage", type + indexOuter + (objectIndex + 1) + i, 0, 0);
                                        }

                                        setFieldValue("baggage_" + type + indexOuter + objectIndex + i, el.getAttribute("id"));
                                        setFieldValue("baggage_kg_" + type + indexOuter + objectIndex + i, baggageWeight);
                                        this.props.getAdditionalCharges("baggage", type + indexOuter + objectIndex + i, el.getAttribute("id"), baggageWeight);
                                      }}
                                      value={firstDropdownValue}
                                    >
                                      <option value="0" id="0">
                                        No Preferences
                                      </option>
                                      {filteredBaggageOptions.map((baggage, index) => (
                                        <option key={index} value={baggage.description !== "" ? baggage.description : baggage.code} id={baggage.price}>
                                          {(baggage.description !== "" ? baggage.description : baggage.code) + " - Rs." + baggage.price}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span className="select-items">
                                    <strong>Baggage Weight :</strong> {firstDropdownValue} Kg
                                  </span>
                                  <span className="select-items">
                                    <strong>Baggage Charges :</strong> Rs. {values["baggage_" + type + indexOuter + objectIndex + i] || '0'}
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        })
                      }


                          </div>
                        </div>
                      </div>
                      : ""
                  }
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12">
              <div className="select-check">
                <small className="checkbox">
                  <Field
                    type="checkbox"
                    name={"saveCustomer_" + type + "_" + i}
                  />
                  <label />
                </small>
                <span>Save/Update Customer in "My Customer" List</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return passengers;
  };

  handleButtonClick = () => {
    this.props.setFieldValue("submit", "submit", false); // last arg tells Formik not to run validation and make this change synchronously
    this.props.setFieldValue("selectedSeat", this.props.selectedSeat, false);
    this.props.setFieldValue("isLcc", this.props.isLcc, false);

    // Submit the form
    this.props.submitForm();

    setTimeout(() => {
      this.props.scrollToFirstError(this.props.errors);

      // Check if there are any popup messages and requiredField is true
      if (this.props.popupMessages.length > 0 && this.props.requiredField) {
        this.setState({ showPopup: true });
      }
    }, 0);
  };
  
  closePopup = () => {
    this.setState({ showPopup: false });
    if (this.props.handleConfirmationPopupModel) {
      this.props.handleConfirmationPopupModel(false, []); // Reset the popup state in parent
    }
  };

  render() {
    const {
      errors,
      values,
      touched,
      handleChange,
      setFieldValue,
      submitForm,
      companyDetails,
      cellCountryCode,
      scrollToFirstError,
      firstErrorRef
    } = this.props;
    let isAirAsia = (this.props.location && this.props.location.state && this.props.location.state.data &&
      this.props.location.state.data.segments &&
      this.props.location.state.data.segments[0] &&
      this.props.location.state.data.segments[0][0] &&
      this.props.location.state.data.segments[0][0].airline &&
      (this.props.location.state.data.segments[0][0].airline.airline_code === "SG")) &&
      (this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data.api_source === "kafila");

      let isAirAsiaR = (this.props.location && this.props.location.state && this.props.location.state.data && 
        this.props.location.state.data[this.props.location.state.index]&&
        this.props.location.state.data[this.props.location.state.index].segments&&
        this.props.location.state.data[this.props.location.state.index].segments[0] &&
        this.props.location.state.data[this.props.location.state.index].segments[0][0]&&
        this.props.location.state.data[this.props.location.state.index].segments[0][0].airline&&
        (this.props.location.state.data[this.props.location.state.index].segments[0][0].airline.airline_code === "SG")) &&
        (this.props.location && this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] &&
          this.props.location.state.data[this.props.location.state.index].api_source === "kafila");
    let data = this.props.location.state.data;
    let kafilaCheckBooking = false;
    let tripJackCheckBooking = false;
    let airIQCheckBooking = false;
    let goflyCheckBooking = false;
    let eflyCheckBooking = false;
    let tboCheckBooking = false;
    let satkarCheckBooking = false;
    let riyaCheckBooking = false;
    let fareBoutique = false;
    // let index = this.props.location.state.index
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "kafila"
    ) {
      kafilaCheckBooking = true;
    }
    if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      (this.props.location.state.data[0].api_source === "kafila" ||
        this.props.location.state.data[1].api_source === "kafila")
    ) {
      kafilaCheckBooking = true;
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "tripjack"
    ) {
      tripJackCheckBooking = true;
    }
    if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      if (
        this.props.location.state.data[this.props.location.state.index]
          .api_source === "tripjack"
      ) {
        tripJackCheckBooking = true;
      }
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "omairiq"
    ) {
      airIQCheckBooking = true;
    }
    if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      if (
        this.props.location.state.data[this.props.location.state.index]
          .api_source === "omairiq"
      ) {
        airIQCheckBooking = true;
      }
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "gofly"
    ) {
      goflyCheckBooking = true;
    }
    if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      if (
        this.props.location.state.data[this.props.location.state.index]
          .api_source === "gofly"
      ) {
        goflyCheckBooking = true;
      }
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "efly"
    ) {
      eflyCheckBooking = true;
    }
    if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      if (
        this.props.location.state.data[this.props.location.state.index]
          .api_source === "efly"
      ) {
        eflyCheckBooking = true;
      }
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "tbo"
    ) {
      tboCheckBooking = true;
    } else if (
      (this.props.location.state.searchType === "round-trip" ||
        this.props.location.state.searchType === "round-trip-complete") &&
      this.props.location.state.data[this.props.location.state.index]
        .api_source === "tbo"
    ) {
      tboCheckBooking = true;
    }
    if (
      (this.props.location.state.searchType === "one-way" ||
        this.props.location.state.searchType === "special-return") &&
      this.props.location.state.data.api_source === "satkar"
    ) {
      satkarCheckBooking = true;
    }
    if (
      this.props.location.state.searchType === "round-trip" ||
      this.props.location.state.searchType === "round-trip-complete"
    ) {
      if (
        this.props.location.state.data[this.props.location.state.index]
          .api_source === "satkar"
      ) {
        satkarCheckBooking = true;
      }
    }
    if ((this.props.location.state.searchType === "one-way" || this.props.location.state.searchType === "special-return") && this.props.location.state.data.api_source === "riya_travel") {
      riyaCheckBooking = true;
    }
    if (this.props.location.state.searchType === "round-trip" || this.props.location.state.searchType === "round-trip-complete") {
      if (this.props.location.state.data[this.props.location.state.index].api_source === "riya_travel") {
        riyaCheckBooking = true;
      }
    }

    if ((this.props.location.state.searchType === "one-way" || this.props.location.state.searchType === "special-return") && this.props.location.state.data.api_source === "fbq") {
      fareBoutique = true;
    }
    if (this.props.location.state.searchType === "round-trip" || this.props.location.state.searchType === "round-trip-complete") {
      if (this.props.location.state.data[this.props.location.state.index].api_source === "fbq") {
        fareBoutique = true;
      }
    }

    let gst_mandatory = null;
    if (
      this.props.fareQuote.response &&
      this.props.fareQuote.response.airline_remark === "SME" &&
      this.props.fareQuote.response.results.airline_code === "IndiGo"
    ) {
      values.gst_checked = "show";
      gst_mandatory = true;
    }
    if (
      this.props.fareQuote.response &&
      this.props.fareQuote.response.results &&
      this.props.fareQuote.response.results.is_gst_mandatory
    ) {
      values.gst_checked = "show";
      gst_mandatory = true;
    }
    let cell_country_code_mandatory = false;
    if (
      this.props.fareQuote.response &&
      this.props.fareQuote.response.airline_remark &&
      this.props.fareQuote.response.airline_remark.includes("NDC")
    ) {
      cell_country_code_mandatory = true;
    }

    let companyDetailArray = [];
    if (companyDetails && companyDetails.response) {
      companyDetailArray =
        companyDetails &&
        companyDetails.response &&
        companyDetails.response.company_details &&
        companyDetails.response.company_details.map(function (
          companyDetail,
          index
        ) {
          return {
            value: companyDetail.company_name, //it is used for searching
            label: companyDetail.company_name,
            labelValue: companyDetail.company_name,
            company_address: companyDetail.company_address,
            company_contact_number: companyDetail.company_contact_number,
            company_email: companyDetail.company_email,
            company_gst_no: companyDetail.company_gst_no,
            company_registration_no: companyDetail.company_registration_no,
          };
        });
    }
    // let isDomestic = this.props.searchQuery && this.props.searchQuery.is_domestic;
    if (values.country === "") {
      values.country = this.props.country_id;
    }

    if (
      this.props.fareQuote.response &&
      this.props.fareQuote.response.conditions &&
      this.props.fareQuote.response.conditions.gst &&
      this.props.fareQuote.response.conditions.gst.gstappl === true
    ) {
      gst_mandatory = false;
    }
    if (
      this.props.fareQuote.response &&
      this.props.fareQuote.response.conditions &&
      this.props.fareQuote.response.conditions.gst &&
      this.props.fareQuote.response.conditions.gst.igm === true
    ) {
      values.gst_checked = "show";
      gst_mandatory = true;
    }
    let gstChecked = values.gst_checked === "show" ? true : false;

    function timeConversion(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      if (rhours.toString().length < 2) rhours = "0" + rhours;
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if (rminutes.toString().length < 2) rminutes = "0" + rminutes;
      return rhours + "h" + ":" + rminutes + "m";
    }

    return (
      <div className="tabs-content-wrap">
        <div className="step-content">
          <h3 className="step-title">
            <span>
              Please enter names as mentioned on your government ID proof
            </span>{" "}
            <span className="cols pull-right">
              {/* eslint-disable-next-line */}
              <div className="pull-right">
                <button
                  className="btn btn-yellow sm-yellow-btn"
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/flight-search-details",
                      state: {
                        searchAPINo: true,
                      },
                    })
                  }
                >
                  Choose Another Fare
                </button>
              </div>
              {/* <a href="javascript:void(0);" onClick={() => 
                    this.props.history.push({
                        pathname:"/flight-search-details",
                        state:{
                            searchAPINo : true
                        }
                    })
                } >Choose another fare</a> */}
            </span>
          </h3>
          <div className="review-filght-step">
            <div className="review-step">
              <div className="review-step-title">
                <div className="cols pull-left">
                  {" "}
                  <span>Flight detail</span>{" "}
                </div>
                <div className="cols pull-right"></div>
              </div>
              <div className="review-step-deatil">
                <div className="step-deatil-outer">
                  {this.props.fareQuote.loading ? (
                    <div
                      className="loader relative-loader"
                      style={{ display: "block" }}
                    >
                      <div className="loader-content">
                        <div className="loader-ring blue-ring">
                          <span />{" "}
                        </div>
                      </div>
                    </div>
                  ) : (
                    this.props.fareQuote.response &&
                    this.props.fareQuote.response.results &&
                    this.props.fareQuote.response.results.segments &&
                    this.props.fareQuote.response.results.segments.map(
                      (itemListing, keyLisitng) => {
                        let stopData = flightHelper.getFlightStops(itemListing);
                        return (
                          <div className="flight-details" key={keyLisitng}>
                            <div className="flight-date">
                              {" "}
                              <i className="icon icon-flight" />{" "}
                              <span>
                                {dateFormat(
                                  this.props.searchQuery &&
                                    this.props.searchQuery.segments &&
                                    this.props.searchQuery.segments[
                                      keyLisitng
                                    ] &&
                                    this.props.searchQuery.segments[keyLisitng]
                                      .preferred_departure_time,
                                  "DD MMM YYYY, FDAY"
                                )}
                              </span>{" "}
                            </div>
                            <ul>
                              {stopData.map((item, key) => {
                                let originTerminal = "";
                                let destinationTerminal = "";
                                if (
                                  item.origin_terminal !== "" &&
                                  item.origin_terminal
                                ) {
                                  if (tripJackCheckBooking) {
                                    originTerminal = (
                                      <React.Fragment>
                                        -{" "}
                                        <strong>{item.origin_terminal}</strong>
                                      </React.Fragment>
                                    );
                                  } else {
                                    originTerminal = (
                                      <React.Fragment>
                                        -{" "}
                                        <strong>
                                          T-{item.origin_terminal}
                                        </strong>
                                      </React.Fragment>
                                    );
                                  }
                                }
                                if (
                                  item.destination_terminal !== "" &&
                                  item.destination_terminal
                                ) {
                                  if (tripJackCheckBooking) {
                                    destinationTerminal = (
                                      <React.Fragment>
                                        -{" "}
                                        <strong>
                                          {item.destination_terminal}
                                        </strong>
                                      </React.Fragment>
                                    );
                                  } else {
                                    destinationTerminal = (
                                      <React.Fragment>
                                        -{" "}
                                        <strong>
                                          T-{item.destination_terminal}
                                        </strong>
                                      </React.Fragment>
                                    );
                                  }
                                }
                                return (
                                  <li key={key}>
                                    <div className="flight-card">
                                      <div className="traveller-flight">
                                        <figure className="pull-left">
                                          <img
                                            src={
                                              item.airline.flight_logo
                                                ? item.airline.flight_logo
                                                : ""
                                            }
                                            alt=""
                                          />
                                        </figure>
                                        <div className="flight-dat">
                                          {" "}
                                          <span>
                                            {item.airline.airline_name +
                                              ", " +
                                              item.airline.airline_code +
                                              (item.airline.flight_number
                                                ? " - " +
                                                  item.airline.flight_number
                                                : "")}
                                          </span>
                                          {kafilaCheckBooking === false &&
                                            airIQCheckBooking === false &&
                                            goflyCheckBooking === false &&
                                            eflyCheckBooking === false && 
                                            satkarCheckBooking === false && 
                                            riyaCheckBooking === false && (
                                              <p>
                                                {item &&
                                                item.airline &&
                                                item.airline
                                                  .operating_carrier_name
                                                  ? "Operated By - " +
                                                    item.airline
                                                      .operating_carrier_name
                                                  : item &&
                                                    item.airline &&
                                                    item.airline
                                                      .operating_carrier
                                                  ? "Operated By - " +
                                                    item.airline
                                                      .operating_carrier
                                                  : null}
                                              </p>
                                            )}
                                          {airIQCheckBooking === false &&
                                            goflyCheckBooking === false &&
                                            eflyCheckBooking === false && 
                                            satkarCheckBooking === false &&
                                            riyaCheckBooking === false &&
                                            fareBoutique === false &&(
                                              <p>Aircraft - {item.craft}</p>
                                            )}
                                        </div>
                                      </div>
                                      <div className="flight-stops">
                                        <div className="flight-start">
                                          <h3>{item.origin_city} </h3>
                                          <span>
                                            {dateFormat(
                                              item.origin_dep_time,
                                              "FDAY, DD MMM YYYY h:m"
                                            )}
                                          </span>
                                          <p>
                                            {item.origin_airport_name}{" "}
                                            {originTerminal}
                                          </p>
                                        </div>
                                        <div
                                          className={
                                            (item.cabin_class_name !== null &&
                                              item.stop_over &&
                                              item.stop_over.length >= 1) ||
                                            item.stop_over
                                              ? "flight-direction flight-luggage flight-cabin flight-time"
                                              : item.cabin_class_name !== null
                                              ? "flight-direction flight-luggage flight-cabin "
                                              : "flight-direction flight-luggage"
                                          }
                                        >
                                          <div className="fl-mid-inner">
                                            <ul>
                                              {tripJackCheckBooking === false &&
                                                tboCheckBooking === false && riyaCheckBooking === false && (
                                                  <li>
                                                    <i
                                                      className="fa fa-clock-o"
                                                      aria-hidden="true"
                                                    ></i>{" "}
                                                    <span>
                                                      {flightHelper.getDuration(
                                                        itemListing,
                                                        "h"
                                                      )}
                                                    </span>
                                                  </li>
                                                )}
                                              {tripJackCheckBooking ===
                                                true && (
                                                <li>
                                                  <i
                                                    className="fa fa-clock-o"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                  <span>
                                                    {timeConversion(
                                                      item.duration
                                                    )}{" "}
                                                  </span>
                                                </li>
                                              )}
                                              {tboCheckBooking === true && (
                                                <li>
                                                  <i
                                                    className="fa fa-clock-o"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                  <span>
                                                    {timeConversion(
                                                      item.duration
                                                    )}{" "}
                                                  </span>
                                                </li>
                                              )}
                                                {riyaCheckBooking === true && (
                                                <li>
                                                  <i
                                                    className="fa fa-clock-o"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                  <span>
                                                    {timeConversion(
                                                      item.duration
                                                    )}{" "}
                                                  </span>
                                                </li>
                                              )}
                                              {item.cabin_baggage ? (
                                                <li className="mid-meal">
                                                  {" "}
                                                  <i className="material-icons">
                                                    card_travel
                                                  </i>{" "}
                                                  <span>
                                                    {item.cabin_baggage}
                                                  </span>{" "}
                                                </li>
                                              ) : null}
                                              {item.baggage ? (
                                                <li>
                                                  {" "}
                                                  <i className="material-icons">
                                                    shopping_cart
                                                  </i>{" "}
                                                  <span>{item.baggage}</span>{" "}
                                                </li>
                                              ) : null}
                                            </ul>
                                          </div>
                                          {/* round trip  normal return start*/}
                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.data &&
                                            this.props.location.state.data
                                              .api_source === "tripjack" &&
                                            this.props.location.state
                                              .searchType ===
                                              "special-return" && ( //segment.length === 1 &&
                                              <div
                                                className="time-col"
                                                style={{
                                                  display:
                                                    item.stop_over &&
                                                    item.stop_over.length >= 1
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {flightHelper.getCityCodeTripJack(
                                                    item
                                                  )}
                                                </span>
                                              </div>
                                            )}

                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.data &&
                                            (this.props.location.state.data
                                              .api_source === "tbo" ||
                                              (this.props.location.state.data &&
                                                this.props.location.state.data
                                                  .api_source === "kafila") || this.props.location.state.data.api_source === "riya_travel") &&
                                            this.props.location.state
                                              .searchType ===
                                              "special-return" && ( //segment.length === 1 &&
                                              <div
                                                className="time-col"
                                                style={{
                                                  display: item.stop_over
                                                    ? "block"
                                                    : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {item.stop_point_full_name}
                                                </span>
                                              </div>
                                            )}
                                          {/* round trip  normal return end*/}

                                          {/* ======================================*/}
                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.data &&
                                            this.props.location.state
                                              .searchType ===
                                              "round-trip-complete" && ( //segment.length === 1 &&
                                              <div
                                                className="time-col"
                                                style={{
                                                  display:
                                                    item.stop_over &&
                                                    item.stop_over.length >= 1
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {flightHelper.getCityCodeTripJack(
                                                    item
                                                  )}
                                                </span>
                                              </div>
                                            )}

                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.data &&
                                            this.props.location.state
                                              .searchType ===
                                              "round-trip-complete" && ( //segment.length === 1 &&
                                              <div
                                                className="time-col"
                                                style={{
                                                  display:
                                                    item.stop_point_full_name
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {item.stop_point_full_name}
                                                </span>
                                              </div>
                                            )}
                                          {/* ======================================*/}

                                          {/* round trip  special return international start*/}
                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.data &&
                                            this.props.location.state.data
                                              .api_source === "tripjack" &&
                                            this.props.location.state
                                              .searchType ===
                                              "lcc-special-return" && ( //segment.length === 1 &&
                                              <div
                                                className="time-col"
                                                style={{
                                                  display:
                                                    item.stop_over &&
                                                    item.stop_over.length >= 1
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {flightHelper.getCityCodeTripJack(
                                                    item
                                                  )}
                                                </span>
                                              </div>
                                            )}

                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.data &&
                                            (this.props.location.state.data
                                              .api_source === "tbo" ||
                                              (this.props.location.state.data &&
                                                this.props.location.state.data
                                                  .api_source === "kafila") || this.props.location.state.data.api_source === "riya_travel") &&
                                            this.props.location.state
                                              .searchType ===
                                              "lcc-special-return" && ( //segment.length === 1 &&
                                              <div
                                                className="time-col"
                                                style={{
                                                  display: item.stop_over
                                                    ? "block"
                                                    : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {item.stop_point_full_name}
                                                </span>
                                              </div>
                                            )}
                                          {/*  round trip  special return international end*/}

                                          {/* round trip  special return domestic start*/}
                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.data &&
                                            this.props.location.state
                                              .searchType ===
                                              "lcc-special-return" && ( //segment.length === 1 &&
                                              <div
                                                className="time-col"
                                                style={{
                                                  display:
                                                    item.stop_over &&
                                                    item.stop_over.length >= 1
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {flightHelper.getCityCodeTripJack(
                                                    item
                                                  )}
                                                </span>
                                              </div>
                                            )}

                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.data &&
                                            this.props.location.state
                                              .searchType ===
                                              "lcc-special-return" && ( //segment.length === 1 &&
                                              <div
                                                className="time-col"
                                                style={{
                                                  display:
                                                    item.stop_point_full_name
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {item.stop_point_full_name}
                                                </span>
                                              </div>
                                            )}
                                          {/*  round trip  special return domestic end*/}

                                          {/* one way  start*/}
                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.data &&
                                            this.props.location.state.data
                                              .api_source === "tripjack" &&
                                            this.props.location.state
                                              .searchType === "one-way" && ( //segment.length === 1 &&
                                              <div
                                                className="time-col"
                                                style={{
                                                  display:
                                                    item.stop_over &&
                                                    item.stop_over.length >= 1
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {flightHelper.getCityCodeTripJack(
                                                    item
                                                  )}
                                                </span>
                                              </div>
                                            )}

                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.data &&
                                            (this.props.location.state.data
                                              .api_source === "tbo" ||
                                              (this.props.location.state.data &&
                                                this.props.location.state.data
                                                  .api_source === "kafila") || this.props.location.state.data.api_source === "riya_travel") &&
                                            this.props.location.state
                                              .searchType === "one-way" && ( //segment.length === 1 &&
                                              <div
                                                className="time-col"
                                                style={{
                                                  display: item.stop_over
                                                    ? "block"
                                                    : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {item.stop_point_full_name}
                                                </span>
                                              </div>
                                            )}
                                          {/* one way  end*/}
                                          {/* round trip domestic start*/}
                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state
                                              .searchType === "round-trip" &&
                                            this.props.location.state.data[0] &&
                                            this.props.location.state.data[0]
                                              .api_source === "tripjack" && (
                                              <div
                                                className="time-col"
                                                style={{
                                                  display:
                                                    item.stop_over &&
                                                    item.stop_over.length >= 1
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {flightHelper.getCityCodeTripJack(
                                                    item
                                                  )}
                                                </span>
                                              </div>
                                            )}

                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state
                                              .searchType === "round-trip" &&
                                            ((this.props.location.state
                                              .data[0] &&
                                              this.props.location.state.data[0]
                                                .api_source === "tbo") ||
                                              (this.props.location.state
                                                .data[0] &&
                                                this.props.location.state
                                                  .data[0].api_source ===
                                                  "kafila") || (this.props.location.state.data[0] && this.props.location.state.data[0].api_source === "riya_travel") ) && (
                                              <div
                                                className="time-col"
                                                style={{
                                                  display: item.stop_over
                                                    ? "block"
                                                    : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {item.stop_point_full_name}
                                                </span>
                                              </div>
                                            )}
                                          {/* round trip domestic end*/}

                                          {/* multi city  start*/}
                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state
                                              .searchType === "multi-city" &&
                                            this.props.location.state.data &&
                                            this.props.location.state.data
                                              .api_source === "tripjack" && (
                                              <div
                                                className="time-col"
                                                style={{
                                                  display:
                                                    item.stop_over &&
                                                    item.stop_over.length >= 1
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {flightHelper.getCityCodeTripJack(
                                                    item
                                                  )}
                                                </span>
                                              </div>
                                            )}

                                          {this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state
                                              .searchType === "multi-city" &&
                                            this.props.location.state.data &&
                                            (this.props.location.state.data
                                              .api_source === "tbo" ||
                                              this.props.location.state.data
                                                .api_source === "kafila" || this.props.location.state.data.api_source === "riya_travel") && (
                                              <div
                                                className="time-col"
                                                style={{
                                                  display: item.stop_over
                                                    ? "block"
                                                    : "none",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "black",
                                                  }}
                                                >
                                                  Technical Stop Via{" "}
                                                  {item.stop_point_full_name}
                                                </span>
                                              </div>
                                            )}
                                          {/* multi city  end*/}
                                          <div className="flight-bar">
                                            <i className="icon icon-flight3" />
                                          </div>
                                          {item.cabin_class_name !== null && (
                                            <div className="cabin-info">
                                              <strong>Cabin : </strong>
                                              <span>
                                                {item.cabin_class_name}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        <div className="flight-start flight-landing">
                                          <h3>{item.destination_city}</h3>
                                          <span>
                                            {dateFormat(
                                              item.destination_arr_time,
                                              "FDAY, DD MMM YYYY h:m"
                                            )}
                                          </span>
                                          <p>
                                            {item.destination_airport_name}{" "}
                                            {destinationTerminal}
                                          </p>
                                        </div>
                                        {}
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      }
                    )
                  )}
                </div>
                {/* {
                                    this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.is_remark_available === true && <div className="fl-sme"><span>{this.props.fareQuote.response.results.airline_remark}</span></div>
                                } */}
              </div>
            </div>
            {this.props.searchQuery &&
              this.props.searchQuery.adult_count > 0 &&
              this.passengerForm(
                this.props.searchQuery.adult_count,
                "adult",
                "icon-adult"
              )}
            {this.props.searchQuery &&
              this.props.searchQuery.child_count > 0 &&
              this.passengerForm(
                this.props.searchQuery.child_count,
                "child",
                "icon-child"
              )}
            {this.props.searchQuery &&
              this.props.searchQuery.infant_count > 0 &&
              this.passengerForm(
                this.props.searchQuery.infant_count,
                "infant",
                "icon-infant"
              )}
            <div className="gst-card">
              <div className="review-step">
                <div className="review-step-title">
                  <div className="cols pull-left">
                    <span>GENERAL Details</span>
                  </div>
                </div>
                <div className="review-step-deatil">
                  <div className="step-deatil-outer">
                    <div className="adult-form">
                      <div className="block form-card">
                        <div className="adult-form-outer">
                          <div className="form-row">
                            <div className="col-sm-12 col-lg-3">
                              <div className="form-group">
                                <label className="control-label">
                                  Country<sup>*</sup>
                                </label>
                                <div className="custom-select-box">
                                  <select
                                    className={
                                      errors.country && touched.country
                                        ? "form-control custom-filed error-shadow"
                                        : "form-control custom-filed"
                                    }
                                    value={values.country}
                                    name="country"
                                    onChange={(event) => {
                                      handleChange(event);
                                      var index =
                                        event.nativeEvent.target.selectedIndex;
                                      setFieldValue(
                                        "country_name",
                                        event.nativeEvent.target[index].text
                                      );
                                      setFieldValue(
                                        "country_code",
                                        event.nativeEvent.target[
                                          index
                                        ].getAttribute("data-country-code")
                                      );
                                    }}
                                  >
                                    <option value="" data-country-code="">
                                      Select Country
                                    </option>
                                    {this.props.locations.response &&
                                      this.props.locations.response.country &&
                                      this.props.locations.response.country.map(
                                        (item, key) => {
                                          return (
                                            <option
                                              key={key}
                                              data-country-code={
                                                item.locationCode
                                              }
                                              value={item.id}
                                            >
                                              {item.country}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-3">
                              <div className="form-group">
                                <label className="control-label">City</label>
                                <div className="custom-select-box">
                                  <select
                                    className="form-control custom-filed"
                                    value={values.city}
                                    name="city"
                                    onChange={(event) => {
                                      handleChange(event);
                                      var index =
                                        event.nativeEvent.target.selectedIndex;
                                      setFieldValue(
                                        "city_name",
                                        event.nativeEvent.target[index].text
                                      );
                                    }}
                                  >
                                    <option>Select City</option>
                                    {this.props.locations.response &&
                                      values.country &&
                                      this.props.locations.response.country_city[
                                        values.country
                                      ].map((item, key) => {
                                        return (
                                          <option key={key} value={item.id}>
                                            {item.city}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* {
                                                            isDomestic === 'false' &&
                                                            <div className="col-sm-12 col-lg-3">
                                                                <div className="form-group">
                                                                    <label className="control-label">Nationality</label>
                                                                    <div className="custom-select-box">
                                                                        <select value={values.nationality} name='nationality' onChange={handleChange} className="form-control custom-filed">
                                                                            <option value="">Select Nationality</option>
                                                                            {
                                                                                this.props.locations.response && this.props.locations.response.country
                                                                                && this.props.locations.response.country.map((item, key) => {
                                                                                    return (
                                                                                        <option key={key}  value={item.nationality}>{item.nationality}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        } */}
                            <div className="col-sm-12 col-lg-3">
                              <div className="form-group">
                                <label className="control-label">
                                  Cell Country Code
                                  {cell_country_code_mandatory === true ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                </label>
                                <div className="custom-select-box">
                                  <select
                                    className={
                                      errors.cell_country_codes &&
                                      touched.cell_country_codes
                                        ? "form-control custom-filed error-shadow"
                                        : "form-control custom-filed"
                                    }
                                    value={values.cell_country_code}
                                    name="cell_country_code"
                                    onChange={(event) => {
                                      handleChange(event);
                                      var index =
                                        event.nativeEvent.target.selectedIndex;
                                      setFieldValue(
                                        "cell_country_code",
                                        event.nativeEvent.target[index].value
                                      );
                                    }}
                                  >
                                    <option value="">Country Code</option>
                                    {cellCountryCode &&
                                      cellCountryCode.response &&
                                      cellCountryCode.response
                                        .cell_country_codes &&
                                      cellCountryCode.response.cell_country_codes.map(
                                        (item, key) => {
                                          return (
                                            <option
                                              key={key}
                                              value={item.cell_country_code}
                                            >
                                              {item.country_short_name +
                                                " (" +
                                                item.cell_country_code +
                                                ")"}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className={`col-sm-12 col-lg-3 ${errors.mobile_no && errors.mobile_no.split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                              <div className="form-group">
                                <label className="control-label">
                                  Mobile Number <sup>*</sup>
                                </label>
                                <Field
                                  className={
                                    errors.mobile_no && touched.mobile_no
                                      ? "form-control custom-filed error-shadow"
                                      : "form-control custom-filed"
                                  }
                                  type="text"
                                  name="mobile_no"
                                />
                              </div>
                            </div>
                            <div className={`col-sm-12 col-lg-3 ${errors.email && errors.email.split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                              <div className="form-group">
                                <label className="control-label">
                                  Email<sup>*</sup>
                                </label>
                                <Field
                                  className={
                                    errors.email && touched.email
                                      ? "form-control custom-filed error-shadow"
                                      : "form-control custom-filed"
                                  }
                                  type="text"
                                  name="email"
                                />
                              </div>
                            </div>
                            <div className={`col-sm-12 col-lg-3 ${errors.addressLine1 && errors.addressLine1.split(" ").join("").replace(/[^A-Za-z\s]/g, '')}`}>
                              <div className="form-group">
                                <label className="control-label">
                                  Address Line 1<sup>*</sup>
                                </label>
                                <Field
                                  className={
                                    errors.addressLine1 && touched.addressLine1
                                      ? "form-control custom-filed error-shadow"
                                      : "form-control custom-filed"
                                  }
                                  type="text"
                                  name="addressLine1"
                                  value={values.addressLine1}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-3">
                              <div className="form-group">
                                <label className="control-label">
                                  Address Line 2
                                </label>
                                <Field
                                  className={
                                    errors.addressLine2 && touched.addressLine2
                                      ? "form-control custom-filed error-shadow"
                                      : "form-control custom-filed"
                                  }
                                  type="text"
                                  name="addressLine2"
                                  value={values.addressLine2}
                                />
                              </div>
                            </div>
                            {/* <div className="col-sm-12 col-lg-3">
                                                            <div className="form-group">
                                                                <label className="control-label">Address <sup>*</sup></label>
                                                                <textarea className="form-control custom-filed" type="text" name="address" value={values.address} onChange={handleChange}/>
                                                                {errors.address && touched.address && <span className="error-msg">{errors.address}</span>}
                                                            </div>
                                                        </div>                */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {((this.props.fareQuote.response &&
                this.props.fareQuote.response.results &&
                this.props.fareQuote.response.results.gst_allowed) ||
                kafilaCheckBooking === true) &&  (
                <div className="gst-card-outer">
                  <div className="pull-left">
                    <small className="checkbox">
                      <input
                        type="checkbox"
                        name="gst"
                        checked={gstChecked}
                        disabled={gst_mandatory}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setFieldValue("gst_checked", "show");
                          } else {
                            setFieldValue("gst_checked", "");
                          }
                        }}
                      />
                      <label />
                    </small>{" "}
                  </div>
                  <div className="gst-deatil">
                    <h3>GST number for business travel</h3>
                    <p>
                      <strong>Note:</strong> Please fill GST details only for
                      corporate customers
                    </p>
                  </div>
                </div>
              )}
              {kafilaCheckBooking === false &&
                this.props.fareQuote.response &&
                this.props.fareQuote.response.conditions &&
                this.props.fareQuote.response.conditions.gst &&
                (this.props.fareQuote.response.conditions.gst.gstappl ===
                  true ||
                  this.props.fareQuote.response.conditions.gst.igm ===
                    true) && (
                  <div className="gst-card-outer">
                    <div className="pull-left">
                      <small className="checkbox">
                        <input
                          type="checkbox"
                          name="gst"
                          checked={gstChecked}
                          disabled={gst_mandatory}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setFieldValue("gst_checked", "show");
                            } else {
                              setFieldValue("gst_checked", "");
                            }
                          }}
                        />
                        <label />
                      </small>{" "}
                    </div>
                    <div className="gst-deatil">
                      <h3>GST number for business travel</h3>
                      <p>
                        <strong>Note:</strong> Please fill GST details only for
                        corporate customers
                      </p>
                    </div>
                  </div>
                )}

              <div className={`gst-form-card ${values.gst_checked}`}>
                <div className="review-step">
                  <div className="review-step-title">
                    <div className="cols pull-left">
                      <span>GST Details</span>
                    </div>
                  </div>
                  <div className="review-step-deatil">
                    <div className="step-deatil-outer">
                      <div className="adult-form">
                        <div className="block form-card">
                          <div className="adult-form-outer">
                            <div className="form-row">
                              <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                  <label className="control-label">
                                    Select from list
                                  </label>
                                  <div className="custom-select-box">
                                    <Field
                                      component={Select}
                                      value={values.gst_company_name}
                                      options={companyDetailArray}
                                      name="comapnyDetail"
                                      className=""
                                      onChange={(event) => {
                                        this.getComapnyName(
                                          event,
                                          setFieldValue
                                        );
                                      }}
                                      openMenuOnFocus="true"
                                      // tabSelectsValue="false"
                                      defaultMenuIsOpen={true}
                                    ></Field>
                                    {/* <select className="form-control custom-filed" value={values.city} name='city' onChange={(event) =>{
                                                                                    handleChange(event);
                                                                                    var index = event.nativeEvent.target.selectedIndex;  
                                                                                    setFieldValue('city_name', event.nativeEvent.target[index].text)
                                                                                }}>
                                                                            <option>Select City</option>
                                                                            {
                                                                                this.props.locations.response && values.country &&
                                                                                this.props.locations.response.country_city[values.country].map((item, key) => {
                                                                                    return (
                                                                                        <option key={key} value={item.id}>{item.city}</option>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </select> */}
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                  <label className="control-label">
                                    GST Number
                                    { <sup>*</sup>}
                                  </label>
                                  <Field
                                    className={
                                      errors.gst_number && touched.gst_number
                                        ? "form-control custom-filed error-shadow"
                                        : "form-control custom-filed"
                                    }
                                    type="text"
                                    name="gst_number"
                                    value={values.gst_number}
                                    maxLength="15"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                  <label className="control-label">
                                    GST Company Name
                                    {<sup>*</sup>}
                                  </label>
                                  <Field
                                    className={
                                      errors.gst_company_name &&
                                      touched.gst_company_name
                                        ? "form-control custom-filed error-shadow"
                                        : "form-control custom-filed"
                                    }
                                    type="text"
                                    name="gst_company_name"
                                    value={values.gst_company_name}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                  <label className="control-label">
                                    GST Company Contact Number
                                    {<sup>*</sup>}
                                  </label>
                                  <Field
                                    className={
                                      errors.gst_company_number &&
                                      touched.gst_company_number
                                        ? "form-control custom-filed error-shadow"
                                        : "form-control custom-filed"
                                    }
                                    type="text"
                                    name="gst_company_number"
                                    value={values.gst_company_number}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                  <label className="control-label">
                                    GST Company Address
                                    {<sup>*</sup>}
                                  </label>
                                  <Field
                                    className={
                                      errors.gst_company_address &&
                                      touched.gst_company_address
                                        ? "form-control custom-filed error-shadow"
                                        : "form-control custom-filed"
                                    }
                                    type="text"
                                    name="gst_company_address"
                                    value={values.gst_company_address}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                  <label className="control-label">
                                    GST Company Email
                                    {<sup>*</sup>}
                                  </label>
                                  <Field
                                    className={
                                      errors.gst_company_email &&
                                      touched.gst_company_email
                                        ? "form-control custom-filed error-shadow"
                                        : "form-control custom-filed"
                                    }
                                    type="text"
                                    name="gst_company_email"
                                    value={values.gst_company_email}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-12">
                      <div className="select-check">
                        <small className="checkbox">
                          <Field
                            type="checkbox"
                            name="save_company_gst_details"
                          />
                          <label />
                        </small>
                        <span>Save/Update GST Details</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {((this.props.ssrDetails.response &&
              this.props.ssrDetails.response.seat_dynamic) ||
              (this.props.ssrKafilaDetails.response &&
                this.props.ssrKafilaDetails.response.result &&
                this.props.ssrKafilaDetails.response.result.seat_dynamic) ||
              (this.props.ssrKafilaDetailsRound.response &&
                this.props.ssrKafilaDetailsRound.response.result &&
                this.props.ssrKafilaDetailsRound.response.result
                  .seat_dynamic) ||
              (this.props.fareQuote.response &&
                this.props.fareQuote.response.conditions &&
                this.props.fareQuote.response.conditions.isa)) &&
              !this.props.fareQuote.loading &&
              !this.props.ssrKafilaDetails.loading &&
              !this.props.ssrKafilaDetailsRound.loading && 
              (
                (this.props.location.state && this.props.location.state.data &&(this.props.location.state.data.api_source !== "gofly" || this.props.location.state.data.api_source !== "satkar" || this.props.location.state.data.api_source !== "riya_travel" || this.props.location.state.data.api_source !== "fbq")) || 
                (this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && (this.props.location.state.data[this.props.location.state.index].api_source !== "gofly" || this.props.location.state.data[this.props.location.state.index].api_source !== "satkar" || this.props.location.state.data[this.props.location.state.index].api_source !== "riya_travel" || this.props.location.state.data[this.props.location.state.index].api_source !== "fbq"))
              ) && (
                <div className="btn-block text-right">
                    <button
                    type="button"
                    className="btn btn-primary loading-btn"
                    onClick={(value) => {
                      setFieldValue(
                        "submit",
                        "seat",
                        false
                      ); /** last arg tells formik not to run validation and make this change synchronously  */
                      submitForm();
                      setTimeout(() => {
                        scrollToFirstError(this.props.errors)
                        }, );
                    }}
                  >
                    <span>Select Seat (s)</span>
                  </button>
                </div>
              )}
               {
                ((this.props.location.state && this.props.location.state.data &&this.props.location.state.data.api_source === "riya_travel") ||
                (this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].api_source === "riya_travel")) &&
                (
                  <div className="btn-block text-right">
                    
                  <button
                    type="button"
                    className="btn btn-primary loading-btn"
                    onClick={(value) => {
                      setFieldValue(
                        "submit",
                        "seat",
                        false
                      ); /** last arg tells formik not to run validation and make this change synchronously  */
                      submitForm();
                      setTimeout(() => {
                        scrollToFirstError(this.props.errors)
                        }, );
                    }}
                  >
                    <span>Select Seat (s)</span>
                  </button>
                </div>
                )
              }
            <div className="btn-block text-right">
              <button
                type="button"
                className="btn btn-primary loading-btn"
                disabled={this.props.fareQuote.loading}
                onClick={this.handleButtonClick}
              >
                <span>Continue</span>
                {this.props.addPassengerData.loading && (
                  <div className="flight-animated">
                    <label>
                      <span>
                        <small />
                        <small />
                        <small />
                      </span>
                      <img src="assets/images/flight-continue.svg" alt="" />
                    </label>
                  </div>
                )}
              </button>
            </div>

            {(this.props.location.state.data.api_source === "tbo" || (this.props.location.state.index !== undefined && this.props.location.state.data[this.props.location.state.index].api_source === "tbo")) && this.state.showPopup && (
              <div className="popup-crad confirmation-popup show">
                <div className="popup-outer">
                  <div className="popup-content" style={{ maxWidth: "373px" }}>
                    <div className="popup-head">
                      <h2 style={{ fontWeight: "bold" }}>Mandatory</h2>
                      <div className="popup-close" onClick={this.closePopup}  >
                        <span className="icon icon-cross" />
                      </div>
                    </div>
                    <div className="popup-body popup-scroll">
                      <div className="inner-confirmation">
                        {this.props.popupMessages.map((message, index) => (
                          <p key={index} style={{ fontWeight: "bold" }}>{message}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="popup-overlay" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FlightTravellerComponent;
