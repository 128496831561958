import React, { Component } from "react";

class WalletBoxInner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cashBalance: 0,
            creditBalance: 0,
            load:false
          };
        this.handleRecharge = this.handleRecharge.bind(this);
        
    }

    handleRecharge() {
        window.open('/my-account', '_blank');
    }

    cashBalance = () => {
        return Number(
            (this.props.accountResponse && this.props.accountResponse.myAccountResponse && this.props.accountResponse.myAccountResponse.loading) ? 0 : this.props.accountResponse.myAccountResponse &&
             this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.cash_balance ? this.props.accountResponse.myAccountResponse.response.cash_balance : 0
        );
    };
    creditBalance = () => {
        return Number(
            (this.props.accountResponse && this.props.accountResponse.myAccountResponse && this.props.accountResponse.myAccountResponse.loading) ? 0 : this.props.accountResponse.myAccountResponse &&
             this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.credit_limit ? this.props.accountResponse.myAccountResponse.response.credit_limit : 0
        );
    };
    fetchBalance = async () => {
        this.setState({load:true})
            await this.props.myAccountAction();
    
        let apiBalance = this.props.accountResponse.myAccountResponse &&
            this.props.accountResponse.myAccountResponse.response &&
            this.props.accountResponse.myAccountResponse.response.cash_balance;
        let creditBalance = this.props.accountResponse.myAccountResponse &&
            this.props.accountResponse.myAccountResponse.response &&
            this.props.accountResponse.myAccountResponse.response.credit_limit;
        if (this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response) {
            setTimeout(() => {
                this.setState({ load: false });
            }, 1000);
        }
        if (creditBalance !== this.state.creditBalance) {
            this.setState({ creditBalance: creditBalance });
        }
        if (apiBalance !== this.state.cashBalance) {
            this.setState({ cashBalance: apiBalance });
            return apiBalance;
        } else {
            return this.state.cashBalance;
        }
    }
    componentDidUpdate= (props,state)=>{
        let apiBalance =  props.accountResponse.myAccountResponse &&
            props.accountResponse.myAccountResponse.response &&
            props.accountResponse.myAccountResponse.response.cash_balance;
            if (apiBalance !== state.cashBalance) {
                this.setState({ cashBalance: apiBalance });
                return apiBalance;
            } else {
                return state.cashBalance;
            }
    }

    render() {
        let is_credit_allowed = this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.is_credit_allowed &&
         this.props.accountResponse.myAccountResponse.response.is_credit_allowed.toLowerCase() === 'y';
        return (
            <>
                <div className="wallet-box-block">
                    <div className="wallet-card-info">
                        <div className="wallet-card-left">
                            <div className="wallet-card-left-inner">
                                <div className="credit-col">
                                    <div className="cash-card">
                                        <div className="cash-card-inner cash-wallet">
                                            <i className="icon icon-currency2"></i>
                                            <div className="cahs-detail">
                                                <span>Cash Balance</span>
                                                <p className={(this.state.cashBalance < 0 || this.cashBalance() < 0) ? 'danger_color':''}>₹<strong className={(this.state.cashBalance < 0 || this.cashBalance() < 0) ? 'danger_color':''}>
                                                       {this.state.cashBalance ?  this.state.cashBalance : this.cashBalance()}
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>
                                        {is_credit_allowed && <div className="cash-card-inner credit-wallet">
                                            <i className="icon icon-currency"></i>
                                            <div className="cahs-detail">
                                                <span>Credit Limit</span>
                                                <p>
                                                    ₹
                                                    <strong>
                                                        {this.state.creditBalance ? this.state.creditBalance : this.creditBalance()}
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>}
                                        <div className="wallet-card-right">
                                            <div className={this.state.load ? "refresh-icons  spin-loading": "refresh-icons"} onClick={() => this.fetchBalance()}><span className="refresh-icon"><i className="fa fa-refresh" aria-hidden="true"></i></span></div>
                                        </div>
                                    </div>

                                </div>
                                    <div className="recharge-col" onClick={() => this.handleRecharge()}>
                                        <div className="recharge-btn">
                                            <div className="recharge-btn-text"> <span>Recharge</span>
                                            </div>
                                        </div>
                                    </div>

                            </div>
                        </div>


                    </div>
                </div>
            </>
        );
    }
}

export default WalletBoxInner;
