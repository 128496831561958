import React from 'react';
import FlightReviewBookingComponent from './FlightReviewBookingComponent';
import FlightTravellerComponent from './FlightTravellerComponent';
import FlightFareSummaryComponent from './FlightFareSummaryComponent';
import * as flightHelper from '../../../shared/flightHelper';
import { currencyFormat, dateFormat, prettyScrollBar, getNewDateFormted } from '../../../shared/commonHelper';
import SessionExpire from '../../shared/SessionExpire';

class FlightBookTicketComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			baggage: {},
			meal: {},
			baggageAmt: 0,
			baggageQty: 0,
			mealAmt: 0,
			seatAmt: 0,
			selectedSeat: [],
			selectedSeatPrices: [],
			selectedSegmentIndex:0,
			selectedSeatKafila:[],
			selectedSeatCodes: [],
			selectedSeatPrize: [],
			totalSeatPrize: 0,
			totalPassenger: this.props.searchQuery && this.props.searchQuery.adult_count + this.props.searchQuery.child_count,
			seatIndex: 0,
			seatOuterIndex : 0,
			priceChangedOk : null,
			kafilaPriceChangedOk : null,
			totalPrice:0,
			firstRow:null,
			secondRow:null,
			// fareQuote : fareQuote,
			// loading : loading,
			autocomplete: {
				count: null,
				type: null,
				error: null,
				search: null,
				searchResults: null,
				showPopup: false,
			},
			searchStart: null,
			count: 0,
			fareQuoteData: null,
			showConfirmationPopup: false,
			confimationAction: null,
			showBalancePopup: true,
			seatSelectionCount: null
		}
	}

	//************************************************************Confimation Popup*********************************************************/
	handleConfirmationPopup = (event, action) => {
		this.setState({
			showConfirmationPopup: event,
			confimationAction: action
		})
	}

	handleConfimationAction = () => {
		let action = this.state.confimationAction;
		this.setState({
			showConfirmationPopup: false
		})
		if (action && action === 'ticket-booking') {
			this.props.bookTicket();
		}
		else if (action && action === 'hold-ticket-booking') {
			this.props.handleHold('ticket');
		}
		else if (action && action === 'ticket-hold') {
			this.props.handleHold('hold');
		}
	}
	//************************************************************AutoFill passenger from customer List Start***********************************************/	

	//Function to show and hide popup 
	handlePopup = (type, count) => {
		this.setState({
			autocomplete: Object.assign({},
				this.state.autocomplete,
				{
					count: count,
					type: type,
					showPopup: true
				}
			)
		})
	}

	//Function to search customer from customer list
	handleSearch = () => {
		let searchResults = [];
		this.setState({
			searchStart : true
		});
		if (!this.state.autocomplete.search || this.state.autocomplete.search.length < 1) {
			for (let i = 0; i < this.props.customerList.response.passenger.length; i++) {
				let item = this.props.customerList.response.passenger[i];
					searchResults.push(item);
			}
			this.setState({
				autocomplete: Object.assign({},
					this.state.autocomplete,
					{
						error: true,
						showPopup: true,
						searchResults:searchResults
					}
				)
			})
		}
		else {
			if (this.props.customerList.response) {
				for (let i = 0; i < this.props.customerList.response.passenger.length; i++) {
					let item = this.props.customerList.response.passenger[i];
					let firstName = item.first_name;
					let lastName = item.last_name;
					if (firstName.includes(this.state.autocomplete.search) || lastName.includes(this.state.autocomplete.search)
						|| firstName.toUpperCase().includes(this.state.autocomplete.search) || lastName.toUpperCase().includes(this.state.autocomplete.search)
						|| firstName.toLowerCase().includes(this.state.autocomplete.search) || lastName.toLowerCase().includes(this.state.autocomplete.search)
					) {
						searchResults.push(item);
					}
				}
			}
			this.setState({
				autocomplete: Object.assign({},
					this.state.autocomplete,
					{
						error: false,
						searchResults: searchResults,
						showPopup: true
					}
				)
			})
		}
	}

	//Function to autofill passenger detail from customer list
	autoCompleteCustomer = (value) => {
		let dob=value.date_of_birth && value.date_of_birth.split("-");
		let pass_exp=value.passport_exp && value.passport_exp !== "0000-00-00" && value.passport_exp.split("-");
		let pass_issue=value.passport_issue_date && value.passport_issue_date !== "0000-00-00" && value.passport_issue_date.split("-");
		let isDomestic = this.props.searchQuery && this.props.searchQuery.is_domestic;
		let defaultAddress = this.props.accountResponse.myAccountResponse.response ? this.props.accountResponse.myAccountResponse.response.office_address : '';
		let defaultEmail = this.props.accountResponse.myAccountResponse.response ? this.props.accountResponse.myAccountResponse.response.email_address : '';
		let airline_remark =this.props.fareQuote && this.props.fareQuote.response &&this.props.fareQuote.response.results && this.props.fareQuote.response.results.airline_remark;
		let isPassportMandatory = this.props.fareQuote &&  this.props.fareQuote.response &&  this.props.fareQuote.response.results && this.props.fareQuote.response.results.is_passport_mandatory;
		let tboBooking = false;
		if ((this.props.location.state.searchType === "one-way" ||  this.props.location.state.searchType === "multi-city" ||  this.props.location.state.searchType === "special-return") &&this.props.location.state.data.api_source === "tbo"  ) {
			tboBooking = true;
		  } else if ((this.props.location.state.searchType === "round-trip" ||this.props.location.state.searchType === "round-trip-complete") &&	this.props.location.state.data[this.props.location.state.index].api_source === "tbo" ) {
			tboBooking = true;
		  }

		if (this.state.autocomplete.count && this.state.autocomplete.type) {
			let count = this.state.autocomplete.count;
			let type = this.state.autocomplete.type;
			this.props.setFieldValue("first_name_" + type + "_" + count, value.first_name);
			this.props.setFieldValue("last_name_" + type + "_" + count, value.last_name);
			this.props.setFieldValue("mobile_no", value.mobile_no);
			this.props.setFieldValue("gender_" + type + "_" + count, value.gender);
			this.props.setFieldValue("dob_" + type + "_" + count, value.date_of_birth ? getNewDateFormted(value.date_of_birth):null);

			if(dob && dob.length > 0){
				this.props.setFieldValue("dobDay_" + type + "_" + count, dob[2]);
				this.props.setFieldValue("dobYear_" + type + "_" + count, dob[0]);
				this.props.setFieldValue("dobMonth_" + type + "_" + count, dob[1]);
			}else{
				this.props.setFieldValue("dobDay_" + type + "_" + count, null);
				this.props.setFieldValue("dobYear_" + type + "_" + count, null);
				this.props.setFieldValue("dobMonth_" + type + "_" + count, null);
			}
			if(pass_exp && pass_exp.length > 0){
				this.props.setFieldValue("passportDayExpiry_" + type + "_" + count, pass_exp[2]);
				this.props.setFieldValue("passportYearExpiry_" + type + "_" + count, pass_exp[0]);
				this.props.setFieldValue("passportMonthExpiry_" + type + "_" + count, pass_exp[1]);
			}else{
				this.props.setFieldValue("passportDayExpiry_" + type + "_" + count, null);
				this.props.setFieldValue("passportYearExpiry_" + type + "_" + count, null);
				this.props.setFieldValue("passportMonthExpiry_" + type + "_" + count, null);
			}
			if(pass_issue && pass_issue.length > 0){
				this.props.setFieldValue("passporDaytIssue_" + type + "_" + count, pass_issue[2]);
				this.props.setFieldValue("passporYeartIssue_" + type + "_" + count, pass_issue[0]);
				this.props.setFieldValue("passporMonthtIssue_" + type + "_" + count, pass_issue[1]);
			}else{
				this.props.setFieldValue("passporDaytIssue_" + type + "_" + count, null);
				this.props.setFieldValue("passporYeartIssue_" + type + "_" + count, null);
				this.props.setFieldValue("passporMonthtIssue_" + type + "_" + count, null);
			}
			

			this.props.setFieldValue("frequentFlyer_" + type + "_" + count, value.frequent_flyer_no ? value.frequent_flyer_no : '');
			this.props.setFieldValue("email", value.email_id ? value.email_id : defaultEmail);
			this.props.setFieldValue("addressLine1", value.address ? value.address : defaultAddress);
			this.props.setFieldValue("country", value.country_id ? value.country_id : '');
			this.props.setFieldValue("city", value.city_id ? value.city_id : '');
			this.props.setFieldValue("country_name", value.country_id ? value.country : '');
			this.props.setFieldValue("country_code", value.country_code ? value.country_code : '');
			this.props.setFieldValue("cell_country_code", value.cell_country_code ? value.cell_country_code : '');
			this.props.setFieldValue("city_name", value.city_id ? value.city : '');
			this.props.setFieldValue("passenger_title_" + type + "_" + count, value.title_id);
			//this.props.setFieldValue("gst_number", value.gst_no ? value.gst_no : '');
			//this.props.setFieldValue("gst_company_name", value.gst_company_name ? value.gst_company_name : '');
			//this.props.setFieldValue("gst_company_number", value.gst_company_contact_no ? value.gst_company_contact_no : '');
			//this.props.setFieldValue("gst_company_address", value.gst_company_address ? value.gst_company_address : '');
			//this.props.setFieldValue("gst_company_email", value.gst_company_email ? value.gst_company_email : '');
			this.props.setFieldValue("title_name_" + type + "_" + count, value.passenger_title);
			this.props.setFieldValue("document_type_" + type + "_" + count, value.student_id);
			this.props.setFieldValue("senior_citizen_id_" + type + "_" + count, value.senior_citizen_id);
			if (isDomestic === 'false') {
				this.props.setFieldValue("passport_" + type + "_" + count, value.passport_no ? value.passport_no : '');
				this.props.setFieldValue("passportExpiry_" + type + "_" + count, value.passport_exp ? getNewDateFormted(value.passport_exp):null);
				if (tboBooking === true && isPassportMandatory === true && (airline_remark && airline_remark !== null && airline_remark.includes("NDC"))) {
					this.props.setFieldValue("passport_issue_country_code_" + type + "_" + count, value.passport_issue_country_code ? value.passport_issue_country_code : 'IN');
				} else {
					this.props.setFieldValue("passport_issue_country_code_" + type + "_" + count, value.passport_issue_country_code ? value.passport_issue_country_code : '');
				}
				this.props.setFieldValue("passportIssue_" + type + "_" + count, value.passport_issue_date !== "0000-00-00" ? getNewDateFormted(value.passport_issue_date):null);
				this.props.setFieldValue("nationality_" + type + "_" + count, value.nationality ? value.nationality : "Indian");
			}

			if (value.gst_no || value.gst_company_name || value.gst_company_contact_no || value.gst_company_address || value.gst_company_email) {
				this.props.setFieldValue("gst_checked", 'show');
			}
			this.setState({
				autocomplete: Object.assign({},
					this.state.autocomplete,
					{
						showPopup: false
					}
				)
			})
		}
	}

	//************************************************************AutoFill passenger from customer List End***********************************************/
	//*********************************************************************Seat Selection Module Start********************************************************/	
	//Function to save details of selected seat 
	saveSeatDetail(value, price, outerIndex,index) {
		
		let count = parseInt(this.props.searchQuery.adult_count) + parseInt(this.props.searchQuery.child_count);
		let stateArray = [];
		let newArray = [];
		let newArrayOuter = [];
		if(this.state.selectedSeat[index]){
			if (this.state.selectedSeat[index][outerIndex]) {
				stateArray = this.state.selectedSeat[index][outerIndex];
			}
			newArray = this.state.selectedSeat[index];
		}
		let statePrizeArray = [];
		let newArrayPrice = [];
		let newArrayPriceOuter = [];
		if(this.state.selectedSeatPrize[index]){
			if (this.state.selectedSeatPrize[index][outerIndex]) {
				statePrizeArray = this.state.selectedSeatPrize[index][outerIndex];
			}
			newArrayPrice = this.state.selectedSeatPrize[index];
		}
		if (stateArray.includes(value)) {
			//selected seat code
			var indexValue = stateArray.indexOf(value)
			stateArray.splice(indexValue, 1);
			//Selected seat prizes
			var indexPrice = statePrizeArray.indexOf(price)
			statePrizeArray.splice(indexPrice, 1);
		}
		else {
			if (stateArray.length === count || stateArray[stateArray.length - 1] === 0) {
				stateArray.shift();
				stateArray.push(value);
				statePrizeArray.shift();
				statePrizeArray.push(price);
			}
			else {
				stateArray.push(value);
				statePrizeArray.push(price);
			}
		}

		if (outerIndex > 0 && !this.state.selectedSeat[outerIndex - 1]) {
			newArray[outerIndex - 1] = [0];
			newArrayPrice[outerIndex - 1] = [0];
			newArrayOuter[index] = newArray;
			newArrayPriceOuter[index] = newArrayPrice;
		}
		newArray[outerIndex] = stateArray;
		newArrayPrice[outerIndex] = statePrizeArray;
		newArrayOuter[index] = newArray;
		newArrayPriceOuter[index] = newArrayPrice;
		this.setState(prevState => ({
			selectedSeat: Object.assign(newArrayOuter, this.state.selectedSeat),
			selectedSeatPrize: Object.assign(newArrayPriceOuter, this.state.selectedSeatPrize),
		}));
	};

	//for riya

	saveSeatDetailRiya(value, price, SeatID, outerIndex, index) {
		let count = parseInt(this.props.searchQuery.adult_count) + parseInt(this.props.searchQuery.child_count);
		let stateArray = [];
		let newArray = [];
		let newArrayOuter = [];
		let stateArrayId = []; // For seat IDs
		let newArrayId = [];
		let newArrayIdOuter = [];
		
		if (this.state.selectedSeat[index]) {
			if (this.state.selectedSeat[index][outerIndex]) {
				stateArray = this.state.selectedSeat[index][outerIndex];
				stateArrayId = this.state.selectedSeatId[index][outerIndex]; // Fetch the seat IDs
			}
			newArray = this.state.selectedSeat[index];
			newArrayId = this.state.selectedSeatId[index]; // Fetch the seat IDs array
		}
	
		let statePrizeArray = [];
		let newArrayPrice = [];
		let newArrayPriceOuter = [];
		
		if (this.state.selectedSeatPrize[index]) {
			if (this.state.selectedSeatPrize[index][outerIndex]) {
				statePrizeArray = this.state.selectedSeatPrize[index][outerIndex];
			}
			newArrayPrice = this.state.selectedSeatPrize[index];
		}
	
		if (stateArray.includes(value)) {
			// selected seat code
			let indexValue = stateArray.indexOf(value);
			stateArray.splice(indexValue, 1);
			stateArrayId.splice(indexValue, 1); // Remove the corresponding seat ID
			// Selected seat prizes
			let indexPrice = statePrizeArray.indexOf(price);
			statePrizeArray.splice(indexPrice, 1);
		} else {
			if (stateArray.length === count || stateArray[stateArray.length - 1] === 0) {
				stateArray.shift();
				stateArrayId.shift(); // Remove the first seat ID
				stateArray.push(value);
				stateArrayId.push(SeatID); // Add the new seat ID
				statePrizeArray.shift();
				statePrizeArray.push(price);
			} else {
				stateArray.push(value);
				stateArrayId.push(SeatID); // Add the new seat ID
				statePrizeArray.push(price);
			}
		}
	
		// Check previous indexes and set [0] if not selected
		for (let i = outerIndex - 1; i >= 0; i--) {
			if (!this.state.selectedSeat[index] || !this.state.selectedSeat[index][i]) {
				newArray[i] = [0];
				newArrayPrice[i] = [0];
				newArrayId[i] = [0]; // Initialize with default ID
			}
		}
		
		newArray[outerIndex] = stateArray;
		newArrayPrice[outerIndex] = statePrizeArray;
		newArrayId[outerIndex] = stateArrayId; // Update the seat IDs
		newArrayOuter[index] = newArray;
		newArrayPriceOuter[index] = newArrayPrice;
		newArrayIdOuter[index] = newArrayId;

	
		// Set the state
		this.setState(prevState => ({
			selectedSeat: Object.assign(newArrayOuter, this.state.selectedSeat),
			selectedSeatPrize: Object.assign(newArrayPriceOuter, this.state.selectedSeatPrize),
			selectedSeatId: Object.assign(newArrayIdOuter, this.state.selectedSeatId) // Set the seat IDs
		}));
	
	}
	



	addOtherCharges = (event, type) => {
		if (type === 'baggage') {
			this.setState({
				extraBaggage: event.target.value
			});
		}
		if (type === 'meal') {
			this.setState({
				meal: event.target.value
			});
		}
	}

	totalPassengerSeats = (totalPassenger, index,outerIndex) => {
		let totalSeatsArray = [];
		for (let i = 1; i <= totalPassenger; i++) {
			totalSeatsArray.push(
				<li className={this.state.selectedSeat[outerIndex] && this.state.selectedSeat[outerIndex][index] && this.state.selectedSeat[outerIndex][index][i - 1] ? 'yellow-seat' : ''} key={i}>
					<i className="icon icon-seat2"> <span>{this.state.selectedSeat[outerIndex] && this.state.selectedSeat[outerIndex][index] && this.state.selectedSeat[outerIndex][index][i - 1]}
					</span></i> </li>
			)
		}
		return totalSeatsArray;
	}
	// kafila ok
	totalPassengerSeatsKafila = (totalPassenger, index, outerIndex) => {
		let orignalSeat = [];
		let filterSeats = this.state.selectedSeat && this.state.selectedSeat.length > 0 && this.state.selectedSeat.flatMap((value) => {
			return value
		})
		// let finalSeats = filterSeats && filterSeats.length > 0 && filterSeats.filter(subarray => subarray && subarray.length > 0);
		orignalSeat.push(filterSeats)

		let totalSeatsArray = [];
		for (let i = 1; i <= totalPassenger; i++) {
			totalSeatsArray.push(
				<li className={orignalSeat && orignalSeat[outerIndex] && orignalSeat[outerIndex][index] && orignalSeat[outerIndex][index][i - 1] ? 'yellow-seat' : ''} key={i}>
					<i className="icon icon-seat2"> <span>{orignalSeat[outerIndex] && orignalSeat[outerIndex][index] && orignalSeat[outerIndex][index][i - 1]}
					</span></i> </li>
			)
		}

		return totalSeatsArray;

	}

	dispalyPaxDetailsRiya = (count, otherCount, type, values,riyaBooking) => {

		let totalPaxDeatils = [];
		let indexCount = '';
		for (let i = 1; i <= count; i++) {
			if (type === 'adult') {
				indexCount = i - 1;
			}
			else {
				indexCount = parseInt(otherCount) + parseInt(i - 1);
			}
			
			totalPaxDeatils.push(
				<li key={type + '' + i}>
					<div className="pax-info">
						<div className="pax-seat">
							{
								riyaBooking && this.getSegmentSeatsPriceRiya(indexCount, 'seat') 
							}
						</div>
						<span className="pax-name">{values['title_name_' + type + '_' + i] + ' ' + values['first_name_' + type + '_' + i]}</span>
					</div>
						{
						riyaBooking && this.getSegmentSeatsPriceRiya(indexCount, 'price') 				
						}
				</li>
			)
		}
		return totalPaxDeatils;
	}

	dispalyPaxDetails = (count, otherCount, type, values,tripJackBooking) => {
		let totalPaxDeatils = [];
		let indexCount = '';
		for (let i = 1; i <= count; i++) {
			if (type === 'adult') {
				indexCount = i - 1;
			}
			else {
				indexCount = parseInt(otherCount) + parseInt(i - 1);
			}
			
			totalPaxDeatils.push(
				<li key={type + '' + i}>
					<div className="pax-info">
						<div className="pax-seat">
							{
								tripJackBooking ? this.getSegmentSeatsPriceTripJack(indexCount, 'seat') :  this.getSegmentSeatsPrice(indexCount, 'seat')
							}
						</div>
						<span className="pax-name">{values['title_name_' + type + '_' + i] + ' ' + values['first_name_' + type + '_' + i]}</span>
					</div>
						{
						tripJackBooking ? this.getSegmentSeatsPriceTripJack(indexCount, 'price') :  this.getSegmentSeatsPrice(indexCount, 'price')					
						}
				</li>
			)
		}
		return totalPaxDeatils;
	}

	// dispaly pax kafila
	dispalyPaxDetailsKafila = (count, otherCount, type, values) => {

		let totalPaxDeatils = [];
		let indexCount = '';
		for (let i = 1; i <= count; i++) {
			if (type === 'adult') {
				indexCount = i - 1;
			}
			else {
				indexCount = parseInt(otherCount) + parseInt(i - 1);
			}

			totalPaxDeatils.push(
				<li key={type + '' + i}>
					<div className="pax-info">
						<div className="pax-seat">
							{
								this.getSegmentSeatsPriceKafila(indexCount, "seat")
							}
						</div>
						<span className="pax-name">{values['title_name_' + type + '_' + i] + ' ' + values['first_name_' + type + '_' + i]}</span>
					</div>
					{
						this.getSegmentSeatsPriceKafila(indexCount, "price")
					}
				</li>
			)
		}
		return totalPaxDeatils;
	}

	dispalyPaxDetailsKafilaRound = (count, otherCount, type, values) => {
		let totalPaxDeatils = [];
		let indexCount = '';
		for (let i = 1; i <= count; i++) {
			if (type === 'adult') {
				indexCount = i - 1;
			}
			else {
				indexCount = parseInt(otherCount) + parseInt(i - 1);
			}

			totalPaxDeatils.push(
				<li key={type + '' + i}>
					<div className="pax-info">
						<div className="pax-seat">
							{
								this.getSegmentSeatsPriceKafilaRound(indexCount, "seat")
							}
						</div>
						<span className="pax-name">{values['title_name_' + type + '_' + i] + ' ' + values['first_name_' + type + '_' + i]}</span>
					</div>
					{
						this.getSegmentSeatsPriceKafilaRound(indexCount, "price")
					}
				</li>
			)
		}
		return totalPaxDeatils;
	}
	

	//Get Number of seats according to number of filghts between routes that is number of connecting flights for tbo flight
	getSegmentSeatsPrice = (indexCount, type) => {
		let seats = [];
		let price = 0;
		if (this.props.ssrDetails.response && this.props.ssrDetails.response.seat_dynamic) {
			for (let seat_dynamic = 0; seat_dynamic < this.props.ssrDetails.response.seat_dynamic.length; seat_dynamic++) {
				let item = this.props.ssrDetails.response.seat_dynamic[seat_dynamic];
				for (let innerSeat = 0; innerSeat < item.segment_seat.length; innerSeat++) {
					seats.push(
						<i key={seat_dynamic + '' + innerSeat} className={this.state.selectedSeat[seat_dynamic] && this.state.selectedSeat[seat_dynamic][innerSeat] && this.state.selectedSeat[seat_dynamic][innerSeat][indexCount] ? 'icon icon-seat2 yellow-seat' : 'icon icon-seat2'}>
							<span>{this.state.selectedSeat[seat_dynamic] && this.state.selectedSeat[seat_dynamic][innerSeat] && this.state.selectedSeat[seat_dynamic][innerSeat][indexCount]}</span>
						</i>
					);
					if (this.state.selectedSeatPrize[seat_dynamic] && this.state.selectedSeatPrize[seat_dynamic][innerSeat]) {
						price += parseInt(this.state.selectedSeatPrize[seat_dynamic][innerSeat][indexCount]);
					}
				}
			}
		}
		if (type === 'seat')
			return seats
		else if (type === 'price') {
			return (<div className="pax-price">
				<span>
					<i className="fa fa-inr" aria-hidden="true">
					</i>{price ? price : 0}</span>
			</div>)
		}
	}
	// segment seat kafila======================================tested ok
	getSegmentSeatsPriceKafila = (indexCount, type) => {
		let seats = [];
		let price = 0;
		const { totalPassenger } = this.state;
		let orignalSeat = [];
		let orignalPrice = []
		let filterSeats = this.state.selectedSeat && this.state.selectedSeat.length > 0 && this.state.selectedSeat.flatMap((value) => {
			return value
		})
		// let forOnePassanger = filterSeats && filterSeats.length > 0 && filterSeats.filter(subarray => subarray && subarray.length > 0);
		// const mergedArray = this.state.selectedSeat && this.state.selectedSeat.length > 0 && this.state.selectedSeat.map(subArray =>
		// 	subArray && subArray.filter(item => Array.isArray(item)).flat()
		// );

		// const finalResultSeat = mergedArray && mergedArray.length > 0 && mergedArray.map(subArray => {
		// 	const uniqueItems = [...new Set(subArray)];
		// 	return uniqueItems.length === 1 ? uniqueItems[0] : uniqueItems;
		// });
		orignalSeat.push(filterSeats)
		// for price===================================================================================================
		let priceCopy = this.state.selectedSeatPrices && this.state.selectedSeatPrices.length > 0 && this.state.selectedSeatPrices.flatMap((item) => {
			return item
		})
		const forOnePassangerPrice = priceCopy && priceCopy.length > 0 && priceCopy.filter(subarray => subarray && subarray.length > 0)

		orignalPrice.push(forOnePassangerPrice)

		if (this.props.location.state.index !== 1 && this.props.ssrKafilaDetails.response && this.props.ssrKafilaDetails.response.result && this.props.ssrKafilaDetails.response.result.seat_dynamic && this.props.ssrKafilaDetails.response.result.seat_dynamic.length>0) {
			for (let seat_dynamic = 0; seat_dynamic < this.props.ssrKafilaDetails.response.result.seat_dynamic.length / 2; seat_dynamic++) {
				let item = this.props.ssrKafilaDetails.response.result.seat_dynamic[seat_dynamic];
				for (let innerSeat = 0; innerSeat < this.props.ssrKafilaDetails.response.result.seat_dynamic.length; innerSeat++) {
					seats.push(
						<i key={seat_dynamic + '' + innerSeat} className={orignalSeat[seat_dynamic] && orignalSeat[seat_dynamic][innerSeat] && orignalSeat[seat_dynamic][innerSeat][indexCount] ? 'icon icon-seat2 yellow-seat' : 'icon icon-seat2'}>
							<span>{orignalSeat[seat_dynamic] && orignalSeat[seat_dynamic][innerSeat] && orignalSeat[seat_dynamic][innerSeat][indexCount]}</span>
						</i>
					);
					if (orignalPrice[seat_dynamic] && orignalPrice[seat_dynamic][innerSeat]) {
						price += parseInt(orignalPrice[seat_dynamic][innerSeat][indexCount]);
					}
				}
			}
		}

		if (type === 'seat')
			return seats
		else if (type === 'price') {
			return (<div className="pax-price">
				<span>
					<i className="fa fa-inr" aria-hidden="true">
					</i>{price ? price : 0}</span>
			</div>)
		}
	}

	getSegmentSeatsPriceKafilaRound = (indexCount, type) => {
		let seats = [];
		let price = 0;
		const { totalPassenger } = this.state;
		let orignalSeat = [];
		let orignalPrice = []
		let filterSeats = this.state.selectedSeat && this.state.selectedSeat.length > 0 && this.state.selectedSeat.flatMap((value) => {
			return value
		})
		// let forOnePassanger = filterSeats && filterSeats.length > 0 && filterSeats.filter(subarray => subarray && subarray.length > 0);

		orignalSeat.push(filterSeats)



		// for price===================================================================================================

		let priceCopy = this.state.selectedSeatPrices && this.state.selectedSeatPrices.length > 0 && this.state.selectedSeatPrices.flatMap((item) => {
			return item
		})
		const forOnePassangerPrice = priceCopy && priceCopy.length > 0 && priceCopy.filter(subarray => subarray && subarray.length > 0)
		const mergedArrayPrice = this.state.selectedSeatPrices && this.state.selectedSeatPrices.length > 0 && this.state.selectedSeatPrices.map(subArray =>
			subArray && subArray.filter(item => Array.isArray(item)).flat()
		);

		if (totalPassenger == 1) {
			orignalPrice.push(forOnePassangerPrice)
		} else {
			// let multiplePassenger=finalResultPrice.filter(subarray => subarray.length > 0);
			orignalPrice.push(mergedArrayPrice)
		}

		if (this.props.location.state.index == 1 && this.props.ssrKafilaDetailsRound.response && this.props.ssrKafilaDetailsRound.response.result && this.props.ssrKafilaDetailsRound.response.result.seat_dynamic && this.props.ssrKafilaDetailsRound.response.result.seat_dynamic.length>0) {
			for (let seat_dynamic = 0; seat_dynamic < this.props.ssrKafilaDetailsRound.response.result.seat_dynamic.length; seat_dynamic++) {
				let item = this.props.ssrKafilaDetailsRound.response.result.seat_dynamic[seat_dynamic];
				for (let innerSeat = 0; innerSeat < item.segment_seat.length; innerSeat++) {
					seats.push(
						<i key={seat_dynamic + '' + innerSeat} className={orignalSeat[seat_dynamic] && orignalSeat[seat_dynamic][innerSeat] && orignalSeat[seat_dynamic][innerSeat][indexCount] ? 'icon icon-seat2 yellow-seat' : 'icon icon-seat2'}>
							<span>{orignalSeat[seat_dynamic] && orignalSeat[seat_dynamic][innerSeat] && orignalSeat[seat_dynamic][innerSeat][indexCount]}</span>
						</i>
					);
					if (orignalPrice[seat_dynamic] && orignalPrice[seat_dynamic][innerSeat]) {
						price += parseInt(orignalPrice[seat_dynamic][innerSeat][indexCount]);
					}
				}
			}
		}
		if (type === 'seat')
			return seats
		else if (type === 'price') {
			return (<div className="pax-price">
				<span>
					<i className="fa fa-inr" aria-hidden="true">
					</i>{price ? price : 0}</span>
			</div>)
		}
	}
	   
		//Get Number of seats according to number of filghts between routes that is number of connecting flights for riya flight    
		//seat with pax details - final
	getSegmentSeatsPriceRiya = (indexCount, type) => {
		
			let seats = [];
			let price = 0;
		
			if (this.props.seatRiyaInfo.response && this.props.seatRiyaInfo.response.status === 'ok') {
				const responseKeys = Object.keys(this.props.seatRiyaInfo.response).filter(key => !isNaN(key));
				
				for (let key of responseKeys) {
					let segment = this.props.seatRiyaInfo.response[key];
					
					for (let innerSeat = 0; innerSeat < responseKeys.length; innerSeat++) {
						const isSelected = this.state.selectedSeat[key] && this.state.selectedSeat[key][innerSeat] && this.state.selectedSeat[key][innerSeat][indexCount];
						if (isSelected) {
							seats.push(
								<i key={key + '' + innerSeat} className={isSelected? 'icon icon-seat2 yellow-seat': 'icon icon-seat2'}>
									<span>{isSelected}</span>
								</i>
							);
		
							if (this.state.selectedSeatPrize[key] && this.state.selectedSeatPrize[key][innerSeat]) {
								price += parseInt(this.state.selectedSeatPrize[key][innerSeat][indexCount], 10);
							}
						}
					}
				}
			}
		
			if (type === 'seat') {
				return seats;
			} else if (type === 'price') {
				return (
					<div className="pax-price">
						<span>
							<i className="fa fa-inr" aria-hidden="true"></i>
							{price ? price : 0}
						</span>
					</div>
				);
			}
		}

	//Get Number of seats according to number of filghts between routes that is number of connecting flights for Tripjack flight
	getSegmentSeatsPriceTripJack = (indexCount, type) => {
		let seats = [];
		let price = 0;
		if (this.props.seatInfo.response && this.props.seatInfo.response.results) {
			for (let result = 0; result < this.props.seatInfo.response.results.length; result++) {
				let segment_key = Object.keys(this.props.seatInfo.response.results[result])
				for (let innerSeat = 0; innerSeat < segment_key.length; innerSeat++) {
				
					seats.push(
						<i key={result + '' + innerSeat} className={this.state.selectedSeat[result] && this.state.selectedSeat[result][innerSeat] && this.state.selectedSeat[result][innerSeat][indexCount] ? 'icon icon-seat2 yellow-seat' : 'icon icon-seat2'}>
							<span>{this.state.selectedSeat[result] && this.state.selectedSeat[result][innerSeat] && this.state.selectedSeat[result][innerSeat][indexCount]}</span>
						</i>
					);
					if (this.state.selectedSeatPrize[result] && this.state.selectedSeatPrize[result][innerSeat]) {
						price += parseInt(this.state.selectedSeatPrize[result][innerSeat][indexCount]);
					}
				}
			}
		}
		if (type === 'seat')
			return seats
		else if (type === 'price') {
			return (<div className="pax-price">
				<span>
					<i className="fa fa-inr" aria-hidden="true">
					</i>{price ? price : 0}</span>
			</div>)
		}
	}

	//Get extra baggage ,meal and seat value
	getAdditionalCharges = (type, index, amount,quantity) => {
		let baggage = this.state.baggage;
		let meal = this.state.meal;
		let baggageQty = this.state.baggageQty;
		let baggageAmt = 0;
		let mealAmt = 0;
		if (type === 'baggage') {
			baggage[index] = amount;
			for (var i in baggage) {
				baggageAmt += parseInt(baggage[i])
			}
			this.setState({
				baggage: baggage,
				baggageAmt: baggageAmt,
				baggageQty: parseInt(baggageQty) + parseInt(quantity)
			})
		} else if (type === 'meal') {
			meal[index] = amount;
			for (var j in meal) {
				mealAmt += parseInt(meal[j])
			}
			this.setState({
				meal: meal,
				mealAmt: mealAmt
			})
		}
	}

	//Get total seats price
	getTotalSeatPrice = () => {
		let finalPrice = 0;

		if (this.state.selectedSeatPrize && this.state.selectedSeatPrize.length > 0) {
			for (let i = 0; i < this.state.selectedSeatPrize.length; i++) {
				for (let j = 0; j < (this.state.selectedSeatPrize[i] && this.state.selectedSeatPrize[i].length); j++) {
					for (let k = 0; k < (this.state.selectedSeatPrize[i]&&this.state.selectedSeatPrize[i][j]&&this.state.selectedSeatPrize[i][j].length); k++) {
						finalPrice += parseInt(this.state.selectedSeatPrize[i]&&this.state.selectedSeatPrize[i][j][k]);
					}
				}
			}
		}
		return finalPrice
	}
	// total seat price kafila
	getTotalSeatPriceKafila = () => {
		return this.state.totalPrice
	}

	getFlightSeats = (segment, minIndex, maxIndex, segmentIndex, OuterIndex, seatsMap, totalSeatCount) => {
		const exitRowSeatType = [6, 8, 9, 12, 14, 15, 18, 20, 21, 22, 23, 24, 43, 45, 47];
		const reclineSeatType = [4, 7, 8, 10, 13, 14, 16, 19, 20, 22, 23, 24];
		let seatsArray = [];
		for (let i = 0; i < segment.row_seats.length; i++) {
			// let seatCount = { aisle: 0 };
			let seatIndex = i;
			let seats = segment.row_seats[i];
			if (minIndex === 0)
				break;
			maxIndex = maxIndex === -1 ? segment.row_seats.length : maxIndex;
			if (seatIndex !== 0 && seatIndex >= minIndex && seatIndex <= maxIndex) {

				seatsArray.push(
					<React.Fragment key={i}>
						{
							(exitRowSeatType.indexOf(seats.seats[0].seat_type) !== -1) &&
							<li className="emergency-door-block"><i className="icon icon-health-left"></i><span>Emergency door </span><i className="icon icon-health-right"></i></li>

						}
						{
							(reclineSeatType.indexOf(seats.seats[0].seat_type) !== -1) &&
							<li className="recline-area">
							</li>

						}
						<li key={seatIndex}>
							<div className="col-seat">
								<ul>
									{
										this.getRows(seats, segmentIndex, OuterIndex, "first", seatsMap, totalSeatCount)
									}
								</ul>
							</div>

							<div className="col-seat">
								<ul>
									{
										seatsMap.secondCol !== 0 && this.getRows(seats, segmentIndex, OuterIndex, "second", seatsMap, totalSeatCount)
									}
								</ul>
							</div>
							{
								seatsMap.column === 3 &&
								<div className="col-seat">
									<ul>
										{
											seatsMap.thirdCol !== 0 && this.getRows(seats, segmentIndex, OuterIndex, "third", seatsMap, totalSeatCount)
										}
									</ul>
								</div>
							}
						</li>
					</React.Fragment>
				)
			}
		}
		return seatsArray
	}

	getFlightSeatsRiya = (segment,  segmentIndex, OuterIndex, totalSeatCount) => {
		// Find minimum and maximum values of XAxis and YAxis
		let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
		segment.forEach(seatRow => {
			seatRow.forEach(seat => {
				minX = Math.min(minX, parseInt(seat.XAxis));
				maxX = Math.max(maxX, parseInt(seat.XAxis));
				minY = Math.min(minY, parseInt(seat.YAxis));
				maxY = Math.max(maxY, parseInt(seat.YAxis));
			});
		});
	
	
		// Create a matrix to represent seat arrangement
	
		const matrix = [];
		for (let y = minY; y <= maxY; y++) {
			const row = [];
			for (let x = minX; x <= maxX; x++) {
				const seat = segment.flat().find(seat => parseInt(seat.XAxis) === x && parseInt(seat.YAxis) === y);
				
				row.push(seat);
			}
			matrix.push(row);
		}

		// Map over the matrix to render seat information
		const seatsArray = [];
		matrix.forEach((row, rowIndex) => {
			seatsArray.push(
				<React.Fragment key={segmentIndex + "." + rowIndex}>
					<li key = {rowIndex}>
						<div className="col-seat separate-seat">
							<ul>
								{row.map((seat, columnIndex) => {
									
									totalSeatCount.totalSeats++;
									return (
									
										seat && <li key={totalSeatCount.totalSeats}
											
												className={
													seat.SeatAvailability === "Closed"
														? 'black-seat'
														: this.state.selectedSeat[OuterIndex] &&
														  this.state.selectedSeat[OuterIndex][segmentIndex] &&
														  this.state.selectedSeat[OuterIndex][segmentIndex].includes(
															  seat.SeatName
														  )
														? 'yellow-seat'
														: ''
												}
											
													onClick={
														seat.SeatAvailability === "Open"
															? this.saveSeatDetailRiya.bind(
																  this,
																  seat.SeatName,
																  seat.SeatAmount,
																  seat.SeatID,
																  segmentIndex,
																  OuterIndex
															  )
															: null
													}
												>
													<i className="icon icon-seat2">
														<small>{seat.SeatName}</small>
														<span className="tooltip-seat">
															<samp>{"Seat No : " + seat.SeatName + ","}</samp>{" "}
															<samp>{"Price : Rs. " + seat.SeatAmount}</samp>
														</span>
													</i>
												
										</li>
									)
								}
								)}
							</ul>
						</div>
					</li>
				</React.Fragment>
			);
		});
	
		return seatsArray;
	};

	getFlightSeatsTripJack = (segment, segmentIndex, OuterIndex, totalSeatCount) => {
		let seatsArray = [];
		let rowSeats = [];

		segment.sInfo && segment.sInfo.forEach((item, index) => {
			if(item && item.seatPosition){
				if(Array.isArray(rowSeats[item.seatPosition.row]) === false)
					{
						rowSeats[item.seatPosition.row] = [];
					}
					rowSeats[item.seatPosition.row][item.seatPosition.column] = item;
			}
		})
		rowSeats && rowSeats.length > 0 && rowSeats.forEach((item,index)=>{
			seatsArray.push(
				<React.Fragment key = {segmentIndex+"."+index}>
					<li key={index}>
						<div className="col-seat">
							<ul>
								{
									item.length > 0 && item.map((column_item,column)=>{
									totalSeatCount.totalSeats++;
									return (
										
										column <=  3 &&  <li key={totalSeatCount.totalSeats}
											className={column_item.isBooked === true ? 'black-seat' : (this.state.selectedSeat[OuterIndex] && this.state.selectedSeat[OuterIndex][segmentIndex]
												&& this.state.selectedSeat[OuterIndex][segmentIndex].includes(column_item.code) ? 'yellow-seat' : '')}
											onClick={column_item.isBooked === false ? this.saveSeatDetail.bind(this, column_item.code, column_item.amount, segmentIndex, OuterIndex) : null}>
											<i className="icon icon-seat2"><small>{column_item.code}</small>
												<span className="tooltip-seat"><samp>{"Seat No : " + column_item.code + ","}</samp>  <samp>{"Price : Rs. " + column_item.amount}</samp></span></i>
										</li>
									)
									})
								}
								{
									segment.sData.column > 1 && !item.hasOwnProperty("1") && <li><i className="icon icon-seat2 space-between-seats"></i></li>
								}
								{
									segment.sData.column > 3 && !item.hasOwnProperty("2") && <li><i className="icon icon-seat2 space-between-seats"></i></li>
								}
								{
									segment.sData.column > 5 && !item.hasOwnProperty("3") && <li><i className="icon icon-seat2 space-between-seats"></i></li>
								}
								
							</ul>
						</div>
						<div className="col-seat">
							<ul>
								{
									item.length > 0 && item.map((column_item,column)=>{
									totalSeatCount.totalSeats++;
									return (
										
										column >  3 &&  <li key={totalSeatCount.totalSeats}
											className={column_item.isBooked === true ? 'black-seat' : (this.state.selectedSeat[OuterIndex] && this.state.selectedSeat[OuterIndex][segmentIndex]
												&& this.state.selectedSeat[OuterIndex][segmentIndex].includes(column_item.code) ? 'yellow-seat' : '')}
											onClick={column_item.isBooked === false ? this.saveSeatDetail.bind(this, column_item.code, column_item.amount, segmentIndex, OuterIndex) : null}>
											<i className="icon icon-seat2"><small>{column_item.code}</small>
												<span className="tooltip-seat"><samp>{"Seat No : " + column_item.code + ","}</samp>  <samp>{"Price : Rs. " + column_item.amount}</samp></span></i>
										</li>
									)
									})
								}
								
								
							</ul>
						</div>
					</li>
				</React.Fragment>
			)
		})
	
		return seatsArray
	}

	numberOfAisleSeats = (seats) => {
		const aisleSeatType = [2, 10, 11, 12, 13, 14, 15, 23, 31, 32, 33, 46, 47, 34, 36, 37, 38];
		let aisleSeatCount = 0
		for (let i = 0; i < seats.length; i++) {
			if (aisleSeatType.indexOf(seats[i].seat_type) !== -1) {
				aisleSeatCount++;
			}
		}

		return aisleSeatCount;

	}

	
	getRows = (seats, segmentIndex, OuterIndex, column, seatsMap, totalSeatCount) => {
		const windowSeatType = [1, 4, 5, 6, 7, 8, 9, 22, 25, 26, 27, 42, 43];
		const MiddleSeatType = [3, 16, 17, 18, 19, 20, 21, 24, 28, 29, 30, 44, 45, 35, 39, 40, 41];
		const aisleSeatType = [2, 10, 11, 12, 13, 14, 15, 23, 31, 32, 33, 46, 47, 34, 36, 37, 38];
		const bulkheadSeatType = [25, 27, 28, 30, 31, 33, 36, 38, 39, 41, 42, 43, 44, 45, 46, 47];
		const centreAisleSeatType = [34, 36, 37, 38];
		
		let seatCount = 0, totalSeats = 0;// eslint-disable-line no-unused-vars
		let startPoint = 100, endpoint = seats.seats.length;
		let aisleSeatCount = 0, windowSeatCount = 0, middleSeatCount = 0;
		let rows = [];
		for (let i = 0; i < seats.seats.length; i++) {
			
			if (aisleSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
				aisleSeatCount++
			}
			else if (windowSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
				windowSeatCount++
			}
			else if (MiddleSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
				middleSeatCount++
			}

		}
		if (windowSeatCount === 0 && aisleSeatCount === 0 && middleSeatCount > 0) {
			totalSeats = 0;
			if (column === "first") {
				startPoint = 0
				if (middleSeatCount === 4) {
					endpoint = 2;
				}
			}
			else if (column === "second") {
				if (middleSeatCount === 4) {
					startPoint = 2
					endpoint = 4;
				}
			}
			else if (column === "third") {

			}
		}
		else if (column === "first") {
			totalSeats = seatsMap.firstCol;
			startPoint = 0;
			if (aisleSeatType.indexOf(seats.seats[0].seat_type) !== -1 || centreAisleSeatType.indexOf(seats.seats[0].seat_type) !== -1) {
				startPoint = seats.seats.length;
			}

			for (let i = 0, j = 0; i < seats.seats.length; i++) {
				if (aisleSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
					j++
				}
				if (j === 1) {
					endpoint = i + 1;
					break;
				}
			}
		}
		else if (column === "second") {
			totalSeats = seatsMap.secondCol;
			if (aisleSeatType.indexOf(seats.seats[0].seat_type) !== -1) {
				for (let i = 0, j = 0; i < seats.seats.length; i++) {
					if (aisleSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
						j++
						if (seatsMap.column === 3) {
							if (aisleSeatCount === 3 || aisleSeatCount === 2) {
								startPoint = 0;
								if (aisleSeatCount === 2) {
									if (centreAisleSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
										endpoint = i + 1;
									}
								}
								else {
									if (j === 2)
										endpoint = i + 1;
								}
							}
							else {
								startPoint = seats.seats.length
							}
						}
						else if (seatsMap.column === 2) {
							if (aisleSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
								startPoint = 0;
								endpoint = seats.seats.length
							}
						}
					}

				}
			}
			else {
				for (let i = 0, j = 0; i < seats.seats.length; i++) {
					if (aisleSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
						j++
						if (seatsMap.column === 3) {
							if (aisleSeatCount === 4) {
								if (j === 2) {
									startPoint = i;
								}
								else if (j === 3) {
									endpoint = i + 1
								}
							}
							else if (aisleSeatCount === 3) {
								if (j === 2) {
									startPoint = i;
									endpoint = i + 1;
								}
							}
							else if (aisleSeatCount === 2) {
								if (centreAisleSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
									startPoint = i;
									endpoint = seats.seats.length; //i + 1;
								}
							}
						}
						else if (seatsMap.column === 2) {
							if (j === 2) {
								startPoint = i;
								endpoint = seats.seats.length
							}
						}
					}
				}

			}
			if (seatsMap.column === 2) {
				if (seatsMap.secondCol === 2) {
					totalSeatCount.totalSeats++
					rows.push(
						<li key={totalSeatCount.totalSeats} >
							{
								<span className="bulkhead-line" style={{ visibility: 'hidden' }}></span>
							}
							<i className="icon icon-seat2 space-between-seats"></i>

						</li>
					);
				}
				let seatCount = 0, windowSeat = false;
				for (let i = startPoint; i < endpoint; i++) {
					seatCount++;
					if (windowSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
						windowSeat = true
					}
				}
				if (seatCount < seatsMap.secondCol && windowSeat) {

					for (let i = seatCount; i < seatsMap.secondCol; i++) {
						totalSeatCount.totalSeats++
						rows.push(
							<li key={totalSeatCount.totalSeats} >
								{
									<span className="bulkhead-line" style={{ visibility: 'hidden' }}></span>
								}
								<i className="icon icon-seat2 space-between-seats"></i>

							</li>
						);
					}
				}
			}
		}
		else if (column === "third") {
			totalSeats = seatsMap.thirdCol;
			for (let i = 0, j = 0; i < seats.seats.length; i++) {
				if (aisleSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
					j++
					if (j === 3) {
						startPoint = i + 1;
					}
				}
			}
			if (aisleSeatType.indexOf(seats.seats[0].seat_type) !== -1) {
				for (let i = 0, j = 0; i < seats.seats.length; i++) {
					if (aisleSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
						j++

						if (aisleSeatCount === 3) {
							if (j === 3) {
								startPoint = i;
							}
						}
						else if (aisleSeatCount === 2) {

							if (j === 2) {
								startPoint = i;
							}
							if (centreAisleSeatType.indexOf(seats.seats[i].seat_type) !== -1) {
								startPoint = i + 1;;
							}
						}
						else if (aisleSeatCount === 1) {
							if (j === 1) {
								startPoint = 1
							}
						}
					}
				}
			}
			let seatCount = 0
			for (let i = startPoint; i < endpoint; i++) {
				seatCount++;
			}
			if (seatCount < seatsMap.thirdCol) {
				for (let i = seatCount; i < seatsMap.thirdCol; i++) {
					totalSeatCount.totalSeats++
					rows.push(
						<li key={totalSeatCount.totalSeats} >
							<i className="icon icon-seat2 space-between-seats"></i>

						</li>
					);
				}
			}
		}
		if (seats && seats.seats[startPoint]) {
			seatCount = 0;
			for (let i = startPoint; i < endpoint; i++) {
				seatCount++;
				let index = i;
				totalSeatCount.totalSeats++;
				rows.push(
					<li key={totalSeatCount.totalSeats}
						className={seats.seats[index].availablity_type !== 1 ? 'black-seat' : (this.state.selectedSeat[OuterIndex] && this.state.selectedSeat[OuterIndex][segmentIndex]
							&& this.state.selectedSeat[OuterIndex][segmentIndex].includes(seats.seats[index].code) ? 'yellow-seat' : '')}
						onClick={seats.seats[index].availablity_type === 1 ? this.saveSeatDetail.bind(this, seats.seats[index].code, seats.seats[index].price, segmentIndex, OuterIndex) : null}>
						{
							// (bulkheadSeatType.indexOf(seats.seats[i].seat_type) !== -1) &&
							<span className="bulkhead-line" style={{ visibility: bulkheadSeatType.indexOf(seats.seats[i].seat_type) !== -1 ? 'visible' : 'hidden' }}></span>
						}
						<i className="icon icon-seat2"><small>{seats.seats[index].code}</small>
							<span className="tooltip-seat"><samp>{"Seat No : " + seats.seats[index].code + ","}</samp>  <samp>{"Price : Rs. " + seats.seats[index].price}</samp></span></i>
					</li>
				);
			}

		}

		if (column === "first") {
			for (let i = seatCount; i < seatsMap.firstCol; i++) {
				totalSeatCount.totalSeats++
				rows.push(
					<li key={totalSeatCount.totalSeats} >
						{
							<span className="bulkhead-line" style={{ visibility: 'hidden' }}></span>
						}
						<i className="icon icon-seat2 space-between-seats"></i>

					</li>
				);
			}
		}
		else if (column === "second" && seatsMap.column === 3) {
			for (let i = seatCount; i < seatsMap.secondCol; i++) {
				totalSeatCount.totalSeats++
				rows.push(
					<li key={totalSeatCount.totalSeats} >
						{
							<span className="bulkhead-line" style={{ visibility: 'hidden' }}></span>
						}
						<i className="icon icon-seat2 space-between-seats"></i>

					</li>
				);
			}
		}

		if (seatsMap.column === 2 && seatsMap.firstCol === 2 && column === 'first') {
			totalSeatCount.totalSeats++
			rows.push(
				<li key={totalSeatCount.totalSeats} >
					{
						<span className="bulkhead-line" style={{ visibility: 'hidden' }}></span>
					}
					<i className="icon icon-seat2 space-between-seats"></i>

				</li>
			);

		}

		return rows;
	}

	//*********************************************************************Seat Selection Module End********************************************************/
	//Get combined fare for LCC return flights
	getLCCSpecialReturnFare = (data) => {
		let published_fare = parseInt(data[0].fare.published_fare) + parseInt(data[1].fare.published_fare);
		let offered_fare = parseInt(data[0].fare.offered_fare) + parseInt(data[1].fare.offered_fare);
		data[0].fare.published_fare = published_fare;
		data[0].fare.offered_fare = offered_fare;
		for (let i = 0; i < data[0].fare_breakdown.length; i++) {
			let item = data[0].fare_breakdown[i];
			item.base_fare = parseInt(item.base_fare) + parseInt(data[1].fare_breakdown[i].base_fare);
		}
		data[0].fare.tax = parseInt(data[0].fare.tax) + parseInt(data[1].fare.tax);
		data[0].fare.yq_tax = parseInt(data[0].fare.yq_tax) + parseInt(data[1].fare.yq_tax);
		data[0].fare.other_charges = parseInt(data[0].fare.other_charges) + parseInt(data[1].fare.other_charges);
		data[0].fare.discount = parseInt(data[0].fare.discount) + parseInt(data[1].fare.discount);
		data[0].fare.agent_discount = parseInt(data[0].fare.agent_discount) + parseInt(data[1].fare.agent_discount);
		data[0].fare.other_tax = parseInt(data[0].fare.other_tax) + parseInt(data[1].fare.other_tax);
		data[0].fare.service_charge = parseInt(data[0].fare.service_charge) + parseInt(data[1].fare.service_charge);
		this.setState({
			count: parseInt(this.state.count) + 1,
			fareQuoteData: data[0]
		})
		return data[0];
	}

	enterPressed = (event) => {
		var code = event.keyCode || event.which;
		if (code === 13) {
			this.handleSearch();
		}
	}

	searchWingRow = (segment_seat) => {
		const wingSeatType = [5, 7, 9, 11, 13, 15, 17, 19, 21, 22, 23, 24, 42, 44, 46];
		let wingSeatArray = [];
		for (let j = 0; j < segment_seat.length; j++) {
			let wingSeat = { start: 0, end: 0, }
			for (let i = 0; i < segment_seat[j].row_seats.length; i++) {
				if (wingSeatType.indexOf(segment_seat[j].row_seats[i].seats[0].seat_type) !== -1) {
					wingSeat.start = i;
					break;
				}
			}
			for (let i = wingSeat.start; i < segment_seat[j].row_seats.length; i++) {
				if (wingSeatType.indexOf(segment_seat[j].row_seats[i].seats[0].seat_type) === -1) {
					wingSeat.end = i - 1;
					break;
				}
			}
			wingSeatArray.push(wingSeat);
		}
		return wingSeatArray;
	}

	seatArrangement = (segment_seat) => {
		const windowSeatType = [1, 4, 5, 6, 7, 8, 9, 22, 25, 26, 27, 42, 43];
		const MiddleSeatType = [3, 16, 17, 18, 19, 20, 21, 24, 28, 29, 30, 44, 45, 35, 39, 40, 41];
		const aisleSeatType = [2, 10, 11, 12, 13, 14, 15, 23, 31, 32, 33, 46, 47, 34, 36, 37, 38];
		let seatMapArray = [];
		let aisleSeatCount = 0, middleSeatCount = 0, windowSeatCount = 0, column = 0, firstCol = 0, secondCol = 0, thirdCol = 0;// eslint-disable-line no-unused-vars
		for (let j = 0; j < segment_seat.length; j++) {
			let seatsMap = { column: 0, firstCol: 0, secondCol: 0, thirdCol: 0 }
			for (let i = 0; i < segment_seat[j].row_seats.length; i++) {
				aisleSeatCount = 0; middleSeatCount = 0; windowSeatCount = 0; column = 0; firstCol = 0; secondCol = 0; thirdCol = 0;
				for (let k = 0; k < segment_seat[j].row_seats[i].seats.length; k++) {
					if (windowSeatType.indexOf(segment_seat[j].row_seats[i].seats[k].seat_type) !== -1) {
						if (column === 0) {
							column = 1
						}

						if (column === 1) {
							windowSeatCount = 11
							firstCol++;
						}
						else if (column === 2) {
							windowSeatCount = 12;
							secondCol++;
						}
						else if (column === 3) {
							windowSeatCount++;
							thirdCol++;
						}
					}
					else if (MiddleSeatType.indexOf(segment_seat[j].row_seats[i].seats[k].seat_type) !== -1) {

						if (column === 0) {
							column = 1
						}

						if (middleSeatCount === 11) {
							column = 2
						}

						else if (middleSeatCount === 12) {
							column = 3
						}
						
						if (column === 1) {
							middleSeatCount = 11
							firstCol++;
						}
						else if (column === 2) {
							middleSeatCount = 12;
							secondCol++;
						}
						else if (column === 3) {
							middleSeatCount++;
							thirdCol++;
						}
					}
					else if (aisleSeatType.indexOf(segment_seat[j].row_seats[i].seats[k].seat_type) !== -1) {

						if (aisleSeatCount === 11) {
							column = 2
						}
						else if (aisleSeatCount === 13) {
							column = 3
						}

						if (column === 1) {
							aisleSeatCount = 11
							firstCol++;
						}
						else if (column === 2) {
							aisleSeatCount++;
							secondCol++;
						}
						else if (column === 3) {
							aisleSeatCount++;
							thirdCol++;
						}
						
					}
					seatsMap.column = seatsMap.column < column ? column : seatsMap.column;
					seatsMap.firstCol = seatsMap.firstCol < firstCol ? firstCol : seatsMap.firstCol;
					seatsMap.secondCol = seatsMap.secondCol < secondCol ? secondCol : seatsMap.secondCol;
					seatsMap.thirdCol = seatsMap.thirdCol < thirdCol ? thirdCol : seatsMap.thirdCol;
				}

			}
			seatMapArray.push(seatsMap);
		}
		return seatMapArray;
	}
	renderRiyaSeatDetails(ssrDetails, seatRiyaInfo) {
		
		// Check if ssrDetails and seatRiyaInfo are available
		if (ssrDetails && ssrDetails.response && ssrDetails.response.results && ssrDetails.response.results.segments &&
			seatRiyaInfo && seatRiyaInfo.response) {
				
			// Extract segments from ssrDetails
			const segments = ssrDetails.response.results.segments;
			let totalSeatCount = { totalSeats: 0 }
			let filteredSeatInfo = null;
			// Render seat details
			return (
				<div>
					{segments.map((segmentGroup, index) => (
						<div className="mid-seat-select" key={index}>
							
							{segmentGroup.map((segment, segmentIndex) => {
								
								const flightId = segment.flightId;
								
								// Filter seat information based on flightId

																
								if (seatRiyaInfo.response && typeof seatRiyaInfo.response === "object") {
									
									filteredSeatInfo = [];
									Object.keys(seatRiyaInfo.response).forEach(key => {
										const seatInfoArray = seatRiyaInfo.response[key];
								
										if (Array.isArray(seatInfoArray)) {
										
											const foundSeat = seatInfoArray.filter(seat => seat.FlightID === flightId);
											if (foundSeat) {
												filteredSeatInfo.push(foundSeat);
											}
										} 
									});
								} 
						
								
									if (!filteredSeatInfo || seatRiyaInfo.error === true) {
										return (
											<div className="mid-seat-content" key={segmentIndex} style={{ display: this.state.seatIndex === segmentIndex && this.state.seatOuterIndex === index ? 'block' : 'none' }}>
												<p className="text-danger" style={{ position: 'absolute', top: 300, right: 200 }}>Sorry, there is no seat selection available</p>
											</div>
										);
									}


								return (
									<div className="mid-seat-content" key={segmentIndex} style={{ display: this.state.seatIndex === segmentIndex && this.state.seatOuterIndex === index ? 'block' : 'none' }}>
										<div className="tbody-seat">
											<div className="">
												<ul>
													{this.getFlightSeatsRiya(filteredSeatInfo, segmentIndex, index, totalSeatCount)}
												</ul>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					))}
				</div>
			);
		}
	}

	// Available Room Details popup data
	renderSeatDetails(ssrDetails) {
		let wingSeatArray = null;
		let seatMapArray = null;
		if (this.props.ssrDetails.response && this.props.ssrDetails.response.seat_dynamic) {
			wingSeatArray = this.searchWingRow(this.props.ssrDetails.response.seat_dynamic[0].segment_seat);
			seatMapArray = this.seatArrangement(this.props.ssrDetails.response.seat_dynamic[0].segment_seat)
		}
		return (
			this.props.ssrDetails.response && this.props.ssrDetails.response.seat_dynamic &&
			this.props.ssrDetails.response.seat_dynamic.map((item, index) => {
				let totalSeatCount = { totalSeats: 0 }
				return (
					<div className="mid-seat-select" key={index}>
						{
							item.segment_seat.map((segment, segmentIndex) => {
								if (segment.row_seats.length === 1) {
									return (
										<div className="mid-seat-content" key={segmentIndex} style={{ display: this.state.seatIndex === segmentIndex && this.state.seatOuterIndex === index ? 'block' : 'none' }}>

											<p className="text-danger" style={{ position: 'absolute', top: 300, right: 200, }}>Sorry, there is no seat selection available</p>

										</div>
									)
								}
								return (
									<div className="mid-seat-content" key={segmentIndex} style={{ display: this.state.seatIndex === segmentIndex && this.state.seatOuterIndex === index ? 'block' : 'none' }}>
										<div className="tbody-seat">
											<div className="">
												<ul>
													{
														this.getFlightSeats(segment, 1, (wingSeatArray[segmentIndex].start - 1), segmentIndex, index, seatMapArray[segmentIndex], totalSeatCount)
													}
												</ul>
											</div>
											<div className="emergency-gate-wing" style={{ display: wingSeatArray[segmentIndex].start ? "block" : "none" }}>
												<ul>
													{
														this.getFlightSeats(segment, wingSeatArray[segmentIndex].start, wingSeatArray[segmentIndex].end, segmentIndex, index, seatMapArray[segmentIndex], totalSeatCount)
													}
												</ul>
											</div>
											<div className="" style={{ display: wingSeatArray[segmentIndex].start ? "block" : "none" }}>
												<ul>
													{
														this.getFlightSeats(segment, (wingSeatArray[segmentIndex].end + 1), -1, segmentIndex, index, seatMapArray[segmentIndex], totalSeatCount)
													}
												</ul>
											</div>
										</div>
									</div>
								)
							})}
					</div>
				)
			})
		);
	}

	// Available Room Details popup data
	renderTripJackSeatDetails(seatInfo) {
		
		return (
			seatInfo.response && seatInfo.response.results &&
			this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments.map((item, index) => {
				let totalSeatCount = { totalSeats: 0 }
				let segmentSeatInfo = seatInfo.response.results;
				return (
					<div className="mid-seat-select" key={index}>
						{
							
								Object.keys(item).map((segment,segmentIndex) => {
									let segment_id = item[segmentIndex].segment_id;
								if (segmentSeatInfo&& !(segmentSeatInfo[0][segment_id] &&segmentSeatInfo[0][segment_id].sInfo)) {
									return (
										<div className="mid-seat-content" key={segmentIndex} style={{ display: this.state.seatIndex === segmentIndex && this.state.seatOuterIndex === index ? 'block' : 'none' }}>

											<p className="text-danger" style={{ position: 'absolute', top: 300, right: 200, }}>Sorry, there is no seat selection available</p>

										</div>
									)
								}
								return (
									<div className="mid-seat-content" key={segmentIndex} style={{ display: this.state.seatIndex === segmentIndex && this.state.seatOuterIndex === index ? 'block' : 'none' }}>
										<div className="tbody-seat">
											<div className="">
												<ul>
													{
														this.getFlightSeatsTripJack(segmentSeatInfo[0][segment_id], segmentIndex, index, totalSeatCount)
													}
												</ul>
											</div>
										</div>
									</div>
								)
							})}
					</div>
				)
			})
		);
	}

	renderKafilaSeat(apiResponse) {
		const { selectedSegmentIndex, selectedSeat } = this.state;
		const selectedSegment = apiResponse && apiResponse.seat_dynamic && apiResponse.seat_dynamic.length > 0 && apiResponse.seat_dynamic[selectedSegmentIndex];
		// const selectedSegment = apiResponse && apiResponse.seat_dynamic&&apiResponse.seat_dynamic.length>0&&apiResponse.seat_dynamic[selectedSegmentIndex];
		// this.state.firstRow && this.state.firstRow.length > 0 && this.state.firstRow[selectedSegmentIndex] && this.state.firstRow[selectedSegmentIndex].map((item) => {
		// 	return item.map((item) => {
		// 	})
		// })

		return (
			<div className="mid-seat-select-outer">

				<div className="mid-seat-select">
					<div className="mid-seat-content" >
						<div className="tbody-seat">

							<div className="mid-seats">
								<div className="seat-col seats-left">
									{this.state.firstRow && this.state.firstRow.length > 0 && this.state.firstRow[selectedSegmentIndex].map((row, rowIndex) => (
										<div className="mid-seat" key={rowIndex} >
											<ul>
												{row.map((seat, seatIndex) => (
													<li key={seatIndex} className={!seat.availablity_type ? "black-seat" : (selectedSeat[selectedSegmentIndex] &&
														selectedSeat[selectedSegmentIndex][selectedSegmentIndex] &&
														selectedSeat[selectedSegmentIndex][selectedSegmentIndex].includes(seat.code)) ? "yellow-seat" : ""}>
														<button className="seat-button"
															key={seatIndex}
															onClick={() => this.handleSeatSelection(seat.code, selectedSegmentIndex, seat.price)}
															disabled={!seat.availablity_type}
															style={{
																backgroundColor: (selectedSeat[selectedSegmentIndex] &&
																	selectedSeat[selectedSegmentIndex][selectedSegmentIndex] &&
																	selectedSeat[selectedSegmentIndex][selectedSegmentIndex].includes(seat.code))
																	? ''
																	: 'white',
																color: (selectedSeat[selectedSegmentIndex] &&
																	selectedSeat[selectedSegmentIndex][selectedSegmentIndex] &&
																	selectedSeat[selectedSegmentIndex][selectedSegmentIndex].includes(seat.code))
																	? 'white'
																	: 'black',
															}}
														>
															<div class="black-seat"><i class="icon icon-seat2"><small>{seat.code}</small><span class="tooltip-seat"><samp>Seat No : {seat.code},</samp>  <samp>Price : Rs. {seat.price}</samp></span></i></div>
														</button>
													</li>
												))}
											</ul>
										</div>
									))}
								</div>
								<div className="seat-col seats-right">
									{this.state.secondRow && this.state.secondRow.length > 0 && this.state.secondRow[selectedSegmentIndex] && this.state.secondRow[selectedSegmentIndex].map((row, rowIndex) => (
										<div className="mid-seat" key={rowIndex}>
											<ul >
												{row.map((seat, seatIndex) => (
													<li key={seatIndex} className={!seat.availablity_type ? "black-seat" : (selectedSeat[selectedSegmentIndex] &&
														selectedSeat[selectedSegmentIndex][selectedSegmentIndex] &&
														selectedSeat[selectedSegmentIndex][selectedSegmentIndex].includes(seat.code)) ? "yellow-seat" : ""}>
														<button className="seat-button"
															key={seatIndex}
															onClick={() => this.handleSeatSelection(seat.code, selectedSegmentIndex, seat.price)}
															disabled={!seat.availablity_type}
															style={{
																backgroundColor: (selectedSeat[selectedSegmentIndex] &&
																	selectedSeat[selectedSegmentIndex][selectedSegmentIndex] &&
																	selectedSeat[selectedSegmentIndex][selectedSegmentIndex].includes(seat.code))
																	? ''
																	: 'white',
																color: (selectedSeat[selectedSegmentIndex] &&
																	selectedSeat[selectedSegmentIndex][selectedSegmentIndex] &&
																	selectedSeat[selectedSegmentIndex][selectedSegmentIndex].includes(seat.code))
																	? 'white'
																	: 'black',
															}}
														>
															<div class="black-seat"><i class="icon icon-seat2"><small>{seat.code}</small><span class="tooltip-seat"><samp>Seat No : {seat.code},</samp>  <samp>Price : Rs. {seat.price}</samp></span></i></div>
														</button>
													</li>
												))}
											</ul>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	handleSeatSelection = (seatCode, segmentIndex, seatPrice) => {
		const { totalPassenger, selectedSeat } = this.state;

		const selectedSeatsCopy = [...this.state.selectedSeat];
		const selectedSeatPricesCopy = [...this.state.selectedSeatPrices];

		if (!selectedSeatsCopy[segmentIndex]) {
			selectedSeatsCopy[segmentIndex] = [];
			selectedSeatPricesCopy[segmentIndex] = [];
		}

		if (!selectedSeatsCopy[segmentIndex][segmentIndex]) {
			selectedSeatsCopy[segmentIndex][segmentIndex] = [];
			selectedSeatPricesCopy[segmentIndex][segmentIndex] = [];
		}

		const selectedSeatsInSegment = selectedSeatsCopy && selectedSeatsCopy[segmentIndex].flatMap(
			(row) => row
		);

		const selectedIndex = selectedSeatsInSegment && selectedSeatsInSegment.indexOf(seatCode);

		if (selectedIndex !== -1) {
			selectedSeatsCopy && selectedSeatsCopy[segmentIndex] && selectedSeatsCopy[segmentIndex][segmentIndex].splice(selectedIndex, 1);
			selectedSeatPricesCopy && selectedSeatPricesCopy[segmentIndex] && selectedSeatPricesCopy[segmentIndex][segmentIndex].splice(selectedIndex, 1);
		} else {
			const totalSelectedSeatsInSegment = selectedSeatsCopy && selectedSeatsCopy[segmentIndex] && selectedSeatsCopy[segmentIndex][segmentIndex] && selectedSeatsCopy[segmentIndex][segmentIndex].length;

			if (totalSelectedSeatsInSegment === totalPassenger) {
				selectedSeatsCopy && selectedSeatsCopy[segmentIndex] && selectedSeatsCopy[segmentIndex][segmentIndex] && selectedSeatsCopy[segmentIndex][segmentIndex].splice(0, 1);
				selectedSeatPricesCopy && selectedSeatPricesCopy[segmentIndex] && selectedSeatPricesCopy[segmentIndex][segmentIndex] && selectedSeatPricesCopy[segmentIndex][segmentIndex].splice(0, 1);
				selectedSeatsCopy && selectedSeatsCopy[segmentIndex] && selectedSeatsCopy[segmentIndex][segmentIndex] && selectedSeatsCopy[segmentIndex][segmentIndex].push(seatCode);
				selectedSeatPricesCopy && selectedSeatPricesCopy[segmentIndex] && selectedSeatPricesCopy[segmentIndex][segmentIndex] && selectedSeatPricesCopy[segmentIndex][segmentIndex].push(seatPrice);
			}

			if (totalSelectedSeatsInSegment < totalPassenger) {
				selectedSeatsCopy && selectedSeatsCopy[segmentIndex] && selectedSeatsCopy[segmentIndex][segmentIndex].push(seatCode);
				selectedSeatPricesCopy && selectedSeatPricesCopy[segmentIndex] && selectedSeatPricesCopy[segmentIndex][segmentIndex].push(seatPrice);
			}
		}

		const totalPrice = selectedSeatPricesCopy && selectedSeatPricesCopy
			.flatMap((segmentPrices) => segmentPrices && segmentPrices.flatMap((rowPrices) => rowPrices))
			.reduce((acc, price) => acc + price, 0);

		this.setState({
			selectedSeat: selectedSeatsCopy,
			selectedSeatPrices: selectedSeatPricesCopy,
			totalPrice: totalPrice,
		});
		let orignalSeat = []
		let seatCopy = selectedSeatsCopy && selectedSeatsCopy.flatMap((seat) => {
			return seat
		})
		orignalSeat.push(seatCopy)
		// const mergedArray = this.state.selectedSeat.map(subArray =>
		// 	subArray && subArray.filter(item => Array.isArray(item)).flat()
		// );

		// const finalResult = mergedArray.map(subArray => {
		// 	const uniqueItems = [...new Set(subArray)];
		// 	return uniqueItems.length === 1 ? uniqueItems[0] : uniqueItems;
		// });
		// if (this.state.totalPassenger == 1) {
		// 	orignalSeat.push(seatCopy)
		// } else {
		// 	let multiplePassenger = finalResult.filter(subarray => subarray && subarray.length > 0);
		// 	orignalSeat.push(multiplePassenger)
		// }
		this.setState({
			selectedSeatKafila: orignalSeat,
			totalSeatPrize: this.state.totalPrice
		})
		this.props.kafilaSeatState(orignalSeat)
	};

	// finalSeatSelection() {
	// 	let orignalSeat = []
	// 	let seatCopy = this.state.selectedSeat && this.state.selectedSeat.flatMap((seat) => {
	// 		return seat
	// 	})
	// 	const mergedArray = this.state.selectedSeat.map(subArray =>
	// 		subArray && subArray.filter(item => Array.isArray(item)).flat()
	// 	);

	// 	const finalResult = mergedArray.map(subArray => {
	// 		const uniqueItems = [...new Set(subArray)];
	// 		return uniqueItems.length === 1 ? uniqueItems[0] : uniqueItems;
	// 	});
	// 	if (this.state.totalPassenger == 1) {
	// 		orignalSeat.push(seatCopy)
	// 	} else {
	// 		let multiplePassenger = finalResult.filter(subarray => subarray && subarray.length > 0);
	// 		orignalSeat.push(multiplePassenger)
	// 	}
	// 	this.setState({
	// 		selectedSeatKafila: orignalSeat,
	// 		totalSeatPrize: this.state.totalPrice
	// 	})

	// }

	// ----------Balance popup-------------
	handleBalancePopup = () => {
		this.setState({ showBalancePopup: !this.state.showBalancePopup });
	}

	render() {
		let fareQuote = null;
		let loading = null;
		let fareQuote_updated = 0;
		let tripJackBooking = false;
		let kafilaBooking=false
		let riyaBooking = false
		let tripjackSessionTime = '';
		let seatsKafilaData=this.props.location.state && this.props.location.state.index !== 1 && this.props.ssrKafilaDetails && this.props.ssrKafilaDetails.response&&this.props.ssrKafilaDetails.response.result&&this.props.ssrKafilaDetails.response.result.seat_dynamic&&this.props.ssrKafilaDetails.response.result.seat_dynamic ? 
		this.props.ssrKafilaDetails && this.props.ssrKafilaDetails.response&&this.props.ssrKafilaDetails.response.result&&this.props.ssrKafilaDetails.response.result.seat_dynamic&&this.props.ssrKafilaDetails.response.result.seat_dynamic : 
		this.props.ssrKafilaDetailsRound && this.props.ssrKafilaDetailsRound.response&&this.props.ssrKafilaDetailsRound.response.result&&this.props.ssrKafilaDetailsRound.response.result.seat_dynamic&&this.props.ssrKafilaDetailsRound.response.result.seat_dynamic
		let firstRow=seatsKafilaData &&seatsKafilaData.length > 0 && seatsKafilaData.map((result)=>{
			return result && result.segment_seat.map((result)=>{
				return result.row_seats.filter((result)=>{
					if(["A","B","C"].includes(result.code.slice(result.code.length-1))){
					return result
					}
				})
			})
		})
		let secondRow=seatsKafilaData &&seatsKafilaData.length > 0 && seatsKafilaData.map((result)=>{
			return result && result.segment_seat.map((result)=>{
				return result.row_seats.filter((result)=>{
					if(["D","E","F"].includes(result.code.slice(result.code.length-1))){
					return result
					}
				})
			})
		})
		this.state.firstRow=firstRow;
		this.state.secondRow=secondRow;

		if (!this.props.addPassengerSuccess && !this.state.priceChangedOk) {
			if (this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'multi-city' || this.props.location.state.searchType === 'special-return'){
				fareQuote = this.props.location.state.data;
			}
			else if (this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete') {
				let index = this.props.location.state.index;
				fareQuote = this.props.location.state.data[index];
			}
			else if (this.props.location.state.searchType === 'lcc-special-return') {
				if (this.state.count === 0) {
					fareQuote = this.getLCCSpecialReturnFare(this.props.location.state.data);
				} else {
					fareQuote = this.state.fareQuoteData
				}
			}
			else if (this.props.location.state.searchType === 'advance-search-round') {
				fareQuote = this.props.location.state.priceRBD.results[0][0];
			}
			fareQuote_updated = this.props.fareQuote.response && this.props.fareQuote.response.results;
			loading = this.props.fareQuote.loading
		} else {
			fareQuote = this.props.fareQuote.response && this.props.fareQuote.response.results;
			loading = this.props.fareQuote.loading
		}
		let segment1 = '';
		let segment2 = '';
		let totalPublishedFare = 0;
		let bothActive = false;
		let firstActive = false;
		if (this.props.location.state.searchType === 'special-return') {
			segment1 = this.props.location.state.data.segments[0];
			segment2 = this.props.location.state.data.segments[1];
			bothActive = true;
			totalPublishedFare = currencyFormat(Number(this.props.location.state.data.fare.published_fare) + Number(this.state.baggageAmt) + Number(this.state.mealAmt) + Number(this.state.seatAmt));
		} else if (this.props.location.state.searchType === 'lcc-special-return') {
			segment1 = this.props.location.state.data[0].segments[0];
			segment2 = this.props.location.state.data[1].segments[0];
			totalPublishedFare = currencyFormat(Number(this.props.location.state.data[0].fare.published_fare) + Number(this.state.baggageAmt) + Number(this.state.mealAmt) + Number(this.state.seatAmt));
			bothActive = true;
		} else if (this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete') {
			segment1 = this.props.location.state.data[0].segments[0];
			segment2 = this.props.location.state.data[1].segments[0];
			totalPublishedFare = currencyFormat(Number(this.props.location.state.data[0].fare.published_fare) + Number(this.props.location.state.data[1].fare.published_fare));
			// totalPublishedFare = currencyFormat(Number(this.props.location.state.data[0].fare.published_fare) + Number(this.props.location.state.data[1].fare.published_fare) + Number(this.state.baggageAmt) + Number(this.state.mealAmt) + Number(this.state.seatAmt));
			if (this.props.location.state.index === 1) {
				bothActive = true;
				firstActive = true;
			}
		}
		// else if(this.props.location.state.searchType === 'round-trip-complete'){
		// 	segment1 = this.props.location.state.data[0].segments[0];
		// 	segment2 = this.props.location.state.data[1].segments[0];
		// 	totalPublishedFare = currencyFormat(Number(this.props.outboundPubFare) + Number(this.props.location.state.data[1].fare.published_fare) + Number(this.state.baggageAmt) + Number(this.state.mealAmt) + Number(this.state.seatAmt));
		// 	if (this.props.location.state.index === 1) {
		// 		bothActive = true;
		// 		firstActive = true;
		// 	}
		// }
		else if (this.props.location.state.searchType === 'advance-search-round') {
			segment1 = this.props.location.state.data[0].segments[0];
			segment2 = this.props.location.state.data[1].segments[0];
			totalPublishedFare = currencyFormat(Number(this.props.location.state.priceRBD.results[0][0].fare.published_fare) + Number(this.state.baggageAmt) + Number(this.state.mealAmt) + Number(this.state.seatAmt));
			bothActive = true;
		}
		let riyaFareQuotePublished = this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare && this.props.fareQuote.response.results.fare.published_fare
		let riyaSearchPublished = fareQuote && fareQuote.fare && fareQuote.fare.published_fare;
		let showChangedPrice = this.state.priceChangedOk === null &&  (this.props.fareQuote.response && this.props.fareQuote.response.is_price_changed) ;
		let showKafilaChangedPrice = this.state.kafilaPriceChangedOk === null && (this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.api_respone && this.props.fareQuote.response.api_respone.IsFareUpdate && this.props.fareQuote.response.api_respone.IsFareUpdate === true);
		let accountResponse = this.props.accountResponse.myAccountResponse.response
		let totalAccountBalance = 0;
		if (accountResponse) {
			if (parseInt(accountResponse.cash_balance) > 0) {
				totalAccountBalance = parseInt(accountResponse.cash_balance) + parseInt(accountResponse.credit_limit);
			} else {
				totalAccountBalance = parseInt(accountResponse.credit_limit);
			}
		}
		let offeredFare = fareQuote && fareQuote.fare.offered_fare;
		let showInsuffient = false
		if (offeredFare && accountResponse && (totalAccountBalance < offeredFare)) {
			showInsuffient = true;
		}

		else if ((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return')) {
			if (this.props.fareQuote.loading === false && this.props.location.state.data.api_source === "tripjack") {
				tripJackBooking = true
				tripjackSessionTime = this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st
			}
		}
		else if (this.props.location.state.searchType === 'special-return' || this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete') {
			if (this.props.fareQuote.loading === false && (this.props.location.state.data.api_source === "tripjack" || this.props.location.state.data[this.props.location.state.index].api_source === "tripjack")) {
				tripJackBooking = true;
				tripjackSessionTime = this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st
			}
		}

		if (!this.props.addPassengerSuccess && !this.state.priceChangedOk) {
			if ((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return')) {
				if (this.props.fareQuote.loading === false && this.props.location.state.data.api_source === "tripjack") {
					tripJackBooking = true
					tripjackSessionTime = this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st
				}
			}
			else if (this.props.location.state.searchType === 'special-return' || this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete') {
				if (this.props.fareQuote.loading === false && (this.props.location.state.data.api_source === "tripjack" || this.props.location&&this.props.location.state&&this.props.location.state.data&&this.props.location.state.data[this.props.location.state.index]&&this.props.location.state.data[this.props.location.state.index].api_source === "tripjack")) {
					tripJackBooking = true;
					tripjackSessionTime = this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st
				}
			}
		} else {
			if ((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return')) {
				if (this.props.fareQuote.loading === false && this.props.location.state.data.api_source === "tripjack") {
					tripJackBooking = true
					tripjackSessionTime = this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st
				}
			}
			else if (this.props.location.state.searchType === 'special-return' || this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete') {
				if (this.props.fareQuote.loading === false && (this.props.location.state.data.api_source === "tripjack" || this.props.location.state.data[this.props.location.state.index].api_source === "tripjack")) {
					tripJackBooking = true;
					tripjackSessionTime = this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st
				}
			}
		}
		// change kafila
		if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return')){
			if (this.props.fareQuote.loading === false && this.props.location.state.data.api_source === "kafila") {
				kafilaBooking = true
				// tripjackSessionTime = this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st
			}
        }
		else if (this.props.location.state.searchType === 'special-return' || this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete'
		) {
			if (this.props.fareQuote.loading === false && (this.props.location.state.data.api_source === "kafila" || this.props.location&&this.props.location.state&&this.props.location.state.data&&this.props.location.state.data[this.props.location.state.index]&&this.props.location.state.data[this.props.location.state.index].api_source === "kafila")) {
				kafilaBooking = true;
				// tripjackSessionTime = this.props.fareQuote.response && this.props.fareQuote.response.conditions && this.props.fareQuote.response.conditions.st
			}
		}

		// change riya
		if((this.props.location.state.searchType === 'one-way' || this.props.location.state.searchType === 'special-return')){
			if (this.props.fareQuote.loading === false && this.props.location.state.data.api_source === "riya_travel") {
				riyaBooking = true
				showChangedPrice = (this.state.priceChangedOk === null) && (riyaFareQuotePublished !==riyaSearchPublished) 
			}
        }
		else if (this.props.location.state.searchType === 'special-return' || this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete'
		) {
			if (
				this.props.fareQuote.loading === false &&
				(
				  this.props.location.state.data &&
				  (
					this.props.location.state.data.api_source === "riya_travel" ||
					(
					  this.props.location.state.data[this.props.location.state.index] &&
					  this.props.location.state.data[this.props.location.state.index].api_source === "riya_travel"
					)
				  )
				)
			  ) {
				riyaBooking = true;
				showChangedPrice = (this.state.priceChangedOk === null) && (riyaFareQuotePublished !==riyaSearchPublished) 
			  }
		}
		return (
			
			<section className="mid-wrapper">
				<div className="container">
					<div className="review-booking-wrap">
						<React.Fragment>
							{
								tripJackBooking && 
								<SessionExpire startCount={tripjackSessionTime -30} {...this.props} tripJackBooking={tripJackBooking}/>
							}
							{
								!tripJackBooking &&
								<SessionExpire startCounts='870' {...this.props} tripJackBooking={tripJackBooking}/>
							}
						</React.Fragment>
						{
							(this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'special-return'
								|| this.props.location.state.searchType === 'lcc-special-return' || this.props.location.state.searchType === 'round-trip-complete'
								|| this.props.location.state.searchType === 'advance-search-round') &&
							<div className="review-step">
								<div className="review-step-title">
									<div className="cols pull-left"> <span>Booking In Progress</span> </div>
									<div className="cols pull-right"> </div>
								</div>
								<div className="review-step-deatil review-booking">
									<div className="step-deatil-outer">
										<div className="booking-details">
											<div className={`cols ${firstActive && 'disabled-section'}`}>
												<div className="booking-head"> <span>Outbound Booking</span> </div>
												<div className="booking-list">
													<ul>
														<li><span>{this.props.searchQuery.segments[0].origin} - {this.props.searchQuery.segments[0].destination}</span>,
											<span>{segment1[0].airline.airline_code} -
											{segment1[0].airline.flight_number + ' '}
																{segment1[0].airline.fare_class}</span></li>
														<li><span>{dateFormat(segment1[0].origin.dep_time, 'DD MMM YYYY')}</span>,
											<span>{flightHelper.getDepartureTime(segment1[0].origin.dep_time)} - 
											{flightHelper.getArivalTime(segment1)}</span></li>
													</ul>
												</div>
											</div>
											<div className={`cols ${!bothActive && 'disabled-section'}`}>
												<div className="booking-head"> <span>Inbound Booking</span> </div>
												<div className="booking-list">
													<ul>
														<li> <span>{this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[1] && this.props.searchQuery.segments[1].origin} - {this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments[1] && this.props.searchQuery.segments[1].destination}</span>,
															<span>{segment2[0].airline.airline_code} - {segment2[0].airline.flight_number} {segment2[0].airline.fare_class}</span>
														</li>
														<li><span>{dateFormat(segment2[0].origin.dep_time, 'DD MMM YYYY')}</span>,
															<span>{flightHelper.getDepartureTime(segment2[0].origin.dep_time)} -{flightHelper.getArivalTime(segment2)}</span>
														</li>
													</ul>
												</div>
											</div>
											<div className="total-adults-price">
												<div className="pull-left">Total Publish </div>
												<div className="pull-right">
													<i className="icon icon-rupee" />
													<span>{totalPublishedFare}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						<div className="left-side">
							<div className="fare-trigger">
								{ /* eslint-disable-next-line */}
								<a href="javascript:void(0)" className="btn btn-sky-blue fare-trigger-btn"><i className="icon icon-currency-file"></i>Fare Summery</a>
							</div>
							<div className="fare-summery">
								<React.Fragment>
									<FlightFareSummaryComponent fareQuote_updated={fareQuote_updated} fareQuote={fareQuote} loading={loading} {...this.state} getTotalSeatPrice={kafilaBooking ? this.getTotalSeatPriceKafila:this.getTotalSeatPrice} addPassengerSuccess={this.props.addPassengerSuccess} />
								</React.Fragment>
								{/* <div className="apply-discount">
									<div className="discount-outer"> <i className="scissors-icon"><img src="assets/images/scissors.svg" alt="" /></i>
										<h3>Apply Discount</h3>
										<p>If you have Have a discount/promo code ? please enter for discount</p>
										<div className="input-group add-input-group">
											<input className="form-control custom-filed" type="text" placeholder="Enter Coupon Code" />
											<button className="input-btn">Apply</button>
										</div>
									</div>
								</div> */}
							</div>
						</div>
						<div className="right-side">
							<div className="review-steps-wrap">
								<span className="insufficient_balance text-center" style={{ display: showInsuffient && !this.props.addPassengerSuccess ? 'block' : 'none' }}>Insufficient balance in your account.Please request for cash or credit</span>
								<div className="review-step-nav">
									<ul>
										<li className={!this.props.addPassengerSuccess ? 'current' : 'active'}>
											<div className="step-col">
												<div className="step-icon"><i className="icon icon-traveller" /><i className="icon icon-checked" /></div>
												<h2><span>TRAVELLER</span>
													{ /* eslint-disable-next-line */}
													<a href="javascript:void(0);" onClick={(event) => this.props.switchPage(false)} className="icon icon-pencil" > </a>
												</h2>
											</div>
										</li>
										<li className={this.props.addPassengerSuccess ? 'current' : ''}>
											<div className="step-col">
												<div className="step-icon"><i className="icon icon-book" /><i className="icon icon-checked" /></div>
												<h2><span>REVIEW BOOKING</span></h2>
											</div>
										</li>
									</ul>
								</div>
								{
									!this.props.addPassengerSuccess
									&&
									<FlightTravellerComponent {...this.props}
										handlePopup={this.handlePopup}
										getAdditionalCharges={this.getAdditionalCharges}  //Get extra baggage,meal and seat
										selectedSeat={this.state.selectedSeat}
										selectedSeatKafila={this.props.kafilaSeatStates}
									/>
								}
								{
									this.props.addPassengerSuccess
									&&
									<FlightReviewBookingComponent
										{...this.props}
										fare={this.state}
										getTotalSeatPrice={kafilaBooking?this.getTotalSeatPriceKafila:this.getTotalSeatPrice}
										handleConfirmationPopup={this.handleConfirmationPopup}
									/>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="popup-crad popup-customer-list" style={{ display: this.state.autocomplete.showPopup ? 'block' : 'none' }}>
					<div className="popup-outer">
						<div className="popup-content">
							<div className="popup-head">
								<h2>Customer List</h2>
								<div className="popup-close"><span onClick={() => {
									this.setState({ autocomplete: Object.assign({}, this.state.autocomplete, { showPopup: false ,search:null}), searchStart: null });

								}
								}
									className="icon icon-cross" /></div>
							</div>
							<div className="customer-list-block">
								<div className="customer-select">
									<div className="form-group-customer">
										<div className="customer-select-box">
											<input
												type="search"
												value={this.state.autocomplete.search || ''}
												onKeyPress={(event) => this.enterPressed(event)}
												onChange={(event) => {
													const searchValue = event.target.value;
													this.setState((prevState) => ({
														autocomplete: {
															...prevState.autocomplete,
															search: searchValue,
															showPopup: true
														}
													}), () => this.handleSearch());
												}}
											/>
										</div>
									</div>
									{
										this.state.autocomplete.error &&
										<span className="red-error">Please enter atleast 1 Characters</span>
									}
								</div>
							</div>
							<div className="popup-scorll">
								<ul className="list-select">
									{
										this.state.searchStart && this.state.autocomplete.search
											?
											this.state.autocomplete.searchResults && this.state.autocomplete.searchResults.map((item, index) => {
												return (
													<React.Fragment key={index}>
														{ /* eslint-disable-next-line */}
														<li><a href="javascript:void(0);" onClick={() => this.autoCompleteCustomer(item)} key={index} >{item.first_name + " " + item.last_name + " (" + item.mobile_no + ")"}</a></li>
													</React.Fragment>
												)
											})
											:
											this.props.customerList.response && this.props.customerList.response.passenger.map((item, index) => {
												return (
													<React.Fragment key={index}>
														{ /* eslint-disable-next-line */}
														<li><a href="javascript:void(0);" onClick={() => this.autoCompleteCustomer(item)} key={index} >{item.first_name + " " + item.last_name + " (" + item.mobile_no + ")"}</a></li>
													</React.Fragment>
												)
											})
									}
								</ul>
								{/* <div className="loader relative-loader" style={{display: 'block'}}>
                            <div className="loader-content">
                                <div className="loader-ring blue-ring"> <span /> </div>
                            </div>
                        </div> */}
							</div>
						</div>
					</div>
					<div className="popup-overlay" />
				</div>
				{
					(this.props.location.state.data.api_source === 'kafila') &&
					<div className={`popup-crad total-fare-card ${showKafilaChangedPrice && 'show'}`}>
						<div className="popup-outer">
							<div className="popup-content">
								{/*<div className="popup-head">
									<h2>The total fare has been changed.</h2>
								</div> */}
								<div className="popup-scorll">
									<div className="fare-change-block">
										<div className="fare-block-inner">
											{/* <div className="fare-col fare-thead">
												<div className="cols" />
												<div className="cols"><span>Published Fare</span></div>
												<div className="cols"><span>Offered Fare</span></div>
											</div> */}
											<div className="fare-col fare-tbody">
												{/* <ul>
													<li>
														<div className="cols"><span className="red-text">New Price</span></div>
														<div className="cols"><span className="red-text"><i className="fa fa-inr" aria-hidden="true" />
															{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare && currencyFormat(this.props.fareQuote.response.results.fare.published_fare)}</span></div>
														<div className="cols"><span className="red-text"><i className="fa fa-inr" aria-hidden="true" />
															{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare && currencyFormat(this.props.fareQuote.response.results.fare.offered_fare)}</span></div>
													</li>
												</ul> */}
												<div className="fare-bottom-block">
													{/* <span className="red-text">(*TDS INCLUDE)</span>
													<span>Do you want to proceed further?</span> */}
													<h1 style={{textAlign:'center'}}>All tickets are sold.</h1>
												</div>
												<div className="popup-footer">
													{/* <button className="btn btn-orange" onClick={() => {
														this.setState({
															kafilaPriceChangedOk: 'yes'
														})
													}}>Yes</button> */}
													<button className="btn btn-grey" 
													onClick={() => {
														this.props.history.push({
															pathname: "/flight-search-details",
															state: {
																searchAPINo: false
															}
														});
													}}>Choose Another Flight</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="popup-overlay" />
					</div>
				}
				{
					(this.props.location.state.searchType !== 'one-way' &&
						((this.props.location.state && this.props.location.state.index === undefined) ?
							((this.props.location.state && this.props.location.state.data && this.props.location.state.data.api_source) === 'kafila') :
							((this.props.location.state && this.props.location.state.data && this.props.location.state.data[this.props.location.state.index] && this.props.location.state.data[this.props.location.state.index].api_source) === 'kafila')
						)
					) &&
					<div className={`popup-crad total-fare-card ${showKafilaChangedPrice && 'show'}`}>
						<div className="popup-outer">
							<div className="popup-content">
								{/* <div className="popup-head">
									<h2>The total fare has been changed.</h2>
								</div> */}
								<div className="popup-scorll">
									<div className="fare-change-block">
										<div className="fare-block-inner">
											{/* <div className="fare-col fare-thead">
												<div className="cols" />
												<div className="cols"><span>Published Fare</span></div>
												<div className="cols"><span>Offered Fare</span></div>
											</div> */}
											<div className="fare-col fare-tbody">
												{/* <ul>
													<li>
														<div className="cols"><span className="red-text">New Price</span></div>
														<div className="cols"><span className="red-text"><i className="fa fa-inr" aria-hidden="true" />
															{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare && currencyFormat(this.props.fareQuote.response.results.fare.published_fare)}</span></div>
														<div className="cols"><span className="red-text"><i className="fa fa-inr" aria-hidden="true" />
															{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare && currencyFormat(this.props.fareQuote.response.results.fare.offered_fare)}</span></div>
													</li>
												</ul> */}
												<div className="fare-bottom-block">
													{/* <span className="red-text">(*TDS INCLUDE)</span>
													<span>Do you want to proceed further?</span> */}
													<h1 style={{textAlign:'center'}}>All tickets are sold.</h1>
												</div>
												<div className="popup-footer">
													{/* <button className="btn btn-orange" onClick={() => {
														this.setState({
															kafilaPriceChangedOk: 'yes'
														})
													}}>Yes</button> */}
													<button className="btn btn-grey" onClick={() => {
														this.props.history.push({
															pathname: "/flight-search-details",
															state: {
																searchAPINo: false
															}
														});
													}}>Choose Another Flight</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="popup-overlay" />
					</div>
				}
				{
				kafilaBooking && <div class={`popup-crad ticket-sold-card  ${showChangedPrice && 'show'}`}>
					<div className="popup-outer">
						<div className="popup-content">
						{
							kafilaBooking === false &&
							<div className="popup-head">
								<h2>The total fare has been changed</h2>
							</div>
						}
						<div className="popup-box-wrap">
								<div className="popup-box-icon">					
									<span className="popup-icon"><i className="fa fa-ticket"></i></span>
								</div>
								<div className="popup-box-content">All tickets are sold.</div>
								<div className="popup-box-button">
								{ /* eslint-disable-next-line */}
								<a href="javascript:void(0)" className="btn blue-btn" onClick={() => {
									this.props.history.push({
										pathname: "/flight-search-details",
										state: {
											searchAPINo: false
										}
									});
								}}>Choose Another Flight</a>
								</div>
							</div>
						</div>
					</div>
					<div className="popup-overlay"></div>
				</div> 
				}
				{
					!kafilaBooking && <div className={`popup-crad total-fare-card ${showChangedPrice && 'show'}`}>
					<div className="popup-outer">
						<div className="popup-content">
							{
								kafilaBooking === false &&
							<div className="popup-head">
								<h2>The total fare has been changed.</h2>
							</div>
							}
							
							<div className="popup-scorll">
								<div className="fare-change-block">
									
									{
										kafilaBooking === false &&
									<div className="fare-block-inner">
										<div className="fare-col fare-thead">
											<div className="cols" />
											<div className="cols"><span>Published Fare</span></div>
											<div className="cols"><span>Offered Fare</span></div>
										</div>
										<div className="fare-col fare-tbody">
											<ul>
												<li>
													<div className="cols"><span className="red-text">New Price</span></div>
													<div className="cols"><span className="red-text"><i className="fa fa-inr" aria-hidden="true" />
														{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare && currencyFormat(this.props.fareQuote.response.results.fare.published_fare)}</span></div>
													<div className="cols"><span className="red-text"><i className="fa fa-inr" aria-hidden="true" />
														{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.fare && currencyFormat(this.props.fareQuote.response.results.fare.offered_fare)}</span></div>
												</li>
												<li>
													<div className="cols"><span>Old Price</span></div>
													<div className="cols"><span><i className="fa fa-inr" aria-hidden="true" />
														{currencyFormat(fareQuote && fareQuote.fare && fareQuote.fare.published_fare)}</span>
													</div>
													<div className="cols"><span><i className="fa fa-inr" aria-hidden="true" />
														{currencyFormat(fareQuote && fareQuote.fare && fareQuote.fare.offered_fare)}</span>
													</div>
												</li>
											</ul>
											<div className="fare-bottom-block">
												<span className="red-text">(*TDS INCLUDE)</span>
												<span>Do you want to proceed further?</span>
											</div>
											<div className="popup-footer">
												<button className="btn btn-orange" onClick={() => {
													this.setState({
														priceChangedOk: 'yes'
													})
												}}>Yes</button>
												<button className="btn btn-grey" onClick={() => {
													this.props.history.push({
														pathname: "/flight-search-details",
														state: {
															searchAPINo: false
														}
													});
												}}>No</button>
											</div>
										</div>
									</div>
									}
									
								</div>
							</div>
						</div>
					</div>
					<div className="popup-overlay" />
				</div>
				}
				
				<div className="popup-crad sheet-book-popup show" style={{ display: this.props.showSeatPopup && !this.props.addPassengerSuccess ? 'block' : 'none' }}>
					<div className="popup-outer">
						<div className="popup-content">
							<div className="popup-head">
								<h3>Seat Details</h3>
								<div className="popup-close"><span className="icon icon-cross" onClick={() => {
								    this.props.handleSeatPopup(false);
									this.props.setFieldValue('submit', 'submit', false) /** last arg tells formik not to run validation and make this change synchronously  */
									this.props.setFieldValue('selectedSeat',this.state.selectedSeat, false);
									kafilaBooking&&this.props.setFieldValue("selectedSeatKafila",this.props.kafilaSeatStates,false)
									this.props.setFieldValue('isLcc', this.props.isLcc, false);
									}}></span></div>
							</div>
							<div className="popup-mid">
								<div className="popup-left">
									<div className="popup-seat-detail">
										<div className="popup-seat-block">
											<h3>Seat Details</h3>
											<div className="selected-seat-detail">
												<div className="select-city-block">
													{
														!tripJackBooking && this.props.ssrDetails.response && this.props.ssrDetails.response.seat_dynamic &&
														this.props.ssrDetails.response.seat_dynamic.map((item, index) => {
															return (
																<React.Fragment key={index} >
																	{item.segment_seat.map((newItem, newIndex) => {
																		return (
																			<React.Fragment key={newIndex}>
																				<div className="selected-city">
																					<div className="select-route">
																						<div className="pull-left">
																							<div className="country-to-other" >
																								<span>{newItem.row_seats[0].seats[0].origin}</span> <small>-To-</small>
																								<span>{newItem.row_seats[0].seats[0].destination}</span> </div>
																							<div className="date-block">
																								<span>{this.props.fareQuote.response && dateFormat((this.props.fareQuote && this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments && this.props.fareQuote.response.results.segments[index][newIndex] &&this.props.fareQuote.response.results.segments[index][newIndex].origin.dep_time), 'DD')}
																									{this.props.fareQuote.response && dateFormat(this.props.fareQuote.response.results.segments[index][newIndex].origin.dep_time, 'MMM YYYY, FDAY')}</span></div>
																						</div>
																						<div className="pull-right">
																							<button className={`btn-border ${this.state.seatIndex === newIndex && this.state.seatOuterIndex === index ? 'active' : ''}`} onClick={() => this.setState({ seatIndex: newIndex, seatOuterIndex: index })}>Select Seat</button>
																						</div>
																					</div>
																					<div className="selected-seat">
																						<h4>Selected Seat</h4>
																						<ul className="select-seat-list">
																							{
																								this.totalPassengerSeats(this.state.totalPassenger, newIndex, index)
																							}
																						</ul>
																					</div>
																				</div>
																			</React.Fragment>
																		)
																	})
																	}
																</React.Fragment>
															)
														})
													}
													{
														tripJackBooking && this.props.seatInfo.response && this.props.seatInfo.response.results && this.props.seatInfo.response.results.length > 0 &&
														this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments.map((item, index) => {
															return (
															<React.Fragment key={index} >
																{ 
																Object.keys(item).map((key,newIndex) => {
																
																	return (
																	<React.Fragment key={key}>
																	<div className="selected-city">
																		<div className="select-route">
																		<div className="pull-left">
																				<div className="country-to-other" >
																					<span>{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[index][newIndex].origin.airport.airport_code}</span> <small>-To-</small>
																					<span>{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[index][newIndex].destination.airport.airport_code}</span> </div>
																					<div className="date-block">
																						<span>
																							{this.props.fareQuote.response && this.props.fareQuote.response.results && dateFormat(this.props.fareQuote.response.results.segments[index][newIndex].origin.dep_time, 'DD')}
																							{this.props.fareQuote.response && this.props.fareQuote.response.results && dateFormat(this.props.fareQuote.response.results.segments[index][newIndex].origin.dep_time, 'MMM YYYY, FDAY')}
																							</span>
																							</div>
																						
																			</div>
																			<div className="pull-right">
																							<button className={`btn-border ${this.state.seatIndex === newIndex && this.state.seatOuterIndex === index ? 'active' : ''}`} onClick={() => this.setState({ seatIndex: newIndex, seatOuterIndex: index })}>Select Seat</button>
																						</div>
																					</div>
																					<div className="selected-seat">
																						<h4>Selected Seat</h4>
																						<ul className="select-seat-list">
																							{
																								this.totalPassengerSeats(this.state.totalPassenger, newIndex, index)
																							}
																						</ul>
																					</div>
																				</div>
																	</React.Fragment>
																		)
																		})
																	}
																</React.Fragment>
																)
															})
													}
														{
														
														riyaBooking && this.props.seatRiyaInfo && this.props.seatRiyaInfo.response  &&
														
														this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments.map((item, index) => {
															return (
																
															<React.Fragment key={index} >
																
																{ 
																Object.keys(item).map((key,newIndex) => {
																	
																	return (
																	<React.Fragment key={key}>
																	<div className="selected-city">
																		<div className="select-route">
																		<div className="pull-left">
																				<div className="country-to-other" >
																					<span>{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[index][newIndex].origin.airport.airport_code}</span> <small>-To-</small>
																					<span>{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[index][newIndex].destination.airport.airport_code}</span> </div>
																					<div className="date-block">
																						<span>
																							{this.props.fareQuote.response && this.props.fareQuote.response.results && dateFormat(this.props.fareQuote.response.results.segments[index][newIndex].origin.dep_time, 'DD')}
																							{this.props.fareQuote.response && this.props.fareQuote.response.results && dateFormat(this.props.fareQuote.response.results.segments[index][newIndex].origin.dep_time, 'MMM YYYY, FDAY')}
																							</span>
																							</div>
																						
																			</div>
																			<div className="pull-right">
																							<button className={`btn-border ${this.state.seatIndex === newIndex && this.state.seatOuterIndex === index ? 'active' : ''}`} onClick={() => this.setState({ seatIndex: newIndex, seatOuterIndex: index })}>Select Seat</button>
																						</div>
																					</div>
																					<div className="selected-seat">
																						<h4>Selected Seat</h4>
																						<ul className="select-seat-list">
																							{
																								this.totalPassengerSeats(this.state.totalPassenger, newIndex, index)
																							}
																						</ul>
																					</div>
																				</div>
																	</React.Fragment>
																		)
																		})
																	}
																</React.Fragment>
																)
															})
													}
													{
														kafilaBooking && this.props.ssrKafilaDetails.response && this.props.location.state.index !==1 && this.props.ssrKafilaDetails.response.result && this.props.ssrKafilaDetails.response.result.seat_dynamic  &&
														this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments.length>0 && this.props.fareQuote.response.results.segments.map((item, index) => {
															return (
															<React.Fragment key={index} >
																{ 
																Object.keys(item).map((key,newIndex) => {
																
																	return (
																	<React.Fragment key={key}>
																	<div className="selected-city">
																		<div className="select-route">
																		<div className="pull-left">
																				<div className="country-to-other" >
																					<span>{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[index][newIndex].origin.airport.airport_code}</span> <small>-To-</small>
																					<span>{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[index][newIndex].destination.airport.airport_code}</span> </div>
																					<div className="date-block">
																						<span>
																							{this.props.fareQuote.response && this.props.fareQuote.response.results && dateFormat(this.props.fareQuote.response.results.segments[index][newIndex].origin.dep_time, 'DD')}
																							{this.props.fareQuote.response && this.props.fareQuote.response.results && dateFormat(this.props.fareQuote.response.results.segments[index][newIndex].origin.dep_time, 'MMM YYYY, FDAY')}
																							</span>
																							</div>
																						
																			</div>
																			<div className="pull-right">
																							<button className={`btn-border ${this.state.seatIndex === newIndex && this.state.seatOuterIndex === index ? 'active' : ''}`} onClick={() => this.setState({ seatIndex: newIndex, seatOuterIndex: index,selectedSegmentIndex:newIndex })}>Select Seat</button>
																						</div>
																					</div>
																					<div className="selected-seat">
																						<h4>Selected Seat</h4>
																						<ul className="select-seat-list">
																							
																								{this.totalPassengerSeatsKafila(this.state.totalPassenger, newIndex, index)}
																	
																						</ul>
																					</div>
																				</div>
																	</React.Fragment>
																		)
																		})
																	}
																</React.Fragment>
																)
															})
														}
														{
															kafilaBooking && this.props.location.state.searchType!=="one-way" && this.props.location.state.index==1 && this.props.ssrKafilaDetailsRound.response && this.props.ssrKafilaDetailsRound.response.result && this.props.ssrKafilaDetailsRound.response.result.seat_dynamic  &&
															this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments.length>0&&this.props.fareQuote.response.results.segments.map((item, index) => {
																return (
																<React.Fragment key={index} >
																	{ 
																	Object.keys(item).map((key,newIndex) => {
																	
																		return (
																		<React.Fragment key={key}>
																		<div className="selected-city">
																			<div className="select-route">
																			<div className="pull-left">
																					<div className="country-to-other" >
																						<span>{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[index][newIndex].origin.airport.airport_code}</span> <small>-To-</small>
																						<span>{this.props.fareQuote.response && this.props.fareQuote.response.results && this.props.fareQuote.response.results.segments[index][newIndex].destination.airport.airport_code}</span> </div>
																						<div className="date-block">
																							<span>
																								{this.props.fareQuote.response && this.props.fareQuote.response.results && dateFormat(this.props.fareQuote.response.results.segments[index][newIndex].origin.dep_time, 'DD')}
																								{this.props.fareQuote.response && this.props.fareQuote.response.results && dateFormat(this.props.fareQuote.response.results.segments[index][newIndex].origin.dep_time, 'MMM YYYY, FDAY')}
																								</span>
																								</div>
																							
																				</div>
																				<div className="pull-right">
																								<button className={`btn-border ${this.state.seatIndex === newIndex && this.state.seatOuterIndex === index ? 'active' : ''}`} onClick={() => this.setState({ seatIndex: newIndex, seatOuterIndex: index,selectedSegmentIndex:newIndex })}>Select Seat</button>
																							</div>
																						</div>
																						<div className="selected-seat">
																							<h4>Selected Seat</h4>
																							<ul className="select-seat-list">
																								
																									{this.totalPassengerSeatsKafila(this.state.totalPassenger, newIndex, index)}
																		
																						</ul>
																					</div>
																				</div>
																	</React.Fragment>
																		)
																		})
																	}
																</React.Fragment>
																)
															})
													}
												</div>
												<div className="total-select-seat"> <span className="pull-left"> Total </span> <span className="pull-right"> <i className="fa fa-inr" aria-hidden="true"></i>
													<samp>{kafilaBooking? this.state.totalPrice :this.getTotalSeatPrice()}</samp>
												</span>
												</div>
												<div className="pax-detail">
													<h5>pax(s) Details</h5>
													{/* <div className="popup-scorll">
													{
														!kafilaBooking ? <ul className="pax-list">
															{
																this.props.searchQuery && this.props.searchQuery.adult_count > 0 &&
																this.dispalyPaxDetails(this.props.searchQuery.adult_count, this.props.searchQuery.child_count, 'adult', this.props.values,tripJackBooking)
															}
															{
																this.props.searchQuery && this.props.searchQuery.child_count > 0 &&
																this.dispalyPaxDetails(this.props.searchQuery.child_count, this.props.searchQuery.adult_count, 'child', this.props.values,tripJackBooking)
															}
															</ul>:this.props.ssrKafilaDetails&&this.props.ssrKafilaDetails &&this.props.ssrKafilaDetails.response ? <ul className="pax-list">
																	{
																		this.props.searchQuery && this.props.searchQuery.adult_count > 0 &&
																		this.dispalyPaxDetailsKafila(this.props.searchQuery.adult_count, this.props.searchQuery.child_count, 'adult', this.props.values,"kafilaBooking")
																	}
																	{
																		this.props.searchQuery && this.props.searchQuery.child_count > 0 &&
																		this.dispalyPaxDetailsKafila(this.props.searchQuery.child_count, this.props.searchQuery.adult_count, 'child', this.props.values,"kafilaBooking")
																	}
																</ul> : <ul className="pax-list">
																{
																	this.props.searchQuery && this.props.searchQuery.adult_count > 0 &&
																	this.dispalyPaxDetailsKafilaRound(this.props.searchQuery.adult_count, this.props.searchQuery.child_count, 'adult', this.props.values,"kafilaBooking")
																}
																{
																	this.props.searchQuery && this.props.searchQuery.child_count > 0 &&
																	this.dispalyPaxDetailsKafilaRound(this.props.searchQuery.child_count, this.props.searchQuery.adult_count, 'child', this.props.values,"kafilaBooking")
																}
														</ul>
															}
																
													</div> */}
													<div className="popup-scorll">
													{
														!kafilaBooking && tripJackBooking ? <ul className="pax-list">
															{
																this.props.searchQuery && this.props.searchQuery.adult_count > 0 &&
																this.dispalyPaxDetails(this.props.searchQuery.adult_count, this.props.searchQuery.child_count, 'adult', this.props.values,tripJackBooking)
															}
															{
																this.props.searchQuery && this.props.searchQuery.child_count > 0 &&
																this.dispalyPaxDetails(this.props.searchQuery.child_count, this.props.searchQuery.adult_count, 'child', this.props.values,tripJackBooking)
															}
															</ul>:!riyaBooking && this.props.ssrKafilaDetails&&this.props.ssrKafilaDetails &&this.props.ssrKafilaDetails.response ? <ul className="pax-list">
																	{
																		this.props.searchQuery && this.props.searchQuery.adult_count > 0 &&
																		this.dispalyPaxDetailsKafila(this.props.searchQuery.adult_count, this.props.searchQuery.child_count, 'adult', this.props.values,"kafilaBooking")
																	}
																	{
																		this.props.searchQuery && this.props.searchQuery.child_count > 0 &&
																		this.dispalyPaxDetailsKafila(this.props.searchQuery.child_count, this.props.searchQuery.adult_count, 'child', this.props.values,"kafilaBooking")
																	}
																</ul> : !riyaBooking ? <ul className="pax-list">
																{
																	this.props.searchQuery && this.props.searchQuery.adult_count > 0 &&
																	this.dispalyPaxDetailsKafilaRound(this.props.searchQuery.adult_count, this.props.searchQuery.child_count, 'adult', this.props.values,"kafilaBooking")
																}
																{
																	this.props.searchQuery && this.props.searchQuery.child_count > 0 &&
																	this.dispalyPaxDetailsKafilaRound(this.props.searchQuery.child_count, this.props.searchQuery.adult_count, 'child', this.props.values,"kafilaBooking")
																}
														</ul> : <ul className="pax-list">
																{
																	this.props.searchQuery && this.props.searchQuery.adult_count > 0 &&
																	this.dispalyPaxDetailsRiya(this.props.searchQuery.adult_count, this.props.searchQuery.child_count, 'adult', this.props.values,riyaBooking)
																}
																{
																	this.props.searchQuery && this.props.searchQuery.child_count > 0 &&
																	this.dispalyPaxDetailsRiya(this.props.searchQuery.child_count, this.props.searchQuery.adult_count, 'child', this.props.values,riyaBooking)
																}
														</ul>
															}
																
													</div>
												</div>
											</div>
										</div>
										<div className="seat-type">
											<ul>
												<li className="grey-seat"> <span></span> <small>Open Seat</small></li>
												<li className="yellow-seat"> <span></span> <small>Selected Seat</small></li>
												<li className="black-seat"> <span></span> <small>Occupied Seat</small></li>
											</ul>
										</div>
									</div>
								</div>
								<div className="popup-right">
									<div className="popup-scorll">
										{/* {
											tripJackBooking ? prettyScrollBar(this.renderTripJackSeatDetails(this.props.seatInfo), '80vh', '20px')
											: kafilaBooking &&this.props.ssrKafilaDetails&&this.props.ssrKafilaDetails.response ?
										 prettyScrollBar(this.renderKafilaSeat(this.props.ssrKafilaDetails&&this.props.ssrKafilaDetails.response&&this.props.ssrKafilaDetails.response.result), '80vh', '20px'):kafilaBooking &&
										 this.props.ssrKafilaDetailsRound&&this.props.ssrKafilaDetailsRound.response &&this.props.location.state.index==1   ?
										  prettyScrollBar(this.renderKafilaSeat(this.props.ssrKafilaDetailsRound&&this.props.ssrKafilaDetailsRound.response&&this.props.ssrKafilaDetailsRound.response.result), '80vh', '20px'): 
											prettyScrollBar(this.renderSeatDetails(this.props.ssrDetails), '80vh', '20px')
										} */}
										{
											tripJackBooking ? prettyScrollBar(this.renderTripJackSeatDetails(this.props.seatInfo), '80vh', '20px')
											: kafilaBooking &&this.props.ssrKafilaDetails&&this.props.ssrKafilaDetails.response ?
										 prettyScrollBar(this.renderKafilaSeat(this.props.ssrKafilaDetails&&this.props.ssrKafilaDetails.response&&this.props.ssrKafilaDetails.response.result), '80vh', '20px'):kafilaBooking &&
										 this.props.ssrKafilaDetailsRound&&this.props.ssrKafilaDetailsRound.response &&this.props.location.state.index===1   ?
										  prettyScrollBar(this.renderKafilaSeat(this.props.ssrKafilaDetailsRound&&this.props.ssrKafilaDetailsRound.response&&this.props.ssrKafilaDetailsRound.response.result), '80vh', '20px'): 
										  this.props.seatRiyaInfo.loading?
										  <div className="loader relative-loader" style={{display: 'block'}}>
											  <div className="loader-content"><div className="loader-ring blue-ring"> 
										  <span/> </div></div></div>
										  : (riyaBooking && this.props.ssrDetails && this.props.ssrDetails.response && this.props.seatRiyaInfo) ? prettyScrollBar(this.renderRiyaSeatDetails(this.props.ssrDetails, this.props.seatRiyaInfo), '80vh', '20px') :
											prettyScrollBar(this.renderSeatDetails(this.props.ssrDetails), '80vh', '20px')
										}
									</div>
								</div>
								{/* <!-- <div className="loader relative-loader" style="display:block;">
							<div className="loader-content">
							<div className="loader-ring blue-ring"> <span></span> </div>
							</div>
							</div>-->  */}
							</div>
						</div>
					</div>
					<div className="popup-overlay"></div>
				</div>

				{/* Popup for booking confirmation */}
				<div className={`popup-crad confirmation-popup ${this.state.showConfirmationPopup ? 'show' : ''}`}>
					<div className="popup-outer">
						<div className="popup-content">
							<div className="popup-head">
								<h2>Booking Confirmation</h2>
								<div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false, null)} /></div>
							</div>
							<div className="popup-body popup-scorll">
								<div className="inner-confirmation">
									<p>Are you sure you want to continue?</p>
									<div className="popup-footer">
										<button className="btn btn-orange" onClick={() => this.handleConfimationAction()}>Yes</button>
										<button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false, null)}>No</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="popup-overlay" />
				</div>

				{accountResponse && accountResponse.user_type_id !== 1 && showInsuffient && <InsufficentBalancePopupComponent showInsuffient={showInsuffient} {...this.props} {...this.state} handleBalancePopup={this.handleBalancePopup} />}

			</section>
		)
	}
}

/*--------- Insufficent balance popup component ------------*/
class InsufficentBalancePopupComponent extends React.Component {
	render() {
		let { showBalancePopup, handleBalancePopup, showInsuffient } = this.props;
		return (
			<div className={`popup-crad confirmation-popup ${showBalancePopup && showInsuffient ? 'show' : ''}`}>
				<div className="popup-outer">
					<div className="popup-content">
						<div className="popup-head">
							<h2>Insufficent Balance</h2>
							<div className="popup-close"><span className="icon icon-cross" onClick={() => handleBalancePopup()} /></div>
						</div>
						<div className="popup-body popup-scorll">
							<div className="inner-confirmation">
								<p>Insufficient balance in your account.Please request for cash or credit!</p>
								<div className="popup-footer">
									<button className="btn btn-orange" onClick={() => handleBalancePopup()}>Yes</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="popup-overlay"></div>
			</div>
		);
	}
}

export default FlightBookTicketComponent