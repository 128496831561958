import React, { Component } from 'react';
import { dateFormat } from '../../shared/commonHelper';
import FlightSearchContainer from '../../containers/flights/FlightSearchContainer';
import Link from 'react-router-dom/es/Link';
import history from "../../shared/history";

class FlightSearchHeaderComponent extends Component {
	constructor(){
		super()
		this.state = {
			modifySearchShow : false
		}
	}

	toggleModifySearch = () => {
        this.setState({modifySearchShow: this.state.modifySearchShow === true ? false : true })
    }

	render() {
		let productAccess = [];
        if(this.props.agentProductAccess && this.props.agentProductAccess.response){
            for(let i = 0;i<this.props.agentProductAccess.response.user_access_info.length;i++){
                productAccess.push(this.props.agentProductAccess.response.user_access_info[i].product_code);
            }
        }
		return (
			<React.Fragment>
				{/****************************************************** Top menu navigation - start *******************************************************/}
				<div className="nav-section">
					<div className="container">
						<ul className="nav-block">
							{
								this.props.productResponse && this.props.productResponse.response && this.props.productResponse.response.map((item,key) => {
									let product_nav; 
									if(item.product_url === 'flight-booking'){
										product_nav = "assets/images/flight-nav.svg";
									} else if(item.product_url === 'train-booking'){
										product_nav = "assets/images/railway-nav.svg";
									} else if(item.product_url === 'hotel-booking'){
										product_nav = "assets/images/hotel-nav.svg";
									}else if(item.product_url === 'tour-packages'){
										product_nav = "assets/images/tour-nav.svg";
									}

									let redirect = null;
									if (item.product_url === 'flight-booking') {
										redirect = '/flight-search';
                                    } 
                                    else if(item.product_url === 'hotel-booking')
                                    {
                                        redirect = '/hotel-search';
                                    }
									return (
										<React.Fragment key={key}>
											{
												productAccess.includes(item.product_code) 
												?
												<li className={item.product_url === 'flight-booking' ? "active" : ''}>
													{(redirect ? <Link to={redirect}><i><img src={product_nav} alt=""/></i>{item.product_name}</Link> 
														/* eslint-disable-next-line */
														: <a href="javascript:void(0);"><i><img src={product_nav} alt=""/></i>{item.product_name}</a>)}
												</li>
												:
												<li className={`disable-nav ${item.product_url === 'flight-booking' ? "" : ''}`}>
													{/* eslint-disable-next-line */
													<a href="javascript:void(0);"><i><img src={product_nav} alt=""/></i>{item.product_name}</a>}
												</li>
											}
										</React.Fragment>
									)
								})		
							}
						</ul>
					</div>
				</div>
				{/******************************************************* Top menu navigation - end ********************************************************/}

				{/******************************************************** Top search header - start ********************************************************/}
				<div className={`result-section ${this.props.searchQuery&&this.props.searchQuery.journey_type === '3' && 'multicity-flight-details'} ${this.state.modifySearchShow && 'modify-result'}`}>
					<div className="container">
						<div className="result-block">
							<div className="result-filter">
								<ul>
									<li>
										<div className="col-filter">
											<label className="control-col">{this.props.searchQuery&&this.props.searchQuery.journey_type_name}</label>
											<div className="bottom-result">
												{
													this.props.searchQuery&&this.props.searchQuery.journey_type === '2' || this.props.searchQuery&&this.props.searchQuery.journey_type === '5' ||this.props.searchQuery&& this.props.searchQuery.journey_type === '4'?
													<React.Fragment>
														<span>{this.props.searchQuery&&this.props.searchQuery.segments[0].originCityName}</span> 
														<small><img src="assets/images/flight-continue-black.svg" alt=""/></small>
														<span>{this.props.searchQuery&&this.props.searchQuery.segments[0].destinationCityName}</span>
													</React.Fragment>
													:
													this.props.searchQuery&&this.props.searchQuery.segments.map(function (segment, index) {
														return(
															<div className="city-way" key={index}>
																{/* {index > 0 && <small className="multicity-header">|</small>} */}
																<span>{segment.originCityName}</span> 
																<small><img src="assets/images/flight-continue-black.svg" alt=""/></small>
																<span>{segment.destinationCityName }</span>
															</div>
														)
													})
												}
											</div>
										</div>
									</li>
									<li className="col-duration-time">
										<div className="col-filter">
											<div className="col-2-md">
												<label className="control-col">Departure</label>
												<div className="bottom-result">
													<i className="fa fa-calendar"></i> 
													<span> {dateFormat(this.props.searchQuery&&this.props.searchQuery.segments[0].preferred_departure_time, 'DD')} </span> 
													<small className="desk-date"> {dateFormat(this.props.searchQuery&&this.props.searchQuery.segments[0].preferred_departure_time, 'MMM YYYY, FDAY')} </small>
													<small className="mob-date"> {dateFormat(this.props.searchQuery&&this.props.searchQuery.segments[0].preferred_departure_time, 'MMM YYYY, DAY')} </small>
												</div>
											</div>
											{
												this.props.searchQuery&&this.props.searchQuery.journey_type === '2' || this.props.searchQuery&&this.props.searchQuery.journey_type === '5' ||this.props.searchQuery&& (this.props.searchQuery.journey_type === '4' &&this.props.searchQuery&& this.props.searchQuery.advanceSearchType === '2')?
												<div className="col-2-md">
													<label className="control-col">Return</label>
													<div className="bottom-result">
														<i className="fa fa-calendar"></i>
															<span> {dateFormat(this.props.searchQuery.segments[1].preferred_departure_time, 'DD')}</span> 
															<small className="desk-date"> {dateFormat(this.props.searchQuery.segments[1].preferred_departure_time, 'MMM YYYY, FDAY')}</small>
															<small className="mob-date"> {dateFormat(this.props.searchQuery.segments[1].preferred_departure_time, 'MMM YYYY, DAY')}</small>
													</div>
												</div>
												:
												''
											}
										</div>
									</li>
									<li className="col-user-count">
										<div className="col-filter">
											<div className="col-3-md">
												<label className="control-col">ADULT</label>
												<div className="bottom-result"><span>{this.props.searchQuery&&this.props.searchQuery.adult_count}</span></div>
											</div>
											<div className="col-3-md">
												<label className="control-col">CHILD</label>
												<div className="bottom-result"> <span>{this.props.searchQuery&&this.props.searchQuery.child_count}</span> </div>
											</div>
											<div className="col-3-md">
												<label className="control-col">INFANT</label>
												<div className="bottom-result"> <span>{this.props.searchQuery&&this.props.searchQuery.infant_count}</span> </div>
											</div>
										</div>
									</li>
									{/* {
										!this.props.pageType && */}
										<li className="col-duration-detail">
											<div className="modify-btn"> 
											{ /* eslint-disable-next-line */ }
												<a href="javascript:void(0);" className="btn-line" onClick={this.toggleModifySearch}>
												<i className="fa fa-search" aria-hidden="true"></i> Modify Search</a> 
											</div>
										</li>
									
									
								</ul>
								{/* Modify Search div start*/}
								<div className={this.state.modifySearchShow?'block modify-search show':'block modify-search hide'}>	
									{
										this.state.modifySearchShow &&
											<FlightSearchContainer modifySearch="true" toggleModifySearch={this.toggleModifySearch} {...this.props} history={history}/>
									}
									<div className="popup-overlay"></div>
								</div>
								{/* Modify Search div end*/}
							</div>
						</div>
					</div>
				</div>
				{/********************************************************* Top search header - end *********************************************************/}
			</React.Fragment>
		)
	}
}

export default FlightSearchHeaderComponent