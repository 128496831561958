import React, {Component} from 'react';
import {DayPicker, MonthPicker, YearPicker} from "react-dropdown-date";
import Link from "react-router-dom/es/Link";
import { Field } from 'formik';
import { getCurrentDate } from '../../../shared/commonHelper';

class OwnerShip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader  : false
        };
    }

    continueHandler=()=>{
        this.setState( {
                loader : true
        });
        const self = this;
        setTimeout(function(){ self.props.handleValues(); }, 1000);
    };
    checkPasswordStrength = (e) => {
        var targetValue = e.target.value;
        var validationaWarning =  new RegExp(/[0-9]/).test(targetValue) && (new RegExp(/[a-z]/).test(targetValue) || new RegExp(/[A-Z]/).test(targetValue) || new RegExp(/[!#@$%^&*~`)(+=._-]/).test(targetValue));
        var validationSuccess =  new RegExp(/[0-9]/).test(targetValue) && new RegExp(/[a-z]/).test(targetValue) && new RegExp(/[A-Z]/).test(targetValue) && new RegExp(/[!#@$%^&*~`)(+=._-]/).test(targetValue);
       if(targetValue.length >= 4 && validationaWarning && validationSuccess)
        {
            this.setState({ strengthClass: 'success',});
        }
        else if(targetValue.length >= 4 && validationaWarning)
        {
            this.setState({ strengthClass: 'warning',});
        }
        else  if(targetValue.length >= 1)
        {
            this.setState({ strengthClass: 'danger',});
        }
        else{
            this.setState({ strengthClass: null,});
        }
    }
    render() {
        let currentDate = getCurrentDate();
        const endDate = currentDate.getFullYear();
        const {values, errors, touched, handleBlur, handleChange,setFieldValue} = this.props;
        return (
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-block">
                            <label className="control-label">First Name<span
                                className="red-error">*</span></label>
                            <div className="control-group">
                                <Field className="form-control" type="text" name="first_name" value={values.first_name} placeholder="(ex. Ramesh)" onBlur={handleBlur} onChange={handleChange} />
                            </div>
                            {errors.first_name && touched.first_name && <span className="error-msg">{errors.first_name}</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-block">
                            <label className="control-label">Last Name<span
                                className="red-error">*</span></label>
                            <div className="control-group">
                                <Field className="form-control" type="text" name="last_name" value={values.last_name} placeholder="(ex. Gupta)" onBlur={handleBlur} onChange={handleChange} />
                            </div>
                            {errors.last_name && touched.last_name && <span className="error-msg">{errors.last_name}</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-block">
                            <label className="control-label">Gender<span
                                className="red-error">*</span></label>
                            <div className="control-group">
                                <div className="radio-check inline-check">
                                    <Field type="radio" id="1" name="gender" onBlur={handleBlur} onChange={(event) => { setFieldValue("gender", "male");
                                    }} classes={'form-control'} defaultChecked={values.gender === 'male' ? 'checked' : ''} />
                                    <label htmlFor="1">  <i className="fa fa-male" aria-hidden="true"></i>Male</label>
                                </div>
                                <div className="radio-check inline-check">
                                    <Field type="radio" id="2" name="gender" onBlur={handleBlur} onChange={(event) => { setFieldValue("gender", "female");
                                    }} classes={'form-control'} defaultChecked={values.gender === 'female' ? 'checked' : ''} />
                                    <label htmlFor="2"><i className="fa fa-female" aria-hidden="true"></i>Female
                                    </label>
                                </div>
                                {/* <div className="radio-check inline-check">
                                    <Field type="radio" id="3" name="gender" onBlur={handleBlur} onChange={(event) => { setFieldValue("gender", "others");
                                    }} defaultChecked={values.gender === 'others' ? 'checked' : ''} />
                                    <label htmlFor="3">Others</label>
                                </div> */}
                                {errors.gender && touched.gender && <span className="error-msg">{errors.gender}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-block">
                            <label className="control-label">Date of Birth<span
                                className="red-error">*</span></label>
                            <div className="control-group col-section">
                                <div className="col-area select-custom">
                                    <DayPicker defaultValue={'Day'} year={values.dobyear} month={values.dobmonth} endYearGiven  name='dobDay' onChange={(event) => {
                                        setFieldValue("dobDay", event);
                                    }} classes={'form-control'}/>
                                </div>
                                <div className="col-area select-custom">
                                    <MonthPicker defaultValue={'Month'} endYearGiven year={values.dobyear} onChange={(event) => {
                                        setFieldValue("dobmonth", parseInt(event)+1);
                                    }} name={'dobmonth'} onBlur={handleBlur} classes={'form-control'} />
                                </div>
                                <div className="col-area select-custom">
                                    <YearPicker defaultValue={'Year'} start={1905} end={endDate} reverse onChange={(event) => { setFieldValue("dobyear", event);
                                    }} name={'dobyear'} onBlur={handleBlur} classes={'form-control'}/>
                                </div>
                                {(errors.dobDay || errors.dobDay || errors.dobyear) && (touched.dobDay || touched.dobDay || touched.dobyear) && <span className="error-msg">Please enter date of birth</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 info-detail">
                        <div className="form-block">
                            <label className="control-label">Password<span
                                className="red-error">*</span></label>
                            <div className="control-group">
                                <Field className="form-control" type="password" placeholder="(ex. 12aB@)" name="password" onBlur={handleBlur} onChange={handleChange} onKeyUp={((e) => this.checkPasswordStrength(e))} />
                            </div>
                            {errors.password && touched.password && <span className="error-msg">{errors.password}</span>}
                            {this.state.strengthClass?
                                <div className="progress progress-bar-block">
                                    <div className={"progress-bar progress-bar-striped bg-"+this.state.strengthClass} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                </div> 
                            :""
                            }   
                            <span className="info-block"><i className="icon icon-info"></i>
                                <span className="info-tooltip"><small>Password must be min 4 character with one lowercase character and one uppercase character and special character and one number </small></span>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-block">
                            <label className="control-label">Confirm Password<span
                                className="red-error">*</span></label>
                            <div className="control-group">
                                <Field className="form-control" type="password" placeholder="(ex. 12aB@)" name="confirmPassword" onBlur={handleBlur} onChange={handleChange} />
                            </div>
                            {errors.confirmPassword && touched.confirmPassword && <span className="error-msg">{errors.confirmPassword}</span>}
                        </div>
                    </div>
                    <div className="form-btn-block">
                        { /* eslint-disable-next-line */}            
                        <a href="javascript:void(0);" className={`btn btn-orange loading-btn 
                        ${values.first_name && !errors.first_name 
                        && values.last_name && !errors.last_name 
                        && values.gender && !errors.gender 
                        && values.password && !errors.password 
                        && values.confirmPassword && !errors.confirmPassword 
                        && values.dobDay && !errors.dobDay 
                        && values.dobmonth && !errors.dobmonth 
                        && values.dobyear && !errors.dobyear  ? "" : "disabled" }`} onClick={this.continueHandler}><span>Continue</span>
                            {
                            this.state.loader
                            ?
                                <div className="flight-animated"><label><span><small></small><small></small><small></small></span>
                                <img src="/assets/images/flight-continue.svg" alt=""/></label></div>
                            :''
                            }
                        </a>
                        <Link to={'/login'} className="btn btn-grey">Cancel</Link>
                    </div>
                </div>
        );
    }
}

export default OwnerShip;