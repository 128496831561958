import React from 'react';
import { Field } from 'formik';
import { dateFormat, getNewDateFormted, newDateFunction} from '../../../../shared/commonHelper';
import moment from 'moment';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

class VendorFlightTravellerComponent extends React.Component {
    constructor(){
        super()
        this.state = {
            showSSr         : []
        }
    }
    autofill=(title,type,count)=>{
        if(title === "Mr" || title === "Mstr"){
            this.props.setFieldValue('gender_'+type+'_'+count,'male');
        }else if(title && title !== ''){
            this.props.setFieldValue('gender_'+type+'_'+count,'female');
        }
    }

    openSsr = (event,action) => {
        let ssrShow = this.state.showSSr;
        if(action === 'add'){
            ssrShow.push(event)
        }
        else if(action === 'remove'){
            var indexValue = ssrShow.indexOf(event)
			ssrShow.splice(indexValue, 1);
        }
        this.setState({
            showSSr : ssrShow
        })
    }

    passengerForm = (count, type, icon) => {
        const { handleChange, values, errors, touched, handleBlur, setFieldValue} = this.props;
        let isDomestic = this.props.searchQuery && this.props.searchQuery.is_domestic;
        let airline_code = null;
		if(this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete'){
			let index = this.props.location.state.index;
            airline_code = this.props.location.state.data[index].segments[0][0].airline.airline_code;
        }else{
			airline_code = this.props.location.state.data.segments[0][0].airline.airline_code;
        }
        let dobRequired = false;
        if((airline_code === 'AK' || airline_code === 'I5' || airline_code === 'FD' 
        || airline_code === 'D7' || airline_code === 'QZ' || airline_code === 'QZ2' 
        || airline_code === 'IX' || airline_code === 'G9' || airline_code === 'YY') && type === 'adult') {
            dobRequired = true;
        }else if(type !== 'adult'){
            dobRequired = true;
        }else if( type === 'adult' && (airline_code === 'SG' || airline_code === 'G8' || airline_code === '6E') && isDomestic === 'false'){
            dobRequired = true;
        }
        let passengers = [];
        let date = getNewDateFormted(this.props.searchQuery.segments[0].preferred_departure_time);
        let dobmax = null;
        let dobmin = null;
        let valid  = null; 
        if(type === 'adult')
        {
            dobmax =  newDateFunction(moment(date).subtract(12,'years'));
            valid = function( currentDate ){
                return currentDate.isBefore( dobmax );
            };
        }
        else if(type === 'child')
        {
            dobmax = newDateFunction(moment(date).subtract(2,'years'));
            dobmin = newDateFunction(moment(date).subtract(12,'years'));
            valid = function( current ){
               return current.isAfter( dobmin ) && current.isBefore( dobmax );
            };
        }
        else if(type === 'infant')
        {
            dobmin = newDateFunction(moment(date).subtract(2,'years'));
            dobmax = newDateFunction(moment(date).subtract(0,'years'));
            valid = function( current ){
                return current.isAfter( dobmin ) && current.isBefore( dobmax );
            };
        }
        
        let validPassportDate = function( current ){
            return current.isAfter();
        };
        for (let i = 1; i <= count; i++) {
            passengers.push(
                <div key={type + '' + i} className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left">
                            <i className={`icon ${icon}`} />
                            <span>Passanger </span><small>({type + ' ' + i})</small> </div>
                        <div className="cols pull-right">
                            { /* eslint-disable-next-line */}
                            <a className="link-col" href="javascript:void(0)" onClick={() => this.props.handlePopup(type, i)}>Select Passenger from Customer List</a>
                        </div>
                    </div>
                    <div className="review-step-deatil">
                        <div className="step-deatil-outer">
                            <div className="adult-form">
                                <div className="block form-card">
                                    <div className="adult-form-outer">
                                        <div className="form-row">
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">Salutation<sup>*</sup></label>
                                                    <div className="custom-select-box">
                                                        <select className="form-control custom-filed" value={values['passenger_title_' + type + '_' + i]} name={'passenger_title_' + type + '_' + i} onChange={(event) => {
                                                           //AutoFill gender
                                                            handleChange(event);
                                                            var index = event.nativeEvent.target.selectedIndex;
                                                            this.autofill(event.nativeEvent.target[index].text,type,i);   
                                                            setFieldValue('title_name_' + type + '_' + i, event.nativeEvent.target[index].text)
                                                        }}>
                                                            {
                                                                  /* eslint-disable-next-line */
                                                                this.props.paxTitles.response && this.props.paxTitles.response.pax_titles.map((item, key) => {
                                                                    
                                                                    if(item.pax_type.toLowerCase() === type){
                                                                        return (
                                                                            <option key={key} value={item.title_id}>{item.passenger_title}</option>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">First Name <sup>*</sup></label>
                                                    <Field 
                                                        className={errors['first_name_' + type + '_' + i] && touched['first_name_' + type + '_' + i]?'form-control custom-filed error-shadow':'form-control custom-filed'}
                                                        type="text" name={'first_name_' + type + '_' + i} onBlur={handleBlur} value={values['first_name_' + type + '_' + i]} />
                                                </div>

                                            </div>
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">Last Name <sup>*</sup></label>
                                                    <Field 
                                                        className={errors['last_name_' + type + '_' + i] && touched['last_name_' + type + '_' + i]?'form-control custom-filed error-shadow':'form-control custom-filed'}
                                                        type="text" name={'last_name_' + type + '_' + i} value={values['last_name_' + type + '_' + i]} />
                                                        {airline_code === 'SG'? errors['last_name_' + type + '_' + i] && touched['last_name_' + type + '_' + i] && 
                                                    <span className="error-msg">{errors['last_name_' + type + '_' + i]}</span>:''}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">Date of Birth {dobRequired ===true ? <sup>*</sup>:''}</label>
                                                    <div>
                                                    <label className="input-group input-addon">
                                                        <Datetime 
                                                            ref={"dobPicker"+ type + '_' + i}
                                                            value={values['dob_' + type + '_' + i]}
                                                            onChange={(date) => {setTimeout(
                                                                () => this.refs["dobPicker"+ type + '_' + i].closeCalendar(),
                                                                100
                                                            ); setFieldValue('dob_' + type + '_' + i,date)}}
                                                            className={errors['dob_' + type + '_' + i] && touched['dob_' + type + '_' + i]?'form-control custom-filed date error-shadow':'form-control custom-filed date'}
                                                            name={'dob_' + type + '_' + i}
                                                            autoComplete="off"
                                                            timeFormat={false} 
                                                            dateFormat="DD-MM-YYYY"
                                                            isValidDate={ (valid) } 
                                                            closeOnSelect={true}
                                                        /> 
                                                        <span className="input-group-addon"><i className="fa fa-calendar" /></span> 
                                                        </label></div>
                                                    {errors['dob_' + type + '_' + i] && touched['dob_' + type + '_' + i] && 
                                                    <span className="error-msg">{errors['dob_' + type + '_' + i]}</span>} 
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">Gender<sup>*</sup></label>
                                                    <div className="custom-select-box">
                                                        <select className="form-control custom-filed" value={values['gender_' + type + '_' + i]} name={'gender_' + type + '_' + i} onChange={handleChange} disabled>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isDomestic === 'false' &&
                                                <div className="col-sm-12 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="control-label">Nationality</label>
                                                        <div className="custom-select-box">
                                                            <select value={values['nationality_' + type + '_' + i]} name={'nationality_' + type + '_' + i} onChange={handleChange} className="form-control custom-filed">
                                                                {
                                                                    this.props.locations.response && this.props.locations.response.country
                                                                    && this.props.locations.response.country.map((item, key) => {
                                                                        return (
                                                                            <option key={key}  value={item.locationCode}>{item.nationality}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">Frequent Flyer No</label>
                                                    <Field className="form-control custom-filed" type="text" name={'frequentFlyer_' + type + '_' + i} />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">Frequent Flyer Airline Code</label>
                                                    <Field className="form-control custom-filed" type="text" name={'ff_airline_' + type + '_' + i} />
                                                    {errors['ff_airline_' + type + '_' + i] && touched['ff_airline_' + type + '_' + i] && 
                                                    <span className="error-msg">{errors['ff_airline_' + type + '_' + i]}</span>}
                                                </div>
                                            </div>
                                            {
                                            isDomestic === 'false' &&
                                            <React.Fragment>
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">Passport No
                                                    {
                                                        this.props.passportValidation[type] && <sup>*</sup>
                                                    }</label>
                                                    <Field
                                                     className={errors['passport_' + type + '_' + i] && touched['passport_' + type + '_' + i]?'form-control custom-filed error-shadow':'form-control custom-filed'}
                                                     type="text" name={'passport_' + type + '_' + i} />
                                                </div>
                                            </div>
                                            
                                            <div className="col-sm-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="control-label">Passport Expiry
                                                    {
                                                        this.props.passportValidation[type] && <sup>*</sup>
                                                    }</label>
                                                    
                                                    <div>
                                                    <label className="input-group input-addon">
                                                        <Datetime 
                                                            ref={"passPicker"+ type + '_' + i}
                                                            value={values['passportExpiry_' + type + '_' + i]}
                                                            onChange={(date) => {setTimeout(
                                                                () => this.refs["passPicker"+ type + '_' + i].closeCalendar(),
                                                                100
                                                            ); setFieldValue('passportExpiry_' + type + '_' + i,date)}}
                                                            className={errors['passportExpiry_' + type + '_' + i] && touched['passportExpiry_' + type + '_' + i]?'form-control custom-filed date error-shadow':'form-control custom-filed date'}
                                                            name={'passportExpiry_' + type + '_' + i}
                                                            autoComplete="off"
                                                            timeFormat={false} 
                                                            dateFormat="DD-MM-YYYY"
                                                            isValidDate={ (validPassportDate) } 
                                                        /> 
                                                        <span className="input-group-addon"><i className="fa fa-calendar" /></span></label> </div>
                                                </div>
                                            </div>
                                            </React.Fragment>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12">
                            <div className="select-check"><small className="checkbox">
                                <Field type="checkbox" name={'saveCustomer_' + type + '_' + i} />
                                <label />
                            </small>
                                <span>Save/Update Customer in "My Customer" List</span></div>
                        </div>
                    </div>
                </div>
            )
        }
        return passengers;
    }

    render() {
        const { errors,values,touched,handleChange ,setFieldValue ,submitForm} = this.props;
        let fareQuote = null;
		if(this.props.location.state.searchType === 'round-trip' || this.props.location.state.searchType === 'round-trip-complete'){
			let index = this.props.location.state.index;
            fareQuote = this.props.location.state.data[index];
        }else{
			fareQuote = this.props.location.state.data;
        }
        return (
            <div className="tabs-content-wrap">
                <div className="step-content">
                <h3 className="step-title"><span>Please enter names as mentioned on your government ID proof</span> <span className="cols pull-right">
                { /* eslint-disable-next-line */}
                <div className="pull-right"><button className="btn btn-yellow sm-yellow-btn" onClick={() => 
                    this.props.history.push({
                        pathname:"/flight-search-details",
                        state:{
                            searchAPINo : true
                        }
                    })
                } >Choose Another Fare</button></div> 
                </span></h3>
                    <div className="review-filght-step">
                    <div className="review-step">
                            <div className="review-step-title">
                                <div className="cols pull-left"> <span>Flight detail</span> </div>
                                <div className="cols pull-right"></div>
                            </div>
                            <div className="review-step-deatil">
                                <div className="step-deatil-outer">
                                {
                                    fareQuote.segments.map((itemListing,keyLisitng) => {
                                        return (
                                            <div className="flight-details" key={keyLisitng}>
                                                <div className="flight-date"> <i className="icon icon-flight" /> <span>{dateFormat(this.props.searchQuery.segments[keyLisitng].preferred_departure_time,'DD MMM YYYY, FDAY')}</span> </div>
                                                <ul>
                                                    {
                                                        itemListing.map((item,key) => {
                                                            let originTerminal = '';
                                                            let destinationTerminal = '';
                                                            if(item.origin.airport.terminal !== '' && item.origin.airport.terminal){
                                                                originTerminal = <React.Fragment>- <strong>T-{item.origin.airport.terminal}</strong></React.Fragment>;
                                                            }
                                                            if(item.destination.airport.terminal !== '' && item.destination.airport.terminal)
                                                            {
                                                                destinationTerminal = <React.Fragment>- <strong>T-{item.destination.airport.terminal}</strong></React.Fragment>;
                                                            }
                                                            return (
                                                                <li key={key}>
                                                                    <div className="flight-card">
                                                                        <div className="traveller-flight">
                                                                            <figure className="pull-left"><img src={item.airline.flight_logo} alt="" /></figure>
                                                                            <div className="flight-dat"> <span>{item.airline.airline_name+', '+item.airline.airline_code+" - "+item.airline.flight_number}</span>
                                                                                <p>Aircraft - {item.craft}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flight-stops">
                                                                            <div className="flight-start">
                                                                                <h3>{item.origin.airport.city_name} </h3>
                                                                                <span>{dateFormat(item.origin.dep_time,'FDAY, DD MMM YYYY h:m')}</span>
                                                                                <p>{item.origin.airport.airport_name} {originTerminal}</p>
                                                                            </div>
                                                                            <div className="flight-direction">
                                                                                <div className="flight-bar"><i className="icon icon-flight3" /></div>
                                                                            </div>
                                                                            <div className="flight-start flight-landing">
                                                                                <h3>{item.destination.airport.city_name}</h3>
                                                                                <span>{dateFormat(item.destination.arr_time,'FDAY, DD MMM YYYY h:m')}</span>
                                                                                <p>{item.destination.airport.airport_name} {destinationTerminal}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                         {
                            this.props.searchQuery && this.props.searchQuery.adult_count > 0 &&
                            this.passengerForm(this.props.searchQuery.adult_count, 'adult', 'icon-adult')
                        }
                        {
                            this.props.searchQuery && this.props.searchQuery.child_count > 0 &&
                            this.passengerForm(this.props.searchQuery.child_count, 'child', 'icon-child')
                        }
                        {
                            this.props.searchQuery && this.props.searchQuery.infant_count > 0 &&
                            this.passengerForm(this.props.searchQuery.infant_count, 'infant', 'icon-infant')
                        }
                        <div className="gst-card">
                        <div className="review-step">
                                <div className="review-step-title">
                                    <div className="cols pull-left"><span>GENERAL Details</span></div>
                                </div>
                                <div className="review-step-deatil">
                                    <div className="step-deatil-outer">
                                        <div className="adult-form">
                                            <div className="block form-card">
                                                <div className="adult-form-outer">
                                                    <div className="form-row">
                                                        <div className="col-sm-12 col-lg-3">
                                                            <div className="form-group">
                                                                <label className="control-label">Country<sup>*</sup></label>
                                                                <div className="custom-select-box">
                                                                    <select 
                                                                        className={errors.country && touched.country?'form-control custom-filed error-shadow':'form-control custom-filed'}
                                                                        value={values.country} name='country' onChange={
                                                                        (event) =>{
                                                                                handleChange(event);
                                                                                var index = event.nativeEvent.target.selectedIndex;
                                                                                setFieldValue('country_name', event.nativeEvent.target[index].text);
                                                                                setFieldValue('country_code', event.nativeEvent.target[index].getAttribute('data-country-code'))
                                                                            }
                                                                        }>
                                                                        <option value="" data-country-code="">Select Country</option>
                                                                        {
                                                                            this.props.locations.response && this.props.locations.response.country
                                                                            && this.props.locations.response.country.map((item, key) => {
                                                                                return (
                                                                                    <option key={key} data-country-code={item.locationCode} value={item.id}>{item.country}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-3">
                                                            <div className="form-group">
                                                                <label className="control-label">City</label>
                                                                <div className="custom-select-box">
                                                                    <select className="form-control custom-filed" 
                                                                    value={values.city} name='city' onChange={(event) =>{
                                                                                handleChange(event);
                                                                                var index = event.nativeEvent.target.selectedIndex;  
                                                                                setFieldValue('city_name', event.nativeEvent.target[index].text)
                                                                            }}>
                                                                        <option>Select City</option>
                                                                        {
                                                                            this.props.locations.response && values.country &&
                                                                            this.props.locations.response.country_city[values.country].map((item, key) => {
                                                                                return (
                                                                                    <option key={key} value={item.id}>{item.city}</option>
                                                                                )
                                                                            }
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-3">
                                                            <div className="form-group">
                                                                <label className="control-label">Mobile Number <sup>*</sup></label>
                                                                <Field 
                                                                className={errors.mobile_no && touched.mobile_no?'form-control custom-filed error-shadow':'form-control custom-filed'} 
                                                                type="text" name='mobile_no' />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-3">
                                                            <div className="form-group">
                                                                <label className="control-label">Email<sup>*</sup></label>
                                                                <Field 
                                                                className={errors.email && touched.email?'form-control custom-filed error-shadow':'form-control custom-filed'} 
                                                                type="text" name='email' />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-3">
                                                            <div className="form-group">
                                                                <label className="control-label">Address Line 1<sup>*</sup></label>
                                                                <Field 
                                                                className={errors.addressLine1 && touched.addressLine1?'form-control custom-filed error-shadow':'form-control custom-filed'} 
                                                                type="text" name='addressLine1' value={values.addressLine1}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-3">
                                                            <div className="form-group">
                                                                <label className="control-label">Address Line 2</label>
                                                                <Field 
                                                                className={errors.addressLine2 && touched.addressLine2?'form-control custom-filed error-shadow':'form-control custom-filed'}
                                                                type="text" name='addressLine2' value={values.addressLine2}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="btn-block text-right">
                            <button type="button" className="btn btn-primary loading-btn" 
                            onClick={(value) => {
                                submitForm()
                             }}>
                                <span>Continue</span>
                                {
                                    this.props.addPassengerData.loading &&
                                    <div className="flight-animated">
                                        <label><span><small /><small /><small /></span><img src="assets/images/flight-continue.svg" alt="" /></label>
                                    </div>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default VendorFlightTravellerComponent