import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import { addLeadingZero, dateFormat } from '../../shared/commonHelper';
import HotelSearchContainer from '../../containers/hotel/HotelSearchContainer';
import history from "../../shared/history";

class HotelSearchDetailHeaderComponent extends Component {
    constructor(){
		super()
		this.state = {
			modifySearchShow : false
		}
    }
    
    toggleModifySearch = () => {
        this.setState(prevState => ({modifySearchShow: !prevState.modifySearchShow}));
    }

	render() {
        let productAccess = [];
        if(this.props.agentProductAccess && this.props.agentProductAccess.response){
            for(let i = 0;i<this.props.agentProductAccess.response.user_access_info.length;i++){
                productAccess.push(this.props.agentProductAccess.response.user_access_info[i].product_code);
            }
        }
        let totalAdult = 0;
        let totalChild = 0;
        if(this.props.searchQuery){
            for(let i = 0;i<this.props.searchQuery.room_guests.length;i++){
                let item = this.props.searchQuery.room_guests[i];
                totalAdult = parseInt(totalAdult) + parseInt(item.no_of_adults);
                totalChild =  parseInt(totalChild) + parseInt(item.no_of_child);
            }
        }

        let checkInDate = this.props.searchQuery && this.props.searchQuery.check_in_date && this.props.searchQuery.check_in_date.split('/')[2]+'-'+this.props.searchQuery.check_in_date.split('/')[1]+'-'+this.props.searchQuery.check_in_date.split('/')[0];
        let checkOutDate = this.props.searchQuery && this.props.searchQuery.check_out_date && this.props.searchQuery.check_out_date.split('/')[2]+'-'+this.props.searchQuery.check_out_date.split('/')[1]+'-'+this.props.searchQuery.check_out_date.split('/')[0];
       
		return (
            <React.Fragment>
                {/****************************************************** Top menu navigation - start *******************************************************/}
                <div className="nav-section">
                    <div className="container">
                        <ul className="nav-block">
                            {
								this.props.productResponse && this.props.productResponse.response && this.props.productResponse.response.map((item,key) => {
									let product_nav; 
									if(item.product_url === 'flight-booking'){
										product_nav = "assets/images/flight-nav.svg";
									} else if(item.product_url === 'train-booking'){
										product_nav = "assets/images/railway-nav.svg";
									} else if(item.product_url === 'hotel-booking'){
										product_nav = "assets/images/hotel-nav.svg";
									}else if(item.product_url === 'tour-packages'){
										product_nav = "assets/images/tour-nav.svg";
									}

									let redirect = null;
									if (item.product_url === 'flight-booking') {
										redirect = '/flight-search';
                                    } 
                                    else if(item.product_url === 'hotel-booking')
                                    {
                                        redirect = '/hotel-search';
                                    }
                                    
									return (
										<React.Fragment key={key}>
											{
												productAccess.includes(item.product_code) 
												?
												<li className={item.product_url === 'hotel-booking' ? "active" : ''}>
													{(redirect ? <Link to={redirect}><i><img src={product_nav} alt=""/></i>{item.product_name}</Link> 
														/* eslint-disable-next-line */
														: <a href="javascript:void(0);"><i><img src={product_nav} alt=""/></i>{item.product_name}</a>)}
												</li>
												:
												<li className={`disable-nav ${item.product_url === 'flight-booking' ? "" : ''}`}>
													{/* eslint-disable-next-line */
													<a href="javascript:void(0);"><i><img src={product_nav} alt=""/></i>{item.product_name}</a>}
												</li>
											}
										</React.Fragment>
									)
								})		
							}
                        </ul>
                    </div>
                </div>
                {/****************************************************** Top menu navigation - end ***********************************************************/}

                {/****************************************************** Top search header - start *******************************************************/}
                <div className={`result-section hotel-search-section ${this.state.modifySearchShow && 'modify-result'}`}>
                    <div className="container">
                        <div className="result-block">
                            {/* <h3>Hotels and more in Dubai</h3> */}
                            <div className="result-filter">
                                <ul className="search-result-bar">
                                    <li>
                                        <div className="col-filter">
                                            <label className="control-col">Destination</label>
                                            <div className="bottom-result"><span>{this.props.searchQuery && addLeadingZero(this.props.searchQuery.city_name)}</span></div>
                                        </div>
                                    </li>
                                    <li className="col-duration-time">
                                        <div className="col-filter">
                                            <div className="col-2-md">
                                                <label className="control-col">CHECK - IN</label>
                                                <div className="bottom-result"><i className="fa fa-calendar" /> 
                                                    <span>{dateFormat(checkInDate, 'DD')} </span> 
                                                    <small className="desk-date">{dateFormat(checkInDate, 'MMM YYYY, FDAY')}</small>
                                                    <small className="mob-date">{dateFormat(checkInDate, 'MMM YYYY, DAY')}</small>
                                                </div>
                                            </div>
                                            <div className="col-2-md">
                                                <label className="control-col">CHECK - OUT</label>
                                                <div className="bottom-result"><i className="fa fa-calendar" />
                                                    <span>{dateFormat(checkOutDate, 'DD')} </span> 
                                                    <small className="desk-date">{dateFormat(checkOutDate, 'MMM YYYY, FDAY')}</small>
                                                    <small className="mob-date">{dateFormat(checkOutDate, 'MMM YYYY, DAY')}</small>
                                                 </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="col-user-count">
                                        <div className="col-filter">
                                            <div className="col-3-md">
                                                <label className="control-col">ROOMS</label>
                                                <div className="bottom-result"><span>{this.props.searchQuery && addLeadingZero(this.props.searchQuery.no_of_rooms)}</span></div>
                                            </div>
                                            <div className="col-3-md">
                                                <label className="control-col">ADULT</label>
                                                <div className="bottom-result"> <span>{addLeadingZero(totalAdult)}</span> </div>
                                            </div>
                                            <div className="col-3-md">
                                                <label className="control-col">CHILD</label>
                                                <div className="bottom-result"> <span>{addLeadingZero(totalChild)}</span> </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="col-period">
                                        <div className="col-filter">
                                            <label className="control-col">Stay period</label>
                                            <div className="bottom-result"><span>{this.props.searchQuery && addLeadingZero(this.props.searchQuery.no_of_nights)} Night(s)</span></div>
                                        </div>
                                    </li>
                                    <li className="col-duration-detail">
                                        <div className="modify-btn"> 
                                        { /* eslint-disable-next-line */}
                                        <a className="btn-line" href="javascript:void(0)" onClick={this.toggleModifySearch}><i className="fa fa-search" aria-hidden="true" /> Modify Search</a> </div>
                                    </li>
                                </ul>
                                {/* Modify Search div start*/}
								<div className={this.state.modifySearchShow?'block modify-search show':'block modify-search hide'}>	
									{
										this.state.modifySearchShow &&
											<HotelSearchContainer modifySearch="true" toggleModifySearch={this.toggleModifySearch} {...this.props} history={history}/>
									}
									<div className="popup-overlay"></div>
								</div>
								{/* Modify Search div end*/}
                            </div>
                        </div>
                    </div>
                </div>
                {/****************************************************** Top search header - end *********************************************************/}
            </React.Fragment>
		)
	}
}

export default HotelSearchDetailHeaderComponent