import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates'
import moment from 'moment';
import { dateFormat, getCurrentDate } from '../../../shared/commonHelper';
import Pagination from '../../shared/Pagination';
import { Formik } from 'formik';
import { Alert } from 'reactstrap';
import TrainFailedBookingComponent from './TrainFailedBookingComponent';

class TrainBookingSearchComponent extends Component {
    constructor() {
        super();
        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        this.state = {
            bookingStart: moment(),
            bookingEndDate: moment(),
            bookingStartFocus: false,
            bookingEndFocus: false,
            perPageLimit: 4,
            currentPage: 1,
            limitOffSet: '',
            currentDate :current_date,
            showFailBookingPopup: false,
            failPopupValue: null,
        }
    }

    onBookingEndFocusChange = ({ focused }) => {
        this.setState({ bookingEndFocus: focused })
    }

    bookingStartFocusChange = ({ focused }) => {
        this.setState({ bookingStartFocus: focused })
    }

    changeDateTimeFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }

    changeDateFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }

    enterPressed = (handleSubmit, event) => { //event is not passed from methd calling as its received by default when it was binded to our context
        var code = event.keyCode || event.which;
        if (code === 13) {
            handleSubmit();
        }
    }

    //function called on click of "invoice" button to print that booking invoice
    printInvoice = (booking_id) => {
        let printInvoiceData = {
            "booking_id": booking_id
        }
        localStorage.setItem("print-train-invoice", JSON.stringify(printInvoiceData));
        window.open('/print-train-invoice', "_blank");
    }

    printCreditNote = (booking_id) => {
        let printTrainCreditNote = {
            "booking_id": parseInt(booking_id)
        }

        localStorage.setItem("print-train-credit-note", JSON.stringify(printTrainCreditNote));
        window.open('/print-train-credit-note', "_blank");
    }

    //get hotel bookings list & status and cities
    componentDidMount() {
        this.props.getBookingStatus(this.props.location.state.clickedProductCode);
        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        let limitOffset = "?limit=" + this.state.perPageLimit + "&offset=0";
        this.setState({
            limitOffSet: limitOffset
        });
        let searchData = {
            "booking_status": "confirmed",
            "to_date": current_date,
            "from_date": current_date
        };
        this.props.getTrainBookings(searchData, limitOffset);
    }


    showFailBooking = (item, popupValue) => {
        this.setState({
            showFailBookingPopup: popupValue,
            failPopupValue: item
        })
    }

    //function to create query string to pass with API call to get the results as per search panel values or clicked pagination
    createSearchingQueryString = (values, activePage = 0) => {
        var offset;
        if (activePage === 0) {
            offset = 0 * this.state.perPageLimit;
        }
        else {
            offset = (activePage - 1) * this.state.perPageLimit;
        }
        var limitOffset = "?offset=" + offset + "&limit=" + this.state.perPageLimit;
        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        //If booking status search is filled then value else all
        limitOffset = values.booking_status !== '' ? limitOffset + '&booking_status=' + values.booking_status : limitOffset + '&booking_status=all&booking_date_from=' + current_date;
        if (values.booking_start_date && values.booking_start_date !== '') {
            limitOffset = limitOffset + '&booking_date_from=' + values.booking_start_date;
        }
        if (values.booking_end_date !== '') {
            limitOffset = limitOffset + '&booking_date_to=' + values.booking_end_date;
        }
        this.setState({
            limitOffSet: limitOffset,
        });

        let searchData = {
            "booking_status": values.booking_status,
            "from_date": values.booking_start_date,
            "to_date": values.booking_end_date
        };
        this.props.getTrainBookings(searchData, limitOffset);
    }

    resetFormValues = (setFieldValue) => {
        this.setState({
            bookingStart: moment(),
            bookingEndDate: moment(),
        })
        setFieldValue("booking_status", 'all');

        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        var limitOffset = "?offset=0&limit=4";
        let searchData = {
            "booking_status": "confirmed",
            "from_date": current_date,
            "to_date": current_date
        };
        this.props.getTrainBookings(searchData, limitOffset);
    }

    //function to render search panel
    renderSearchForm = (formikProps, props) => {
        const { bookingStatusDetails } = props;
        const { values, setFieldValue, handleBlur, handleSubmit, handleChange } = formikProps;
        const { bookingStart ,bookingEndDate } = this.state;
        let diffTime = Math.abs(new Date(bookingStart) - new Date(bookingEndDate));
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const current_date = moment().format('YYYY-MM-DD');
        return (
            <form>
                {
                    diffDays > 31 ? <Alert color="danger">You can search booking of maximum 31 days.</Alert> : ''
                }
                <div className="form-row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label className="control-label">BOOKING STATUS:</label>
                            <div className="custom-select-box">
                                <select className="form-control custom-filed" name="booking_status" value={values.booking_status} onBlur={handleBlur} onChange={handleChange} onKeyPress={this.enterPressed.bind(this, handleSubmit)}>
                                    <option value="all">All</option>
                                    {
                                        bookingStatusDetails.response && bookingStatusDetails.response.booking_status.map((item, key) => {
                                            return (
                                                <option key={key} value={item.status}>{item.status_name}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Booking Start Date :</label>
                            <div className="input-group input-addon single-calendar">
                                {
                                    <SingleDatePicker
                                        date={bookingStart}
                                        onDateChange={bookingStart => {
                                            this.setState({ bookingStart: bookingStart });
                                            setFieldValue("booking_start_date", bookingStart ? this.changeDateTimeFormat(bookingStart) : '')
                                        }}
                                        focused={this.state.bookingStartFocus}
                                        onFocusChange={this.bookingStartFocusChange}
                                        id="booking_start_date"
                                        displayFormat="DD-MM-YYYY"
                                        readOnly={true}
                                        showDefaultInputIcon={true}
                                        inputIconPosition={"after"}
                                        isOutsideRange={() => false}
                                        hideKeyboardShortcutsPanel={true}
                                        isDayHighlighted={day => day.isSame(current_date, 'day')}
                                        reopenPickerOnClearDate={true}
                                        withPortal={true}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label className="control-label">Booking End Date :</label>
                            <div className="input-group input-addon single-calendar">
                                {
                                    <SingleDatePicker
                                        date={bookingEndDate}
                                        onDateChange={bookingEndDate => {
                                            this.setState({ bookingEndDate: bookingEndDate });
                                            setFieldValue("booking_end_date", bookingEndDate ? this.changeDateFormat(bookingEndDate) : '')
                                        }}
                                        focused={this.state.bookingEndFocus}
                                        onFocusChange={this.onBookingEndFocusChange}
                                        id="booking_end_date"
                                        displayFormat="DD-MM-YYYY"
                                        readOnly={true}
                                        showDefaultInputIcon={true}
                                        inputIconPosition={"after"}
                                        isOutsideRange={() => false}
                                        hideKeyboardShortcutsPanel={true}
                                        isDayHighlighted={day => day.isSame(current_date, 'day')}
                                        reopenPickerOnClearDate={true}
                                        withPortal={true}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-block text-center">
                    {diffDays && diffDays > 31 ?
                        <button type="button" className="btn btn-sky-blue" onClick={handleSubmit} disabled>Search</button>
                        :
                        <button type="button" className="btn btn-sky-blue" onClick={handleSubmit}>Search</button>
                    }
                    <button type="button" className="btn btn-grey" onClick={() => this.resetFormValues(setFieldValue)}>Cancel</button>
                </div>
            </form>
        );
    }

    //function to get records as per clicked page from pagination
    getTrainBookingRecordsPerPage(activePage, formValues) {
        this.setState({ currentPage: activePage });
        this.createSearchingQueryString(formValues, activePage);
    }

    renderTrainBookingsListing = (formikProps, props) => {
        const { myTrainBookings } = props;
        return (
            <div className="profile-detail-info">
                <div className="edit-profile-bar">
                    <div className="pull-right">
                        { /* eslint-disable-next-line */}
                        <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn"><i className="icon icon-menu"></i>Profile Menus</a>
                    </div>
                </div>
                <div className="tabs-container-detail search-transation">
                    <div className="statement-info">
                        <div className="tabs-top">
                            <div className="tabs-title">
                                <h2><i className="fa fa-train"></i><span>SEARCH YOUR BOOKING</span></h2>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: this.props.location.state.pageDescription }} />
                        </div>
                        <div className="statement-outer statement-transaction">
                            <div className="form-row">
                                <div className="col-left">
                                    <div className="transtion-table-outer">
                                        <div className="table-transaction">
                                            {/* booked train listing section ================================================= Start */}
                                            <ul className="transaction-list">
                                                {
                                                    (myTrainBookings.loading === false)
                                                        ?
                                                        myTrainBookings && myTrainBookings.response && myTrainBookings.response.bookings.length > 0 
                                                            ?
                                                            myTrainBookings.response.bookings.map((item, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <div className="col-transaction">
                                                                            <div className="col-table col-thead">
                                                                                <div className="cols col-name">
                                                                                    <span></span>
                                                                                </div>
                                                                                <div className="cols col-hold">
                                                                                    <span className="hold-tk">{item.booking_status && item.booking_status.replace('_', ' ').toUpperCase()}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-table col-tbody">
                                                                                <div className="cols railway-col-name">
                                                                                    <div className="col-bottom">
                                                                                        <div className="book-datail">
                                                                                            <span>Reservation ID:</span>
                                                                                            <small>{item.irctc_reservation_id}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="cols">
                                                                                    <div className="col-bottom">
                                                                                        <div className="book-datail">
                                                                                            <span>Booked On:</span>
                                                                                            <small>{dateFormat(item.booking_date, 'FDAY, DD MMM YYYY')}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="cols col-pnr">
                                                                                    <div className="inner-pnr">
                                                                                        <div className="col-info"><span className="text-bold">Reference No:</span> <small>{item.booking_transaction_ref}</small></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="foot-block">
                                                                            {
                                                                                item.booking_status === 'confirmed' &&
                                                                                <React.Fragment>
                                                                                    <button className="btn btn-yellow" onClick={() => this.printInvoice(item.booking_id)}><i className="fa fa-print" aria-hidden="true"></i> Invoice</button>
                                                                                    <button className="btn btn-red" onClick={() => this.showFailBooking(item, true)}>Fail Booking</button>
                                                                                </React.Fragment>
                                                                            }
                                                                            {
                                                                                item.booking_status === 'cancelled' &&
                                                                                <React.Fragment>
                                                                                    <button className="btn btn-yellow" onClick={() => this.printCreditNote(item.booking_id)}><i className="fa fa-print" aria-hidden="true"></i> Credit Note</button>
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                            :
                                                            <div className="no-result-wrap">
                                                                <div className="result-wrap-outer">
                                                                    <div className="block">
                                                                        <div className="no-result-box">
                                                                            <h2>No results found</h2>
                                                                            <p>We can’t find any item matching your search.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        :
                                                        <div className="loader relative-loader" style={{ "display": "block" }}>
                                                            <div className="loader-content">
                                                                <div className="loader-ring blue-ring">
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </ul>
                                            {/* booked hotels listing section =================================================== End */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-right">
                                    <div className="search-transaction-form form-sec-block">
                                        <h3 className="title-search">Filter</h3>
                                        <div className="search-form">
                                            {/* search panel (filters) section */}
                                            {this.renderSearchForm(formikProps, props)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* pagination section for booked Train listing ================================================= Start */}
                <div className="table-footer">
                    <Pagination
                        totalRecord={myTrainBookings.response && myTrainBookings.response.total_count}
                        pageLimit={this.state.perPageLimit}
                        activePage={this.state.currentPage}
                        getRecordsPerPage={(value) => this.getTrainBookingRecordsPerPage(value, formikProps.values)}
                    />
                </div>
                {/* pagination section for booked Train listing =================================================== End */}
                {
                    this.state.showFailBookingPopup && this.state.failPopupValue && this.state.failPopupValue.booking_status === 'confirmed' &&
                    <TrainFailedBookingComponent {...this.props} {...this.state} showFailBooking={this.showFailBooking} />
                }
            </div>
        );
    }

    render() {
        const initialValues = {
            booking_status: 'all',
            booking_start_date: this.state.currentDate,
            booking_end_date: this.state.currentDate,
        };

        return (
            //putting complete component in formik because we need formikProps (like check in date, check out date, hotel name etc) in searching and pagination both
            <React.Fragment>
                <Formik
                    initialValues={initialValues} //Set initial values of search panel fields to blank
                    onSubmit={(values) => {
                        this.createSearchingQueryString(values);
                    }}
                    render={formikProps => this.renderTrainBookingsListing(formikProps, this.props)}
                />
            </React.Fragment>
        )
    }
}

export default TrainBookingSearchComponent;