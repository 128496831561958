import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import 'react-dates/initialize';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates'
import { Formik } from "formik";
import * as Yup from "yup";

class NewDeposit extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            date:null,
            focused:false,
            createdAt:moment(),
            calendarFocused:false,
            // hideMsg: false,
            depositType: '1',
            formFlag : true,
        }
        this.handleDepositChange = this.handleDepositChange.bind(this);
    }
   
    onDateChange = (createdAt)=>{
        this.setState(()=>({createdAt}));
    }
    onFocusChange =({focused})=>{
        this.setState(()=>({calendarFocused:focused}))
        const input = document.querySelector("#date_deposit");
        let y= window.scrollY
        if(y>=0){
            if(input){
                input.blur()
            }
        }
    }
    changeDateTimeFormat(value)
    {
        let finalDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
        return finalDate;
    }
    changeDateFormat(value)
    {
        let finalDate = moment(value).format('YYYY-MM-DD');
        return finalDate;
    }
    handleDepositChange(event,setFieldValue) {
        this.setState({depositType: event.target.value});
        setFieldValue("deposit_type_id", event.target.value );
    }
    
    renderForm = (formikProps) =>{
        // const { focused, date } = this.state;
        const {setFieldValue, errors, touched, handleBlur, handleChange,handleSubmit, values} = formikProps;
        const {depositTypeResponse, agentBankDetail} = this.props.accountResponse;
        const {showConfirmationPopup} = this.props;
        let showCashInHand = false;
        if(this.state.depositType === '2'){
            showCashInHand =  true;
        }
        return(
                <div className="search-transaction-form">
                    <form id="deposit_request" autoComplete="off" tabIndex="0">
                    <div className="form-row">

                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="control-label">AGENCY NAME :</label>
                                <input className="form-control custom-filed" type="text" name="agency_name" placeholder={this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.company_name} readOnly/>
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="control-label">AGENCY CODE :</label>
                                <input className="form-control custom-filed" type="text" name="product_code" placeholder={this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.agency_code} readOnly/>

                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="control-label">Deposit Amount <span className="red-error">*</span> :</label>
                                <input className="form-control custom-filed" type="text" name="amount" value={values.amount} onBlur={handleBlur} onChange={handleChange}/>
                                {errors.amount && touched.amount && <span className="error-msg">{errors.amount}</span>}
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="control-label">DATE & TIME OF DEPOSIT :</label>
                                <div className="input-group input-addon single-calendar">
                                    { 
                                        <SingleDatePicker
                                            date={this.state.createdAt}
                                            onDateChange={createdAt => {
                                                this.setState({ createdAt });
                                                setFieldValue("date_deposit", this.changeDateTimeFormat(createdAt)); }}
                                            focused={this.state.calendarFocused}
                                            onFocusChange={this.onFocusChange}
                                            id="date_deposit"
                                            displayFormat="DD-MM-YYYY HH:mm:ss"
                                            //readOnly={true}
                                            showDefaultInputIcon={true}
                                            inputIconPosition={"after"}
                                            hideKeyboardShortcutsPanel={true}
                                            isOutsideRange={() => false}
                                            reopenPickerOnClearDate={true}
                                            withPortal={true}
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="control-label">TYPE OF DEPOSIT <span className="red-error">*</span>:</label>
                                <div className="custom-select-box">
                                <select className="form-control custom-filed" name="deposit_type_id" onChange={(event) =>{ this.handleDepositChange(event, setFieldValue)}}>
                                    <option value=''>Select Type of Deposit</option>
                                {
                                    depositTypeResponse.response &&  depositTypeResponse.response.map((item,key) => {
                                        return (
                                            <option key={key} value={item.deposit_type_id}>{item.deposit_type}</option>
                                        );
                                    })  
                                } 
                                </select>
                                {errors.deposit_type_id && touched.deposit_type_id && <span className="error-msg">{errors.deposit_type_id}</span>}
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="control-label">BENEFICIARY BANK <span className="red-error">*</span>:</label>
                                <div className="custom-select-box">
                                <select className="form-control custom-filed" name="beneficiary_name" onBlur={handleBlur} onChange={handleChange}>
                                    <option value=''>Select Beneficiary Bank</option>
                                {
                                    /* eslint-disable-next-line */
                                    agentBankDetail.response &&  agentBankDetail.response.response.map((item,key) => {
                                        if(item.bank_name !== "CASH IN HAND"){
                                            return (
                                                <option key={key} value={item.bank_id}>{item.bank_name}</option>
                                            );
                                        }else if(showCashInHand){
                                            return (
                                                <option key={key} value={item.bank_id}>{item.bank_name}</option>
                                            );
                                        }
                                    })  
                                } 
                                </select>
                                {errors.beneficiary_name && touched.beneficiary_name && <span className="error-msg">{errors.beneficiary_name}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="control-label">CHEQUE/DD NO/Transaction No. <span className="red-error">*</span> :</label>
                                <input className="form-control custom-filed" type="text" name="cheque_dd_number" value={values.cheque_dd_number} onBlur={handleBlur} onChange={handleChange}/>
                                {errors.cheque_dd_number && touched.cheque_dd_number && <span className="error-msg">{errors.cheque_dd_number}</span>}
                            </div>
                        </div>

                        {/* <div className="col-sm-12 col-lg-6" style={{ display: (showHideColumn === 'true')?'none':'block' }}>
                            <div className="form-group">
                                <label className="control-label">YOUR BANK ACCOUNT NO. <span className="red-error">*</span> :</label>
                                <input className="form-control custom-filed" type="text" name="requester_bank_account_number" value={values.requester_bank_account_number} onBlur={handleBlur} onChange={handleChange}/>
                                {errors.requester_bank_account_number && touched.requester_bank_account_number && <span className="error-msg">{errors.requester_bank_account_number}</span>}
                            </div>
                        </div> */}

                        {/* <div className="col-sm-12 col-lg-6" style={{ display: (showHideColumn === 'true')?'none':'block' }}>
                            <div className="form-group">
                                <label className="control-label">YOUR BANK'S NAME <span className="red-error">*</span> :</label>
                                <input className="form-control custom-filed" type="text" name="requester_bank_name" value={values.requester_bank_name} onBlur={handleBlur} onChange={handleChange}/>
                                {errors.requester_bank_name && touched.requester_bank_name && <span className="error-msg">{errors.requester_bank_name}</span>}
                            </div>
                        </div> */}

                        {/* <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="control-label">YOUR BANK'S BRANCH :</label>
                                <input className="form-control custom-filed" type="text" name="requester_bank_branch" value={values.requester_bank_branch} onBlur={handleBlur} onChange={handleChange}/>
                            </div>
                        </div> */}

                        {/* <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="control-label">DATE OF ISSUANCE :</label>
                                <div className="input-group input-addon single-calendar" >
                                <SingleDatePicker
                                    date={date} 
                                    onDateChange={date => {
                                        this.setState({ date });
                                        setFieldValue("date_issueance", this.changeDateFormat(date)); }}
                                    focused={focused}
                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                    id="date_issueance"
                                    displayFormat="DD-MM-YYYY"
                                    readOnly={true}
                                    showDefaultInputIcon={true}
                                    inputIconPosition={"after"}
                                    hideKeyboardShortcutsPanel={true}
                                />
                                </div>
                            </div>
                        </div> */}
                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="control-label">REMARKS :</label>
                                <textarea className="form-control custom-filed" name="remarks" value={values.remarks} onBlur={handleBlur} onChange={handleChange}></textarea>
                            </div>
                        </div>

                    </div>

                    <div className="btn-block text-right">
                        <button type="button" className="btn btn-sky-blue" onClick={handleSubmit} tabIndex="3">{/*accountResponse.addCreditRequestResponse.loading === false?"Submit Request":"Submitting.."*/}Continue </button>
                        <button type="reset" className="btn btn-grey">Cancel</button>
                    </div>

                    </form>       
                    {/* Popup for deposit confirmation */}
                    <div className={`popup-crad confirmation-popup ${showConfirmationPopup ? 'show' : ''}`}>
                        <div className="popup-outer">
                            <div className="popup-content">
                                <div className="popup-head">
                                    <h2>Deposit Confirmation</h2>
                                    <div className="popup-close"><span className="icon icon-cross" onClick={()=>this.props.handleConfirmationPopup(false)}/></div>
                                </div>							
                                <div className="popup-body popup-scorll">
                                {
                                    !this.props.showLoaderOnPopup &&
                                    <div className="inner-confirmation">
                                        <p>Are you sure you want to continue?</p>
                                        <div className="popup-footer">
                                            <button className="btn btn-orange" onClick={()=>this.props.submitRequestForm(values)}>Yes</button>
                                            <button className="btn btn-grey" onClick={()=>this.props.handleConfirmationPopup(false)}>No</button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.props.showLoaderOnPopup &&
                                    <div className="loader ssr-loader relative-loader" style={{display:'block'}}>
                                        <div className="loader-content">
                                                <div className="loader-ring blue-ring"> 
                                                    <span> </span>
                                                </div>
                                        </div>
                                    </div>
                                }    
                                </div>
                            </div>
                        </div>
                        <div className="popup-overlay"/>
                    </div>               
                </div>
        )
    }

    render() { 
        const {pagesResponse} = this.props; 
      //  const {depositTypeResponse} = this.props.accountResponse;
        const initialValues = {
            agency_name         : "",
            amount              : "",
            date_deposit        : moment().format('YYYY-MM-DD HH:mm:ss'),
            deposit_type_id     : "",
            beneficiary_name    : "",
            cheque_dd_number    : "",
            date_issueance      : "",
            remarks             : "",
        };
       let validationSchema;
        validationSchema = Yup.object().shape({
            amount: Yup.number()
                .required('Amount is required')
                .typeError('Please enter valid amount'),
            cheque_dd_number: Yup.string()
                .required('Cheque/DD Number is required'),
            deposit_type_id: Yup.string()
                .required('Deposit type is required'),
            beneficiary_name: Yup.string()
                .required('Beneficiary Bank is required')    
        });

        var errorMsg,successMsg;
        if(this.props.submitDepositMsg === 'success')
        {
            errorMsg = '';
            successMsg = 'Deposit Request has been send successfully.';
            if(!this.state.formFlag){
                document.getElementById("deposit_request").reset();
                this.setState({formFlag: false});
            }
        }
        else if(this.props.submitDepositMsg === 'error')
        {
            errorMsg = 'Deposit Request insertion failed.';
            successMsg = '';
        }

        return (
            <div className="profile-detail-info">
                <div className="edit-profile-bar">
                    <div className="pull-right">
                        { /* eslint-disable-next-line */}
                        <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn">
                        <i className="icon icon-menu"></i>Profile Menus</a>
                    </div>
                </div>
                <div className="tabs-container-detail">
                    <div className="statement-info">
                        <div className="tabs-top">
                            <div className="tabs-title">
                                <h2><i className="icon icon-money"></i><span>New Deposit Requests</span></h2>
                                <div className="pull-right"> <Link to ="view-deposit" className="sm-btn blue-sm-btn">View Deposit Request</Link> </div>
                            </div> 
                            <div dangerouslySetInnerHTML={{ __html: pagesResponse.response && pagesResponse.response.pages['add_deposit-request'].page_description }} />   
                        </div>
                        {successMsg && <div className="alert alert-success" style={{ display: (this.props.hideMsg === true)?'none':'' }}> 
                        <strong> <i className="fa fa-check-circle"></i> {successMsg} </strong> <i className="fa fa-times" aria-hidden="true" onClick={this.props.showReplyForm}></i> </div>}
                        {errorMsg && <div className="alert alert-danger" style={{ display: (this.props.hideMsg === true)?'none':'' }}> 
                        <strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong> <i className="fa fa-times" aria-hidden="true" onClick={this.props.showReplyForm}></i></div>}
                        <div className="statement-outer">
                                <Formik
                                  initialValues={initialValues}
                                  onSubmit={() => this.props.handleConfirmationPopup(true,null)}
                                  validationSchema={validationSchema}
                                  render={this.renderForm}
                              /> 
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default (NewDeposit)