import React, { Component } from 'react';
import history from "../../../shared/history";
import Select from "react-virtualized-select";
import { SingleDatePicker } from 'react-dates'
import moment from 'moment';
import { dateFormat, newDateFunction } from '../../../shared/commonHelper';

class AddVendorBookingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideMsg: false,
            leftNavActive: '/top-up',
            url: history.location.pathname,
            selectedOriginCity: props.values.origin !== '' ? props.values.origin : 'default',
            originCityArray: [],
            selectedDestinationCity: props.values.destination !== '' ? props.values.destination : 'default',
            destinationCityArray: [],
            validToDate: props.values.validToDate !== '' ? moment(newDateFunction(props.values.validToDate)) : null,
            validFromDate: props.values.validFromDate !== '' ? moment(newDateFunction(props.values.validFromDate)) : null,
            startDate: null,
            endDate: null,
            validTofocused: false,
            validFromfocused: null,
            startDatefocused: false,
            endDatefocused: false,
            travelFromDate: props.values.travelFromDate !== '' ? moment(newDateFunction(props.values.travelFromDate)) : null,
            travelFromfocused: false,
            travelToDate: props.values.travelToDate !== '' ? moment(newDateFunction(props.values.travelToDate)) : null,
            travelTofocused: false,
            BlackTofocused: false,
            BlackFromfocused: false,
            segmentCount: props.values.segmentCount,
            returnSegmentCount: props.values.returnSegmentCount,
            showFareRulesPopup : false,
            blackOutCount: props.values.blackOutCount ? props.values.blackOutCount : 1,
            seatAllocationCount: props.values.seatAllocationCount ? props.values.seatAllocationCount : 1,
            activeForm: 'initial',
            seatCount : 0,
            showSeatPopup : '',
            seatValue : 1,
            journey_type : props.values.journey_type ? props.values.journey_type : ''
        }
        
        if(props.values.blackOutCount)
        {
            for(let i = 0; i< props.values.blackOutCount ; i++){
                this.state["BlackFromDate"+ i] = props.values['BlackFromDate'+i] ? moment(newDateFunction(props.values['BlackFromDate'+i])) : null;
                this.state["BlackToDate"+ i] = props.values['BlackFromDate'+i] ? moment(newDateFunction(props.values['BlackToDate'+i])) : null;
            }
        }

        if(props.values.journey_type !== '')
        {
            for(let i = 0; i< props.values.segmentCount ; i++){
                this.state["originCityArray"+ i] = [];
                this.state["destinationCityArray"+ i] = [];
                this.state["segmentOriginCity"+ i] = props.values['segmentOrigin'+i];
                this.state["segmentDestCity"+ i] = props.values['segmentDest'+i];
            }
            if(props.values.journey_type === 2){
                for(let i = 0; i< props.values.returnSegmentCount ; i++){
                    this.state["originCityArrayreturn"+ i] = [];
                    this.state["destinationCityArrayreturn"+ i] = [];
                    this.state["segmentOriginCityreturn"+ i] = props.values['segmentOriginreturn'+i];
                    this.state["segmentDestCityreturn"+ i] = props.values['segmentDestreturn'+i];
                }
            }
        }
    }

    addRemoveBlackOut = (type) => {
        let value = this.state.blackOutCount;
        if (type === 'remove') {
            value = Number(value) - 1;
        } else if (type === 'add') {
            value = Number(value) + 1;
        }

        this.setState({
            blackOutCount: value }, () => 
            this.props.setFieldValue('blackOutCount', value)
        )
    }


    addRemoveSengmet = (type, action) => {
        let value = '';
        if (action === 'onward') {
            value = this.state.segmentCount;
        } else if (action === 'return') {
            value = this.state.returnSegmentCount;
        }

        if (type === 'remove') {
            value = Number(value) - 1;
        } else if (type === 'add') {
            value = Number(value) + 1;
        }

        if (action === 'onward') {
            this.setState({
                segmentCount: value
            }, () => this.props.setFieldValue('segmentCount', value));
        } else if (action === 'return') {
            this.setState({
                returnSegmentCount: value
            }, () => this.props.setFieldValue('returnSegmentCount', value));
        }
    }

    changeDateTimeFormat(value) {
        let finalDate = moment(value).format('YYYY-MM-DDTH:mm:ss');
        return finalDate;
    }

    componentDidMount() {
        this.getOriginCitiesByFilter(this.state.selectedOriginCity,'','');
        this.getDestinationCitiesByFilter(this.state.selectedDestinationCity,'','');
        for(let i = 0; i< this.state.segmentCount ; i++){
            this.getOriginCitiesByFilter(this.state["segmentOriginCity"+ i],i,'');
            this.getDestinationCitiesByFilter(this.state["segmentDestCity"+ i],i,'');
        }
        if(this.state.journey_type === 2){
            for(let i = 0; i< this.state.returnSegmentCount ; i++){
                this.getOriginCitiesByFilter(this.state["segmentOriginCityreturn"+ i],i,'return');
                this.getDestinationCitiesByFilter(this.state["segmentDestCityreturn"+ i],i,'return');
            }
        }
        
        this.props.getAllAirlines(null);
    }

    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }

    /*************************** Set Origin City Name ***************************/
    setOriginCityName = (selectedOriginCity, setFieldValue) => {
        setFieldValue('originCityFullName', selectedOriginCity.label);
        setFieldValue('originCityName', selectedOriginCity.cityName);
        setFieldValue('origin', selectedOriginCity.value);
        this.setState({ selectedOriginCity:  selectedOriginCity});
    }

    setDestinationCityName = (selectedDestinationCity, setFieldValue) => {
        setFieldValue('destinationCityFullName', selectedDestinationCity.label);
        setFieldValue('destinationCityName', selectedDestinationCity.cityName);
        setFieldValue('destination', selectedDestinationCity.value);
        this.setState({ selectedDestinationCity });
    }
    //*************************************** Get origin city *********************************************/
    getOriginCitiesByFilter(filterString,index,returnOnward) {
        if (filterString && filterString.length > 1) {
            const successCb = () => {
                let cityArray = [];
                if (this.props.citiesResponse.response) {
                    cityArray = this.props.citiesResponse.response.map(function (cityData, index) {
                        return ({
                            value: cityData.city_code,//set value
                            cityName: cityData.city_name,
                            label: <React.Fragment> <span className='pull-right'>{cityData.airport_code}</span><samp>{cityData.city_full_name}</samp> </React.Fragment>,
                        });
                    })
                }
                this.setState({ ['originCityArray'+returnOnward+index]: cityArray });
            }
            this.props.getCitiesAction(filterString, successCb);
        }
    }

    getAirlineByFilter() {
        let airlineArray = [];
        if (this.props.allAirlinesData.response) {
            airlineArray = this.props.allAirlinesData.response.map(function (airline, index) {
                return ({
                    value: airline.airline_code,//set value
                    airlineName: airline.airline_name,
                    label: <React.Fragment> <span className='pull-right'>{airline.airline_code}</span><samp>{airline.airline_name}</samp> </React.Fragment>,
                });
            })
        }
        return airlineArray;
    }

    //************************************** Get destination city ******************************************/
    getDestinationCitiesByFilter(filterString,index,returnOnward) {
        if (filterString && filterString.length > 1) {
            const successCb = () => {
                let cityArray = [];
                if (this.props.citiesResponse.response) {
                    cityArray = this.props.citiesResponse.response.map(function (cityData, index) {
                        return ({
                            value: cityData.city_code,//set value
                            cityName: cityData.city_name,
                            label: <React.Fragment> <span className='pull-right'>{cityData.airport_code}</span><samp>{cityData.city_full_name}</samp> </React.Fragment>,
                        });
                    })
                }
                this.setState({ ['destinationCityArray'+returnOnward+index]: cityArray });
            }
            this.props.getCitiesAction(filterString, successCb);
        }
    }

  

    originHandleBlur = (value) => {
        this.props.setFieldTouched([value], true);
    };

    showFareRulesPopup = (value) => {
        this.setState({
            showFareRulesPopup : true
        })
    }



    render() {
        const { addResponse, activeForm } = this.props; 
        return (
            <React.Fragment>
                {
                    this.fareRulesPopup()
                }
                <div className="profile-detail-info">
                    <div className="edit-profile-bar">{ /* eslint-disable-next-line */}
                        <div className="pull-right"> <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn"><i className="icon icon-menu" />Profile Menus</a> </div>
                    </div>
                    <div className="tabs-container-detail">
                        <div className="statement-info">
                            <div className="tabs-top">
                                <div className="tabs-title">
                                    <h2><i className="icon icon-information" /><span>Add Vendor Booking</span></h2>
                                </div>
                                {addResponse && addResponse.status === 'ok' && <div className="alert alert-success">
                                    <strong> <i className="fa fa-check-circle"></i>Your Booking is sucessfully saved.</strong> <i className="fa fa-times" aria-hidden="true"></i> </div>}
                                {addResponse && addResponse.status !== 'ok' && addResponse.status !== ''  && <div className="alert alert-danger">
                                    <strong><i className="fa fa-exclamation-triangle"></i>{addResponse.message}</strong> <i className="fa fa-times" aria-hidden="true" ></i></div>}
                                {this.props.editSuccess && <div className="alert alert-success">
                                    <strong> <i className="fa fa-check-circle"></i>Your Booking is sucessfully updated.</strong> <i className="fa fa-times" aria-hidden="true"></i> </div>}
                                {this.props.editerror && <div className="alert alert-danger">
                                    <strong><i className="fa fa-exclamation-triangle"></i>{this.props.editerror}</strong> <i className="fa fa-times" aria-hidden="true" ></i></div>}
                            </div>

                            {
                                activeForm === 'initial' && this.initialForm()
                            }
                            {
                                activeForm === 'seatsAllocation' && this.seatsAllocationForm()
                            }
                            {
                                activeForm === 'seatsAllocationListing' && this.seatsAllocationListing()
                            }
                            
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    seatsAllocationForm = () => {
        const { setFieldValue, handleSubmit, handleChange, handleBlur, errors, touched, values} = this.props; 
        const startDateState            = (values.travelFromDate && values.travelFromDate !== '') ? moment(values.travelFromDate) : null;
        const endDateState              = (values.travelToDate && values.travelToDate !== '') ? moment(values.travelToDate) : null;
        const startDatefocusedState     = (this.state.startDatefocused && this.state.startDatefocused !== '') ? this.state.startDatefocused : false;
        const endDatefocusedState       = (this.state.endDatefocused && this.state.endDatefocused !== '') ? this.state.endDatefocused : false;
        return (
            <div className="statement-outer">
                <div className="search-information-form">
                    <div className="form-info">
                        <div className="form-heading">
                            <h3>Fare Information / Seat Allocation</h3>
                        </div>
                        <ul className="information-list">
                            <li>
                                <div className="form-row">
                                    <div className="col-sm-12 col-lg-4">
                                        <div className="form-group">
                                            <label className="control-label">Start Date:<sup>*</sup></label>
                                            <div className={`input-group input-addon`}>
                                                <SingleDatePicker
                                                    date={startDateState}
                                                    placeholder={"Start Date"}
                                                    onDateChange={startDate => {
                                                        this.setState({ startDate:  startDate});
                                                        setFieldValue("startDate", this.changeDateTimeFormat(startDate));
                                                    }}
                                                    focused={startDatefocusedState}
                                                    onFocusChange={({ focused }) => 
                                                    { 
                                                        this.setState({ startDatefocused:  focused});
                                                        const input = document.querySelector("#startDate"); 
                                                        let y= window.scrollY
                                                        if(y>=0){
                                                            if(input){
                                                                input.blur()
                                                            }
                                                        }
                                                    }}  
                                                    id={"startDate"}
                                                    displayFormat="DD-MM-YYYY"
                                                    readOnly={true}
                                                    showDefaultInputIcon={true}
                                                    inputIconPosition={"after"}
                                                    hideKeyboardShortcutsPanel={true}
                                                    reopenPickerOnClearDate={true}
                                                    withPortal={true}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-4">
                                        <div className="form-group">
                                            <label className="control-label">End Date:<sup>*</sup></label>
                                            <div className={`input-group input-addon ${(errors["endDate"] && touched["endDate"]) && 'error-shadow'}`}>
                                                <SingleDatePicker
                                                    date={endDateState}
                                                    placeholder={"End Date"}
                                                    onDateChange={endDate => {
                                                        this.setState({ endDate: endDate });
                                                        setFieldValue("endDate", this.changeDateTimeFormat(endDate));
                                                    }}
                                                    focused={endDatefocusedState}
                                                    onFocusChange={({ focused }) => 
                                                    { 
                                                        this.setState({ endDatefocused:  focused});
                                                        const input = document.querySelector("#endDate"); 
                                                        let y= window.scrollY
                                                        if(y>=0){
                                                            if(input){
                                                                input.blur()
                                                            }
                                                        }
                                                    }}  
                                                    id={"endDate"}
                                                    displayFormat="DD-MM-YYYY"
                                                    readOnly={true}
                                                    showDefaultInputIcon={true}
                                                    inputIconPosition={"after"}
                                                    hideKeyboardShortcutsPanel={true}
                                                    reopenPickerOnClearDate={true}
                                                    withPortal={true}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-4">
                                        <div className="form-group">
                                            <label className="control-label">Seats Available :<sup>*</sup></label>
                                            <input className={`form-control custom-filed ${(errors["seatsCount"] && touched["seatsCount"]) && 'error-shadow'}`} type="text" name={"seatsCount"} onChange={handleChange} value={values["seatsCount"]?values["seatsCount"]:''}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="fax-fields">
                                    <div className="fax-col fax-head">
                                        <div className="cols"><span>P Type</span> </div>
                                        <div className="cols"><span>Basic</span></div>
                                        <div className="cols"><span>(Tax + YQ)</span></div>
                                    </div>
                                    <div className="fax-col fax-body">
                                        <ul>
                                            <li>
                                                <div className="cols"><span>Adult<sup className="red-color">*</sup></span></div>
                                                <div className="cols">
                                                    <input type="number"
                                                        className={`form-control ${(errors["adultBasic"] && touched["adultBasic"]) && 'error-shadow'}`}
                                                        placeholder={0} name={"adultBasic"} onChange={handleChange} onBlur={handleBlur} value={values["adultBasic"]} />
                                                </div>
                                                <div className="cols">
                                                    <input type="number"
                                                        className={`form-control ${(errors["adultTax"] && touched["adultTax"]) && 'error-shadow'}`}
                                                        placeholder={0} name={"adultTax"} onChange={handleChange} onBlur={handleBlur} value={values["adultTax"]}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="cols"><span>Child<sup className="red-color">*</sup></span></div>
                                                <div className="cols">
                                                    <input type="number" className={`form-control ${(errors["childBasic"] && touched["childBasic"]) && 'error-shadow'}`} 
                                                    placeholder={0} name={"childBasic"} value={values["childBasic"]} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                                <div className="cols">
                                                    <input type="number" className={`form-control ${(errors["childTax"] && touched["childTax"]) && 'error-shadow'}`} 
                                                    placeholder={0} name={"childTax"} value={values["childTax"]} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                            </li>
                                            <li>
                                                <div className="cols"><span>Infant<sup className="red-color">*</sup></span></div>
                                                <div className="cols">
                                                    <input type="number" className={`form-control ${(errors["infantBasic"] && touched["infantBasic"]) && 'error-shadow'}`} 
                                                    placeholder={0} name={"infantBasic"} value={values["infantBasic"]} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                                <div className="cols">
                                                    <input type="number" className={`form-control ${(errors["infantTax"] && touched["infantTax"]) && 'error-shadow'}`} 
                                                    placeholder={0} name={"infantTax"} value={values["infantTax"]} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="form-row" style={{paddingTop: '10px'}}>
                                    <div className="col-sm-12 col-lg-6">
                                        <div className="form-group">
                                            <div className="checkbox check-refund">
                                                <input name={"enableAllDates"} type="checkbox" className="form-control" defaultChecked={values.enableAllDates ? true : false} onChange={(event) => {
                                                    setFieldValue("enableAllDates", event.target.checked ? 1 : 0);
                                                }} />
                                                <label />
                                                <span>Enable and apply for all seats remaining in the range</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="btn-block text-right">
                    <button type="button" className="btn btn-grey" onClick={() => this.props.handleActiveForm('initial')}>Back</button>&nbsp;&nbsp;&nbsp;
                    <button type="button" className="btn btn-sky-blue" onClick={() => handleSubmit()}>Next</button>
                </div>
            </div>
        );
    }

    viewEditSeatPopup = (count,value) => {
        this.setState({
            seatCount : count,
            showSeatPopup : value
        }) 
    }


    saveSeatCount = () => {
        this.setState({
            showSeatPopup : ''
        }) 
    }

    changeSeatVal = (value) => {
        this.setState({
            seatValue :value
        })
    }

    seatsAllocationListing = () => {
        const { handleSubmit, handleChange, values, errors, touched, handleBlur} = this.props; 
        let seatListing = [];
        for(let i = 0; i < values.seatAllocationCount; i++)
        {
            seatListing.push(
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{dateFormat(values['startDate'+i],'DD MMM YYYY, FDAY')}</td>
                        <td>{dateFormat(values['endDate'+i],'DD MMM YYYY, FDAY')}</td>
                        <td>{values['seatsCount'+i]}</td>
                        <td>
                            {/* <a href="javascript:void(0);" style={{marginRight : '4px'}}><i className="fa fa-eye"></i></a> */}
                            { /* eslint-disable-next-line */}
                            <a href="javascript:void(0);" style={{marginRight : '4px'}} onClick={() => this.viewEditSeatPopup(i,'show')}><i className="fa fa-edit"></i></a>
                        </td>
                    </tr>
            )
        }
        return (
            <div className="statement-outer">      
                <div className="search-information-form">
                    <div className="form-info">
                        <div className="form-heading">
                            <h3>Fare Information / Seat Allocation</h3>
                        </div>
                    </div>
                </div>                  
                <div className="balance-sheet-info">
                    <div className="balance-table">
                        <div className="table-outer">
                            <div className="table-container">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Available Seats</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>                                  
                                    {
                                        seatListing
                                    }
                                </tbody>
                                </table>                                            
                            </div>
                        </div>
                    </div>
                    <div className="table-footer">
                    </div>
                </div>
                <div className="btn-block text-right">
                    <button type="button" className="btn btn-grey" onClick={() => this.props.handleActiveForm('seatsAllocation')}>Back</button>
                    &nbsp;&nbsp;&nbsp;
                    <button type="button" className="btn btn-sky-blue" onClick={() => handleSubmit()}>Save</button>
                </div>
                {/* Seat allocation popup */}
                <div className={`popup-crad change-request-popup ${this.state.showSeatPopup}`}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>Edit Seat</h2>
                            </div>
                            <div className="popup-scorll">
                                <div className="block select-block">
                                    <div className="form-row">
                                        <div className="col-sm-12 col-lg-4">
                                            <div className="form-group">
                                                <label className="control-label">Start Date:</label>
                                                <p>{dateFormat(values['startDate'+this.state.seatCount],'DD MMM YYYY, FDAY')}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-4">
                                            <div className="form-group">
                                                <label className="control-label">End Date:</label>
                                                <p>{dateFormat(values['endDate'+this.state.seatCount],'DD MMM YYYY, FDAY')}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-4">
                                            <div className="form-group">
                                                <label className="control-label">Seats Available :<sup>*</sup></label>
                                                <input className={`form-control custom-filed`} type="text" name={"seatsCount"+this.state.seatCount} onChange={handleChange} value={values['seatsCount'+this.state.seatCount]}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="information-list">
                                    <li>
                                        <div className="fax-fields">
                                            <div className="fax-col fax-head">
                                                <div className="cols"><span>P Type</span> </div>
                                                <div className="cols"><span>Basic</span></div>
                                                <div className="cols"><span>(Tax + YQ)</span></div>
                                            </div>
                                            <div className="fax-col fax-body">
                                                <ul>
                                                    <li>
                                                        <div className="cols"><span>Adult<sup className="red-color">*</sup></span></div>
                                                        <div className="cols">
                                                            <input type="number"
                                                                className={`form-control ${(errors["adultBasic"+this.state.seatCount] && touched["adultBasic"+this.state.seatCount]) && 'error-shadow'}`}
                                                                placeholder={0} name={"adultBasic"+this.state.seatCount} onChange={handleChange} onBlur={handleBlur} value={values["adultBasic"+this.state.seatCount]} />
                                                        </div>
                                                        <div className="cols">
                                                            <input type="number"
                                                                className={`form-control ${(errors["adultTax"+this.state.seatCount] && touched["adultTax"+this.state.seatCount]) && 'error-shadow'}`}
                                                                placeholder={0} name={"adultTax"+this.state.seatCount} onChange={handleChange} onBlur={handleBlur} value={values["adultTax"+this.state.seatCount]}/>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="cols"><span>Child<sup className="red-color">*</sup></span></div>
                                                        <div className="cols">
                                                            <input type="number" className={`form-control ${(errors["childBasic"+this.state.seatCount] && touched["childBasic"+this.state.seatCount]) && 'error-shadow'}`} 
                                                            placeholder={0} name={"childBasic"+this.state.seatCount} value={values["childBasic"+this.state.seatCount]} onChange={handleChange} onBlur={handleBlur} />
                                                        </div>
                                                        <div className="cols">
                                                            <input type="number" className={`form-control ${(errors["childTax"+this.state.seatCount] && touched["childTax"+this.state.seatCount]) && 'error-shadow'}`} 
                                                            placeholder={0} name={"childTax"+this.state.seatCount} value={values["childTax"+this.state.seatCount]} onChange={handleChange} onBlur={handleBlur} />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="cols"><span>Infant<sup className="red-color">*</sup></span></div>
                                                        <div className="cols">
                                                            <input type="number" className={`form-control ${(errors["infantBasic"+this.state.seatCount] && touched["infantBasic"+this.state.seatCount]) && 'error-shadow'}`} 
                                                            placeholder={0} name={"infantBasic"+this.state.seatCount} value={values["infantBasic"+this.state.seatCount]} onChange={handleChange} onBlur={handleBlur} />
                                                        </div>
                                                        <div className="cols">
                                                            <input type="number" className={`form-control ${(errors["infantTax"+this.state.seatCount] && touched["infantTax"+this.state.seatCount]) && 'error-shadow'}`} 
                                                            placeholder={0} name={"infantTax"+this.state.seatCount} value={values["infantTax"+this.state.seatCount]} onChange={handleChange} onBlur={handleBlur} />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="popup-footer">
                                <button className="btn btn-orange" onClick={() => this.saveSeatCount()}>Okay</button>
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay" />
                </div>
            </div>
        );
    }


    initialForm = () => {

        const { setFieldValue, handleSubmit, handleChange, handleBlur, errors, touched, values} = this.props; 

        const { selectedOriginCity,
            originCityArray,
            selectedDestinationCity,
            destinationCityArray,
            validToDate,
            validTofocused,
            validFromDate,
            validFromfocused,
            travelFromDate,
            travelFromfocused,
            travelToDate,
            travelTofocused,
        } = this.state;

        return(
            <div className="statement-outer">
                <div className="search-information-form">
                    <div className="form-info">
                        <div className="form-heading">
                            <h3>Basic Information</h3>
                        </div>
                        <div className="form-row">
                            <div className="col-sm-12 col-lg-4">
                                <div className="form-group">
                                    <label className="control-label">Booking Type:<sup>*</sup></label>
                                    <div className="custom-select-box">
                                        <select className={`form-control custom-filed ${(errors.is_domestic && touched.is_domestic) && 'error-shadow'}`} value={values.is_domestic} name="is_domestic" onChange={handleChange} onBlur={handleBlur}>
                                            <option value="">Booking Type</option>
                                            <option value="1">Domestic</option>
                                            <option value="0">International</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <div className="form-group">
                                    <label className="control-label">Search Type:<sup>*</sup></label>
                                    <div className="custom-select-box">
                                        <select className={`form-control custom-filed ${(errors.journey_type && touched.journey_type) && 'error-shadow'}`} value={values.journey_type} name="journey_type" onChange={handleChange} onBlur={handleBlur}>
                                            <option value="">Search Type</option>
                                            <option value="1">One Way</option>
                                            <option value="2">Normal round trip</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <div className="form-group">
                                    <label className="control-label">PNR:<sup>*</sup></label>
                                    <input className={`form-control custom-filed ${(errors.pnr && touched.pnr) && 'error-shadow'}`} type="text" name="pnr" onChange={handleChange} value={values.pnr?values.pnr:''}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <div className="form-group">
                                    <label className="control-label">Origin:<sup>*</sup></label>
                                    <Select
                                        component={Select}
                                        value={selectedOriginCity}
                                        options={originCityArray}
                                        name="origin"
                                        className={`${(errors.origin && touched.origin) && 'error-shadow'}`}
                                        onBlur={() => this.originHandleBlur('origin')}
                                        onChange={(event) => { this.setOriginCityName(event, setFieldValue) }}
                                        onInputChange={(event) => { this.getOriginCitiesByFilter(event,'','') }}
                                        placeholder="Select Origin City"
                                        openMenuOnFocus="true"
                                    >
                                    </Select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <div className="form-group">
                                    <label className="control-label">Destination:<sup>*</sup></label>
                                    <Select
                                        component={Select}
                                        value={selectedDestinationCity}
                                        options={destinationCityArray}
                                        name="destination"
                                        className={`${(errors.origin && touched.origin) && 'error-shadow'}`}
                                        onBlur={() => this.originHandleBlur('destination')}
                                        onChange={(event) => { this.setDestinationCityName(event, setFieldValue) }}
                                        onInputChange={(event) => { this.getDestinationCitiesByFilter(event,'','') }}
                                        placeholder="Select Destination City"
                                        openMenuOnFocus="true"
                                        tabIndex="0"
                                    >
                                    </Select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                <div className="form-group">
                                    <label className="control-label">Cabin Class:</label>
                                    <div className="custom-select-box">
                                        <select className="form-control custom-filed" name="cabin_class" value={values.cabin_class} onChange={handleChange}>
                                            <option value="">Any</option>
                                            <option value="economy">Economy</option>
                                            <option value="premium_economy">Premium Economy</option>
                                            <option value="business">Business</option>
                                            <option value="premium_business">Premium Business</option>
                                            <option value="first">First</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                    <label className="control-label">Valid From:<sup>*</sup></label>
                                    <div className={`input-group input-addon ${(errors.validFromDate && touched.validFromDate) && 'error-shadow'}`}>
                                        <SingleDatePicker
                                            date={validFromDate}
                                            placeholder={"Valid From"}
                                            onDateChange={validFromDate => {
                                                this.setState({ validFromDate });
                                                setFieldValue("validFromDate", this.changeDateTimeFormat(validFromDate));
                                            }}
                                            focused={validFromfocused}
                                            onFocusChange={({ focused }) => 
                                            { 
                                                this.setState({ validFromfocused: focused })
                                                const input = document.querySelector("#validFromDate"); 
                                                let y= window.scrollY
                                                if(y>=0){
                                                    if(input){
                                                        input.blur()
                                                    }
                                                }
                                            }}  
                                            id="validFromDate"
                                            displayFormat="DD-MM-YYYY"
                                            readOnly={true}
                                            showDefaultInputIcon={true}
                                            inputIconPosition={"after"}
                                            hideKeyboardShortcutsPanel={true}
                                            reopenPickerOnClearDate={true}
                                            withPortal={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                    <label className="control-label">Valid To:<sup>*</sup></label>
                                    <div className={`input-group input-addon ${(errors.validToDate && touched.validToDate) && 'error-shadow'}`}>
                                        <SingleDatePicker
                                            date={validToDate}
                                            placeholder={"Valid To"}
                                            onDateChange={validToDate => {
                                                this.setState({ validToDate });
                                                setFieldValue("validToDate", this.changeDateTimeFormat(validToDate));
                                            }}
                                            focused={validTofocused}
                                            onFocusChange={({ focused }) => 
                                            { 
                                                this.setState({ validTofocused: focused })
                                                const input = document.querySelector("#validToDate"); 
                                                let y= window.scrollY
                                                if(y>=0){
                                                    if(input){
                                                        input.blur()
                                                    }
                                                }
                                            }}  
                                            id="validToDate"
                                            displayFormat="DD-MM-YYYY"
                                            readOnly={true}
                                            showDefaultInputIcon={true}
                                            inputIconPosition={"after"}
                                            hideKeyboardShortcutsPanel={true}
                                            reopenPickerOnClearDate={true}
                                            withPortal={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                    <label className="control-label">Travel Date From:<sup>*</sup></label>
                                    <div className={`input-group input-addon ${(errors.travelFromDate && touched.travelFromDate) && 'error-shadow'}`}>
                                        <SingleDatePicker
                                            date={travelFromDate}
                                            placeholder={"Travel Date From"}
                                            onDateChange={travelFromDate => {
                                                this.setState({ travelFromDate });
                                                setFieldValue("travelFromDate", this.changeDateTimeFormat(travelFromDate));
                                            }}
                                            focused={travelFromfocused}
                                            onFocusChange={({ focused }) => 
                                            { 
                                                this.setState({ travelFromfocused: focused }) 
                                                const input = document.querySelector("#travelFromDate");
                                                let y= window.scrollY
                                                if(y>=0){
                                                    if(input){
                                                        input.blur()
                                                    }
                                                }
                                            }} 
                                            id="travelFromDate"
                                            displayFormat="DD-MM-YYYY"
                                            readOnly={true}
                                            showDefaultInputIcon={true}
                                            inputIconPosition={"after"}
                                            hideKeyboardShortcutsPanel={true}
                                            reopenPickerOnClearDate={true}
                                            withPortal={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3">
                                <div className="form-group">
                                    <label className="control-label">Travel Date To:<sup>*</sup></label>
                                    <div className={`input-group input-addon ${(errors.travelToDate && touched.travelToDate) && 'error-shadow'}`}>
                                        <SingleDatePicker
                                            date={travelToDate}
                                            placeholder={"Travel Date To"}
                                            onDateChange={travelToDate => {
                                                this.setState({ travelToDate });
                                                setFieldValue("travelToDate", this.changeDateTimeFormat(travelToDate));
                                            }}
                                            focused={travelTofocused}
                                            onFocusChange={({ focused }) => 
                                            {
                                                this.setState({ travelTofocused: focused })
                                                const input = document.querySelector("#travelToDate"); 
                                                let y= window.scrollY
                                                if(y>=0){
                                                    if(input){
                                                        input.blur()
                                                    }
                                                }
                                            }}						 
                                            id="travelToDate"
                                            displayFormat="DD-MM-YYYY"
                                            readOnly={true}
                                            showDefaultInputIcon={true}
                                            inputIconPosition={"after"}
                                            hideKeyboardShortcutsPanel={true}
                                            isOutsideRange={(day) => day.isBefore(travelFromDate)}
                                            reopenPickerOnClearDate={true}
                                            withPortal={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-sm-12 col-lg-4">
                                <div className="form-group">
                                    <label className="control-label">No Of Seats:<sup>*</sup></label>
                                    <input className={`form-control custom-filed ${(errors.no_of_seats && touched.no_of_seats) && 'error-shadow'}`} type="number" name="no_of_seats" onChange={handleChange} value={values.no_of_seats?values.no_of_seats:''}/>
                                </div>
                            </div> */}
                            <div className="col-sm-12 col-lg-12">
                                <div className="form-group">
                                    <div className="checkbox check-refund">
                                        <input name="is_refundable" type="checkbox" className="form-control" defaultChecked={values.is_refundable ? false : true} onChange={(event) => setFieldValue("is_refundable", event.target.checked ? 0 : 1)} />
                                        <label />
                                        <span>Non Refundable</span> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-12">
                                <div className="form-period">
                                    <div className="pull-right">
                                        { /* eslint-disable-next-line */}
                                        <a className="sm-btn green-sm-btn" href="javascript:void(0);" onClick={() => this.addRemoveBlackOut('add')} style={{marginRight:'4px'}}>Add</a>
                                        {
                                            this.state.blackOutCount > 1 &&
                                            <React.Fragment>
                                                {/* eslint-disable-next-line */}
                                                <a className="sm-btn red-sm-btn" href="javascript:void(0);" onClick={() => this.addRemoveBlackOut('remove')}>Remove</a>
                                            </React.Fragment>
                                        }
                                    </div>
                                    <h4>Black Out Period</h4>
                                    {
                                        this.getBlackOutSection()
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-info">
                        <div className="form-heading">
                            <div className="pull-right">
                                { /* eslint-disable-next-line */}
                                {this.props.values.fareRules === "" 
                                    ?
                                        <React.Fragment>
                                            {/* eslint-disable-next-line */}
                                            <a className="anchor-link" href="javascript:void(0);" onClick={() => this.showFareRulesPopup()} style={{marginRight:'4px', color:'#f00'}} >Fare Rule Details<sup style={{color:'#f00'}}>*</sup></a>
                                        </React.Fragment>
                                    :
                                        <React.Fragment>
                                        {/* eslint-disable-next-line */}
                                            <a className="anchor-link" href="javascript:void(0);" onClick={() => this.showFareRulesPopup()} style={{marginRight:'4px'}}>Fare Rule Details<sup style={{color:'#f00'}}>*</sup></a> 
                                        </React.Fragment>
                                }
                                
                                { /* eslint-disable-next-line */}
                                <a className="sm-btn green-sm-btn" href="javascript:void(0);" onClick={() => this.addRemoveSengmet('add', 'onward')} style={{marginRight:'4px'}}>Add</a>
                                {
                                    this.state.segmentCount > 1 &&
                                    <React.Fragment>
                                        {/* eslint-disable-next-line */}
                                        <a className="sm-btn red-sm-btn" href="javascript:void(0);" onClick={() => this.addRemoveSengmet('remove', 'onward')}>Remove</a>
                                    </React.Fragment>
                                }
                            </div>
                            <h3>Flight Information {parseInt(this.props.values.journey_type) === 2 && 'Onward'}</h3>
                        </div>
                        <ul className="information-list">
                            {
                                this.getSegments('onward')
                            }
                        </ul>
                    </div>
                    {
                        parseInt(this.props.values.journey_type) === 2 &&
                        <div className="form-info">
                            <div className="form-heading">{ /* eslint-disable-next-line */}
                                <div className="pull-right"><a className="sm-btn green-sm-btn" href="javascript:void(0);" onClick={() => this.addRemoveSengmet('add', 'return')}>Add</a>
                                    {
                                        this.state.returnSegmentCount > 1 &&
                                        <React.Fragment>
                                            {/* eslint-disable-next-line */}
                                            <a className="sm-btn red-sm-btn" href="javascript:void(0);" onClick={() => this.addRemoveSengmet('remove', 'return')}>Remove</a>
                                        </React.Fragment>
                                    }
                                </div>
                                <h3>Flight Information Return</h3>
                            </div>
                            <ul className="information-list">
                                {
                                    this.getSegments('return')
                                }
                            </ul>
                        </div>
                    }
                    
                </div>
                <div className="btn-block text-right">
                    <button type="button" className="btn btn-sky-blue" onClick={() => handleSubmit()}>Next</button>
                    {/* <button type="button" className="btn btn-grey">Cancel</button> */}
                </div>
            </div>
        )
    }

    setSegmentOriginCityName = (selectedCity, setFieldValue, count, valueName) => {
        setFieldValue('segmentOrigin' + valueName + count, selectedCity.value);
        this.setState({ ["segmentOriginCity" + valueName + count]: selectedCity });
    }

    setSegmentDestCityName = (selectedCity, setFieldValue, count, valueName) => {
        setFieldValue('segmentDest' + valueName + count, selectedCity.value);
        this.setState({ ["segmentDestCity" + valueName + count]: selectedCity });
    }

    setSegmentAirline = (selectedAirline, setFieldValue, count, valueName) => {
        setFieldValue('airlines' + valueName + count, selectedAirline.value);
    }

    getBlackOutSection = () => {
        let blackOutArray = [];
        const { blackOutCount } = this.state;
        let { setFieldValue,values} = this.props;

        for (let i = 0; i < blackOutCount; i++) {
            blackOutArray.push(
                <div className="form-row" key={i}>
                    <div className="col-sm-12 col-lg-6">
                        <div className="form-group">
                            <label className="control-label">Travel Date From:</label>
                            <div className="input-group input-addon">
                                <SingleDatePicker
                                    date={this.state["BlackFromDate"+i]}
                                    placeholder={"From"}
                                    onDateChange={BlackFromDate => {
                                        this.setState({ ["BlackFromDate"+i]: BlackFromDate });
                                        setFieldValue("BlackFromDate"+i, this.changeDateTimeFormat(BlackFromDate));
                                        if(values.blackOutCount === 0){
                                            setFieldValue("blackOutCount", 1);
                                            this.setState({ blackOutCount: 1 });
                                        }
                                    }}
                                    focused={this.state["BlackFromfocused"+i]}
									onFocusChange={({ focused }) => 
                                    { 
                                        this.setState({ ["BlackFromfocused"+i]: focused })
                                        const input = document.querySelector("#BlackFromDate"); 
                                        let y= window.scrollY
                                        if(y>=0){
                                            if(input){
                                                input.blur()
                                            }
                                        }
                                    }}
                                    id="BlackFromDate"			 
                                    displayFormat="DD-MM-YYYY"
                                    readOnly={true}
                                    showDefaultInputIcon={true}
                                    inputIconPosition={"after"}
                                    hideKeyboardShortcutsPanel={true}
                                    isOutsideRange={(day) => day.isBefore(this.state.validFromDate)}
                                    reopenPickerOnClearDate={true}
                                    withPortal={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                        <div className="form-group">
                            <label className="control-label">To:</label>
                            <div className="input-group input-addon">
                                <SingleDatePicker
                                    date={this.state["BlackToDate"+i]}
                                    placeholder={"To"}
                                    onDateChange={BlackToDate => {
                                        this.setState({ ["BlackToDate"+i]: BlackToDate });
                                        setFieldValue("BlackToDate"+i, this.changeDateTimeFormat(BlackToDate));
                                        if(values.blackOutCount === 0){
                                            setFieldValue("blackOutCount", 1);
                                            this.setState({ blackOutCount: 1 });
                                        }
                                    }}
                                    focused={this.state["BlackTofocused"+i]}
									onFocusChange={({ focused }) => 
                                    { 
                                        this.setState({ ["BlackTofocused"+i]: focused }) 
                                        const input = document.querySelector("#BlackToDate");
                                        let y= window.scrollY
                                        if(y>=0){
                                            if(input){
                                                input.blur()
                                            }
                                        }
                                    }}
                                    id="BlackToDate"			 
                                    displayFormat="DD-MM-YYYY"
                                    readOnly={true}
                                    showDefaultInputIcon={true}
                                    inputIconPosition={"after"}
                                    hideKeyboardShortcutsPanel={true}
                                    isOutsideRange={(day) => day.isBefore(this.state["BlackFromDate"+i])}
                                    reopenPickerOnClearDate={true}
                                    withPortal={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return blackOutArray;
    }

    getSegments = (segmentType) => {
        let { allAirlinesData, handleChange, setFieldValue, handleBlur, errors, touched, values} = this.props;
        let allAirlineListing = allAirlinesData.response ? this.getAirlineByFilter() : []; 
        let {originCityArray} =  this.state;
        let segmentArray = [];
        let valueName = segmentType === 'onward' ? '' : 'return';
        let segments = segmentType === 'onward' ? this.state.segmentCount : this.state.returnSegmentCount;
        for (let i = 0; i < segments; i++) {
            segmentArray.push(
                <li key={i}>
                    <input type="hidden" name={values["stoppage_id" + valueName + i]} value={values["stoppage_id" + valueName + i] ? values["stoppage_id" + valueName + i] : ''} />
                    <div className="form-row">
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="control-label">Airline Code:{i === 0 && <sup>*</sup>}</label>
                                <Select
                                    component={Select}
                                    value={values["airlines" + valueName + i]}
                                    options={allAirlineListing}
                                    name={"airlines" + valueName + i}
                                    className={`${(errors["airlines" + valueName + i] && touched["airlines" + valueName + i] && i === 0) ? 'error-shadow' : ''}`} 
                                    onBlur={handleBlur}
                                    onChange={(event) => this.setSegmentAirline(event, setFieldValue, i, valueName)}
                                    placeholder="Select Flight"
                                    openMenuOnFocus="true"
                                >
                                </Select>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="control-label">Flight No:{i === 0 && <sup>*</sup>}</label>
                                <input 
                                    className={`form-control custom-filed ${(errors["flight_no" + valueName + i] && touched["flight_no" + valueName + i] && i === 0) && 'error-shadow'}`} 
                                    type="text" 
                                    placeholder="Flight No" 
                                    name={"flight_no" + valueName + i} 
                                    onChange={handleChange} 
                                    value={values["flight_no" + valueName + i]}
                                    onBlur={handleBlur} 
                                    />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="control-label">B.Class:{i === 0 && <sup>*</sup>}</label>
                                <input 
                                    className={`form-control custom-filed ${(errors["booking_class" + valueName + i] && touched["booking_class" + valueName + i] && i === 0) && 'error-shadow'}`}  
                                    type="text" 
                                    placeholder="Booking Class" 
                                    name={"booking_class" + valueName + i} 
                                    value={values["booking_class" + valueName + i] ? values["booking_class" + valueName + i] : ''} 
                                    onChange={handleChange} 
                                    onBlur={handleBlur} 
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="control-label">From:{i === 0 && <sup>*</sup>}</label>
                                <Select
                                    component={Select}
                                    value={this.state["segmentOriginCity" + valueName + i] ? this.state["segmentOriginCity" + valueName + i] : null}
                                    options={this.state['originCityArray'+valueName+i] ? this.state['originCityArray'+valueName+i] : originCityArray}
                                    name={"segmentOrigin" + valueName + i}
                                    className={`${(errors["segmentOrigin" + valueName + i] && touched["segmentOrigin" + valueName + i] && i === 0) && 'error-shadow'}`}
                                    onBlur={() => this.originHandleBlur("segmentOrigin" + valueName + i)}
                                    onChange={(event) => this.setSegmentOriginCityName(event, setFieldValue, i, valueName)}
                                    onInputChange={(event) => { this.getOriginCitiesByFilter(event,'','') }}
                                    placeholder="From"
                                    openMenuOnFocus="true"
                                    // tabSelectsValue="false"
                                    defaultMenuIsOpen={true}
                                >
                                </Select>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="control-label">To:{i === 0 && <sup>*</sup>}</label>
                                <Select
                                    component={Select}
                                    value={this.state["segmentDestCity" + valueName + i] ? this.state["segmentDestCity" + valueName + i] : null}
                                    options={this.state['destinationCityArray'+valueName+i] ? this.state['destinationCityArray'+valueName+i] : originCityArray}
                                    name={"segmentDest" + valueName + i}
                                    className={`${(errors["segmentDest" + valueName + i] && touched["segmentDest" + valueName + i] && i === 0) && 'error-shadow'}`}
                                    onBlur={() => this.originHandleBlur("segmentDest" + valueName + i)}
                                    onChange={(event) => this.setSegmentDestCityName(event, setFieldValue, i, valueName)}
                                    onInputChange={(event) => { this.getOriginCitiesByFilter(event,'','') }}
                                    placeholder="To"
                                    openMenuOnFocus="true"
                                    // tabSelectsValue="false"
                                    defaultMenuIsOpen={true}
                                >
                                </Select>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="control-label">Craft:</label>
                                <input 
                                    className="form-control custom-filed"
                                    type="text" 
                                    placeholder="Craft" 
                                    name={"craft" + valueName + i} 
                                    onChange={handleChange} 
                                    value={values["craft" + valueName + i] ? values["craft" + valueName + i] : ''} 
                                    onBlur={handleBlur} 
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group ">
                                <label className="control-label">Depart:{i === 0 && <sup>*</sup>}</label>
                                <div className="form-row">
                                    <div className="col-sm-12 col-lg-6">
                                        <div className="custom-select-box">
                                            <select
                                                className={`form-control custom-filed ${(errors["departHour" + valueName + i] && touched["departHour" + valueName + i] && i === 0) && 'error-shadow'}`}
                                                name={"departHour" + valueName + i}
                                                onChange={handleChange} onBlur={handleBlur}
                                                value={values["departHour" + valueName + i]} >
                                                <option>HH</option>
                                                {
                                                    this.getHoursMinutes(24)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <div className="custom-select-box">
                                            <select
                                                className={`form-control custom-filed ${(errors["departMin" + valueName + i] && touched["departMin" + valueName + i] && i === 0) && 'error-shadow'}`}
                                                name={"departMin" + valueName + i}
                                                onChange={handleChange} onBlur={handleBlur}
                                                value={values["departMin" + valueName + i]} >
                                                <option>MM</option>
                                                {
                                                    this.getHoursMinutes(60)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group ">
                                <label className="control-label">Arrival:{i === 0 && <sup>*</sup>}</label>
                                <div className="form-row">
                                    <div className="col-sm-12 col-lg-6">
                                        <div className="custom-select-box">
                                            <select
                                                className={`form-control custom-filed ${(errors["arrivalHour" + valueName + i] && touched["arrivalHour" + valueName + i] && i === 0) && 'error-shadow'}`}
                                                name={"arrivalHour" + valueName + i}
                                                onChange={handleChange} onBlur={handleBlur}
                                                value={values["arrivalHour" + valueName + i]}
                                                >
                                                <option>HH</option>
                                                {
                                                    this.getHoursMinutes(24)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <div className="custom-select-box">
                                            <select
                                                className={`form-control custom-filed ${(errors["arrivalMin" + valueName + i] && touched["arrivalMin" + valueName + i] && i === 0) && 'error-shadow'}`}
                                                name={"arrivalMin" + valueName + i}
                                                onChange={handleChange} onBlur={handleBlur}
                                                value={values["arrivalMin" + valueName + i]}
                                                >
                                                <option>MM</option>
                                                {
                                                    this.getHoursMinutes(60)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="control-label">Baggage:</label>
                                <input 
                                    className="form-control custom-filed"
                                    type="text" 
                                    placeholder="5 kg or 1 piece" 
                                    name={"baggage" + valueName + i} 
                                    onChange={handleChange} 
                                    value={values["baggage" + valueName + i] ? values["baggage" + valueName + i] : ''} 
                                    onBlur={handleBlur} 
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="control-label">Origin Terminal:</label>
                                <input 
                                    className="form-control custom-filed"
                                    type="text" 
                                    placeholder="Origin Terminal" 
                                    name={"origin_terminal" + valueName + i} 
                                    onChange={handleChange} 
                                    value={values["origin_terminal" + valueName + i] ? values["origin_terminal" + valueName + i] : ''} 
                                    onBlur={handleBlur} 
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <label className="control-label">Destination Terminal:</label>
                                <input 
                                    className="form-control custom-filed"
                                    type="text" 
                                    placeholder="Destination Terminal" 
                                    name={"destination_terminal" + valueName + i} 
                                    onChange={handleChange} 
                                    value={values["destination_terminal" + valueName + i] ? values["destination_terminal" + valueName + i] : ''} 
                                    onBlur={handleBlur} 
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <div className="checkbox check-refund">
                                    <input name={"isArrivalNextDay" + valueName + i} type="checkbox" className="form-control" defaultChecked={values["isArrivalNextDay" + valueName + i] === 'Y' ? true : false} onChange={(event) => setFieldValue("isArrivalNextDay" + valueName + i, event.target.checked ? 'Y' : 'N')} />
                                    <label />
                                    <span>Is Arrival Next Day?</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        }
        return segmentArray;
    }

    getHoursMinutes = (value) => {
        let optionArray = [];
        for (let i = 0; i < value; i++) {
            if (i < 10) {
                optionArray.push(<option key={i} value={'0' + i}>0{i}</option>);
            } else {
                optionArray.push(<option key={i} value={i}>{i}</option>);
            }
        }
        return optionArray;
    }

    /*--------- Fare Rules popup ------------*/
        fareRulesPopup = () => {
            let {handleChange,handleBlur,values} = this.props;
            return (
                <div className={`popup-crad confirmation-popup ${this.state.showFareRulesPopup && 'show'}`}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>Enter Fare Rules</h2>
                                <div className="popup-close"><span className="icon icon-cross" onClick={() => this.setState({showFareRulesPopup : false })}/></div>
                            </div>
                            <div className="popup-body popup-scorll">
                                <div className="inner-confirmation">
                                    <textarea name='fareRules' className={`${(this.props.errors.fareRules && this.props.touched.fareRules) && 'error-shadow'}`} onChange={handleChange} onBlur={handleBlur} value={values.fareRules} ></textarea>
                                    <div className="popup-footer">
                                        <button className="btn btn-orange" onClick={() => this.setState({showFareRulesPopup : false })}>OK</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay"></div>
                </div>
            );
        }
}
export default AddVendorBookingComponent