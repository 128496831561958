import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { AutoSizer } from "react-virtualized";
import moment from 'moment';

// const fullMonthNames 	= ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const abbrMonthNames 	= ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
const fullDayNames 		= ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const abbrDayNames 		= ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const currencyFormat = (value) => {
	let amount = Number(value);
	// amount = parseFloat(Math.round(amount * 100) / 100).toFixed(2);
	amount = amount ? amount.toFixed(2) : 0;
	var nf = new Intl.NumberFormat('en-IN');
	return nf.format(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const dateFormat = (full_date, format) => {
	
	if(full_date)
	{let seconds = '', dateTime='';
		if (full_date.includes('T'))
			dateTime = full_date.split('T');
		else
			dateTime = full_date.split(' ');
		let new_date = '';
		if (dateTime.length > 1) {
			new_date = dateTime[1].split(':');
			seconds = new_date[2]
			if (seconds) {
				dateTime = new_date[0] + ':' + new_date[1];
			}else {
				dateTime = new_date[0] + ':' + new_date[1];
			}
		} else {
			dateTime = dateTime[1];
		}
		let d =''
		if(full_date.includes('000Z')){
			d= newDateFunction(full_date)
		}else{
			d= getNewDateFormted(full_date)
		}
		let onlyDate = d.getDate();
		let onlyMonth = d.getMonth(); //Months are zero based
		let onlyYear = d.getFullYear(); 
		let onlyDay = d.getDay(); 


		let onlyHour = d.getHours();
		let onlyMin = d.getMinutes();

		if(format === 'DD MMM')
		{
			onlyDate = (onlyDate + '').padStart(2, '0');
			onlyMonth = abbrMonthNames[onlyMonth];
			return onlyDate + ' ' + onlyMonth;
		}
		else if(format === 'DD')
		{
			onlyDate = (onlyDate + '').padStart(2, '0');
			return onlyDate;
		}
		else if(format === 'MMM YYYY, FDAY')
		{
			return abbrMonthNames[onlyMonth] + ' ' + onlyYear + ', ' + fullDayNames[onlyDay];
		}
		else if(format === 'MMM YYYY, DAY')
		{
			return abbrMonthNames[onlyMonth] + ' ' + onlyYear + ', ' + abbrDayNames[onlyDay];
		}
		else if(format === 'DD MMM YYYY, FDAY')
		{
			return onlyDate + ' ' +abbrMonthNames[onlyMonth] + ' ' + onlyYear + ', ' + fullDayNames[onlyDay];
		}
		else if(format === 'FDAY, DD MMM')
		{
			return fullDayNames[onlyDay]+ ', ' + onlyDate + ' ' +abbrMonthNames[onlyMonth];
		}
		else if(format === 'FDAY, DD MMM, HH:MM')
		{
			onlyHour = (onlyHour + '').padStart(2, '0');
			onlyMin = (onlyMin + '').padStart(2, '0');
			return fullDayNames[onlyDay]+ ', ' + onlyDate + ' ' +abbrMonthNames[onlyMonth] + ', ' + onlyHour + ':' + onlyMin;
		}
		else if(format === 'DAY, DD MMM, HH:MM')
		{
			onlyHour = (onlyHour + '').padStart(2, '0');
			onlyMin = (onlyMin + '').padStart(2, '0');
			return abbrDayNames[onlyDay]+ ', ' + onlyDate + ' ' +abbrMonthNames[onlyMonth] + ', ' + onlyHour + ':' + onlyMin;
		}
		else if(format === 'FDAY, DD MMM YYYY')
		{
			return fullDayNames[onlyDay]+', '+onlyDate + ' ' +abbrMonthNames[onlyMonth] + ' ' + onlyYear;
		}
		else if(format === 'FDAY, DD MMM YYYY h:m')
		{
			return abbrDayNames[onlyDay]+', '+onlyDate + ' ' +abbrMonthNames[onlyMonth] + ' ' + onlyYear+' '+dateTime;
		}
		else if(format === 'DAY, DD MMM YYYY')
		{
			return abbrDayNames[onlyDay]+', '+onlyDate + ' ' +abbrMonthNames[onlyMonth] + ' ' + onlyYear;
		}
		else if(format === 'DD MMM YYYY')
		{
			return onlyDate + ' ' +abbrMonthNames[onlyMonth] + ' ' + onlyYear;
		}
		else if(format === 'MMM DD, HH:MM AMPM')
		{
			onlyMin = (onlyMin + '').padStart(2, '0');
			return abbrMonthNames[onlyMonth]+' '+onlyDate +', ' + onlyHour + ':' +onlyMin +" "+ (onlyHour>12?"PM":"AM");
		}
		else if(format === 'DD MM YYYY HH:MM')
		{
			onlyHour = (onlyHour + '').padStart(2, '0');
			onlyMin = (onlyMin + '').padStart(2, '0');
			return onlyDate + ' ' +abbrMonthNames[onlyMonth] + ' ' + onlyYear+' '+ onlyHour + ':' + onlyMin;
		}
		else if(format === 'DD-MMM-YYYY')
		{
			return onlyDate + '-' +abbrMonthNames[onlyMonth] + '-' + onlyYear;
		}
		else if(format === 'HH:MM AMPM FDAY,DD/MMM/YYYY')
		{
			onlyMin = (onlyMin + '').padStart(2, '0');
			return onlyHour + ':' +onlyMin +" "+ (onlyHour>12?"PM":"AM")+" "+abbrDayNames[onlyDay]+", "+onlyDate + '/' +abbrMonthNames[onlyMonth] + '/' + onlyYear;
		}
		else if(format === 'HH:MM FDAY,DD/MMM/YYYY')
		{
			onlyMin = (onlyMin + '').padStart(2, '0');
			onlyHour = (onlyHour + '').padStart(2, '0');
			return onlyHour + ':' +onlyMin +" "+abbrDayNames[onlyDay]+", "+onlyDate + '/' +abbrMonthNames[onlyMonth] + '/' + onlyYear;
		}
		else if(format === 'FDAY')
		{
			return fullDayNames[onlyDay];
		}
		else
		{
			return full_date;
		}
	}
	
};

export const timeFormatHhMm = (full_date) => {
	let d = getNewDateFormted(full_date);
	if (d) {
		let departHour = (d.getHours() + '').padStart(2, '0');
		let departMin = (d.getMinutes() + '').padStart(2, '0');
		return departHour + ':' + departMin;
	} else {
		return d;
	}
};

export const arrayUnique = (arr) => {
	if(Array.isArray(arr[0]))
	{
		var hashMap = {}
		arr.forEach(function(data){
			hashMap[data.join("|")] = data;
		});
		var result = Object.keys(hashMap).map(function(k){
			return hashMap[k]
		})
		return result;
	}
	else
	{
		let findUnique = (data) => data.filter((v,i) => data.indexOf(v) === i)
		return findUnique(arr);
	}
};

export const remvoveArrayElement = (arr, value) => {
	if(Array.isArray(arr))
	{
		let index = arr.indexOf(value);
		if (index > -1) {
			arr.splice(index, 1);
		}
		return arr;
	}
	else
	{
		return [];
	}
};

export const arrayIntersection = (arr1, arr2) => {
	var temp, finalArr;
	if (arr2.length > arr1.length)  // indexOf to loop over shorter
	{
		temp = arr2; 
		arr2 = arr1; 
		arr1 = temp;
	}
	let index = arr2.indexOf("I5");
	if (index !== -1) {
		arr2[index] = "IX";
	}
	finalArr = arr1.filter(function (e) {
		if(e.replace(/\s+/g, '') === 'I5'){
			e = 'IX'
		}
        return arr2.indexOf(e) > -1;
	});
	return finalArr;
};

export const timeDifference = (startTime, endTime) => {
	startTime = newDateFunction(startTime);
	endTime = newDateFunction(endTime);
	return (Math.abs(endTime.getTime() - startTime.getTime()))/(1000*60);
};

export const minutesToHours = (minutes) => {
	return (Math.floor(minutes / 60) + '').padStart(2, '0');
};

export const minutesRemainder = (minutes) => {
	return ((minutes % 60) + '').padStart(2, '0');
};

export const minutesToDuration = (minutes, format) => {
	if(format === ':')
	{
		return minutesToHours(minutes) + ':' + minutesRemainder(minutes);
	}
	else
	{
		return minutesToHours(minutes) + 'h ' + minutesRemainder(minutes) + 'm';
	}
};

export const showValue = (prefix, value, suffix) => {
	if(value !== '' && value !== null && value !== undefined && value !== 'null' && value !== 'undefined')
	{
		if(value.toString().includes('Terminal'))
		{
			return " "+value + suffix;
		}
		else
		{
			return prefix + value + suffix;
		}
	}
	else
	{
		return '';
	}
};


export const lastSeenFormat = (segments) => {
	if(segments)
	{
		var today = getCurrentDate()
		var yyyy = today.getFullYear();
		var mm = (today.getMonth() + 1);
		var dd = today.getDate();
		var hh = today.getHours();
		var min = today.getMinutes();
		var ss = today.getSeconds();
		var Hour = '';
		if(hh > 12)
			Hour = hh-12;
		else 
			 Hour = hh;
			 
		var date = yyyy+'-'+(mm + '').padStart(2, '0')+'-'+(dd + '').padStart(2, '0')+' '+(Hour + '').padStart(2, '0')+':'+(min + '').padStart(2, '0')+':'+(ss + '').padStart(2, '0');

		var date1 = newDateFunction(date);
		var date2 = newDateFunction(segments);
		var timeDiff = (Math.abs(date2.getTime() - date1.getTime()))/1000;
		
		var days = Math.floor(timeDiff / 86400);
		var hours = Math.floor(timeDiff / 3600) % 24;
		var minutes = Math.floor(timeDiff / 60) % 60;

		if(days !== 17927 )
		{
			if(minutes > 1 && hours === 0 && days === 0)
				return minutes+' Minutes ago';
			else if(minutes === 1 && hours === 0 && days === 0)
				return minutes+' Minute ago';
			else if(hours > 1 && days === 0)
				return hours+' Hours ago';
			else if(hours === 1 && days === 0)
				return hours+' Hour ago';
			else if(days > 1)
				return  days+' Days ago';
			else if(days === 1)
				return  days+' Day ago';
			else
				return '0 Minutes ago';
		}else{
				return '0 Minutes ago';
		}
	}
	else{
		return '0 Minutes ago';
	}
};

export const getDateWithOrdinal =(fullDate, format)=>{
	var formatedDate = dateFormat(fullDate, format);
	let dateArray = formatedDate.split(" ");
	let ordinalDate = '';
	let finalDate = '';
	if(format === "DD MMM YYYY")
	{
		ordinalDate = getNumberWithOrdinal(dateArray[0]);
		finalDate = ordinalDate+" "+dateArray[1]+" "+dateArray[2];
	}
	else if(format === "DD MMM")
	{
		ordinalDate = getNumberWithOrdinal(dateArray[0]);
		finalDate = ordinalDate+" "+dateArray[1];
	}
	else if(format === "DD")
	{
		ordinalDate = getNumberWithOrdinal(dateArray[0]);
		finalDate = ordinalDate;
	}
	
	return finalDate;

}
export const getNumberWithOrdinal = (number) => {
    var displayName=["th","st","nd","rd"];
   	var finalNo = number%100;
    return number+(displayName[(finalNo-20)%10]||displayName[finalNo]||displayName[0]);
 }
export const getParameterFromUrl = (name) => {
    name = name.replace(/[[\]]/g, "\\$&");
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const jsUcfirst = (string) => 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const compareDate = (dateFirst,dateSecond) => {  
	//Note: 00 is month i.e. January  
	let dateOne = '';
	if(dateFirst === 'currentDate'){	
		 dateOne = getCurrentDate();
	}else{
		 dateOne = newDateFunction(dateFirst); //Year, Month, Date  
	}
	var dateTwo = newDateFunction(dateSecond); //Year, Month, Date  
	if (dateOne > dateTwo) {  
		 return 'true';
	 }else {  
		 return 'false';
	 }  
 } 
 
export const numberToWords = (amount) => {
	if(amount !== '' &&  amount !== undefined)
	{
		var words = [];
		words[0] = '';
		words[1] = 'One';
		words[2] = 'Two';
		words[3] = 'Three';
		words[4] = 'Four';
		words[5] = 'Five';
		words[6] = 'Six';
		words[7] = 'Seven';
		words[8] = 'Eight';
		words[9] = 'Nine';
		words[10] = 'Ten';
		words[11] = 'Eleven';
		words[12] = 'Twelve';
		words[13] = 'Thirteen';
		words[14] = 'Fourteen';
		words[15] = 'Fifteen';
		words[16] = 'Sixteen';
		words[17] = 'Seventeen';
		words[18] = 'Eighteen';
		words[19] = 'Nineteen';
		words[20] = 'Twenty';
		words[30] = 'Thirty';
		words[40] = 'Forty';
		words[50] = 'Fifty';
		words[60] = 'Sixty';
		words[70] = 'Seventy';
		words[80] = 'Eighty';
		words[90] = 'Ninety';
		amount = amount.toString();
		var atemp = amount.split(".");
		var number = atemp[0].split(",").join("");
		var n_length = number.length;
		var words_string = "";
		var j = '';

		if (n_length <= 9) {
			var n_array = [0, 0, 0, 0, 0, 0, 0, 0, 0];
			var received_n_array =[];
			for (var i = 0; i < n_length; i++) {
				received_n_array[i] = number.substr(i, 1);
			}
			for ( i = 9 - n_length, j = 0; i < 9; i++, j++) {
				n_array[i] = received_n_array[j];
			}
			for ( i = 0, j = 1; i < 9; i++, j++) {
				if (i === 0 || i === 2 || i === 4 || i === 7) {
					/* eslint-disable-next-line */
					if (n_array[i] == 1) {
						n_array[j] = 10 + parseInt(n_array[j]);
						n_array[i] = 0;
					}
				}
			}
			var value = "";
			for (i = 0; i < 9; i++) {
				if (i === 0 || i === 2 || i === 4 || i === 7) {
					value = n_array[i] * 10;
				} else {
					value = n_array[i];
				}
				if (value !== 0) {
					words_string += words[value] + " ";
				}
				if ((i === 1 && value !== 0) || (i === 0 && value !== 0 && n_array[i + 1] === 0)) {
					words_string += "Crores ";
				}
				if ((i === 3 && value !== 0) || (i === 2 && value !== 0 && n_array[i + 1] === 0)) {
					words_string += "Lakhs ";
				}
				if ((i === 5 && value !== 0) || (i === 4 && value !== 0 && n_array[i + 1] === 0)) {
					words_string += "Thousand ";
				}/* eslint-disable-next-line */
				if (i == 6 && value != 0 && (n_array[i + 1] !== 0 && n_array[i + 2] !== 0)) {
					words_string += "Hundred ";
				} /* eslint-disable-next-line */
				else if (i == 6 && value != 0) {
					words_string += "Hundred ";
				}
			}
			words_string = words_string.split("  ").join(" ");
		}
		return words_string;
	}	

};
export const withDecimal = (n) => {
    var nums = n.toString().split('.')
    var whole = numberToWords(nums[0])
    if (nums.length === 2) {
        var fraction = numberToWords(nums[1])
        return whole + 'and ' + fraction;
    } else {
        return whole;
    }
}
export const addLeadingZero = (n) => {
	return (n < 10) ? ("0" + n) : n;
};


//getting pretty scrollBar
export const prettyScrollBar = (Data,width,height) => {  
	return <div className="pretty-scroll"
	style={{
		display: "flex",
		height: 'calc('+width+' - '+height+')'
	}}>
		<div style={{ flex: "1 1 auto" }}>
			<AutoSizer>
				{({ width, height }) => {
					return (
							<Scrollbars style={{ width, height }}>
							{Data}
							</Scrollbars>
							);
				}}
			</AutoSizer>
		</div>
	</div>	
 } 

 export const getFormattedDate = (date) => {
		var today = newDateFunction(date)
		var yyyy = today.getFullYear();
		var mm = (today.getMonth() + 1);
		var dd = today.getDate();
		var NewDate = yyyy+'-'+(mm + '').padStart(2, '0')+'-'+(dd + '').padStart(2, '0');
		return NewDate;
};

export const getNewDateFormted = (date) => {
	if (date) {
		var s = date;
		var a = s.split(/[^0-9]/);
		let a1 = a[0] ? a[0] : '';
		let a2 = a[1] ? a[1] - 1 : '';
		let a3 = a[2] ? a[2] : '';
		let a4 = a[3] ? a[3] : '';
		let a5 = a[4] ? a[4] : '';
		let a6 = a[5] ? a[5] : '';
		let NewDate = new Date(a1, a2, a3, a4, a5, a6);
		return NewDate;
	} else {
		return date;
	}
};

//Get current date
export const getCurrentDate = () => {
	let NewDate = new Date();
	return NewDate;
};

//Get data formated from new date function
export const newDateFunction = (date) => {
	let NewDate = new Date(date);
	return NewDate;
};

export const onClickAnchor = (e) => {
	e.preventDefault();
};

export const getValidationDate = (date) => {
	if (date) {
		//let NewDate1 = new Date(date);
		var momentObj = moment(date);
		var momentString = momentObj.format('YYYY-MM-DD');
		var s = momentString;
		var a = s.split(/[^0-9]/);
		let a1 = a[0] ? a[0] : '';
		let a2 = a[1] ? a[1] - 1 : '';
		let a3 = a[2] ? a[2] : '';
		let NewDate = new Date(a1, a2, a3);
		return NewDate;
	} else {
		return date;
	}
};

export const dateDifference = (startDate, endDate) => {
	startDate = newDateFunction(startDate);
	endDate = newDateFunction(endDate);
	let differenceInTime = endDate.getTime() - startDate.getTime();
	let differenceInDays = differenceInTime / (1000 * 3600 * 24);
	return differenceInDays;
};

export const getNextDate = (newDate) => {
	const today = new Date(newDate)
	let tomorrow = new Date(today)
	tomorrow.setDate(tomorrow.getDate() + 1);
	tomorrow = getFormattedDate(tomorrow);
	return tomorrow;
}

export const arrayUniqueAirlineCode = (arr) => {
	const key = 'airline_code';
	const arrayUniqueByKey = [...new Map(arr.map(item => [item[key], item])).values()];
	return arrayUniqueByKey;
}