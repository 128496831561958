/* external dependencies */
import React from 'react'
import * as yup from "yup"
import LoginPage from '../../components/shared/login_reset_password';
import { connect } from 'react-redux'
import { loginAction, loginVerifyAction } from '../../actions/shared/sessionAction';
import { verifyEmail, resetPassword, changeComponentClass } from '../../actions/shared/resetPasswordAction';
import { pagesAction, getIp } from '../../actions/shared/sharedAction'
import VerifyLoginPage from '../../components/shared/login_otp/VerifyLoginPage';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            email: null,
            loginSuccess : null,
            message :null,
            passwordExpiry : null,
            resend: false,
            otpSubmit: false,
            disableResendButton: false,
            countdown: 60,
        };
    }

    /* create our form validator */
    loginValidator = yup.object()
        .shape({
            username: yup.string()
                .required('User Name is required'),
            password: yup.string()
                .required('Please enter your password')
        });

    handleComponentClass = (values, event) => {
        this.props.changeFlipCardClass(values);
    }

    //Function to redirect after login
    loginRedirection = () => {
        let cookies = document.cookie;
        let cookieValue = null;
        for (let i = 0; i < cookies.length; i++) {
            if (cookies.startsWith("session_id=")) {
                cookieValue = cookies.substring("session_id=".length, cookies.length);
                break;
            }
        }
        let { agentProductAccess } = this.props;
        let redirectTo;
        this.setState({
            passwordExpiry: null
        }, () => {
            if (cookieValue !== null && this.props.session && this.props.session.token) {
                // just after successful login, check if user have access to any product, if have than redirect to that product search page otherwise show error message
                if (agentProductAccess.response && agentProductAccess.response.user_access_info.length > 0) {
                    let products = this.getAllAccessProducts(agentProductAccess.response.user_access_info);
                    if (products.includes('flight-booking')) {
                        redirectTo = '/flight-search';
                    } else if (products.includes('hotel-booking')) {
                        redirectTo = '/hotel-search';
                    } else if (products.includes('train-booking')) {
                        redirectTo = '/my-account';
                    }
                    if (redirectTo) {
                        localStorage.setItem('assigned-product', redirectTo);
                        this.setState({ loginSuccess: true });
                        setTimeout(function () {
                            window.location.href = redirectTo;
                        }, 1000);
                    }
                } else {
                    this.setState({ error: "You don't have any product to access. Please contact your administrator." });
                }
            }
            else {
                this.props.history.push({
                    pathname: '/verify-otp',
                    username: this.props.history.location && this.props.history.location.username,
                    password: this.props.history.location && this.props.history.location.password
                })
            }
        })
    }

    getAllAccessProducts(allProducts)
    {
        let products = [];
        /* eslint-disable-next-line */
        allProducts.map((item,index) => {
            products.push(item.product_url);
        })
        return products;
    }
    /* define our submit handler */
    handleLoginSubmit = (values, actions) => {
        this.setState({ error: null });
        let cookies = document.cookie;
        let cookieValue = null;
        for (let i = 0; i < cookies.length; i++) {
            if (cookies.startsWith("session_id=")) {
                cookieValue = cookies.substring("session_id=".length, cookies.length);
                break;
            }
        }

        /* on successful login, forward the user to the Searching container */
        const successCb = (data) => {
            let { agentProductAccess } = this.props;
            let redirectTo;
            if (data.password_expires_in <= 5) {
                this.setState({
                    passwordExpiry: data.password_expires_in,
                    disableResendButton: true
                })
                this.props.history.push({ 
                    username : values.username,
                    password : values.password
                })
                // Start the countdown timer
                this.timer = setInterval(() => {
                    this.setState((prevState) => {
                        if (prevState.countdown > 1) {
                            return { countdown: prevState.countdown - 1 };
                        } else {
                            clearInterval(this.timer);
                            return { disableResendButton: false, countdown: 60 };
                        }
                    });
                }, 1000);
            } else {
                if (cookieValue !== null  && data.token) {
                    // just after successful login, check if user have access to any product, if have than redirect to that product search page otherwise show error message
                    if (agentProductAccess.response && agentProductAccess.response.user_access_info.length > 0) {
                        let products = this.getAllAccessProducts(agentProductAccess.response.user_access_info);
                        if (products.includes('flight-booking')) {
                            redirectTo = '/flight-search';
                        } else if (products.includes('hotel-booking')) {
                            redirectTo = '/hotel-search';
                        } else if (products.includes('train-booking')) {
                            redirectTo = '/my-account';
                        }
                        if (redirectTo) {
                            localStorage.setItem('assigned-product', redirectTo);
                            this.setState({ loginSuccess: true });
                            setTimeout(function () {
                                window.location.href = redirectTo;
                            }, 1000);
                        }
                    } else {
                        this.setState({ error: "You don't have any product to access. Please contact your administrator." });
                    }
                }
                else {
                    this.setState({ disableResendButton: true });
                    this.props.history.push({
                        pathname: '/verify-otp',
                        username: values.username,
                        password: values.password
                    })
                    // Start the countdown timer
                    this.timer = setInterval(() => {
                        this.setState((prevState) => {
                            if (prevState.countdown > 1) {
                                return { countdown: prevState.countdown - 1 };
                            } else {
                                clearInterval(this.timer);
                                return { disableResendButton: false, countdown: 60 };
                            }
                        });
                    }, 1000);
                }
            }
        }

        /* on error, update the error in the state */
        const errorCb = (error) => {
            this.setState({ error: error });
            actions.setSubmitting(false);
        }
        values['session_id'] = (cookieValue === null ? null : cookieValue.replace(/['"]+/g, ''))
        values['from'] = 'front';
        values['ip'] = this.props.getIpResponse.response.ipAddress;
        this.props.loginAction(values, successCb, errorCb);
    };

    handleResendLoginSubmit = (values, actions) => {
        this.setState({ error: null });
        this.setState({ message: null });
        this.setState({resend: true});
        this.setState({ disableResendButton: true });
        /* on successful login, forward the user to the Searching container */
        const successCb = (data) => {
            this.setState({ message: data.message });
            setTimeout(() =>{
                this.setState({ message: null });
            },5000)
            this.setState({resend: false})
        }

        /* on error, update the error in the state */
        const errorCb = (error) => {
            this.setState({ error: error });
            this.setState({resend: false});
            setTimeout(() =>{
                this.setState({ error: null });
            },5000)
        }
        values['username'] = values.username;
        values['password'] = values.password;
        values['from'] = 'front';
        values['ip'] = this.props.getIpResponse.response.ipAddress;
        this.props.loginAction(values, successCb, errorCb);
        // Start the countdown timer
        this.timer = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.countdown > 1) {
                    return { countdown: prevState.countdown - 1 };
                } else {
                    clearInterval(this.timer);
                    return { disableResendButton: false, countdown: 60 };
                }
            });
        }, 1000);
    };

    handleVerifyOtp = (values, actions) => {
        this.setState({ error: null });
        this.setState({otpSubmit: true});
        /* on successful login, forward the user to the Searching container */
        const successCb = (data) => {
            const COOKIE_NAME = "session_id"
            const cookieExpireDate = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000);
            document.cookie = `${COOKIE_NAME}=${JSON.stringify(data.session_id)}; expires=${cookieExpireDate};`;
            let { agentProductAccess } = this.props;
            let redirectTo;
            this.setState({otpSubmit: false});
            if (data.password_expires_in <= 5) {
                this.setState({
                    passwordExpiry: data.password_expires_in,
                    disableResendButton: true
                })
                // Start the countdown timer
                this.timer = setInterval(() => {
                    this.setState((prevState) => {
                        if (prevState.countdown > 1) {
                            return { countdown: prevState.countdown - 1 };
                        } else {
                            clearInterval(this.timer);
                            return { disableResendButton: false, countdown: 60 };
                        }
                    });
                }, 1000);
            } else {
                // just after successful login, check if user have access to any product, if have than redirect to that product search page otherwise show error message
                if (agentProductAccess.response && agentProductAccess.response.user_access_info.length > 0) {
                    let products = this.getAllAccessProducts(agentProductAccess.response.user_access_info);
                    if (products.includes('flight-booking')) {
                        redirectTo = '/flight-search';
                    } else if (products.includes('hotel-booking')) {
                        redirectTo = '/hotel-search';
                    } else if (products.includes('train-booking')) {
                        redirectTo = '/my-account';
                    }
                    if (redirectTo) {
                        localStorage.setItem('assigned-product', redirectTo);
                        this.setState({ loginSuccess: true });
                        setTimeout(function () {
                            window.location.href = redirectTo;
                        }, 1000);
                    }
                } else {
                    this.setState({ error: "You don't have any product to access. Please contact your administrator." });
                }
            }
        }

        /* on error, update the error in the state */
        const errorCb = (error) => {
            this.setState({ error: error });
            this.setState({otpSubmit: false})
            setTimeout(() =>{
                this.setState({ error: null });
            },5000)
            actions.setSubmitting(false);
        }
        values['from'] = 'front';
        values['ip'] = this.props.getIpResponse.response.ipAddress;
        values['username'] = this.props.location && this.props.location.username
        this.props.loginVerifyAction(values, successCb, errorCb);
    };

    handleEmailSubmit = (values, actions, flipCardClass) => {
        this.setState({error: null});
        this.setState({email: values.email}); //stored the email from verifyEmail so we can use it as input in change password 

        /* on successful login, forward the user to the Searching container */
        const successCb = () => {
            actions.setSubmitting(false);
        }

        /* on error, update the error in the state */
        const errorCb = (error) => {
            this.setState({error: error});
            actions.setSubmitting(false);
        }
        this.props.verifyEmailAction(values, flipCardClass, successCb, errorCb);
    };

    handlePasswordSubmit = (values, actions) => {
        this.setState({error: null});

        /* on successful login, forward the user to the Searching container */
        const successCb = () => {
            setTimeout(function(){  window.location.href = '/login'; }, 1000);    
        }

        /* on error, update the error in the state */
        const errorCb = (error) => {
            this.setState({error: error});
            actions.setSubmitting(false);
        }

        values.email = this.state.email; //got the email from state into values
        this.props.resetPasswordAction(values, successCb, errorCb);
    };

    componentDidMount() {
        this.props.pagesAction();
         this.props.getIp();
        if(this.props.location && this.props.location.pathname === '/verify-otp'){
            if ('referrer' in document) {
                //window.location = '/login';
                window.location.href = '/login'
                /* OR */
                //location.replace(document.referrer);
            } 
            else {
                window.history.back();
            }
        }
    }
    componentWillUnmount() {
        // Clear the timer if the component unmounts to avoid memory leaks
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    render() {
        let { location } = this.props;
        return (
            location && location.pathname !== '/verify-otp' ?
            <LoginPage
                handleComponentClass={this.handleComponentClass.bind(this)}
                handleLoginSubmit={this.handleLoginSubmit.bind(this)}
                handleEmailSubmit={this.handleEmailSubmit.bind(this)}
                handlePasswordSubmit={this.handlePasswordSubmit.bind(this)}
                handleVerifyOtp={this.handleVerifyOtp.bind(this)}
                errorMsg={this.state.error}
                {...this.props}
                successMsg={this.state.loginSuccess}
                passwordExpiry={this.state.passwordExpiry}
                loginRedirection={this.loginRedirection}
                disableResendButton={this.state.disableResendButton}
                countdown={this.state.countdown}
            />:
            <VerifyLoginPage
                handleComponentClass={this.handleComponentClass.bind(this)}
                handleResendLoginSubmit={this.handleResendLoginSubmit.bind(this)}
                handleEmailSubmit={this.handleEmailSubmit.bind(this)}
                handlePasswordSubmit={this.handlePasswordSubmit.bind(this)}
                handleVerifyOtp={this.handleVerifyOtp.bind(this)}
                errorMsg={this.state.error}
                {...this.props}
                successMsg={this.state.message}
                passwordExpiry={this.state.passwordExpiry}
                loginRedirection={this.loginRedirection}
                resend={this.state.resend}
                otpSubmit={this.state.otpSubmit}
                disableResendButton={this.state.disableResendButton}
                countdown={this.state.countdown}
            />
        );
    }

}

const mapStateToProps = (state) => {
    return {
        session : state.session,
        resetPassword: state.resetPassword,
        productResponse: state.shared.productResponse,
        settingResponse: state.shared.settingResponse,
        pagesResponse: state.shared.pagesResponse,
        agentProductAccess: state.shared.agentProductAccess,
        getIpResponse: state.shared.getIpResponse,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loginAction: (credentials, successCb, errorCb) => dispatch(loginAction(credentials, successCb, errorCb)),
        verifyEmailAction: (email, flipCardClass, successCb, errorCb) => dispatch(verifyEmail(email, flipCardClass, successCb, errorCb)),
        resetPasswordAction: (inputs, successCb, errorCb) => dispatch(resetPassword(inputs, successCb, errorCb)),
        pagesAction: (credentials) => dispatch(pagesAction(credentials)),
        changeFlipCardClass: (flipCardClass) => dispatch(changeComponentClass(flipCardClass)),
        getIp: () => dispatch(getIp()),
        loginVerifyAction: (credentials, successCb, errorCb) => dispatch(loginVerifyAction(credentials, successCb, errorCb)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Login)