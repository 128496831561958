import React from 'react';
import { Field } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { dateFormat, jsUcfirst ,compareDate} from '../../../shared/commonHelper';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar'

class HotelReviewBookingComponent extends React.Component {
    constructor(props) {
		super(props)
		this.state = {
			showConfirmationPopup : false,
		}
    }

    // ----------Booking Confirmation popup-------------
    handleConfirmationPopup = (action)=> {

        if(action === 'submit')
        {
            this.props.handleSubmit();
        }

        if(this.props.values['terms_conditions'] && this.props.values['under_cancel'])
        {
            this.setState({showConfirmationPopup : !this.state.showConfirmationPopup});
        }
        else
        {
            !this.props.values['terms_conditions'] && this.props.setFieldError('terms_conditions', 'required');
            !this.props.values['under_cancel'] && this.props.setFieldError('under_cancel', 'required');
        }
    }

    // Hotel Norms Information
    renderHotelNorms(hotelNorms) {
        return (
            <PerfectScrollbar>
                <div className="inner-fare-block hotel-norms">
                    <ul className="hotel-norms-list">
                    {   hotelNorms && hotelNorms.map((hotelNorm, key) => {
                        let norm = hotelNorm.replace(/\|\\r\n/g,'<br/>');
                        return  (
                                norm && norm !== '' &&
                                <li key={key}>
                                    <span dangerouslySetInnerHTML={{ __html: norm }} ></span>
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
            </PerfectScrollbar>
        );
    }
    
	render() {
        let {fareDetails,cancelPolicy,tripjackOfferPrice} = this.props;
        let self = this;
        let roomDetails = this.props.blockHotelDetails;

        let accountResponse = this.props.accountResponse.myAccountResponse.response
        let totalAccountBalance = 0;
        if(accountResponse){
            if(parseInt(accountResponse.cash_balance) > 0){
                totalAccountBalance = parseInt(accountResponse.cash_balance) + parseInt(accountResponse.credit_limit);
            }else{
                totalAccountBalance = parseInt(accountResponse.credit_limit);
            }
		}

		let offeredFare = fareDetails && fareDetails.offered_price;
        let showInsuffient = false
        if(this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type === 'tripjack'){
            if(tripjackOfferPrice && accountResponse && (totalAccountBalance < tripjackOfferPrice)){
                showInsuffient = true;
            }
        }else{
            if(offeredFare && accountResponse && (totalAccountBalance < offeredFare)){
                showInsuffient = true;
            }
        }
        let lastCancellationDate = this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type !== 'tripjack' && this.props.blockHotelDetails.response.hotel_room_details[0].last_cancellation_date;
        let lastCacellationTripjackDate = this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type === 'tripjack' && cancelPolicy && cancelPolicy.response && cancelPolicy.response.last_cancellation_date;
        let showHold = compareDate('currentDate',lastCancellationDate);
        let showTripHold = compareDate('currentDate',lastCacellationTripjackDate);
        let supplierType = this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.supplier_type;
        let isBA=this.props.blockHotelDetails.response && this.props.blockHotelDetails.response.conditions && this.props.blockHotelDetails.response.conditions.isBA
        return (
                <div className="tabs-content-wrap">
                    <div className="step-content" >
                        <div className="review-filght-step">

                            {/*------------------- Number of guest details room wise --------------------*/}
                            {<GuestRoomDetailsComponent roomDetails={roomDetails} searchQuery={this.props.searchQuery} cancelPolicy={this.props.cancelPolicy}/>}

                            {/*------------------- Passenger details --------------------*/}
                            <div className="review-step">
                                <div className="review-step-title">
                                    <div className="cols pull-left"> <span>Guest Details</span> </div>
                                    <div className="cols pull-right">  </div>
                                </div>
                                <div className= "review-step-deatil">
                                    <div className="step-deatil-outer">
                                        <div className="passenger-information">
                                            <div className="passenger-fl-info">
                                                { this.props.searchQuery.room_guests.map(function(room, index) {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            { <PassengerDetailComponent {...self.props} travellerType={'adult'} trevellerCount={room.no_of_adults} childAge={room.child_age} roomNumber={index+1}/>}
                                                            { <PassengerDetailComponent {...self.props} travellerType={'child'} trevellerCount={room.no_of_child} childAge={room.child_age} roomNumber={index+1}/> }
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>      
                            {/*------------------- Guest arrivals details --------------------*/}
                            {(this.props.values.flight_no || this.props.values.transport_no) && <GuestArrivalDetailComponent {...this.props} />}

                            {/*------------------- Guest arrival details --------------------*/}
                            {<IsCorporateComponent {...this.props} /> }

                            {/*------------------- Room cancellation and charge details --------------------*/}
                            {<RoomCancellationAndChargeDetailComponent roomDetails={roomDetails} searchQuery={this.props.searchQuery} cancelPolicy={this.props.cancelPolicy}/>}

                            {/*------------------- Amenities detail --------------------*/}
                            {<AmenitiesDetailComponent roomDetails={roomDetails} searchQuery={this.props.searchQuery}/>}     

                            {/*------------------- Hotel norm details -----------------*/}
                            {<HotelNormDetailComponent roomDetails={roomDetails} renderHotelNorms={this.renderHotelNorms} />}

                            {/*------------------- Hotel terms and condition and under cancel -----------------*/}
                            <HotelTermsConditionComponent {...this.props}/>

                            <span className="insufficient_balance" style={{display:showInsuffient?'block':'none'}}>Sorry insufficient balance in your account. Booking can't be proceed.</span>
                            <div className="btn-block text-right">
                                {
                                    (showHold === 'false' || showTripHold === 'false') && isBA &&
                                    <button type="button" className={`btn btn-primary`} onClick={() => {
                                        this.props.setFieldValue('submitType','hold',false);    
                                        this.handleConfirmationPopup()
                                    }}>
                                    Hold
                                        {
                                            //this.props.hotelBookDetails.loading && <i className="ht-loader"><img src="assets/images/btn-search_hotel.gif" alt="" /></i>
                                        }        
                                    </button>
                                }
                                   <button type="button" className={`btn btn-primary ${showInsuffient ? 'btn-disabled' : ''}`} onClick={() => {
                                       this.props.setFieldValue('submitType','book',false); 
                                       this.handleConfirmationPopup()
                                   }}>
                                   Book
                                    {
                                        //this.props.hotelBookDetails.loading && <i className="ht-loader"><img src="assets/images/btn-search_hotel.gif" alt="" /></i>
                                    }        
                                   </button>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.hotelBookDetails.loading && supplierType !== 'tripjack' &&
                        <div className="loader-hotel">
                            <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/white-hotel-loader.gif" alt="loader" /></div>        
                            <div className="overlay-loader show"></div>
                        </div>
                    }
                    {
                        this.props.hotelBookDetails.loading === true && supplierType === 'tripjack' &&
                        <div className="loader-hotel">
                            <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/white-hotel-loader.gif" alt="loader" /></div>        
                            <div className="overlay-loader show"></div>
                        </div>
                    }
                    {
                        (this.props.hotelBookedDetails.loading === true || this.props.isBookingPendingData) && supplierType === 'tripjack' &&
                        <div className="loader-hotel">
                            <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/white-hotel-loader.gif" alt="loader" /></div>        
                            <div className="overlay-loader show"></div>
                        </div>
                    }
                    <BookingConfirmationComponent {...this.props} {...this.state} handleConfirmationPopup={this.handleConfirmationPopup} cancelPolicy={this.props.cancelPolicy}/>
                </div>
                )
	}
}

/*-------------- Passenger details component -----------------*/
class PassengerDetailComponent extends React.Component {
    render() {
        let {travellerType, trevellerCount, roomNumber, values, childAge} = this.props
        let rows = [];
		for (let i = 1; i <= trevellerCount; i++) {
			rows.push(
                <div className="cols" key={i}>
                <div className="adult-title"><span>Room {roomNumber}</span><small>({travellerType+' '+(i)})</small></div>
                    <ul className="psg-list">
                        <li>
                            <span>Name:</span>
                            <small style={{textTransform:"uppercase"}}>{values['guest_title_room' +roomNumber+'_'+ travellerType + i]+' '+jsUcfirst(values['first_name_room' +roomNumber+'_'+ travellerType + i])+' '+jsUcfirst(values['last_name_room' +roomNumber+'_'+ travellerType + i])}</small>
                        </li>

                        {values['pan_no_room' +roomNumber+'_'+ travellerType + i] &&
                        <li>
                            <span>PAN:</span>
                            {
                                values['isCopyPANToAll'] 
                                ?
                                <small style={{textTransform:"uppercase"}}>{values['pan_no_room' +roomNumber+'_'+ travellerType + i]}</small>
                                :
                                <small style={{textTransform:"uppercase"}}>{values['pan_no_room' +roomNumber+'_'+ travellerType + i]}</small>
                            }
                        </li>
                        }

                        {values['passport_no_room' +roomNumber+'_'+ travellerType + i] &&
                        <li>
                            <span>Passport No.:</span>
                            <small>{values['passport_no_room' +roomNumber+'_'+ travellerType + i]}</small>
                        </li>
                        }

                        {values['passport_issue_date_room' +roomNumber+'_'+ travellerType + i] &&
                        <li>
                            <span>Passport Issue Date: </span>
                            <small>{' '} {moment(values['passport_issue_date_room' +roomNumber+'_'+ travellerType + i]).format('YYYY-MM-DD')}</small>
                        </li>
                        }

                        {values['passport_exp_date_room' +roomNumber+'_'+ travellerType + i] &&
                        <li>
                            <span>Passport Expiry Date: </span>
                            <small>{' '} {moment(values['passport_exp_date_room' +roomNumber+'_'+ travellerType + i]).format('YYYY-MM-DD')}</small>
                        </li>
                        }

                        {values['contact_no_room' +roomNumber+'_'+ travellerType + i] &&
                        <li>
                            <span>Contact No.:</span>
                            <small>{values['contact_no_room' +roomNumber+'_'+ travellerType + i]}</small>
                        </li>
                        }

                        {childAge && travellerType === 'child' &&
                        <li>
                            <span>Age:</span>
                            <small>{childAge[i-1]}</small>
                        </li>
                        }

                    </ul>
                </div>
			);
        }
        return rows;
	}
}

/*------------ Guest room details component --------------*/
class GuestRoomDetailsComponent extends React.Component { 
    render(){
        let { searchQuery, roomDetails,cancelPolicy } = this.props;
        let hotelRoomInfo = roomDetails.response && roomDetails.response.hotel_room_details;
        let hotelInfo = roomDetails.response && roomDetails.response;
       
        let ratingLi = [];
		for(let i=0; i<hotelInfo.star_rating; i++) {
			ratingLi.push(<li key={i}><i className="icon icon-star-full" /></li>)
        }

        let checkInDate =  this.props.searchQuery.check_in_date.split('/')[2]+'-'+this.props.searchQuery.check_in_date.split('/')[1]+'-'+this.props.searchQuery.check_in_date.split('/')[0];
        let checkOutDate = this.props.searchQuery.check_out_date.split('/')[2]+'-'+this.props.searchQuery.check_out_date.split('/')[1]+'-'+this.props.searchQuery.check_out_date.split('/')[0];
        let hotelRoomInfoTripjack = roomDetails.response && roomDetails.response.hotel_rooms_details && roomDetails.response.hotel_rooms_details;

        let cancellationDateTripjack=hotelInfo && hotelInfo.supplier_type === 'tripjack' && cancelPolicy && cancelPolicy.response && cancelPolicy.response.cancellation_policies && cancelPolicy.response.cancellation_policies.length > 0 && 
        cancelPolicy.response.cancellation_policies.filter((date)=>{
           return date.charge === 0;
        });
        if(cancellationDateTripjack && cancellationDateTripjack.length > 0){
            if(cancellationDateTripjack && cancellationDateTripjack.length > 1){
                cancellationDateTripjack = cancellationDateTripjack.reduce((max, current) => {
                  return  new Date(current.to_date) > new Date(max.to_date) ? current : max;
               });
           }else{
                cancellationDateTripjack=cancellationDateTripjack[0]
           }
        }
        
        return(
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Booking Details</span></div>
                    </div>
                    <div className="review-step-deatil">
                        <div className="step-deatil-outer">
                            <div className="flight-information">
                                <div className="inner-fl-info">
                                    <table className="fl-book-info hotel-info">
                                        <thead></thead>
                                        <tbody>
                                            <tr><td><span className="text-bold">Hotel Name : </span> {hotelInfo.hotel_name} <ul className="rating">{ratingLi}</ul></td></tr>
                                            {
                                                hotelInfo.supplier_type === 'tripjack' &&
                                            <tr>
                                                <td>
                                                <span className="text-bold">Hotel Address : </span> 
                                                            <span className="text-span">{hotelInfo && hotelInfo.address && hotelInfo.address.adr + ", "}</span>
                                                            <span className="text-span">{hotelInfo && hotelInfo.address && hotelInfo.address.city && hotelInfo.address.city.name + ", "}</span>
                                                            <span className="text-span">{hotelInfo && hotelInfo.address && hotelInfo.address.state && hotelInfo.address.state.name + ", "}</span>
                                                            <span className="text-span">{hotelInfo && hotelInfo.address && hotelInfo.address.postalCode + ", "}</span>
                                                            <span className="text-span">{hotelInfo && hotelInfo.address && hotelInfo.address.country && hotelInfo.address.country.name}</span>
                                                </td>
                                            </tr>
                                            }
                                            {
                                                hotelInfo.supplier_type !== 'tripjack' &&
                                                <tr><td><span className="text-bold">Hotel Address : </span> {hotelInfo.address_line1} {hotelInfo.address_line2 && ', ' + hotelInfo.address_line2}</td></tr>
                                            }                                            
                                            <tr>
                                                <td>
                                                    <div className="travell-date">
                                                        <div className="cols"><span className="text-bold">Check In Date: </span> <small>{dateFormat(checkInDate,'DD MMM YYYY')}</small></div> 
                                                        <div className="cols"> <span className="text-bold">Check Out Date: </span> <small>{dateFormat(checkOutDate,'DD MMM YYYY')}</small></div> 
                                                    </div>
                                                    <div className="travell-date">
                                                        {/* {hotelInfo.hotel_room_details[0].last_cancellation_date &&
                                                            <div className="last-cancel-date cols">
                                                                <span className="text-bold">Last Cancellation Date: </span>{dateFormat(hotelInfo.hotel_room_details[0].last_cancellation_date,'DD MMM YYYY')}
                                                            </div>
                                                        }
                                                        {hotelInfo.hotel_room_details[0].last_voucher_date &&
                                                            <div className="last-cancel-date cols p-2">
                                                                <span className="text-bold">Last Voucher Date: </span>{dateFormat(hotelInfo.hotel_room_details[0].last_voucher_date,'DD MMM YYYY')}
                                                            </div>
                                                        } */}
                                                        {hotelInfo && hotelInfo.supplier_type !== 'tripjack' && hotelInfo.hotel_room_details && hotelInfo.hotel_room_details[0] && hotelInfo.hotel_room_details[0].last_cancellation_date &&
                                                            <div className="last-cancel-date cols">
                                                                <span className="text-bold">Last Cancellation Date: </span>{dateFormat(hotelInfo && hotelInfo.hotel_room_details[0] && hotelInfo.hotel_room_details[0].last_cancellation_date, 'DD MMM YYYY')}
                                                            </div>
                                                        }
                                                        {cancellationDateTripjack && cancellationDateTripjack.to_date && hotelInfo && hotelInfo.supplier_type === 'tripjack' && 
                                                            <div className="last-cancel-date cols">
                                                                <span className="text-bold">Last Cancellation Date: </span>{dateFormat(cancellationDateTripjack && cancellationDateTripjack.to_date, 'DD MMM YYYY')}
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="step-deatil-outer">
                            <div className="flight-information">
                                <div className="inner-fl-info">
                                    <table className="fl-book-info">
                                        <thead>
                                            <tr>
                                                <th>Rooms</th>
                                                <th>Room Type</th>
                                                <th>No. of Guests</th>
                                            </tr>
                                        </thead>
                                        { !roomDetails.loading && hotelRoomInfo &&
                                            <tbody>
                                                { searchQuery.room_guests.map((room, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{'Room '+(index+1)}</td>
                                                            <td className="room-inclusion">
                                                                <span>{hotelRoomInfo[index].room_type_name+' '}</span>
                                                                {hotelRoomInfo[index].amenities.length !== 0 && <span className="">Incl: {hotelRoomInfo[index].amenities.join(',')}</span>}
                                                                {hotelRoomInfo[index].room_promotion && <span className="red-text">{hotelRoomInfo[index].room_promotion.replace(/\|/g, "")}</span>}
                                                            </td>
                                                            <td>{room.no_of_adults+' Adult(s)'} {room.no_of_child !== 0 && ','+room.no_of_child+'Child(s)'}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        }
                                        {!roomDetails.loading && hotelRoomInfoTripjack &&
                                            <tbody>
                                                {searchQuery.room_guests.map((room, index) => {
                                                    return (
                                                        hotelRoomInfoTripjack && hotelRoomInfoTripjack.map((item) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{'Room ' + (index + 1)}</td>
                                                                    <td className="room-inclusion">
                                                                        <span>{item && item.room_combination[index] && item.room_combination[index].room_type_name + ' '}</span>
                                                                        {item && item.room_combination && item.room_combination[index] && item.room_combination[index].inclusion && <span className="">Incl: {item && item.room_combination[index].inclusion}</span>}
                                                                    </td>
                                                                    <td>{room.no_of_adults + ' Adult(s)'} {room.no_of_child !== 0 && ',' + room.no_of_child + 'Child(s)'}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                })
                                                }
                                            </tbody>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}

/*--------- Room cancellation and charge details component ------------*/
class RoomCancellationAndChargeDetailComponent extends React.Component { 
    render(){
        let { searchQuery, roomDetails,cancelPolicy } = this.props;
        let HotelRoomDetails = roomDetails.response && roomDetails.response.hotel_room_details;
        let HotelRoomDetail = roomDetails && roomDetails.response && roomDetails.response.hotel_rooms_details;
        let supplier_type = roomDetails && roomDetails.response && roomDetails.response.supplier_type;
        let checkOutDate = this.props.searchQuery && this.props.searchQuery.check_out_date.split('/')[2] + '-' + this.props.searchQuery.check_out_date.split('/')[1] + '-' + this.props.searchQuery.check_out_date.split('/')[0];
        return(
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Cancellation and Charge</span></div>
                    </div>
                    {
                        supplier_type !== 'tripjack' &&
                        <div className="review-step-deatil">
                            {!roomDetails.loading && HotelRoomDetails && searchQuery.room_guests.map((room, index) => {
                                let cancellationPolicies = HotelRoomDetails[index].cancellation_policies;
                                return (
                                    <div className="step-deatil-outer" key={index}>
                                        <div className="flight-information">
                                            <div className="inner-fl-info">
                                                <div className="pull-left"><span>{'Room ' + (index + 1)} : {HotelRoomDetails[index].room_type_name}</span></div>
                                                <table className="fl-book-info">
                                                    <thead>
                                                        <tr>
                                                            <th>Cancelled on or After</th>
                                                            <th>Cancelled on or Before</th>
                                                            <th>Cancellation Charges</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cancellationPolicies.map((Policies, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')},{" "}{moment(Policies.from_date).format("hh:mm A")}</td>
                                                                    <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')},{" "}{moment(Policies.to_date).format("hh:mm A")}</td>
                                                                    {Policies.charge_type === 1 && <td>Rs. {Policies.charge}</td>}
                                                                    {Policies.charge_type === 2 && <td>{Policies.charge}%</td>}
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                            <div className="cancellation-note"><span className="red-text">Note: Early check out will attract full cancellation charges unless otherwise specified.</span></div>
                        </div>
                    }
                    {
                        supplier_type === 'tripjack' &&
                        <div className="review-step-deatil">
                            {
                                supplier_type === 'tripjack' && !cancelPolicy.loading &&
                                HotelRoomDetail && searchQuery.room_guests.map((room, index) => {
                                    return (
                                        HotelRoomDetail[index] && HotelRoomDetail[index].room_combination.map((itee, ind) => {
                                            return (
                                                <div className="step-deatil-outer" key={index}>
                                                    <div className="flight-information">
                                                        <div className="inner-fl-info">
                                                            <div className="pull-left"><span>{'Room ' + (ind + 1)} : {itee && itee.room_type_name}</span></div>
                                                            <table className="fl-book-info">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Cancelled on or After</th>
                                                                        <th>Cancelled on or Before</th>
                                                                        <th>Cancellation Charges</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        cancelPolicy && cancelPolicy.response && cancelPolicy.response.cancellation_policies.map((Policies, cancellationKey) => {
                                                                            return (
                                                                                <tr key={cancellationKey}>
                                                                                    <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')},{" "}{moment(Policies.from_date).format("hh:mm A")}</td>
                                                                                    <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')},{" "}{moment(Policies.to_date).format("hh:mm A")}</td>
                                                                                    {Policies.charge_type === 1 && <td>Rs. {Policies.charge}</td>}
                                                                                    {Policies.charge_type === 2 && <td>{Policies.charge}%</td>}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )

                                })
                            }
                            <div className="cancellation-note"><span className="red-text">Note: Early check out will attract full cancellation charges unless otherwise specified.</span></div>
                        </div>
                    }
                </div>

            );
    }
}

/*--------- Hotel norm details component ------------*/
class HotelNormDetailComponent extends React.Component { 
    render(){
        let { roomDetails, renderHotelNorms } = this.props;
        let hotelNorms = roomDetails.response && roomDetails.response.supplier_type !== 'tripjack' && roomDetails.response.hotel_policy_detail.split('|');
        let hotelNorm = roomDetails && roomDetails.response && roomDetails.response.supplier_type === 'tripjack' && roomDetails.response.norms
        return(
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Norms</span></div>
                    </div>
                    <div className="review-step-deatil">
                        <div className="step-deatil-outer">
                            <div className="fare-rule-info">
                                {
                                    roomDetails && roomDetails.response && roomDetails.response.supplier_type !== 'tripjack' &&
                                    renderHotelNorms(hotelNorms)
                                }
                                {
                                    roomDetails && roomDetails.response && roomDetails.response.supplier_type === 'tripjack' &&
                                    renderHotelNorms(hotelNorm)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}

/*------------- hotel terms and condition and under cancel component ------------*/
class HotelTermsConditionComponent extends React.Component {
    redirectToTermsAndCondition() {
        window.open("/terms-and-conditions", "_blank");
    }
    render() {
        let {handleBlur, values, errors, touched} = this.props;
        return (
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Arrival Details</span></div>
                    </div>
                    <div className="review-step-deatil">
                        <div className="step-deatil-outer">
                            <div className="adult-form">
                                <div className="block form-card">
                                    <div className="adult-form-outer">
                                        <div className="form-row">
                                            <div className="col-sm-12">
                                                <div className="form-group check-detail">
                                                    <div className="control-group">
                                                        <div className="check-term">
                                                            <small className={errors['terms_conditions'] && touched['terms_conditions']?'checkbox error-shadow':'checkbox'}>
                                                            <Field id="1" name="terms_conditions" type="checkbox"  defaultChecked={values.terms_conditions === true ? 'checked' : ''}  onBlur={handleBlur}/>
                                                            <label></label></small>
                                                            <label htmlFor="1">I agree with<a href="javascript:void(0);" onClick={this.redirectToTermsAndCondition}>Terms &amp; Conditions</a></label>
                                                        </div>
                                                        <div className="check-term">
                                                            <small className={errors['under_cancel'] && touched['under_cancel']?'checkbox error-shadow':'checkbox'}>
                                                            <Field id="2" name="under_cancel" type="checkbox" defaultChecked={values.under_cancel === true ? 'checked' : ''}  onBlur={handleBlur}/>
                                                            <label></label></small>
                                                            <label htmlFor="2">The booking is under cancellation and any cancellation made on this booking will attract the charges as mentioned.</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
	}
}

/*--------- Amenities details component ------------*/
class AmenitiesDetailComponent extends React.Component { 
    render(){
        let { searchQuery, roomDetails } = this.props;
        let hotelRoomInfo = roomDetails && roomDetails.response && roomDetails.response.hotel_room_details ? roomDetails.response.hotel_room_details : roomDetails && roomDetails.response && roomDetails.response.hotel_rooms_details;
        return(
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Amenities</span></div>
                    </div>
                    <div className="review-step-deatil"><div className="step-deatil-outer">
                        <div className="flight-information">
                            { !roomDetails.loading ?
                            <div className="inner-fl-info">
                                <table className="fl-book-info">
                                    <thead></thead>
                                    { !roomDetails.loading && hotelRoomInfo &&
                                        <tbody>
                                            {roomDetails && roomDetails.response && roomDetails.response.supplier_type !== 'tripjack' && searchQuery.room_guests.map((room, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{'Room '+(index+1)}</td>
                                                        <td>
                                                            {hotelRoomInfo[index].amenities.length !== 0?<span>{hotelRoomInfo[index].amenities.join(',')}</span>:'-'}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            {
                                                roomDetails && roomDetails.response && roomDetails.response.supplier_type === 'tripjack' && searchQuery.room_guests.map((room, index) => {
                                                    return (
                                                        hotelRoomInfo[index] && hotelRoomInfo[index].room_combination.map((itemRoom, indexRoom) => {
                                                            return (
                                                                <tr key={indexRoom}>
                                                                    <td>{'Room ' + (indexRoom + 1)}</td>
                                                                    <td>
                                                                        <span>{itemRoom && itemRoom.amenities}</span> : '-'
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                })
                                            }
                                        </tbody>
                                    }
                                </table>
                            </div>
                            :
                                <div className="loader relative-loader" style={{ display:'block'}}>
                                    <div className="loader-content">
                                        <div className="loader-ring blue-ring"> <span></span> </div>
                                    </div>
                                </div>
                            }
                        </div>
                        </div>
                    </div>
                </div>
            );
    }
}

/*--------- Booking confirmation component ------------*/
class BookingConfirmationComponent extends React.Component { 
    
    render(){
        let { showConfirmationPopup, handleConfirmationPopup , blockHotelDetails, searchQuery,cancelPolicy} = this.props;
        let HotelRoomDetails = blockHotelDetails.response && blockHotelDetails.response.hotel_room_details;
        let HotelRoomDetail = blockHotelDetails && blockHotelDetails.response && blockHotelDetails.response.hotel_rooms_details;
        let supplier_type = blockHotelDetails && blockHotelDetails.response && blockHotelDetails.response.supplier_type;
        let checkOutDate = searchQuery && searchQuery.check_out_date.split('/')[2] + '-' + searchQuery.check_out_date.split('/')[1] + '-' + searchQuery.check_out_date.split('/')[0];
        return(
            <div className={`popup-crad hotel-book-confirmation ${showConfirmationPopup ? 'show' : ''}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>This will Hold/Confirm the booking. Are you sure you want to Hold/Confirm booking?</h2>
                            <div className="popup-close"><span className="icon icon-cross" onClick={()=> handleConfirmationPopup(null) }/></div>
                        </div>							
                        <div className="popup-body popup-scorll">
                            <div className="inner-confirmation">
                                {
                                    supplier_type !== 'tripjack' &&
                                    <div className="review-step-deatil">
                                        {HotelRoomDetails && searchQuery.room_guests.map((room, index) => {
                                            let cancellationPolicies = HotelRoomDetails[index].cancellation_policies;
                                            return (
                                                <div className="step-deatil-outer" key={index}>
                                                    <div className="flight-information">
                                                        <div className="inner-fl-info">
                                                            <div className="pull-left"><span>{'Room ' + (index + 1)} : {HotelRoomDetails[index].room_type_name}</span></div>
                                                            <table className="fl-book-info">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Cancelled on or After</th>
                                                                        <th>Cancelled on or Before</th>
                                                                        <th>Cancellation Charges</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {cancellationPolicies.map((Policies, key) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')}</td>
                                                                                <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')}</td>
                                                                                <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')},{" "}{moment(Policies.from_date).format("hh:mm A")}</td>
                                                                                <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')},{" "}{moment(Policies.to_date).format("hh:mm A")}</td>
                                                                                {Policies.charge_type === 1 && <td>Rs. {Policies.charge}</td>}
                                                                                {Policies.charge_type === 2 && <td>{Policies.charge}%</td>}
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        <div className="cancellation-note"><span className="red-text">Note: Early check out will attract full cancellation charges unless otherwise specified.</span></div>
                                    </div>
                                }
                                {
                                    supplier_type === 'tripjack' &&
                                    <div className="review-step-deatil">
                                        {HotelRoomDetail && searchQuery.room_guests.map((room, index) => {
                                            //let cancellationPolicies = HotelRoomDetail[index].cancellation_policies;
                                            return (
                                                HotelRoomDetail[index] && HotelRoomDetail[index].room_combination.map((itee, ind) => {
                                                    return (
                                                        <div className="step-deatil-outer" key={index}>
                                                            <div className="flight-information">
                                                                <div className="inner-fl-info">
                                                                    <div className="pull-left"><span>{'Room ' + (ind + 1)} : {itee && itee.room_type_name}</span></div>
                                                                    <table className="fl-book-info">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Cancelled on or After</th>
                                                                                <th>Cancelled on or Before</th>
                                                                                <th>Cancellation Charges</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                cancelPolicy && cancelPolicy.response && cancelPolicy.response.cancellation_policies.map((Policies, cancellationKey) => {
                                                                                    return (
                                                                                        <tr key={cancellationKey}>
                                                                                            <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')}</td>
                                                                                            <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')}</td>
                                                                                            <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')},{" "}{moment(Policies.from_date).format("hh:mm A")}</td>
                                                                                            <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')},{" "}{moment(Policies.to_date).format("hh:mm A")}</td>
                                                                                            {Policies.charge_type === 1 && <td>Rs. {Policies.charge}</td>}
                                                                                            {Policies.charge_type === 2 && <td>{Policies.charge}%</td>}
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                        })
                                        }
                                        <div className="cancellation-note"><span className="red-text">Note: Early check out will attract full cancellation charges unless otherwise specified.</span></div>
                                    </div>
                                }
                                <div className="popup-footer">
                                    <button className="btn btn-orange" onClick={()=> handleConfirmationPopup('submit') }>Yes</button>
                                    <button className="btn btn-grey" onClick={()=> handleConfirmationPopup(null) }>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay"/>
            </div>
        );
    }
}

/*--------- Guest arrivals details component ------------*/
class GuestArrivalDetailComponent extends React.Component { 
    render(){
        let { values } = this.props;
        return(
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Arrival Detalis</span></div>
                    </div>
                    <div className="review-step-deatil">
                        <div className="step-deatil-outer">
                            <div className="flight-information">
                                <div className="inner-fl-info">
                                    <table className="fl-book-info hotel-info">
                                        <thead></thead>
                                        <tbody>
                                            <tr>
                                                <td><span className="text-bold"> Transport Type : </span>
                                                    {values['transport_no']?values['transport_no']+' ':values['flight_no']+' '} 
                                                    {moment(values['check_in_date']).format('YYYY-MM-DD')+' '+values['arrival_hour']+':'+values['arrival_minute']+':00'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}

/*---------Is Coroporat Booking ------------*/
class IsCorporateComponent extends React.Component { 
    render(){
        let { values } = this.props;
        return(
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Is corporate </span></div>
                    </div>
                    <div className="review-step-deatil">
                        <div className="step-deatil-outer">
                            <div className="flight-information">
                                <div className="inner-fl-info">
                                    <table className="fl-book-info hotel-info">
                                        <thead></thead>
                                        <tbody>
                                            <tr>
                                                <td><span className="text-bold">Is Corporate : </span>
                                                {values['is_corporate']? "Yes": "No"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}
export default HotelReviewBookingComponent