import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { dateFormat, jsUcfirst, getCurrentDate} from '../../../shared/commonHelper';
import HotelFareSummaryComponent from './HotelFareSummaryComponent';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar'

class BookingConfirmComponent extends React.Component {
    constructor(props) {
		super(props)
		this.state = {
			showConfirmationPopup : false,
            showModal:'show'
		}
    }
    hidePopupModal= () =>{
        this.setState({showModal:'hide'})
    }

    // ----------Booking Confirmation popup-------------
    handleConfirmationPopup = (action)=> {
        if(action === false)
        {
            this.setState({showConfirmationPopup : !this.state.showConfirmationPopup});
        }
        else
        {
            if(this.props.location.state.response.supplier_type !== 'tripjack'){
                this.props.generateVoucherAfterHold(this.props.location.state.response.booking_id,this.props.location.state.response.tbo_booking_id);
            }
            if(this.props.location.state.response.supplier_type === 'tripjack'){
                this.props.generateTripjackVoucherAfterHold(this.props.location.state.response, this.props.searchQuery);
            }
            this.setState({showConfirmationPopup : !this.state.showConfirmationPopup});
        }
    }

     // Hotel Norms Information
     renderHotelNorms(hotelNorms) {
        return (
            <PerfectScrollbar>
            <div className="inner-fare-block hotel-norms">
                <ul className="hotel-norms-list">
                {   hotelNorms && hotelNorms.map((hotelNorm, key) => {
                    let norm = hotelNorm.replace(/\|\\r\n/g,'<br/>');
                    return  (
                            norm && norm !== '' &&
                            <li key={key}>
                                <span dangerouslySetInnerHTML={{ __html: norm }} ></span>
                            </li>
                        )
                    })
                }
                </ul>
            </div>
            </PerfectScrollbar>
        );
    }

	render() {
        let self = this;
        let roomDetails = this.props.location.state;
        let supplierType = roomDetails && roomDetails.response && roomDetails.response.supplier_type;
        let bookingStatus = roomDetails && roomDetails.response && roomDetails.response.booking_status_str;
        let responseCheck = this.props.hotelBookedDetails && this.props.hotelBookedDetails.response
        let booking_status_booked = this.props.hotelBookedDetails && this.props.hotelBookedDetails.response && this.props.hotelBookedDetails.response.booking_status_str
        return (
                <section className={`mid-wrapper hotel-book-container ${this.props.type?'booking-confirmation-container':''}`}>
                    <div className="container">
                        <div className="review-booking-wrap">
                            <div className="left-side">
                                <div className="fare-summery">
                                    <React.Fragment>
                                        <HotelFareSummaryComponent 
                                            loading={false} 
                                            currencyCode={'INR'} 
                                            roomData={roomDetails && roomDetails.response && roomDetails.response.hotel_room_details} 
                                            fareDetails={roomDetails && roomDetails.response && roomDetails.response.total_price} 
                                            noOfRooms={this.props.searchQuery && this.props.searchQuery.no_of_rooms} 
                                            booking_value={this.props.location.state.booking_value}
                                            searchQuery={this.props.searchQuery}
                                        />
                                    </React.Fragment>
                                    <div className="apply-discount">
                                        <div className="discount-outer"> <i className="scissors-icon"><img src="assets/images/scissors.svg" alt=""/></i>
                                            <h3>Apply Discount</h3>
                                            <p>If you have Have a discount/promo code ? please enter for discount</p>
                                            <div className="input-group add-input-group">
                                            <input className="form-control custom-filed" type="text" placeholder="Enter Coupon Code" />
                                            <button className="input-btn">Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-side">
                                <div className="review-steps-wrap">
                                    <div className="tabs-content-wrap">
                                        <div className="step-content" >
                                            <h3 className="step-title"><span>Booking Confirmation</span> </h3>
                                            <div className="review-filght-step">
                                                {/*------------------- Hotel booked confirmation details --------------------*/}
                                                {<BookingConfirmationDetailsComponent booking_status_booked={booking_status_booked} roomDetails={roomDetails} booking_value={this.props.location.state.booking_value} cancelPolicy={this.props.location.state.cancelPolicy} companyName={this.props.accountResponse && this.props.accountResponse.myAccountResponse.response &&  this.props.accountResponse.myAccountResponse.response.company_name}/>}

                                                {/*------------------- Number of guest details room wise --------------------*/}
                                                {<GuestRoomDetailsComponent roomDetails={roomDetails} booking_value={this.props.location.state.booking_value} searchQuery={this.props.searchQuery}/>}

                                                {/*------------------- Passenger details --------------------*/}
                                                <PassengerDetailComponent {...self.props} roomDetails={roomDetails} booking_value={this.props.location.state.booking_value} />

                                                {/*------------------- Room cancellation and charge details --------------------*/}
                                                {<GuestArrivalDetailComponent roomDetails={roomDetails} booking_value={this.props.location.state.booking_value} />}
                                                                
                                                {/*------------------- Room cancellation and charge details --------------------*/}
                                                <RoomCancellationAndChargeDetailComponent roomDetails={roomDetails} booking_value={this.props.location.state.booking_value} cancelPolicy={this.props.location.state.cancelPolicy}/>

                                                {/*------------------- Amenities detail --------------------*/}
                                                <AmenitiesDetailComponent roomDetails={roomDetails} booking_value={this.props.location.state.booking_value} />

                                                {/*------------------- Hotel norm details -----------------*/}
                                                <HotelNormDetailComponent roomDetails={roomDetails} renderHotelNorms={this.renderHotelNorms} booking_value={this.props.location.state.booking_value} blockHotelDetails={this.props.location.state.blockHotelDetails}/>
                                                {/*--------------------Hotel Generate Voucher Button --------*/}
                                                {
                                                    !(roomDetails && roomDetails.response && roomDetails.response.voucher_status) && supplierType !== 'tripjack' &&
                                                    <div className="btn-block text-right">
                                                        <button type="button" className={`btn btn-primary`}
                                                        onClick={() => this.setState({showConfirmationPopup : !this.state.showConfirmationPopup})} >
                                                        Generate Voucher
                                                            {
                                                                //this.props.voucherDetails.loading && <i className="ht-loader"><img src="assets/images/btn-search_hotel.gif" alt="" /></i>
                                                            }        
                                                        </button>
                                                    </div>
                                                }
                                                {
                                                    !(roomDetails && roomDetails.response && roomDetails.response.voucher_status) && supplierType === 'tripjack' && (responseCheck === null ?  bookingStatus === 'Hold' : booking_status_booked === 'Hold')  &&
                                                    <div className="btn-block text-right">
                                                        <button type="button" className={`btn btn-primary`}
                                                        onClick={() => this.setState({showConfirmationPopup : !this.state.showConfirmationPopup})} >
                                                        Generate Voucher
                                                            {
                                                                //this.props.voucherDetails.loading && <i className="ht-loader"><img src="assets/images/btn-search_hotel.gif" alt="" /></i>
                                                            }        
                                                        </button>
                                                    </div>
                                                }
                                                {
                                                    this.props.hotelBookedDetails.response && this.props.hotelBookedDetails.response.booking_status_str !== "16" && supplierType === 'tripjack' ? "" :  this.props.hotelBookedDetails && roomDetails && roomDetails.response && roomDetails.response.voucher_status &&
                                                    <div className="btn-block text-right">
                                                        <button type="button" className={`btn btn-primary`}
                                                            onClick={() => {
                                                                let printHotelInvoiceData = {
                                                                    "booking_id" : roomDetails.response.booking_id
                                                                }
                                                                localStorage.setItem("print-hotel-invoice", JSON.stringify(printHotelInvoiceData));
                                                                window.open('/print-hotel-invoice', "_blank");
                                                            }}>
                                                        Print Invoice
                                                                 
                                                        </button>

                                                        <button type="button" className={`btn btn-primary`}
                                                            onClick={() => {
                                                                let printInvoiceData = {
                                                                    "booking_id" : roomDetails.response.booking_id
                                                                }
                                                                localStorage.setItem("print-hotel-voucher-data", JSON.stringify(printInvoiceData));
                                                                window.open('/hotel-voucher', "_blank");
                                                            }
                                                            }
                                                            >
                                                            Print Voucher
                                                                 
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.voucherDetails.loading && supplierType !== 'tripjack' &&
                        <div className="loader-hotel">
                            <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/white-hotel-loader.gif" alt="loader" /></div>
                            <div className="overlay-loader show"></div>
                        </div>
                    }
                    {
                        this.props.voucherDetails.loading === true && supplierType === 'tripjack' &&
                        <div className="loader-hotel">
                            <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/white-hotel-loader.gif" alt="loader" /></div>
                            <div className="overlay-loader show"></div>
                        </div>
                    }
                    {
                        this.props.hotelBookedDetails.loading === true && supplierType === 'tripjack' &&
                        <div className="loader-hotel">
                            <div className="loader-mid"><span>Your booking is in progress please do not refresh or press back button</span><img src="assets/images/white-hotel-loader.gif" alt="loader" /></div>
                            <div className="overlay-loader show"></div>
                        </div>
                    }
                    <BookingConfirmationPopupComponent {...this.props} {...this.state} handleConfirmationPopup={this.handleConfirmationPopup} />
                    {
                        booking_status_booked === "Pending" &&
                    <PendingBookingPopup  showModal={this.state.showModal} hidePopupModal={this.hidePopupModal}/>

                    }
                    
                </section>
                )
	}
}

/*--------- Hotel confirmation details component ------------*/
class BookingConfirmationDetailsComponent extends React.Component {
    constructor(props) {
		super(props)
    }
    render(){
        let { roomDetails,cancelPolicy,companyName, booking_status_booked } = this.props;
        let hotelInfo = roomDetails && roomDetails.response;
        let cancelInfo = cancelPolicy && cancelPolicy.response
        let currentDate = moment(moment(getCurrentDate())).format('YYYY-MM-DD') + 'T00:00:00';
        let supplierTpe = roomDetails && roomDetails.response && roomDetails.response.supplier_type
        let cancellationDateTripjack=supplierTpe === 'tripjack' && cancelPolicy && cancelPolicy.response && cancelPolicy.response.cancellation_policies && cancelPolicy.response.cancellation_policies.length > 0 && 
        cancelPolicy.response.cancellation_policies.filter((date)=>{
           return date.charge === 0;
        });
        if(cancellationDateTripjack && cancellationDateTripjack.length > 0){
            if(cancellationDateTripjack && cancellationDateTripjack.length > 1){
                cancellationDateTripjack = cancellationDateTripjack.reduce((max, current) => {
                  return  new Date(current.to_date) > new Date(max.to_date) ? current : max;
               });
           }else{
                cancellationDateTripjack=cancellationDateTripjack[0]
           }
        }
        //let booking_status_booked = this.props.roomDetails&&this.props.roomDetails.response&&this.props.roomDetails.response.booking_status_str
        return(
                <div className={(hotelInfo && hotelInfo.voucher_status === false) || (supplierTpe==="tripjack" && booking_status_booked !== 'Successful') ? "review-step hold-booking-confirm" : 'review-step'}>
                    <div className={(hotelInfo && hotelInfo.voucher_status === true) || (hotelInfo && hotelInfo.voucher_status === 1) || (supplierTpe==="tripjack" && booking_status_booked === 'Successful') ? "review-step-deatil select-booking" : 'review-step-deatil'}>
                        <div className="step-deatil-outer">
                            <div className="flight-details-block">
                                <div className="flight-col">
                                    <div className="flight-head">
                                        <div className="cols">
                                        {
                                            hotelInfo && hotelInfo.voucher_status === true &&
                                            <span className="confirm-icon"><i className="icon icon-checked"></i></span>
                                        }
                                        <span>{this.props.accountResponse &&
                                        this.props.accountResponse.myAccountResponse.response && 
                                        this.props.accountResponse.myAccountResponse.response.company_name || companyName}</span>
                                        </div>
                                        <div className="cols">
                                            <div className="voucher-info">
                                            { supplierTpe === "tripjack" && <h5>{booking_status_booked}</h5> }
                                            { supplierTpe !== "tripjack" && <h5>{(hotelInfo && hotelInfo.voucher_status === true) ? 'Vouchered':'Confirmed'}</h5> }
                                              { ((cancellationDateTripjack && cancellationDateTripjack.length > 0) || (hotelInfo && hotelInfo.last_cancellation_date)) && <div className="last-cancel-date">
                                                    <span className="date-text">Last Cancellation Date</span>
                                                {
                                                    supplierTpe === 'tripjack' ?
                                                        <span className="last-date">{dateFormat((cancellationDateTripjack && cancellationDateTripjack.to_date), 'DD MMM YYYY')}</span> :
                                                        <span className="last-date">{dateFormat(hotelInfo && hotelInfo.last_cancellation_date, 'DD MMM YYYY')}</span>
                                                }
                                                </div>}
                                            </div>
                                        </div>
                                        {
                                            supplierTpe !== 'tripjack' ?
                                                <div className="cols"><span className="pnr-no">Confirmation No <samp>{hotelInfo && hotelInfo.confirmation_no}</samp></span></div>
                                                : <div className="cols">{booking_status_booked === "confirm" ? <span className="pnr-no">Confirmation No <samp>{hotelInfo && hotelInfo.hotel_confirmation_no}</samp></span>:
                                                <span className="pnr-no"><samp>{booking_status_booked}</samp></span> }
                                            </div>
                                        }
                                            
                                    </div>
                                    <div className="flight-list">
                                        <div className="cols">
                                            <ul>
                                                {/*hotelInfo.voucher_status && <li><span className="text-bold">Vouchered</span></li>*/}
                                                <li>Check In Date: <span className="text-bold">{dateFormat((hotelInfo && hotelInfo.check_in_date),'DD MMM YYYY')}</span> </li>
                                                <li>Check Out Date: <span className="text-bold">{dateFormat((hotelInfo && hotelInfo.check_out_date),'DD MMM YYYY')}</span> </li>
                                            </ul>
                                        </div>
                                        <div className="cols">
                                            <ul>
                                                {/*<li>{hotelInfo.voucher_status === false && <span className="text-bold">Hold</span> }
                                                    {hotelInfo.voucher_status === true && <span className="text-bold">Confirmed</span>}
                                                </li>*/}
                                                <li>Booked on: <span className="text-bold">{dateFormat(currentDate,'DD MMM YYYY')}</span></li>
                                                <li>Reference No: <span className="text-bold">{supplierTpe !== 'tripjack' ? (hotelInfo && hotelInfo.booking_ref_no) : (hotelInfo && hotelInfo.reference_number)}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}

/*-------------- Passenger details component -----------------*/
class PassengerDetailComponent extends React.Component {
    render() {
        let { roomDetails } = this.props
        let hotelRoomInfo = roomDetails.response && roomDetails.response.hotel_room_details;
        let rows = [];

		hotelRoomInfo && hotelRoomInfo.map((room, roomIndex) => {
            if(room && room.hotel_passengers){
                room && room.hotel_passengers && room.hotel_passengers.map((guest, guestIndex) => {
                    rows.push(
    
                        <div className="cols" key={guestIndex+''+roomIndex}>
                            <div className="adult-title"><span>Room {roomIndex+1}</span><small>({guest.pax_type === 1?'Adult ':'Child '}{guestIndex+1})</small></div>
                            <ul className="psg-list">
                                <li>
                                    <span>Name:</span>
                                    <small>{guest.title+' '+jsUcfirst(guest.first_name)+' '+jsUcfirst(guest.last_name)}</small>
                                </li>
    
                                {guest.pan && guest.pax_type === 1 &&
                                    <li>
                                        <span>PAN:</span>
                                        <small>{guest.pan}</small>
                                    </li>
                                }
    
                                {guest.passport_no && guest.pax_type === 1 &&
                                <li>
                                    <span>Passport No.:</span>
                                    <small>{guest.passport_no}</small>
                                </li>
                                }
    
                                {guest.passport_issue_date && guest.pax_type === 1 &&
                                <li>
                                    <span>Passport Issue Date: </span>
                                    <small>{guest.passport_issue_date.split('T')[0]}</small>
                                </li>
                                }
    
                                {guest.passport_exp_date && guest.pax_type === 1 &&
                                <li>
                                    <span>Passport Expiry Date: </span>
                                    <small>{guest.passport_exp_date.split('T')[0]}</small>
                                </li>
                                }
    
                                {guest.phone_no && guest.pax_type === 1 &&
                                    <li>
                                        <span>Contact No.:</span>
                                        <small>{guest.phone_no}</small>
                                    </li>
                                }
    
                                { guest.pax_type === 2 &&
                                    <li>
                                        <span>Age:</span>
                                        <small>{guest.age }</small>
                                    </li>
                                }
                            </ul>
                        </div>
                    );
                    return rows;
                });
            }
            if(room && room.hotel_passenger){
                room && room.hotel_passenger && room.hotel_passenger.map((guest, guestIndex) => {
                    rows.push(
    
                        <div className="cols" key={guestIndex+''+roomIndex}>
                            <div className="adult-title"><span>Room {roomIndex+1}</span><small>({guest.pax_type === 1?'Adult ':'Child '}{guestIndex+1})</small></div>
                            <ul className="psg-list">
                                <li>
                                    <span>Name:</span>
                                    <small>{guest.title+' '+jsUcfirst(guest.first_name)+' '+jsUcfirst(guest.last_name)}</small>
                                </li>
    
                                {guest.pan && guest.pax_type === 1 &&
                                    <li>
                                        <span>PAN:</span>
                                        <small>{guest.pan}</small>
                                    </li>
                                }
    
                                {guest.passport_no && guest.pax_type === 1 &&
                                <li>
                                    <span>Passport No.:</span>
                                    <small>{guest.passport_no}</small>
                                </li>
                                }
    
                                {guest.passport_issue_date && guest.pax_type === 1 &&
                                <li>
                                    <span>Passport Issue Date: </span>
                                    <small>{guest.passport_issue_date.split('T')[0]}</small>
                                </li>
                                }
    
                                {guest.passport_exp_date && guest.pax_type === 1 &&
                                <li>
                                    <span>Passport Expiry Date: </span>
                                    <small>{guest.passport_exp_date.split('T')[0]}</small>
                                </li>
                                }
    
                                {guest.phone_no && guest.pax_type === 1 &&
                                    <li>
                                        <span>Contact No.:</span>
                                        <small>{guest.phone_no}</small>
                                    </li>
                                }
    
                                { guest.pax_type === 2 &&
                                    <li>
                                        <span>Age:</span>
                                        <small>{guest.age }</small>
                                    </li>
                                }
                            </ul>
                        </div>
                    );
                    return rows;
                });
            }
            
            return rows;
        });
    return (
        <div className="review-step">
            <div className="review-step-title">
                <div className="cols pull-left"> <span>Guest Details</span> </div>
                <div className="cols pull-right">  </div>
            </div>
            <div className= "review-step-deatil">
                <div className="step-deatil-outer">
                    <div className="passenger-information">
                        <div className="passenger-fl-info">
                            {rows}
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    );
	}
}

/*------------ Guest room details component --------------*/
class GuestRoomDetailsComponent extends React.Component { 
    render(){
        let { roomDetails,booking_value ,searchQuery} = this.props;
        let hotelRoomInfo = roomDetails.response && roomDetails.response.hotel_room_details;
        let hotelInfo = roomDetails.response && roomDetails.response;
        let ratingLi = [];
		for(let i=0; i<(hotelInfo && hotelInfo.star_rating); i++) {
			ratingLi.push(<li key={i}><i className="icon icon-star-full" /></li>)
        }
        let supplierType = roomDetails.response && roomDetails.response.supplier_type;
        return(
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Booking Details</span></div>
                    </div>
                    <div className="review-step-deatil">
                        <div className="step-deatil-outer">
                            <div className="flight-information">
                                <div className="inner-fl-info">
                                    <table className="fl-book-info hotel-info">
                                        <thead></thead>
                                        <tbody>
                                            <tr><td><span className="text-bold">Hotel Name : </span> {hotelInfo && hotelInfo.hotel_name} <ul className="rating">{ratingLi}</ul></td></tr>
                                            <tr><td><span className="text-bold">Hotel Address : </span> {hotelInfo && hotelInfo.address_line1} {(hotelInfo && hotelInfo.address_line2) && ', '+(hotelInfo && hotelInfo.address_line2)}</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="step-deatil-outer">
                            <div className="flight-information">
                                <div className="inner-fl-info">
                                    <table className="fl-book-info">
                                        <thead>
                                            <tr>
                                                <th>Rooms</th>
                                                <th>Room Type</th>
                                                <th>No. of Guests</th>
                                            </tr>
                                        </thead>
                                            <tbody>
                                                { hotelRoomInfo && hotelRoomInfo.map((room, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{'Room '+(index+1)}</td>
                                                            {
                                                                supplierType === 'tripjack' &&
                                                                <td className="room-inclusion">
                                                                    <span>{room && room.room_type_name + ' '}</span>
                                                                    {/* {!booking_value && room && room.amenities && room.amenities.length !== 0 && <span className="">Incl: {room.amenities}</span>} */}
                                                                    {room && room.inclusion && room.inclusion.length !== 0 && <span className="">Incl: {room.inclusion}</span>}
                                                                    {!booking_value && room && room.room_promotion && <span className="red-text">{room.room_promotion}</span>}
                                                                </td>
                                                            }
                                                            {
                                                                supplierType !== 'tripjack' &&
                                                                <td className="room-inclusion">
                                                                    <span>{room && room.room_type_name + ' '}</span>
                                                                    {!booking_value && room && room.amenities && room.amenities.length !== 0 && <span className="">Incl: {room.amenities.join(',')}</span>}
                                                                    {booking_value && room && room.inclusions && room.inclusions.length !== 0 && <span className="">Incl: {room.inclusions.join(',')}</span>}
                                                                    {!booking_value && room && room.room_promotion && <span className="red-text">{room.room_promotion.replace(/\|/g, "")}</span>}
                                                                </td>
                                                            }
                                                            {
                                                                supplierType === 'tripjack' ?
                                                                    <td>{room.adult_count + ' Adult(s)'} {room.child_count !== 0 && ',' + room.child_count + 'Child(s)'}</td>
                                                                    :
                                                                    <td>{room.adult_count + ' Adult(s)'} {room.child_count !== 0 && ',' + room.child_count + 'Child(s)'}</td>
                                                            }
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}

/*--------- Room cancellation and charge details component ------------*/
class RoomCancellationAndChargeDetailComponent extends React.Component { 
    render(){
        let { roomDetails,booking_value,cancelPolicy } = this.props;
        let HotelRoomDetails = roomDetails && roomDetails.response && roomDetails.response.hotel_room_details;
        let cancelInfo = cancelPolicy && cancelPolicy.response;
        let supplierType = roomDetails && roomDetails.response && roomDetails.response.supplier_type;
        let date = roomDetails && roomDetails.response;
        return(
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Cancellation and Charge</span></div>
                    </div>
                    <div className="review-step-deatil">
                    {  HotelRoomDetails && HotelRoomDetails.map((room, index) => {
                        let cancellationPolicies = '';
                        if(supplierType !== 'tripjack'){
                            if(booking_value){
                                cancellationPolicies = room.cancellation_policy.split('|');
                            }else{
                                cancellationPolicies = room.cancellation_policies;
                            }
                        }
                        if(supplierType === 'tripjack'){
                            if(booking_value){
                                cancellationPolicies = cancelInfo;
                            }else{
                                cancellationPolicies = cancelInfo;
                            }
                        }
                        return  (
                                <div className="step-deatil-outer" key={index}>
                                    <div className="flight-information">
                                        <div className="inner-fl-info">
                                            <div className="pull-left"><span>{'Room '+(index+1)} : {room.room_type_name}</span></div>
                                            {
                                                !booking_value && 
                                                    <table className="fl-book-info">
                                                        <thead>
                                                            <tr>
                                                                <th>Cancelled on or After</th>
                                                                <th>Cancelled on or Before</th>
                                                                <th>Cancellation Charges</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {  supplierType !== 'tripjack' &&  cancellationPolicies.map((Policies, key) => {
                                                            return  (
                                                                    <tr key={key}>
                                                                        <td>{dateFormat(Policies.from_date,'DD MMM YYYY')},{" "}{moment(Policies.from_date).format("hh:mm A")}</td>
                                                                        <td>{dateFormat(Policies.to_date,'DD MMM YYYY')},{" "}{moment(Policies.to_date).format("hh:mm A")}</td>
                                                                        {Policies.charge_type === 1 && <td>Rs. {Policies.charge}</td> }
                                                                        {Policies.charge_type === 2 && <td>{Policies.charge}%</td> }
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        {   supplierType === 'tripjack' && cancelPolicy && cancelPolicy.response && cancelPolicy.response.cancellation_policies.map((Policies, cancellationKey) => {
                                                                return (
                                                                    <tr key={cancellationKey}>
                                                                        <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')},{" "}{moment(Policies.from_date).format("hh:mm A")}</td>
                                                                        <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')},{" "}{moment(Policies.to_date).format("hh:mm A")}</td>
                                                                        {Policies.charge_type === 1 && <td>Rs. {Policies.charge}</td>}
                                                                        {Policies.charge_type === 2 && <td>{Policies.charge}%</td>}
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                            }
                                            {
                                                booking_value && 
                                                    <table className="fl-book-info">
                                                        <thead>
                                                            <tr>
                                                                <th>Cancellation Policy</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {  supplierType !== 'tripjack' && cancellationPolicies.map((Policies, key) => {
                                                            return  (
                                                                    <tr key={key}>
                                                                        <td>{Policies}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        {  supplierType === 'tripjack'  &&
                                                                    <tr>
                                                                        <td>{cancelInfo && cancelInfo.cancellation_policy}</td>
                                                                    </tr>
                                                        }
                                                        </tbody>
                                                    </table>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="cancellation-note"><span className="red-text">Note: Early check out will attract full cancellation charges unless otherwise specified.</span></div>
                    </div>
                </div>
            );
    }
}

/*--------- Hotel norm details component ------------*/
class HotelNormDetailComponent extends React.Component { 
    render(){
        let { roomDetails, renderHotelNorms,blockHotelDetails } = this.props;
        let hotelNorms = roomDetails.response && roomDetails.response.hotel_policy_detail && roomDetails.response.hotel_policy_detail.split('|');
        let norms = blockHotelDetails && blockHotelDetails.response && blockHotelDetails.response.norms;
        let supplierTpe = roomDetails.response && roomDetails.response.supplier_type
        return(
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Norms</span></div>
                    </div>
                    <div className="review-step-deatil">
                        <div className="step-deatil-outer">
                            <div className="fare-rule-info">
                                {
                                    supplierTpe !== 'tripjack' &&
                                   renderHotelNorms(hotelNorms)
                                }
                                {
                                    supplierTpe === 'tripjack' &&
                                   renderHotelNorms(norms)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}

/*--------- Amenities details component ------------*/
class AmenitiesDetailComponent extends React.Component { 
    render(){
        let { roomDetails,booking_value } = this.props;
        let hotelRoomInfo = roomDetails.response && roomDetails.response.hotel_room_details;
        let supplierType = roomDetails && roomDetails.response && roomDetails.response.supplier_type;
        return(
            <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Amenities</span></div>
                    </div>
                    <div className="review-step-deatil"><div className="step-deatil-outer">
                        <div className="flight-information">
                            <div className="inner-fl-info">
                                <table className="fl-book-info">
                                    <thead></thead>
                                        <tbody>
                                            { hotelRoomInfo && hotelRoomInfo.map((room, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{'Room '+(index+1)}</td>
                                                        {
                                                            supplierType === 'tripjack' &&
                                                            <td>
                                                                {!booking_value && (room.amenities.length !== 0 ? <span>{room.amenities}</span> : '-')}
                                                                {(booking_value && room.inclusion && room.inclusion.length !== 0) ? <span>{room.inclusion}</span> : '-'}
                                                            </td>
                                                        }
                                                        {
                                                            supplierType !== 'tripjack' &&
                                                            <td>
                                                                {!booking_value && (room.amenities.length !== 0 ? <span>{room.amenities.join(',')}</span> : '-')}
                                                                {(booking_value && room.inclusions && room.inclusion.length !== 0) ? <span>{room.inclusions.join(',')}</span> : '-'}
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            );
    }
}

/*--------- Booking confirmation component ------------*/
class BookingConfirmationPopupComponent extends React.Component { 
    render(){
        let { showConfirmationPopup, handleConfirmationPopup } = this.props;
        return (
            <div className={`popup-crad confirmation-popup ${showConfirmationPopup ? 'show' : ''}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>Booking Confirmation</h2>
                            <div className="popup-close"><span className="icon icon-cross" onClick={()=> handleConfirmationPopup(false) }/></div>
                        </div>							
                        <div className="popup-body popup-scorll">
                            <div className="inner-confirmation">
                                <p>Are you sure you want to continue?</p>
                                <div className="popup-footer">
                                    <button className="btn btn-orange" onClick={()=> handleConfirmationPopup(null) }>Yes</button>
                                    <button className="btn btn-grey" onClick={()=> handleConfirmationPopup(false) }>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay"></div>
            </div>
        );
    }
}

/*--------- Guest arrivals details component ------------*/
class GuestArrivalDetailComponent extends React.Component { 
    render(){
        let { roomDetails } = this.props;
        let arrivalDetails = roomDetails && roomDetails.response && roomDetails.response.arrival_detail;
        return(
            arrivalDetails?
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Arrival Detalis</span></div>
                    </div>
                    <div className="review-step-deatil">
                        <div className="step-deatil-outer">
                            <div className="flight-information">
                                <div className="inner-fl-info">
                                    <table className="fl-book-info hotel-info">
                                        <thead></thead>
                                        <tbody>
                                            <tr>
                                                <td><span className="text-bold"> Transport Type : </span>
                                                    {(arrivalDetails.arrival_transport_type === 0 ? 'Flight': 'Other Tranport')+' '+arrivalDetails.transport_info_id+' '+arrivalDetails.time} 
                                                </td>
                                                <td><span className="text-bold"> Transport Number : </span>
                                                    {arrivalDetails.transport_info_id+' '+arrivalDetails.time} 
                                                </td>
                                                <td><span className="text-bold"> Arrival Time : </span>
                                                    {dateFormat(arrivalDetails.time,'DD MMM YYYY, FDAY')} 
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                ''
            );
    }
}

const PendingBookingPopup = (props) => {

        const { showModal, hidePopupModal } = props;
            return (
                
                    <div className={`popup-crad confirm-popup ${showModal}`}>
                        <div className="popup-outer">
                            <div className="popup-content">
                                <div className="popup-head">
                                    <h2>Confirm to Process</h2>
                                </div>
                                <div className="popup-scorll">
                                    <div className="confirm-popup-block block">
                                        <div className="block">
                                            <div className="session-exp">
                                                <h5>Your booking is under process. Kindly do not book it again, For assistance call our helpdesk.</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block">
                                    <div className="confirm-box-button">
                                        <button className="btn btn-blue" onClick={() =>hidePopupModal()}>OK</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup-overlay"></div>
                    </div>
                )
                
    
}

export default BookingConfirmComponent