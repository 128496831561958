import React, { Component } from 'react';
import { dateFormat } from '../../../shared/commonHelper';
import { Formik } from 'formik';
import * as yup from "yup";

class FlightCancellationComponent extends Component {
    constructor(props) {
        super(props);
        let checkSegmentsVal = [];
        props.cancelPopupValue.segments.map((item, key) => {
            return item.map((itemInner, keyInner) => checkSegmentsVal[key + '-' + keyInner] = false);
        });

        let checkSegmentsValReturn = [];
        props.cancelPopupValue.segments.map((item, key) => {
            return item.map((itemInner, keyInner) => checkSegmentsValReturn[key + '-' + keyInner] = false);
        });

        let checkPaxVal = [];
        props.cancelPopupValue.passengers.map((item, key) => checkPaxVal[key] = false);

        let checkPaxValReturn = [];
        props.cancelPopupValue.passengers.map((item, key) => checkPaxValReturn[key] = false);

        this.state = {
            showCancelPopup: false,
            cancelledPnr: null,
            showConfirmationPopup: false,
            checkAllSectors: false,
            checkAllPax: false,
            checkPassengers: checkPaxVal,
            checkSegments: checkSegmentsVal,
            sector:'',


            // return type
            checkAllSectorsReturn: false,
            checkSegmentsReturn: checkSegmentsValReturn,
            checkAllPaxReturn: false,
            checkPassengersReturn: checkPaxValReturn,
            firstPassengerSection : '',
            secondPassengerSection : '',
            firstSection : '',
            secondSection : ''
        }

    }


    //***************************************Start Confimation Popup********************************************/
    handleConfirmationPopup = (event) => {
        this.setState({
            showConfirmationPopup: event
        })
    }
    //***************************************End Confimation Popup*********************************************/

    redirectToTermsAndCondition() {
        window.open("/cancellation-terms-and-conditions", "_blank");
    }

    cancelTicket = (values) => {
        this.setState({
            showConfirmationPopup: false
        })
        if (this.props.cancelPopupValue.booking_from === 'tripjack') {
            let data = '';
            // let origin = '';
            // let destination = '';
            let departureTime = '';
            if (values.cancel_type === 'full_cancellation') {
                data = {
                    "booking_id": this.props.cancelPopupValue.booking_id,
                    "tripjack_bookingId": this.props.cancelPopupValue.tripjack_booking_id ? this.props.cancelPopupValue.tripjack_booking_id : this.props.cancelPopupValue.transaction_no,
                    "request_type": "1",
                    "cancellation_type": "3",
                    "remarks": values.remark
                };
            }
            else if (values.cancel_type === 'cancel_ticket') {
                if (this.props.cancelPopupValue.booking_type === 'online') {
                    data = {
                        "booking_id": this.props.cancelPopupValue.booking_id,
                        "tripjack_bookingId": this.props.cancelPopupValue.transaction_no,
                        "request_type": "1",
                        "cancellation_type": "2",
                        "remarks": values.remark
                    };
                } else if (this.props.cancelPopupValue.booking_type === 'offline') {
                    data = {
                        "booking_id": this.props.cancelPopupValue.booking_id,
                        "request_type": "1",
                        "cancellation_type": "2",
                        "remarks": values.remark
                    };
                }
            }
            else {
                let sectors = [];
                let passengers = [];
                let pax_ticket = [];
                let passengers2 = [];
                // let item1 = '';
                let item2 = '';
                if (this.props.cancelPopupValue) {
                    for (let i = 0; i < (this.props.cancelPopupValue.passengers.length); i++) {
                        let origin = this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i][0] && this.props.cancelPopupValue.segments[i][0].origin_code;
                        let destination = this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i][this.props.cancelPopupValue.segments[i].length - 1] && this.props.cancelPopupValue.segments[i][this.props.cancelPopupValue.segments[i].length - 1].destination_code;
                        // let departureTime = this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i][0] && this.props.cancelPopupValue.segments[i][0].departure_time
                        if (this.props.cancelPopupValue.trip_type === 1) {
                            values && values.pax_ticket.map((paxItem) => {
                                if (paxItem.value === this.props.cancelPopupValue.passengers[i].pax_id) {
                                    passengers.push({
                                        "first_name": this.props.cancelPopupValue.passengers[i].first_name,
                                        "last_name": this.props.cancelPopupValue.passengers[i].last_name
                                    })
                                    pax_ticket.push(paxItem.value)
                                }
                                return null
                            })
                            values && values.sectors.filter((ele, ind) => ind === values.sectors.findIndex( elem => elem.time === ele.time))
                            .map((paxItem) => {
                              return  item2 = paxItem
                            })
                            return null;
                        }
                        if (this.props.cancelPopupValue.trip_type === 2) {
                            values && values.pax_ticket.map((paxItem) => {
                                if (paxItem.value === this.props.cancelPopupValue.passengers[i].pax_id) {
                                    if (this.props.cancelPopupValue.passengers[i].first_name !== undefined && this.props.cancelPopupValue.passengers[i].last_name !== undefined) {
                                        if (paxItem.firstPassengerSection === 1) {
                                            passengers.push({
                                                "first_name": this.props.cancelPopupValue.passengers[i].first_name,
                                                "last_name": this.props.cancelPopupValue.passengers[i].last_name
                                            })
                                            pax_ticket.push(paxItem.value)
                                        }
                                        if (paxItem.secondPassengerSection === 2) {
                                            passengers2.push({
                                                "first_name": this.props.cancelPopupValue.passengers[i].first_name,
                                                "last_name": this.props.cancelPopupValue.passengers[i].last_name
                                            })
                                            pax_ticket.push(paxItem.value)
                                        }
                                    }
                                }
                            })
                            if (this.props.cancelPopupValue.segments[0] && this.props.cancelPopupValue.segments[0].length === 1) {
                                if (origin !== destination) {
                                    values && values.sectors.filter((ele, ind) => ind === values.sectors.findIndex( elem => elem.time === ele.time)).map((paxItem) => {
                                        if (paxItem.firstSection === 1 && paxItem.origin === origin && paxItem.destination === destination) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers
                                            })
                                        }
                                        if (paxItem.secondSection === 2 && paxItem.origin === origin && paxItem.destination === destination) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers2
                                            })
                                        }
                                    })
                                } else {
                                    values && values.sectors.filter( (ele, ind) => ind === values.sectors.findIndex( elem => elem.time === ele.time)).map((paxItem) => {
                                        if (paxItem.firstSection === 1 && paxItem.origin === this.props.cancelPopupValue.segments[0].origin_code && paxItem.destination === this.props.cancelPopupValue.segments[0].destination_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers
                                            })
                                        }
                                        if (paxItem.secondSection === 2 && paxItem.origin === this.props.cancelPopupValue.segments[0].origin_code && paxItem.destination === this.props.cancelPopupValue.segments[0].destination_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers2
                                            })
                                        }
                                    })
                                }
                            }
                            if (this.props.cancelPopupValue.segments[0] && this.props.cancelPopupValue.segments[0].length === 2) {
                                for (let j = 0; j < (this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i].length); j++) {
                                    let item = this.props.cancelPopupValue.segments[i][j];
                                    values && values.sectors.filter( (ele, ind) => ind === values.sectors.findIndex( elem => elem.time === ele.time)).map((paxItem) => {
                                        if (paxItem.firstSection === 1 && paxItem.origin === item.origin_code && paxItem.destination === this.props.cancelPopupValue.destination_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers
                                            })
                                        }
                                        if (paxItem.secondSection === 2 && paxItem.origin === item.destination_code && paxItem.destination === this.props.cancelPopupValue.origin_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers2
                                            })
                                        }
                                    })
                                }
                            }
                            if (this.props.cancelPopupValue.segments[0] && this.props.cancelPopupValue.segments[0].length > 2) {
                                for (let j = 0; j < (this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i].length); j++) {
                                    let item = this.props.cancelPopupValue.segments[i][j];
                                    values && values.sectors.filter((ele, ind) => ind === values.sectors.findIndex( elem => elem.time === ele.time)).map((paxItem) => {
                                        if (paxItem.firstSection === 1 && paxItem.origin === item.origin_code && paxItem.destination === this.props.cancelPopupValue.destination_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers
                                            })
                                        }
                                        if (paxItem.secondSection === 2 && paxItem.origin === item.destination_code && paxItem.destination === this.props.cancelPopupValue.origin_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers2
                                            })
                                        }
                                    })
                                }
                            }
                        }
                        if(this.props.cancelPopupValue.trip_type === 3){
                            values && values.pax_ticket.map((paxItem) => {
                                if (paxItem.value === this.props.cancelPopupValue.passengers[i].pax_id) {
                                    if (this.props.cancelPopupValue.passengers[i].first_name !== undefined && this.props.cancelPopupValue.passengers[i].last_name !== undefined) {
                                        if (paxItem.firstPassengerSection === 1) {
                                            passengers.push({
                                                "first_name": this.props.cancelPopupValue.passengers[i].first_name,
                                                "last_name": this.props.cancelPopupValue.passengers[i].last_name
                                            })
                                            pax_ticket.push(paxItem.value)
                                        }
                                        if (paxItem.secondPassengerSection === 2) {
                                            passengers2.push({
                                                "first_name": this.props.cancelPopupValue.passengers[i].first_name,
                                                "last_name": this.props.cancelPopupValue.passengers[i].last_name
                                            })
                                            pax_ticket.push(paxItem.value)
                                        }
                                    }
                                }
                                return null;
                            })
                            if (this.props.cancelPopupValue.segments[0] && this.props.cancelPopupValue.segments[0].length > 2) {
                                const uniqueSectors = new Set(); // Create a set to store unique sector information
                                for (let i = 0; i < this.props.cancelPopupValue.segments.length; i++) {
                                    for (let j = 0; j < this.props.cancelPopupValue.segments[i].length; j++) {
                                        const item = this.props.cancelPopupValue.segments[i][j];
                                       
                                        values && values.sectors.map((paxItem) => {
                                            if ((paxItem.firstSection === 1 || paxItem.secondSection === 2) && item.origin_code === paxItem.origin && item.destination_code === paxItem.destination) {
                                                uniqueSectors.add({ // Add sector information to the set
                                                    "origin": paxItem.origin,
                                                    "destination": paxItem.destination,
                                                    "travel_date_time": item.departure_time, //departureTime,
                                                    "passengers": paxItem.firstSection === 1 ? passengers : passengers2 // Determine passengers based on section
                                                });
                                            }
                                        });
                                    }
                                }
                                sectors = [...uniqueSectors]; // Assign the unique sectors from the set to the sectors array
                            }
                        }
                    }
                }
                if (this.props.cancelPopupValue.booking_type === 'online') {
                    if (this.props.cancelPopupValue.trip_type === 1) {
                        data = {
                            "booking_id": this.props.cancelPopupValue.booking_id,
                            "tripjack_bookingId": this.props.cancelPopupValue.transaction_no,
                            "request_type": "2",
                            "cancellation_type": "2",
                            "sectors": [
                                {
                                    "origin": item2 && item2.origin,
                                    "destination": item2 && item2.destination,
                                    "travel_date_time": item2 && item2.time, //departureTime,
                                    "passengers": passengers
                                }
                            ],
                            "ticket_id": pax_ticket,
                            "remarks": values.remark
                        }
                    }
                    else if (this.props.cancelPopupValue.trip_type === 2) {
                        data = {
                            "booking_id": this.props.cancelPopupValue.booking_id,
                            "tripjack_bookingId": this.props.cancelPopupValue.transaction_no,
                            "request_type": "2",
                            "cancellation_type": "2",
                            "sectors": sectors,
                            "ticket_id": pax_ticket,
                            "remarks": values.remark
                        }
                    }
                    else if (this.props.cancelPopupValue.trip_type === 3) {
                        data = {
                            "booking_id": this.props.cancelPopupValue.booking_id,
                            "tripjack_bookingId": this.props.cancelPopupValue.transaction_no,
                            "request_type": "2",
                            "cancellation_type": "2",
                            "sectors": sectors,
                            "ticket_id": pax_ticket,
                            "remarks": values.remark
                        }
                    }
                } else if (this.props.cancelPopupValue.booking_type === 'offline') {
                    if (this.props.cancelPopupValue.trip_type === 1) {
                        data = {
                            "booking_id": this.props.cancelPopupValue.booking_id,
                            "request_type": "2",
                            "cancellation_type": "2",
                            "sectors": [
                                {
                                    "origin": item2.origin,
                                    "destination": item2.destination,
                                    "travel_date_time": departureTime,
                                    "passengers": passengers
                                }
                            ],
                            "ticket_id": pax_ticket,
                            "remarks": values.remark
                        }
                    } else if (this.props.cancelPopupValue.trip_type === 2) {
                        data = {
                            "booking_id": this.props.cancelPopupValue.booking_id,
                            "request_type": "2",
                            "cancellation_type": "2",
                            "sectors": sectors,
                            "ticket_id": pax_ticket,
                            "remarks": values.remark
                        }
                    } else if (this.props.cancelPopupValue.trip_type === 3) {
                        data = {
                            "booking_id": this.props.cancelPopupValue.booking_id,
                            "request_type": "2",
                            "cancellation_type": "2",
                            "sectors": sectors,
                            "ticket_id": pax_ticket,
                            "remarks": values.remark
                        }
                    }

                }
            }
            const successCb = () => {
                this.props.getMyBookings(this.props.limitOffSet);
                this.setState({
                    cancelledPnr: values.pnr
                })
            }
            const errorCb = () => {
                this.props.getMyBookings(this.props.limitOffSet);
                this.setState({
                    cancelledPnr: 'error'
                })
            }
            this.props.cancelTripjackBooking(data, successCb, errorCb);
        }else if(this.props.cancelPopupValue.booking_from==="kafila"){
            let data = '';
            // let origin = '';
            // let destination = '';
            let departureTime = '';
            if (values.cancel_type === 'full_cancellation') {
                data = {
                    "booking_id": this.props.cancelPopupValue.booking_id,
                    "kafila_booking_id": this.props.cancelPopupValue.kafila_booking_id,
                    "request_type": "1",
                    "cancellation_type": "3",
                    // "reason":values.cancel_reason&&JSON.parse(values.cancel_reason) ,
                    "remarks":values.remark
                };
            }
            else if (values.cancel_type === 'cancel_ticket') {
                if (this.props.cancelPopupValue.booking_type === 'online') {
                    data = {
                        "booking_id": this.props.cancelPopupValue.booking_id,
                        "kafila_booking_id": this.props.cancelPopupValue.kafila_booking_id,
                        "request_type": "1",
                        "cancellation_type": "2",
                        "remarks": values.remark
                    };
                } else if (this.props.cancelPopupValue.booking_type === 'offline') {
                    data = {
                        "booking_id": this.props.cancelPopupValue.booking_id,
                        "request_type": "1",
                        "cancellation_type": "2",
                        "remarks": values.remark
                    };
                }
            }
            else {
                let sectors = [];
                let passengers = [];
                let pax_ticket = [];
                let passengers2 = [];
                // let item1 = '';
                let item2 = '';
                if (this.props.cancelPopupValue) {
                    for (let i = 0; i < (this.props.cancelPopupValue.passengers.length); i++) {
                        let origin = this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i][0] && this.props.cancelPopupValue.segments[i][0].origin_code;
                        let destination = this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i][this.props.cancelPopupValue.segments[i].length - 1] && this.props.cancelPopupValue.segments[i][this.props.cancelPopupValue.segments[i].length - 1].destination_code;
                        // let departureTime = this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i][0] && this.props.cancelPopupValue.segments[i][0].departure_time
                        if (this.props.cancelPopupValue.trip_type === 1) {
                            values && values.pax_ticket.map((paxItem) => {
                                if (paxItem.value === this.props.cancelPopupValue.passengers[i].pax_id) {
                                    passengers.push({
                                        "first_name": this.props.cancelPopupValue.passengers[i].first_name,
                                        "last_name": this.props.cancelPopupValue.passengers[i].last_name,
                                        "pax_title": this.props.cancelPopupValue.passengers[i].pax_title,
                                        "pax_type":this.props.cancelPopupValue.passengers[i].pax_type
                                    })
                                    pax_ticket.push(paxItem.value)
                                }
                                return null;
                            })
                            values && values.sectors.filter((ele, ind) => ind === values.sectors.findIndex( elem => elem.time === ele.time)).map((paxItem) => {
                                item2 = paxItem
                                return null;
                            })
                        }
                        if (this.props.cancelPopupValue.trip_type === 2) {
                            values && values.pax_ticket.map((paxItem) => {
                                if (paxItem.value === this.props.cancelPopupValue.passengers[i].pax_id) {
                                    if (this.props.cancelPopupValue.passengers[i].first_name !== undefined && this.props.cancelPopupValue.passengers[i].last_name !== undefined) {
                                        if (paxItem.firstPassengerSection === 1) {
                                            passengers.push({
                                                "first_name": this.props.cancelPopupValue.passengers[i].first_name,
                                                "last_name": this.props.cancelPopupValue.passengers[i].last_name,
                                                "pax_title": this.props.cancelPopupValue.passengers[i].pax_title,
                                                "pax_type": this.props.cancelPopupValue.passengers[i].pax_type
                                            })
                                            pax_ticket.push(paxItem.value)
                                        }
                                        if (paxItem.secondPassengerSection === 2) {
                                            passengers2.push({
                                                "first_name": this.props.cancelPopupValue.passengers[i].first_name,
                                                "last_name": this.props.cancelPopupValue.passengers[i].last_name,
                                                "pax_title": this.props.cancelPopupValue.passengers[i].pax_title,
                                                "pax_type":this.props.cancelPopupValue.passengers[i].pax_type
                                            })
                                            pax_ticket.push(paxItem.value)
                                        }
                                    }
                                }
                                return null;
                            })
                            if (this.props.cancelPopupValue.segments[0] && this.props.cancelPopupValue.segments[0].length === 1) {
                                if (origin !== destination) {
                                    values && values.sectors.filter((ele, ind) => ind === values.sectors.findIndex( elem => elem.time === ele.time)).map((paxItem) => {
                                        if (paxItem.firstSection === 1 && paxItem.origin === origin && paxItem.destination === destination) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers
                                            })
                                        }
                                        if (paxItem.secondSection === 2 && paxItem.origin === origin && paxItem.destination === destination) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers2
                                            })
                                        }
                                        return null;
                                    })
                                } else {
                                    values && values.sectors.filter( (ele, ind) => ind === values.sectors.findIndex( elem => elem.time === ele.time)).map((paxItem) => {
                                        if (paxItem.firstSection === 1 && paxItem.origin === this.props.cancelPopupValue.segments[0].origin_code && paxItem.destination === this.props.cancelPopupValue.segments[0].destination_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers
                                            })
                                        }
                                        if (paxItem.secondSection === 2 && paxItem.origin === this.props.cancelPopupValue.segments[0].origin_code && paxItem.destination === this.props.cancelPopupValue.segments[0].destination_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers2
                                            })
                                        }
                                        return null;
                                    })
                                }
                            }
                            if (this.props.cancelPopupValue.segments[0] && this.props.cancelPopupValue.segments[0].length === 2) {
                                for (let j = 0; j < (this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i].length); j++) {
                                    let item = this.props.cancelPopupValue.segments[i][j];
                                    values && values.sectors.filter( (ele, ind) => ind === values.sectors.findIndex( elem => elem.time === ele.time)).map((paxItem) => {
                                        if (paxItem.firstSection === 1 && paxItem.origin === item.origin_code && paxItem.destination === this.props.cancelPopupValue.destination_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers
                                            })
                                        }
                                        if (paxItem.secondSection === 2 && paxItem.origin === item.destination_code && paxItem.destination === this.props.cancelPopupValue.origin_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers2
                                            })
                                        }
                                        return null;
                                    })
                                }
                            }
                            if (this.props.cancelPopupValue.segments[0] && this.props.cancelPopupValue.segments[0].length > 2) {
                                for (let j = 0; j < (this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i].length); j++) {
                                    let item = this.props.cancelPopupValue.segments[i][j];
                                    values && values.sectors.filter((ele, ind) => ind === values.sectors.findIndex( elem => elem.time === ele.time)).map((paxItem) => {
                                        if (paxItem.firstSection === 1 && paxItem.origin === item.origin_code && paxItem.destination === this.props.cancelPopupValue.destination_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers
                                            })
                                        }
                                        if (paxItem.secondSection === 2 && paxItem.origin === item.destination_code && paxItem.destination === this.props.cancelPopupValue.origin_code) {
                                            sectors.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers2
                                            })
                                        }
                                        return null;
                                    })
                                }
                            }
                        }
                    }
                }
                if (this.props.cancelPopupValue.booking_type === 'online') {
                    if (this.props.cancelPopupValue.trip_type === 1) {
                        data = {
                            "booking_id": this.props.cancelPopupValue.booking_id,
                            "kafila_booking_id": this.props.cancelPopupValue.transaction_no,
                            "request_type": "2",
                            "cancellation_type": "2",
                            "sectors": [
                                {
                                    "origin": item2 && item2.origin,
                                    "destination": item2 && item2.destination,
                                    "travel_date_time": item2 && item2.time, //departureTime,
                                    "passengers": passengers
                                }
                            ],
                            "ticket_id": pax_ticket,
                            "remarks": values.remark
                        }
                    }
                    else if (this.props.cancelPopupValue.trip_type === 2) {
                        data = {
                            "booking_id": this.props.cancelPopupValue.booking_id,
                            "kafila_booking_id": this.props.cancelPopupValue.transaction_no,
                            "request_type": "2",
                            "cancellation_type": "2",
                            "sectors": sectors,
                            "ticket_id": pax_ticket,
                            "remarks": values.remark
                        }
                    }

                } else if (this.props.cancelPopupValue.booking_type === 'offline') {
                    if (this.props.cancelPopupValue.trip_type === 1) {
                        data = {
                            "booking_id": this.props.cancelPopupValue.booking_id,
                            "request_type": "2",
                            "cancellation_type": "2",
                            "sectors": [
                                {
                                    "origin": item2.origin,
                                    "destination": item2.destination,
                                    "travel_date_time": departureTime,
                                    "passengers": passengers
                                }
                            ],
                            "ticket_id": pax_ticket,
                            "remarks": values.remark
                        }
                    } else if (this.props.cancelPopupValue.trip_type === 2) {
                        data = {
                            "booking_id": this.props.cancelPopupValue.booking_id,
                            "request_type": "2",
                            "cancellation_type": "2",
                            "sectors": sectors,
                            "ticket_id": pax_ticket,
                            "remarks": values.remark
                        }
                    }

                }
            }
            const successCb = () => {
                this.props.getMyBookings(this.props.limitOffSet);
                this.setState({
                    cancelledPnr: "success"
                })
            }
            const errorCb = () => {
                this.props.getMyBookings(this.props.limitOffSet);
                this.setState({
                    cancelledPnr: 'error'
                })
            }
            this.props.cancelKafilaBooking(data, successCb, errorCb);
        }else if(this.props.cancelPopupValue.booking_from==="riya"){
            let data = '';
            // let origin = '';
            // let destination = '';
            let departureTime = '';
            if (values.cancel_type === 'full_cancellation') {
                data = {
                    "booking_id": this.props.cancelPopupValue.booking_id,
                    "riya_booking_id": this.props.cancelPopupValue.riya_booking_id ? this.props.cancelPopupValue.riya_booking_id : this.props.cancelPopupValue.transaction_no,
                    "request_type": "1",
                    "cancellation_type": "3",
                    "remarks": values.remark
                };
            }
            else if (values.cancel_type === 'cancel_ticket') {
                if (this.props.cancelPopupValue.booking_type === 'online') {
                    data = {
                        "booking_id": this.props.cancelPopupValue.booking_id,
                        "riya_booking_id": this.props.cancelPopupValue.transaction_no,
                        "request_type": "1",
                        "cancellation_type": "2",
                        "remarks": values.remark
                    };
                } else if (this.props.cancelPopupValue.booking_type === 'offline') {
                    data = {
                        "booking_id": this.props.cancelPopupValue.booking_id,
                        "request_type": "1",
                        "cancellation_type": "2",
                        "remarks": values.remark
                    };
                }
            }
            const successCb = () => {
                this.props.getMyBookings(this.props.limitOffSet);
                this.setState({
                    cancelledPnr: "success"
                })
            }
            const errorCb = () => {
                this.props.getMyBookings(this.props.limitOffSet);
                this.setState({
                    cancelledPnr: 'error'
                })
            }
            this.props.cancelRiyaBooking(data, successCb, errorCb);
        }

        else if (this.props.cancelPopupValue.booking_from !== 'tripjack' && this.props.cancelPopupValue.booking_from !== "kafila") {
            let data = '';
            if (values.cancel_type === 'full_cancellation') {
                data = {
                    "booking_id": this.props.cancelPopupValue.booking_id,
                    "tbo_booking_id": this.props.cancelPopupValue.transaction_no,
                    "request_type": "1",
                    "cancellation_type": "3",
                    "remarks": values.remark
                };
            }
            else if (values.cancel_type === 'cancel_ticket') {
                if (this.props.cancelPopupValue.booking_type === 'online') {
                    data = {
                        "booking_id": this.props.cancelPopupValue.booking_id,
                        "tbo_booking_id": this.props.cancelPopupValue.transaction_no,
                        "request_type": "1",
                        "cancellation_type": "2",
                        "remarks": values.remark
                    };
                } else if (this.props.cancelPopupValue.booking_type === 'offline') {
                    data = {
                        "booking_id": this.props.cancelPopupValue.booking_id,
                        "request_type": "1",
                        "cancellation_type": "2",
                        "remarks": values.remark
                    };
                }
            } else {
                let sectors = [];
                let passengers=[]
                let tboPassSector=[]
                if (this.props.cancelPopupValue) {
                        for (let i = 0; i < (this.props.cancelPopupValue.passengers.length); i++) {
                            // let origin = this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i][0] && this.props.cancelPopupValue.segments[i][0].origin_code;
                            // let destination = this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i][this.props.cancelPopupValue.segments[i].length - 1] && this.props.cancelPopupValue.segments[i][this.props.cancelPopupValue.segments[i].length - 1].destination_code;
                            // let departureTime = this.props.cancelPopupValue.segments[i] && this.props.cancelPopupValue.segments[i][0] && this.props.cancelPopupValue.segments[i][0].departure_time
                                values && values.pax_ticket.map((paxItem) => {
                                    if (paxItem.toString() === this.props.cancelPopupValue.passengers[i].ticket_id) {
                                        passengers.push({
                                            "first_name": this.props.cancelPopupValue.passengers[i].first_name,
                                            "last_name": this.props.cancelPopupValue.passengers[i].last_name
                                        })
                                    }
                                    return null;
                                })
                        }
                                values && values.sectors.map((paxItem) => {
                                        if (paxItem) {
                                            tboPassSector.push({
                                                "origin": paxItem.origin,
                                                "destination": paxItem.destination,
                                                "travel_date_time": paxItem.time, //departureTime,
                                                "passengers": passengers
                                            })
                                        }
                                        return null;
                                })
                    for (let i = 0; i < this.props.cancelPopupValue.segments.length; i++) {
                        for (let j = 0; j < this.props.cancelPopupValue.segments[i].length; j++) {
                            let item = this.props.cancelPopupValue.segments[i][j];
                            
                            if (item.origin_code !== 'all' && item.destination_code) {
                                sectors.push({
                                    "origin": item.origin_code,
                                    "destination": item.destination_code,
                                });
                            }
                        }
                    }
                }
                if(this.props.cancelPopupValue && this.props.cancelPopupValue.length){
                    tboPassSector.forEach((flight) => {
                        const matchingPass = this.props.cancelPopupValue && this.props.cancelPopupValue.partial_cancel.find((p) => p.origin === flight.origin);
                        if (matchingPass) {
                            flight.passengers = flight.passengers.filter((passenger) =>
                                matchingPass.passengers.every((p) => !isEqual(p, passenger))
                            );
                        }
                      });
                      
                      function isEqual(obj1, obj2) {
                        return JSON.stringify(obj1) === JSON.stringify(obj2);
                      }
                }
                
                if (this.props.cancelPopupValue.booking_type === 'online') {
                    data = {
                        "booking_id": this.props.cancelPopupValue.booking_id,
                        "tbo_booking_id": this.props.cancelPopupValue.transaction_no,
                        "request_type": "2",
                        "cancellation_type": "2",
                        "sectors": values.sectors,
                        "tbo_sector":tboPassSector,
                        "ticket_id": values.pax_ticket,
                        "remarks": values.remark
                    }
                } else if (this.props.cancelPopupValue.booking_type === 'offline') {
                    data = {
                        "booking_id": this.props.cancelPopupValue.booking_id,
                        "request_type": "2",
                        "cancellation_type": "2",
                        "sectors": values.sectors,
                        "tbo_sector":tboPassSector,
                        "ticket_id": values.pax_ticket,
                        "remarks": values.remark
                    }
                }
            }
            const successCb = () => {
                this.props.getMyBookings(this.props.limitOffSet);
                this.setState({
                    cancelledPnr: values.pnr
                })
            }
            const errorCb = () => {
                this.props.getMyBookings(this.props.limitOffSet);
                this.setState({
                    cancelledPnr: 'error'
                })
            }

            this.props.cancelBooking(data, successCb, errorCb);
        }
    }


    setPaxTicketValue = (event, value, setFieldValue, values, key) => {
        let paxTicketValue = values.pax_ticket;
        if (event.target.checked) {
            this.setStateByValue(event, true, 'checkAllPax', key, 'checkPassengers');
            paxTicketValue.push(value);
        } else {
            this.setStateByValue(event, false, 'checkAllPax', key, 'checkPassengers');
            if (paxTicketValue.includes(value)) {
                var indexValue = paxTicketValue.indexOf(value)
                paxTicketValue.splice(indexValue, 1);
            }
        }
        setFieldValue('pax_ticket', paxTicketValue);
    }

    setTripJackPaxTicketValue = (event, value, setFieldValue, values, key,tripSegments) => {
        let paxTicketValue = values.pax_ticket;
        this.setState({ firstPassengerSection: '1' })
        
        if (event.target.checked) {
            this.setStateByValue(event, true, 'checkAllPax', key, 'checkPassengers');
            paxTicketValue.push({ 'value': value, 'firstPassengerSection': 1 });
            if (tripSegments.length > 0) {
                tripSegments.map((segment, index) => {
                    let departure_time = segment.departure_time;
                    let segment_value = segment.origin_code + '-' + segment.destination_code;
                    let sector_key = segment.sector_key;
                    this.setTripjackSectorsValue(event, segment_value, setFieldValue, values, sector_key, departure_time)
                })
            }
        } else {
            this.setStateByValue(event, false, 'checkAllPax', key, 'checkPassengers');
            let data = [];
            paxTicketValue && paxTicketValue.map((item) => {
                data.push(item.value)
            })
            if (data.includes(value)) {
                var indexValue = data.indexOf(value)
                paxTicketValue.splice(indexValue, 1);
                if(paxTicketValue.length === 0){
                    this.checkAllValues(false, 'checkSegments');
                }
            }
        }
        setFieldValue('pax_ticket', paxTicketValue);
        //setFieldValue('firstPassengerSection', 1);
    }

    setPaxTicketValueReturn = (event, value, setFieldValue, values, key,tripSegments) => {
        let paxTicketValue = values.pax_ticket;
        this.setState({ secondPassengerSection: '2' })
        if (event.target.checked) {
            this.setStateByValue(event, true, 'checkAllPaxReturn', key, 'checkPassengersReturn');
            paxTicketValue.push({ 'value': value, 'secondPassengerSection': 2 });
            if (tripSegments.length > 0) {
                tripSegments.map((segment, index) => {
                    let departure_time = segment.departure_time;
                    let segment_value = segment.origin_code + '-' + segment.destination_code;
                    let sector_key = segment.sector_key;
                    this.setSectorsValueReturn(event, segment_value, setFieldValue, values, sector_key, departure_time)
                })
            }
        } else {
            this.setStateByValue(event, false, 'checkAllPaxReturn', key, 'checkPassengersReturn');
            let data = [];
            paxTicketValue && paxTicketValue.map((item) => {
                data.push(item.value)
            })
            if (data.includes(value)) {
                var indexValue = data.indexOf(value)
                paxTicketValue.splice(indexValue, 1);
                if(paxTicketValue.length === 0){
                    this.checkAllValues(false, 'checkSegmentsReturn');
                }
            }
        }
        setFieldValue('pax_ticket', paxTicketValue);
        //setFieldValue('secondPassengerSection', 2);
    }

    setStateByValue = (event, allValue, allKeyName, key, keyName) => {
        let targetValue = event.target.value;
        if (targetValue === 'all') {
            this.checkAllValues(allValue, keyName);
            this.setState({
                [allKeyName]: allValue
            });
        }
        if (event.target.checked) {
            if (targetValue !== 'all') {
                if (this.state[allKeyName] === true) {
                    this.checkAllValues(false, keyName);
                    this.setState({
                        [allKeyName]: false
                    });
                }
                let sectorValues = this.state[keyName];
                sectorValues[key] = true;
                this.setState({
                    [keyName]: sectorValues
                });
            }
        } else {
            if (targetValue !== 'all') {
                let sectorValues = this.state[keyName];
                sectorValues[key] = false;
                this.setState({
                    [allKeyName]: false,
                    [keyName]: sectorValues
                });
            }
        }
    }

    setSectorsValue = (event, value, setFieldValue, values, key) => {
        let paxTicketValue = values.sectors;
        let origionDestination = value.split('-');
        let sectorObject = {
            "origin": origionDestination[0],
            "destination": origionDestination[1]
        };
    
        if (event.target.checked) {
            this.setStateByValue(event, true, 'checkAllSectors', key, 'checkSegments');
            paxTicketValue.push(sectorObject);
        } else {
            this.setStateByValue(event, false, 'checkAllSectors', key, 'checkSegments');
            let indexToRemove = paxTicketValue.findIndex((sector) => 
                sector.origin === sectorObject.origin && sector.destination === sectorObject.destination
            );
    
            if (indexToRemove !== -1) {
                paxTicketValue.splice(indexToRemove, 1);
            }
        }
        this.setState({ sector: paxTicketValue });
        setFieldValue('sectors', paxTicketValue);
    }
    

    setTripjackSectorsValue = (event, value, setFieldValue, values, key, time,tripType1Passenger) => {
        this.setState({ firstSection: '1' })
        let paxTicketValue = values.sectors;
        let origionDestination = value.split('-');
        if (event.target.checked) {
            this.setStateByValue(event, true, 'checkAllSectors', key, 'checkSegments');
            paxTicketValue.push({
                "origin": origionDestination[0],
                "destination": origionDestination[1],
                "time": time,
                'firstSection': 1
            });
        } else {
            this.setStateByValue(event, false, 'checkAllSectors', key, 'checkSegments');
            let data = [];
            paxTicketValue && paxTicketValue.map((item) => {
                data.push(item.origin + '-' + item.destination)
            })
            if (data.includes(value)) {
                var indexValue = data.indexOf(value)
                paxTicketValue.splice(indexValue, 1);
                if(tripType1Passenger){
                    if(paxTicketValue.length > 0){
                        paxTicketValue.splice(indexValue, paxTicketValue.length);
                   }
                }
            }            
        }
        setFieldValue('sectors', paxTicketValue);
        ///setFieldValue('firstSection', 1);
    }

    setSectorsValueReturn = (event, value, setFieldValue, values, key, time,tripType2Passenger) => {
        this.setState({ secondSection: '2' })
        let paxTicketValue = values.sectors;
        let origionDestination = value.split('-');
        if (event.target.checked) {
            this.setStateByValue(event, true, 'checkAllSectorsReturn', key, 'checkSegmentsReturn');
            paxTicketValue.push({
                "origin": origionDestination[0],
                "destination": origionDestination[1],
                "time": time,
                'secondSection': 2
            });
        } else {
            this.setStateByValue(event, false, 'checkAllSectorsReturn', key, 'checkSegmentsReturn');
            let data = [];
            paxTicketValue && paxTicketValue.map((item) => {
                data.push(item.origin + '-' + item.destination)
            })
            if (data.includes(value)) {
                var indexValue = data.indexOf(value)
                paxTicketValue.splice(indexValue, 1);
                if(tripType2Passenger){
                    if(paxTicketValue.length > 0){
                        paxTicketValue.splice(indexValue, paxTicketValue.length);
                    }
                }
                
            }
        }
        setFieldValue('sectors', paxTicketValue);
        //setFieldValue('secondSection', 2);
    }

    /* create our form validator */
    cancelValidator = yup.object()
        .shape({
            remark: yup.string()
                .required('Remark is required.'),
            cancel_type: yup.string()
                .required('Remark is required.'),
                // cancel_reason:this.props.cancelPopupValue.booking_from==="kafila" ? yup.string().required('Reason is required.'): yup.string(),
            sectors: yup.array().test('cancel_type', 'Sectors required',
                function (value) {
                    let cancelType = this.resolve(yup.ref("cancel_type"));
                    if (cancelType === 'cancel_ticket') {
                        return true;
                    }
                    else if (cancelType === 'partial_cancellation') {
                        if (value.length > 0) {
                            return true;
                        }
                        else {
                            return false;
                        }

                    }
                    else {
                        return true;
                    }
                }),
            pax_ticket: yup.array().test('cancel_type', 'Pax ticket required',
                function (value) {
                    let cancelType = this.resolve(yup.ref("cancel_type"));
                    if (cancelType === 'cancel_ticket') {
                        return true;
                    }
                    else if (cancelType === 'partial_cancellation') {
                        if (value.length > 0) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        return true;
                    }
                }),
            termAndCondition: yup.bool()
                .required("Required")
                .oneOf([true], "Must agree Terms and Conditions")
        });

    initialValuesFlight = {
        remark: "",
        pax_ticket: [],
        sectors: [],
        cancel_type:"",
        // cancel_reason:"",
        termAndCondition: false
    };

    showAllChecked = (e, setFieldValue) => {
        setFieldValue('cancel_type', e.target.value);
        let value = e.target.value === 'full_cancellation' ? true : false;
        let paxValue = (e.target.value === 'full_cancellation' || e.target.value === 'cancel_ticket') ? true : false;
        let sendCheckVal = value;
        this.checkAllValues(sendCheckVal, 'checkPassengers');
        this.checkAllValues(sendCheckVal, 'checkPassengersReturn');
        this.checkAllValues(sendCheckVal, 'checkSegments');
        this.checkAllValues(sendCheckVal, 'checkSegmentsReturn');
        this.checkAllPaxValues(sendCheckVal);
        this.setState({
            checkAllSectors: value,
            checkAllPax: paxValue
        })
        this.setState({
            checkAllSectorsReturn: value,
            checkAllPaxReturn: paxValue
        })
    }

    checkAllValues = (value, keyName) => {
        let checkSectors = this.state[keyName];
        for (var index in checkSectors) {
            checkSectors[index] = value ? true : false
        }
        this.setState({
            [keyName]: checkSectors,
        })
    }

    checkAllPaxValues = (value) => {
        let checkPax = this.state.checkPassengers;
        for (var index in checkPax) {
            checkPax[index] = value ? true : false
        }
        this.setState({
            checkPassengers: checkPax,
        })
    }

    renderForm = (formikProps) => {
        const { handleSubmit, handleChange, handleBlur, touched, errors, setFieldValue, values, resetForm } = formikProps;
        const { showConfirmationPopup } = this.state;
        let tripType1Segments = []
        let tripType2Segments = []
        let tripType3Segments = []
        let tripType1Passenger = []
        let tripType2Passenger = [];
        let checkPartialPassengers=[]
        let cancelSector =  this.props.cancelPopupValue && this.props.cancelPopupValue.partial_cancel  && this.props.cancelPopupValue.partial_cancel.length && this.props.cancelPopupValue.partial_cancel.map((sec)=>{
            checkPartialPassengers=[...checkPartialPassengers,...sec.passengers]
            if((sec.passengers && sec.passengers.length > 1) || (this.props.cancelPopupValue.passengers.length === sec.passengers.length)){
            return sec.origin
            }
            return null;
        })
        let allPassengers = this.props.cancelPopupValue && this.props.cancelPopupValue.passengers && this.props.cancelPopupValue.passengers.length && this.props.cancelPopupValue.passengers.map((pass)=>{
            return {
                "first_name":pass.first_name,
                "last_name":pass.last_name
            }
        })
        
        let allMatches = JSON.stringify(allPassengers)===JSON.stringify(checkPartialPassengers) ? true : false
        let cancelTicketId = this.props.cancelPopupValue && this.props.cancelPopupValue.partial_cancel && this.props.cancelPopupValue.partial_cancel.length &&
            this.props.cancelPopupValue.partial_cancel.map((items) => {
                if (this.props.cancelPopupValue.origin_code === items.origin) {
                    return items.passengers.map((passengers) => {
                        return passengers.first_name + passengers.last_name
                    })
                }
                return null;
            })
            cancelTicketId = cancelTicketId && cancelTicketId.length && cancelTicketId.flat()
            let cancelTicketIdRound = this.props.cancelPopupValue && this.props.cancelPopupValue.partial_cancel && this.props.cancelPopupValue.partial_cancel.length &&
            this.props.cancelPopupValue.partial_cancel.map((items) => {
                if (this.props.cancelPopupValue.destination_code === items.origin) {
                    return items.passengers.map((passengers) => {
                        return passengers.first_name + passengers.last_name
                    })
                }
                return null
            })
            cancelTicketIdRound = cancelTicketIdRound && cancelTicketIdRound.length && cancelTicketIdRound.flat();
            let cancelTicketSectorTbo = this.props.cancelPopupValue && this.props.cancelPopupValue.partial_cancel && this.props.cancelPopupValue.partial_cancel.length &&
             this.props.cancelPopupValue.partial_cancel.map((sec)=>{
                if((sec.passengers && sec.passengers.length > 1) || (this.props.cancelPopupValue.passengers.length === sec.passengers.length)){
                    return sec.origin
                }
                return null;
            })
            let filteredNames = values.sectors && values.sectors.length === 1 && this.props.cancelPopupValue && this.props.cancelPopupValue.partial_cancel && this.props.cancelPopupValue.partial_cancel.length && this.props.cancelPopupValue.partial_cancel
            .filter(cancel => values.sectors.some(sector => sector.origin === cancel.origin))
            .flatMap(cancel => cancel.passengers.map(passenger => passenger.first_name+passenger.last_name));
        return (
            <React.Fragment>
                <div className={`popup-crad change-request-popup show`}>
                    <div className="popup-outer">
                        {
                            this.props.cancelPopupValue &&
                            <div className="popup-content">
                                <div className="popup-head">
                                    <h2>Change Request <span>(PNR: {this.props.cancelPopupValue.pnr})</span></h2>
                                    <div className="popup-close" onClick={() => this.props.showCancelTicket()}><span className="icon icon-cross" /></div>
                                </div>
                                <div className="popup-scorll">
                                    <div className="alert alert-success" style={{ display: this.state.cancelledPnr && this.state.cancelledPnr !== 'error' && this.state.cancelledPnr === this.props.cancelPopupValue.pnr ? 'block' :this.state.cancelledPnr==="success" ?"block": 'none' }}>
                                        <strong><i className="fa fa-check-circle" />{(this.props.kafila_cancel_booking_details&&this.props.kafila_cancel_booking_details.response&&this.props.kafila_cancel_booking_details.response.status==="ok"&&this.props.kafila_cancel_booking_details.response.message)||(this.props.cancelBookingDetail.response && this.props.cancelBookingDetail.response.message )|| (this.props.cancelTripJackBookingDetail.response && this.props.cancelTripJackBookingDetail.response.message) || (this.props.cancelRiyaBookingDetail.response && this.props.cancelRiyaBookingDetail.response.message)}</strong>
                                    </div>
                                    <div className="alert alert-danger" style={{ display: this.state.cancelledPnr && this.state.cancelledPnr === 'error' ? 'block' : 'none' }}>
                                        <strong><i className="fa fa-exclamation-triangle" />{(this.props.kafila_cancel_booking_details&&this.props.kafila_cancel_booking_details.response&&this.props.kafila_cancel_booking_details.response.status&&this.props.kafila_cancel_booking_details.response.message)||(this.props.cancelBookingDetail.error && this.props.cancelBookingDetail.error.message) || (this.props.cancelTripJackBookingDetail.error && this.props.cancelTripJackBookingDetail.error.message) || (this.props.cancelRiyaBookingDetail.response && this.props.cancelRiyaBookingDetail.response.message)}</strong>
                                    </div>
                                        <div className="change-requrst-block">
                                            <div className="block select-block">
                                                <div className="select-custom">
                                                    <select className={errors.cancel_type && touched.cancel_type ? 'form-control error-shadow' : 'form-control'}
                                                        name="cancel_type" onChange={(e) => this.showAllChecked(e, setFieldValue)} onBlur={handleBlur} value={values.cancel_type}>
                                                        <option value="">Select</option>
                                                        <option value="full_cancellation">Normal Cancel</option>
                                                        {
                                                            (this.props.cancelPopupValue.booking_from !== 'kafila' && this.props.cancelPopupValue.booking_from !== 'riya') &&
                                                            <option value="partial_cancellation">Partial Cancellation</option>
                                                        }
                                                         
                                                        {
                                                           ( this.props.cancelPopupValue.booking_from !== 'tripjack' && this.props.cancelPopupValue.booking_from !== 'riya') &&
                                                            <option value="cancel_ticket">Flight Cancelled/Full Refund</option>
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                this.props.cancelPopupValue.booking_from !== 'tripjack' && this.props.cancelPopupValue.booking_from !== 'kafila' &&
                                                <div className="block refund-sector">
                                                    <h5>Please select Refund Sectors</h5>
                                                    <div className="select-sector"> <small className="checkbox">
                                                        <input
                                                            className={values.cancel_type === '' ? 'disable-check' : ''}
                                                            name="sectors"
                                                            type="checkbox"
                                                            value="all"
                                                            onChange={(event) => this.setSectorsValue(event, 'all', setFieldValue, values, '')}
                                                            onBlur={handleBlur}
                                                            disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || values.cancel_type === 'partial_cancellation'}
                                                            checked={(this.props.cancelPopupValue.booking_from ==="kafila") ? true : this.state.checkAllSectors}
                                                        />
                                                        <label />
                                                    </small> <span>All</span> </div>
                                                    {
                                                        this.props.cancelPopupValue.segments.map((item, key) => {
                                                            return item.map((itemInner, keyInner) => {
                                                                let uncheckClass = '';
                                                                if (values.cancel_type === '')
                                                                    uncheckClass = 'disable-check';

                                                                return (
                                                                    <div className="select-sector" key={keyInner}>
                                                                        <small className={errors.sectors && touched.sectors ? 'checkbox error-shadow' : (cancelTicketSectorTbo && cancelTicketSectorTbo.length && cancelTicketSectorTbo.includes(itemInner.origin_code) && values.cancel_type === 'partial_cancellation') ? 'checkbox checkbox-disabled' : 'checkbox'}>
                                                                            <input
                                                                                className={uncheckClass}
                                                                                name="sectors"
                                                                                type="checkbox"
                                                                                value={itemInner.origin_code + '-' + itemInner.destination_code}
                                                                                onChange={(event) => this.setSectorsValue(event, itemInner.origin_code + '-' + itemInner.destination_code, setFieldValue, values, key + '-' + keyInner)}
                                                                                onBlur={handleBlur}
                                                                                disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelTicketSectorTbo && cancelTicketSectorTbo.length && cancelTicketSectorTbo.includes(itemInner.origin_code))}
                                                                                checked={(this.props.cancelPopupValue.booking_from ==="kafila") ? true : this.state.checkSegments[key + '-' + keyInner]}
                                                                            />
                                                                            <label />
                                                                        </small>
                                                                       <span>{itemInner.origin_code} - {itemInner.destination_code} ({dateFormat(itemInner.departure_time, 'DD MMM YYYY')})</span>
                                                                    </div>
                                                                )
                                                            })
                                                        })
                                                    }
                                                </div>
                                            }
                                            {
                                                (this.props.cancelPopupValue.booking_from !== 'tripjack' && this.props.cancelPopupValue.booking_from !== 'kafila') &&
                                                <div className="block refund-sector">
                                                    <h5>Please select Passenger</h5>
                                                    <div className="select-sector"> <small className="checkbox">
                                                        <input
                                                            className={values.cancel_type === '' ? 'disable-check' : ''}
                                                            name="pax_ticket"
                                                            id="all"
                                                            type="checkbox"
                                                            value="all"
                                                            onChange={(event) => this.setPaxTicketValue(event, 'all', setFieldValue, values, '')}
                                                            onBlur={handleBlur}
                                                            disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || values.cancel_type === 'partial_cancellation' || values.cancel_type === 'cancel_ticket'}
                                                            checked={this.state.checkAllPax}
                                                        />
                                                        <label />
                                                    </small> <span>All</span> </div>
                                                    {
                                                        this.props.cancelPopupValue.passengers.map((item, key) => {
                                                            let dest=[];
                                                            let cancelledTicketId=[];
                                                            this.props.cancelPopupValue && this.props.cancelPopupValue.partial_cancel && this.props.cancelPopupValue.partial_cancel.length && this.props.cancelPopupValue.partial_cancel.forEach((item)=>{
                                                                dest.push(item.destination_code)
                                                                item.ticket_id && item.ticket_id.length && item.ticket_id.forEach((itemInner)=>{
                                                                    cancelledTicketId.push(Number(itemInner.ticket_id))
                                                                })
                                                            }) 
                                                            let uncheckClass = '';
                                                            if (values.cancel_type === '')
                                                                uncheckClass = 'disable-check';
                                                            return (
                                                                <div className="select-sector" key={key}>
                                                                    <small className={errors.pax_ticket && touched.pax_ticket ? 'checkbox error-shadow' : (filteredNames && filteredNames.length && filteredNames.includes(item.first_name+item.last_name) && values.cancel_type === 'partial_cancellation') ? 'checkbox checkbox-disabled' : 'checkbox'}>
                                                                        <input
                                                                            className={uncheckClass}
                                                                            name="pax_ticket"
                                                                            type="checkbox"
                                                                            value={item.ticket_id}
                                                                            onChange={(event) => this.setPaxTicketValue(event, item.ticket_id, setFieldValue, values, key)}
                                                                            onBlur={handleBlur}                                                                                                             
                                                                            disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || values.cancel_type === 'cancel_ticket'  || (filteredNames && filteredNames.length && filteredNames.includes(item.first_name+item.last_name))}// || cancelledTicketId.length && cancelledTicketId.includes(item.pax_id)
                                                                            checked={(this.state.checkPassengers[key] || this.state.checkAllPax)}
                                                                        />
                                                                        <label />
                                                                    </small>
                                                                    
                                                                  <span>{item.first_name} {item.last_name}</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            {/* ================================================SECTORS TRIPJACK/KAFILA START=============================================================================== */}
                                            {
                                                (this.props.cancelPopupValue.booking_from === 'tripjack' || this.props.cancelPopupValue.booking_from === 'kafila') &&
                                                <div className="block refund-sector">
                                                    <h5>Please select Refund Sectors</h5>
                                                    <div className="select-sector"> <small className="checkbox">
                                                        <input
                                                            className={values.cancel_type === '' ? 'disable-check' : ''}
                                                            name="sectors"
                                                            type="checkbox"
                                                            value="all"
                                                            onChange={(event) => this.setTripjackSectorsValue(event, 'all', setFieldValue, values, '')}
                                                            onBlur={handleBlur}
                                                            disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || values.cancel_type === 'partial_cancellation'}
                                                            checked={(this.props.cancelPopupValue.booking_from === "kafila" &&(values.cancel_type === 'full_cancellation')) ? true : this.state.checkAllSectorsReturn}
                                                        />
                                                        <label />
                                                    </small> <span>All</span> </div>
                                                    {
                                                       
                                                        this.props.cancelPopupValue.trip_type === 1 && this.props.cancelPopupValue.segments.map((item, key) => {
                                                            if (item && item.length > 1) {
                                                                let origin_code = item && item[0].origin_code;
                                                                let destination_code = item && item[item.length - 1].destination_code;
                                                                let departure_time = item && item[0].departure_time;

                                                                tripType1Segments.push({origin_code:origin_code,destination_code:destination_code,departure_time:departure_time,sector_key : key + '-' + key})
                                                                let uncheckClass = '';
                                                                if (values.cancel_type === '')
                                                                    uncheckClass = 'disable-check';
                                                                return (
                                                                    <div className="select-sector" key={key}>
                                                                        <small className={(errors.sectors && touched.sectors) ? 'checkbox error-shadow' : 
                                                                        ((cancelSector && cancelSector.length && cancelSector.includes(origin_code)) || allMatches) && values.cancel_type === 'partial_cancellation' ? 
                                                                        'checkbox checkbox-disabled' : 'checkbox'}>
                                                                            <input
                                                                                className={uncheckClass}
                                                                                name="sectors"
                                                                                type="checkbox"
                                                                                value={origin_code + '-' + destination_code}
                                                                                onChange={(event) => this.setTripjackSectorsValue(event, origin_code + '-' + destination_code, setFieldValue, values, key + '-' + key, departure_time,tripType1Passenger)}
                                                                                onBlur={handleBlur}
                                                                                disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelSector && cancelSector.length && cancelSector.includes(origin_code)) || allMatches}
                                                                                checked={this.state.checkSegments[key + '-' + key]}
                                                                            />
                                                                            <label />
                                                                        </small>
                                                                       <span>{origin_code} - {destination_code} ({dateFormat(departure_time, 'DD MMM YYYY')})</span>
                                                                    </div>
                                                                )
                                                            }
                                                            if (item && item.length === 1) {
                                                                return item.map((itemInner, keyInner) => {
                                                                    let uncheckClass = '';
                                                                    tripType1Segments.push({origin_code:itemInner.origin_code,destination_code:itemInner.destination_code,departure_time:itemInner.departure_time,sector_key : key + '-' + keyInner})

                                                                    if (values.cancel_type === '')
                                                                        uncheckClass = 'disable-check';

                                                                    return (
                                                                        <div className="select-sector" key={keyInner}>
                                                                            <small className={(errors.sectors && touched.sectors) ? 'checkbox error-shadow' : 
                                                                            ((cancelSector && cancelSector.length && cancelSector.includes(itemInner.origin_code)) || allMatches) && values.cancel_type === 'partial_cancellation' ? 
                                                                            'checkbox checkbox-disabled' : 'checkbox'}>
                                                                                <input
                                                                                    className={uncheckClass}
                                                                                    name="sectors"
                                                                                    type="checkbox"
                                                                                    value={itemInner.origin_code + '-' + itemInner.destination_code}
                                                                                    onChange={(event) => this.setTripjackSectorsValue(event, itemInner.origin_code + '-' + itemInner.destination_code, setFieldValue, values, key + '-' + keyInner, itemInner.departure_time,tripType1Passenger)}
                                                                                    onBlur={handleBlur}
                                                                                    disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelSector && cancelSector.length && cancelSector.includes(itemInner.origin_code)) || allMatches}
                                                                                    checked={this.state.checkSegments[key + '-' + keyInner]}
                                                                                />
                                                                                <label />
                                                                            </small>
                                                                           <span>{itemInner.origin_code} - {itemInner.destination_code} ({dateFormat(itemInner.departure_time, 'DD MMM YYYY')})</span> 
                                                                        </div>
                                                                    )
                                                                })

                                                            }
                                                            return null;
                                                        })
                                                    }
                                                    {
                                                        this.props.cancelPopupValue.trip_type === 2 && this.props.cancelPopupValue.segments.map((item, key) => {

                                                            if (item && item.length === 1) {
                                                                if (this.props.cancelPopupValue.journey_type === 'inbound' || this.props.cancelPopupValue.journey_type === 'outbound') {
                                                                    return item.map((itemInner, keyInner) => {
                                                                        let uncheckClass = '';
                                                                        tripType2Segments.push({origin_code:itemInner.origin_code,destination_code:itemInner.destination_code,departure_time:itemInner.departure_time,sector_key : key + '-' + keyInner})

                                                                        if (values.cancel_type === '')
                                                                            uncheckClass = 'disable-check';
                                                                        return (
                                                                            <div className="select-sector" key={keyInner}>
                                                                                <small className={
                                                                                    (errors.sectors && touched.sectors) ? 'checkbox error-shadow' :
                                                                                        ((cancelSector && cancelSector.length && cancelSector.includes(itemInner.origin_code)) || allMatches) && values.cancel_type === 'partial_cancellation' ?
                                                                                            'checkbox checkbox-disabled' :
                                                                                            'checkbox'
                                                                                }>

                                                                                    <input
                                                                                        className={uncheckClass}
                                                                                        name="sectors"
                                                                                        type="checkbox"
                                                                                        value={itemInner.origin_code + '-' + itemInner.destination_code}
                                                                                        onChange={(event) => this.setTripjackSectorsValue(event, itemInner.origin_code + '-' + itemInner.destination_code, setFieldValue, values, key + '-' + keyInner, itemInner.departure_time,tripType1Passenger)}
                                                                                        onBlur={handleBlur}
                                                                                        disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelSector && cancelSector.length && cancelSector.includes(itemInner.origin_code)) || allMatches}
                                                                                        checked={this.state.checkSegments[key + '-' + keyInner]}
                                                                                    />
                                                                                    <label />
                                                                                </small>
                                                                                 <span>{itemInner.origin_code} - {itemInner.destination_code} ({dateFormat(itemInner.departure_time, 'DD MMM YYYY')})</span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                } else {
                                                                    return item.map((itemInner, keyInner) => {
                                                                        let uncheckClass = '';
                                                                        tripType2Segments.push({origin_code:itemInner.origin_code,destination_code:itemInner.destination_code,departure_time:itemInner.departure_time,sector_key : key + '-' + keyInner})

                                                                        if (values.cancel_type === '')
                                                                            uncheckClass = 'disable-check';
                                                                        return (
                                                                            <div className="select-sector" key={key}>
                                                                                <small className={(errors.sectors && touched.sectors) ? 'checkbox error-shadow' :
                                                                                 ((cancelSector && cancelSector.length && cancelSector.includes(itemInner.origin_code)) || allMatches) && values.cancel_type === 'partial_cancellation' ? 'checkbox checkbox-disabled' : 'checkbox'}>
                                                                                    <input
                                                                                        className={uncheckClass}
                                                                                        name="sectors"
                                                                                        type="checkbox"
                                                                                        value={itemInner.origin_code + '-' + itemInner.destination_code}
                                                                                        onChange={(event) => this.setTripjackSectorsValue(event, itemInner.origin_code + '-' + itemInner.destination_code, setFieldValue, values, key + '-' + key, itemInner.departure_time,tripType1Passenger)}
                                                                                        onBlur={handleBlur}
                                                                                        disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelSector && cancelSector.length && cancelSector.includes(itemInner.origin_code)) || allMatches}
                                                                                        checked={this.state.checkSegments[key + '-' + key]}
                                                                                    />
                                                                                    <label />
                                                                                </small>
                                                                                <span>{itemInner.origin_code} - {itemInner.destination_code} ({dateFormat(itemInner.departure_time, 'DD MMM YYYY')})</span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            }
                                                            if (item && item.length === 2) {
                                                                if(this.props.cancelPopupValue.journey_type === 'inbound' || this.props.cancelPopupValue.journey_type === 'outbound'){
                                                                    let origin = item && item[0].origin_code;
                                                                    let destination = item && item[item.length - 1].destination_code;
                                                                    let departureTime = item && item[0].departure_time;
                                                                    let uncheckClass = '';
                                                                    tripType2Segments.push({origin_code:origin,destination_code:destination,departure_time:departureTime,sector_key : key + '-' + 0})

                                                                    if (values.cancel_type === '') { uncheckClass = 'disable-check'; }
                                                                    return (
                                                                        <div className="select-sector" key={0}>
                                                                            <small className={errors.sectors && touched.sectors ? 'checkbox error-shadow' : (cancelSector && cancelSector.length && cancelSector.includes(origin) || allMatches) && values.cancel_type === 'partial_cancellation' ? 'checkbox checkbox-disabled' : 'checkbox'}>
                                                                                <input
                                                                                    className={uncheckClass}
                                                                                    name="sectors"
                                                                                    type="checkbox"
                                                                                    value={origin + '-' + destination}
                                                                                    onChange={(event) => this.setTripjackSectorsValue(event, origin + '-' + destination, setFieldValue, values, key + '-' + 0, departureTime,tripType1Passenger)}
                                                                                    onBlur={handleBlur}
                                                                                    disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || cancelSector.includes(origin) || allMatches}
                                                                                    checked={this.state.checkSegments[key + '-' + 0]}
                                                                                />
                                                                                <label />
                                                                            </small>
                                                                            <span>{origin} - {destination} ({dateFormat(departureTime, 'DD MMM YYYY')})</span>
                                                                        </div>
                                                                    )
                                                                }else{
                                                                    let origin = item && item[0].origin_code;
                                                                    let destination = item && item[item.length - 1].destination_code;
                                                                    let departureTime = item && item[0].departure_time
                                                                    
                                                                    let uncheckClass = '';
                                                                    if (values.cancel_type === '') { uncheckClass = 'disable-check'; }
                                                                    if (origin === destination) {
                                                                        return item.map((itemInner, keyInner) => {
                                                                            if (this.props.cancelPopupValue.origin_code === itemInner.origin_code && this.props.cancelPopupValue.destination_code === itemInner.destination_code) {
                                                                                let departure_time = itemInner.departure_time
                                                                                tripType2Segments.push({origin_code:itemInner.origin_code,destination_code:itemInner.destination_code,departure_time:itemInner.departure_time,sector_key : key + '-' + keyInner})

                                                                                return (
                                                                                    <div className="select-sector" key={keyInner}>
                                                                                        <small className={(errors.sectors && touched.sectors) ? 'checkbox error-shadow' : 
                                                                                        ((cancelSector && cancelSector.length && cancelSector.includes(itemInner.origin_code)) || allMatches) && values.cancel_type === 'partial_cancellation' ? 
                                                                                        'checkbox checkbox-disabled' : 'checkbox'}>
                                                                                            <input
                                                                                                className={uncheckClass}
                                                                                                name="sectors"
                                                                                                type="checkbox"
                                                                                                value={itemInner.origin_code + '-' + itemInner.destination_code}
                                                                                                onChange={(event) => this.setTripjackSectorsValue(event, itemInner.origin_code + '-' + itemInner.destination_code, setFieldValue, values, key + '-' + keyInner, itemInner.departure_time,tripType1Passenger)}
                                                                                                onBlur={handleBlur}
                                                                                                disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelSector && cancelSector.length && cancelSector.includes(itemInner.origin_code)) || allMatches}
                                                                                                checked={this.state.checkSegments[key + '-' + keyInner]}
                                                                                            />
                                                                                            <label />
                                                                                        </small>
                                                                                        <span>{itemInner.origin_code} - {itemInner.destination_code} ({dateFormat(departure_time, 'DD MMM YYYY')})</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })

                                                                    }
                                                                    else {
                                                                        tripType2Segments.push({origin_code:origin,destination_code:destination,departure_time:departureTime,sector_key : key + '-' + 0})

                                                                        return (
                                                                            <div className="select-sector" key={key}>
                                                                                <small className={(errors.sectors && touched.sectors) ? 'checkbox error-shadow' : 
                                                                                ((cancelSector && cancelSector.length && cancelSector.includes(origin)) || allMatches) && values.cancel_type === 'partial_cancellation' ?
                                                                                 'checkbox checkbox-disabled' : 'checkbox'}>
                                                                                    <input
                                                                                        className={uncheckClass}
                                                                                        name="sectors"
                                                                                        type="checkbox"
                                                                                        value={origin + '-' + destination}
                                                                                        onChange={(event) => this.setTripjackSectorsValue(event, origin + '-' + destination, setFieldValue, values, key + '-' + key, departureTime,tripType1Passenger)}
                                                                                        onBlur={handleBlur}
                                                                                        disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelSector && cancelSector.length && cancelSector.includes(origin)) || allMatches}
                                                                                        checked={this.state.checkSegments[key + '-' + key]}
                                                                                    />
                                                                                    <label />
                                                                                </small>
                                                                                <span>{origin} - {destination} ({dateFormat(departureTime, 'DD MMM YYYY')})</span>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                               
                                                            }
                                                            if (item && item.length > 2) {
                                                                if(this.props.cancelPopupValue.journey_type === 'inbound' || this.props.cancelPopupValue.journey_type === 'outbound'){
                                                                    let origin = item && item[0].origin_code;
                                                                    let destination = item && item[item.length - 1].destination_code;
                                                                    let departureTime = item && item[0].departure_time;
                                                                    tripType2Segments.push({origin_code:origin,destination_code:destination,departure_time:departureTime,sector_key : key + '-' + 0})

                                                                    let uncheckClass = '';
                                                                    if (values.cancel_type === '') { uncheckClass = 'disable-check'; }
                                                                    return (
                                                                        <div className="select-sector" key={0}>
                                                                            <small className={(errors.sectors && touched.sectors) ? 'checkbox error-shadow' : 
                                                                            ((cancelSector && cancelSector.length && cancelSector.includes(origin)) || allMatches) && values.cancel_type === 'partial_cancellation' ? 
                                                                            'checkbox checkbox-disabled' : 'checkbox'}>
                                                                                <input
                                                                                    className={uncheckClass}
                                                                                    name="sectors"
                                                                                    type="checkbox"
                                                                                    value={origin + '-' + destination}
                                                                                    onChange={(event) => this.setTripjackSectorsValue(event, origin + '-' + destination, setFieldValue, values, key + '-' + 0, departureTime,tripType1Passenger)}
                                                                                    onBlur={handleBlur}
                                                                                    disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelSector && cancelSector.length && cancelSector.includes(origin)) || allMatches}
                                                                                    checked={this.state.checkSegments[key + '-' + 0]}
                                                                                />
                                                                                <label />
                                                                            </small>
                                                                            <span>{origin} - {destination} ({dateFormat(departureTime, 'DD MMM YYYY')})</span>
                                                                        </div>
                                                                    )
                                                                }else{
                                                                    let origin = item && item[0].origin_code;
                                                                    // let destination = item && item[item.length - 1].destination_code;
                                                                    let departureTime = item && item[0].departure_time;
                                                                    let origin_destination = this.props.cancelPopupValue.destination_code;
                                                                    // var index = item.findIndex(segment => segment.origin_code === origin_destination);
                                                                    // let last_departureTime = item && item[index] && item[index].departure_time;
                                                                    let uncheckClass = '';
                                                                    tripType2Segments.push({origin_code:origin,destination_code:origin_destination,departure_time:departureTime,sector_key : key + '-' + 0})

                                                                    if (values.cancel_type === '') { uncheckClass = 'disable-check'; }
                                                                    return (
                                                                        <div className="select-sector" key={0}>
                                                                            <small className={(errors.sectors && touched.sectors) ? 'checkbox error-shadow' : 
                                                                            ((cancelSector && cancelSector.length && cancelSector.includes(origin)) || allMatches) && values.cancel_type === 'partial_cancellation' ? 
                                                                            'checkbox checkbox-disabled' : 'checkbox'}>
                                                                                <input
                                                                                    className={uncheckClass}
                                                                                    name="sectors"
                                                                                    type="checkbox"
                                                                                    value={origin + '-' + origin_destination}
                                                                                    onChange={(event) => this.setTripjackSectorsValue(event, origin + '-' + origin_destination, setFieldValue, values, key + '-' + 0, departureTime,tripType1Passenger)}
                                                                                    onBlur={handleBlur}
                                                                                    disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelSector && cancelSector.length && cancelSector.includes(origin)) || allMatches}
                                                                                    checked={this.state.checkSegments[key + '-' + 0]}
                                                                                />
                                                                                <label />
                                                                            </small>
                                                                            <span>{origin} - {origin_destination} ({dateFormat(departureTime, 'DD MMM YYYY')})</span>
                                                                        </div>
                                                                    )
                                                                }
                                                                
                                                            }
                                                        })
                                                    }
                                                    {
                                                        (this.props.cancelPopupValue.trip_type === 3) && this.props.cancelPopupValue.segments.map((item, key) => {
                                                            return item.map((itemInner, keyInner) => {
                                                                let uncheckClass = '';
                                                                if (values.cancel_type === '')
                                                                    uncheckClass = 'disable-check';
                                                                return (
                                                                    <div className="select-sector" key={keyInner}>
                                                                        <small className={(errors.sectors && touched.sectors) ? 'checkbox error-shadow' :
                                                                            ((cancelSector && cancelSector.length && cancelSector.includes(itemInner.origin_code)) || allMatches) && values.cancel_type === 'partial_cancellation' ? 'checkbox checkbox-disabled' : 'checkbox'}>
                                                                            <input
                                                                                className={uncheckClass}
                                                                                name="sectors"
                                                                                type="checkbox"
                                                                                value={itemInner.origin_code + '-' + itemInner.destination_code}
                                                                                onChange={(event) => this.setTripjackSectorsValue(event, itemInner.origin_code + '-' + itemInner.destination_code, setFieldValue, values, key + '-' + keyInner)}
                                                                                onBlur={handleBlur}
                                                                                disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelSector && cancelSector.length && cancelSector.includes(itemInner.origin_code)) || allMatches}
                                                                                checked={this.state.checkSegments[key + '-' + keyInner]}
                                                                            />
                                                                            <label />
                                                                        </small>
                                                                        <span>{itemInner.origin_code} - {itemInner.destination_code} ({dateFormat(itemInner.departure_time, 'DD MMM YYYY')})</span>
                                                                    </div>
                                                                )
                                                            })

                                                        })
                                                    }
                                                </div>
                                            }
                                            {/* ================================================SECTORS TRIPJACK/KAFILA END=============================================================================== */}

                                            {/* ================================================PASSENGERS TRIPJACK/KAFILA START ONE WAY=============================================================================== */}
                                            {
                                                (this.props.cancelPopupValue.booking_from === 'tripjack' || this.props.cancelPopupValue.booking_from === 'kafila') &&
                                                <div className="block refund-sector">
                                                    <h5>Please select Passenger</h5>
                                                    <div className="select-sector"> <small className="checkbox">
                                                        <input
                                                            className={values.cancel_type === '' ? 'disable-check' : ''}
                                                            name="pax_ticket"
                                                            id="all"
                                                            type="checkbox"
                                                            value="all"
                                                            onChange={(event) => this.setTripJackPaxTicketValue(event, 'all', setFieldValue, values, '',"")}
                                                            onBlur={handleBlur}
                                                            disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || values.cancel_type === 'partial_cancellation' || values.cancel_type === 'cancel_ticket'}
                                                            checked={this.state.checkAllPax}
                                                        />
                                                        <label />
                                                    </small> <span>All</span> </div>
                                                    {
                                                        this.props.cancelPopupValue.passengers.map((item, key) => {
                                                            
                                                            let uncheckClass = '';
                                                            tripType1Passenger.push({pax_id :item.pax_id, sector_key : (key || this.state.checkAllPax) })
                                                            let tripSegments = this.props.cancelPopupValue.trip_type === 2 ? tripType2Segments : tripType1Segments
                                                            if (values.cancel_type === '')
                                                                uncheckClass = 'disable-check';

                                                            return (
                                                                <div className="select-sector" key={key}>
                                                                    <small className={(errors.pax_ticket && touched.pax_ticket) ? 'checkbox error-shadow' : 
                                                                    (cancelTicketId && cancelTicketId.includes(item.first_name + item.last_name)) && values.cancel_type === 'partial_cancellation' ? 
                                                                    'checkbox checkbox-disabled' : 'checkbox'}>
                                                                        <input
                                                                            className={uncheckClass}
                                                                            name="pax_ticket"
                                                                            type="checkbox"
                                                                            value={item.pax_id}
                                                                            onChange={(event) => this.setTripJackPaxTicketValue(event, item.pax_id, setFieldValue, values, key,tripSegments)}
                                                                            onBlur={handleBlur}
                                                                            disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || values.cancel_type === 'cancel_ticket' || (cancelTicketId && cancelTicketId.includes(item.first_name + item.last_name))}
                                                                            checked={this.state.checkPassengers[key] || this.state.checkAllPax}
                                                                        />
                                                                        <label />
                                                                    </small>
                                                                    <span>{item.first_name} {item.last_name}</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            {/* ================================================PASSENGERS TRIPJACK/KAFILA ONE WAY END=============================================================================== */}
                                           
                                            {/* ===========================ROUND=====================SECTORS TRIPJACK/KAFILA START======================ROUND========================================================= */}
                                            {
                                                (this.props.cancelPopupValue.booking_from === 'tripjack' || this.props.cancelPopupValue.booking_from === 'kafila') && this.props.cancelPopupValue.trip_type === 2 && (this.props.cancelPopupValue.segments && this.props.cancelPopupValue.segments[0].length >= 2) &&
                                                this.props.cancelPopupValue.segments.map((item, key) => {
                                                    let origin = item && item[0].origin_code;
                                                    let destination = item && item[item.length - 1].destination_code;
                                                   
                                                    if (origin === destination) {
                                                        return (
                                                            <div class="block return-sector" key={key}>
                                                                <h5>Return Trip</h5>
                                                                <div class="block refund-sector">
                                                                    <h5>Please select Refund Sectors</h5>
                                                                    <div className="select-sector"> <small className="checkbox">
                                                                        <input
                                                                            className={values.cancel_type === '' ? 'disable-check' : ''}
                                                                            name="sectors"
                                                                            type="checkbox"
                                                                            value="all"
                                                                            onChange={(event) => this.setSectorsValueReturn(event, 'all', setFieldValue, values, '')}
                                                                            onBlur={handleBlur}
                                                                            disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || values.cancel_type === 'partial_cancellation'}
                                                                            checked={(this.props.cancelPopupValue.booking_from === "kafila" && values.cancel_type === 'full_cancellation') ? true : this.state.checkAllSectorsReturn}
                                                                        />
                                                                        <label />
                                                                    </small> <span>All</span> </div>
                                                                    {
                                                                        this.props.cancelPopupValue.segments.map((item, key) => {
                                                                            if (item && item.length === 2) {
                                                                                if (this.props.cancelPopupValue.journey_type !== 'inbound' || this.props.cancelPopupValue.journey_type !== 'outbound') {
                                                                                    return item.map((itemInner, keyInner) => {
                                                                                        let uncheckClass = '';
                                                                                        if (values.cancel_type === '')
                                                                                            uncheckClass = 'disable-check';

                                                                                        if (this.props.cancelPopupValue.origin_code === itemInner.destination_code && this.props.cancelPopupValue.destination_code === itemInner.origin_code) {
                                                                                            let destination_code = itemInner.destination_code
                                                                                            let origin_code = itemInner.origin_code
                                                                                            let departure_time = itemInner.departure_time
                                                                                            tripType3Segments.push({origin_code:origin_code,destination_code:destination_code,departure_time:departure_time,sector_key : key + '-' + keyInner})
                                                                                            return (
                                                                                                <div className="select-sector" key={keyInner}>
                                                                                                    <small className={(errors.sectors && touched.sectors) ? 'checkbox error-shadow' : 
                                                                                                    ((cancelSector && cancelSector.length && cancelSector.includes(origin_code)) || allMatches) && values.cancel_type === 'partial_cancellation' ? 
                                                                                                    'checkbox checkbox-disabled' : 'checkbox'}>
                                                                                                        <input
                                                                                                            className={uncheckClass}
                                                                                                            name="sectors"
                                                                                                            type="checkbox"
                                                                                                            value={origin_code + '-' + destination_code}
                                                                                                            onChange={(event) => this.setSectorsValueReturn(event, origin_code + '-' + destination_code, setFieldValue, values, key + '-' + keyInner, departure_time,tripType2Passenger)}
                                                                                                            onBlur={handleBlur}
                                                                                                            disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelSector && cancelSector.length && cancelSector.includes(origin_code)) || allMatches}
                                                                                                            checked={this.state.checkSegmentsReturn[key + '-' + keyInner]}
                                                                                                        />
                                                                                                        <label />
                                                                                                    </small>
                                                                                                    <span>{origin_code} - {destination_code} ({dateFormat(departure_time, 'DD MMM YYYY')})</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            }
                                                                            if (item && item.length > 2) {
                                                                                if (this.props.cancelPopupValue.journey_type !== 'inbound' || this.props.cancelPopupValue.journey_type !== 'outbound') {
                                                                                    // let origin = item && item[0].origin_code;
                                                                                    let destination = item && item[item.length - 1].destination_code;
                                                                                    // let departureTime = item && item[0].departure_time;
                                                                                    let origin_destination = this.props.cancelPopupValue.destination_code;
                                                                                    var index = item.findIndex(segment => segment.origin_code === origin_destination);
                                                                                    let last_departureTime = item && item[index] && item[index].departure_time;
                                                                                    tripType3Segments.push({origin_code:origin_destination,destination_code:destination,departure_time:last_departureTime,sector_key : key + '-' + 1})
                                                                                    let uncheckClass = '';
                                                                                    if (values.cancel_type === '') { uncheckClass = 'disable-check'; }
                                                                                    return (
                                                                                        <div className="select-sector" key={1}>
                                                                                            <small className={(errors.sectors && touched.sectors) ? 'checkbox error-shadow' : 
                                                                                            ((cancelSector && cancelSector.length && cancelSector.includes(origin_destination)) || allMatches) && values.cancel_type === 'partial_cancellation' ? 
                                                                                            'checkbox checkbox-disabled' : 'checkbox'}>
                                                                                                <input
                                                                                                    className={uncheckClass}
                                                                                                    name="sectors"
                                                                                                    type="checkbox"
                                                                                                    value={origin_destination + '-' + destination}
                                                                                                    onChange={(event) => this.setSectorsValueReturn(event, origin_destination + '-' + destination, setFieldValue, values, key + '-' + 1, last_departureTime,tripType2Passenger)}
                                                                                                    onBlur={handleBlur}
                                                                                                    disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || (cancelSector && cancelSector.length && cancelSector.includes(origin_destination)) || allMatches}
                                                                                                    checked={this.state.checkSegmentsReturn[key + '-' + 1]}
                                                                                                />
                                                                                                <label />
                                                                                            </small>
                                                                                            <span>{origin_destination} - {destination} ({dateFormat(last_departureTime, 'DD MMM YYYY')})</span>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }

                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="block refund-sector">
                                                                    <h5>Please select Passenger</h5>
                                                                    <div className="select-sector"> <small className="checkbox">
                                                                        <input
                                                                            className={values.cancel_type === '' ? 'disable-check' : ''}
                                                                            name="pax_ticket"
                                                                            id="all"
                                                                            type="checkbox"
                                                                            value="all"
                                                                            onChange={(event) => this.setPaxTicketValueReturn(event, 'all', setFieldValue, values, '')}
                                                                            onBlur={handleBlur}
                                                                            disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || values.cancel_type === 'partial_cancellation' || values.cancel_type === 'cancel_ticket'}
                                                                            checked={this.state.checkAllPaxReturn}
                                                                        />
                                                                        <label />
                                                                    </small> <span>All</span> </div>
                                                                    {
                                                                        this.props.cancelPopupValue.passengers.map((item, key) => {
                                                                            let uncheckClass = '';
                                                                            tripType2Passenger.push({pax_id :item.pax_id, sector_key : (key || this.state.checkAllPaxReturn) })
                                                                            let tripSegments = this.props.cancelPopupValue.trip_type === 2 ? tripType3Segments : tripType2Segments
                                                                            if (values.cancel_type === '')
                                                                                uncheckClass = 'disable-check';

                                                                            return (
                                                                                <div className="select-sector" key={key}>
                                                                                    <small className={errors.pax_ticket && touched.pax_ticket ? 'checkbox error-shadow' : (cancelTicketIdRound && cancelTicketIdRound.includes(item.first_name + item.last_name) && values.cancel_type === 'partial_cancellation') ? 
                                                                                    'checkbox checkbox-disabled' : 'checkbox'}>
                                                                                        <input
                                                                                            className={uncheckClass}
                                                                                            name="pax_ticket"
                                                                                            type="checkbox"
                                                                                            value={item.pax_id}
                                                                                            onChange={(event) => this.setPaxTicketValueReturn(event, item.pax_id, setFieldValue, values, key,tripSegments)}
                                                                                            onBlur={handleBlur}
                                                                                            disabled={values.cancel_type === '' || values.cancel_type === 'full_cancellation' || values.cancel_type === 'cancel_ticket' ||( cancelTicketIdRound && cancelTicketIdRound.includes(item.first_name + item.last_name))}
                                                                                            checked={this.state.checkPassengersReturn[key] || this.state.checkAllPaxReturn}
                                                                                        />
                                                                                        <label />
                                                                                    </small>
                                                                                    <span>{item.first_name} {item.last_name}</span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                })
                                            }
                                            {/* ===========================ROUND=====================SECTORS TRIPJACK/KAFILA END======================ROUND========================================================= */}

                                          <div className="block remark-sector">
                                            <h5>Please enter remarks<span className="red-error">*</span></h5>
                                            <textarea className={errors.remark && touched.remark ? "error-shadow" : ''} name='remark' value={values.remark}
                                                onChange={(event) => {
                                                    setFieldValue('pnr', this.props.cancelPopupValue.pnr);
                                                    setFieldValue('remark', event.target.value);
                                                }} onBlur={handleBlur} />
                                        </div>
                                            
                                        
                                       
                                        
                                        
                                        <div className="block note-sector">
                                            <h5>Note</h5>
                                            <ul>
                                                <li>Partial Refund will be processed offline.</li>
                                                <li>In case of Infant booking, cancellation will be processed offline.</li>
                                                <li>In case of One sector to be cancel, please send the offline request.</li>
                                                <li>In case of Flight cancelation/ flight reschedule, please select flight cancelled.</li>
                                                <li>Cancellation Charges cannot be retrieved for Partial Cancelled Booking.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="block note-sector">
                                    <p className="red-text m-3">Auto cancellation enabled, please reverify the details before proceeding for cancellation</p>
                                    <div className="select-sector ml-3"> <small className="checkbox">
                                        <input
                                            name="termAndCondition"
                                            type="checkbox"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        // disabled={values.cancel_type === 'cancel_ticket' || values.cancel_type === 'full_cancellation'}
                                        // checked={values.termAndCondition}
                                        />
                                        <label />
                                        { /* eslint-disable-next-line */}
                                    </small> <span>I Accept  <a href="javascript:void(0);" onClick={this.redirectToTermsAndCondition}>Terms and Conditions</a></span>
                                        {
                                            (errors.termAndCondition && touched.termAndCondition) &&
                                            <p className="red-text">{errors.termAndCondition}</p>
                                        }
                                    </div>
                                </div>
                                <div className="popup-footer">
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className={`btn btn-orange loading-btn ${(this.props.cancelBookingDetail.loading || this.props.cancelTripJackBookingDetail.loading || this.props.kafila_cancel_booking_details.loading || this.props.cancelRiyaBookingDetail.loading) ? 'disabled' : ''}`}
                                        onClick={(e) =>{
                                            if (!(this.props.cancelBookingDetail.loading || this.props.cancelTripJackBookingDetail.loading || this.props.kafila_cancel_booking_details.loading || this.props.cancelRiyaBookingDetail.loading)) {
                                                handleSubmit();
                                            }
                                        }}><span>Send Request</span>
                                        {
                                           ( this.props.cancelBookingDetail.loading || this.props.cancelTripJackBookingDetail.loading || this.props.kafila_cancel_booking_details.loading || this.props.cancelRiyaBookingDetail.loading )
                                                ?
                                                <div className="flight-animated"><label><span><small></small><small></small><small></small></span>
                                                    <img src="/assets/images/flight-continue.svg" alt="" /></label></div>
                                                : ''
                                        }
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="popup-overlay" />
                </div>
                {/* Popup for credit confirmation */}
                <div className={`popup-crad confirmation-popup ${showConfirmationPopup ? 'show' : ''}`}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>Cancellation Confirmation</h2>
                                <div className="popup-close"><span className="icon icon-cross" onClick={() => this.handleConfirmationPopup(false)} /></div>
                            </div>
                            <div className="popup-body popup-scorll">
                                <div className="inner-confirmation">
                                    <p>Are you sure you want to continue?</p>
                                    <div className="popup-footer">
                                        <button className="btn btn-orange" onClick={() => this.cancelTicket(values, resetForm)}>Yes</button>
                                        <button className="btn btn-grey" onClick={() => this.handleConfirmationPopup(false)}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay" />
                </div>

            </React.Fragment>
        )
    }
    // componentDidMount(){
    //     if (this.props.cancelPopupValue && this.props.cancelPopupValue.booking_from === "kafila") {
    //         this.props.kafilaCancelReasonSubmit()
    //     }
    // }
    render() {
        return (
            <Formik
                initialValues={this.initialValuesFlight}
                onSubmit={() => this.handleConfirmationPopup(true)}
                validationSchema={this.cancelValidator}
                render={this.renderForm}
            />
        )

    }
}

export default FlightCancellationComponent
