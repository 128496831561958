import React from 'react';
import { currencyFormat } from '../../../shared/commonHelper';
import * as hotelHelper from '../../../shared/hotelHelper';

class HotelFareSummaryComponent extends React.Component {
    constructor(){
        super()
        this.state={
            showhidePopup : null,
            rateBreakUpData : null,
            showOfferedPrice  : null
        }
    }
    //Show offered price and discount
    offeredPriceDisplay=()=>{
        this.setState({
            showOfferedPrice : this.state.showOfferedPrice ? false : true
        })
    }
    //Function to fill week days values in rate breakup
    fillWeekDaysValues = (data) => {
        let output = [];
        if(data){
            for(let i = 0;i<=6;i++){
                if(data[i]){
                    output.push(<span key={i}>{data[i].amount}</span>);
                }else{
                    output.push(<span key={i}>-</span>);
                }
            }
        }
        return output;
    }
    showRateBreakup = ()=>{
        let oneRoomData = null;
        let price = null;
        if(!this.props.booking_value){
            oneRoomData = this.state.rateBreakUpData && hotelHelper.getDayRateBreakUpWeekWise(this.state.rateBreakUpData.day_rates);
            price = this.state.rateBreakUpData && this.state.rateBreakUpData.price_modified;
        }else{
            let noOfNights = Number(this.props.searchQuery.no_of_nights);
            let startDay = hotelHelper.getDayFromDate(this.props.searchQuery.check_in_date);
            let oneRoomPrice = null;
            price = this.props.fareDetails;
            oneRoomPrice = Number(price.room_price)/Number(noOfNights);
            let finalStartDay = startDay !== 0 ? Number(startDay) - 1 : Number(startDay);
            oneRoomData = hotelHelper.getDayRateFromBookingDetails(finalStartDay,noOfNights,oneRoomPrice);
        }
        return (
            !this.props.booking_value
            ? 
            <React.Fragment>
                <div className="week-summary">
                    <div className="table-week-day">
                        <ul className="day-list">
                            <li></li>
                            <li>Sun</li>
                            <li>Mon</li>
                            <li>Tue</li>
                            <li>Wed</li>
                            <li>Thu</li>
                            <li>Fri</li>
                            <li>Sat</li>
                        </ul>
                    </div>
                    <div className="table-week-list">
                        <ul className="list-week">
                            {
                                oneRoomData && oneRoomData.map((item,key) => {
                                    return (
                                        <li key={key}>
                                            <span className="head-week">Week {Number(key+1)}</span>
                                            {
                                                this.fillWeekDaysValues(item)
                                            }                                      
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>    
                <div className="rate-summary">
                <div className="popup-head">
                    <h2>Rate Summary</h2>
                    <div className="popup-close"><span className="icon icon-cross" onClick={() => this.setState({
                        showhidePopup : false,
                        rateBreakUpData : null
                    })}></span></div>
                </div>
                <div className="rate-price-list">
                    <ul className="list-price">
                    <li> <span>Currency</span> <small>{this.props.currency_code}</small> </li>
                    <li> <span>Total</span> <small>{price && currencyFormat(price.room_price)}</small> </li>
                    <li> <span>Tax(+)</span> <small>{price && currencyFormat(price.tax)}</small> </li>
                    <li> <span>Other Tax(+)</span> <small>{price && currencyFormat(price.other_tax)}</small> </li>
                    <li> <span>Extra Guest Charges(+)</span> <small>{price && currencyFormat(price.extra_guest_charge)}</small> </li>
                    <li> <span>Service Charges (+)</span> <small>{price && currencyFormat(price.service_charge)}</small> </li>
                    <li> <span>Other Charges (+)</span> <small>{price && currencyFormat(price.other_charges)}</small> </li>
                    <li> <span>GST (+)</span> <small>{price && currencyFormat(Number(price.sgst) + Number(price.igst) + Number(price.cgst))}</small> </li>
                    {/* <li> <span>No. of Rooms</span> <small>{this.props.noOfRooms}</small> </li> */}
                    <li> <span>Total Price</span> <small>{price && currencyFormat(price.published_price)}</small> </li>
                    </ul>
                </div>
            </div>  
        </React.Fragment>
            : 
            <React.Fragment>
                <div className="week-summary">
                    <div className="table-week-day">
                        <ul className="day-list">
                            <li></li>
                            <li>Sun</li>
                            <li>Mon</li>
                            <li>Tue</li>
                            <li>Wed</li>
                            <li>Thu</li>
                            <li>Fri</li>
                            <li>Sat</li>
                        </ul>
                    </div>
                    <div className="table-week-list">
                        <ul className="list-week">
                            {
                                oneRoomData && oneRoomData.map((item,key) => {
                                    return (
                                        <li key={key}>
                                            <span className="head-week">Week {Number(key+1)}</span>
                                            {
                                                this.fillWeekDaysValues(item)
                                            }                                      
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>    
                <div className="rate-summary">
                <div className="popup-head">
                    <h2>Rate Summary</h2>
                    <div className="popup-close"><span className="icon icon-cross" onClick={() => this.setState({
                        showhidePopup : false,
                        rateBreakUpData : null
                    })}></span></div>
                </div>
                <div className="rate-price-list">
                    <ul className="list-price">
                    <li> <span>Currency</span> <small>{this.props.currency_code}</small> </li>
                    <li> <span>Total</span> <small>{price && currencyFormat(price.room_price)}</small> </li>
                    <li> <span>Tax(+)</span> <small>{price && currencyFormat(price.tax)}</small> </li>
                    <li> <span>Other Tax(+)</span> <small>{price && currencyFormat(price.other_tax)}</small> </li>
                    <li> <span>Extra Guest Charges(+)</span> <small>{price && currencyFormat(price.extra_guest_charge)}</small> </li>
                    <li> <span>Service Charges (+)</span> <small>{price && currencyFormat(price.service_charge)}</small> </li>
                    <li> <span>Other Charges (+)</span> <small>{price && currencyFormat(price.other_charges)}</small> </li>
                    <li> <span>GST (+)</span> <small>{price && currencyFormat(Number(price.sgst) + Number(price.igst) + Number(price.cgst))}</small> </li>
                    {/* <li> <span>No. of Rooms</span> <small>{this.props.noOfRooms}</small> </li> */}
                    <li> <span>Total Price</span> <small>{price && currencyFormat(price.published_price)}</small> </li>
                    </ul>
                </div>
            </div>  
        </React.Fragment>
        )
        
    }
    render() {

        const getAverageAge = () => {
            let sum = 0
            this.props.roomDataTripjack && this.props.roomDataTripjack.map((item1, key1) => {
                for (let i = 0; i < (item1 && item1.room_combination && item1.room_combination.length); i++) {
                    sum += (item1 && item1.room_combination && item1.room_combination[i] && item1.room_combination[i].price_modified && item1.room_combination[i].price_modified.published_price)
                }
            })
            return sum
        }

        const getTds = () => {
            let tds = 0
            this.props.roomDataTripjack && this.props.roomDataTripjack.map((item1, key1) => {
                for (let i = 0; i < (item1 && item1.room_combination && item1.room_combination.length); i++) {
                    tds += (item1 && item1.room_combination && item1.room_combination[i] && item1.room_combination[i].price_modified && item1.room_combination[i].price_modified.tds)
                }
            })
            return tds
        }
        const getDiscount = () => {
            let agentDiscount = 0
            this.props.roomDataTripjack && this.props.roomDataTripjack.map((item1, key1) => {
                for (let i = 0; i < (item1 && item1.room_combination && item1.room_combination.length); i++) {
                    agentDiscount += (item1 && item1.room_combination && item1.room_combination[i] && item1.room_combination[i].price_modified && item1.room_combination[i].price_modified.agent_discount)
                }
            })
            return agentDiscount
        }
        const getNetPaybaleAmt = () => {
            let offeredPrice = 0
            this.props.roomDataTripjack && this.props.roomDataTripjack.map((item1, key1) => {
                for (let i = 0; i < (item1 && item1.room_combination && item1.room_combination.length); i++) {
                    offeredPrice += (item1 && item1.room_combination && item1.room_combination[i] && item1.room_combination[i].price_modified && item1.room_combination[i].price_modified.offered_price)
                }
            })
            return offeredPrice
        }

         return (
            <React.Fragment>
                <div className="title-fare">
                    { /* eslint-disable-next-line */}
                    <a href="javascript:void(0);" className="close-fare icon icon-arrow-left2"></a>
                    <h3 className="fare-title">Fare Summary 
                        <span className="offer-amount-icon btn btn-blue net-fare-button-round" onClick={() => this.offeredPriceDisplay()}>{this.state.showOfferedPrice ? 'Hide Net Fare ' : 'Show Net Fare '} <i><img src="assets/images/offer-icon.svg" alt="" /></i></span>
                    </h3>
                </div>
                <div className="fare-outer">
                {
                     this.props.loading
                     ?
                     <div className="loader relative-loader white-ring" style={{ display: 'block' }}>
                         <div className="loader-content">
                             <div className="loader-ring blue-ring"> <span></span> </div>
                         </div>
                     </div>
                     :
                     <React.Fragment>
                        {
                            this.props.supplier_type !== 'tripjack' && this.props.roomData && this.props.roomData.map((item,key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <div className="cols">
                                            <h4>
                                            <span className="pull-right link-rate">
                                            { /* eslint-disable-next-line */}
                                            <a href="javascript:void(0);" onClick={() => this.setState({
                                                showhidePopup : true,
                                                rateBreakUpData : item
                                            })}>Rate Breakup</a>
                                            </span>
                                            <samp className="heading-title">{item.room_type_name}</samp>
                                            </h4>
                                            <ul>
                                                {
                                                    !this.props.booking_value &&
                                                        <li> <span className="pull-left">Rate</span> <span className="pull-right"><i className="icon icon-rupee"></i>{currencyFormat(item.price_modified.published_price)}</span> </li>
                                                }
                                                {
                                                    this.props.booking_value &&
                                                        <li> <span className="pull-left">Rate</span> <span className="pull-right"><i className="icon icon-rupee"></i>{currencyFormat(item.price.published_price)}</span> </li>
                                                }
                                                <li> <span className="pull-left">No. of Rooms</span> <span className="pull-right">1</span> </li>
                                            </ul>
                                            {
                                                !this.props.booking_value &&
                                                <div className="total-amount"> 
                                                    <span className="pull-left">Total ({item.price_modified.published_price+' * '+1})</span> 
                                                    <span className="pull-right">{currencyFormat(item.price_modified.published_price * 1)}</span>
                                                </div>
                                            }
                                            {
                                                this.props.booking_value &&
                                                <div className="total-amount"> 
                                                    <span className="pull-left">Total ({item.price.published_price+' * '+1})</span> 
                                                    <span className="pull-right">{currencyFormat(item.price.published_price * 1)}</span>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                        {
                            this.props.supplier_type === 'tripjack' && this.props.roomDataTripjack && this.props.roomDataTripjack.map((item1, key1) => {
                                return (
                                    item1 && item1.room_combination.map((item, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <div className="cols">
                                                    <h4>
                                                        <span className="pull-right link-rate">
                                                            { /* eslint-disable-next-line */}
                                                            <a href="javascript:void(0);" onClick={() => this.setState({
                                                                showhidePopup: true,
                                                                rateBreakUpData: item
                                                            })}>Rate Breakup</a>
                                                        </span>
                                                        <samp className="heading-title">{item.room_type_name}</samp>
                                                    </h4>
                                                    <ul>
                                                        {
                                                            !this.props.booking_value &&
                                                            <li> <span className="pull-left">Rate</span> <span className="pull-right"><i className="icon icon-rupee"></i>{currencyFormat(item && item.price_modified && item.price_modified.published_price)}</span> </li>
                                                        }
                                                        {
                                                            this.props.booking_value &&
                                                            <li> <span className="pull-left">Rate</span> <span className="pull-right"><i className="icon icon-rupee"></i>{currencyFormat(item && item.price_modified && item.price.published_price)}</span> </li>
                                                        }
                                                        <li> <span className="pull-left">No. of Rooms</span> <span className="pull-right">1</span> </li>
                                                    </ul>
                                                    {
                                                        !this.props.booking_value &&
                                                        <div className="total-amount">
                                                            <span className="pull-left">Total ({item && item.price_modified && item.price_modified.published_price + ' * ' + 1})</span>
                                                            <span className="pull-right">{currencyFormat(item && item.price_modified && item.price_modified.published_price * 1)}</span>
                                                        </div>
                                                    }
                                                    {
                                                        this.props.booking_value &&
                                                        <div className="total-amount">
                                                            <span className="pull-left">Total ({item && item.price && item.price.published_price + ' * ' + 1})</span>
                                                            <span className="pull-right">{currencyFormat(item && item.price && item.price.published_price * 1)}</span>
                                                        </div>
                                                    }
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                )

                            })
                        }
                        {/* <div className="cols">
                        <h4>TAXES & SURCHARGES</h4>
                            <ul>
                                <li> <span className="pull-left">GST</span> <span className="pull-right"><i className="icon icon-rupee"></i>{this.props.fareDetails && currencyFormat(Number(this.props.fareDetails.sgst) + 
                                    Number(this.props.fareDetails.cgst) + Number(this.props.fareDetails.igst))}</span> </li>
                            </ul>
                            <div className="total-amount"> <span className="pull-left">Total Taxes & Surcharges</span> <span className="pull-right"><i className="icon icon-rupee"></i> {this.props.fareDetails && currencyFormat(Number(this.props.fareDetails.sgst) + 
                                    Number(this.props.fareDetails.cgst) + Number(this.props.fareDetails.igst))}</span> </div>
                        </div> */}
                        {/* <div className="cols">
                        <h4>TOTAL SERVICES</h4>
                        <ul>
                            <li> <span className="pull-left">Insurance</span> <span className="pull-right"><i className="icon icon-rupee"></i> 747</span> </li>
                            <li> <span className="pull-left">Charity</span> <span className="pull-right"><i className="icon icon-rupee"></i> 5</span> </li>
                        </ul>
                        <div className="total-amount"> <span className="pull-left">Total Base Fare</span> <span className="pull-right"><i className="icon icon-rupee"></i> 752</span> </div>
                        </div> */}
                        {
                            this.props.supplier_type !== 'tripjack' &&
                            <div className="grand-total"> <span className="pull-left">Grand Total</span> <span className="pull-right"><i className="icon icon-rupee"></i> {this.props.fareDetails && currencyFormat(this.props.fareDetails.published_price)}</span> </div>
                        }
                        {
                            this.props.supplier_type === 'tripjack' &&
                            <div className="grand-total"> <span className="pull-left">Grand Total</span> <span className="pull-right"><i className="icon icon-rupee"></i> {currencyFormat(getAverageAge())}</span> </div>
                        }
                        {
                            this.props.supplier_type !== 'tripjack' &&
                            <div className="cols net-booking" style={{ display: this.state.showOfferedPrice ? 'block' : 'none' }}>
                                <ul>
                                    <li> <span className="pull-left">TDS </span> <span className="pull-right"><i className="icon icon-rupee" />{this.props.fareDetails && currencyFormat(this.props.fareDetails.tds)}</span> </li>
                                    <li> <span className="pull-left">Discount(-) </span> <span className="pull-right"><i className="icon icon-rupee" />{this.props.fareDetails && currencyFormat(Number(this.props.fareDetails.agent_discount))}</span> </li>
                                </ul>
                                <div className="grand-total"> <span className="pull-left">Net Payable Amount </span> <span className="pull-right"><i className="icon icon-rupee" /> {this.props.fareDetails && currencyFormat(this.props.fareDetails.offered_price)}</span> </div>
                            </div>}
                        {
                            this.props.supplier_type === 'tripjack' &&
                            <div className="cols net-booking" style={{ display: this.state.showOfferedPrice ? 'block' : 'none' }}>
                                <ul>
                                    <li> <span className="pull-left">TDS </span> <span className="pull-right"><i className="icon icon-rupee" />{currencyFormat(getTds())}</span> </li>
                                    <li> <span className="pull-left">Discount(-) </span> <span className="pull-right"><i className="icon icon-rupee" />{currencyFormat(Number(getDiscount()))}</span> </li>
                                </ul>
                                <div className="grand-total"> <span className="pull-left">Net Payable Amount </span> <span className="pull-right"><i className="icon icon-rupee" /> {currencyFormat(getNetPaybaleAmt())}</span> </div>
                            </div>
                        }
                        <div className={`popup-crad rate-summary-popup ${this.state.showhidePopup?'show':''}`}>
                        <div className="popup-outer">
                            <div className="popup-content">
                                <div className="popup-scorll">
                                    <div className="summary-popup-mid">
                                        {
                                            this.state.showhidePopup &&
                                            this.showRateBreakup()
                                        }
                                    </div>
                                    </div>
                                </div>
                            </div>
                        <div className="popup-overlay"></div>
                        </div>
                     </React.Fragment>
                }
                </div>
            </React.Fragment>
        )
    }
}

export default HotelFareSummaryComponent