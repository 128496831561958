import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import HeaderWithoutSession from './HeaderWithoutSession';
import { currencyFormat, onClickAnchor } from '../../shared/commonHelper';
import WalletBox from './WallletBox';
import WalletBoxMobile from './WalletBoxMobile';
import WalletBoxInner from './WalletBoxInner';

class HeaderWithSession extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreditCash: null,
            showNotifications: false,
            showMenu : false
        };
    }
    //Show credit and cash balance
    showCreditCashBalance = () => {
        this.setState({
            showCreditCash: this.state.showCreditCash ? false : true
        })
    }

    menuClick = (value) => {
        this.setState({
            showMenu :value
        })
    }

    render() {

        let productAccess = [];
        let productAccessCount = 0;
        let ProductAccess = null;
        if (this.props.agentProductAccess.response) {
            for (let i = 0; i < this.props.agentProductAccess.response.user_access_info.length; i++) {
                productAccessCount = productAccessCount + 1;
                if (this.props.agentProductAccess.response.user_access_info[i].product_url === 'train-booking') {
                    ProductAccess = '/jfrb/login';
                }
                productAccess.push(this.props.agentProductAccess.response.user_access_info[i].product_code);
            }
        }
        var hideHeaderInfo;
        var notificationClass = '';
        if (this.props.url === '/create-report' || this.props.url === '/view-flight-bookings' || this.props.url === '/booking' || this.props.url === '/my-bookings' || this.props.url === '/top-up' || this.props.url === '/my-account' || this.props.url === '/change-password' || this.props.url === '/my-transactions' || this.props.url === '/mark-up-tool' || this.props.url === '/deposits' || this.props.url === '/view-deposit' || this.props.url === '/deposit-request' || this.props.url === '/credits' || this.props.url === '/view-credits' || this.props.url === '/credit-request' || this.props.url === '/account-statement' || this.props.url === '/bank-detail' || this.props.url === '/flight-type-list' || this.props.url === '/domestic-markup-tool' || this.props.url === '/international-markup-tool' || this.props.url === '/agent-info' || this.props.url === '/booking-calendar') {
            hideHeaderInfo = "none";
            notificationClass = "header-right notification-account";
        } else {
            hideHeaderInfo = "block";
            notificationClass = "header-right";
        }

        var showheader;
        if ((this.props.url === '/flight-search' || this.props.url === '/hotel-search') && this.props.url !== '/' && this.props.url !== '/login' && this.props.url !== '/verify-otp')
            showheader = 'dark';
        else if (this.props.url !== '/' && this.props.url !== '/login' && this.props.url !== '/verify-otp')
            showheader = 'light';
        else
            showheader = 'login' || this.props.url !== '/verify-otp'
        let user_type_id = this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.user_type_id;
        //let cashBalance = this.props.accountResponse.myAccountResponse.response && currencyFormat(this.props.accountResponse.myAccountResponse.response.cash_balance);
        let redictionURL = '/flight-search';

        if (productAccessCount === 1 && ProductAccess === '/jfrb/login') {
            redictionURL = 'https://www.agent.irctc.co.in/nget/train-search';
        }
        return (
            <React.Fragment>
                {
                    (showheader === 'light') &&
                    <div className={this.props.url === "/jfrb/login" ? "login-header" : "header-inner"}>
                        <div className="container"> { /* eslint-disable-next-line */}
                            <a href={redictionURL} target="_blank">
                                <h1 className="logo-figure">
                                    {
                                        this.props.url === "/jfrb/login"
                                            ? <img src={this.props.settingResponse.response && this.props.settingResponse.response.website_logo_light} alt="" />
                                            : <img src={this.props.settingResponse.response && this.props.settingResponse.response.website_logo_dark} alt="" />
                                    }
                                </h1>
                            </a>
                            {
                                (window.location.pathname !== '/my-account' && window.location.pathname !== '/change-password' && window.location.pathname !== '/my-bookings' &&
                                window.location.pathname !== '/request-bookings' && window.location.pathname !== '/mark-up-tool' && window.location.pathname !== '/deposits' &&
                                window.location.pathname !== '/bank-detail' && window.location.pathname !== '/account-statement' && window.location.pathname !== '/create-report' &&
                                 window.location.pathname !== '/booking-calendar' && window.location.pathname !== '/booking-calendar'&& window.location.pathname !== '/view-flight-booking'
                                 && window.location.pathname !== '/booking'&& window.location.pathname !== '/view-train-booking'&& window.location.pathname !== '/view-hotel-booking'
                                 && window.location.pathname !== '/request-flight-booking'&& window.location.pathname !== '/flight-type-list'&& window.location.pathname !== '/domestic-markup-tool'
                                 && window.location.pathname !== '/international-markup-tool'&& window.location.pathname !== '/deposits'&& window.location.pathname !== '/view-deposit'
                                 && window.location.pathname !== '/deposit-request'&& window.location.pathname !== '/bank-detail'&& window.location.pathname !== '/account-statement'
                                 && window.location.pathname !== '/create-report'&& window.location.pathname !== '/booking-calendar'&& window.location.pathname !== '/view-topup'&& 
                                 window.location.pathname !== '/top-up' && window.location.pathname !== '/topup-request'&& window.location.pathname !== '/booking-error'&& window.location.pathname !== '/vendor'
                                 && window.location.pathname !== '/hotel-booking'&& window.location.pathname !== '/hotel-type-list'&& window.location.pathname !== '/add-vendor-booking'
                                 && window.location.pathname !== '/view-booking-detail' && window.location.pathname !== '/edit-vendor-booking' && window.location.pathname !== '/pending-booking'
                                 && window.location.pathname !== '/agent-info'&& window.location.pathname !== '/credits'&& window.location.pathname !== '/credit-request'&& window.location.pathname !== '/view-credits'
                                 ) && <div className='header-center'>
                                <WalletBoxInner 
                                {...this.props}
                                />
                                </div>
                            }
                            
                            <div className={notificationClass} style={{ display: hideHeaderInfo }}>
                                {/*<div className="credit-block" style={{ display: (user_type_id !== 1) ? 'block' : 'none' }}>
                                    <div className="col-cr">
                                        <label>Cash Balance</label>
                                        <Link to="/deposit-request">
                                            <div className={`bl-info ${parseInt(cashBalance) > 0 ? 'green-col':'red-col' }`}> 
                                                <small><i className="fa fa-inr" aria-hidden="true"></i></small> 
                                                <span>{this.props.accountResponse.myAccountResponse.response && currencyFormat(this.props.accountResponse.myAccountResponse.response.cash_balance)}</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-cr">
                                        <label>Credit Limit</label>
                                        <Link to="/credit-request">
                                            <div className="bl-info green-col">
                                                <small><i className="fa fa-inr" aria-hidden="true"></i> </small>
                                                <span>{this.props.accountResponse.myAccountResponse.response && currencyFormat(this.props.accountResponse.myAccountResponse.response.credit_limit)}</span>
                                                {this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.credit_expiry_date ?
                                                    <div className="tooltip-date"><span>{"Valid till " + getDateWithOrdinal(this.props.accountResponse.myAccountResponse.response.credit_expiry_date, 'DD MMM YYYY')}</span></div> : ""}
                                            </div>
                                        </Link>
                                    </div>
                                </div>*/}
                                
                                {(window.location.pathname !== '/my-account' && window.location.pathname !== '/change-password' && window.location.pathname !== '/my-bookings' &&
                                window.location.pathname !== '/request-bookings' && window.location.pathname !== '/mark-up-tool' && window.location.pathname !== '/deposits' &&
                                window.location.pathname !== '/bank-detail' && window.location.pathname !== '/account-statement' && window.location.pathname !== '/create-report' &&
                                 window.location.pathname !== '/booking-calendar' && window.location.pathname !== '/booking-calendar'&& window.location.pathname !== '/view-flight-booking'
                                 && window.location.pathname !== '/booking'&& window.location.pathname !== '/view-train-booking'&& window.location.pathname !== '/view-hotel-booking'
                                 && window.location.pathname !== '/request-flight-booking'&& window.location.pathname !== '/flight-type-list'&& window.location.pathname !== '/domestic-markup-tool'
                                 && window.location.pathname !== '/international-markup-tool'&& window.location.pathname !== '/deposits'&& window.location.pathname !== '/view-deposit'
                                 && window.location.pathname !== '/deposit-request'&& window.location.pathname !== '/bank-detail'&& window.location.pathname !== '/account-statement'
                                 && window.location.pathname !== '/create-report'&& window.location.pathname !== '/booking-calendar'&& window.location.pathname !== '/view-topup'&& 
                                 window.location.pathname !== '/top-up' && window.location.pathname !== '/topup-request'&& window.location.pathname !== '/booking-error'&& window.location.pathname !== '/vendor'
                                 && window.location.pathname !== '/hotel-booking'&& window.location.pathname !== '/hotel-type-list'&& window.location.pathname !== '/add-vendor-booking'
                                 && window.location.pathname !== '/view-booking-detail' && window.location.pathname !== '/edit-vendor-booking' && window.location.pathname !== '/pending-booking'
                                 && window.location.pathname !== '/agent-info'&& window.location.pathname !== '/credits'&& window.location.pathname !== '/credit-request'&& window.location.pathname !== '/view-credits'
                                 ) &&
                                <div className="user-detail">
                                    <ul>
                                        <li> { /* eslint-disable-next-line */}
                                            <a href="#" onClick={onClickAnchor}>
                                                <figure>
                                                    <img src={this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.company_logo ? this.props.accountResponse.myAccountResponse.response.company_logo : 'assets/images/no-image-icon.png'} alt="" />
                                                </figure>
                                                <span style={{ color: this.props.url !== "/jfrb/login" ? "#000" : '#fff' }}>Welcome </span><i className="fa fa-caret-down" aria-hidden="true"></i></a>
                                            <ul className="submenu">
                                                <div className="inner-submenu">
                                                    <li>
                                                        { /* eslint-disable-next-line */}
                                                        <a href="javascript:void(0);"><i className="fa fa-user"></i><span dangerouslySetInnerHTML={{ __html: this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.company_name }}></span></a>

                                                    </li>
                                                    {
                                                        this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.agency_code && this.props.accountResponse.myAccountResponse.response.agency_code !== '' &&
                                                        <li>
                                                            { /* eslint-disable-next-line */}
                                                            <a href="javascript:void(0);"><i className="fa fa-qrcode"></i><span dangerouslySetInnerHTML={{ __html: this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.agency_code }}></span></a>
                                                        </li>
                                                    }
                                                    
                                                    <li> { /* eslint-disable-next-line */}<Link to='/my-account'><i className="fa fa-lock"></i>My Account</Link></li>
                                                    
                                                    
                                                    <li>
                                                        { /* eslint-disable-next-line */}
                                                        <a href="javascript:void(0);" onClick={() => { this.props.logoutAction() }}><i className="fa fa-sign-out" />Logout </a>
                                                    </li>
                                                </div>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
    }
                            </div>
                            
                        </div>
                    </div>
                }
                {
                    (showheader === 'dark') &&
                    <div className="login-header">
                        <div className="container">
                            { /* eslint-disable-next-line */}
                            <a href="/flight-search">
                                <h1 className="logo-figure"><img src={this.props.settingResponse.response && this.props.settingResponse.response.website_logo_light} alt="" /></h1>
                            </a>
                            <div class="header-center">
                                <WalletBoxMobile {...this.props}/>
                            </div>
                            <div className="header-right">
                                <WalletBox {...this.props}/>
                                { /* eslint-disable-next-line */}
                                <a href="javascript:void(0);" className={`toggle-icon ${this.state.showMenu ? `active` : ``}`} onClick={() => { this.menuClick(true) }} ><span /> <span /> <span /></a>
                                <div className={`slide-nav show-slide ${this.state.showMenu ? `show_menu` : ``}`}>
                                    <nav>
                                        <div className="block">
                                            <span>Menu</span>
                                            { /* eslint-disable-next-line */}
                                            <a href="javascript:void(0);" className="toggle-icon" onClick={() => { this.menuClick(false) }} ><span /> <span /> <span /></a>
                                        </div>
                                        <ul className="navigation-block">
                                            {
                                                this.props.productResponse && this.props.productResponse.response && this.props.productResponse.response.map((item, key) => {
                                                    let redirect = null;
                                                    if (item.product_url === 'flight-booking') {
                                                        redirect = '/flight-search';
                                                    }
                                                    else if (item.product_url === 'hotel-booking') {
                                                        redirect = '/hotel-search';
                                                    } else if (item.product_url === 'train-booking') {
                                                        redirect = 'https://www.agent.irctc.co.in/nget/train-search';
                                                    }
                                                    return (
                                                        <React.Fragment key={key}>
                                                            {
                                                                productAccess.includes(item.product_code) ?
                                                                    <li className={redirect === this.props.url ? 'active' : ''}>
                                                                        {/* eslint-disable-next-line */}
                                                                        {(redirect ? <a href={redirect} target={item.product_url === 'train-booking' ? '_blank' : ''}>{item.product_name}</a> :
                                                                            /* eslint-disable-next-line */
                                                                            <a href="javascript:void(0);">{item.product_name}</a>)}
                                                                    </li>
                                                                    : <li className={`disable-nav ${redirect === this.props.url ? 'active' : ''}`}>
                                                                        {/* eslint-disable-next-line */
                                                                            <a href="javascript:void(0);">{item.product_name}</a>}
                                                                    </li>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                }
                                                )
                                            }

                                        </ul>
                                    </nav>
                                    <div className="header-right-block">
                                        <div className="balance-detail" style={{ display: (user_type_id && user_type_id !== 1) ? 'block' : 'none' }}>
                                            <div className="cash-card-info search-card-info" style={{ display: this.state.showCreditCash ? 'block' : 'none' }}>
                                                <Link to="/deposit-request">
                                                    <div className="col">
                                                        <div className="cash-card"> <i className="icon icon-currency2"></i>
                                                            <div className="cahs-detail"> <span>Cash Balance</span>
                                                                <p>₹<strong>{this.props.accountResponse.myAccountResponse.response && currencyFormat(this.props.accountResponse.myAccountResponse.response.cash_balance)}</strong></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="/credit-request">
                                                    <div className="col credit-limit-col">
                                                        <div className="cash-card"> <i className="icon icon-currency"></i>
                                                            <div className="cahs-detail"> <span>Credit Limit</span>
                                                           
                                                                <p>₹<strong>{this.props.accountResponse.myAccountResponse.response && currencyFormat(this.props.accountResponse.myAccountResponse.response.credit_limit)}</strong></p>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            {/* <div className="cash-icon" onClick={() => this.showCreditCashBalance()}>
                                                <i className="icon icon-money"></i>
                                            </div> */}
                                        </div>
                                        {/* here */}
                                       
                                        
                                        <div className="user-detail">
                                            <ul>
                                                <li>
                                                    { /* eslint-disable-next-line */}
                                                    <a href="javascript:void(0);">
                                                        <figure>
                                                            <img src={this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.company_logo ? this.props.accountResponse.myAccountResponse.response.company_logo : 'assets/images/no-image-icon.png'} alt="" />
                                                        </figure>
                                                        <span>Welcome </span> <i className="fa fa-caret-down" aria-hidden="true" />
                                                    </a>
                                                    <ul className="submenu">
                                                        <div className="inner-submenu">
                                                            <li>
                                                                { /* eslint-disable-next-line */}
                                                                <a href="javascript:void(0);"><i className="fa fa-user"></i><span dangerouslySetInnerHTML={{ __html: this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.company_name }}></span></a>

                                                            </li>
                                                            {
                                                                this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.agency_code && this.props.accountResponse.myAccountResponse.response.agency_code !== '' &&
                                                                <li>
                                                                    { /* eslint-disable-next-line */}
                                                                    <a href="javascript:void(0);"><i className="fa fa-qrcode"></i><span dangerouslySetInnerHTML={{ __html: this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.agency_code }}></span></a>
                                                                </li>
                                                            }
                                                            <li>
                                                                { /* eslint-disable-next-line */}
                                                                <Link to="my-account"><i className="fa fa-lock" />My Account</Link>
                                                            </li>

                                                            <li>
                                                                { /* eslint-disable-next-line */}
                                                                <a href="javascript:void(0);" onClick={() => { this.props.logoutAction() }}><i className="fa fa-sign-out" />Logout </a>
                                                            </li>
                                                        </div>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    (showheader === 'login' || showheader === '/verify-otp') && <HeaderWithoutSession {...this.props} />
                }
            </React.Fragment>
        )
    }
}

export default HeaderWithSession;