import React from 'react';
import { dateFormat, showValue, minutesToDuration, timeDifference, timeFormatHhMm, getNewDateFormted } from '../../shared/commonHelper';
import * as flightHelper from '../../shared/flightHelper';
import moment from 'moment';
class FlightAdvanceSearchDetailsRightRoundTripComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendMailPopup: '',
            sendMailAddress: null,
            sendMailValues: [],
            // limit: 15,
            includeFare: false,
            openSegments: [],
            mobileActiveTab: 'outbound',
            containerHeight:0,
            containerStyle:{}
        };
    }

    //Show and hide send email popup
    showMainPopup = () => {
        if (this.state.sendMailPopup === '')
            this.setState({ sendMailPopup: 'show' });
        else
            this.setState({ sendMailPopup: '' });
    }

    //Set state with information of flights to be send through email
    saveEmailData = (event) => {
        if (event.target.checked === true) {
            this.state.sendMailValues.push(event.target.value);
        }
        else {
            var index = this.state.sendMailValues.indexOf(event.target.value);
            if (index > -1) {
                this.state.sendMailValues.splice(index, 1);
            }
        }
    }

    //Send itineraries mails
    sendMail = () => {
        this.props.sendItinerariesMail(this.state.sendMailAddress, this.state.sendMailValues, this.state.includeFare);
    }

    trackScrolling = (event) => {
        let section = document.getElementsByClassName("page-container")[0];
        let footer = document.getElementsByTagName("footer")[0];
        let pageScorllRemaining = (section.scrollHeight + footer.scrollHeight - window.pageYOffset - window.innerHeight);
        if (pageScorllRemaining < footer.scrollHeight) {
            this.loadMoreDataM();
        }
    }
    loadMoreDataM = () => {
        let totalResultsOutbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0], this.props.resultSorting, 'outbound');
          const sameFlightOutboundResults = totalResultsOutbound && totalResultsOutbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsOutbound);
          const allSameFlightOutboundKeys = totalResultsOutbound && Object.keys(sameFlightOutboundResults);
          let totalResultsInbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[1], this.props.searchResult.results[1], this.props.resultSorting, 'inbound');
          const sameFlightInboundResults = totalResultsInbound && totalResultsInbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsInbound);
          const allSameFlightInboundKeys = totalResultsInbound && Object.keys(sameFlightInboundResults);
          if(this.state.mobileActiveTab === 'outbound'){
            const nextResultsToShow = Math.min(this.props.isFilterCheckedStatus + 25, allSameFlightOutboundKeys.length);
            this.props.isFilterChecked(nextResultsToShow)
          }else{
            const nextResultsToShowInbound = Math.min(this.props.isFilterCheckedStatusInound + 25, allSameFlightInboundKeys.length);
            this.props.isFilterCheckedInbound(nextResultsToShowInbound)
          }
      };

    loadMoreData = () => {
        let totalResultsOutbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0],this.props.resultSorting,'outbound');
        const sameFlightOutboundResults = totalResultsOutbound && totalResultsOutbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsOutbound);
        const allSameFlightOutboundKeys = totalResultsOutbound && Object.keys(sameFlightOutboundResults);
        const nextResultsToShow = Math.min(this.props.isFilterCheckedStatus + 25, allSameFlightOutboundKeys.length);
        this.props.isFilterChecked(nextResultsToShow)
      };
      loadMoreDataInbound = () => {
        let totalResultsInbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[1], this.props.searchResult.results[1],this.props.resultSorting,'inbound');
        const sameFlightInboundResults  = totalResultsInbound && totalResultsInbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsInbound);
        const allSameFlightInboundKeys  = totalResultsInbound && Object.keys(sameFlightInboundResults);
        const nextResultsToShow = Math.min(this.props.isFilterCheckedStatusInound + 25, allSameFlightInboundKeys.length);
        this.props.isFilterCheckedInbound(nextResultsToShow)
      };
      handleResize=()=>{
        if(window.innerWidth <= 766){
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.remove('page-containers-start')
            document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").classList.remove('page-containers-start-outbound')
            document.addEventListener('scroll', this.trackScrolling);
        }else{
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start') 
            document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").classList.add('page-containers-start-outbound')
            document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
            document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").addEventListener('scroll', this.trackScrollingInbound);
        }
      }
    trackScrollingM = () => {
        const container = document.querySelector('.page-containers-start');
        const footer = document.querySelector('.footers-end');
        if (container && footer) {
          const containerScrollRemaining =
            container.scrollHeight - container.scrollTop - container.clientHeight;
          const footerHeight = footer.scrollHeight;
          if (containerScrollRemaining-2 < footerHeight) {
            this.loadMoreData();
          }
        }
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
          }
      
          this.scrollTimeout = setTimeout(() => {
            if (container.scrollHeight < 200) {
              this.props.scroll_position({ ...this.props.track_scroll_position,childContainer: "" });
            } else {
              this.props.scroll_position({ ...this.props.track_scroll_position,childContainer: 1 });
            }
          }, 500); 
      };
      trackScrollingInbound = () => {
        const container = document.querySelector('.page-containers-start-outbound');
        const footer = document.querySelector('.footers-end-outbound');
        if (container && footer) {
          const containerScrollRemaining =
            container.scrollHeight - container.scrollTop - container.clientHeight;
          const footerHeight = footer.scrollHeight;
        if (containerScrollRemaining-2 < footerHeight) {
            this.loadMoreDataInbound();
          }
        }
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
          }
      
          this.scrollTimeout = setTimeout(() => {
            if (container.scrollHeight < 200) {
              this.props.scroll_position({ ...this.props.track_scroll_position,childContainer: "" });
            } else {
              this.props.scroll_position({ ...this.props.track_scroll_position,childContainer: 1 });
            }
          }, 500); 
      };

    componentDidMount() {
        let liHeight=document.getElementById('calcHeight') && document.getElementById('calcHeight').clientHeight
        let filterHEight=document.getElementById('adv-filter');
        let cliHeight=filterHEight && filterHEight.clientHeight;
        let resultToREnder=liHeight ? Math.ceil(cliHeight/liHeight)+3 : 0
        let isResultToREnder = resultToREnder && resultToREnder > 25 ? resultToREnder : 25
        let totalResultsOutbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0],this.props.resultSorting,'outbound');
        const sameFlightOutboundResults = totalResultsOutbound && totalResultsOutbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsOutbound);
        const allSameFlightOutboundKeys = totalResultsOutbound && Object.keys(sameFlightOutboundResults);
        let totalResultsInbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[1], this.props.searchResult.results[1],this.props.resultSorting,'inbound');
        const sameFlightInboundResults  = totalResultsInbound && totalResultsInbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsInbound);
        const allSameFlightInboundKeys  = totalResultsInbound && Object.keys(sameFlightInboundResults);
        if((allSameFlightInboundKeys && allSameFlightInboundKeys.length) || (allSameFlightOutboundKeys && allSameFlightOutboundKeys.length)){
            for (let initialResults = 6; initialResults <= isResultToREnder; initialResults++) {
                setTimeout(() => {
                    this.props.isFilterChecked(initialResults);
                }, 10 * (initialResults - 1));
            }
        }
        window.addEventListener('resize', this.handleResize)
        if (window.innerWidth <= 766) {
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.remove('page-containers-start')
            document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").classList.remove('page-containers-start-outbound')
            document.addEventListener('scroll', this.trackScrolling);
        } else {
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start')
            document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").classList.add('page-containers-start-outbound')
            document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
            document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").addEventListener('scroll', this.trackScrollingInbound);
        }
        document.addEventListener('click', this.trackClicking.bind(this));

        // Condition for default selected min price flights on bottom
        if (this.props.searchResult && this.props.searchResult.results) {
            let self = this;
            // default selected min price Inbound flight on bottom
            if (this.props.searchResult && this.props.searchResult.results && this.props.searchResult.results[0]) {
                self.props.getBottomDepartureFlightsDetails([this.props.searchResult.results[0][0]]);
            }
            // default selected min price Outbound flight on bottom
            if (this.props.searchResult && this.props.searchResult.results && this.props.searchResult.results[1]) {
                self.props.getBottomReturnFlightsDetails([this.props.searchResult.results[1][0]]);
            }
        }
        else {
            let self = this;
            self.props.getBottomDepartureFlightsDetails([]);
            self.props.getBottomReturnFlightsDetails([]);
        }
    }

    //For bottom flight show according to left filters
    trackClicking = (event) => {
        let totalResultsOutbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0]);
        let totalResultsInbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[1], this.props.searchResult.results[1]);

        if (event.target.className === 'is-filter' || event.target.className === 'active is-filter') {
            if (totalResultsOutbound && totalResultsOutbound[0]) {
                this.props.getBottomDepartureFlightsDetails([totalResultsOutbound[0]]);
            }

            if (totalResultsInbound && totalResultsInbound[0]) {
                this.props.getBottomReturnFlightsDetails([totalResultsInbound[0]]);
            }
        }
    }

    componentWillUnmount() {
        if(window.innerWidth <= 766){
            document.removeEventListener('scroll', this.trackScrolling);
        }else{
            document.removeEventListener('scroll', this.trackScrollingM);
            document.removeEventListener('scroll', this.trackScrollingInbound);
        }
        window.removeEventListener('resize',this.handleResize)
        document.removeEventListener('click', this.trackClicking.bind(this));
    }
    componentDidUpdate() {
        if(document.getElementsByClassName("page-containers-start") && document.getElementsByClassName("page-containers-start").length === 0){
            if (window.innerWidth <= 766) {
            } else {
                document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start')
                document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").classList.add('page-containers-start-outbound')
                document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
                document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").addEventListener('scroll', this.trackScrollingInbound);
            }
        }
    }

    //Prev day Outbound flight search
    prevDayOutboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[0].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() - 1);
        let prevDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[0].preferred_arrival_time = prevDay;
        this.props.searchQuery.segments[0].preferred_departure_time = prevDay;
        this.props.getFlightSearchResults(this.props.searchQuery);
    }

    //Next day Inbound flight search
    nextDayOutboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[0].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() + 1);
        let nextDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[0].preferred_arrival_time = nextDay;
        this.props.searchQuery.segments[0].preferred_departure_time = nextDay;
        this.props.getFlightSearchResults(this.props.searchQuery);
    }

    //Prev day Inbound flight search
    prevDayInboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[1].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() - 1);
        let prevDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[1].preferred_arrival_time = prevDay;
        this.props.searchQuery.segments[1].preferred_departure_time = prevDay;
        this.props.getFlightSearchResults(this.props.searchQuery);
    }

    //Next day Outbound flight search
    nextDayInboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[1].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() + 1);
        let nextDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[1].preferred_arrival_time = nextDay;
        this.props.searchQuery.segments[1].preferred_departure_time = nextDay;
        this.props.getFlightSearchResults(this.props.searchQuery);
    }

    //get selected seat class
    getSelectedSeatClass = (selectedSeatInfo) => {
        this.props.getAdvanceRoundTripSelectedSeatClass(selectedSeatInfo);
    }

    // set open segment
    setOpenSegment = (resultIndex) => {
        let openSegments = this.state.openSegments;
        let foundIndex = false;
        let sameFound = false;

        openSegments.length > 0 && openSegments.map((flightResultIndex, index) => {
            if (flightResultIndex === resultIndex) {
                openSegments.splice(index, 1);
                sameFound = true;
            }
            else if (flightResultIndex.indexOf('OB') > -1 && resultIndex.indexOf('OB') > -1) {
                openSegments.splice(index, 1);
                foundIndex = true;
            }

            if (flightResultIndex === resultIndex) {
                openSegments.splice(index, 1);
                sameFound = true;
            }
            else if (flightResultIndex.indexOf('IB') > -1 && resultIndex.indexOf('IB') > -1) {
                openSegments.splice(index, 1);
                foundIndex = true;
            }
            return true;
        })

        if (openSegments.length < 2 || foundIndex === true) {
            if (sameFound === false) {
                openSegments.push(resultIndex);
            }
            this.setState({ openSegments: openSegments });
        }
    }

    //Set inbound or outbound active in mobile view
    setActiveBound = (value) => {
        this.setState({
            mobileActiveTab: value
        })
    }

    render() {
        let flightFareRulesData = this.props.flightFareRuleDetails.response;
        let traceId = this.props.searchResult && this.props.searchResult.trace_id;
        let searchQuery = this.props.searchQuery;
        let self = this;
        let totalResultsOutbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0]);
        let totalResultsInbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[1], this.props.searchResult.results[1]);
        let { getBottomDepartureFlightsDetails, getBottomReturnFlightsDetails } = this.props;
        let outboundFlightIndex, inboundFlightIndex = '';

        return (
            <div className="right-side">
                <div className="search-right-section round-trip">
                    <div className="filter-btn"><span>Filter</span>
                        { /* eslint-disable-next-line */}
                        <a href="javascript:void(0);" className="btn btn-primary"><i className="icon icon-filter"></i> Filter</a>
                    </div>
                    {(!totalResultsOutbound || totalResultsOutbound.length === 0) && (!totalResultsInbound || totalResultsInbound.length === 0) ?
                        (
                            <div className="no-result-wrap">
                                <div className="result-wrap-outer">
                                    <div className="block">
                                        <div className="no-result-box">
                                            <figure><img src="assets/images/no-result.svg" alt="" /></figure>
                                            <h2>No results found</h2>
                                            <p>We can’t find any item matching your search.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                        <React.Fragment>
                            <div className="title-info">
                                <div className="pull-right">
                                    <div className={`btn-mail ${this.state.sendMailPopup}`}>
                                        { /* eslint-disable-next-line */}
                                        <a className="btn btn-green whatsapp-button" href="javascript:void(0);" onClick={() => this.props.sendItinerariesMessage(this.state.sendMailValues)}><i className="fa fa-whatsapp" aria-hidden="true"></i> Whatsapp </a>    
                                        { /* eslint-disable-next-line */}
                                        <a className="btn btn-sky-blue" href="javascript:void(0);" onClick={this.showMainPopup}><i className="fa fa-envelope" aria-hidden="true"></i> Send Email </a>
                                        <div className="email-tooltip">
                                            <div className="tooltip-outer">
                                                <div className="form-group">
                                                    {
                                                        this.props.sendMailerror && !this.props.ItinerariesMail.loading &&
                                                        <div className="alert alert-danger"> <strong><i className="fa fa-exclamation-triangle"></i>{this.props.sendMailerror}</strong></div>
                                                    }
                                                    {
                                                        this.props.sendMailsuccess && !this.props.ItinerariesMail.loading &&
                                                        <div className="alert alert-success"> <strong> <i className="fa fa-check-circle"></i>Email sent successfully</strong></div>
                                                    }
                                                    <input className="form-control custom-filed" type="email" placeholder="Email Address" onChange={(e) => {
                                                        this.setState({ sendMailAddress: e.target.value });
                                                    }} />
                                                </div>
                                                <div className="block">
                                                    <button type="submit" className={`btn btn-primary ${this.props.ItinerariesMail.loading ? 'show' : ''}`} onClick={this.sendMail}>
                                                        <div className="loader-ring"> <span></span>
                                                        </div>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-trip">
                                <div className="tabs-nav">
                                    <ul>
                                        { /* eslint-disable-next-line */}
                                        <li className={this.state.mobileActiveTab === 'outbound' ? 'active' : ''}><a onClick={() => this.setActiveBound('outbound')} href="javascript:void(0);">Outbound</a></li>
                                        { /* eslint-disable-next-line */}
                                        <li className={this.state.mobileActiveTab === 'inbound' ? 'active' : ''}><a onClick={() => this.setActiveBound('inbound')} href="javascript:void(0);">Inbound</a></li>
                                    </ul>
                                </div>
                                <div className="table-list">
                                    <div className="col-half" style={{ display: window.innerWidth < 767 ? (this.state.mobileActiveTab === 'outbound' ? 'block' : 'none') : 'block' }}>
                                        <div className="title-info">
                                            <span className="no-found">Found <samp>{(!totalResultsOutbound) ? 0 : totalResultsOutbound.length}</samp> Flight(s)</span>
                                            <div className="pull-left">
                                                <div className="city-to-city">
                                                    <small className="icon-info">
                                                        <img src="assets/images/flight-nav-grey.svg" alt="Flight Icon" /></small>
                                                    <span>{searchQuery.segments[0].originCityName}</span> To <span>{searchQuery.segments[0].destinationCityName}</span>
                                                </div>
                                                <div className="date-time">{dateFormat(searchQuery.segments[0].preferred_departure_time, 'DAY, DD MMM YYYY')}</div>
                                            </div>
                                            <div className="pull-right">
                                                <div className="prev-next-day">
                                                    <span onClick={this.prevDayOutboundFlightSearch}><i className="fa fa-angle-double-left" aria-hidden="true"></i>Prev Day</span>
                                                    <span onClick={this.nextDayOutboundFlightSearch}>Next Day<i className="fa fa-angle-double-right" aria-hidden="true"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="inner-table-list">
                                            <div className="table-head">
                                                <div className="table-col table-col-first"><span>airline</span></div>
                                                <div className="table-col"> <span>depart</span></div>
                                                <div className="table-col"><span>arrive</span></div>
                                                <div className="table-col"><span>duration</span></div>
                                            </div>
                                            <div className="table-body" id="cont-st" style={window.innerWidth <= 766 ? {} : {overflow:'auto',maxHeight:this.props.track_scroll_position && this.props.track_scroll_position.cliHeight > 1000 ? this.props.track_scroll_position.cliHeight : '1020px' ,minHeight: '1000px'}}>
                                                <ul>
                                                    {
                                                        !totalResultsOutbound || totalResultsOutbound.length === 0 ?
                                                            (
                                                                <li className="no-result-round">
                                                                    <figure><img src="assets/images/no-result.svg" alt="No Result" /></figure>
                                                                    <div className="no-result-text">
                                                                        <h2>No results found</h2>
                                                                        <p>We can’t find any item matching your search.</p>
                                                                    </div>
                                                                </li>
                                                            ) :
                                                            (
                                                                totalResultsOutbound && totalResultsOutbound.slice(0,this.props.isFilterCheckedStatus).map(function (data, index) {
                                                                    if (index === 0) {
                                                                        outboundFlightIndex = data.result_index;
                                                                    }
                                                                    return (
                                                                        <RoundTripFlightSearchDetails
                                                                            key={index}
                                                                            data={data}
                                                                            searchQuery={searchQuery}
                                                                            saveEmailData={self.saveEmailData}
                                                                            history={self.props.history}
                                                                            flightType="departureFlightDetails"
                                                                            getBottomDepartureFlightsDetails={getBottomDepartureFlightsDetails}
                                                                            traceId={traceId}
                                                                            selectedFlightIndex={outboundFlightIndex}
                                                                            getSelectedSeatClass={self.getSelectedSeatClass}
                                                                            setOpenSegment={self.setOpenSegment}
                                                                            {...self.state}
                                                                            {...self.props}
                                                                        />
                                                                    )
                                                                })
                                                            )
                                                    }
                                                </ul>
                                                <div className='footers-end'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-half" style={{ display: window.innerWidth < 767 ? (this.state.mobileActiveTab === 'inbound' ? 'block' : 'none') : 'block' }}>
                                        <div className="title-info">
                                            <span className="no-found">Found <samp>{(!totalResultsInbound) ? 0 : totalResultsInbound.length}</samp> Flight(s)</span>
                                            <div className="pull-left">
                                                <div className="city-to-city">
                                                    <small className="icon-info"><img src="assets/images/flight-nav-grey.svg" alt="Flight Icon" /></small>
                                                    <span>{searchQuery.segments[1].originCityName}</span> To <span>{searchQuery.segments[1].destinationCityName}</span></div>
                                                <div className="date-time">{dateFormat(searchQuery.segments[1].preferred_departure_time, 'DAY, DD MMM YYYY')}</div>
                                            </div>
                                            <div className="pull-right">
                                                <div className="prev-next-day">
                                                    <span onClick={this.prevDayInboundFlightSearch}><i className="fa fa-angle-double-left" aria-hidden="true"></i>Prev Day</span>
                                                    <span onClick={this.nextDayInboundFlightSearch}>Next Day<i className="fa fa-angle-double-right" aria-hidden="true"></i></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="inner-table-list">
                                            <div className="table-head">
                                                <div className="table-col table-col-first"><span>airline</span></div>
                                                <div className="table-col"> <span>depart</span></div>
                                                <div className="table-col"><span>arrive</span></div>
                                                <div className="table-col"><span>duration</span></div>
                                            </div>
                                            <div className="table-body" id="cont-st-outbound" style={window.innerWidth <= 766 ? {} : {overflow:'auto',maxHeight:this.props.track_scroll_position && this.props.track_scroll_position.cliHeight > 1000 ? this.props.track_scroll_position.cliHeight : '1020px',minHeight: '1000px' }}>
                                                <ul>
                                                    {
                                                        !totalResultsInbound || totalResultsInbound.length === 0 ?
                                                            (
                                                                <li className="no-result-round">
                                                                    <figure><img src="assets/images/no-result.svg" alt="No Result" /></figure>
                                                                    <div className="no-result-text">
                                                                        <h2>No results found</h2>
                                                                        <p>We can’t find any item matching your search.</p>
                                                                    </div>
                                                                </li>
                                                            ) :
                                                            (
                                                                totalResultsInbound && totalResultsInbound.slice(0,this.props.isFilterCheckedStatus).map(function (data, index) {
                                                                    if (index === 0) {
                                                                        inboundFlightIndex = data.result_index;
                                                                    }
                                                                    return (
                                                                        <RoundTripFlightSearchDetails
                                                                            key={index}
                                                                            data={data}
                                                                            searchQuery={searchQuery}
                                                                            saveEmailData={self.saveEmailData}
                                                                            history={self.props.history}
                                                                            flightType="returnFlightDetails"
                                                                            getBottomReturnFlightsDetails={getBottomReturnFlightsDetails}
                                                                            traceId={traceId}
                                                                            selectedFlightIndex={inboundFlightIndex}
                                                                            getSelectedSeatClass={self.getSelectedSeatClass}
                                                                            setOpenSegment={self.setOpenSegment}
                                                                            {...self.state}
                                                                            {...self.props}
                                                                        />
                                                                    )
                                                                })
                                                            )
                                                    }
                                                </ul>
                                                <div className='footers-end-outbound'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
                {/*Fare Detail Popup Start*/}
                <div className={this.props.isShowFareDetailpopup === true ? "popup-crad show" : "popup-crad hide"}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>Fare Rules</h2>
                                <div className="popup-close"><span className="icon icon-cross" onClick={this.props.showFareDetailsPopup}></span></div>
                            </div>
                            <div className="popup-scorll">
                                {!this.props.flightFareRuleDetails.response && this.props.flightFareRuleDetails.loading === true ?
                                    (<div className="loader relative-loader" style={{ display: 'block' }}>
                                        <div className="loader-content">
                                            <div className="loader-ring blue-ring"> <span></span> </div>
                                        </div>
                                    </div>)
                                    :
                                    flightFareRulesData && flightFareRulesData.map(function (data, index) {
                                        let fareRulesDetails = data.fare_rule_detail.replace(/\r\n\t/g, '');
                                        return (
                                            <React.Fragment key={index}>
                                                <h3>{data.origin} - {data.destination}</h3>
                                                <div dangerouslySetInnerHTML={{ __html: fareRulesDetails }} />
                                            </React.Fragment>
                                        )
                                    })
                                }
                                {!flightFareRulesData && this.props.flightFareRuleDetails.loading === false ? <p><br />Fare Rules not available.<br /></p> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay"></div>
                </div>
                {/*Fare Detail Popup End*/}
            </div>
        )
    }
}

class RoundTripFlightSearchDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFlight: this.props.selectedFlightIndex,
        };
    }
    // flights details accordian
    toggleDetailsAccordion = (event, data) => {
        let flightType = document.getElementById(data.result_index).name;
        this.props.reInitializeFlightPriceDetails();
        let selectedRadiobtn = document.getElementById(data.result_index);
        selectedRadiobtn.checked = true;
        if (flightType === 'returnFlightDetails') {
            this.props.getBottomReturnFlightsDetails([data]);
        }
        else {
            this.props.getBottomDepartureFlightsDetails([data]);
        }
        this.props.setOpenSegment(data.result_index);
        this.props.emptySelectedSeatClass(data.result_index);
    }

    //for email 
    emailData = (event) => {
        this.props.saveEmailData(event);
    }

    // show selected flight in bottom
    getSelectedFlightData = (event, data) => {
        this.setState({ selectedFlight: data.result_index })
        if (event.target.name === 'returnFlightDetails') {
            this.props.getBottomReturnFlightsDetails([data]);
        }
        else {
            this.props.getBottomDepartureFlightsDetails([data]);
        }
        this.props.reInitializeFlightPriceDetails();
        this.props.emptySelectedSeatClass(data.result_index);
    }
    //set selected seat class 
    callSelectedSeatClass = (event, selectedSeatInfo, flightType, data) => {
        if (flightType === 'returnFlightDetails') {
            this.props.getBottomReturnFlightsDetails([data]);
        }
        else {
            this.props.getBottomDepartureFlightsDetails([data]);
        }
        this.props.getSelectedSeatClass(selectedSeatInfo);
    }

    render() {
        let data = this.props.data;
        let totalTraveller = this.props.searchQuery.adult_count + this.props.searchQuery.infant_count + this.props.searchQuery.child_count;
        let notAvailbleSeatsClass = [];
        let self = this;
        let segmentData = data.segments[0];
        let stopData = flightHelper.getFlightStops(segmentData);
        return (
            <li className={this.props.openSegments.includes(data.result_index) === true ? "open" : ""} id='calcHeight'>
                <div className="trip-card">
                    <div className="col-table-inner">
                        <div className="table-col table-col-first">
                            <div className="col-list-name">
                                <div className="radio-check yellow-check">
                                    <input type="radio"
                                        id={data.result_index}
                                        value="1"
                                        name={this.props.flightType}
                                        onClick={(event) => this.getSelectedFlightData(event, data)}
                                        defaultChecked={this.state.selectedFlight === data.result_index ? true : false}
                                    />
                                    <label htmlFor={data.result_index}></label>
                                </div>
                                <div className="company-info"> <span className="mob-label">Airline</span>
                                    <figure><img src={data.segments[0][0].airline.flight_logo} alt="" /></figure>
                                    <div className="company-nm">
                                        <span>{flightHelper.getCombinedAirlineName(data.segments[0])}</span>
                                        <small>{flightHelper.getCombinedAirlineCode(data.segments[0])}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-col">
                            <span className="mob-label">Depart</span>
                            <div className="col-detail">
                                {
                                    flightHelper.getDepartureCityTime(data)
                                }
                                {/* <span>{flightHelper.getDepartureTime(data.segments[0][0].stop_point_departure_time)}</span>  */}
                                <small>{flightHelper.getDepartureDate(data.segments[0][0].origin.dep_time, 'DD MMM')}</small>
                            </div>
                        </div>
                        <div className="table-col"> <span className="mob-label">Arrive</span>
                            <div className="col-detail">
                                {
                                    flightHelper.getArrivalCityTime(data)
                                }
                                {/* <span>{flightHelper.getArivalTime(data.segments[0])}</span>  */}
                                <small>{flightHelper.getArivalDate(data.segments[0])}</small>
                            </div>
                        </div>
                        <div className="table-col"> <span className="mob-label">Duration</span>
                            <div className="col-detail">
                                <span> {flightHelper.getCommonDuration(data.segments[0], ':',data.api_source,data)} </span>
                                {/* ----------------------- Duration Non-stop Tooltip - starts here ----------------------- */}
                                { /* eslint-disable-next-line */}
                                <a href="javascript:void(0);" className="non-stop-click">
                                    <small>{flightHelper.getJournyType(stopData)}</small>
                                    <div className="non-stop-tooltip">
                                        <ul>
                                            {
                                                stopData.map(function (segment, segmentIndex) {
                                                    return (
                                                        <li key={segmentIndex}>
                                                            <div className="col-stop">{segment.airline_code + '-' + segment.flight_number}</div>
                                                            <div className="col-stop">{segment.origin_airport_code + " (" + timeFormatHhMm(segment.origin_dep_time) + ")"}</div>
                                                            <div className="col-stop last-stop">
                                                                <i className="icon icon-arrow-right2"></i>
                                                                {segment.destination_airport_code + " (" + timeFormatHhMm(segment.destination_arr_time) + ")"}
                                                                {segment.craft && segment.remark && segment.craft === "BUS" ? <samp className="red-text">*</samp> : ""}</div>
                                                            {
                                                                (segment.techStop) &&
                                                                <div className="techstopmsg">1 Technical Stop</div>
                                                            }
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </a>
                                {
                                    data && data.api_source && data.api_source === 'kafila' ?
                                    <small className="left-seat">{data.segments[0][0].no_of_seat_available !== null ? flightHelper.kafilaNumberOfSeat(data.segments[0][0].no_of_seat_available) + ' seat(s) left' : ''}</small>
                                    :
                                    <small className="left-seat">{data.segments[0][0].no_of_seat_available !== null ? data.segments[0][0].no_of_seat_available + ' seat(s) left' : ''}</small>
                                }
                            </div>
                        </div>
                        {
                            (data.is_remark_available === true || data.is_vendor === 1) ? <div className="fl-sme"><span>{data.airline_remark ? data.airline_remark : ''}</span></div> : ''
                        }
                    </div>
                    <div className="block-title">
                        <h2 onClick={(event) => this.toggleDetailsAccordion(event, data)} className={this.props.flightType}>Flight Detail</h2>
                        <div className="title-label">
                            <span className="mail-icon">
                                <input type="checkbox" value={data.result_index} name="sendMail" onChange={(event) => this.emailData(event)} />
                                <i className="icon icon-mail1"></i> <i className="icon icon-checked"></i>
                            </span>
                        </div>
                    </div>
                    <div className="block-accordion">
                        <div className="block-describe">
                            <div className="info-detail">
                                <div className="time-duration-section">
                                    <ul className="flight-duration">
                                        {
                                            data.segments[0].map(function (segment, segmentIndex) {
                                                return (
                                                    <li key={segmentIndex}>
                                                        {
                                                            segmentIndex !== 0
                                                                ? <div className="time-col"><span>Change plane at {segment.origin.airport.city_name}</span></div>
                                                                : ""
                                                        }
                                                        <div className="detail-bx">
                                                            <div className="fl-info">
                                                                <div className="fl-name">
                                                                    <span>{flightHelper.getAirlineNameAndCode(segment)}</span> <small>Aircraft - {segment.craft}</small>
                                                                </div>
                                                                {/* Layover Start */}
                                                                <div className="fl-information">
                                                                    {
                                                                        segmentIndex !== 0 && timeDifference(data.segments[0][segmentIndex - 1].destination.arr_time, segment.origin.dep_time) > 0
                                                                            ? <span> Layover {minutesToDuration(timeDifference(data.segments[0][segmentIndex - 1].destination.arr_time, segment.origin.dep_time), 'h')}</span>
                                                                            : ""
                                                                    }
                                                                    {segment.craft && segment.remark && segment.craft === "BUS" ? <div className="fn-segment"><span className="red-text">{segment.remark}</span></div> : ""}
                                                                </div>
                                                                {/* Layover End */}


                                                            </div>
                                                            <div className="fl-detail">
                                                                <div className="fl-inner">
                                                                    <div className="fl-col"> <span> {segment.origin.airport.city_name} </span> <small> {dateFormat(segment.origin.dep_time, "DAY, DD MMM, HH:MM")} </small>
                                                                        <div className="terminal-nm"> <span> {segment.origin.airport.airport_name}{showValue(" - T-", segment.origin.airport.terminal, "")}</span> </div>
                                                                    </div>
                                                                    <div className="fl-mid">
                                                                        <div className="fl-mid-inner">
                                                                            <ul>
                                                                                <li className="time-info">
                                                                                    <span data-tooltip={flightHelper.getDuration(segment, 'h')}><i className="icon icon-clock" ></i></span>
                                                                                </li>
                                                                                {
                                                                                    segment.cabin_baggage !== null
                                                                                        ? (<li className="mid-meal"> <span data-tooltip={segment.cabin_baggage}><i className="material-icons">card_travel</i></span></li>)
                                                                                        : ""
                                                                                }
                                                                                {
                                                                                    segment.baggage !== null
                                                                                        ? (<li className="seat-info"><span data-tooltip={segment.baggage}> <i className="material-icons">shopping_cart</i></span></li>)
                                                                                        : ""
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                        <div className="mid-line"><span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span> </div>
                                                                    </div>
                                                                    <div className="fl-col"> <span> {segment.destination.airport.city_name} </span> <small> {dateFormat(segment.destination.arr_time, "DAY, DD MMM, HH:MM")} </small>
                                                                        <div className="terminal-nm"> <span> {segment.destination.airport.airport_name} {showValue(" - T-", segment.destination.airport.terminal, "")}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="advance-search">
                                                            <div className="seat-list">
                                                                {
                                                                    segment && segment.availability && segment.availability.map(function (seatClass, seatClassIndex) {
                                                                        let selectedSeatInfo = data.result_index + '-' + segmentIndex + '-' + seatClass.class + '-' + seatClass.seats + '-' + self.props.flightType;
                                                                        let activeSeat = ''
                                                                        let seatIndex = self.props.selectedSeatClass.indexOf(selectedSeatInfo);
                                                                        if (seatIndex > -1) {
                                                                            activeSeat = 'active'
                                                                        }
                                                                        else {
                                                                            activeSeat = ''
                                                                        }
                                                                        return (
                                                                            <span key={seatClassIndex} className={activeSeat} onClick={(event) => self.callSelectedSeatClass(event, selectedSeatInfo, self.props.flightType, data)}>{seatClass.class + seatClass.seats}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            {
                                                                segment.availability.map(function (seatClass, seatClassIndex) {
                                                                    let selectedSeatInfo = data.result_index + '-' + segmentIndex + '-' + seatClass.class + '-' + seatClass.seats + '-' + self.props.flightType;
                                                                    let seatIndex = self.props.selectedSeatClass.indexOf(selectedSeatInfo);
                                                                    if (seatIndex > -1) {
                                                                        if (totalTraveller <= selectedSeatInfo.split('-')[3]) {
                                                                            return (
                                                                                <div className="seat-available" key={seatClassIndex}>
                                                                                    <span>{totalTraveller} Seat(s) in {selectedSeatInfo.split('-')[2]} Class</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        else {
                                                                            notAvailbleSeatsClass.push(seatClass);
                                                                            return (
                                                                                <div className="seat-available no-seats" key={seatClassIndex}>
                                                                                    <span>{totalTraveller} Seat(s) not available</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    }
                                                                    else {
                                                                        return false;
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

export default FlightAdvanceSearchDetailsRightRoundTripComponent