import React, { Component } from 'react';
import 'react-dates/initialize';
import moment from 'moment';
import { Field } from 'formik';
import { SingleDatePicker, DateRangePicker } from 'react-dates'
import Select from "react-virtualized-select";
import Picky from 'react-picky';
import 'react-picky/dist/picky.css'; 
import { compareDate, getCurrentDate } from '../../shared/commonHelper';

class FlightSearchActiveComponent extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            adultCount: props.values.adult_count,
            childCount: props.values.child_count,
            infantCount: props.values.infant_count,
            date:moment(getCurrentDate()),//for depart date of one-way
            startDate:moment(getCurrentDate()),//for depart date
            endDate:props.values && props.values.second_preferred_departure_time ? moment(props.values.second_preferred_departure_time.split('T')[0]) :moment(getCurrentDate()),//for return date
            focused:false,
            selectedAdvanceSearchType:  props.searchQuery 
                                        ? 
                                        props.searchQuery.advanceSearchType
                                        : 
                                        '1',
            selectedroundTripType: props.searchQuery 
                                   ? 
                                   props.searchQuery.roundTripType
                                   : 
                                   'normalReturn',
            selectedOriginCity: props.searchQuery 
                                ? 
                                {value: props.searchQuery.segments[0].origin, label: <React.Fragment>{props.searchQuery.segments[0].originCityFullName} <span className="pull-right">{props.searchQuery.segments[0].originAirportCode}</span></React.Fragment>, cityName: props.searchQuery.segments[0].originCityName, countryName: props.searchQuery.segments[0].originCountryName, newValue: props.searchQuery.segments[0].originAirportCode, labelValue: props.searchQuery.segments[0].originCityFullName} 
                                : 
                                {value: "JAI", label: <React.Fragment><span className="pull-right">JAI</span><samp>Jaipur, India</samp> </React.Fragment>, cityName: 'Jaipur', countryName: 'India', newValue: "JAI", labelValue: "Jaipur, India"},
            selectedDestinationCity: props.searchQuery 
                                ? 
                                {value: props.searchQuery.segments[0].destination, label: <React.Fragment>{props.searchQuery.segments[0].destinationCityFullName} <span className="pull-right">{props.searchQuery.segments[0].destinationAirportCode}</span></React.Fragment>, cityName: props.searchQuery.segments[0].destinationCityName, countryName: props.searchQuery.segments[0].destinationCountryName, newValue: props.searchQuery.segments[0].destinationAirportCode, labelValue: props.searchQuery.segments[0].destinationCityFullName}  
                                : 
                                null,
            selectedAirlines: [],
            selectedCabinClass: props.searchQuery 
                                ? 
                                {value: props.searchQuery.flight_cabin_class, label: props.searchQuery.flightCabinClassName} 
                                : 
                                {value: "1", label: "Any"},
            isDirectFlight: props.searchQuery 
                                ? 
                                props.searchQuery.direct_flight
                                : 
                                "false",
            selectedPreferredAirlines: props.searchQuery 
                                ? 
                                props.searchQuery.preferred_airlines
                                : 
                                '',
            originCityArray     : [],
            destinationCityArray: [],
            selectedFareType    : props.searchQuery 
                                ? 
                                props.searchQuery.pft
                                : "REGULAR",
        }
    }
    //Swap origin and destination on arrow click
    swapOriginDestination = (type, setFieldValue) => {
        let originState = this.state.selectedOriginCity;
        let destinationState = this.state.selectedDestinationCity;

        if(destinationState)
        {
        setFieldValue('originCityFullName', destinationState.labelValue);
        setFieldValue('originCityName', destinationState.cityName);
        setFieldValue('origin', destinationState.newValue);
        setFieldValue('originAirportCode', destinationState.newValue);
        setFieldValue('originCountryName', destinationState.countryName);
        }

        if(originState)
        {
        setFieldValue('destinationCityFullName', originState.labelValue);
        setFieldValue('destinationCityName', originState.cityName);
        setFieldValue('destination', originState.newValue);
        setFieldValue('destinationAirportCode', originState.newValue);
        setFieldValue('destinationCountryName', originState.countryName);
        }

        this.setState({selectedOriginCity: destinationState });
        this.setState({selectedDestinationCity: originState });
    }

    //increment and decrement adult 
    onAdultClick = (type, setFieldValue) => {
        let oldcount =  this.state.adultCount;
        let newcount = (type === 'add') ? ((oldcount < 9) ? (oldcount + 1) : oldcount ) : ((oldcount > 0) ? (oldcount - 1) : 0 );
        this.setState({adultCount: newcount});
        setFieldValue("adult_count", newcount );
    }

    //increment and decrement child
    onChildClick = (type, setFieldValue) => {
        let oldcount =  this.state.childCount;
        let newcount = (type === 'add') ? ((oldcount < 8) ? (oldcount + 1) : oldcount ) : ((oldcount > 0) ? (oldcount - 1) : 0 );
        this.setState({childCount: newcount});
        setFieldValue("child_count", newcount );
    }

    //increment and decrement infant
    onInfantClick = (type, setFieldValue) => {
        let oldcount =  this.state.infantCount;
        let newcount = (type === 'add') ? ((oldcount < 9) ? (oldcount + 1) : oldcount ) : ((oldcount > 0) ? (oldcount - 1) : 0 );
        this.setState({infantCount: newcount});
        setFieldValue("infant_count", newcount );
    }

    //set show direct flights
    showDirectFligts = (event,setFieldValue) => {
        setFieldValue("direct_flight", event.target.checked.toString());
        this.setState({isDirectFlight: event.target.checked});
    }

    changeDateFormat(value)
    {
        let finalDate = moment(value).format('YYYY-MM-DD')+'T00:00:00';
        return finalDate;
    }

    /*************************** Set Origin City Name ***************************/
    setOriginCityName = (selectedOriginCity, setFieldValue) =>
    {
        if(selectedOriginCity && selectedOriginCity.labelValue !== null){
            setFieldValue('originCityFullName', selectedOriginCity && selectedOriginCity.labelValue);
            setFieldValue('originCityName', selectedOriginCity && selectedOriginCity.cityName);
            setFieldValue('origin', selectedOriginCity && selectedOriginCity.newValue);
            setFieldValue('originAirportCode', selectedOriginCity && selectedOriginCity.airportCode);
            setFieldValue('originCountryName', selectedOriginCity && selectedOriginCity.countryName);
            this.setState({ selectedOriginCity });
        }else{
            setFieldValue('originCityFullName', "");
            setFieldValue('originCityName', "");
            setFieldValue('origin', "");
            setFieldValue('originAirportCode', '');
            setFieldValue('originCountryName', "");
            this.setState({ selectedOriginCity });
        }
        
    }

    /*************************** Set Destination City Name ***************************/
    setDestinationCityName = (selectedDestinationCity, setFieldValue) =>
    {
        if(selectedDestinationCity && selectedDestinationCity.labelValue){
            setFieldValue('destinationCityFullName', selectedDestinationCity && selectedDestinationCity.labelValue);
            setFieldValue('destinationCityName', selectedDestinationCity && selectedDestinationCity.cityName);
            setFieldValue('destination', selectedDestinationCity && selectedDestinationCity.newValue);
            setFieldValue('destinationAirportCode', selectedDestinationCity && selectedDestinationCity.airportCode);
            setFieldValue('destinationCountryName', selectedDestinationCity && selectedDestinationCity.countryName);
            this.setState({ selectedDestinationCity });
        }
        
    }

    /*************************** Set Selected Airlines *******************************/
    setSelectedAirlines = (selectedAirlines, setFieldValue) =>
    {
        if(this.props.searchQuery && this.props.searchQuery.source)
        {
            delete this.props.searchQuery.source;
        }
        this.setState({ selectedAirlines });
        let selectSource = [];
        for(let i = 0;i< selectedAirlines.length ; i++)
        {
            selectSource.push(selectedAirlines[i].value);
        }
        setFieldValue('source', selectSource);
    }

    // setSelectedPrefferedAirlines = (selectedPreferredAirlines, setFieldValue) =>
    // {
    //     this.setState({ selectedPreferredAirlines:  selectedPreferredAirlines.target.value});
    //     setFieldValue('preferred_airlines', selectedPreferredAirlines.value);
    //     setFieldValue('preferredAirlinesName', selectedPreferredAirlines.value);
    // }

    /*************************** Set Selected Cabin Class *******************************/
    setSelectedCabinClass = (selectedCabinClass, setFieldValue) =>
    {
        this.setState({ selectedCabinClass });
        setFieldValue('flight_cabin_class', selectedCabinClass.value);
        setFieldValue('flightCabinClassName', selectedCabinClass.label);
    }

    /************ call setFieldTouched and manually update touched.origin ****************/
    originHandleBlur = () => {
        this.props.setFieldTouched('origin', true);
    };

    /************ call setFieldTouched and manually update touched.destination ***********/
    destinationHandleBlur = () => {
        this.props.setFieldTouched('destination', true);
    };

    getSelectedAdvanceSearchType = (event) => {
        this.setState({selectedAdvanceSearchType: event.target.value})
        this.props.setFieldValue('advanceSearchType', event.target.value);
    }

    getSelectedRoundTripType = (event) => {
        this.setState({selectedroundTripType: event.target.value})
        this.props.setFieldValue('roundTripType', event.target.value);
    }
    getSelectedFareType = (event) => {
        this.setState({selectedFareType: event.target.value})
        this.props.setFieldValue('pft', event.target.value);
    }

    
    /******************************* Get origin cities ***************************************/
    getOriginCitiesByFilter(filterString)
    {
        if(filterString && filterString.length > 1)
        {
            const successCb = () =>{
                let cityArray = []; 
                if(this.props.citiesResponse.response){
                    cityArray = this.props.citiesResponse.response.map(function (cityData, index) {
                             return ({
                                value: cityData.airport_code+' '+cityData.city_code+' '+cityData.city_full_name,//it is used for searching
                                newValue: cityData.city_code,//set value
                                label:  <React.Fragment> <span className='pull-right'>{cityData.airport_code}</span><samp>{cityData.city_full_name}</samp> </React.Fragment>,
                                airportCode:cityData.airport_code,
                                cityName: cityData.city_name,
                                labelValue:cityData.city_full_name,
                                countryName: cityData.country_name,
                            });
                        })
                }
                this.setState({originCityArray: cityArray});
            }
            this.props.getCitiesAction(filterString, successCb);
        }
    }

    /******************************** Get destination cities *********************************/
    getDestinationCitiesByFilter(filterString)
    {
        if(filterString && filterString.length > 1)
        {
            const successCb = () =>{
                let cityArray = []; 
                if(this.props.citiesResponse.response){
                    cityArray = this.props.citiesResponse.response.map(function (cityData, index) {
                             return ({
                                value: cityData.airport_code+' '+cityData.city_code+' '+cityData.city_full_name,//it is used for searching
                                newValue: cityData.city_code,//set value
                                label:  <React.Fragment> <span className='pull-right'>{cityData.airport_code}</span><samp>{cityData.city_full_name}</samp> </React.Fragment>,
                                airportCode:cityData.airport_code,
                                cityName: cityData.city_name,
                                labelValue:cityData.city_full_name,
                                countryName: cityData.country_name,
                            });
                        })
                }
                this.setState({destinationCityArray: cityArray});
            }
            this.props.getCitiesAction(filterString, successCb);
        }
    }

    componentDidMount()
    {
        this.getOriginCitiesByFilter('default');
        this.getDestinationCitiesByFilter('default');
    }
    

    render() { 
        const {allClassesData, allAirlinesData, activeSarchType, handleSubmit, setFieldValue, errors, touched, values } = this.props;
        //const is_kafila_user = localStorage.getItem('is_kafila_user');
        let activeIconClass = '';
        let {selectedAdvanceSearchType,selectedroundTripType, selectedAirlines, originCityArray, destinationCityArray} = this.state;
        if(activeSarchType === 'round-trip' || (selectedAdvanceSearchType === '2' && activeSarchType === 'advance-search'))
            activeIconClass = 'fa fa-exchange';
        else
            activeIconClass = 'fa fa-arrow-right';
       
        let { focused, endDate, selectedOriginCity, selectedDestinationCity, selectedCabinClass, isDirectFlight } = this.state;
        
        /******************************** Map All Airlines Array ******************************/ 
        let allAirlinesArray = [];  
        if(this.state.selectedroundTripType === 'GDS')
        {
            allAirlinesArray.push({
                value: 'GDS',
                label: 'GDS'
            });
        }
        else if(this.state.selectedroundTripType === 'normalReturn')
        {
            allAirlinesArray.push({
                value: 'GDS',
                label: 'GDS'
            },
            {
                value: 'TBA',
                label: 'Other Intl LCC'
            });
        }

        if(allAirlinesData.response){

            for(let i = 0;i< allAirlinesData.response.length ; i++){
                if(allAirlinesData.response[i].is_preffered === 'A'){
                    if(this.state.selectedroundTripType === 'LCC' && ["G8","6E","SG"].includes(allAirlinesData.response[i].airline_code) === true)
                    {
                        allAirlinesArray.push({
                            value: allAirlinesData.response[i].airline_code,
                            label: allAirlinesData.response[i].airline_name
                        });
                    } 
                    else if(this.state.selectedroundTripType === 'GDS' && ["GDS"].includes(allAirlinesData.response[i].airline_code) === true)
                    {
                        allAirlinesArray.push({
                            value: allAirlinesData.response[i].airline_code,
                            label: allAirlinesData.response[i].airline_name
                        });
                    }
                    else if(this.state.selectedroundTripType === 'normalReturn')
                    {
                        allAirlinesArray.push({
                            value: allAirlinesData.response[i].airline_code,
                            label: allAirlinesData.response[i].airline_name
                        });
                    }
                }

                // if(allAirlinesData.response[i].airline_code === 'UK' && is_kafila_user === 'Y')
                // {
                //     allAirlinesArray.push({
                //         value: allAirlinesData.response[i].airline_code,
                //         label: allAirlinesData.response[i].airline_name
                //     });
                // }
                
            }
        }

        // if(selectedAirlines.length === 0 && !(this.props.searchQuery && this.props.searchQuery.source))
        // {
        //     selectedAirlines = allAirlinesArray;
        // }

        // if(is_kafila_user === 'Y')
        // {
        //     let airlineArray1 = [];
        //     let airlineArray2 = [];
        //     for(let i = 0;i< allAirlinesArray.length; i++){
        //         if(['GDS', 'TBA', 'AK'].includes(allAirlinesArray[i].value))
        //         {
        //             airlineArray1.push(allAirlinesArray[i]);
        //         }
        //         else 
        //         {
        //             airlineArray2.push(allAirlinesArray[i]);
        //         }                        
        //     }
        //     allAirlinesArray = airlineArray2.concat(airlineArray1);
        // }

        if(this.props.searchQuery && this.props.searchQuery.source){
            selectedAirlines = [];
            // if(is_kafila_user === 'Y')
            // {
            //     let selectedAirlines1 = [];
            //     let selectedAirlines2 = [];
            //     for(let i = 0;i< allAirlinesArray.length; i++){
            //         if(this.props.searchQuery.source.includes(allAirlinesArray[i].value) && ['GDS', 'TBA', 'AK'].includes(allAirlinesArray[i].value))
            //         {
            //             selectedAirlines1.push(allAirlinesArray[i]);
            //         }                        
            //         else if(this.props.searchQuery.source.includes(allAirlinesArray[i].value))
            //         {
            //             selectedAirlines2.push(allAirlinesArray[i]);
            //         }                        
            //     }
            //     selectedAirlines = selectedAirlines2.concat(selectedAirlines1);
            // }
            // else
            // {
                for(let i = 0;i< allAirlinesArray.length; i++){
                    if(this.props.searchQuery.source.includes(allAirlinesArray[i].value))
                    {
                        selectedAirlines.push(allAirlinesArray[i]);
                    }                        
                }
            // }
            
        }

        /************************* Map All Preferred Airlines Array *************************/   
        let allPreferredAirlinesArray = [];  
        allPreferredAirlinesArray.push({
            value: 'GDS',
            label: 'GDS'
        },
        {
            value: 'TBA',
            label: 'Other Intl LCC'
        });

        if(allAirlinesData.response){
            for(let i = 0;i< allAirlinesData.response.length ; i++){
                if(allAirlinesData.response[i].is_preffered === 'A'){
                    allPreferredAirlinesArray.push({
                        value: allAirlinesData.response[i].airline_code,
                        label: allAirlinesData.response[i].airline_name
                    });
                }
            }
        }

        /******************************** Map All Class Array ******************************/            
        let allCabinClassArray = [];
        //const kafilaClassArray = ['Any', 'Economy', 'Business']
        if(allClassesData.response){
           

            for(let i = 0;i< allClassesData.response.length; i++){
                // if(is_kafila_user === 'Y' && kafilaClassArray.includes(allClassesData.response[i].flight_class))
                // {
                //     allCabinClassArray.push( {
                //         value: allClassesData.response[i].class_id,
                //         label: allClassesData.response[i].flight_class
                //     });
                // }
                // else if(is_kafila_user === 'N')
                // {
                    allCabinClassArray.push( {
                        value: allClassesData.response[i].class_id,
                        label: allClassesData.response[i].flight_class
                    });
                // }                       
            }
            
        }
         
        let dateData = '';
        // let dateDataReturn = '';
        if(this.props.values.segments)
        {
            dateData = this.props.values.segments[0].preferred_departure_time.split('T');
            // if(this.props.values.journey_type === '2'){
            //     dateDataReturn = this.props.values.segments && this.props.values.segments[1].preferred_departure_time ? this.props.values.segments[1].preferred_departure_time.split('T') :'';
            // }
        }
        else
        {
            dateData = this.props.values.first_preferred_departure_time.split('T');
            // dateDataReturn = this.props.values && this.props.values.second_preferred_departure_time ? this.props.values.second_preferred_departure_time.split('T') :'';
        }

        //If return date is less than depart date then assign depart date to return date value 
        if(compareDate(dateData[0],endDate) === 'true'){
            endDate = moment(dateData[0]);
        }
        


        return (  
            <div className="tab_contener fadeIn">
                <div className="inneer-tab-section">
                    <div className="mid-tab-form">
                        <div className="row-block">
                            {
                                activeSarchType === 'advance-search' &&
                                <div className="col-block col-radio">
                                    <div className="form-block">
                                        <div className="control-group">
                                            <div className="radio-check inline-check">
                                                <input 
                                                    name="advanceSearchType" 
                                                    type="radio" 
                                                    id="1" 
                                                    onClick={(event) => this.getSelectedAdvanceSearchType(event,'advance-oneway')}
                                                    className="form-control"
                                                    value="1" 
                                                    defaultChecked={!selectedAdvanceSearchType || selectedAdvanceSearchType === '1'? true: false}
                                                />
                                                <label htmlFor="1">Advance Oneway</label></div><div className="radio-check inline-check">
                                                <input 
                                                    name="advanceSearchType" 
                                                    type="radio" 
                                                    id="2" 
                                                    onClick={(event) => this.getSelectedAdvanceSearchType(event,'advance-return')}
                                                    className="form-control"
                                                    value="2"
                                                    defaultChecked={selectedAdvanceSearchType === '2'? true: false} 
                                                />
                                                <label htmlFor="2">Advance Return</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                activeSarchType === 'round-trip' &&
                                <div className="col-block col-radio">
                                    <div className="form-block">
                                        <div className="control-group">
                                            <div className="radio-check inline-check">
                                                <input 
                                                    name="roundTripType" 
                                                    type="radio" 
                                                    id="1" 
                                                    onClick={(event) => this.getSelectedRoundTripType(event)}
                                                    className="form-control"
                                                    value="normalReturn" 
                                                    defaultChecked={selectedroundTripType === 'normalReturn'? true: false}
                                                />
                                                <label htmlFor="1">Normal Return</label></div><div className="radio-check inline-check">
                                                <input 
                                                    name="roundTripType" 
                                                    type="radio" 
                                                    id="2" 
                                                    onClick={(event) => this.getSelectedRoundTripType(event)}
                                                    className="form-control"
                                                    value="LCC"
                                                    defaultChecked={selectedroundTripType === 'LCC'? true: false} 
                                                />
                                                <label htmlFor="2">LCC Return</label></div><div className="radio-check inline-check">
                                                <input 
                                                    name="roundTripType" 
                                                    type="radio" 
                                                    id="3" 
                                                    onClick={(event) => this.getSelectedRoundTripType(event)}
                                                    className="form-control"
                                                    value="GDS"
                                                    defaultChecked={selectedroundTripType === 'GDS'? true: false} 
                                                />
                                                <label htmlFor="3">GDS Return</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* -------------------------------------------------- City - From -------------------------------------------Start----- */}
                            <div className="col-row">
                                <div className="form-inner">
                                    <label className="control-label">From<span className="red-error">*</span></label>
                                    <div className="control-group select-custom">
                                        <Field
                                            component={Select}
                                            value={selectedOriginCity}
                                            options={originCityArray}
                                            name="origin" 
                                            className="" 
                                            onBlur={this.originHandleBlur} 
                                            onChange={(event) =>{ this.setOriginCityName(event, setFieldValue)}}
                                            onInputChange={(event) => {this.getOriginCitiesByFilter(event)}}
                                            openMenuOnFocus="true"
                                           // tabSelectsValue="false"
                                           defaultMenuIsOpen ={true}
                                           placeholder="Select City"
                                        >
                                        </Field>
                                    </div>
                                    {errors.origin && touched.origin && <span className="error-msg">{errors.origin}</span>}
                                </div>
                            </div>
                           
                            {/* -------------------------------------------- City/Trip Direction arrow -----------------------------------Start----- */}
                            <div className="col-mid">
                                <div className="exchange-icon" onClick={(event) => {this.swapOriginDestination(event, setFieldValue)}}>
                                <i className={activeIconClass} aria-hidden="true" /></div>
                            </div>
                           
                            {/* --------------------------------------------------- City - To --------------------------------------------Start----- */}
                            <div className="col-row">
                                <div className="form-inner">
                                    <label className="control-label">To<span className="red-error">*</span></label>
                                    <div className="control-group select-custom">
                                        <Field
                                            component={Select}
                                            value={selectedDestinationCity}
                                            options={destinationCityArray}
                                            name="destination" 
                                            className="" 
                                            onBlur={this.destinationHandleBlur} 
                                            onInputChange={(event) => {this.getDestinationCitiesByFilter(event)}}
                                            onChange={(event) => {this.setDestinationCityName(event, setFieldValue)}}
                                            placeholder="Select City"
                                            openMenuOnFocus="true"
                                            tabIndex="0"
                                           // tabSelectsValue="false"
                                        >
                                        </Field>
                                    </div>
                                    {errors.destination && touched.destination && <span className="error-msg">{errors.destination}</span>}
                                </div>
                            </div>
                            
                            {/* ----------------------------------------------- One Way Date Picker --------------------------------------Start----- */}
                            {(activeSarchType === 'one-way'  || (selectedAdvanceSearchType === '1' && activeSarchType === 'advance-search')) && 
                            <React.Fragment>
                            <div className="col-block date-half-col">
                                <div className="form-inner">
                                    <label className="control-label">Depart<span className="red-error">*</span></label>
                                    <div className="control-group">
                                        <div className="input-group">
                                            <SingleDatePicker
                                                 date={moment(dateData[0])}
                                                //onDateChange={this.onDatesChange.bind(this,{ date })}
                                                onDateChange={date => {
                                                    this.setState({ date });
                                                    setFieldValue("first_preferred_departure_time", this.changeDateFormat(date)); 
                                                    setFieldValue("first_preferred_arrival_time", this.changeDateFormat(date));
                                                    setFieldValue("second_preferred_departure_time", this.changeDateFormat(date)); 
                                                    setFieldValue("second_preferred_arrival_time", this.changeDateFormat(date));
                                                }}
                                                focused={focused}
                                                onFocusChange={({ focused }) => this.setState({ focused })}
                                                id="start_date"
                                                displayFormat="DD MMM YYYY"
                                                readOnly={true}
                                                showDefaultInputIcon={true}
                                                inputIconPosition={"after"}
                                                hideKeyboardShortcutsPanel={true}
                                                reopenPickerOnClearDate={true}
                                                withPortal={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-block">
                                <div className="form-inner">
                                    <label className="control-label">Return</label>
                                    <div className="control-group disabled-group">
                                        <div className="input-group  date dateteam-picker" data-date="Return Date " data-date-format="dd-mm-yyyy">
                                            <input className="form-control" type="text" defaultValue="Return Date " disabled={true} />
                                            <span className="input-group-addon"><i className="fa fa-calendar" /></span> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </React.Fragment>
                            }

                            {/* ----------------------------------------------- Round Trip Date Picker -----------------------------------Start----- */}
                            {(activeSarchType === 'round-trip' || activeSarchType === 'multi-city' || ( activeSarchType === 'advance-search' && selectedAdvanceSearchType === '2')) && 
                            <div className="col-block date-col-block">
                                <div className="form-inner">
                                    <label className="control-label">Depart<span className="red-error">*</span></label>
                                    <label className="control-label">Return<span className="red-error">*</span></label>
                                    <div className="control-group">
                                        <div className="input-group">
                                            <DateRangePicker
                                                startDatePlaceholderText= "Depart Date"
                                                endDatePlaceholderText = "Return Date"
                                                startDate={moment(dateData[0])} 
                                                startDateId="first_preferred_departure_time" 
                                                endDate={endDate} 
                                                endDateId="second_preferred_departure_time"
                                                //onDatesChange={this.onDatesChange.bind(this,{ startDate, endDate })}
                                                onDatesChange={({ startDate, endDate }) => { 
                                                    this.setState({ startDate, endDate }); 
                                                    setFieldValue("first_preferred_departure_time", this.changeDateFormat(startDate)); 
                                                    setFieldValue("first_preferred_arrival_time", this.changeDateFormat(startDate));
                                                    setFieldValue("second_preferred_departure_time", this.changeDateFormat(endDate)); 
                                                    setFieldValue("second_preferred_arrival_time", this.changeDateFormat(endDate));
                                                 }}
                                                focusedInput={this.state.focusedInput} 
                                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                displayFormat="DD MMM YYYY"
                                                readOnly={true}
                                                hideKeyboardShortcutsPanel={true}
                                                minimumNights={0}
                                                withPortal={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {
                            activeSarchType !== 'advance-search' &&
                            <React.Fragment>
                                {/*---------------------------------------------------- Flight Class -----------------------------------------Start-----*/}
                                <div className="col-block">
                                    <div className="form-inner">
                                        <label className="control-label">Class</label>
                                        <div className="control-group select-custom">
                                            <div className="control-group">
                                                <Field 
                                                component={Select} 
                                                name="flight_cabin_class"
                                                options={allCabinClassArray}
                                                value={selectedCabinClass}
                                                onChange={(event) =>{ this.setSelectedCabinClass(event, setFieldValue)}}
                                                placeholder="Select Class"  
                                                > 
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* -------------------------------------------------- Preferred Airline --------------------------------------Start-----*/}
                                <div className="col-block">
                                    <div className="form-inner">
                                        <label className="control-label">Source</label>
                                        <div className="control-group">
                                            <Picky
                                                options={allAirlinesArray}
                                                value={selectedAirlines}
                                                valueKey="value"
                                                labelKey="label"
                                                multiple={true}
                                                includeSelectAll={true}
                                                onChange={(event) =>{ this.setSelectedAirlines(event, setFieldValue)}}
                                                dropdownHeight={600}
                                                placeholder={"Any"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            }

                            { activeSarchType === 'advance-search' &&
                                <div className="col-block">
                                    <div className="form-inner">
                                        <label className="control-label">Preferred Airline</label>
                                        <div className="control-group">
                                            <Field 
                                            className="form-control"
                                            type="text"
                                            value={values.preferred_airlines}
                                            name="preferred_airlines"
                                            placeholder={"Preferred Airlines"}
                                            /> 
                                        </div>
                                        <span>Restrict to specified Airline(s) Leave blank for full results</span>
                                    </div>
                                </div>
                            }
                            {
                                <div className="col-block col-radio">
                                    <div className="form-inner">
                                        <label className="control-label">Select Fare Type</label>
                                        <div className="control-group">
                                            <div className="radio-check inline-check">
                                                <input 
                                                    name="pft" 
                                                    type="radio" 
                                                    id="pft1" 
                                                    onClick={(event) => this.getSelectedFareType(event)}
                                                    className="form-control"
                                                    value="REGULAR" 
                                                    defaultChecked={this.state.selectedFareType === 'REGULAR'? true: false}
                                                />
                                                <label htmlFor="pft1">Regular Fare</label></div><div className="radio-check inline-check label-tooltip student-fare-tooltip">
                                                <input 
                                                    name="pft" 
                                                    type="radio" 
                                                    id="pft2" 
                                                    onClick={(event) => this.getSelectedFareType(event)}
                                                    className="form-control"
                                                    value="STUDENT"
                                                    defaultChecked={this.state.selectedFareType === 'STUDENT'? true: false} 
                                                />
                                                <label htmlFor="pft2">
                                                <span className="">Student Fare
	                                           <div className="info-tooltip">
	                                        	<div className="tooltip-outer">
		                                         <p>Only students above 12 years of age are eligible for special fares and/or additional baggage allowances. Carrying valid student ID cards and student visas (where applicable) is mandatory, else the passenger may be denied boarding or asked to pay for extra baggage.</p>
	                                        	</div>
	                                                </div>
                                                    </span>
                                                </label></div><div className="radio-check inline-check label-tooltip senior-citizen-tooltip">
                                                <input 
                                                    name="pft" 
                                                    type="radio" 
                                                    id="pft3" 
                                                    onClick={(event) => this.getSelectedFareType(event)}
                                                    className="form-control"
                                                    value="SENIOR_CITIZEN"
                                                    defaultChecked={this.state.selectedFareType === 'SENIOR_CITIZEN'? true: false} 
                                                />
                                                <label htmlFor="pft3">
                                                <span className="">Senior Citizen Fare
                                            	<div className="info-tooltip">
	                                        	<div className="tooltip-outer">
		                                          <p>Only senior citizens above the age of 60 years can avail this special fare. It is mandatory to produce proof of Date of Birth at the airport, without which prevailing fares will be charged.</p>
		                                            </div>
	                                                </div>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                            }
                            {/* ------------------------------------------- Adult + Child + Infant Selector -------------------------------Start----- */}
                            <div className="col-block col-add-block">
                                <div className="col-plus-minus">
                                    <div className="form-inner">
                                        <label className="control-label"> Adult<span className="red-error">*</span> {this.state.selectedFareType === 'SENIOR_CITIZEN' ?  <small>(60+ Yrs)</small> :<small>(12+ Yrs)</small>}</label>
                                        <div className="group-input">
                                            <span onClick={() => this.onAdultClick('sub', setFieldValue)} className={this.state.adultCount !== 0 ? 'bold-active':'disabled-click'}><i className="fa fa-minus" aria-hidden="true" /></span>
                                            <small>{this.state.adultCount}</small>
                                            <span onClick={() => this.onAdultClick('add', setFieldValue)} className={(this.state.adultCount+this.state.childCount) < 9 ? 'bold-active':'disabled-click'}><i className="fa fa-plus" aria-hidden="true" /> </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-plus-minus mid-plus-minus">
                                    <div className="form-inner">
                                        <label className="control-label">Child <small>(2-12 Yrs)</small></label>
                                        <div className="group-input">
                                            <span onClick={() => this.onChildClick('sub', setFieldValue)} className={this.state.childCount !== 0 ? 'bold-active':'disabled-click'}><i className="fa fa-minus" aria-hidden="true" /></span>
                                            <small>{this.state.childCount}</small>
                                            <span onClick={() => this.onChildClick('add', setFieldValue)} className={(this.state.adultCount+this.state.childCount) < 9 ? 'bold-active':'disabled-click'}><i className="fa fa-plus" aria-hidden="true" /> </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-plus-minus">
                                    <div className="form-inner">
                                        <label className="control-label">Infant <small>(Below 2 Yrs)</small></label>
                                        <div className="group-input">
                                            <span onClick={() => this.onInfantClick('sub', setFieldValue)} className={this.state.infantCount !== 0 ? 'bold-active':'disabled-click'}><i className="fa fa-minus" aria-hidden="true" /></span>
                                            <small>{this.state.infantCount}</small>
                                            <span onClick={() => this.onInfantClick('add', setFieldValue)} className={this.state.infantCount < this.state.adultCount ? 'bold-active':'disabled-click'}><i className="fa fa-plus" aria-hidden="true" /> </span>
                                        </div>
                                    </div>
                                </div>
                                {errors.adult_count && touched.adult_count && <span className="traveller-error-msg">{errors.adult_count}</span>}
                            </div>
                        </div>
                    </div>
                    
                    <div className="bottom-search">
                        <div className="show-check">
                            {activeSarchType !== 'advance-search' &&
                                <React.Fragment>
                                <small className="checkbox">
                                <input defaultChecked={JSON.parse(isDirectFlight)} onClick={(event) => this.showDirectFligts(event,setFieldValue)} type="checkbox" /><label></label></small><span>Show Direct Flights</span>
                                </React.Fragment>
                            }
                            </div>
                            <div className="form-btn-block"><a onClick={handleSubmit} className="btn btn-green text-white" href="/"><i className="fa fa-search" aria-hidden="true"></i>Search</a></div>
                    </div>
                </div>
            </div>
        )
    }
}



export default FlightSearchActiveComponent