import React, { Component } from 'react';
import 'react-dates/initialize';
import moment from 'moment';
import { Field } from 'formik';
import {  DateRangePicker } from 'react-dates'
import Select from "react-virtualized-select";
import { getFormattedDate, getCurrentDate, getNewDateFormted } from '../../shared/commonHelper';

class HotelSearchActiveComponent extends Component {
    constructor(props){
        super(props);
        let check_in = props.searchQuery && props.searchQuery.check_in_date.split("/");
        let check_out = props.searchQuery && props.searchQuery.check_out_date.split("/");
        console.log();
        this.state = {
            adultsCount: 1,
            childCount: 0,
            childAge: [],
            focused:false,
            startDate: props.searchQuery? moment(new Date(check_in[2],check_in[1] - 1,check_in[0])):moment(getCurrentDate()),//check in date
            endDate:  props.searchQuery? moment(new Date(check_out[2],check_out[1] - 1,check_out[0])):moment(getCurrentDate()).add(1,'days'),//check out date
            selectedRoom: props.searchQuery?Number(props.searchQuery.no_of_rooms) : 1,
            selectedActiveRoom: props.searchQuery?Number(props.searchQuery.no_of_rooms) : 1,
            requiredRoom: props.searchQuery?props.searchQuery.no_of_rooms :'1',
            cityArray: [],
            roomGuests: props.searchQuery? 
            props.roomGuests
            :[
                {
                'adultsCount': 1,
                'childCount': 0,
                'childAge': null
                }
            ],
            selectedDestinationCity: props.searchQuery 
                                ? 
                                {
                                    value: props.searchQuery.city_name,//it is used for searching
                                    newValue: props.searchQuery.city_id,//set value
                                    label: props.searchQuery.city_name+'('+props.searchQuery.country_name+')',
                                    cityName: props.searchQuery.city_name,
                                    countryCode: props.searchQuery.country_code,
                                    countryName: props.searchQuery.country_name,
                                    api_calling: props.searchQuery.api_calling
                                } 
                                : 
                                null
        }
    }
    /*************************** Set Destination City Name ***************************/
    setDestinationCityName = (selectedDestinationCity, setFieldValue) =>
    {
        if(selectedDestinationCity)
        {
            setFieldValue('cityName', selectedDestinationCity.cityName);
            setFieldValue('destinationID', selectedDestinationCity.newValue);
            setFieldValue('countryName', selectedDestinationCity.countryName);
            setFieldValue('countryCode', selectedDestinationCity.countryCode);
            setFieldValue('api_calling', selectedDestinationCity.api_calling);
        }
        this.setState({ selectedDestinationCity });
    }

   //increment and decrement adult 
   onAdultClick = (type, setFieldValue) => {
        let oldcount =  this.state.adultsCount;
        let newcount = (type === 'add') ? ((oldcount < 8) ? (oldcount + 1) : oldcount ) : ((oldcount > 1) ? (oldcount - 1) : 1 );
        this.setState({adultsCount: newcount});
        setFieldValue("adultsCount", newcount );
        
        let cloneRoomGuestsArray = this.state.roomGuests; //creates the clone of the state
        cloneRoomGuestsArray[this.state.selectedActiveRoom-1]['adultsCount'] = newcount;
        this.setState({roomGuests: cloneRoomGuestsArray});
        this.props.setFieldValue('roomGuests', cloneRoomGuestsArray);
    }

    //increment and decrement child
    onChildClick = (type, setFieldValue) => {
        let oldcount = this.state.childCount;
        let newcount = (type === 'add') ? ((oldcount < 3) ? (oldcount + 1) : oldcount) : ((oldcount > 0) ? (oldcount - 1) : 0);
        this.setState({ childCount: newcount });
        setFieldValue("childCount", newcount);
    
        let cloneRoomGuestsArray = [...this.state.roomGuests]; //creates the clone of the state
        cloneRoomGuestsArray[this.state.selectedActiveRoom - 1]['childCount'] = newcount;
    
        if (!cloneRoomGuestsArray[this.state.selectedActiveRoom - 1]['childAge']) {
            cloneRoomGuestsArray[this.state.selectedActiveRoom - 1]['childAge'] = [];
        }
    
        if (newcount === 0) {
            cloneRoomGuestsArray[this.state.selectedActiveRoom - 1]['childAge'] = [];
        } else if (newcount < oldcount) {
            // If decreasing child count, remove last child age value
            cloneRoomGuestsArray[this.state.selectedActiveRoom - 1]['childAge'].pop();
        } else if (newcount > oldcount) {
            // If increasing child count, add '0' for the new child age
            cloneRoomGuestsArray[this.state.selectedActiveRoom - 1]['childAge'].push('0');
        }
    
        this.setState({ roomGuests: cloneRoomGuestsArray });
        this.props.setFieldValue('roomGuests', cloneRoomGuestsArray);
    }
    
    

    // get selected Room
    selectedRoom = (roomCount) => {
        let cloneRoomGuestsArray = this.state.roomGuests; //creates the clone of the state
        this.setState({
            selectedRoom: roomCount,
            selectedActiveRoom: roomCount,
            adultsCount: cloneRoomGuestsArray[roomCount-1]['adultsCount'],
            childCount: cloneRoomGuestsArray[roomCount-1]['childCount'],
        });
        this.props.setFieldValue('selectedActiveRoom', roomCount);
        this.props.setFieldValue('selectedRoom', roomCount);
    }

    selectedRoomGuest = (event) => {
        let roomGuests = [];
        for(let i = 0;i<event.target.value ;i++){
            roomGuests.push({
                'adultsCount': 1,
                'childCount': 0,
                'childAge': null
                });
        }
        this.setState({
            requiredRoom: event.target.value,
            selectedActiveRoom: 1,
            roomGuests: roomGuests,
            selectedRoom: parseInt(event.target.value),
            adultsCount: 1,
            childCount: 0
        });
        this.props.setFieldValue('roomGuests', roomGuests);
        this.props.setFieldValue('roomsCount', event.target.value);
        this.props.setFieldValue('selectedActiveRoom', 1);
        this.props.setFieldValue('selectedRoom', parseInt(event.target.value));
    }

    setChildAge = (event,childNumber) => {
        let cloneRoomGuestsArray = this.state.roomGuests; //creates the clone of the state
        if(!cloneRoomGuestsArray[this.state.selectedActiveRoom-1]['childAge'])
        {
            let childAge = [];
            childAge.push(event.target.value);
            cloneRoomGuestsArray[this.state.selectedActiveRoom-1]['childAge'] = childAge;
        }
        else if(!cloneRoomGuestsArray[this.state.selectedActiveRoom-1]['childAge'][childNumber-1])
        {
            cloneRoomGuestsArray[this.state.selectedActiveRoom-1]['childAge'].push(event.target.value);
        }
        else
        {
            cloneRoomGuestsArray[this.state.selectedActiveRoom-1]['childAge'][childNumber-1] = event.target.value;
        }
        this.setState({roomGuests: cloneRoomGuestsArray});
        this.props.setFieldValue('roomGuests', cloneRoomGuestsArray);
    }

    setTotalNights = (startDate, endDate) => {
        let date1 = getNewDateFormted(getFormattedDate(startDate));
        let date2 = getNewDateFormted(getFormattedDate(endDate));
        let timeDiff = (Math.abs(date2.getTime() - date1.getTime()))/1000;
        let nightsCount = startDate && endDate ? Math.floor(timeDiff / 86400):1;
        //let nightsCount = startDate && endDate && endDate.diff(moment(startDate).format('DD MMM YYYY'), 'days');
        startDate && endDate && this.props.setFieldValue("nightsCount", nightsCount.toString());
    }

    getHotelCitiesByFilter(filterString)
    {
        if(filterString && filterString.length > 2)
        {
            const successCb = () =>{
                let cityArray = []; 
                if(this.props.citiesResponse.response){
                    cityArray = this.props.citiesResponse.response.map(function (cityData, index) {
                        if(cityData.api_calling !== 'tbo'){
                            return ({
                                   value: cityData.destination,//it is used for searching
                                   newValue: cityData.city_id,//set value
                                   label: cityData.destination+'('+cityData.country+')',
                                   cityName: cityData.destination,
                                   countryName: cityData.country,
                                   countryCode: cityData.country_code,
                                   api_calling: cityData.api_calling
                           });
                        }
                        return false;
                        })
                }
                this.setState({cityArray: cityArray});
            }
            this.props.getHotelCitiesAction(filterString, successCb);
        }
    }

    componentDidMount()
    {
        this.getHotelCitiesByFilter('default');
        this.props.getNationalityAction();
    }
    
 
    render() { 
        const {handleSubmit, setFieldValue, handleChange, errors,values} = this.props;
        const {startDate, endDate, selectedDestinationCity, roomGuests, requiredRoom, cityArray, selectedActiveRoom} = this.state;
        let date1 = getNewDateFormted(getFormattedDate(startDate));
        let date2 = getNewDateFormted(getFormattedDate(endDate));
        let timeDiff = (Math.abs(date2.getTime() - date1.getTime()))/1000;
        let nightsCount = startDate && endDate ? Math.floor(timeDiff / 86400):1;
        //let nightsCount = startDate && endDate && endDate.diff(moment(startDate).format('DD MMM YYYY'), 'days');
        return ( 
            <div className="tab-content">
            <div id="advance-search" className="tab_contener">
                <div className="inneer-tab-section">
                    <h2>Book Domestic and International Hotels</h2>
                    <div className="mid-tab-form">
                        <div className="row-block">
                            <div className="col-block">
                                <div className="form-inner">
                                    <label className="control-label">Destination City<span className="red-error">*</span></label>
                                    <div className="control-group select-custom">
                                        <Field
                                            component={Select}
                                            value={selectedDestinationCity}
                                            options={cityArray}
                                            name="destinationID" 
                                            className="" 
                                            onBlur={this.destinationHandleBlur} 
                                            onChange={(event) => {this.setDestinationCityName(event, setFieldValue)}}
                                            onInputChange={(event) => {this.getHotelCitiesByFilter(event)}}
                                            placeholder="Search destination City..."
                                            openMenuOnFocus="true"
                                            tabIndex="0"
                                            tabSelectsValue={false}
                                        >
                                        </Field>
                                    </div>
                                    {errors.destinationID && <span className="error-msg">{errors.destinationID}</span>}
                                </div>
                            </div>
                            <div className="col-block">
                                <div className="form-inner">
                                    <label className="control-label">Nationality</label>
                                    <div className="control-group select-custom">
                                        <select className="form-control" name="guestNationality" onChange={handleChange} value={values.guestNationality}>
                                        {
                                            this.props.getNationalityDetails.response && this.props.getNationalityDetails.response.country_details
                                            && this.props.getNationalityDetails.response.country_details.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.short_name+","+item.tripjack_id}>{item.nationality}</option>
                                                )
                                            })
                                        }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-block date-col-block">
                                <div className="form-inner">
                                    <label className="control-label">Check In</label>
                                    <label className="control-label">Check Out<small className="book-room">{nightsCount} Nights</small></label>
                                    <div className="control-group">
                                        <div className="input-group">
                                            <DateRangePicker
                                                startDatePlaceholderText= "Check In Date"
                                                endDatePlaceholderText = "Check Out Date"
                                                startDate={this.state.startDate}
                                                startDateId="your_unique_start_date_id"
                                                endDate={this.state.endDate}
                                                endDateId="your_unique_end_date_id"
                                                onDatesChange={({ startDate, endDate }) => { 
                                                    this.setState({ startDate, endDate }); 
                                                    let checkInDate = startDate.format('DD/MM/YYYY');
                                                    setFieldValue("checkInDate", checkInDate);
                                                    let checkOutDate = endDate ? endDate.format('DD/MM/YYYY') : moment(startDate).add(1,'days').format('DD/MM/YYYY'); 
                                                    setFieldValue("checkOutDate", checkOutDate); 
                                                    this.setTotalNights(startDate, endDate);
                                                }}
                                                focusedInput={this.state.focusedInput}
                                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                displayFormat="DD MMM YYYY"
                                                readOnly={true}
                                                hideKeyboardShortcutsPanel={true}
                                                withPortal={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-block">
                                <div className="form-inner">
                                    <label className="control-label">Rooms</label>
                                    <div className="control-group select-custom">
                                        <select className="form-control" name="roomsCount" value={values.roomsCount} onChange={(event) => this.selectedRoomGuest(event)}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-block col-ht">
                                <div className="form-inner">
                                    <div className="room-list">
                                        <ul>
                                            <li className={(selectedActiveRoom === 1) ? "active" : (requiredRoom >= '1') ? "" : "hide"  }>
                                            { /* eslint-disable-next-line */}
                                                <a href="javascript:void(0);" onClick={() => this.selectedRoom(1)}>Room 1</a></li>
                                            <li className={(selectedActiveRoom === 2) ? "active" : (requiredRoom >= '2') ? "" : "hide"}>
                                            { /* eslint-disable-next-line */}
                                                <a href="javascript:void(0);" onClick={() => this.selectedRoom(2)}>Room 2</a></li>
                                            <li className={(selectedActiveRoom === 3) ? "active" : (requiredRoom >= '3') ? "" : "hide"}>
                                            { /* eslint-disable-next-line */}
                                                <a href="javascript:void(0);" onClick={() => this.selectedRoom(3)}>Room 3</a></li>
                                            <li className={(selectedActiveRoom === 4) ? "active" : (requiredRoom >= '4') ? "" : "hide"}>
                                            { /* eslint-disable-next-line */}
                                                <a href="javascript:void(0);" onClick={() => this.selectedRoom(4)}>Room 4</a></li>
                                            <li className={(selectedActiveRoom === 5) ? "active" : (requiredRoom >= '5') ? "" : "hide"}>
                                            { /* eslint-disable-next-line */}
                                                <a href="javascript:void(0);" onClick={() => this.selectedRoom(5)}>Room 5</a></li>
                                            <li className={(selectedActiveRoom === 6) ? "active" : (requiredRoom >= '6') ? "" : "hide"}>
                                            { /* eslint-disable-next-line */}
                                                <a href="javascript:void(0);" onClick={() => this.selectedRoom(6)}>Room 6</a></li>
                                        </ul>
                                    </div>
                                    <div className="control-group">
                                        <div className="col-plus-minus">
                                            <div className="form-col">
                                                <label className="control-label">Adult<span className="red-error">*</span> <small>(+18 yrs)</small> </label>
                                                <div className="group-input"> 
                                                <span onClick={() => this.onAdultClick('sub', setFieldValue)} className={this.state.adultsCount !== 1 ? 'bold-active':''}><i className="fa fa-minus" aria-hidden="true" /></span>
                                            <small>{roomGuests && roomGuests[selectedActiveRoom-1] && roomGuests[selectedActiveRoom-1]['adultsCount']}</small>
                                            <span onClick={() => this.onAdultClick('add', setFieldValue)} className={this.state.adultsCount < 8 ? 'bold-active':''}><i className="fa fa-plus" aria-hidden="true" /> </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-plus-minus inner-child-option">
                                            <div className="form-col">
                                                <label className="control-label">Child<span className="red-error">*</span> <small>(0 - 12 yrs)</small> </label>
                                                <div className="group-input show-child"> 
                                                <span onClick={() => this.onChildClick('sub', setFieldValue)} className={this.state.childCount !== 0 ? 'bold-active':''}><i className="fa fa-minus" aria-hidden="true" /></span>
                                                <small>{roomGuests && roomGuests[selectedActiveRoom-1] && roomGuests[selectedActiveRoom-1]['childCount']}</small>
                                                <span onClick={() => this.onChildClick('add', setFieldValue)} className={this.state.childCount < 3 ? 'bold-active':''}><i className="fa fa-plus" aria-hidden="true" /> </span>
                                                    
                                                </div>
                                                <div className="child-element">
                                                 {
                                                    roomGuests &&
                                                    roomGuests[selectedActiveRoom - 1] &&
                                                    roomGuests[selectedActiveRoom - 1]['childCount'] > 0 && <div className="age-label">Age of Child</div>
                                                 }   
                                                
                                                <div className="child-row">
                                                
                                                    {
                                                        roomGuests &&
                                                        roomGuests[selectedActiveRoom - 1] &&
                                                        roomGuests[selectedActiveRoom - 1]['childCount'] > 0 &&
                                                        Array.from({ length: roomGuests[selectedActiveRoom - 1]['childCount'] }).map((_, index) => (
                                                            <div className="child-col" key={`child-${index + 1}`}>
                                                                <label className="control-label" htmlFor={`age${index + 1}`}>{`Child ${index + 1}`}</label>
                                                                <div className="control-group select-custom">
                                                                    <select className={roomGuests[selectedActiveRoom - 1] &&
                                                                        roomGuests[selectedActiveRoom - 1]['childAge'][index] === '0' ? "form-control error-shadow" : "form-control"} 
                                                                    id={`age${index + 1}`}
                                                                    value={
                                                                        roomGuests[selectedActiveRoom - 1] &&
                                                                        roomGuests[selectedActiveRoom - 1]['childAge'][index]
                                                                    }
                                                                    name="childAge"
                                                                    onChange={(event) => this.setChildAge(event, index + 1)}
                                                                >
                                                                    <option value="0">Select</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                    <option value="11">11</option>
                                                                    <option value="12">12</option>
                                                                </select>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                               </div>
                                               {
                                                roomGuests && roomGuests[selectedActiveRoom - 1] && roomGuests[selectedActiveRoom - 1].childAge && roomGuests[selectedActiveRoom - 1].childAge.includes('0') ? <span style={{ color: 'red' }}>Please select an age</span> : ""
                                               }
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-block">
                                <div className="form-inner">
                                    <label className="control-label">Star Rating</label>
                                    <div className="control-group select-custom">
                                        <select className="form-control" name="hotelRating" onChange={handleChange} value={values.hotelRating}>
                                            <option value="0-5">Show All</option>
                                            <option value="0-1">1 Star or less</option>
                                            <option value="0-2">2 Star or less</option>
                                            <option value="0-3">3 Star or less</option>
                                            <option value="0-4">4 Star or less</option>
                                            <option value="0-5">5 Star or less</option>
                                            <option value="1-5">1 Star or More</option>
                                            <option value="2-5">2 Star or More</option>
                                            <option value="3-5">3 Star or More</option>
                                            <option value="4-5">4 Star or More</option>
                                            <option value="5-5">5 Star or More</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-block">
                                    <div className="form-inner">
                                        <label className="control-label">Preferred Hotel</label>
                                        <div className="control-group">
                                            <Field 
                                            className="form-control"
                                            type="text"
                                            value={values.preferredHotel}
                                            name="preferredHotel"
                                            placeholder={"Preferred Hotel"}
                                            /> 
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className={roomGuests && roomGuests[selectedActiveRoom - 1] && roomGuests[selectedActiveRoom - 1].childAge && roomGuests[selectedActiveRoom - 1].childAge.includes('0') ? "form-btn-block isButtonDisable" : "form-btn-block"}>
                        { /* eslint-disable-next-line */}
                        <a onClick={(e)=>{e.preventDefault();handleSubmit()}} className="btn btn-green" href="/"><i className="fa fa-search" aria-hidden="true" ></i>Search</a>
                    </div>
                </div>
            </div>
        </div>
    
                                    
        )
    }
}
export default HotelSearchActiveComponent;