import React from 'react';
import {currencyFormat} from '../../shared/commonHelper';
import * as flightHelper from '../../shared/flightHelper';
class FlightSearchDetailsLeftComponent extends React.Component {
    constructor(props) {
        super(props);
        const data = props && props.searchQuery && props.searchQuery.segments && props.searchQuery.segments.length
        this.state = {
            filters: Array.from({ length: data }, () => ({
                stops: [], 
                departTime: [], 
                fareType: [], 
                airlines: [], 
                layovers: [],
                destinations: [],
                segmentStops: [0],
                flight_number: null,
            })),
            filterIndex: 0,
        };
    }

    resetFilters = (event) => {
        const data = this.props && this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments.length
        const initialFilters = Array.from({ length: data }, () => ({
            stops: [], 
            departTime: [], 
            fareType: [], 
            airlines: [], 
            layovers: [],
            destinations: [],
            segmentStops: [0],
            flight_number: null,
        }));
    
        this.setState({ filters: initialFilters, filterIndex: 0 }, () => {
            this.props.filterFlightResults(this.state.filters);
        });
    }

    toggleFilterIndex = () => {
        this.setState({filterIndex: this.state.filterIndex === 0 ? 1 : 0 });
    }

    toggleFilter = (segmentId, filterGroup, filter, duplicateAirline,extractedAirline) => {
        let result = 2
        const containerInbound = document.querySelector('.page-containers-start-outbound');
              containerInbound && containerInbound.scrollTo(0, 0)
              const container = document.querySelector('.page-containers-start');
              container && container.scrollTo(0, 0)
              let liHeight=document.getElementById('calcHeight') && document.getElementById('calcHeight').clientHeight
                let filterHEight=document.getElementById('adv-filter');
                let cliHeight=filterHEight && filterHEight.clientHeight;
                let resultToREnder=liHeight ? Math.ceil(cliHeight/liHeight)+3 : 0
                let isResultToREnder = resultToREnder && resultToREnder > 15 ? resultToREnder : 15
        if(this.state.filterIndex===0){
            this.props.isFilterChecked(result)
            for (let i = 3; i <= isResultToREnder; i=i+3) {
              setTimeout(() => {
                this.props.isFilterChecked(i);
              }, 20 * (i - 1));
            }
        }else{
            this.props.isFilterCheckedInbound(result)
            for (let i = 3; i <= isResultToREnder; i=i+3) {
                setTimeout(() => {
                  this.props.isFilterCheckedInbound(i)
                }, 20 * (i - 1));
              }
        }
        let tmp = this.state.filters[segmentId][filterGroup];
        
        if(tmp.includes(filter))
        {
            let index = tmp.indexOf(filter);
            if (index > -1) {
                tmp.splice(index, 1);
            }
        }
        else
        {
            tmp.push(filter);
        }

        for (let i = 0; i < duplicateAirline.length; i++) {
            if (duplicateAirline[i].replace(/\s+/g, '').toLowerCase() === filter.replace(/\s+/g, '').toLowerCase() || filter === "G8") {
                filter = duplicateAirline[i];
                if (tmp.includes(filter)) {
                    let index = tmp.indexOf(filter);
                    if (index > -1) {
                        tmp.splice(index, 1);
                    }
                }
                else {
                    tmp.push(filter)
                }
                break;
            }
			for(let i=0;i<extractedAirline.length;i++){
                if (extractedAirline[i].replace(/\s+/g, '').toLowerCase() === filter.replace(/\s+/g, '').toLowerCase() || filter==="I5") {
    
                   filter = extractedAirline[i];
                   if (tmp.includes(filter)) {
                       let index = tmp.indexOf(filter);
                       if (index > -1) {
                           tmp.splice(index, 1);
                       }
                   }
                   else {
                       tmp.push(filter)
                   }
                   break;
               
            }
        }
        }

        let filters = this.state.filters;
        filters[segmentId] = {...this.state.filters[segmentId], [filterGroup]: tmp}

        this.setState({filters}, () => {
            this.props.filterFlightResults(JSON.parse(JSON.stringify(this.state.filters)))
        });
    }
    toggleSegmentStopsFilter = (segmentId, filterGroup, filter) => {
        const { filters } = this.state;
        const newFilters = filters.map((filterItem, index) => {
                if (index === segmentId) {
                    return {
                        ...filterItem,
                        [filterGroup]: filter === filterItem[filterGroup] ? [] : [filter],
                    };
                }
            return filterItem;
        });
        this.setState({filterIndex: filter });
        this.setState({ filters: newFilters }, () => {
            this.props.filterFlightResults(JSON.parse(JSON.stringify(newFilters)));
        });
    };

    toggleFlightNumberFilter = (filterGroup, filter) => {
        const containerInbound = document.querySelector('.page-containers-start-outbound');
        containerInbound && containerInbound.scrollTo(0, 0)
        const container = document.querySelector('.page-containers-start');
        container && container.scrollTo(0, 0)
        let liHeight=document.getElementById('calcHeight') && document.getElementById('calcHeight').clientHeight
          let filterHEight=document.getElementById('adv-filter');
          let cliHeight=filterHEight && filterHEight.clientHeight;
          let resultToREnder=liHeight ? Math.ceil(cliHeight/liHeight)+3 : 0
          let isResultToREnder = resultToREnder && resultToREnder > 15 ? resultToREnder : 15
          this.props.isFilterChecked(isResultToREnder)
        // Your scroll and height calculation logic remains unchanged...
        let filters = [...this.state.filters]; // Spread operator to clone the array
        filters[this.state.filterIndex][filterGroup] = filter; // Update the specific filterGroup with the new filter
        this.setState({ filters }, () => {
            this.props.filterFlightResults(JSON.parse(JSON.stringify(this.state.filters)));
        });
    }
    
    toggleOnwardReturnFilter = (segmentId, filterGroup, filter) => {
        const { filters } = this.state;
        const newFilters = filters.map((filterItem, index) => {
                if (index === segmentId) {
                    return {
                        ...filterItem,
                        [filterGroup]: filter === filterItem[filterGroup] ? [] : [filter],
                    };
                }
            return filterItem;
        });
        this.setState({filterIndex: this.state.filterIndex === 0 ? 1 : 0 });
        this.setState({ filters: newFilters }, () => {
            this.props.filterFlightResults(JSON.parse(JSON.stringify(newFilters)));
        });
    };
    componentDidMount(){
        let filterHEight=document.getElementById('adv-filter');
        let cliHeight=filterHEight && filterHEight.clientHeight;
        this.props.scroll_position({...this.props.track_scroll_position,cliHeight})
    }
    
    render() {
        
        return ( 
            <div className="left-side">
                <div className="filter-section">
                    <div className="filter-title"> 
                        { /* eslint-disable-next-line */}
                        <a href="javascript:void(0);" className="close-ui icon icon-arrow-left2"></a>
                        <h3>Filter Results</h3>
                        { /* eslint-disable-next-line */}
                        <a className="reset-link" href="javascript:void(0);" onClick={this.resetFilters}>
                            <span>Reset All</span> <i className="icon icon-refresh"></i>
                        </a>
                    </div>
                    <div className="inner-filter" id="adv-filter">
                        {
                            (this.props.searchQuery.journey_type === "2" && this.props.searchQuery.is_domestic === 'true') || (this.props.searchQuery.journey_type === "5" && this.props.searchQuery.is_domestic === 'true' && this.props.searchQuery.roundTripType === 'LCC') || (this.props.searchQuery.journey_type === "4" && this.props.searchQuery.advanceSearchType === "2")
                            ?
                            <div className="tab-filter">
                                <ul>
                                    <li className={this.state.filterIndex === 0 ? "active" : ""}>
                                    { /* eslint-disable-next-line */}
                                    <a href="/" onClick={(e)=>{e.preventDefault(); this.toggleFilterIndex()}}>Onward</a></li>
                                    <li className={this.state.filterIndex === 1 ? "active" : ""}>
                                    { /* eslint-disable-next-line */}
                                    <a href="/" onClick={(e)=>{e.preventDefault(); this.toggleFilterIndex()}}>Return</a></li>
                                </ul>
                            </div>
                            :
                            ""
                        }
                        {
                            this.props.searchQuery && (this.props.searchQuery.journey_type === "2" && this.props.searchQuery.is_domestic === 'false' &&  this.props.searchQuery.roundTripType === 'normalReturn' ) || (this.props.searchQuery.journey_type === "5" && this.props.searchQuery.is_domestic === 'false' && this.props.searchQuery.roundTripType === 'LCC')  ||  (this.props.searchQuery.journey_type === "5" && this.props.searchQuery.is_domestic === 'false' && this.props.searchQuery.roundTripType === 'GDS') 
                            ?
                            <div className="tab-filter">
                                <ul>
                                    <li className={flightHelper.makeFilterSelected(0, this.state.filters[this.state.filterIndex].segmentStops) ? "active" : ""}  onClick={this.toggleOnwardReturnFilter.bind(this, 0, 'segmentStops', 0)}>
                                    { /* eslint-disable-next-line */}
                                    <a href="/" onClick={(e)=>{e.preventDefault();}}>Onward</a></li>
                                    <li className={flightHelper.makeFilterSelected(1, this.state.filters[this.state.filterIndex].segmentStops) ? "active" : ""}  onClick={this.toggleOnwardReturnFilter.bind(this, 1, 'segmentStops', 1)}>
                                    { /* eslint-disable-next-line */}
                                    <a href="/" onClick={(e)=>{e.preventDefault();}}>Return</a></li>
                                </ul>
                            </div>
                            :
                            ""
                        }
                        {
                            this.props.searchQuery.journey_type === "3" 
                            ?
                            <AdvanceMultiCitySearchFilters {...this.props} {...this.state} toggleFilterIndex={this.toggleFilterIndex} toggleFilter={this.toggleFilter} toggleSegmentStopsFilter={this.toggleSegmentStopsFilter} toggleFlightNumberFilter={this.toggleFlightNumberFilter} resetSingleFilter={this.resetSingleFilter} />          
                            :
                            <AdvanceSearchFilters {...this.props} {...this.state} toggleFilterIndex={this.toggleFilterIndex} toggleFilter={this.toggleFilter}  toggleFlightNumberFilter={this.toggleFlightNumberFilter} resetSingleFilter={this.resetSingleFilter} />          
                        }
                    </div>
                </div>
            </div>
        )
    }
}


class AdvanceSearchFilters extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let totalResults = this.props.searchResult && this.props.searchResult.results;
        let filterIndex = this.props.filterIndex
        const self = this;
        const check = (this.props.searchQuery && this.props.searchQuery.is_domestic === 'false' && this.props.searchQuery.journey_url === 'round-trip')
        let filterUniqueAirlines = totalResults && flightHelper.getUniqueAirlines(totalResults[check ? 0 : this.props.filterIndex]);
        let filterUniqueAirlineCode = totalResults && flightHelper.getUniqueAirlinesCode(totalResults[check ? 0 : this.props.filterIndex]);
        let filterUniqueLayovers = totalResults && flightHelper.getAllUniqueLayovers(totalResults[check ? 0 : this.props.filterIndex]);
        let filterUniqueDestinations = totalResults && flightHelper.getAllUniqueDestination(totalResults[check ? 0 : this.props.filterIndex]);
        let filterMinPriceByStops = totalResults && this.props.searchQuery.journey_type !== "4" && flightHelper.getMinFareByFlightStops(totalResults[check ? 0 : this.props.filterIndex]);
        let tempArray = [];
        let uniqueAirline = [];
        let duplicateAirline =['GoAir'];
		let extractedAirline=['AirAsia India'];
        let flag = false;
        let count = 0;

        if (filterUniqueAirlines) {
            for (let i = 0; i < filterUniqueAirlines.length; i++) {
                tempArray[i] = filterUniqueAirlines[i].replace(/\s+/g, '').toLowerCase();
            }
            for (let j = 0; j < filterUniqueAirlines.length; j++) {
                for (let k = j; k < tempArray.length; k++) {
                    if (filterUniqueAirlines[j].replace(/\s+/g, '').toLowerCase() === tempArray[k + 1]) {
                        duplicateAirline.push(filterUniqueAirlines[j]);
                        flag = true;
                    }
                }
                if(filterUniqueAirlines[j] === "GoAir"){
                    duplicateAirline.push(filterUniqueAirlines[j]);
                    flag = true;
                }
				if(filterUniqueAirlines[j] === "AirAsia India"){
                    extractedAirline.push(filterUniqueAirlines[j]);
                    flag = true;
                }
                count++;
                if (count === 1 && flag === false) {
                    uniqueAirline.push(filterUniqueAirlines[j]);
                }
                flag = false;
                count = 0;
            }
        }
        return ( 
            <div className="filter-block">
                <div className="filter-col">
                    <h4>Stops</h4>
                    <div className="col-inner">
                        <div className="stop-section">
                            <ul className="stop-info">
                                <li className={flightHelper.makeFilterSelected('STOPS_0', this.props.filters[filterIndex].stops)+" is-filter"} 
                                onClick={this.props.toggleFilter.bind(this, filterIndex, 'stops', 'STOPS_0')} > 
                                { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"> 
                                        <span className="is-filter">0</span> 
                                        {   
                                            filterMinPriceByStops &&filterMinPriceByStops[0].length > 0 ? 
                                            <small className= "is-filter">
                                                <i className="fa fa-inr is-filter" aria-hidden="true"></i> 
                                                <samp className = "is-filter">{currencyFormat(filterMinPriceByStops[0][0])}</samp>
                                            </small> 
                                            : <small> <samp>-</samp></small> 
                                        }
                                    </a> 
                                </li>
                                <li className={flightHelper.makeFilterSelected('STOPS_1', this.props.filters[filterIndex].stops)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'stops', 'STOPS_1')} > 
                                { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"> 
                                        <span className="is-filter">1</span> 
                                        {
                                            filterMinPriceByStops && filterMinPriceByStops[1].length > 0 ? 
                                            <small className= "is-filter">
                                                <i className="fa fa-inr is-filter" aria-hidden="true"></i> 
                                                <samp className= "is-filter">{currencyFormat(filterMinPriceByStops[1][0])}</samp>
                                            </small> 
                                            : <small> <samp>-</samp></small> 
                                        }
                                    </a> 
                                </li>
                                <li className={flightHelper.makeFilterSelected('STOPS_2PLUS', this.props.filters[filterIndex].stops)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'stops', 'STOPS_2PLUS')} > 
                                { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"> 
                                        <span className="is-filter">2+</span> 
                                        {
                                            filterMinPriceByStops && filterMinPriceByStops[2].length > 0 ? 
                                            <small className= "is-filter">
                                                <i className="fa fa-inr is-filter" aria-hidden="true"></i> 
                                                <samp className= "is-filter">{currencyFormat(filterMinPriceByStops[2][0])}</samp>
                                            </small> 
                                            : <small> <samp>-</samp></small> 
                                        }
                                    </a> 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="filter-col">
                    <h4>depart time</h4>
                    <div className="col-inner">
                        <div className="depart-section">
                            <ul className="depart-info">
                                <li className={flightHelper.makeFilterSelected('00_06', this.props.filters[filterIndex].departTime)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'departTime', '00_06')}>
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"><i className="icon icon-sun2"></i> <span  className="is-filter">00-06</span> </a>
                                </li>
                                <li className={flightHelper.makeFilterSelected('06_12', this.props.filters[filterIndex].departTime)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'departTime', '06_12')}>
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"><i className="icon icon-sun1" aria-hidden="true"></i> <span  className="is-filter">06-12</span></a>
                                </li>
                                <li className={flightHelper.makeFilterSelected('12_18', this.props.filters[filterIndex].departTime)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'departTime', '12_18')}>
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"><i className="icon icon-sun3" aria-hidden="true"></i> <span  className="is-filter">12-18</span> </a>
                                </li>
                                <li className={flightHelper.makeFilterSelected('18_00', this.props.filters[filterIndex].departTime)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'departTime', '18_00')}>
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"><i className="icon icon-moon" aria-hidden="true"></i> <span  className="is-filter">18-00</span> </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="filter-col">
                    <h4>fare type</h4>
                    <div className="col-inner">
                        <div className="fare-section">
                            <ul className="fare-info">
                                <li className={flightHelper.makeFilterSelected('REFUNDABLE', this.props.filters[filterIndex].fareType)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'fareType', 'REFUNDABLE')}>
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"> <span  className="is-filter">Refundable</span> </a>
                                </li>
                                <li className={flightHelper.makeFilterSelected('NON_REFUNDABLE', this.props.filters[filterIndex].fareType)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'fareType', 'NON_REFUNDABLE')}>
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"> <span  className="is-filter">Non-Refundable</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="filter-col">
                    <h4>flight number</h4>
                    <div className="col-inner">
                        <div className="ft-hotel-name">
                            <div className="form-group">
                                <input  value={(this.props && this.props.filters && this.props.filters[filterIndex].flight_number) ? this.props.filters[filterIndex].flight_number : ''}  onChange={(event) => this.props.toggleFlightNumberFilter('flight_number', event.target.value)} className="form-control custom-filed" style={{padding: '9px 5px'}} placeholder="AI-123 or 123" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* { uniqueAirline && uniqueAirline.length > 0 ?
                    <div className="filter-col">
                        <h4>Airlines</h4>
                        <div className="col-inner">
                            <div className="list-section">
                                <ul className="list-info">
                                {
                                    uniqueAirline.map(function (airline, index) {
                                        return (
                                            <li key={index}>
                                                <div className="pull-left">
                                                    <div className="list-check"> 
                                                        <small className="checkbox">
                                                            <input type="checkbox" className="is-filter" checked={flightHelper.makeFilterSelected(airline, self.props.filters[filterIndex].airlines) === 'active'} onChange={self.props.toggleFilter.bind(self, filterIndex, 'airlines', airline, duplicateAirline, extractedAirline)}/>
                                                            <label></label>
                                                        </small> 
                                                        <span>{airline}</span> 
                                                    </div>
                                                </div>
                                            </li>
                                        )    
                                    })
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                    : ""
                } */}

                { filterUniqueAirlineCode && filterUniqueAirlineCode.length > 0 ?
                    <div className="filter-col">
                        <h4>Airlines</h4>
                        <div className="col-inner">
                            <div className="list-section">
                                <ul className="list-info">
                                {
                                    filterUniqueAirlineCode.sort((a, b) => (a.airline_name > b.airline_name) ? 1 : -1).map(function (airline, index) {
                                        return (
                                            <li key={index}>
                                                <div className="pull-left">
                                                    <div className="list-check"> 
                                                        <small className="checkbox">
                                                            <input type="checkbox" className="is-filter" checked={flightHelper.makeFilterSelected(airline.airline_code, self.props.filters[filterIndex].airlines) === 'active'} onChange={self.props.toggleFilter.bind(self, filterIndex, 'airlines', airline.airline_code)}/>
                                                            <label></label>
                                                        </small> 
                                                        <span>{airline.airline_name === 'AirAsia India' ? 'Air Asia' : airline.airline_name === 'GoAir' ? 'GO FIRST' : airline.airline_name === 'Indigo' ? 'IndiGo' : airline.airline_name === 'Go First' ? 'GO FIRST' : airline.airline_name}</span> 
                                                    </div>
                                                </div>
                                            </li>
                                        )    
                                    })
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                    : ""
                }
                { filterUniqueLayovers && filterUniqueLayovers.length > 0 ?
                    <div className="filter-col">
                        <h4>Layovers</h4>
                        <div className="col-inner">
                            <div className="list-section">
                                <ul className="list-info">
                                {
                                    filterUniqueLayovers.sort((a, b) => (a[1] > b[1]) ? 1 : -1).map(function (city, index) {
                                        return (
                                            <li key={index}>
                                                <div className="pull-left">
                                                    <div className="list-check"> 
                                                        <small className="checkbox">
                                                            <input type="checkbox" className="is-filter" checked={flightHelper.makeFilterSelected(city[0], self.props.filters[filterIndex].layovers) === 'active'} onChange={self.props.toggleFilter.bind(self, filterIndex, 'layovers', city[0])}/>
                                                            <label></label>
                                                        </small> 
                                                        <span>{city[1]} </span> 
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                    : ""
                }

                { filterUniqueDestinations && filterUniqueDestinations.length > 1 ?
                    <div className="filter-col">
                        <h4>Destinations</h4>
                        <div className="col-inner">
                            <div className="list-section">
                                <ul className="list-info">
                                {
                                    filterUniqueDestinations.sort((a, b) => (a[1] > b[1]) ? 1 : -1).map(function (city, index) {
                                        return (
                                            <li key={index}>
                                                <div className="pull-left">
                                                    <div className="list-check"> 
                                                        <small className="checkbox">
                                                            <input type="checkbox" className="is-filter" checked={flightHelper.makeFilterSelected(city[0], self.props.filters[filterIndex].destinations) === 'active'} onChange={self.props.toggleFilter.bind(self, filterIndex, 'destinations', city[0])}/>
                                                            <label></label>
                                                        </small> 
                                                        <span>{city[1]} </span> 
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                    : ""
                }
            </div>
        )
    }
}

class AdvanceMultiCitySearchFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let totalResults = this.props.searchResult && this.props.searchResult.results;
        let filterIndex =  this.props.filterIndex
        const self = this;
        const check = (this.props.searchQuery && this.props.searchQuery.is_domestic === 'false')
        let filterUniqueAirlines = totalResults && flightHelper.getUniqueAirlines(totalResults[check ? 0 : filterIndex]);
        let filterUniqueAirlineCode = totalResults && flightHelper.getUniqueAirlinesCode(totalResults[check ? 0 : filterIndex]);
        let filterUniqueLayovers = totalResults && flightHelper.getAllUniqueLayovers(totalResults[check ? 0 : filterIndex]);
        let filterUniqueDestinations = totalResults && flightHelper.getAllUniqueDestination(totalResults[check ? 0 : filterIndex]);
        let filterMinPriceByStops = totalResults && this.props.searchQuery.journey_type !== "4" && flightHelper.getMinFareByFlightStopsMulti(totalResults[check ? 0 : filterIndex]);
        let tempArray = [];
        let uniqueAirline = [];
        let duplicateAirline =['GoAir'];
		let extractedAirline=['AirAsia India'];
        let flag = false;
        let count = 0;

        if (filterUniqueAirlines) {
            for (let i = 0; i < filterUniqueAirlines.length; i++) {
                tempArray[i] = filterUniqueAirlines[i].replace(/\s+/g, '').toLowerCase();
            }
            for (let j = 0; j < filterUniqueAirlines.length; j++) {
                for (let k = j; k < tempArray.length; k++) {
                    if (filterUniqueAirlines[j].replace(/\s+/g, '').toLowerCase() === tempArray[k + 1]) {
                        duplicateAirline.push(filterUniqueAirlines[j]);
                        flag = true;
                    }
                }
                if(filterUniqueAirlines[j] === "GoAir"){
                    duplicateAirline.push(filterUniqueAirlines[j]);
                    flag = true;
                }
				if(filterUniqueAirlines[j] === "AirAsia India"){
                    extractedAirline.push(filterUniqueAirlines[j]);
                    flag = true;
                }
                count++;
                if (count === 1 && flag === false) {
                    uniqueAirline.push(filterUniqueAirlines[j]);
                }
                flag = false;
                count = 0;
            }
        }
        return(
            <div className="filter-block">
                {
                    this.props.searchQuery && this.props.searchQuery.is_domestic === 'false' &&
                    <div className="filter-col">
                        <h4>Segments</h4>
                        <div className="col-inner">
                            <div className="fare-section">
                                <ul className="fare-info">
                                    {
                                        this.props.searchQuery && this.props.searchQuery.segments && this.props.searchQuery.segments.map((item, index) => {
                                            return (
                                                <li className={flightHelper.makeFilterSelected(index, this.props.filters[filterIndex].segmentStops) + " is-filter"} key={index}
                                                    onClick={this.props.toggleSegmentStopsFilter.bind(this, index, 'segmentStops', index)}>
                                                    { /* eslint-disable-next-line */}
                                                    <a href="/" onClick={(e) => e.preventDefault()} className="is-filter"> <span className="is-filter">{(item && item.origin) + '-' + (item && item.destination)}</span> </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                <div className="filter-col">
                    <h4>Stops</h4>
                    <div className="col-inner">
                        <div className="stop-section">
                            <ul className="stop-info">
                                <li className={flightHelper.makeFilterSelected('STOPS_0', this.props.filters[filterIndex].stops)+" is-filter"} 
                                onClick={this.props.toggleFilter.bind(this, filterIndex, 'stops', 'STOPS_0')} > 
                                { /* eslint-disable-next-line */}
                                    <a href="/" onClick={(e)=>e.preventDefault()} className="is-filter"> 
                                        <span className="is-filter">0</span> 
                                        {   
                                            filterMinPriceByStops &&filterMinPriceByStops[0].length > 0 ? 
                                            <small className= "is-filter">
                                                <i className="fa fa-inr is-filter" aria-hidden="true"></i> 
                                                <samp className = "is-filter">{currencyFormat(filterMinPriceByStops[0][0])}</samp>
                                            </small> 
                                            : <small> <samp>-</samp></small> 
                                        }
                                    </a> 
                                </li>
                                <li className={flightHelper.makeFilterSelected('STOPS_1', this.props.filters[filterIndex].stops)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'stops', 'STOPS_1')} > 
                                { /* eslint-disable-next-line */}
                                    <a href="/" onClick={(e)=>e.preventDefault()} className="is-filter"> 
                                        <span className="is-filter">1</span> 
                                        {
                                            filterMinPriceByStops && filterMinPriceByStops[1].length > 0 ? 
                                            <small className= "is-filter">
                                                <i className="fa fa-inr is-filter" aria-hidden="true"></i> 
                                                <samp className= "is-filter">{currencyFormat(filterMinPriceByStops[1][0])}</samp>
                                            </small> 
                                            : <small> <samp>-</samp></small> 
                                        }
                                    </a> 
                                </li>
                                <li className={flightHelper.makeFilterSelected('STOPS_2PLUS', this.props.filters[filterIndex].stops)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'stops', 'STOPS_2PLUS')} > 
                                { /* eslint-disable-next-line */}
                                    <a href="/" onClick={(e)=>e.preventDefault()} className="is-filter"> 
                                        <span className="is-filter">2+</span> 
                                        {
                                            filterMinPriceByStops && filterMinPriceByStops[2].length > 0 ? 
                                            <small className= "is-filter">
                                                <i className="fa fa-inr is-filter" aria-hidden="true"></i> 
                                                <samp className= "is-filter">{currencyFormat(filterMinPriceByStops[2][0])}</samp>
                                            </small> 
                                            : <small> <samp>-</samp></small> 
                                        }
                                    </a> 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="filter-col">
                    <h4>depart time</h4>
                    <div className="col-inner">
                        <div className="depart-section">
                            <ul className="depart-info">
                                <li className={flightHelper.makeFilterSelected('00_06', this.props.filters[filterIndex].departTime)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'departTime', '00_06')}>
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"><i className="icon icon-sun2"></i> <span className="is-filter">00-06</span> </a>
                                </li>
                                <li className={flightHelper.makeFilterSelected('06_12', this.props.filters[filterIndex].departTime)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'departTime', '06_12')}>
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"><i className="icon icon-sun1" aria-hidden="true"></i> <span className="is-filter">06-12</span></a>
                                </li>
                                <li className={flightHelper.makeFilterSelected('12_18', this.props.filters[filterIndex].departTime)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'departTime', '12_18')}>
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"><i className="icon icon-sun3" aria-hidden="true"></i> <span className="is-filter">12-18</span> </a>
                                </li>
                                <li className={flightHelper.makeFilterSelected('18_00', this.props.filters[filterIndex].departTime)+" is-filter"} onClick={this.props.toggleFilter.bind(this, filterIndex, 'departTime', '18_00')}>
                                    { /* eslint-disable-next-line */}
                                    <a href="javascript:void(0);" className="is-filter"><i className="icon icon-moon" aria-hidden="true"></i> <span className="is-filter">18-00</span> </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="filter-col">
                    <h4>flight number</h4>
                    <div className="col-inner">
                        <div className="ft-hotel-name">
                            <div className="form-group">
                                <input  value={(this.props && this.props.filters && this.props.filters[filterIndex].flight_number) ? this.props.filters[filterIndex].flight_number : ''}  onChange={(event) => this.props.toggleFlightNumberFilter('flight_number', event.target.value)} className="form-control custom-filed" style={{padding: '9px 5px'}} placeholder="AI-123 or 123" />
                            </div>
                        </div>
                    </div>
                </div>
                { filterUniqueAirlineCode && filterUniqueAirlineCode.length > 0 ?
                    <div className="filter-col">
                        <h4>Airlines</h4>
                        <div className="col-inner">
                            <div className="list-section">
                                <ul className="list-info">
                                {
                                    filterUniqueAirlineCode.sort((a, b) => (a.airline_name > b.airline_name) ? 1 : -1).map(function (airline, index) {
                                        return (
                                            <li key={index}>
                                                <div className="pull-left">
                                                    <div className="list-check"> 
                                                        <small className="checkbox">
                                                            <input type="checkbox" className="is-filter" checked={flightHelper.makeFilterSelected(airline.airline_code, self.props.filters[filterIndex].airlines) === 'active'} onChange={self.props.toggleFilter.bind(self, filterIndex, 'airlines', airline.airline_code)}/>
                                                            <label></label>
                                                        </small> 
                                                        <span>{airline.airline_name === 'AirAsia India' ? 'Air Asia' : airline.airline_name === 'GoAir' ? 'GO FIRST' : airline.airline_name === 'Indigo' ? 'IndiGo' : airline.airline_name === 'Go First' ? 'GO FIRST' : airline.airline_name}</span> 
                                                    </div>
                                                </div>
                                            </li>
                                        )    
                                    })
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                    : ""
                }
                { filterUniqueLayovers && filterUniqueLayovers.length > 0 ?
                    <div className="filter-col">
                        <h4>Layovers</h4>
                        <div className="col-inner">
                            <div className="list-section">
                                <ul className="list-info">
                                {
                                    filterUniqueLayovers.sort((a, b) => (a[1] > b[1]) ? 1 : -1).map(function (city, index) {
                                        return (
                                            <li key={index}>
                                                <div className="pull-left">
                                                    <div className="list-check"> 
                                                        <small className="checkbox">
                                                            <input type="checkbox" className="is-filter" checked={flightHelper.makeFilterSelected(city[0], self.props.filters[filterIndex].layovers) === 'active'} onChange={self.props.toggleFilter.bind(self, filterIndex, 'layovers', city[0])}/>
                                                            <label></label>
                                                        </small> 
                                                        <span>{city[1]} </span> 
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                    : ""
                }

                { filterUniqueDestinations && filterUniqueDestinations.length > 1 ?
                    <div className="filter-col">
                        <h4>Destinations</h4>
                        <div className="col-inner">
                            <div className="list-section">
                                <ul className="list-info">
                                {
                                    filterUniqueDestinations.sort((a, b) => (a[1] > b[1]) ? 1 : -1).map(function (city, index) {
                                        return (
                                            <li key={index}>
                                                <div className="pull-left">
                                                    <div className="list-check"> 
                                                        <small className="checkbox">
                                                            <input type="checkbox" className="is-filter" checked={flightHelper.makeFilterSelected(city[0], self.props.filters[filterIndex].destinations) === 'active'} onChange={self.props.toggleFilter.bind(self, filterIndex, 'destinations', city[0])}/>
                                                            <label></label>
                                                        </small> 
                                                        <span>{city[1]} </span> 
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                    : ""
                }
            </div>
        )
    }
}

export default FlightSearchDetailsLeftComponent