import React, { Component } from 'react';
import Link from 'react-router-dom/es/Link';
import 'react-dates/initialize';
import { dateFormat } from '../../../shared/commonHelper';
import { Alert } from 'reactstrap';

class ViewVendorComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmationPopup: false,
            vendorData: {vendorId: null, status: null},
            modalLoading: false,
            messageResponse: {msg: null, color: null}
        }
      
    }
    componentDidMount(){
        this.props.viewVendorBookings();
    }

    enableDisableBooking = (e, vendorId) =>{

        this.setState({vendorData: {vendorId: vendorId, status: e.target.checked}, showConfirmationPopup: true});
    }

    clearEnableDisableBooking = () =>{
        this.setState({vendorData: {vendorId: null, status: null}, showConfirmationPopup: false, modalLoading: false});
    }

    submitEnableDisableBooking = () =>{
        this.setState({modalLoading: true});
        const {vendorData} = this.state;
        const {vendorId, status} = vendorData;

        const submitData = {
            status: ((status === true) ? 'N' : 'Y')
        }

        const that = this;

        const successCb = () =>{
            this.setState({messageResponse: {msg: 'Vendor bookings updated successfully.', color: 'success'}, modalLoading: false, showConfirmationPopup: false});
            setTimeout(function()
            {
                that.setState({messageResponse: {msg: null, color: null}});
            }, 3000);
            this.props.viewVendorBookings();
        }

        const errorCb = ({errors, message, status}) =>{
            this.setState({messageResponse: {msg: message, color: 'danger'}, modalLoading: false, showConfirmationPopup: false});
            setTimeout(function()
            {
                that.setState({messageResponse: {msg: null, color: null}});
            }, 3000);
        }
        
        this.props.editStatusVendorBookings(vendorId, submitData, successCb, errorCb);
    }

    render() {
        //console.log(this.props);
        const {viewVendorResponse} = this.props;
        const {showConfirmationPopup, modalLoading, messageResponse} = this.state;
        const {msg, color} = messageResponse;
        return (
            <div className="profile-detail-info">
                <div className="edit-profile-bar">
                    <div className="pull-right">
                    { /* eslint-disable-next-line */}
                        <a href="javascript:void(0);" className="btn btn-sky-blue profile-btn">
                        <i className="icon icon-menu"></i>Profile Menus</a>
                    </div>
                </div>
                <div className="tabs-container-detail">
                    <div className="statement-info">
                        <div className="tabs-top">
                            <div className="tabs-title">
                                <div className="pull-right"> 
                                <Link to ="add-vendor-booking" className="sm-btn blue-sm-btn">New Vendor Booking</Link> 
                                </div>
                                <h2><i className="icon icon-money"></i><span>Vendor Bookings</span></h2>
                            </div> 
                        </div>
                        {
                            this.props.deleteSuccess &&
                            <div className="alert alert-success">
                                <strong> <i className="fa fa-check-circle"></i> Booking is deleted successfully. </strong> 
                            </div>
                        }
                        {
                            msg && <Alert color={color}>{msg}</Alert>
                        }
                        
                        <div className="statement-outer">                        
                            <div className="balance-sheet-info">
                                <div className="balance-table">
                                    <div className="table-outer">
                                        <div className="table-container">
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Origin</th>
                                                    <th>Destination</th>
                                                    <th>Travel From</th>
                                                    <th>Travel To</th>
                                                    <th>Flight No.</th>
                                                    <th>Status</th>
                                                    <th>Disable</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               
                                                {viewVendorResponse.response &&  
                                                ((viewVendorResponse.response.vendor_list.length > 0) 
                                                ? viewVendorResponse.response.vendor_list.map((item,key) => {
                                                        let status="";
                                                        let statusColor = '';
                                                        if(item.status === 'A'){
                                                            status = "Approved"; 
                                                            statusColor = "#3aaa35";
                                                        }
                                                        else if(item.status === 'D'){
                                                            status = "Declined";
                                                            statusColor = "#FF0000";
                                                        }
                                                        else if(item.status === 'P'){
                                                            status = "Pending";
                                                            statusColor = "#ffc107";
                                                        }
                                                        let travelDateFrom = item.travel_date_from;
                                                        if(!travelDateFrom && item.seat_info && item.seat_info.length > 0 && item.seat_info[0].travel_date_from){
                                                            travelDateFrom = item.seat_info[0].travel_date_from;
                                                        }
                                                        let travelDateTo = item.travel_date_to;
                                                        let lastIndex = 0;
                                                        if(item.seat_info && item.seat_info.length > 0)
                                                        {
                                                            lastIndex = (item.seat_info.length) - 1;
                                                        }
                                                        if(!travelDateTo && item.seat_info && item.seat_info.length > 0 && item.seat_info[lastIndex].travel_date_to){
                                                            travelDateTo = item.seat_info[lastIndex].travel_date_to;
                                                        }
                                                        return (
                                                            <tr key={key}>
                                                                <td>{Number(key) + 1}</td>
                                                                <td>{item.origin}</td>
                                                                <td>{item.destination}</td>
                                                                <td>{travelDateFrom ? dateFormat(travelDateFrom,"DD MMM YYYY, FDAY") : '-'}</td>
                                                                <td>{travelDateTo ? dateFormat(travelDateTo,"DD MMM YYYY, FDAY") : '-' }</td>
                                                                <td>
                                                                    {
                                                                        item.segments && item.segments.map((segment,segmentKey) => {
                                                                            return(
                                                                                <p key={segmentKey}>{segment.airline_code} - {segment.flight_no}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </td>
                                                                <td style={{ color : statusColor}}>{status}</td>
                                                                <td>
                                                                    <div className="checkbox check-refund">
                                                                        <input name="is_refundable" type="checkbox" className="form-control" onChange={(event) => {
                                                                            this.enableDisableBooking(event, item.vendor_id)
                                                                        }} defaultChecked={item.enable_status === 'N' ? true : false} />
                                                                        <label />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    { /* eslint-disable-next-line */}
                                                                    <a href="javascript:void(0);" onClick={() => {
                                                                        this.props.history.push({
                                                                            pathname: "/view-booking-detail",
                                                                            state: {
                                                                                data : item
                                                                            }
                                                                        });}} style={{marginRight:'4px'}}><i className="fa fa-eye"></i></a>
                                                                    { /* eslint-disable-next-line */}
                                                                    <a href="javascript:void(0);" onClick={() => {
                                                                        this.props.history.push({
                                                                            pathname: "/edit-vendor-booking",
                                                                            state: {
                                                                                data : item
                                                                            }
                                                                        });
                                                                    }} style={{marginRight:'4px'}}><i className="fa fa-edit"></i></a>

                                                                    { /* eslint-disable-next-line */}
                                                                    <a href="javascript:void(0);" onClick={() => {
                                                                        this.props.handleConfirmationPopup(true,{
                                                                            'vendor_id' : item.vendor_id
                                                                        });
                                                                    }}><i className="fa fa-trash"></i></a>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }):<tr><td colSpan="9"><span className="no-records">There is no vendor bookings.</span></td></tr>)
                                                } 
                                            </tbody>
                                            </table>                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="table-footer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 {/* Popup for credit confirmation */}
                <div className={`popup-crad confirmation-popup ${showConfirmationPopup ? 'show' : ''}`}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>Top Up Confirmation</h2>
                                <div className="popup-close" onClick={() => this.clearEnableDisableBooking()}><span className="icon icon-cross"/></div>
                            </div>							
                            <div className="popup-body popup-scorll">
                                {
                                    !modalLoading &&
                                    <div className="inner-confirmation">
                                        <p>Are you sure you want to continue?</p>
                                        <div className="popup-footer">
                                            <button className="btn btn-orange" onClick={() => this.submitEnableDisableBooking()}>Yes</button>
                                            <button className="btn btn-grey" onClick={() => this.clearEnableDisableBooking()}>No</button>
                                        </div>
                                    </div>
                                }
                                {
                                    modalLoading &&
                                    <div className="loader ssr-loader relative-loader" style={{display:'block'}}>
                                        <div className="loader-content">
                                                <div className="loader-ring blue-ring"> 
                                                    <span> </span>
                                                </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay"/>
                </div>
            </div>
        )
    }
}

export default ViewVendorComponent