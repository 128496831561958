import React, { Component } from 'react';
import HotelLeftSearch from './HotelSearchDetailsLeftComponent';
import HotelSearchHeaderComponent from './HotelSearchHeaderComponent';
import { currencyFormat, dateFormat} from '../../shared/commonHelper';
import * as hotelHelper from '../../shared/hotelHelper';
import CarouselSlider from '../shared/CarouselComponent';
import GoogleMap from './HotelGoogleMapComponent';
import PerfectScrollbar from 'react-perfect-scrollbar'



class HotelSearchDetailsComponent extends Component {	
	constructor(props) {
        super(props);
        this.state = {
			limit : 10,
			offeredPrice : false,
			offeredPriceHotelRooms : false,
			sortField: '',
			sortAscOrder: false,
			currentRateBreakup: '',
			currentCancellationPolicy: '',
			hotelDetailData : null,
			sortingIcon: "icon icon-caret-top high-price",
			emailPopup: false,
			sendMailValues : [],
			includeFare: false,
			sortRoomField: null,
			hotelFilters : {
				pan_not_required : false,
				free_cancellation : false,
				inclusion : []
			},
			mealDropdown :false
		};
	}
	offeredPriceClick() {
        this.setState({offeredPrice: !this.state.offeredPrice});
	}
	showHideEmailPopup() {
        this.setState({emailPopup: !this.state.emailPopup});
	}
	offeredPriceHotelRooms() {
        this.setState({offeredPriceHotelRooms: !this.state.offeredPriceHotelRooms});
	}
	
	getHotelRatings = (hotel_rating) => {
		let ratingLi = [];
		for(let i=0; i<hotel_rating; i++) {
			ratingLi.push(<li key={i}><i className="icon icon-star-full" /></li>)
		}
		return ratingLi;
	}
	loadMoreData = () => {
		const hotelSearchResult = this.props.hotelSearchResult && this.props.hotelSearchResult.hotel_results
        const nextResultsToShow = Math.min(this.props.isFilterCheckedStatus + 25, hotelSearchResult && hotelSearchResult.length);
        this.props.isFilterChecked(nextResultsToShow)
    };

	trackScrolling = (event) => {
		
        let section = document.getElementsByClassName("page-container")[0];
        let footer = document.getElementsByTagName("footer")[0];
        let pageScorllRemaining = (section.scrollHeight + footer.scrollHeight - window.pageYOffset - window.innerHeight);
        if (pageScorllRemaining-3 < footer.scrollHeight) {
            this.loadMoreData();
        }
	}
	trackScrollingM = () => {
        const container = document.querySelector('.page-containers-start');
        const footer = document.querySelector('.footers-end');
		const hotelSearchResult = this.props.hotelSearchResult && this.props.hotelSearchResult.hotel_results && hotelHelper.filterResults(this.props.hotelAdvanceSearchFilter, this.props.hotelSearchResult.hotel_results);

        if (container && footer) {
            const containerScrollRemaining =
                container.scrollHeight - container.scrollTop - container.clientHeight;
            const footerHeight = footer.scrollHeight;
            if (containerScrollRemaining - 3 < footerHeight) {
                this.loadMoreData();
            }
        }
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }

        this.scrollTimeout = setTimeout(() => {
            if (container && container.scrollHeight < 200) {
                this.props.scroll_position({ ...this.props.track_scroll_position,childContainer: "" });
            } else {
                this.props.scroll_position({  ...this.props.track_scroll_position,childContainer: 1 });
            }
        }, 500);
    };
	handleResize = () => {
        if (window.innerWidth <= 766) {
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.remove('page-containers-start')
            document.addEventListener('scroll', this.trackScrolling);
        } else {
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start')
            document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
        }
    }
	trackContainerScrolling =() =>{
        let section = document.getElementsByClassName("page-container")[0];
        let footer = document.getElementsByTagName("footer")[0];
        let pageScorllRemaining = (section.scrollHeight + footer.scrollHeight - window.pageYOffset - window.innerHeight);
        this.props.scroll_position({...this.props.track_scroll_position,parentContainer:pageScorllRemaining})
      }

	componentDidMount() {
		let liHeight=document.getElementById('calcHeight') && document.getElementById('calcHeight').clientHeight
        let filterHEight=document.getElementById('adv-filter');
        let cliHeight=filterHEight && filterHEight.clientHeight;
        let resultToREnder=liHeight ? Math.ceil(cliHeight/liHeight)+3 : 0
        let isResultToREnder = resultToREnder && resultToREnder > 20 ? resultToREnder : 20;
		for (let initialResults = 6; initialResults <= isResultToREnder; initialResults=initialResults+3) {
			setTimeout(() => {
			  this.props.isFilterChecked(initialResults);
			}, 10 * (initialResults - 1));
		  }
		  window.addEventListener('resize',this.handleResize)
          if(window.innerWidth <= 766){
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.remove('page-containers-start')
            document.addEventListener('scroll', this.trackScrolling);
          }else{
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start');
            document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
          }
		document.addEventListener('scroll', this.trackContainerScrolling);
	}
	componentDidUpdate() {
		if(document.getElementsByClassName("page-containers-start") && document.getElementsByClassName("page-containers-start").length === 0){
			if (window.innerWidth <= 766) {
			} else {
				document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start');
				document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
			}
		}
	}

	componentWillUnmount() {
        if(window.innerWidth <= 766){
            document.removeEventListener('scroll', this.trackScrolling);
        }else{
            this.setState({containerResults:0})
            document.getElementById('cont-st') && document.getElementById('cont-st').removeEventListener('scroll', this.trackScrollingM);
        }
        window.removeEventListener('resize',this.handleResize)
		document.removeEventListener('scroll', this.trackContainerScrolling);
    }

	// Function For sort by room details
	sortHotelRoomResults = (event,type) => {
		var div = document.querySelector('.all-rooms'),
        para = document.querySelectorAll('.all-rooms ul.single-room');
		var paraArr = [].slice.call(para).sort(function (a, b) {
			if(event.target.checked === true)
			{
				return parseInt(a.getAttribute('data-'+type)) <= parseInt(b.getAttribute('data-'+type)) ? 1 : -1;
			}
			else
			{
				return parseInt(a.getAttribute('data-'+type)) >= parseInt(b.getAttribute('data-'+type)) ? 1 : -1;
			}
		});
		paraArr.forEach(function (p) {
			div.appendChild(p);
		});
	}

	// reset room filter
	resetRoomFilter = () => {
		// document.getElementById("inclusion-1").checked = false;
		// document.getElementById("free-cancellation-1").checked = false;
		document.getElementById("price-1").checked = false;
		var div = document.querySelector('.all-rooms'),
        para = document.querySelectorAll('.all-rooms ul.single-room');
		var paraArr = [].slice.call(para).sort(function (a, b) {
			return parseInt(a.getAttribute('data-price')) >= parseInt(b.getAttribute('data-price')) ? 1 : -1;
		});
		paraArr.forEach(function (p) {
			div.appendChild(p);
		});
	}

	//function to set states for sorting
	sortHotelResults = (type) => {
		if(this.state.sortField === '' && type === 'Price') { //when clicked price for first time and this.state.sortField is not set yet then sort desc because results are already displaying in asc order by price
			this.setState({ 
				sortingIcon: "icon icon-caret-top low-price",
				sortAscOrder: false
			});
		} else {
			if(this.state.sortField !== type) {
				if(type === 'Rating') {
					this.setState({ 
					sortingIcon: "icon icon-caret-top low-price",
					sortAscOrder: false
					});
				} else {
					this.setState({
						sortingIcon: "icon icon-caret-top high-price",
						sortAscOrder: true
				});
			}
		} else {
			this.setState({sortAscOrder: !this.state.sortAscOrder}, function() {
				if(type === 'Rating') {
					if(!this.state.sortAscOrder) {
						this.setState({ sortingIcon: "icon icon-caret-top low-price" });
					} else {
						this.setState({ sortingIcon: "icon icon-caret-top high-price" });
					}
				} else {
					if(this.state.sortAscOrder) {
						this.setState({ sortingIcon: "icon icon-caret-top high-price" });
					} else {
						this.setState({ sortingIcon: "icon icon-caret-top low-price" });
						}
					}
				});
			} 
		}		

		this.setState({sortField: type});
    }

	//function to sort passed fields as per their type
    sortByFieldType = (a, b, sortType) => {
		//if clicked column is string type then compare after conversion in lowercase otherwise directly get the difference
		if(sortType === 'string') {
			const diff = a && a.toLowerCase() && a.toLowerCase().localeCompare(b.toLowerCase());    
			if (this.state.sortAscOrder) {
				return diff;
			}
		
			return -1 * diff;
		} else {
			const diff = a-b;
			if (this.state.sortAscOrder) {
				return diff;
			}

			return -1 * diff;
		}
	}
	
	//function to show/hide rate breakup for a room each day in room details modal
	showHideRateBreakup = (key,innerKey) => {
		if(this.state.currentRateBreakup !== key+'-'+innerKey) {
			this.setState({
				currentRateBreakup: key+'-'+innerKey
			});
		} else {
			this.setState({
				currentRateBreakup: null
			});
		}
	}

	//function to show/hide cancellation policy for a room in room details modal
	showHideCancellationPolicy = (key,innerKey,supplier_type,hotelCode,item) => {
		if(this.state.currentCancellationPolicy !== key+'-'+innerKey) {
			this.setState({
				currentCancellationPolicy: key+'-'+innerKey
			});
			if (supplier_type === 'tripjack') {
				let data = {
					"hotel_code": hotelCode,
					"option_id": item.room_id
				}
				this.props.getTripjackCancelPolicy(data)
			}
		} else {
			this.setState({
				currentCancellationPolicy: null
			});
		}


	}

	//Function to fill week days values in rate breakup
	fillWeekDaysValues = (data) => {
        let output = [];
        if(data){
            for(let i = 0;i<=6;i++){
                if(data[i]){
                    output.push(<span key={i}>{data[i].amount}</span>);
                }else{
					output.push(<span key={i}>-</span>);
				}
			}
		}
		return output;
	}
	showRateBreakup = (data)=>{
		let oneRoomData = data && hotelHelper.getDayRateBreakUpWeekWise(data);
		return (
			<div className="week-summary">
				<div className="table-week-day">
					<ul className="day-list">
						<li></li>
						<li>Sun</li>
						<li>Mon</li>
						<li>Tue</li>
						<li>Wed</li>
						<li>Thu</li>
						<li>Fri</li>
						<li>Sat</li>
					</ul>
				</div>
				<div className="table-week-list">
					<ul className="list-week">
						{
							oneRoomData && oneRoomData.map((item,key) => {
                                return (
                                    <li key={key}>
                                        <span className="head-week">Week {Number(key+1)}</span>
                                        {
                                            this.fillWeekDaysValues(item)
                                        }                                      
									</li>
								)
							})
						}
					</ul>
				</div>
			</div>
		)
	}

	//Set email data
	emailData = (event) => {
		let sendMailValues = this.state.sendMailValues;
		if(event.target.checked === true)
		{
			sendMailValues.push(event.target.value);
		}
		else
		{
			var index = sendMailValues.indexOf(event.target.value); 
			if (index > -1) 
			{
				sendMailValues.splice(index, 1);
			}
		}
		this.setState({sendMailValues: sendMailValues});
	}

	renderDescription = (hotelDetail) =>
	{
		return (
			<PerfectScrollbar>
			<div className="inner-tab-content">
				<h2>About {hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.hotel_name}</h2>
				<div className="mid-tab-section">
					<p><span dangerouslySetInnerHTML={{ __html: hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.description }}></span> </p>
				</div>
			</div>
			</PerfectScrollbar>
		)
	}

	// amenities popup data
	renderamenities(hotelDetail) {
		return (
			<PerfectScrollbar>
			<div className="inner-tab-content scroll-popup">
				<div className="mid-tab-section">
					<div className="amenities-block">
						<div className="amenities-list">
							<h2>Hotel Facilities</h2>

							<ul>
								{
									hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.hotel_facilities.map((item, key) => {
										return (
											<React.Fragment key={key}>
												<li>{item}</li>
											</React.Fragment>
										)
									})
								}
							</ul>
						</div>
						<div className="amenities-list">
							<h2>Hotel In and Around</h2>
							<ul>
								{
									hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details && hotelDetail.response.hotel_details.attractions && hotelDetail.response.hotel_details.attractions.map((item, key) => {
										return (
											<React.Fragment key={key}>
												<li><span dangerouslySetInnerHTML={{ __html: item.value }}></span></li>
											</React.Fragment>
										)
									})
								}
							</ul>
						</div>
						<div className="msg-text-red">
							<p>* Services available on Extra Charge</p>
						</div>
					</div>
				</div>
			</div>
			</PerfectScrollbar>				
		);
	}
	//Get room details according to condition 
	getAvailableRooms(item,key,keyInner,supplier_type,hotelCode, cancelPolicy, checkOutDate){
		let liClass = '';
		let liPolicyClass = '';
		let rateBreakupStyle = 'none';
		let cancellationStyle = 'none';
		// code to manage rate breakup section show/hide as per the room we clicked on
		if (key + '-' + keyInner === this.state.currentRateBreakup) {
			liClass = 'open'; //class for slide effect
			rateBreakupStyle = 'block';
		}

		// code to manage cancellation policy section show/hide as per the room we clicked on
		if (key + '-' + keyInner === this.state.currentCancellationPolicy) {
			liPolicyClass = 'open-policy';//class for slide effect
			cancellationStyle = 'block';
		}
		return (
			<React.Fragment key={keyInner}>
				<div className={'cols-list-block ' + liClass + ' ' + liPolicyClass}>
					<div className="cols col-type">
						<label className="mob-head">Room Types</label>
						<div className="layer room-det"><span>{item.room_type_name}</span></div>
						<div className="layer room-offer">
						</div>
						{
							item.room_promotion !== ''
								?
								<span className="red-text">{item.room_promotion}</span>
								: ''
						}

					</div>
					<div className="cols col-inclusion">
						<label className="mob-head">Inclusion</label>
						{
							supplier_type === 'tripjack' &&
							<span>{item && item.inclusion}</span>
						}
						{
							supplier_type !== 'tripjack' &&
							item &&item.inclusion && item.inclusion.map((inclusionItem, inclusionindex) => {
								if (inclusionindex > 0) {
									return (
										<span key={inclusionindex}>,{inclusionItem}</span>
									)
								} else {
									return (
										<span key={inclusionindex}>{inclusionItem}</span>
									)
								}
							})
						}
					</div>
					<div className="cols col-rate">
						<label className="mob-head">Total Rate</label>
						<div className="layer room-price">
							{/* <span className="w-price"><small>Fare:</small> <samp><i className="icon icon-rupee" />43,279</samp></span> */}
							<span className="c-price"><small>Fare:</small>  <samp><i className="icon icon-rupee" />{currencyFormat(item.price_modified.published_price)}</samp></span>
							<span className="a-price" style={{ display: (this.state.offeredPriceHotelRooms === true) ? 'block' : 'none' }}><small>Fare:</small>  <samp><i className="icon icon-rupee" />{currencyFormat(item.price_modified.offered_price)}</samp></span>
						</div>
						<div className="layer link-list">
							<span className="link-anchor rate-breakup" onClick={() => this.showHideRateBreakup(key, keyInner)}>Rate Breakup</span>
							<span className="link-anchor cancellation-policy" onClick={() => this.showHideCancellationPolicy(key, keyInner,supplier_type,hotelCode,item)}>Cancellation Policy</span>
						</div>
						{
							supplier_type !== 'tripjack' &&item.cancellation_policies &&
							item.cancellation_policies.map((cancellationItem, cancellationKey) => {
								let cancellationDate = '';
								if (cancellationItem.charge === 0) {
									cancellationDate = 'Free cancellation till: ' + dateFormat(cancellationItem.to_date, 'DD MMM YYYY');
								}
								return (
									<span key={cancellationKey} className="red-text">{cancellationDate}</span>
								)
							})
						}
						{/* {
							supplier_type === 'tripjack' &&cancelPolicy && cancelPolicy.response && cancelPolicy.response.cancellation_policies &&
							cancelPolicy.response.cancellation_policies.map((cancellationItem, cancellationKey) => {
								let cancellationDate = '';
								if (cancellationItem.charge === 0) {
									cancellationDate = 'Free cancellation till: ' + dateFormat(cancellationItem.to_date, 'DD MMM YYYY');
								}
								return (
									<span key={cancellationKey} className="red-text">{cancellationDate}</span>
								)
							})
						} */}
					</div>

					<div className="cols-list-acc rate-break-detail" style={{ 'display': rateBreakupStyle }}>
						<h4>Rate Breakup</h4>
						<div className="rate-summary-wrap">
							{
								this.showRateBreakup(item.day_rates)
							}
							<div className="rate-summary rate-breakupsummary">
								<div className="summary-title">
									<h3>Rate Summary</h3>
								</div>
								<div className="rate-price-list">
									<ul className="list-price">
										<li> <span>Currency</span> <small>{item.price.currency_code}</small> </li>
										<li> <span>Total</span> <small>{currencyFormat(item.price_modified.room_price)}</small> </li>
										<li> <span>Tax(+)</span> <small>{currencyFormat(item.price_modified.tax)}</small> </li>
										<li> <span>Other Tax(+)</span> <small>{currencyFormat(item.price_modified.other_tax)}</small> </li>
										<li> <span>Extra Guest Charges(+)</span> <small>{currencyFormat(item.price.extra_guest_charge)}</small> </li>
										<li> <span>Service Charges (+)</span> <small>{currencyFormat(item.price_modified.service_charge)}</small> </li>
										<li> <span>Other Charges (+)</span> <small>{currencyFormat(item.price_modified.other_charges)}</small> </li>
										<li> <span>GST (+)</span> <small>{currencyFormat(Number(item.price_modified.sgst) + Number(item.price_modified.igst) + Number(item.price_modified.cgst))}</small> </li>
										{/* <li> <span>No. of Rooms</span> <small>1</small> </li> */}
										<li> <span>Total Price</span> <small>{currencyFormat(item.price_modified.published_price)} </small> </li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="cols-list-acc cancellation-detail" style={{ 'display': cancellationStyle }}>
						<h4>Cancellation Policy</h4>
						<div className="cancellation-col">
							<div className="thead-col-list">
								<div className="cancel-cols"><span>Cancelled on or After</span></div>
								<div className="cancel-cols"><span>Cancelled on or Before</span></div>
								<div className="cancel-cols"><span>Cancellation Charges</span></div>
							</div>
							<div className="tbody-col-list">
								<ul>
									{
										supplier_type !== 'tripjack' && item.cancellation_policies.map((cancellationItem, cancellationKey) => {
											let cancellationCharge = '';
											let dynamicClass = ''
											if (cancellationItem.charge_type === 1) {
												cancellationCharge = currencyFormat(cancellationItem.charge);
												dynamicClass = "icon icon-rupee";
											}
											else {
												cancellationCharge = cancellationItem.charge + '%';
												dynamicClass = '';
											}
											return (
												<li key={cancellationKey}>
													<div className="cancel-cols"><span>{dateFormat(cancellationItem.from_date, 'DD MMM YYYY')}</span></div>
													<div className="cancel-cols"><span>{dateFormat(cancellationItem.to_date, 'DD MMM YYYY')}</span></div>
													<div className="cancel-cols"><span><i className={dynamicClass}></i>{cancellationCharge}</span></div>
												</li>
											)
										})
									}
									{
										supplier_type === 'tripjack' && cancelPolicy && cancelPolicy.response && cancelPolicy.response.cancellation_policies.map((cancellationItem, cancellationKey) => {
											let cancellationCharge = '';
											let dynamicClass = ''
											if (cancellationItem.charge_type === 1) {
												cancellationCharge = currencyFormat(cancellationItem.charge);
												dynamicClass = "icon icon-rupee";
											}
											else {
												cancellationCharge = cancellationItem.charge + '%';
												dynamicClass = '';
											}
											return (
												<li key={cancellationKey}>
													<div className="cancel-cols"><span>{dateFormat(cancellationItem.from_date, 'DD MMM YYYY')}</span></div>
													<div className="cancel-cols"><span>{dateFormat(cancellationItem.to_date, 'DD MMM YYYY')}</span></div>
													<div className="cancel-cols"><span><i className={dynamicClass}></i>{cancellationCharge}</span></div>
												</li>
											)
										})
									}
								</ul>
							</div>
						</div>
					</div>
				</div>
				</React.Fragment>
		)
	}
	// Available Room Details popup data
	renderAvailableRooms(hotelRoomDetail,filteredHotelRoomDetail) {
		return (
			<React.Fragment>
				{
					//If is_policy_per_stay is true then we will use room combination to fetch rooms
					 (hotelRoomDetail && hotelRoomDetail.response && hotelRoomDetail.response.room_combinations.info_source === 'FixedCombination') 

					 ?

					 hotelRoomDetail.response.room_combinations.room_combination.map((itemOuter, key) => {
						let hotelRooms = hotelHelper.getHotelFromRoomIndex(itemOuter, filteredHotelRoomDetail); //Get rooms by room index which are in one combination
						if(hotelRooms.length > 0)
						{
						return (
							<ul 
								key={key} 
								data-price={hotelRooms[0].price_modified.offered_price} 
								data-inclusion={hotelRooms[0].inclusion.length === 0?0:1}
								data-free-cancellation={hotelRooms[0] && hotelRooms[0].cancellation_policies[0] && hotelRooms[0].cancellation_policies[0].charge === 0?1:0}
								data-pan-required={hotelRooms[0].is_pan_mandatory ? 1 : 0}
								className="single-room"
							>
							<React.Fragment>
								<li>
									<React.Fragment>
										<div className="cols col-check">
											<div className="radio-check">
												<input type="radio" id={key} name="choose_room"
													onClick={() => { this.props.getChooseRooms(hotelRooms,false) }}
												/>
												<label htmlFor={key} />
											</div>
										</div>
										<div className="rooms-detail-outer">
										{
											hotelRooms.map((item, keyInner) => 
											{
												return(
													this.getAvailableRooms(item,key,keyInner)
												)
											})
										}
								</div>
								</React.Fragment>
							</li>
						</React.Fragment>
						</ul>
						)
					} else {
						return false;
					}
					})
					:

					//else we have to use  hotel_rooms_details.
					hotelRoomDetail.response.room_combinations.room_combination.map((itemOuter, key) => {
						return (
							<ul key={key}>
							<React.Fragment>
								<h6>Room {Number(key)+1}</h6>
								{
									filteredHotelRoomDetail.map((itemInner, keyInner) => {

										return (
											<React.Fragment key={keyInner}>
												<li
												data-price={itemInner.price_modified.published_price} 
												data-inclusion={itemInner.inclusion.length === 0?0:1}
												data-free-cancellation={itemInner.cancellation_policies[0].charge === 0?1:0}
												data-pan-required={itemInner.is_pan_mandatory ? 1 : 0}
												className="single-room">
													<React.Fragment>
														<div className="cols col-check">
															<div className="radio-check">
																<input type="radio" id={key+''+keyInner} name={"choose_room"+key} 
																	onChange={() => { 
																		this.props.getChooseRooms(itemInner,true,key)
																	}}
																/>
																<label htmlFor={key+''+keyInner} />
															</div>
														</div>
														<div className="rooms-detail-outer">
														{
																this.getAvailableRooms(itemInner,key,keyInner)
														}
												</div>
												</React.Fragment>
											</li>
										</React.Fragment>
										)
									})
								}
							</React.Fragment>
							</ul>
						)
					})
				}
				</React.Fragment>
		);
	}

	// Hotel Details popup data
	renderHotelDetail(hotelDetail) {
		return (
					<PerfectScrollbar>
						<div className="inner-tab-content scroll-popup">
									<div className="amenities-list">
										<h2>About {hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.hotel_name}</h2>
										<div className="mid-tab-section">
											<p><span dangerouslySetInnerHTML={{ __html: hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.description }}></span> </p>
										</div>
									</div>
								<p></p>
							
								<div className="amenities-block">
									<div className="amenities-list">
										<h2>Hotel Facilities</h2>
										<ul>
											{
												hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.hotel_facilities.map((item, key) => {
													return (
														<React.Fragment key={key}>
															<li>{item}</li>
														</React.Fragment>
													)
												})
											}
										</ul>
									</div>
									<div className="amenities-list">
										<h2>Hotel In and Around</h2>
										<ul>
											{
												hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details && hotelDetail.response.hotel_details.attractions && hotelDetail.response.hotel_details.attractions.map((item, key) => {
													return (
														<React.Fragment key={key}>
															<li><span dangerouslySetInnerHTML={{ __html: item.value }}></span></li>
														</React.Fragment>
													)
												})
											}
										</ul>
									</div>
									<div className="msg-text-red">
										<p>* Services available on Extra Charge</p>
									</div>
								</div>
						</div> 
				</PerfectScrollbar>
			);
	}

	// Hotel Image Gallery popup data
	renderImageGallery(hotelDetail) {
		return (
					<PerfectScrollbar>
							<ul className="thumb-img">
							{
								hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details && hotelDetail.response.hotel_details.images && hotelDetail.response.hotel_details.images.map((item, key) => {
									return (
										<React.Fragment key={key}>
											{ /* eslint-disable-next-line */}
											<li onClick={() => this.props.getGalleryImages(item.url ? item.url : item)}><a href="javascript:void(0)"><img src={item.url ? item.url : item} alt="" /></a></li>
										</React.Fragment>
									)
								})
							}
						</ul>
					</PerfectScrollbar>
					);
		}
	//Send itineraries mails
	sendMail = () => {
		this.props.sendItinerariesMail(this.state.sendMailAddress,this.state.sendMailValues,this.state.includeFare);
	}

	 

	setRoomFilters = (e,key,subKey) => {
		let filters = this.state.hotelFilters;
		if(e.target.checked && subKey === ''){
			filters[key] = true;
		}else if(e.target.checked && subKey !== ''){
			let arrayValue = filters[key];
			arrayValue.push(subKey);
			filters[key] = arrayValue;
		}else if(!e.target.checked && subKey !== '')
		{
			let arrayValue = filters[key];
			const index = arrayValue.indexOf(subKey);
			arrayValue.splice(index, 1);
			filters[key] = arrayValue;
		}else{
			filters[key] = false;
		}
		this.setState({ hotelFilters : filters })
	}

	openMealBox = () => {
		let mealDropdown = this.state.mealDropdown;
		if(mealDropdown)
		{
			this.setState({
				mealDropdown : false
			});
		}else{
			this.setState({
				mealDropdown : true
			});
		}
	}

	renderTripjackAvailableRooms(hotelRoomDetail, filteredHotelRoomDetail, hotelCode, cancelPolicy, checkOutDate) {
		return (
			<React.Fragment>
				
				 {
					filteredHotelRoomDetail && filteredHotelRoomDetail.map((item, key) => {
						return(
							// item && item.room_combination.map((item2, key2) => {
							// 	return (
									<ul
										key={key}
										//  data-price={item && item.room_combination && item.room_combination[0].price_modified && (item.room_combination[0].price_modified.offered_price || item.room_combination[1].price_modified.offered_price || item.room_combination[2].price_modified.offered_price||item.room_combination[3].price_modified.offered_price||item.room_combination[4].price_modified.offered_price||item.room_combination[5].price_modified.offered_price)} 
										//  data-inclusion={item && item.room_combination.inclusion && (item.room_combination[0].inclusion.length === 0 || item.room_combination[1].inclusion.length === 0|| item.room_combination[2].inclusion.length === 0|| item.room_combination[3].inclusion.length === 0|| item.room_combination[4].inclusion.length === 0|| item.room_combination[5].inclusion.length === 0)?0:1}
										//  data-free-cancellation={item && item.room_combination && item.room_combination[0].cancellation_policies[0] && (item.room_combination[0].cancellation_policies[0].charge === 0 || item.room_combination[1].cancellation_policies[0].charge === 0|| item.room_combination[2].cancellation_policies[0].charge === 0|| item.room_combination[3].cancellation_policies[0].charge === 0|| item.room_combination[4].cancellation_policies[0].charge === 0|| item.room_combination[5].cancellation_policies[0].charge === 0)?1:0}
										//  data-pan-required={item && item.room_combination && (item.room_combination[0].is_pan_mandatory || item.room_combination[1].is_pan_mandatory|| item.room_combination[2].is_pan_mandatory|| item.room_combination[3].is_pan_mandatory|| item.room_combination[4].is_pan_mandatory|| item.room_combination[5].is_pan_mandatory) ? 1 : 0}
										className="single-room"
									>
										<React.Fragment>
											<li>
												<React.Fragment>
													<div className="cols col-check">
														<div className="radio-check">
															<input type="radio" id={key} name="choose_room"
																onClick={() => { this.props.getChooseRooms((item && item.room_combination), false) }}
															/>
															<label htmlFor={key} />
														</div>
													</div>
													<div className="rooms-detail-outer">
														{
															item && item.room_combination.map((item1, keyInner1) => {
																return (
																	this.getAvailableRooms(item1, key, keyInner1, (hotelRoomDetail.response.supplier_type), hotelCode, cancelPolicy, checkOutDate)
																)
															})
														}
													</div>
												</React.Fragment>
											</li>
										</React.Fragment>
									</ul>
							// 	)
							// })
						)
						
					})
				} 
			</React.Fragment>
		);

	}

	render() {	
		const hotelSearchResult = this.props.hotelSearchResult && this.props.hotelSearchResult.hotel_results && hotelHelper.filterResults(this.props.hotelAdvanceSearchFilter, this.props.hotelSearchResult.hotel_results);
		const {loading , searchQuery , hotelDetail, showReadMoreDetailPopup, showViewOnMapPopup ,galleryImageUrl,readMoreActiveTab } = this.props;
		let checkInDate = this.props.searchQuery && this.props.searchQuery.check_in_date.split('/')[2]+'-'+this.props.searchQuery.check_in_date.split('/')[1]+'-'+this.props.searchQuery.check_in_date.split('/')[0];
		// let checkOutDate = this.props.searchQuery && this.props.searchQuery.check_out_date.split('/')[2]+'-'+this.props.searchQuery.check_out_date.split('/')[1]+'-'+this.props.searchQuery.check_out_date.split('/')[0];
		// let selectedRoomPrice = this.props.finalPrice;
		// let selectedRoomOfferedPrice = this.props.finalOfferedPrice;
		let selectedImageUrl ='';
		if(galleryImageUrl === null)
		{
			selectedImageUrl = hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details && hotelDetail.response.hotel_details.images && hotelDetail.response.hotel_details.images[0];
		}
		else
		{
			selectedImageUrl = galleryImageUrl;
		}
		//call sorting function as per the clicked field
		if(this.state.sortField && hotelSearchResult) {

            let sortChoice = this.state.sortField

			switch (sortChoice) {
				case 'Name':
					hotelSearchResult.sort((a, b) => this.sortByFieldType(a.hotel_name.replace(/^\s+|\s+$/gm,''), b.hotel_name.replace(/^\s+|\s+$/gm,''), 'string'));
					break;
				case 'Price':
					hotelSearchResult.sort((a, b) => this.sortByFieldType(a.price_modified.offered_price, b.price_modified.offered_price, 'number'));
					break;
				case 'Rating':
					hotelSearchResult.sort((a, b) => this.sortByFieldType(a.star_rating, b.star_rating, 'number'));
					break;
				case 'HotDeals':
					hotelSearchResult.sort((a, b) => this.sortByFieldType(a.hotel_promotion, b.hotel_promotion, 'string'));
					break;
				default : 
					hotelSearchResult.sort((a, b) => this.sortByFieldType(a.price_modified.offered_price, b.price_modified.offered_price, 'number'));
					break;
            }
		}
		//let hotelFilterRooms = this.props.hotelRoomDetail;
		let filteredHotelRoomDetail = this.props.hotelRoomDetail && this.props.hotelRoomDetail.response && this.props.hotelRoomDetail.response.hotel_rooms_details && hotelHelper.filterHotelDetails(this.props.hotelRoomDetail.response.hotel_rooms_details,this.state.hotelFilters,this.props.hotelRoomDetail.response.supplier_type);
		let hotelCode = hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details && hotelDetail.response.hotel_details.hotel_code;

		return (
			<React.Fragment>
				<HotelSearchHeaderComponent {...this.props} />
				{
					loading
						?
						<section className="mid-wrapper inner-mid-section">
						<div className="container">
						  <div className="search-result-section search-loader hotel-search-loader">
							<div className="searching">
							  <div  className="container">
								<div className="search-title"> <span>Searching your needs....</span>
								  <h1>Please be patient!</h1>
								</div>
								<div className="searching-outer">
								  <div className="searching-space"><img src="assets/images/searching-bg.jpg" alt=""/></div>
								  <div className="searching-slide"><img src="assets/images/search_hotel.gif" alt=""/></div>
								</div>
							  </div>
							</div>
								</div>
							</div>
						</section>
						:
						<section className="mid-wrapper inner-mid-section">
								{/************************************************* Left Filter, sort by and hotel listing section - start ***********************************************/}
								<div className="container">
									<div className="search-result-section ht-result-section">
										<HotelLeftSearch {...this.props}/> 
										<div className="right-side">
											<div className="ht-search-result">
												<div className="filter-btn">
												{ /* eslint-disable-next-line */}
												<a href="javascript:void(0)" className="btn btn-primary">Filter</a>
											</div>
											<div className="ht-title-info">
												<div className="pull-left">
													<h5>{searchQuery && searchQuery.city_name}</h5>
													<span className="ht-time">{dateFormat(checkInDate, 'DD MMM YYYY, FDAY')}</span>
												</div>
												<div className="pull-right">
														<div className="btn-mail show">
															{ /* eslint-disable-next-line */}
															<a className="btn btn-sky-blue" href="#" onClick={this.showHideEmailPopup.bind(this)}><i className="icon icon-close-envelope" aria-hidden="true" /> Send Email </a>
															<div className="email-tooltip" style={{ display: (this.state.emailPopup === true)?'block':'none' }}>
																<div className="tooltip-outer">
																	<div className="form-group">
																		{
																		this.props.sendMailerror && !this.props.ItinerariesMail.loading &&
																		<div className="alert alert-danger"> <strong><i className="fa fa-exclamation-triangle"></i>{this.props.sendMailerror}</strong></div>
																	}
																	{
																				this.props.sendMailsuccess && !this.props.ItinerariesMail.loading &&
																				<div className="alert alert-success"> <strong> <i className="fa fa-check-circle"></i>Email sent successfully</strong></div>
																		}
																		<input className="form-control custom-filed" type="email" placeholder="Email Address" onChange={(e)=>{this.setState({sendMailAddress:e.target.value})}} />
																	</div>
																	<div className="form-checkbox">
																		<div className="select-check">
																				<small className="checkbox">
																						<input type="checkbox" onChange={(e)=>{this.setState({includeFare:!this.state.includeFare});}}/>
																						<label></label>
																				</small> 
																				<span>Include Price?</span>
																		</div>
																	</div>
																	<div className="block">
																			<button type="submit" className={`btn btn-primary ${this.props.ItinerariesMail && this.props.ItinerariesMail.loading?'show':''}`} onClick={this.sendMail}>
																			<div className="loader-ring"> <span></span> 
																			</div>Submit</button>
																	</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="ht-detail-section">
												<div className="ht-head">
													<div className="pull-left">
														<div className="sort-by-block">
															<ul className="sort-list">
																	<li className="sort-by"><span>SORT BY:</span></li>
																	<li className={this.state.sortField === 'Name' ? 'active' : ''}>
																		{ /* eslint-disable-next-line */}
																		<a className="anchor-sort" href="javascript:void(0)" onClick={()=>this.sortHotelResults('Name')}>
																			Name
																			<i className={(this.state.sortField === 'Name') ? this.state.sortingIcon : ''}></i>
																		</a>
																	</li>
																	<li className={(this.state.sortField === 'Price' || this.state.sortField === '') ? 'active' : ''}>
																		{ /* eslint-disable-next-line */}
																		<a className="anchor-sort" href="javascript:void(0)" onClick={()=>this.sortHotelResults('Price')}>
																			Price
																			<i className={(this.state.sortField === 'Price' || this.state.sortField === '') ? this.state.sortingIcon : ''}></i>
																		</a>
																	</li>
																	<li className={this.state.sortField === 'Rating' ? 'active' : ''}>
																		{ /* eslint-disable-next-line */}
																		<a className="anchor-sort" href="javascript:void(0)" onClick={()=>this.sortHotelResults('Rating')}>
																			Star rating
																			<i className={(this.state.sortField === 'Rating') ? this.state.sortingIcon : ''}></i>
																		</a>
																	</li>
																	<li className={this.state.sortField === 'HotDeals' ? 'active' : ''}>
																		{ /* eslint-disable-next-line */}
																		<a className="anchor-sort" href="javascript:void(0)" onClick={()=>this.sortHotelResults('HotDeals')}>
																			Hot deals
																			<i className={(this.state.sortField === 'HotDeals') ? this.state.sortingIcon : ''}></i>
																		</a>
																</li>
															</ul>
														</div>
													</div>
													<div className="pull-right">
														<span className="ht-number">Showing <small>{hotelSearchResult && hotelSearchResult.length}</small> Hotels</span> 
														{ /* eslint-disable-next-line */}
														<a href="javascript:void(0);" className="btn btn-blue net-fare-button"><small className="offer-info" onClick={this.offeredPriceClick.bind(this)}>{this.state.offeredPrice ? 'Hide Net Fare ' : 'Show Net Fare '}<img src="assets/images/offer-icon.svg" alt="" /></small> </a>
														{/* <span className="ht-offer" onClick={this.offeredPriceClick.bind(this)}><img src="assets/images/offer-icon.svg" alt="" /></span> */}
													</div>
												</div>
												<div className="ht-body-content" id="cont-st" style={window.innerWidth <= 766 ? {} : {overflow:'auto',maxHeight:this.props.track_scroll_position && this.props.track_scroll_position.cliHeight > 1000 ? this.props.track_scroll_position.cliHeight : '1020px'  ,minHeight: '1000px',}}>
													<ul>
															{
																hotelSearchResult && hotelSearchResult.length > 0 
																?
																/* eslint-disable-next-line */
																hotelSearchResult && hotelSearchResult.slice(0,this.props.isFilterCheckedStatus).map((hotels, index) => {
																	if(hotels.hotel_name !== '' && hotels.hotel_address !== '')
																	{
																		return (
																			<li key={index} id='calcHeight'>
																				<div className="ht-col-inner">
																					<div className="ht-price-detail">
																						<span className="mail-icon">
																						<input type="checkbox"  value={hotels.result_index} name="sendMail" onChange={(event) => this.emailData(event)} />
																							<i className="icon icon-mail1" /> <i className="icon icon-checked" />
																						</span>
																						<div className="ht-price-info">
																							<div className="price-box">
																								<div className="correct-price"><span><i className="fa fa-inr" aria-hidden="true" /> {currencyFormat(hotels.price_modified.published_price)}</span></div>
																								<div className="actual-price" style={{ display: (this.state.offeredPrice === true)?'block':'none' }}><span><i className="fa fa-inr" aria-hidden="true" /> {currencyFormat(hotels.price_modified.offered_price)}</span></div>
																							</div>
																							<div className="ht-tax-info">
																								<p>Lowest Total Rate inc. Taxes</p>
																							</div>
																							<div className="ht-book-btn">
																							{ /* eslint-disable-next-line */}
																							<a className="btn btn-yellow" href="javascript:void(0);" onClick={() => {this.props.getHotelRoomDetails(this.props.hotelSearchResult.trace_id, hotels,'both',hotels.hotel_name,hotels.supplier_type)
																							} }>Book Now</a> </div>
																						</div>
																					</div>
																					<div className="ht-room-detail">
																						<div className="ht-pic" >
																							<figure onClick={() => {this.props.getHotelRoomDetails(this.props.hotelSearchResult.trace_id, hotels,'imagePopup',hotels.hotel_name,hotels.supplier_type)
																							} }><img src={(hotels && hotels.hotel_picture && hotels.hotel_picture[0] && hotels.hotel_picture[0].url) ? hotels.hotel_picture[0].url :hotels.hotel_picture} alt="" /></figure>
																						</div>
																						<div className="ht-detail">
																							<div className="ht-detail-title">
																								<h3>{hotels.hotel_name}</h3>
																								<ul className="rating">
																									{this.getHotelRatings(hotels.star_rating)}
																								</ul>
																							</div>
																							<div className="ht-location">
																								<i className="icon icon-location" />
																								<p>{(hotels && hotels.hotel_address && hotels.hotel_address.adr) ? (hotels.hotel_address.adr) : (hotels.hotel_address)}</p>
																								<p className='sub-address-hotels'>
																									{hotels && hotels.hotel_address && hotels.hotel_address.city && hotels.hotel_address.city.name}
																									{hotels && hotels.hotel_address && hotels.hotel_address.state &&", "+ hotels.hotel_address.state.name}
																									{hotels && hotels.hotel_address  &&", "+ hotels.hotel_address.postalCode}
																									{hotels && hotels.hotel_address && hotels.hotel_address.country && ", "+ hotels.hotel_address.country.name}
																								</p>
																							</div>
																							<div className="ht-info">
																								<p><span dangerouslySetInnerHTML={{ __html: hotels.hotel_description }}></span> </p>
																							</div>
																							<div className="ht-info-btn">
																								<button className="btn-border" onClick={() => this.props.getHotelRoomDetails(this.props.hotelSearchResult.trace_id, hotels,'single',hotels.hotel_name,hotels.supplier_type)}>Read More</button>
																								<button className="btn-border" onClick={() => this.props.getHotelRoomDetails(this.props.hotelSearchResult.trace_id, hotels,'map',hotels.hotel_name,hotels.supplier_type)}><i className="icon icon-location" />View On Map</button>
																							</div>
																						</div>
																					</div>
																				</div>
																				<span className="acc-title">
																					<h2 onClick={() => {this.props.getHotelRoomDetails(this.props.hotelSearchResult.trace_id, hotels,'both',hotels.hotel_name,hotels.supplier_type)
																						}}>Room Details</h2>
																				</span>
																			</li>
																		);
																	}
																})
																:	
																<div className="no-result-found">
																	<figure> <img src="assets/images/no-result.svg" alt=""/> </figure>
																<div className="text-no-result">
																  <h2>No results found</h2>
																  <p>We can’t find any item matching your search.</p>
																	</div>
																</div>
														}
													</ul>
												<div className='footers-end'></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/************************************************* Left Filter, sort by and hotel listing section - end *************************************************/}

							{/**************************************************** read more Popup - Start ****************************************************/}
							<div className={(showReadMoreDetailPopup === true) ? "popup-crad read-more-popup show" : "popup-crad read-more-popup"}>
								{/*show*/}
									<div className="popup-outer">
										<div className="popup-content">
											<div className="popup-head">
												<div className="popup-close"><span className="icon icon-cross" onClick={this.props.getHideReadMoreDetailPopup}/></div>
											</div>
											<div className="popup-mid">
												<div className="popup-tophead">
													<div className="popup-title">
														<h3>{this.props.selectedHotelName}</h3>
														<ul className="rating">
															{this.getHotelRatings(hotelDetail && hotelDetail.response &&    hotelDetail.response.hotel_details.star_rating)}
														</ul>
													</div>
													<div className="location">
													<i className="icon icon-location" />
													<p>{hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.address}</p>
												</div>
												<div className="call-detail">
													<ul>
														{ hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.hotel_contact_no &&
															<li className="ph-number">
																<label>Call on:</label>
																<span className="anchor-call" href={'tel:'+hotelDetail.response.hotel_details.hotel_contact_no}><i className="icon icon-phone" /><samp>{hotelDetail.response.hotel_details.hotel_contact_no}</samp></span>
															</li>
														}
														{hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.fax_number &&
															<li>
																<label>Fax:</label>
																<span className="anchor-call" href={'fax:'+hotelDetail.response.hotel_details.hotel_contact_no}><i className="icon icon-printer" /><samp>{hotelDetail.response.hotel_details.fax_number}</samp></span>
															</li>
														}
													</ul>
												</div>
											</div>
											<div className="popup-mid-body">
												<div className="popup-tab-container">
													<div className="popup-tab-nav">
														<ul>
															<li className={(readMoreActiveTab === "description") ? "active" : ""} onClick={this.props.handleReadMoreDetailsTab.bind(this, 'description')}> <span className="anchor">Description</span> </li>
															<li className={(readMoreActiveTab === "photos") ? "active" : ""} onClick={this.props.handleReadMoreDetailsTab.bind(this, 'photos')}> <span className="anchor">Photos</span> </li>
															<li className={(readMoreActiveTab === "amenities") ? "active" : ""} onClick={this.props.handleReadMoreDetailsTab.bind(this, 'amenities')}> <span className="anchor">Amenities</span> </li>
														</ul>
													</div>
														{
															hotelDetail && hotelDetail.loading
															?
																<div className="loader relative-loader" style={{ display:'block'}}>
																<div className="loader-content">
																<div className="loader-ring blue-ring"> <span></span> </div>
																</div>
																</div>
															:
															<React.Fragment>
															<div className="pop-tab-contener" style={{ display: (readMoreActiveTab === 'description')?'block':'none' }}>
															{
																hotelDetail && hotelDetail.response
															?
																<div>
																{
																	this.renderDescription(hotelDetail)
																}
																</div>
																:<div className="no-result-found">
																	<figure> <img src="assets/images/no-result.svg" alt=""/> </figure>
																	<div className="text-no-result">
																	<h2>No description found</h2>
																	</div>
																</div>
															}
															</div>
															<div className="pop-tab-contener" id="amenities" style={{ display: (readMoreActiveTab === 'amenities')?'block':'none' }}>
															{
																hotelDetail && hotelDetail.response
															?
																			<div>
																				{
																					this.renderamenities(hotelDetail)
																				}
																			</div>
																			: <div className="no-result-found">
																				<figure> <img src="assets/images/no-result.svg" alt="" /> </figure>
																				<div className="text-no-result">
																					<h2>No hotel details found</h2>
																				</div>
																			</div>
																	}
																</div>
																<div className="pop-tab-contener" id="photos" style={{ display: (readMoreActiveTab === 'photos') ? 'block' : 'none' }}>
																	{
																		hotelDetail && hotelDetail.response
																			?
																			<div className="inner-tab-content scroll-popup">
																				<h2>Photos</h2>
																				<div className="mid-tab-section">
																					<div className="photo-block">
																						<ul className="photo-carosuel">
																							{
																								hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.images &&
																								<CarouselSlider carousalClass="photo-carosuel" dataSlider={hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.images} />
																							}
																						</ul>
																					</div>
																				</div>
																			</div>
															:<div className="no-result-found">
																	<figure> <img src="assets/images/no-result.svg" alt=""/> </figure>
																	<div className="text-no-result">
																	<h2>No images found</h2>
																	</div>
																			</div>
																	}
																</div>
															</React.Fragment>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="popup-overlay" />
							</div>
							{/**************************************************** read more Popup - End ******************************************************/}

							{/*-room detail Popup Start--*/}

							{/*-room detail Popup End--*/}
							{
								showViewOnMapPopup && <div className={(showViewOnMapPopup === true) ? "popup-crad view-map-card show" : "popup-crad view-map-card"}>
								<div className="popup-outer">
									<div className="popup-content">
										<div className="popup-head">
											<h2>View Map</h2>
											<div className="popup-close"><span className="icon icon-cross" onClick={this.props.getHideViewOnMapPopup}></span></div>
										</div>
											{
											hotelDetail && hotelDetail.loading
											?
												<div className="loader relative-loader" style={{ display:'block'}}>
												<div className="loader-content">
												<div className="loader-ring blue-ring"> <span></span> </div>
												</div>
												</div>
												:
												<div className="popup-scorll">
													<div className="map-block-popup">
														{
															hotelDetail && hotelDetail.response &&
															<GoogleMap googleMapData={hotelDetail.response} />
														}
													</div>
												</div>
										}
									</div>
								</div>
								<div className="popup-overlay"></div>
							</div>
							}
							
						</section>
				}
			</React.Fragment>
		)
	}
}

export default HotelSearchDetailsComponent