/* external dependencies */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from "yup";

class ConfirmPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputType1: 'password',
            eyeIcon1: 'icon icon-eye',
            inputType2: 'password',
            eyeIcon2: 'icon icon-eye',
            strengthClass:null,
        };
    }

    enterPressed = (formikProps, event) => { //event is not passed from methd calling as its received by default when it was binded to our context
        var code = event.keyCode || event.which;
        if(code === 13) { 
            formikProps.handleSubmit();
        } 
    }

    //will be called on click of eye icon in password fields (if input type is text than set it to password and vice versa)
    showHidePassword(fieldName, iconName){
        this.setState({
            [fieldName]: this.state[fieldName] === 'text' ? 'password' : 'text',
            [iconName]: this.state[fieldName] === 'text' ? 'icon icon-eye' : 'icon icon icon-eye-blocked'
        })  
    }

    confirmPasswordValidator = yup.object()
        .shape({
            otp: yup.string()
                .required('OTP is required'),
            password: yup.string()
                .required('Please enter your password'),
            confirmPassword: yup.string()
                .oneOf([yup.ref('password'), null], 'Your new password and confirm password should match.')
                .required('Please re-enter your password')
        });

    handleCancelClick = () => {
        this.props.handleComponentClass('show-send-otp');
    }
    checkPasswordStrength = (e) => {
        var targetValue = e.target.value;
        var validationaWarning =  new RegExp(/[0-9]/).test(targetValue) && (new RegExp(/[a-z]/).test(targetValue) || new RegExp(/[A-Z]/).test(targetValue) || new RegExp(/[!#@$%^&*~`)(+=._-]/).test(targetValue));
        var validationSuccess =  new RegExp(/[0-9]/).test(targetValue) && new RegExp(/[a-z]/).test(targetValue) && new RegExp(/[A-Z]/).test(targetValue) && new RegExp(/[!#@$%^&*~`)(+=._-]/).test(targetValue);
       if(targetValue.length >= 4 && validationaWarning && validationSuccess)
        {
            this.setState({ strengthClass: 'success',});
        }
        else if(targetValue.length >= 4 && validationaWarning)
        {
            this.setState({ strengthClass: 'warning',});
        }
        else  if(targetValue.length >= 1)
        {
            this.setState({ strengthClass: 'danger',});
        }
        else{
            this.setState({ strengthClass: null,});
        }
    }
    
    renderForm = (formikProps) => {
        const {handleSubmit, isSubmitting, isValid, errors, touched} = formikProps;
        const {errorMsg, resetPassword} = this.props;

        // Detect if screen width is less than 768px (Mobile view)
        const isMobile = window.innerWidth < 768;

        // Define inline styles for mobile and desktop
        const progressBarStyle = {
            width: '100%', // Full width for both views
            height: isMobile ? '8px' : '4px', // Smaller height for mobile
            margin: isMobile ? '10px 0' : '5px 0' // Adjust margin for mobile
        };
        return (
            <div className="inner-login">
                <h2>Reset Password</h2>
                {   errors.otp && touched.otp &&
                    <div className="alert alert-danger"><strong><i className="fa fa-exclamation-triangle"></i>{errors.otp}</strong>
                    <i className="fa fa-times" aria-hidden="true"></i></div>
                }
                {   errors.password && touched.password &&
                    <div className="alert alert-danger"><strong><i className="fa fa-exclamation-triangle"></i>{errors.password}</strong>
                    <i className="fa fa-times" aria-hidden="true"></i></div>
                }
                {   errors.confirmPassword && touched.confirmPassword &&
                    <div className="alert alert-danger"><strong><i className="fa fa-exclamation-triangle"></i>{errors.confirmPassword}</strong>
                    <i className="fa fa-times" aria-hidden="true"></i></div>
                }
                {   errorMsg && 
                    <div className="alert alert-danger"><strong><i className="fa fa-exclamation-triangle"></i>{errorMsg}</strong>
                    <i className="fa fa-times" aria-hidden="true"></i></div>
                }
                {   resetPassword.passwordChanged &&
                    <div className="alert alert-success">
                        <strong> <i className="fa fa-check-circle"></i> Your password has been changed successfully!.</strong><i className="fa fa-times" aria-hidden="true"></i>
                    </div>
                }

                <div className="login_form">
                    <div className="form-group">
                        <Field
                            type="text"
                            name="otp"
                            className="form-control" required
                            onKeyPress={this.enterPressed.bind(this, formikProps)} //this will not be received as an argument, it shows only the context to which our method is binded
                        />
                        <i className="material-icons">lock</i>
                        <label className="control-label">OTP</label>
                    </div>
                    <div className="form-group">
                        <Field
                            type={this.state.inputType1}
                            name="password"
                            className="form-control" required
                            onKeyPress={this.enterPressed.bind(this, formikProps)} 
                            onKeyUp={((e) => this.checkPasswordStrength(e))}//this will not be received as an argument, it shows only the context to which our method is binded
                        />
                        <i className="material-icons">lock</i>
                        <label className="control-label">Password</label>
                        <span className="password-show-hide" onClick={() => this.showHidePassword('inputType1', 'eyeIcon1')} >
                            { /* eslint-disable-next-line */}
                            <a href="javascript:void(0)"><i className={this.state.eyeIcon1}></i></a>
                        </span>
                        {this.state.strengthClass?
                            <div className="progress progress-bar-block" style={progressBarStyle}>
                                <div className={"progress-bar progress-bar-striped bg-"+this.state.strengthClass} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                            </div> 
                        :""
                        }
                        <div className="info-block"><i className="icon icon-info"></i>
                            <span className="info-tooltip">Password longer than 4 characters or at least 1 lowercase or uppercase or number or special char</span>
                        </div>
                    </div>
                    <div className="form-group">
                        <Field
                            type={this.state.inputType2}
                            name="confirmPassword"
                            className="form-control" required
                            onKeyPress={this.enterPressed.bind(this, formikProps)} //this will not be received as an argument, it shows only the context to which our method is binded
                        />
                        <i className="material-icons">lock</i>
                        <label className="control-label">Confirm Password</label>
                        <span className="password-show-hide" onClick={() => this.showHidePassword('inputType2', 'eyeIcon2')} >
                            { /* eslint-disable-next-line */}
                            <a href="javascript:void(0)"><i className={this.state.eyeIcon2}></i></a>
                        </span>
                    </div>
                </div>

                <div className="login-btn">
                    <button className="btn btn-dark" onClick={this.handleCancelClick}>Cancel</button>
                    <button
                        type="submit"
                        className="btn btn-green"
                        /*at initial errors object will be blank and touched object will have one value (email from previous form). button should be disabled at initial and till errors object is not blank again*/
                        disabled={isSubmitting || !isValid}
                        onClick={handleSubmit}>Continue
                    </button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="login-reset-pass">
                <div className="login-outer">
                    <Formik
                        initialValues={{otp:'', password:'', confirmPassword:''}}
                        onSubmit={(values, actions) => this.props.handlePasswordSubmit(values, actions)}
                        validationSchema={this.confirmPasswordValidator}
                        render={this.renderForm}
                    /> 
                </div>
            </div>
        );
    }
}

export default ConfirmPasswordForm;